import React, { useState, useEffect,useCallback }  from 'react';
import { withRouter, Link } from 'react-router-dom'
import {connect} from "react-redux";
import AWS from 'aws-sdk';
import _ from "lodash" // Import the entire lodash library
import axios from 'axios';
import * as JSZip from 'jszip';
import '@ant-design/compatible/assets/index.css';
import { Form } from '@ant-design/compatible';
import { InfoCircleOutlined, LoadingOutlined, PaperClipOutlined, DeleteOutlined, PlusOutlined,CloseCircleOutlined } from '@ant-design/icons';
import { Row, Col, Input, Button, Radio, Select, Upload, message, TreeSelect, Collapse, Checkbox, Modal, Card, Tooltip, InputNumber ,Progress, Popover,Divider, Steps} from 'antd';
import 'rc-color-picker/assets/index.css';
import * as Constants from "../../CustomerComponents/Constants";
import { createProduct, updateProduct} from "../../../../redux/actions";
import CustomerOnboarding from './../../CustomerComponents/CustomerOnboarding';
import ColorSelect from '../../FormUtilities/ColorSelect';
import StyleCategory from '../../FormUtilities/StyleCategorySelect';
import $ from 'jquery';
import ENVIRONMENT from '../../../../environments';
import FileConstants from "../../../../FileConstants";
import SuccessModal from '../../SuccessModal/SuccessModal';
import * as Styles from "../../../../Styles";
import HiddenCategory from '../HiddenCategory/HiddenCategory';
import * as Utilities from "../../Utilities";
import PaymentConfirmationModal from '../../PaymentConfirmationModalSingle/PaymentConfirmationModalSingle';
import SubscriptionPackageUpdate from '../../SubscriptionPackageUpdate';
import CardSaveDrawer from '../../CardSaveDrawer/CardSaveDrawer';
import WarningModal from '../../WarningModal/WarningModal';
import LicenseFields from '../../FormUtilities/LicenseFields';
import PaymentFailureModal from '../../PaymentFailureModal/PaymentFailureModal'
import ColorSelection from '../ColorSelection';
import HelpMessageModal from '../../HelpMessageModal/HelpMessageModal';

const { Option } = Select;
const { Panel } = Collapse;
const { Step } = Steps;

const COMPANY_ID = localStorage.getItem('company_id');
const CUSTOMER_USERNAME = localStorage.getItem('username');
const SHARED_ENTITIES = localStorage.getItem('shared_entities');

const categoryStyle = Styles.categoryStyle;
const borderStyle = Styles.borderStyle;
const borderStyleEmpty = Styles.borderStyleEmpty
const progress_bar = Styles.progress_bar;
let upload_obj = {};

const ProductCombinedForm = (props) => {

    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched , getFieldValue , setFieldsValue, resetFields} = props.form;
    const [submitType, setSubmitType] = useState('submit')
    const [materialsData, setMaterialsData] = useState([])
    const [categoriesData, setCategoriesData] = useState([])
    const [licenseType, setLicenseType] = useState('private');
    let onboarding = new URLSearchParams(window.location.search).get("onboarding");
    if(onboarding == undefined){
        onboarding = false;
    }
    else {
        onboarding = true;
    }
    const [showOnboarding, setShowOnboarding] = useState(onboarding);
    const [publicLicensePanel, setPublicLicensePanel] = useState(["1"]);
    const [privateLicensePanel, setPrivateLicensePanel] = useState(["1"]);
    const [requestSubmitted, setRequestSubmitted] = useState(false);
    const [requestSaved, setRequestSaved] = useState(false);
    const [publicLicenseChecked, setPublicLicenseChecked] = useState(false);
    const [privateLicenseChecked, setPrivateLicenseChecked] = useState(false);
    const [forSale, setForSale] = useState(false);
    const [forCreativeCommon, setForCreativeCommon] = useState(false);
    const [creativeCommon, setCreativeCommon] = useState('');
    const [formValidationFailed, setFormValidationFailed] = useState(false);
    const [categoryValue, setCategoryValue] = useState('');
    const [categorySearchValue, setCategorySearchValue] = useState('');
    const [materialValue, setMaterialValue] = useState('');
    const [materialSearchValue, setMaterialSearchValue] = useState('');
    const [uploadError, setUploadError] = useState('');
    const [archiveValidationError, setArchiveValidationError] = useState('');
    const [modelFileList, setModelFileList] = useState([]);
    const [rawFileList, setRawFileList] = useState([]);
    const [userModelType, setUserModelType] = useState('high_res');
    const [modelingRequired, setModelingRequired] = useState(false);
    const [rawAttchments, setRawAttchments] = useState(false);
    const [objBundle, setObjBundle] = useState(false);
    const [productPhotosList, setProductPhotosList] = useState([]);
    const [materialPhotosList, setMaterialPhotosList] = useState([]);
    const [submittingSaveRequest, setSubmittingSaveRequest] = useState(false);
    const [submittingCreateRequest, setSubmittingCreateRequest] = useState(false);
    const [, updateState] = React.useState();
    const [hasError, setHasError] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [makeConfig, setMakeConfig] = useState(false);
    const [materialModal, setMaterialModal] = useState(false);
    const [finalSelectedMaterials, setFinalSelectedMaterials] = useState([]);
    const [selectedMaterial, setSelectedMaterial] = useState([]);
    const [selectedComp, setSelectedComp] = useState('');
    const [selectedImage, setSelectedImage] = useState([]);
    const [componentFileList, setComponentFileList] = useState([]);
    const [showConfigNote, setShowConfigNote] = useState(false);
    const [modelType, setModelType] = useState(1);
    const [componentArray, setComponentArray] = useState([]);
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [materialInfo, setMaterialInfo] = useState([]);
    const [configurableModal, setConfigurableModal] = useState(false);
    const [isConfigurable, setIsConfigurable] = useState(false);
    const [requestID, setRequestID] = useState(0);
    const [productID, setProductID] = useState(-1);
    const [materialsReturned, setMaterialsReturned] = useState([]);
    const [hiddenFile, setHiddenFile] = useState([]);
    const [hiddenCategoryFilelist, setHiddenCategoryFilelist] = useState([]);
    const [hiddenCategoryIndex, setHiddenCategoryIndex] = useState(0);
    const [paymentConfirmationModal, setPaymentConfirmationModal] = useState(false);
    const [productCreatePayload,setProductCreatePayload] = useState(null);
    const [cardSaveDrawer, setCardSaveDrawer] = useState(false);
    const [cardSaveFailure, setCardSaveFailure] = useState(false);
    const [cardPaymentFailure, setCardPaymentFailure] = useState(false);
    const [requestType, setRequestType] = useState('existing_product_model');
    const [paymentRequestID, setPaymentRequestID] = useState(-1);
    const [productName, setProductName] = useState('');
    const [requestPrice, setRequestPrice] = useState(-1);
    const [selectedPriceCategory, setSelectedPriceCategory] = useState('');
    const [subscriptionPackageUpdateModal, setSubscriptionPackageUpdateModal] = useState(false);
    const [subscriptionPlan, setSubscriptionPlan] = useState('');
    const [buttonLoader, setButtonLoader] = useState(false);
    const [requestStatus, setRequestStatus] = useState(null);
    const [cardStatus, setCardStatus] = useState(null);
    const [prices, setPrices] = useState([]);
    const [currentPackage, setCurrentPackage] = useState('');
    const [isSubscriptionActive, setIsSubscriptionActive] = useState(false);
    const [confirmRequest, setConfirmRequest] = useState(false);
    const [price, setPrice] = useState(-1);
    const [paymentFailureModal, setPaymentFailureModal] = useState(false);
    const [paymentFailureMessage, setPaymentFailureMessage] = useState('');
    const [priceError, setPriceError] = useState('');
    const [isSubscriptionUpdateRequest, setIsSubscriptionUpdateRequest] = useState(false);
    const [cardSaveFlowTriggered, setCardSaveFlowTriggered] = useState(false);
    const { match: { params } } = props;
    const [searchValue, setSearchValue] = useState('');
    const [groupIdList, setGroupIdList] = useState([]);
    const [groupIdValue, setGroupIdValue] = useState('');
    const [groupIdSearchValue, setGroupIdSearchValue] = useState('');
    const [enableGroupIDField, setEnableGroupIDField] = useState(false);
    const [hexColor, setHexColor] = useState('#00000');
    const [pantoneColor, setPantoneColor] = useState('');
    const [colorSelectionType, setColorSelectionType] = useState(2);
    const [colorFileList, setColorFileList] = useState([]);
    const [addedProduct, setAddedProduct] = useState(false);
    const [uploadedProduct, setUploadedProduct] = useState(false)
    const [redirectionPath, setRedirectionPath] = useState(null);
    const [projects, setProjects] = useState([]);
    const [helpMessageModal, setHelpMessageModal] = useState(false);

    useEffect(() => {
        let redirection_path = new URLSearchParams(window.location.search).get("origin");
        setRedirectionPath(redirection_path);
    }, [])

    useEffect(() => {
        fetchProjectData();

        let payload = {
            username: CUSTOMER_USERNAME,
            status: "5"
        };

        let shared_entities = SHARED_ENTITIES;
        let company_id = COMPANY_ID;
        if (shared_entities != undefined && company_id != undefined) {
            if (shared_entities.includes('material')) {
                payload.company_id = company_id
            }
        }
        axios.post(ENVIRONMENT.MATERIAL_GET_BATCH, payload)
        .then(res => {
            let parsedData = JSON.parse(res.data.body);
            setMaterialInfo(parsedData);
            console.log(parsedData)
            forceUpdate();
        });
        setRequestType("existing_product_model");
        loadUserRequestStatus();
        if((new URL(window.location.href)).searchParams.get("uploaded-model") == "true"){
            setUploadedProduct(true)
            setAddedProduct(true)
        }

    },[]);

    useEffect(() => {
        // Fetch customer's groups if any
        let company_id = COMPANY_ID;
        let payload = {
            customer_username: CUSTOMER_USERNAME,
        }
        if (company_id != undefined)
            payload.company_id = company_id
        axios.post(ENVIRONMENT.GROUP_IDS_GET_BATCH, payload)
        .then(res => {
            console.log('group ids = ', res)
            parseAndSetGroupIds(res.data)
        })
    }, [])

    const parseAndSetGroupIds = (data) => {
        let group_ids = []
        if (data) {
            data.map((item) => {
                group_ids.push({
                    key: item['group_id'],
                    value: item['group_id'],
                    title: item['group_id'],
                })
            })
            setGroupIdList(group_ids);
        }
    }

    const fetchProjectData = async () => {
        let payload = {
            order_by: "id desc",
            required_fields: [
              "id",
              "name"
            ],
            filter_string: `(status__notexact='approved')&&(username__exact='${CUSTOMER_USERNAME}')&&(access_level__in=['owner','co_owner','editor'])`,
          };
    
        await axios.post(ENVIRONMENT.GET_PROJECT_BATCH, payload).then((res) => {setProjects(res.data);});
        console.log("PROJECTS:",projects);
      };


    const addAsGroup = () => {
        if(!groupIdList.some(e => e.key == groupIdSearchValue)){
            setGroupIdList(groupIdList.concat([{
                key: groupIdSearchValue,
                value: groupIdSearchValue,
                title: groupIdSearchValue,
                children: [],
            }]));
            setGroupIdValue(groupIdSearchValue);
            setFieldsValue({
                group_id: groupIdSearchValue
            });
        }
    }

    const getProductCategories = () => {
        let payload = {
            "request_type": 'product_model',
        }

        axios.post(ENVIRONMENT.GET_CATEGORY_AND_PRICE, payload)
        .then(res => {
            console.log(res);
            if (res.data) {
                setPrices(res.data);
            }
        })
      }


      const loadUserRequestStatus = (confirm_request) => {
        let payload = {
          "username": localStorage.getItem('username'),
          "request_type": requestType,
          "action": "check"
        }
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, payload)
            .then(res => {
                setRequestStatus(res.data['request_allowed']);
                setCardStatus(res.data['card_status']);
                setCurrentPackage(res.data['subscription_plan']);
                setIsSubscriptionActive(res.data['is_subscription_active'])
                setRequestPrice(res.data['price']);
                getProductCategories();
                if (confirm_request != null) {
                    setConfirmRequest(true);
                }
            });
      }


    const changePrice = (e) => {
        setPrice(e.target.value);
    }


    const goToMaterialForm = () => {
        window.location.href = "/create-new-material?request_id="+requestID;
    }

    const changeHexColor = (e) => {
        console.log('Hex', e.target.value);
        setHexColor(e.target.value);
    }

    const changePantoneColor = (e) => {
        console.log('Pantone', e.target.value);
        setPantoneColor(e.target.value);
    }

    useEffect(() => {
        if (modelType == 2) {
            setIsConfigurable(true);
        } else {
            setIsConfigurable(false);
        }
    },[modelType]);


    const modifyAttachmentsStatus = () => {
        if(rawAttchments){
            setRawAttchments(false);
        } else {
            setRawAttchments(true);
        }
    }

    const addComponents = (value) => {
        setComponentArray(value);
    }

    const onChangeModelType = (e) => {
        setModelType(e.target.value);
    }

    const changeModelType = () => {
        setModelType(1);
        forceUpdate();
    }

    const addAsCategory = () => {

        if(!categoriesData.some(e => e.key == categorySearchValue)){
            setCategoriesData(categoriesData.concat([{
                key: categorySearchValue,
                value: categorySearchValue,
                title: categorySearchValue,
                children: [],
            }]));
            setCategoryValue(categorySearchValue);
            setFieldsValue({
                category: categorySearchValue
            });
        }
    }


    const makeConfigChange = (e) => {
        setMakeConfig(e.target.checked);
        if (e.target.checked) {
            setModelType(2);
            forceUpdate();
        }
    }

    const openMaterialModal = () => {
        setSearchValue('')
        setMaterialModal(true);
        let selected_materials = [...finalSelectedMaterials];
        setSelectedMaterial(selected_materials);
    }

    const cancelMaterialModal = () => {
        setSelectedMaterial([]);
        setMaterialModal(false);
        forceUpdate();
    }

    const addMaterials = () => {
        setFinalSelectedMaterials(selectedMaterial);
       console.log(selectedMaterial)
        setMaterialModal(false);
    }

    const selectMaterialImages = (material) => {

        if (selectedMaterial.includes(material.id)) {
            let material_arr = selectedMaterial.filter(id => {return id != material.id});
            setSelectedMaterial(material_arr);
            console.log('Selected Materials Remove',material_arr);
        } else {
            let material_arr = selectedMaterial;
            material_arr.push(material.id);
            setSelectedMaterial(material_arr);
            console.log('Selected Materials Add',material_arr);
        }

        forceUpdate();
    }

    const removeMaterials = (material) => {
        let material_arr = selectedMaterial.filter(id => {return id != material.id});
        setSelectedMaterial(material_arr);
        setFinalSelectedMaterials(material_arr);
    }

    const isModelUploadedandValid = () =>{
        if(modelFileList.length > 0 && uploadError == ''){
            return true;
        } else {
            return false;
        }
    }

    const controlLicenseDetailPanels = (value) => {
        if(value){
            setPublicLicensePanel(["1"]);
            setPrivateLicensePanel(["1"]);
        }
        else{
            setPublicLicensePanel();
            setPrivateLicensePanel();
        }
    }

    const changePrivateLicenseChecked = (value) => {
        setPrivateLicenseChecked(value);
        controlLicenseDetailPanels(!value);
        if(value){
            setPublicLicenseChecked(!value);
        }
    }

    const changePublicLicenseChecked = (value) => {
        setPublicLicenseChecked(value);
        controlLicenseDetailPanels(!value);
        if(value){
            setPrivateLicenseChecked(!value);
        }
    }

    const changeForSale = (value) => {
        setForSale(value);
        if(value){
            setForCreativeCommon(!value);
        }
    }

    const changeForCreativeCommon = (value) => {
        setForCreativeCommon(value);
        if(value) {
            setForSale(!value);
        }
    }

    const handleRedirection = () =>{
        let onboarding = new URLSearchParams(window.location.search).get("onboarding");
        let room_state = new URLSearchParams(window.location.search).get("room_state");

        let step_no = '0';
        if(onboarding){
            if (room_state != undefined) {
                step_no = '4'
            } else {
                step_no = '14';
            }
            props.history.push({
                pathname: '/home',
                search: `?step_no=${step_no}&product_state=added&${props.location.search}`
            });
        } else {
            window.location.href = "/home";
        }

    }

    const handleCancel = () =>{
        if(redirectionPath){
            props.history.push({
                pathname: redirectionPath
            });  
        } else {
            props.history.push({
                pathname: '/home'
            }); 
        }
    }

    const updatePaymentDetailsOnFailure = () => {
        window.addEventListener('card_saved', (e) => {
            let action = e.detail.action;
            setCardSaveFlowTriggered(false);
            if (action == 'subscription_update') {
                upgradeSubscriptionPlan();
            }
            if (action == 'existing_product_model') {
                confirmPaymentAndSubmit();
            }

        }, false);

        setPaymentFailureModal(false);
        let child_window = window.open('/save-card?action=existing_product_model', '', "width=600,height=600");
        let refreshInterval = setInterval(() => {
            if(child_window.closed) {
            setCardSaveFlowTriggered(false);
            clearInterval(refreshInterval);
            }
        }, 500);
        setCardSaveFlowTriggered(true);
    }

    useEffect(() => {
        if((props.productReducer.isCreated == true || props.productReducer.isUpdated == true) && submittingCreateRequest == true) {
            if (props.productReducer.product && props.productReducer.product.product_id) {
                if (componentArray.length > 0 && modelType == 2) {
                    let product_id = props.productReducer.product.product_id;
                    if (isConfigurable) {
                        let paylod_request_id = product_id;
                        axios.post(ENVIRONMENT.CREATE_REQUEST_ID, {"product_id" : paylod_request_id})
                        .then(res => {
                            setRequestID(res.data.confiq_req_id);
                        });
                    }
                    componentArray.map((cmp, index) => {
                        let used_library = 0;

                        let payload = {
                            product_id: product_id,
                            name: cmp,
                            using_material_library: used_library
                        }
                        axios.post(ENVIRONMENT.PRODUCT_COMPONENT_CREATE, payload)
                        .then(res => {

                            if (index == (componentArray.length - 1)) {
                                setConfigurableModal(true);
                            }

                        });
                    });
                } else {
                    setRequestSubmitted(true);
                }
            }
        }
        if((props.productReducer.isCreated == true || props.productReducer.isUpdated == true) && submittingSaveRequest == true){
            
            let cookie_name = Utilities.getCookie("save_help")
            if (!cookie_name) {
                setHelpMessageModal(true);
            } else {
                message.info('Product form saved successfully');
                handleCancel();
            }
            setSubmittingSaveRequest(false);

        }
        if(props.productReducer.error) {
            message.error(props.productReducer.error);
        }

        let data = [];
        axios.post(ENVIRONMENT.ADMIN_MATERIAL_BATCH, {
        })
        .then(res => {
            let categoriesData = Utilities.getMaterialsData( res.data );
            for (let i = 1; i <= (res.data).length; i++) {
                if( res.data[i - 1].parent_id == "")
                {
                    data.push({
                        key: res.data[i - 1]['material_id'],
                        title: res.data[i - 1]['name'],
                        value: res.data[i - 1]['name'],
                        children: categoriesData[res.data[i - 1]['material_id']],
                    });
                }

            }
            setMaterialsData(data);
        });

        const payload = {
            output: 'tree',
            disable_parents: 'true'
        };
        axios.post(ENVIRONMENT.ADMIN_CATEGORY_GET_BATCH, payload)
        .then(res => {
            setCategoriesData(res.data);
        });

        let product_id = params.id;
        if (product_id != undefined) {
            setProductID(params.id);
            if (props.productReducer.product.model_files != undefined){
                if (props.productReducer.product.model_files.length > 0) {
                    setUploadedProduct(true);
                }
                setModelFileList(props.productReducer.product.model_files);
                setUserModelType(props.productReducer.product.model_type);
                setModelingRequired(props.productReducer.product.modeling_required);
                setProductID(props.productReducer.product.product_id);
                if (props.productReducer.product.color_photos) {
                    setColorFileList(props.productReducer.product.color_photos);
                }
                if (props.productReducer.product.hex_color) {
                    setHexColor(props.productReducer.product.hex_color);
                }
                if (props.productReducer.product.pantone_color) {
                    setPantoneColor(props.productReducer.product.pantone_color);
                }
                if (props.productReducer.product.price) {
                    setPrice(props.productReducer.product.price);
                }
                setHiddenCategoryInitialValue();
                let product_photos = props.productReducer.product.product_photos;
                product_photos = props.productReducer.product.product_photos;

                if (product_photos) {
                    setProductPhotosList(product_photos);
                }
                if (product_photos && product_photos.product_photos_all != undefined) {
                    setProductPhotosList(product_photos.product_photos_all);
                    if (product_photos.product_photos_all.product_photos_all != undefined && product_photos.product_photos_all.product_photos_all) {
                        setProductPhotosList(product_photos.product_photos_all.product_photos_all);
                    }
                }

                if (props.productReducer.product.licensing_options != undefined) {
                    let licensing_options = props.productReducer.product.licensing_options;
                    if (licensing_options.license != undefined) {
                        if (licensing_options.license == 'public') {
                            setPublicLicenseChecked(true);
                            if (licensing_options.public_option != undefined) {
                                if (licensing_options.public_option == 'forSale') {
                                    setForSale(true);
                                } else {
                                    setForCreativeCommon(true);
                                    if (licensing_options.creative_common != undefined) {
                                        setCreativeCommon(licensing_options.creative_common);
                                    }
                                }
                            }

                        }
                        else {
                            setPrivateLicenseChecked(true);
                        }
                    }
                }
            }

            let product_materials = props.productReducer.product.material_files;
            if (product_materials != undefined && product_materials.length > 0) {
                setMaterialPhotosList(product_materials);
            }

            if(props.productReducer.product.group_id){
                setEnableGroupIDField(true);
            }
        }

        for(let i=0;i<5;i++) {
            setTimeout(() => {
                setFieldsValue(patchProduct(props.productReducer.product));
                setProductName(props.productReducer.product.product_name);
                let parent_category = props.productReducer.product.parent_category;
                if (parent_category && FileConstants.HIDDEN_CATEGORY[parent_category]) {
                    setFieldsValue({
                        category: parent_category
                    });
                }
            },1000);
        }
    }, [props.productReducer]);

    const setHiddenCategoryInitialValue = () => {
        let category = props.productReducer.product.category;
        let parent_category = props.productReducer.product.parent_category
        if (category) {
            setCategoryValue(category);
            if (FileConstants.HIDDEN_CATEGORY[category]) {
                setHiddenCategoryFilelist(props.productReducer.product.category_attachment || []);
                setHiddenFile(props.productReducer.product.category_attachment || []);
                let default_category = true;
                FileConstants.HIDDEN_CATEGORY[category].map((cat,index) => {
                    if (cat == props.productReducer.product.category_type) {
                        default_category = false;
                        setHiddenCategoryIndex(index);
                    }
                })
                if (default_category) {
                    setHiddenCategoryIndex(1);
                }
            } else if (parent_category && FileConstants.HIDDEN_CATEGORY[parent_category]) {//this will set Bed Frame only which is a hidden category in the table
                setCategoryValue(parent_category);
                setHiddenCategoryFilelist(props.productReducer.product.category_attachment || []);
                setHiddenFile(props.productReducer.product.category_attachment || []);
                let default_category = true;
                FileConstants.HIDDEN_CATEGORY[parent_category].map((cat,index) => {
                    if (cat == props.productReducer.product.category) {
                        default_category = false;
                        setHiddenCategoryIndex(index);
                    }
                })
                if (default_category) {
                    setHiddenCategoryIndex(1);
                }
            }
        }
    }


    useEffect(()=> {
        resetFields();
    }, []);

    useEffect(() => {
        if (productID && (productID != -1)) {
            let payload = {
                product_id: productID
            };
            axios.post(ENVIRONMENT.PRODUCT_MATERIAL_GET_BATCH ,payload)
            .then(res => {
                let response = res.data;
                if (response) {
                    response = response.body;
                    let material_ids = [];
                    response && response.map((material) => {
                        material_ids.push(material.material_id)
                    });
                    setFinalSelectedMaterials(material_ids);
                    setMaterialsReturned(material_ids);
                    console.log('Materials',material_ids)
                }
            });
        }
    },[productID]);

    const onChangeProductName = (e) => {
        setProductName(e.target.value);
    }

    const cancelPaymentConfirmation = () => {
        setPaymentConfirmationModal(false);
        setSubmittingCreateRequest(false);
    }

    const onChangeCategoryValue = (value) => {
        setCategoryValue(value);
    }

    const checkIfValidAgain = (e) => {
        if(e != undefined) {
            e.preventDefault();
        }
        if(formValidationFailed){
            props.form.validateFields((err) => {
                if (!err){
                    if(validateCustomFields()){
                        setFormValidationFailed(false);
                    }
                }
            });

        }
    }

    const addCategoryOption = (e) => {
        if(e.keyCode == 13){
            if(!categoriesData.some(e => e.key == categorySearchValue)){
                setCategoriesData(categoriesData.concat([{
                    key: categorySearchValue,
                    value: categorySearchValue,
                    title: categorySearchValue,
                    children: [],
                }]));
            }
        }
    }

    const addMaterialOption = (e) => {
        if(e.keyCode == 13){
            if(!materialsData.some(e => e.key == materialSearchValue)){
                setMaterialsData(materialsData.concat([{
                    key: materialSearchValue,
                    value: materialSearchValue,
                    title: materialSearchValue,
                    children: [],
                }]));
            }
        }
    }

    useEffect(()=> {
        checkIfValidAgain();
    }, [publicLicenseChecked, privateLicenseChecked, forSale, forCreativeCommon, creativeCommon])

    const validateCustomFields = () => {
        if (!publicLicenseChecked && !privateLicenseChecked){
            return false;
        }
        else if (publicLicenseChecked && (!forSale && !forCreativeCommon) ){
            return false;
        }
        else if (publicLicenseChecked && forCreativeCommon && creativeCommon == '') {
            return false;
        }
        else{
            return true;
        }
    }

    const getLicensingOptions = () => {
        let licensing_options = {
            license: publicLicenseChecked?'public':'private'
        }

        if(publicLicenseChecked){
            licensing_options.public_option = forSale?'forSale':'forCreativeCommon';
            if(forCreativeCommon){
                licensing_options.creative_common = creativeCommon;
            }
        }

        return licensing_options;
    }

    const validateFileUploads = (files) => {
        let status_done = false;
        if (files != undefined) {
            for(var file of files) {
                if (file['status'] == 'uploading') {
                    return 'uploading';
                }
                else if (file['status'] == 'error') {
                    return 'error';
                }
                else if (file['status'] == 'done') {
                    status_done = true;
                }
            }
        }
        if (status_done) {
            return 'done'
        }
        return 'not_started';
    }

    const onChangeColorSelectionType = (e) => {
        setColorSelectionType(e.target.value);
    }

    const handleChangeColorSelection = info => {
        let fileList = [...info.fileList];
        setColorFileList(fileList);
        console.log(info)

        if (info.file.status === "done") {
            setHasError(false);
            setUploadError('');
            setUploading(false);
            setColorFileList(fileList);
        } else if (info.file.status === "error") {
            setUploadError(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setUploadError(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            }  else if (info.file.error.code.includes('Network')) {
                setUploadError(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }
            setHasError(true);
            setUploading(false);
        }
    }

    const handleChangeHiddenCategoryFile = info => {
        let fileList = [...info.fileList];
        setHiddenCategoryFilelist(fileList);
        console.log(info)

        if (info.file.status === "done") {
            setHasError(false);
            setErrorMessage('');
            setUploading(false);
            let listWithoutImage = _.cloneDeep(fileList);            ;
            listWithoutImage.map((file,index) => {
                delete listWithoutImage[index].thumbUrl
            })
            console.log(listWithoutImage);
            setHiddenFile(listWithoutImage);
        } else if (info.file.status === "error") {
            setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            }  else if (info.file.error.code.includes('Network')) {
                setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }
            setHasError(true);
            setUploading(false);
            setUploadedProduct(false);
        }
    }

    const validateModel = e => {
        if(archiveValidationError == '' && uploadedProduct == true){
            setAddedProduct(true);
        }
    };


    const handleSubmit = e => {
        props.form.validateFields((err, values) => {
            console.log(values);
            if (!err) {

                if(validateCustomFields()){
                    let uploadStatus = validateFileUploads(values['model_files']);
                    let materialUploadStatus = validateFileUploads(values['material_files']);
                    let rawFileStatus = validateFileUploads(values['raw_files']);
                    let referenceImageStatus = validateFileUploads(values['reference_photos']);
                    let hiddenFileStatus = validateFileUploads(hiddenCategoryFilelist);
                    let colorPhotoStatus = validateFileUploads(colorFileList);

                    let productPhotoStatus = '';
                    let product_id = params.id;
                    values['product_photos'] = productPhotosList;
                    productPhotoStatus = validateFileUploads(values['product_photos']);
                    let hidden_category_error = '';
                    if (hiddenCategoryIndex == 2 && hiddenCategoryFilelist.length == 0) {
                        hidden_category_error = FileConstants.REQUIRED_FIELD_ERRORS['bedding_category']
                        setUploadError(hidden_category_error);
                    }
                    if(uploadStatus == 'not_started'){
                        setFormValidationFailed(true);
                    }
                    if (publicLicenseChecked && forSale && (price == -1 || !price)) {
                        setPriceError('Please enter a price')
                    }
                    else if (uploadStatus == 'uploading' || productPhotoStatus == 'uploading' || materialUploadStatus == 'uploading' || rawFileStatus == 'uploading' || referenceImageStatus == 'uploading' || hiddenFileStatus == 'uploading' || colorPhotoStatus == 'uploading') {
                        setUploadError('Uploads are in progress, please wait till file uploads are completed.')
                    }
                    else if (uploadStatus == 'error' || productPhotoStatus == 'error' || materialUploadStatus == 'error' || rawFileStatus == 'error' || referenceImageStatus == 'error'  || hiddenFileStatus == 'error' || colorPhotoStatus == 'error') {
                        setUploadError('Error occured in uploading bundle, please re-upload your model bundle.')
                    }
                    else if(archiveValidationError != ''){
                        setUploadError(archiveValidationError);
                    }
                    else if (hidden_category_error == '' && modelFileList && modelFileList.length > 0){
                        setUploadError('');
                        setPriceError('');

                        let product_form_data = formatProduct(values);
                        product_form_data.product_model_type = values.product_model_type == "" ? 'product' : values.product_model_type;

                        if (forSale && price && price != -1 ) {
                            product_form_data.price = price;
                        }
                        product_form_data.licensing_options = getLicensingOptions();
                        product_form_data.model_type = userModelType;
                        product_form_data.is_configurable = isConfigurable;
                        product_form_data.modeling_required = modelingRequired;
                        if (colorSelectionType == 0 && hexColor != "") {
                            product_form_data.hex_color = hexColor;
                            product_form_data.color_photos = [];
                            product_form_data.pantone_color = "";
                        } else if (colorSelectionType == 1 && pantoneColor != "") {
                            product_form_data.pantone_color = pantoneColor;
                            product_form_data.color_photos = [];
                            product_form_data.hex_color = "";
                        } else if (colorSelectionType == 2 && colorFileList && colorFileList.length > 0) {
                            product_form_data.pantone_color = "";
                            product_form_data.hex_color = "";
                            let listWithoutImage = _.cloneDeep(colorFileList);
                            listWithoutImage.map((file,index) => {
                                listWithoutImage[index]['thumbUrl'] = '';
                            })
                            product_form_data.color_photos = listWithoutImage;
                        }

                        if(!modelingRequired){
                            if(values.guidelines!= undefined && values.guidelines != ""){
                                product_form_data.modeling_required = true;
                            }
                        }
                        if(modelingRequired){
                            if(userModelType == 'ar'){
                                product_form_data.need_to_model = 'high_res';
                            } else {
                                product_form_data.need_to_model = 'ar';
                            }
                        }

                        product_form_data.uploaded_model=true;
                        let product_id = params.id;

                        if (FileConstants.HIDDEN_CATEGORY[categoryValue] && hiddenCategoryIndex != 1) {//if category is Bed and selected hidden category is with Pillows and Mattress then mark it as default
                            if (hiddenCategoryIndex !=0 ) {
                                product_form_data.hidden_category_type =  FileConstants.HIDDEN_CATEGORY[categoryValue][hiddenCategoryIndex];
                                product_form_data.hidden_category_attachment = hiddenCategoryIndex == 2 ? hiddenFile : [];
                            } else {
                                product_form_data.category =  FileConstants.HIDDEN_CATEGORY[categoryValue][hiddenCategoryIndex];
                            }

                        } else if (product_id != undefined && props.productReducer.product.category_type) {
                            product_form_data.delete_hidden_category = true;
                        }
                        let payload = {}
                        if (product_id != undefined) {
                            product_form_data.product_id = product_id;
                            console.log("Update Product");
                            if (modelType == 1 && finalSelectedMaterials.length > 0) {
                                product_form_data = createMaterialData(product_form_data);
                            }
                            let requestPayload = formatProduct(product_form_data);
                            requestPayload['username'] = localStorage.getItem('username');
                            payload = {
                                "username": localStorage.getItem('username'),
                                "request_type": requestType,
                                "update": "",
                                "action": "perform",
                                "request_payload": requestPayload,
                                "category": selectedPriceCategory,
                                "request_id": Date.now().toString()
                            }
                        } else {
                            console.log("Create Product");
                            let company_id = COMPANY_ID;
                            if(company_id != undefined) {
                                product_form_data.company_id = company_id;
                            }
                            if (modelType == 1 && finalSelectedMaterials.length > 0) {
                                let materials_to_create = finalSelectedMaterials.filter(x => materialsReturned.indexOf(x) === -1);
                                product_form_data['materials_to_create'] = materials_to_create;
                            }
                            let requestPayload = formatProduct(product_form_data);
                            requestPayload['username'] = localStorage.getItem('username');
                            payload = {
                                "username": localStorage.getItem('username'),
                                "request_type": requestType,
                                "action": "perform",
                                "request_payload": requestPayload,
                                "category": selectedPriceCategory,
                                "request_id": Date.now().toString()
                            }
                        }
                        if (FileConstants.isMSProvider && (FileConstants.MANAGED_CUSTOMER_USERNAME != null || FileConstants.MANAGED_COMPANY_ID != null)) {
                            payload["request_payload"]['requested_for'] = FileConstants.MANAGED_CUSTOMER_USERNAME
                        }   
                        if (requestStatus == 'allowed' || requestStatus == 'always_allowed') {
                            setSubmittingCreateRequest(true);
                            axios.post(ENVIRONMENT.PAYMENT_GATEWAY, payload)
                            .then(res => {
                            if (res.data.status!=undefined && res.data.status == 1) {
                                setRequestSubmitted(true);
                            } else {
                                setPaymentFailureMessage(res.data.error_message);
                                setPaymentFailureModal(true);
                                setPaymentConfirmationModal(false);
                            }
                            });
                        }

                        if (requestStatus == 'payment_required') {
                            setProductCreatePayload(payload);
                            setPaymentConfirmationModal(true);
                        }

                        if (requestStatus == 'update_subscription'){
                            // update subscription plan modal here
                            setProductCreatePayload(payload);
                            setSubscriptionPackageUpdateModal(true)
                        }

                        if (requestStatus == 'not_allowed') {
                            setProductCreatePayload(payload);
                            setSubscriptionPackageUpdateModal(true);
                        }
                    }

                }
                else {
                    setFormValidationFailed(true);
                }
            }
            else {
                setFormValidationFailed(true);
            }
        });
    };

    const initiateConfirmation = () => {
        window.addEventListener('card_saved', (e) => {
            let action = e.detail.action;
            setCardSaveFlowTriggered(false);
            if (action == 'subscription_update') {
                upgradeSubscriptionPlan();
            }
            if (action == 'existing_product_model') {
                confirmPaymentAndSubmit();
            }

        }, false);

        if (cardStatus == 1) {
            confirmPaymentAndSubmit();
        }
        if (cardStatus == 0) {
            let child_window = window.open('/save-card?action=existing_product_model', '', "width=600,height=600");
            let refreshInterval = setInterval(() => {
              if(child_window.closed) {
                setCardSaveFlowTriggered(false);
                clearInterval(refreshInterval);
              }
            }, 500);
            setCardSaveFlowTriggered(true);

        }
    }

    const initiateSubscriptionConfirmation = () => {

        window.addEventListener('card_saved', (e) => {
            let action = e.detail.action;
            setCardSaveFlowTriggered(false);
            if (action == 'subscription_update') {
                upgradeSubscriptionPlan();
            }
            if (action == 'existing_product_model') {
                confirmPaymentAndSubmit();
            }

        }, false);

        if (cardStatus == 1) {
            upgradeSubscriptionPlan();
        }
        if (cardStatus == 0) {
            setIsSubscriptionUpdateRequest(true);
            let child_window = window.open('/save-card?action=subscription_update', '', "width=600,height=600");
            let refreshInterval = setInterval(() => {
              if(child_window.closed) {
                setCardSaveFlowTriggered(false);
                clearInterval(refreshInterval);
              }
            }, 500);
            setCardSaveFlowTriggered(true);
        }
    }

    const upgradeSubscriptionPlan = () => {
        let payload ={
            username: localStorage.getItem('username'),
            subscription_type: subscriptionPlan
          }
          console.log('Subscription payload',payload);
          setButtonLoader(true);
          axios.post(ENVIRONMENT.UPGRADE_SUBSCRIPTION,payload)
          .then( (response) => {
            console.log(response.data);
            setButtonLoader(false);
            setSubscriptionPackageUpdateModal(false);
            setPaymentConfirmationModal(false);
            message.success('Your subscription plan has been successfully upgraded.');
            loadUserRequestStatus('confirm');
        })
          .catch((error) => {
          });

    }

    useEffect(() => {
        if (confirmRequest) {
          initiateConfirmation();
        }
     },[confirmRequest]);

    const confirmPaymentAndSubmit = () => {
        setSubmittingCreateRequest(true);
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, productCreatePayload)
            .then(res => {
                if (res.data.status!=undefined && res.data.status == 1) {
                    console.log(res);
                    setProductID(res.product_id);
                    setRequestSubmitted(true);
                    setSubscriptionPackageUpdateModal(false);
                    setSubmittingCreateRequest(false);
                    setPaymentConfirmationModal(false);
                }
                else {
                    setPaymentFailureMessage(res.data.error_message);
                    setPaymentFailureModal(true);
                    setPaymentConfirmationModal(false);
                }
            });
    }

    const saveRequestIntermedietely = (callback = null) => {
        setSubmittingCreateRequest(true);
        console.log(JSON.stringify(productCreatePayload))
        let payload = {
            'request_type': requestType,
            'request_body': productCreatePayload,
            'processed': false
        }

        if (isSubscriptionUpdateRequest) {
            payload.subscription_update_body = {
                "subscription_type": subscriptionPlan,
                "username": CUSTOMER_USERNAME
            }
        }

        if (paymentRequestID == -1) {
            axios.post(ENVIRONMENT.SAVE_REQUEST_INTERMEDIATE, payload)
                .then(res => {
                    console.log(res);
                    setSubmittingCreateRequest(false);
                    setPaymentConfirmationModal(false);
                    if (callback!= null)
                    {
                    callback(res.data[0].request_id,requestType);
                    }
                });
        } else {
            setSubmittingCreateRequest(false);
            setPaymentConfirmationModal(false);
            if (callback!= null)
            {
            callback(paymentRequestID,requestType);
            }
        }
    }



    const handleSave = () => {
        let values = props.form.getFieldsValue();
        console.log(values,'Save Values');
        console.log('In handleSave()')
        if(values['product_name'] != "" && values['product_name'] != undefined){
            setUploadError('');
            let uploadStatus = 'not_started';
            values['model_files'] = modelFileList;
            uploadStatus = validateFileUploads(values['model_files']);

            let materialUploadStatus = 'not_started';
            values['material_files'] = materialPhotosList;
            materialUploadStatus = validateFileUploads(values['material_files']);

            let rawFileStatus = 'not_started';
            values['raw_files'] = rawFileList;
            rawFileStatus = validateFileUploads(values['raw_files']);

            let colorPhotoStatus = 'not_started';
            values['color_photos'] = colorFileList;
            colorPhotoStatus = validateFileUploads(colorFileList);

            let productPhotoStatus = '';
            values['product_photos'] = productPhotosList;
            productPhotoStatus = validateFileUploads(values['product_photos']);
            if (uploadStatus == 'uploading' || productPhotoStatus == 'uploading' || materialUploadStatus == 'uploading' || rawFileStatus == 'uploading' || colorPhotoStatus == 'uploading') {
                setUploadError('Uploads are in progress, please wait till file uploads are completed.')
            }
            else if (uploadStatus == 'error' || productPhotoStatus == 'error' || materialUploadStatus == 'error' || rawFileStatus == 'error' || colorPhotoStatus == 'error') {
                setUploadError('Error occured in uploading bundle, please re-upload your model bundle.')
            }
            else if(archiveValidationError != ''){
                setUploadError('');
            }
            else {
                setUploadError('');

                let product_form_data = formatProduct(values);
                product_form_data.product_model_type = values.product_model_type == "" ? 'product' : values.product_model_type;

                if (forSale && price && price != -1) {
                    product_form_data.price = price;
                }
                product_form_data.licensing_options = getLicensingOptions();
                product_form_data.model_type = userModelType;
                product_form_data.modeling_required = modelingRequired;
                if(!modelingRequired){
                    if(values.guidelines!= undefined && values.guidelines != ""){
                        product_form_data.modeling_required = true;
                    }
                }
                if(modelingRequired){
                    if(userModelType == 'ar'){
                        product_form_data.need_to_model = 'high_res';
                    } else {
                        product_form_data.need_to_model = 'ar';
                    }
                }
                product_form_data.uploaded_model=true;
                setSubmittingSaveRequest(true);
                let product_id = params.id;
                if (FileConstants.HIDDEN_CATEGORY[categoryValue] && hiddenCategoryIndex != 1) {//if category is Bed and selected hidden category is with Pillows and Mattress then mark it as default
                    if (hiddenCategoryIndex != 0 ) {
                        product_form_data.hidden_category_type =  FileConstants.HIDDEN_CATEGORY[categoryValue][hiddenCategoryIndex]
                        product_form_data.hidden_category_attachment = hiddenCategoryIndex == 2 ? hiddenFile : [];
                    } else {
                        product_form_data.category =  FileConstants.HIDDEN_CATEGORY[categoryValue][hiddenCategoryIndex]
                    }
                } else if (product_id != undefined && props.productReducer.product.category_type) {
                    product_form_data.delete_hidden_category = true;
                }
                if (colorSelectionType == 0 && hexColor != "") {
                    product_form_data.hex_color = hexColor;
                    product_form_data.color_photos = [];
                    product_form_data.pantone_color = "";
                } else if (colorSelectionType == 1 && pantoneColor != "") {
                    product_form_data.pantone_color = pantoneColor;
                    product_form_data.color_photos = [];
                    product_form_data.hex_color = "";
                } else if (colorSelectionType == 2 && colorFileList && colorFileList.length > 0) {
                    product_form_data.pantone_color = "";
                    product_form_data.hex_color = "";
                    let listWithoutImage = _.cloneDeep(colorFileList);
                    listWithoutImage.map((file,index) => {
                        listWithoutImage[index]['thumbUrl'] = '';
                    })
                    product_form_data.color_photos = listWithoutImage;
                }
                if (product_id != undefined) {
                    product_form_data.product_id = product_id;
                    console.log("Update Product");
                    if (modelType == 1 && finalSelectedMaterials.length > 0) {
                        product_form_data = createMaterialData(product_form_data);
                    }
                    props.updateProduct(formatProduct(product_form_data, true));
                }
                else {
                    console.log("Create Product");
                    let company_id = COMPANY_ID;
                    if(company_id != undefined) {
                        product_form_data.company_id = company_id;
                    }
                    if (modelType == 1 && finalSelectedMaterials.length > 0) {
                        let materials_to_create = finalSelectedMaterials.filter(x => materialsReturned.indexOf(x) === -1);
                        product_form_data['materials_to_create'] = materials_to_create;
                    }
                    props.createProduct(formatProduct(product_form_data, true));
                }
            }
        }
        else{
            setUploadError('You must enter atleast the product Name to Save the progress.')
        }
    }


    const createMaterialData = (form) => {
        let product = {...form}
        let payload_entry = {};
        let payload = [];
        let materials_to_create = finalSelectedMaterials.filter(x => materialsReturned.indexOf(x) === -1);
        materials_to_create.map(id =>  {
            payload_entry = {};
            payload_entry.material_id = id;
            payload_entry.product_id = params.id;
            payload.push(payload_entry);
        });
        //materials to be deleted
        var materials_to_delete = materialsReturned.filter(x => finalSelectedMaterials.indexOf(x) === -1);
        let payload_delete_entry = {};
        let payload_delete = [];
        materials_to_delete.map(id => {
            payload_delete_entry = {};
            payload_delete_entry.material_id = id;
            payload_delete_entry.product_id = params.id;
            payload_delete.push(payload_delete_entry);
        });

        product.materials_to_create = payload;
        product.materials_to_delete = payload_delete;

        return product;
    }

    const formatProduct = (form, save_request=false) => {
        let product = {...form};
        if(save_request == true)
            product.model_status = 1;
        else
            product.model_status = 2;
        if(!enableGroupIDField)
            product.group_id = '';
        product.components = [];
        let selected_material = finalSelectedMaterials;
        let library_selected = [];

        if(form.components && modelType == 1 && componentArray.length > 0) {

                componentArray.map((cmp) => {
                    let comp_images = _.cloneDeep(form[`${cmp}_images`]);
                    if (comp_images == undefined) {
                        comp_images = [];
                    }
                    if (finalSelectedMaterials && finalSelectedMaterials[cmp]) {
                       library_selected[cmp] = true;
                       comp_images = finalSelectedMaterials[cmp].material_files;
                    } else {
                        library_selected[cmp] = false;
                    }
                    let existingFiles = props.productReducer.product.components && props.productReducer.product.components.find((ele) => ele.componentName == cmp) || null;
                    if (form[cmp]) {
                        product.components.push({
                            componentName: cmp,
                            componentImages: comp_images,
                            componentMaterial: form[`${cmp}_material`]

                        });
                    } else {
                        product.components.push({
                            componentName: cmp,
                            componentImages: comp_images,//existingFiles ? existingFiles.componentImages : [],
                            componentMaterial: form[`${cmp}_material`] || ''
                        });
                    }
            });
        }

        product.product_photos = {
            product_photos_all: productPhotosList
        }
        product.raw_files = rawFileList

        if (submitType == 'continue') {
            product.model_status = 2;
        }
        product.user_type = "customer"
        console.log('Final Product data',product);

        return product;
    }

    const patchProduct = (product) => {
        let updatedProduct = {components: []};
        Object.keys(product).forEach((key) => {
            if(key != 'components') {
                updatedProduct[key] = product[key];
            }
        });
        product.components && product.components.forEach((component) => {
            updatedProduct.components.push(component.componentName);
            updatedProduct[component.componentName] =component.componentImages;
            // updatedProduct[`${component.componentName}_color_name`] =component.componentColor;
            // updatedProduct[`${component.componentName}_similar_color`] =component.componentSimilarColor;
            updatedProduct[`${component.componentName}_material`] =component.componentMaterial;
        });
        if(!updatedProduct.dimensions) {
            updatedProduct.dimensions = "inches";
        }
        if(!enableGroupIDField && !product.group_id){
            updatedProduct.group_id = '';
        }
        return updatedProduct;
    }

    const productImages = e => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const handleChange = info => {
        let fileList = [...info.fileList];
        fileList = fileList.slice(-1);
        setModelFileList(fileList);
        setUploading(true);
        console.log(info.file);

        if (info.file.status === "done") {
            setHasError(false);
            setErrorMessage('');
            setUploadedProduct(true);
            setUploading(false);
        } else if (info.file.status === "error") {
            setErrorMessage(info.file.error.code.concat('.   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            }  else if (info.file.error.code.includes('Network')) {
                setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }
            setHasError(true);
            setUploading(false);

        }
    };

    const handleChangeRawFiles = info => {
        let fileList = [...info.fileList];
        setRawFileList(fileList);

        if (info.file.status === "done") {
            setHasError(false);
            setErrorMessage('');
            setUploading(false);
        } else if (info.file.status === "error") {
            setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            }  else if (info.file.error.code.includes('Network')) {
                setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }
            setHasError(true);
            setUploading(false);

        }
    };

    const handleChangeProductPhotos = info => {
        let fileList = [...info.fileList];
        setProductPhotosList(fileList);
        setUploading(true);
        if (info.file.status === "done") {
            setHasError(false);
            setErrorMessage('');
            setUploading(false);
        } else if (info.file.status === "error") {
            setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            }  else if (info.file.error.code.includes('Network')) {
                setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }
            setHasError(true);
            setUploading(false);

        }
    };

    const handleChangeMaterialPhotos = info => {
        let fileList = [...info.fileList];
        setMaterialPhotosList(fileList);
        if (info.file.status === "done") {
            setHasError(false);
            setErrorMessage('');
            setUploading(false);
        } else if (info.file.status === "error") {
            setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            }  else if (info.file.error.code.includes('Network')) {
                setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }
            setHasError(true);
            setUploading(false);

        }
    };

    const triggerProductArchive = () => {
        $('#product_info_model_files').trigger('click');
        let button = document.getElementById('product_info_model_files');
        button.disabled = false;
    }

    const triggerProductPhotoUpload = () => {
        $('#product_info_product_photos').trigger('click');
        let button = document.getElementById('product_info_product_photos');
        button.disabled = false;
    }

    const triggerMaterialPhotoUpload = () => {
        $('#product_info_material_files').trigger('click');
        let button = document.getElementById('product_info_material_files');
        button.disabled = false;
    }

    const triggerProductRawFiles = () => {
        $('#product_info_raw_files').trigger('click');
        let button = document.getElementById('product_info_raw_files');
        button.disabled = false;
    }

    const onChangeHiddenCategory = (e) => {
        setHiddenCategoryIndex(e.target.value);
    }

    const changeSearchValue = (e) => {
        setSearchValue(e.target.value);
    }


    const formItems =
        <Form.Item
        name = "reference_urls"
        className="manrope f-14 black-55"
        label ={<span>Reference URL(s)&nbsp;
        <Tooltip  title={<span className="manrope f-12 white">Please type public URL(s) and press 'Enter' - if the link is only available to you via a login, please take a screenshot and upload to Raw Files section in the coming steps.</span>}>
        <InfoCircleOutlined />
        </Tooltip>
        </span>}
        style={{marginTop:"19px"}}
        colon={false}
        required={false}
        >
            {getFieldDecorator(`reference_urls`, {
                rules: [
                    {
                        message: "Please add reference url or delete this field.",
                        type: 'array'
                    },
                ],
            })(
            <Select notFoundContent={null} className="manrope f-10 black-55 selection-popup" mode="tags" placeholder="Please enter reference url(s) that you would like us to have a look at for your product">
            </Select>)}
        </Form.Item>;


    let highResModelApplicationFields = FileConstants.highResModelApplications.map((ele, index) => {
        return <div style={{backgroundColor: '#FAFAFA', padding: 6, margin: 6}}>
                <span style={{color: "rgb(183 178 178)", fontWeight: 600, fontSize: 12}}>{ele.display}</span>
        </div>
    })

    let arModelApplicationFields = FileConstants.arModelApplications.map((ele, index) => {
        return <div style={{backgroundColor: '#FAFAFA', padding: 6, margin: 6}}>
                <span style={{color: "rgb(183 178 178)", fontWeight: 600, fontSize: 12}}>{ele.display}</span>
        </div>
    })

    let highResUseCasesFields = FileConstants.highres_usecases.map( (ele, index) => {
        return <div style={{backgroundColor: '#FAFAFA', padding: 12,margin: 6}}>{ele}</div>
    });

    let arUseCasesFields = FileConstants.ar_usecases.map( (ele, index) => {
        return <div style={{backgroundColor: '#FAFAFA', padding: 12,margin: 6}}>{ele}</div>
    });


    return <div>
        {props.productReducer.isLoading? <div className="text-center"> <LoadingOutlined type="sync" style={{fontSize: 24}} spin /> </div>: "" }
        <Form className="input-form" onChange={() => checkIfValidAgain()} onSubmit={handleSubmit} style={{paddingTop:"20px"}}>

        <Steps className='step-class' style={{marginBottom:10}} size="small" current={ addedProduct == true ? 1 : 0}>
            <Step className={`manrope f-14 ${addedProduct == false ? `blue`: `green`}`} title="Submit Model File" />
            <Step className={`manrope f-14 ${addedProduct == false ? `grey-6b`: `blue`}`} title="Submit Model Details" />
        </Steps>

                <Row>
                <div style={addedProduct ? {display:"none"} : {display:'contents'}}>
                <Col  span={24} style={{marginTop:5}}>
                        <Form.Item name ="model_files" className="manrope f-14 black-55" label={`Model Archive (3D Model File)`}  colon={false} style={archiveValidationError == '' ? {display: 'inline-block', width: "100%",cursor:"pointer"}:{display: 'none', width: "100%",cursor:"pointer"}} onClick={triggerProductArchive}>
                            {getFieldDecorator("model_files", {
                                valuePropName: "model_files",
                                getValueFromEvent: e => e && e.fileList,
                                rules: [!addedProduct?{ required: true, message: 'Please upload model archive' }:{required:false}],
                            })(
                                <Upload
                                    accept=".zip"
                                    multiple={false}
                                    {...(Constants.getUploadProps(props.productReducer.product.platform))}
                                    id="max-upload"
                                    openFileDialogOnClick={false}
                                    // openFileDialogOnClick={!(validateFileUploads(modelFileList) == 'error')}
                                    listType="text"
                                    onRemove={file => {
                                        let button = document.getElementById('product_info_model_files');
                                        button.disabled = true;
                                        setModelFileList([]);
                                        setHasError(false);
                                        setUploading(false);
                                        setUploadedProduct(false)
                                        setErrorMessage('');

                                        if (hasError) {
                                            // abortRetry("model_file");
                                        } else {
                                            // Constants.abortUpload();
                                        }
                                        return true;
                                    }}
                                    className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-color-adjust-form-heights modal-archive-3d"
                                    beforeUpload={file => {

                                        if(!file.name.includes('.zip')){
                                            setArchiveValidationError('Currently only .zip format is supported. Kindly upload your bundle as Zip archive');
                                            return false;
                                        }

                                        var new_zip = new JSZip();
                                        new_zip.loadAsync(file)
                                        .then(function(zip) {
                                            let maxFilesCount = 0;
                                            let objFilesCount = 0;
                                            let mtlFilesCount = 0;
                                            let fbxFilesCount = 0;
                                            let glbFilesCount = 0;
                                            for(let key in zip['files']){
                                                if( key.includes('.max') && !key.includes('__MACOSX')){
                                                    maxFilesCount++;
                                                }
                                                if( key.includes('.obj') && !key.includes('__MACOSX')){
                                                    objFilesCount++;
                                                    setObjBundle(true);
                                                }
                                                if( key.includes('.fbx') && !key.includes('__MACOSX')){
                                                    fbxFilesCount++;
                                                }
                                                if( key.includes('.mtl') && !key.includes('__MACOSX')){
                                                    mtlFilesCount++;
                                                }
                                                if( key.includes('.glb') && !key.includes('__MACOSX')){
                                                    setObjBundle(true);
                                                    glbFilesCount++;
                                                }
                                            }
                                            if(maxFilesCount == 0 && objFilesCount == 0 && fbxFilesCount == 0 && glbFilesCount ==0) {
                                                setArchiveValidationError('Your zip file does not contain any 3D model, please upload the correct file ( Technical 3D tip: Your zip file should contain one .obj, .fbx, .glb or .max file)');
                                                return false;
                                            }
                                            if( (maxFilesCount+objFilesCount+fbxFilesCount+glbFilesCount) > 1) {
                                                setArchiveValidationError('Your zip file contains more than one 3D model, Please upload a zip file containing only one 3D model ( Technical 3D tip: Your zip file shoud contain only one .obj, .fbx, .glb or .max file)');
                                                return false;
                                            }
                                            if(mtlFilesCount == 0 && objFilesCount ==1){
                                                setArchiveValidationError('We did find your 3D obj model but your zip file does not contain any materials, please upload the correct file. ( Technical 3D tip: upload the .mtl file along with the obj file)');
                                                return false;
                                            }

                                            setArchiveValidationError('');
                                        });

                                        setArchiveValidationError('');
                                    }}
                                    onChange = {handleChange}
                                    fileList = {modelFileList}
                                    progress= {progress_bar}
                                >
                                    <div className="d-flex" style={{justifyContent:"center",position:"relative",left:"0",right:"0"}}>
                                        <div style={{textAlign:"center"}}>
                                            <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>
                                            <div className="ant-upload-text ant-upload-text-w manrope f-10" >
                                                <div className="up-info">Drop your product model's <b>Max Archive</b> here, or <span style={{color: "#276DD7", "line-height":"150%"}}>Browse it</span>.</div>
                                                <div className="manrope f-10">Supported formats: ZIP</div>
                                            </div>
                                            {(validateFileUploads(modelFileList) == 'error') && (<Button
                                                onClick={(event) => Constants.triggerFailedFilesUpload('product_info_model_files', modelFileList, setModelFileList, event)}
                                                disabled={modelFileList.length === 0}
                                                loading={uploading}
                                                className="retry-btn multiple manrope f-12" ghost
                                                >
                                                {uploading ? <span>Retrying...</span>  : 'Upload Failed. Click to Retry.'}
                                            </Button>)}
                                        </div>
                                    </div>
                                </Upload>
                            )}
                        </Form.Item>
                        {/* {(archiveValidationError != '')?
                        <div className="manrope f-12 red" style={{textAlign: "center", marginLeft: 12}}>{archiveValidationError}</div>
                        : ''} */}
                        {(archiveValidationError != '')?
                        <div>
                            <Row>
                                <Col span={24}>
                                    <div className='validation-uploaded-model-rejection-div'>
                                        <Card title={<div><CloseCircleOutlined/><span className='validation-uploaded-model-header-rejection'>Model Validation Failed</span></div>}>
                                            <p className='manrope f-14 w-600'>
                                                Reason :
                                            </p>
                                            <p className='manrope f-14'>
                                               {archiveValidationError}
                                            </p>
                                        </Card>
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{marginTop:10}}>
                                <Col span ={12}>
                                    <div className='justify-in-start'>
                                        <Button className='modal-okay square font-12' onClick={()=>{
                                            setArchiveValidationError('');
                                            setModelFileList([]);
                                            setHasError(false);
                                            setUploading(false);
                                            setUploadedProduct(false)
                                            setErrorMessage('');
                                        }}>
                                            Re-upload File
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        :
                        ''
                        }
                        {(validateFileUploads(modelFileList) == 'error') ?
                        <div className="manrope f-12 red" style={{ textAlign: "center", marginLeft: 12}}>{errorMessage}</div>: ''}
                    </Col>

                    </div>
                    <div style={!addedProduct ?{display:"none"} : {display:'contents'}}>
                    <Col span={24}>
                        <Form.Item className="manrope f-14 black-55" name="product_name" label="Name" colon={false}>
                            {getFieldDecorator('product_name', {
                                rules: [{ required: true, message: 'Please input product name' }],
                            })(
                                <Input onChange={onChangeProductName} className="manrope f-10 black-55"
                                    placeholder="Please type your product name"
                                />
                            )}
                        </Form.Item>
                    </Col>

                    <Col span={12}  style={{marginTop: 15, paddingRight: 10}}>
                        <Form.Item className="manrope f-14 black-55" name="brand_id" label="Item ID" colon={false}>
                            {getFieldDecorator('brand_id', {
                                rules: [{ required: false, message: "Type in your Item ID for the product" }],
                            })(
                                <Input className="manrope f-10 black-55"
                                    placeholder="Type in your Item ID for the product"
                                />
                            )}
                        </Form.Item>
                    </Col>
                    
                    <Col span={12}  style={{marginTop: 15}}>
                        <Form.Item className="manrope f-14 black-55" name="gtin" label="GTIN" colon={false}>
                            {getFieldDecorator('gtin', {
                                rules: [{ required: false, message: "Type in GTIN for the product" }],
                            })(
                                <Input className="manrope f-10 black-55"
                                    placeholder="Type in GTIN for the product"
                                />
                            )}
                        </Form.Item>
                    </Col>

                    <Col span={24}  style={{marginTop: 15}}>
                        <Form.Item name="category" className="manrope f-14 black-55 form-category" label="Category" colon={false}>
                            {getFieldDecorator('category', {
                                rules: [{ required: true, message: 'Please select or type in category' }],
                            })(

                                <TreeSelect
                                    className="manrope f-10 black-55"
                                    showSearch
                                    style={{ width: '100%'}}
                                    dropdownStyle={categoryStyle}
                                    placeholder="Select or type in your product category"
                                    treeData={categoriesData}
                                    value={categoryValue}
                                    onChange={(value) => onChangeCategoryValue(value)}
                                    onSearch={(e) => {setCategorySearchValue(e)}}
                                    // onInputKeyDown={(e) => { addCategoryOption(e)} }
                                    notFoundContent={<span  style={{textAlign: 'center'}}><h5 style={{textAlign: 'center'}} className='manrope f-14 black-55'>No Category Found</h5><p className='manrope f-14 blue' style={{textAlign: 'center',cursor: 'pointer'}} onClick={addAsCategory}><PlusOutlined/>&nbsp;Add As Category</p></span>}

                                >
                                </TreeSelect>

                            )}
                        </Form.Item>
                    </Col>

                    <Col span={12}  style={{paddingRight: 10, marginTop: 15}}>
                        <Form.Item name="product_model_type"  label="Product Type"  className="manrope f-14 black-55 form-category" colon={false}>
                            {getFieldDecorator('product_model_type', {
                                rules: [{ required: false, message: 'Please select product type.' }],
                            })(
                                <Select
                                    className="manrope f-10 black-55"
                                    showSearch
                                    placeholder="Select or type in your product type"
                                >
                                    {Object.keys(FileConstants.PRODUCT_MODEL_TYPE).map((key) => (
                                        <Select.Option
                                            key={key}
                                            className="manrope f-10 select-view"
                                            value={key}
                                        >
                                            {FileConstants.PRODUCT_MODEL_TYPE[key]}
                                        </Select.Option>
                                        ))}                                             
                                </Select>
                            )}
                        </Form.Item>
                    </Col>

                    <Col span={12}  style={{marginTop: 15}}>
                        <Form.Item name="style_category"  label="Style Category"  className="manrope f-14 black-55 form-category" colon={false}>
                            {getFieldDecorator('style_category', {
                                rules: [{ required: false, message: 'Please select style category' }],
                            })(
                                <StyleCategory />
                            )}
                        </Form.Item>
                    </Col>


                    {FileConstants.HIDDEN_CATEGORY[categoryValue] ?
                        <HiddenCategory
                        categoryValue={categoryValue}
                        hiddenCategoryIndex={hiddenCategoryIndex}
                        onChangeHiddenCategory={onChangeHiddenCategory}
                        handleChangeHiddenCategoryFile={handleChangeHiddenCategoryFile}
                        hiddenCategoryFilelist={hiddenCategoryFilelist}
                        setHiddenCategoryFilelist={setHiddenCategoryFilelist}
                        hasError={hasError}
                        setHasError={setHasError}
                        />
                    : ''}

                    <Col span={24} style={{marginTop: 15}}>
                        <Form.Item name="project"  label="Projects"  className="manrope f-14 black-55 form-category" colon={false}>
                            {getFieldDecorator('project', {
                                rules: [{ required: false, message: 'Please select project.' }],
                            })(
                                <Select
                                    className="manrope f-10 black-55"
                                    showSearch
                                    placeholder="Select or type in project name"
                                >
                                    {Object.keys(projects).map((key) => (
                                        <Select.Option
                                            key={projects[key].id}
                                            className="manrope f-10 select-view"
                                            value={projects[key].id}
                                        >
                                            {projects[key].name}
                                        </Select.Option>
                                        ))}                                             
                                </Select>
                            )}
                        </Form.Item>
                    </Col>


                    <Col span={12}>
                        {formItems}
                    </Col>

                    <Col span={12} style={{paddingLeft: 8}}>
                        <Form.Item
                            name = "tags"
                            className="manrope f-14 black-55"
                            label ={<span>Tag(s)&nbsp;</span>}
                            style={{marginTop:"19px"}}
                            colon={false}
                            required={false}
                        >
                            {getFieldDecorator(`tags`, {
                                rules: [
                                    {
                                        message: "Please add tags or delete this field.",
                                        type: 'array'
                                    },
                                ],
                            })(
                            <Select notFoundContent={null} className="manrope f-10 black-55 selection-popup" mode="tags" placeholder="Please add custom tags">
                            </Select>)}
                        </Form.Item>
                    </Col>

                    <Col span={24} style={{marginTop: 15}}>
                        <h1 style={{marginBottom: 10, fontWeight: 700}}  className="manrope f-14 black-55">Is your item part of a group of similar items? For example: They have same material</h1>
                        <Radio.Group style={{marginBottom: 5}} value={enableGroupIDField} onChange={(e)=>setEnableGroupIDField(e.target.value)}>
                            <Radio value={false}><span className="manrope f-14 black-55">No</span></Radio>
                            <Radio value={true}><span className="manrope f-14 black-55">Yes</span></Radio>
                        </Radio.Group>
                        {enableGroupIDField ?
                        <Form.Item name="group_id" className="manrope f-14 black-55 group-id-select" colon={false}>
                            {getFieldDecorator('group_id', {
                                rules: [{ required: false}],
                            })(
                                <TreeSelect
                                    showSearch
                                    disabled={!enableGroupIDField}
                                    className="manrope f-12 black-55"
                                    style={{ width: '100%', marginBottom: -20 }}
                                    dropdownStyle={categoryStyle}
                                    placeholder="Select or create your product group"
                                    value={groupIdValue}
                                    treeData={groupIdList}
                                    onChange={(value) => setGroupIdValue(value)}
                                    onSearch={(e) => {setGroupIdSearchValue(e)}}
                                    notFoundContent={<span  style={{textAlign: 'center'}}><h5 style={{textAlign: 'center'}} className='manrope f-14 black-55'>No Group Found</h5><p className='manrope f-14 blue' style={{textAlign: 'center',cursor: 'pointer'}} onClick={addAsGroup}><PlusOutlined/>&nbsp;Add As Group</p></span>}
                                >
                                </TreeSelect>

                            )}
                        </Form.Item> : ''}
                        {enableGroupIDField ?
                        <span className='manrope gray' style={{fontSize: 11}}>* The first item in the group will be approved by you before other items in the group can be created</span>
                        : ''}
                    </Col>
                        {/* {isModelUploadedandValid() ?
                            <div style={{width: '100%'}}>
                                <Col span={24}>
                                        <Form.Item  className="manrope f-14 black-55 w-600" name="guidelines" label="Are there any changes required in your uploaded model? Please mention below." colon={false}>
                                        {getFieldDecorator("guidelines", {
                                            rules: [{ required: false, message: 'Please enter additional details' }],
                                        })(
                                            <span>
                                            <Input.TextArea rows={3} className="manrope f-12 grey-77"
                                                placeholder = "Enter any changes you want us to make"
                                            />
                                            <PaperClipOutlined className="attachment-icon" onClick={() => modifyAttachmentsStatus()} />
                                            </span>
                                        )}
                                    </Form.Item>
                                </Col>
                            </div>:""
                        } */}

                    {(rawAttchments)?
                    <Col span={24}>
                        <div className="d-flex" style={{justifyContent:"center",cursor:"pointer"}} onClick={triggerProductRawFiles}>
                            <div style={{textAlign:"center",minWidth:"100%"}}>
                                <Form.Item name ="raw_files" colon={false}  className="m-b">
                                    {getFieldDecorator('raw_files', {
                                        valuePropName: 'raw_files',
                                        getValueFromEvent:  e => e && e.fileList,
                                    })(
                                        <Upload {...(Constants.getUploadProps(props.productReducer.product.platform))} multiple="true"  listType="text"
                                        className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-color"
                                        onChange = {handleChangeRawFiles}
                                        fileList = {rawFileList}
                                        accept=".zip,.jpg,.jpeg,.png,.tiff"
                                        openFileDialogOnClick={false}
                                        // openFileDialogOnClick={!(validateFileUploads(rawFileList) == 'error')}
                                        onRemove={file => {
                                            let button = document.getElementById('product_info_raw_files');
                                            button.disabled = true;
                                            const index = rawFileList.indexOf(file);
                                            const newFileList = rawFileList.slice();
                                            newFileList.splice(index, 1);
                                            setRawFileList(newFileList);
                                            newFileList.forEach((file) => {
                                                if (file.status !== "error"){
                                                    setHasError(false)
                                                }
                                                else {
                                                    setHasError(true)
                                                }
                                            })
                                            return true;
                                        }}
                                        progress= {progress_bar}
                                        >
                                            <div className="d-flex" style={{justifyContent:"center",position:"relative",left:"0",right:"0"}}>
                                                <div style={{textAlign:"center"}}>
                                                    <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>
                                                    <div className="ant-upload-text ant-upload-text-w manrope f-12" >
                                                        <div className="up-info">Drop your <span className="highlight-text">raw attachments </span>, or <span style={{color: "#276DD7"}}>Browse it</span>.</div>
                                                        <div className="manrope f-12">SUPPORTED FORMATS: JPG, PNG, ZIP, TIFF</div>
                                                    </div>
                                                {(validateFileUploads(rawFileList) == 'error') && (<Button
                                                onClick={(event) => Constants.triggerFailedFilesUpload('product_info_raw_files', rawFileList, setRawFileList, event)}
                                                disabled={rawFileList.length === 0}
                                                loading={uploading}
                                                className="retry-btn multiple manrope f-12" ghost
                                                >
                                                {uploading ? <span>Retrying...</span>  : 'Upload Failed. Click to Retry.'}
                                                </Button>)}
                                                </div>
                                            </div>
                                        </Upload>
                                    )}
                                </Form.Item>
                            </div>
                        </div>

                        {(validateFileUploads(rawFileList) == 'error') ?
                            <div className="manrope f-12 red" style={{color: "#ff4d4f", textAlign: "center", marginLeft: 12}}>{errorMessage}</div>: ''}
                        </Col>:""}

                        {/* <Row style={{width: "100%"}}>
                            <Col span={24}>
                                <h1 style={{marginTop: 29}} className="manrope f-16 black-55"><b>Select your model type</b></h1>
                                <Radio.Group value={modelType} onChange={onChangeModelType} className="model-type-radio justify-align-start" style={{marginBottom: 13,flexWrap:"unset"}}>
                                    <Radio style={{marginTop: 16,width: "50%",whiteSpace:"break-spaces"}} value={1}><span className="manrope f-14 black-55" >
                                        Simple Model <hr/> <span style={{color: "#777777"}}>Select this model type if your model doesn't have any variations.</span></span></Radio>
                                    <Radio style={{marginTop: 16,width: "50%",whiteSpace:"break-spaces",marginRight: 0}}  value={2}>
                                        <span className="manrope f-14 black-55">
                                            Configurable Model<hr/>
                                            <span style={{color: "#777777"}}>Select this model type if your model has variations and you want it to be configurable.</span>
                                            <div className="manrope f-14 blue link" style={{marginTop: 16}}>Learn more about configurable models</div>
                                        </span>
                                    </Radio>
                                </Radio.Group>
                            </Col>
                        </Row> */}
                        {modelType == 2 ?
                            <Col span={24} style={{paddingLeft: 8, paddingRight: 8}}>
                                <h1 style={{marginBottom: 8, marginTop: 29}} className="manrope f-14 black-55">
                                    <b>{isConfigurable ? "*" : ''} Specify the components your product has e.g legs, seat</b>
                                </h1>
                                <Row style={{marginBottom: 13}} className="manrope f-14 grey-77">
                                    This will help you place materials separately on each component of your product
                                </Row>

                                <Form.Item className="manrope f-12 black-55" name="components" label=""  colon={false}>
                                    {getFieldDecorator('components', {
                                        rules: [ {type: 'array',required: isConfigurable, message: 'Please specify at least one part your model has.'} ],
                                    })(
                                        <Select onChange={addComponents} className="manrope f-12 black-55" mode="tags" placeholder="Add your product components">
                                            <Option value=""></Option>
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>: ''}

                    <Col span={24}>
                        <h1 className="manrope f-14 black-55" style={{marginBottom: 10, fontWeight: 500, marginTop: 20}}><b>* Product Photos</b>&nbsp;&nbsp; <Tooltip title={<span className='manrope f-12 white'>If you don't have product images, please take screenshots from the website and upload.</span>}><InfoCircleOutlined/></Tooltip></h1>

                        <div className="d-flex" style={{justifyContent:"center",cursor:"pointer"}} onClick={triggerProductPhotoUpload}>
                            <div style={{textAlign:"center",minWidth:"100%"}}>
                                <Form.Item name ="product_photos"  colon={false} style={{display: 'inline-block', width: "100%"}}  className="m-b">
                                    {getFieldDecorator("product_photos", {
                                        valuePropName: "product_photos",
                                        getValueFromEvent: (e) => {
                                            if (e.fileList.length>=2) {
                                                return (e && e.fileList);
                                            }
                                            else {
                                                return '';
                                            }
                                        },
                                        rules: [{ required: true, message: 'You must upload at least two product photos' }],
                                    })(
                                        <Upload {...(Constants.getUploadProps(props.productReducer.product.platform))}
                                        multiple={true}
                                        onRemove={file => {
                                            let button = document.getElementById('product_info_product_photos');
                                            button.disabled = true;
                                            const index = productPhotosList.indexOf(file);
                                            const newFileList = productPhotosList.slice();
                                            newFileList.splice(index, 1);
                                            setProductPhotosList(newFileList);
                                            newFileList.forEach((file) => {
                                                if (file.status !== "error"){
                                                    setHasError(false)
                                                }
                                                else {
                                                    setHasError(true)
                                                }
                                            })
                                            return true;
                                        }}
                                        accept=".jpg,.jpeg,.png,.tiff"
                                        listType="text"
                                        openFileDialogOnClick={false}
                                        // openFileDialogOnClick={!(validateFileUploads(productPhotosList) == 'error')}
                                        className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-color-adjust-form-heights modal-archive-3d"
                                        onChange = {handleChangeProductPhotos}
                                        fileList = {productPhotosList}
                                        progress= {progress_bar}
                                        >
                                            <div className="d-flex" style={{justifyContent:"center",position:"relative",left:"0",right:"0"}}>
                                                <div style={{textAlign:"center"}}>
                                                    <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>
                                                    <div className="ant-upload-text ant-upload-text-w manrope f-10" >
                                                        <div className="up-info">Drop your photos here, or <span style={{color: "#276DD7" , "line-height":"150%"}}>Browse it</span>.</div>
                                                        <div className="manrope f-10 small">Supported formats: JPG, PNG, TIFF</div>
                                                    </div>
                                                    {(validateFileUploads(productPhotosList) == 'error') && (<Button
                                                    onClick={(event) => Constants.triggerFailedFilesUpload('product_info_product_photos', productPhotosList, setProductPhotosList, event)}
                                                    disabled={productPhotosList.length === 0}
                                                    loading={uploading}
                                                    className="retry-btn multiple manrope f-12" ghost
                                                    >
                                                    {uploading ? <span>Retrying...</span>  : 'Upload Failed. Click to Retry.'}
                                                    </Button>)}

                                                </div>
                                            </div>
                                        </Upload>
                                    )}
                                </Form.Item>
                            </div>
                        </div>
                        {(validateFileUploads(productPhotosList) == 'error') ?
                        <div className="manrope f-12 red" style={{ textAlign: "center", marginLeft: 12}}>
                            {errorMessage}</div>: ''}
                    </Col>
                    </div>

                </Row>

                <div style={!addedProduct ? {display:"none"} :{display:"contents"}}>
                <Collapse style={{marginTop: 40}} defaultActiveKey={['1']} className="collapse-panel bg-fc" ghost expandIconPosition={'left'}>
                    <Panel header={<span className="manrope f-14 grey-77 w-700">Add Colors of your Product Model</span>} key="1">
                        <Row>
                            <Col span={12} style={{ paddingRight: 8,paddingTop: 7}}>
                                <Form.Item className="manrope f-14 black-55" name="color_name" label="Color" colon={false}>
                                    {getFieldDecorator('color_name', {
                                        rules: [{ required: false, message: 'Please enter color name' }],
                                    })(
                                        <Input className="manrope f-10 grey-77"
                                            placeholder="Enter your product's predominant colour" type="text"
                                        />
                                    )}
                                </Form.Item>

                            </Col>
                            <Col span={12} style={{paddingLeft: 8, paddingTop: 7}}>
                                <Form.Item className="manrope f-14 black-55  form-category" name="similar_color"  label="Closest Color Match" colon={false}>
                                    {getFieldDecorator('similar_color', {
                                        rules: [{ required: true, message: 'Please select a color' }],
                                    })(
                                        <ColorSelect />
                                    )}
                                </Form.Item>
                            </Col>
                            <ColorSelection
                            mandatory={false}
                            changePantoneColor={changePantoneColor}
                            pantoneColor={pantoneColor}
                            changeHexColor={changeHexColor}
                            hexColor={hexColor}
                            handleChangeColorSelection={handleChangeColorSelection}
                            colorSelectionType={colorSelectionType}
                            onChangeColorSelectionType={onChangeColorSelectionType}
                            colorFileList={colorFileList}
                            setColorFileList={setColorFileList}
                            platform={props.productReducer.product.platform}
                            />
                        </Row>
                    </Panel>
                </Collapse>

                <Row>
                <Col span={24}>
                    <Collapse style={{marginTop: 32}} defaultActiveKey={['1']} className="collapse-panel bg-fc" ghost expandIconPosition={'left'}>
                        <Panel header={<span className="manrope f-14 grey-77 w-700">Add Materials for your Product Model</span>} key="1">
                            <Row style={{justifyContent:"flex-start"}}>
                                <Col span={24}>
                                    {modelType == 1 && (materialInfo && materialInfo.length > 0) ?
                                    <div style={{marginTop: 22}} className="manrope f-14 black-55">
                                        Upload or Select Materials from Material Library for your Model&nbsp;
                                        <Popover placement="bottom" trigger="hover" className="manrope f-14 black-55 centered-popup"
                                        content={<img  src={'/img/material_guideline.png'} className="sample-image-popup contain"/>}>
                                            <InfoCircleOutlined/>
                                        </Popover>
                                    </div>
                                    : ''}
                                    {modelType == 1 && (materialInfo && materialInfo.length > 0) ?
                                    ''
                                    // <div className="note-bg-specs font-14">Materials you upload here, will be added to your Material Library. You can use these materials for all your existing and future models.</div>
                                    :
                                    modelType == 2 ?
                                    <div className="note-bg-specs font-16 grey-77 pulse-effect" style={{display: 'block'}}>
                                        <div>
                                        You have chosen to make your model configurable. After you submit this form, you will be asked to upload materials for your configurable model if they are not already present in your Material Library.
                                        </div>
                                        <div className="manrope f-16 blue link" style={{marginTop: 26}} onClick={changeModelType}>Change Model Type</div>
                                    </div>: ''}
                                    {modelType == 1 && (materialInfo && materialInfo.length > 0) ?
                                    <div style={{textAlign:'left',marginTop: 8}} className="manrope f-12 blue">
                                    Make sure all material swatches that you upload here are at least 300 DPI
                                    </div>: ''}
                                </Col>
                                {modelType == 1 ?
                                <Col span={24} className="justify-space-between align-start" style={{marginTop: 16}}>
                                    <Col span={(materialInfo && materialInfo.length > 0) ? 12 : 24} >
                                        <div style={materialInfo && materialInfo.length > 0 ? borderStyle : borderStyleEmpty}>
                                        {materialInfo && materialInfo.length > 0 ?<h1 className="manrope f-14 black-55 w-700" style={{ textTransform: "capitalize" }}>Model Materials</h1>:""}
                                            <Form.Item className="manrope f-12 black-55 form-category" name="materials" label={materialInfo && materialInfo.length > 0 ? "" : "Product Model Materials"}  colon={false}>
                                                    {getFieldDecorator('materials', {
                                                        rules: [
                                                            { required: (modelType == 1), message: 'Please search and select product material', type: 'array' },
                                                        ],
                                                    })(
                                                        <TreeSelect className="manrope f-12 black-55"
                                                            showSearch
                                                            showArrow
                                                            style={{ width: '100%',height:"auto" }}
                                                            dropdownStyle={categoryStyle}
                                                            placeholder="Search and select your product materials"
                                                            allowClear
                                                            multiple
                                                            treeDefaultExpandAll
                                                            treeData={materialsData}
                                                            value={materialValue}
                                                            onChange={(value) => setMaterialValue(value)}
                                                            onSearch={(e) => {setMaterialSearchValue(e)}}
                                                            onInputKeyDown={(e) => { addMaterialOption(e)} }
                                                            notFoundContent={<span><h5>No Result Found</h5><p>Press enter to add your material</p></span>}
                                                        >
                                                        </TreeSelect>
                                                    )}
                                            </Form.Item>

                                            <div className="d-flex" style={{justifyContent:"center",cursor:"pointer"}} onClick={triggerMaterialPhotoUpload}>
                                                <div style={{minWidth:"100%",textAlign:'center'}}>
                                                <Form.Item className="m-b manrope f-14 black-55 w-700" name ="material_files" label={materialInfo && materialInfo.length > 0 ? "" :
                                                    <span className='manrope f-14 black-55 w-700'>Upload Material and Texture Swatches&nbsp;
                                                        <Popover placement="bottom" trigger="hover" className="manrope f-14 black-55 centered-popup"
                                                        content={<img  src={'/img/material_guideline.png'} className="sample-image-popup contain"/>}>
                                                            <InfoCircleOutlined/>
                                                        </Popover>
                                                    </span>}   colon={false} style={{display: 'inline-block', width: "100%",textAlign:'left'}}>
                                                        {getFieldDecorator("material_files", {
                                                            valuePropName: "material_files",
                                                            getValueFromEvent: productImages,
                                                            rules: [{ required: false, message: 'Please upload material components' }],
                                                        })(

                                                            <Upload {...(Constants.getUploadProps(props.productReducer.product.platform))}
                                                            multiple={true}
                                                            fileList={materialPhotosList}
                                                            onRemove={file => {
                                                                let button = document.getElementById('product_info_material_files');
                                                                button.disabled = true;
                                                                const index = materialPhotosList.indexOf(file);
                                                                const newFileList = materialPhotosList.slice();
                                                                newFileList.splice(index, 1);
                                                                setMaterialPhotosList(newFileList);
                                                                newFileList.forEach((file) => {
                                                                    if (file.status !== "error"){
                                                                        setHasError(false)
                                                                    }
                                                                    else {
                                                                        setHasError(true)
                                                                    }
                                                                })
                                                                return true;
                                                            }}
                                                            openFileDialogOnClick={false}
                                                            // openFileDialogOnClick={!(validateFileUploads(materialPhotosList) == 'error')}
                                                            onChange = {handleChangeMaterialPhotos}
                                                            listType="text"
                                                            accept=".jpg,.jpeg,.png, .tiff"
                                                            className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-color"
                                                            progress= {progress_bar}>

                                                                <div className="d-flex" style={{justifyContent:"center",position:"relative",left:"0",right:"0"}}>
                                                                <div style={{textAlign:"center"}}>
                                                                    <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>
                                                                    <div className="ant-upload-text ant-upload-text-w manrope f-10" >
                                                                        <div className="up-info" style={{"line-height":"150%"}} >Drop your product Materials and Texture Swatches, or <span className="highlight-text">Browse it</span>.</div>
                                                                        <div className="manrope f-10">Supported formats: JPG, PNG, TIFF</div>
                                                                    </div>

                                                                    {(validateFileUploads(materialPhotosList) == 'error') && (<Button
                                                                    onClick={(event) => Constants.triggerFailedFilesUpload('product_info_material_files', materialPhotosList, setMaterialPhotosList, event)}
                                                                    disabled={materialPhotosList.length === 0}
                                                                    loading={uploading}
                                                                    className="retry-btn multiple manrope f-12" ghost
                                                                    >
                                                                    {uploading ? <span>Retrying...</span>  : 'Upload Failed. Click to Retry.'}
                                                                    </Button>)}
                                                                </div>
                                                            </div>

                                                        </Upload>

                                                        )}
                                                    </Form.Item>
                                                </div>
                                        </div>
                                    {(validateFileUploads(materialPhotosList) == 'error') ?
                                        <div className="manrope f-12 red" style={{color: "#ff4d4f", textAlign: "center", marginLeft: 12}}>{errorMessage}</div>: ''}
                                        </div>
                                    </Col>
                                    {(materialInfo && materialInfo.length > 0) ?
                                    <Col span={12} style={{paddingLeft: 8}}>
                                        <div style={{border: "1px solid #e3e3e3 ", padding: 20, borderRadius: 4}}>
                                            <h1 className="manrope f-14 black-55 w-700" style={{ textTransform: "capitalize" }}>Select From Material Library</h1>
                                            {finalSelectedMaterials.length == 0?
                                            <div className="background fafc pointer pd-2 flex-column modal-material-box-height" onClick={openMaterialModal}>
                                                <img src="/img/material-lib.png" style={{marginBottom: 10}}/>
                                                <div className="manrope f-12 grey-73">Select From Material Library</div>
                                            </div>:
                                            <>
                                            <div className="background fafc pd-1 flex-row" style={{height: 162, alignItems: 'flex-start', overflowY: 'scroll'}}>
                                                <div style={{display:'block'}}>
                                                    {materialInfo && materialInfo.map((material,index) => (
                                                        finalSelectedMaterials.includes(material.id) ?
                                                        <div className="justify-in-start" style={{marginBottom: 10}}>
                                                            {material.renders  && material.renders.data && material.renders.data.thumbnail &&
                                                                <img src={ENVIRONMENT.getBaseURL(material.platform) + ENVIRONMENT.MATERIAL_FILE_URI + material.id + '/' +  encodeURIComponent(material.renders.data.thumbnail)} className="lib-mat small" style={{marginRight: 10}}/>
                                                            }
                                                            <span>
                                                                <div className="manrope f-12 black-00 w-600">{material.name}&nbsp;
                                                                <Tooltip title={<span className="manrope f-12 white">Remove Material</span>}>
                                                                    <DeleteOutlined  className="manrope f-12 grey-77" style={{position:'relative',zIndex: "5"}} onClick={() => {removeMaterials(material)}}/>
                                                                </Tooltip>
                                                                </div>
                                                                <div className="manrope f-12 grey-77">{material.width}x{material.length} inches</div>
                                                            </span>
                                                        </div>:''
                                                    ))}
                                                </div>
                                                <img src="/img/success-small.png" style={{margin: "auto 0"}}/>
                                            </div>
                                            <div onClick={openMaterialModal} className="background light-blue pd-1 pointer justify-in-center manrope f-16 blue" style={{marginTop: 16}}>
                                                <PlusOutlined/>
                                                <div className="manrope f-12 blue" style={{marginLeft: 10}}>Add From Material Library</div>
                                            </div>
                                            </>}
                                        </div>
                                    </Col>: ''}
                                </Col>: ''}
                            </Row>
                        </Panel>
                    </Collapse>
                </Col>
                </Row>
                <Row>
                        <Col span={24}>
                            <Collapse style={{marginTop: 32}} defaultActiveKey={['1']} className="collapse-panel bg-fc" ghost expandIconPosition={'left'}>
                                <Panel header={<span className="manrope f-14 grey-77 w-700">{objBundle? "*": ""} Add Dimensions of your Product Model</span>} key="1">
                                    <Row style={{justifyContent:"flex-start"}}>
                                        <Col span={3}>
                                            <div style={{marginTop:"10px"}}>
                                                <Form.Item name="dimensions" label="" colon={false}>
                                                    {getFieldDecorator('dimensions', {
                                                        initialValue: 'inches',
                                                    })(
                                                        <Select className="manrope f-12 black-55" style={{color:"#333333"}} defaultValue={'inches'} buttonStyle="solid">
                                                            <Option className="manrope f-12 select-view" value="inches">Inches</Option>
                                                            <Option className="manrope f-12 select-view" value="meter">Meter</Option>
                                                            <Option className="manrope f-12 select-view" value="millimeter">Millimeter</Option>
                                                            <Option className="manrope f-12 select-view" value="feet">Feet</Option>
                                                            <Option className="manrope f-12 select-view" value="centimeter">Centimeter</Option>
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col span={6}>
                                            <Form.Item name="height" label="" colon={false}>
                                                {getFieldDecorator('height', {
                                                    rules: [{ required: objBundle, message: 'Please enter height'}],
                                                })(
                                                    <InputNumber className="input-number manrope f-10 grey-77"
                                                    type="number"
                                                    placeholder="Add your product height" min={0}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}  style={{paddingLeft:"8px"}}>
                                            <Form.Item name="width" label="" colon={false}>
                                                {getFieldDecorator('width', {
                                                    rules: [{ required: objBundle, message: 'Please enter width'}],
                                                })(
                                                    <InputNumber className="input-number manrope f-10 grey-77"
                                                    type="number"
                                                    placeholder="Add your product width" min={0}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>

                                        <Col span={6}  style={{paddingLeft:"8px"}}>
                                            <Form.Item name="length" label="" colon={false}>
                                                {getFieldDecorator('length', {
                                                    rules: [{ required: objBundle, message: 'Please enter length'}],
                                                })(
                                                    <InputNumber className="input-number manrope f-10 grey-77"
                                                    type="number"
                                                    placeholder="Add your product depth" min={0}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                    <Col span={24} style={{marginTop: 20}}>
                                        <Form.Item name="dimensions_text" label={<span className="manrope f-14 black-55">Additional Dimensions</span>} colon={false}>
                                            {getFieldDecorator('dimensions_text', {
                                            })(
                                                <Input.TextArea style={{paddingTop:10}} rows={3} className="manrope f-10 grey-77"
                                                    placeholder = "Add any additional dimensions detail that are not already represented above"

                                                />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    </Row>
                                </Panel>
                            </Collapse>
                        </Col>
                </Row>



                <Collapse style={{marginTop: 32,marginBottom:100}} defaultActiveKey={['1']} className="collapse-panel bg-fc" ghost expandIconPosition={'left'}>
                    <Panel header={<span className="manrope f-14 grey-77 w-700">Select a license type for your Product Model *</span>} key="1">
                        <LicenseFields
                        price={price}
                        changePrice={changePrice}
                        creative_commons_text={FileConstants.creative_commons_text}
                        creativeCommon={creativeCommon}
                        setCreativeCommon={setCreativeCommon}
                        forSale={forSale}
                        changeForSale={changeForSale}
                        publicLicenseChecked={publicLicenseChecked}
                        forCreativeCommon={forCreativeCommon}
                        changeForCreativeCommon={changeForCreativeCommon}
                        formValidationFailed={formValidationFailed}
                        publicLicensePanel={publicLicensePanel}
                        changePublicLicenseChecked={changePublicLicenseChecked}
                        privateLicenseChecked={privateLicenseChecked}
                        privateLicensePanel={privateLicensePanel}
                        changePrivateLicenseChecked={changePrivateLicenseChecked}
                        />
                    </Panel>
                </Collapse>


                </div>



        <div className='fixed-row'>
            <Row style={{marginBottom:"100px",marginTop: 15,width:"95%",marginRight:"3%"}}>
                {
                    // props.requestStatus == 'allowed'
                    // ?
                    // <Col span={8} className='text-left'>
                    //     <div style={{display:"grid",paddingRight:130}}>
                    //     <div>
                    //         <span className='manrope f-14' style={{marginTop:20,marginBottom:5}}>Subscription usage</span>
                    //         <span className='manrope f-14 w-600' style={{position:"absolute", right:"130px"}}>
                    //         {props.usedUploadProps}/{props.availableUploadProps+props.usedUploadProps}
                    //         </span>
                    //     </div>
                    //     {/* <div style={{width:'100%'}} className="myProgress">
                    //         <div className="myBar" style={{width:`${props.usedUploadProps/(props.availableUploadProps+props.usedUploadProps)*100}%`, background: '#D83025'}}/>
                    //     </div> */}
                    //     <Progress style={{width:"100%"}} percent={(props.usedUploadProps/(props.availableUploadProps+props.usedUploadProps))*100} showInfo={false} type='line' strokeColor={"#25A759"} trailColor={"#D9D9D9"}/>
                    //     </div>
                    // </Col>
                    // :
                    // ''
                }
                {
                    // props.requestStatus == 'payment_required'
                    // ?
                    // <Col span={8} className='text-left'>
                    //     <div className='manrope f-16'>
                    //         <span>Total Price: <span className='manrope f-16 green w-700' >${requestPrice}</span> </span>
                    //     </div>
                    // </Col>
                    // :
                    // ''
                }
                <Col span={10} className='justify-in-start'>
                    <Button onClick={handleCancel}  style={{marginBottom:'10px',marginRight:"10px"}} className="modal-okay-gray square font-14">
                        Cancel
                    </Button>
                    {addedProduct ?
                    <Button onClick={() => setAddedProduct(false) } style={{marginBottom:'10px',marginRight:"10px"}}  className="modal-okay-gray square font-14">
                        Back
                    </Button>
                    :
                    ''
                    }
                </Col>
                <Col span={14} className="justify-in-end">
                    {/* <Form.Item> */}
                    <div>
                    {(formValidationFailed)?
                            <div className="manrope f-12 red" style={{textAlign: "center",marginBottom: 5}}>Please enter all required information before submitting.</div>
                        :""}

                        {(uploadError != '')?
                            <div className="manrope f-12 red" style={{ textAlign: "center",marginBottom: 5}}>{uploadError}</div>
                        :""}

                        {(priceError != '')?
                            <div className="manrope f-12 red" style={{textAlign: "center",marginBottom: 5}}>{priceError}</div>
                        :""}
                    </div>

                    <Button disabled={!uploadedProduct} style={{marginLeft:'5px',marginBottom: 10,marginRight:"10px"}} onClick={() => handleSave()} className="modal-okay-invert square font-14">
                        Save Draft & Exit {submittingSaveRequest ? <LoadingOutlined type="sync" spin />: ''}
                    </Button>
                    {/* </Form.Item> */}
                    {
                    addedProduct ?
                    <Form.Item>
                        <Button  disabled={submittingCreateRequest} style={{marginLeft:'5px',marginRight:"10px"}}  onClick={() => handleSubmit()} className="modal-okay square font-14">
                            Submit {submittingCreateRequest == true ? <LoadingOutlined type="sync" spin />: ''}
                        </Button>
                    </Form.Item>
                    :
                    <Button disabled={archiveValidationError != '' || !uploadedProduct} style={{marginLeft:'5px',marginBottom: 10,marginRight:"10px"}} onClick={() => {validateModel()}} className="modal-okay square font-14">
                        Next
                    </Button>
                    }
                </Col>
            </Row>
            </div>
        </Form>

        <HelpMessageModal
         visible={helpMessageModal}
         closable={true}
         onCancel={handleCancel}
         heading={"Draft Saved Successfully!"}
         help_image={'/img/inprogress-screen.png'}
         text={`Your drafts are saved in 'Incomplete' section on your home dashboard.`}
         cookie_name={"save_help"}
        />
         <SuccessModal
        visible={requestSubmitted}
        onCancel={() => setRequestSubmitted(false)}
        heading={"Request Submitted Successfully!"}
        text={props.productReducer.product && props.productReducer.product.variant_of ? `Your Product Model's Variation request has been successfully submitted. You will be notified via email once your Product Model is ready to be reviewed.` :`Your Product Model creation request has been successfully submitted. Automatic conversion and validations will be run by the system and the model will be ready within 24 hours. You will be notified via email once your Product Model is ready to be reviewed.`}
        footer={[
            <div className="justify-in-center">
                <Button className="modal-okay-gray square font-14"   onClick={() => window.location.href="/upload-product-model"}>
                    Upload Another Product
                </Button>
                <Button className="modal-okay square font-14" htmlType="submit" onClick={() => handleRedirection()}>
                    Go To Dashboard
                </Button>
            </div>

        ]}
        />
        <SuccessModal
        visible={requestSaved}
        onCancel={() => setRequestSaved(false)}
        heading={"Information Saved Successfully!"}
        text={"You can come back any time from Incomplete section to fill out the remaining information."}
        footer={[
            <div className="justify-in-center">
                <Button className="modal-okay-gray square font-14"   onClick={() => window.location.href="/upload-product-model"}>
                Upload Another Product
                </Button>
                <Button className="modal-okay square font-14" htmlType="submit" onClick={() => (setRequestSaved(false), handleRedirection())}>
                    Go To Dashboard
                </Button>
            </div>
        ]}
        />

        <Modal visible={materialModal}
                onCancel={cancelMaterialModal}
                closable={true}
                maskClosable={true}
                destroyOnClose={true}
                className="aspect-ratio-modal-closable"
                footer={[
                    <span className="justify-in-end">
                      <Button disabled={selectedMaterial.length == 0} className="modal-okay font-14 square" htmlType="submit" onClick={addMaterials}>
                        Done
                      </Button>
                    </span>
                  ]}
                >
            <div style={{padding:"32px 32px 24px 32px"}}>
                <div className="manrope f-16 black-14 w-600" style={{marginBottom: 8,textTransform:"capitalize"}}>
                Select Material from Material Library
                <Row className="justify-space-between"  style={{marginTop:"20px",marginBottom: 20}}>
                    <Col>
                        <div className='manrope f-24 w-700' style={{display:'flex',alignItems:"flex-end"}}><span>Material Library</span>{materialInfo ? materialInfo.length > 0 ?  <span  style={{marginLeft: 4,paddingBottom: 2}} className="manrope f-20">({materialInfo.length} items)</span>: '': ''}</div>
                    </Col>
                    <Col lg={10} xl={10}>
                       {materialInfo && materialInfo.length > 0 ?
                       <Input.Group compact size="large" className="d-inline">
                            <Input placeholder="Search All Materials" onChange={e => {changeSearchValue(e)}} className="manrope f-14 mat-search-bar"></Input>
                        </Input.Group>: ''}
                    </Col>
                </Row>
                </div>
                 {materialInfo && materialInfo.length == 0 ?
                <div className="note-bg-artist red" style={{marginTop: 32, width: 'fit-content'}}><span className="manrope f-14 red">Oops. Looks like you don't have any materials in your material library. You can request new materials through this <a target="_blank" href="/material-request" className="manrope f-14 red underline">link</a>.</span></div>
                :
                <Col span={24} className="request-silo-scrollable justify-in-start wrap" style={{marginTop: 32}}>
                    {materialInfo && materialInfo.map((material,index) => (
                        (searchValue == '' || (Utilities.isMatching(searchValue.toLowerCase(), material.category.toLowerCase()) || Utilities.isMatching(searchValue.toLowerCase(), material.name.toLowerCase()))) ?
                        <Card style={{marginRight: 16, marginBottom: 16, padding: 4, cursor: "pointer",pointerEvents: "auto" ,border: (selectedMaterial.includes(material.id)) ? "1px solid #276DD7" : "1px solid #e4e4e4" }} onClick={() => selectMaterialImages(material)}  className='material-lib-card'>
                            {material && material.status == 5 && material.renders  && material.renders.data && material.renders.data.thumbnail ?
                            <img src={ENVIRONMENT.getBaseURL(material.platform) + ENVIRONMENT.MATERIAL_FILE_URI + material.id + '/' +  encodeURIComponent(material.renders.data.thumbnail)} className="lib-mat"/>:
                            material.material_files[0] && material.material_files[0].uid ? <img className="lib-mat" src={ENVIRONMENT.getBaseURL(material.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(material.material_files[0].uid + '/' + material.material_files[0].name)}/>: ''}
                            {selectedMaterial.includes(material.id) ?
                            <img className="success-img" src="/img/success.png"/> : ''}
                            <Tooltip title={<span className='manrope f-12 white'>{material.name}</span>}>
                            <div className="manrope f-12 black-00 clamp-text w-50 justify-in-start" style={{paddingTop: 8}}>{material.name}</div>
                            </Tooltip>
                            <div className="manrope f-12 grey-77 justify-in-start" style={{paddingTop: 4}}>{material.width}x{material.length} inches</div>
                        </Card> : ''
                    ))}
                </Col>}
            </div>
        </Modal>
        <Modal
        className="success-modal-setting"
        closable={false}
        visible={configurableModal}
        centered={true}
        footer={[
            <div className="justify-in-center">
                <Button key="back" style={{textAlign:"center"}} className="modal-okay-gray square font-16" onClick={handleRedirection}>
                    Go to Dashboard
                </Button>
                <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-16" onClick={goToMaterialForm}>
                    Upload Materials
                </Button>
            </div>
        ]}>
            <div style={{padding:"32px 32px 0 32px",textAlign:"center"}}>
                <img src='/img/success.png' style={{width:"53px",height:"53px",marginBottom:"16px"}} alt="Alert"/>
                <div style={{textAlign:'center'}}>
                    <h2 className="manrope f-24 black-14 justify-in-center" >Configuration Model Request Submitted Successfully!</h2>
                    <div className="manrope f-16 grey-77" style={{marginTop: 8}}>Your request to create a configurable product has been submitted successfully.You will be informed via email when your model is ready to be reviewed.
                    </div>
                    <div className="manrope f-18 black-14" style={{marginTop: 32}}>Next Step: Upload Materials
                    </div>
                    <div className="manrope f-16 grey-77" style={{marginTop: 8,marginBottom: 32}}>
                    Next step is to upload materials for your configurable product model to material library. You can go to dashboard if materials for this model are already present in your material library.
                    </div>
                </div>
            </div>

        </Modal>
        <PaymentConfirmationModal
        name={productName}
        visible={paymentConfirmationModal}
        amount={"$" + requestPrice}
        setSubscriptionModal={setSubscriptionPackageUpdateModal}
        onCancel={cancelPaymentConfirmation}
        text={"Please pay the amount below to proceed. It can take 24 to 72+ hours for completion of the model based on complexity"}
        footer={[
        <span style={{display: 'flex', justifyContent:'center'}}>
            <Button disabled={submittingCreateRequest} className="modal-okay-gray font-14 square" onClick={cancelPaymentConfirmation}>
            Cancel
            </Button>

            <Button disabled={submittingCreateRequest || cardSaveFlowTriggered} className="modal-okay font-14 square" onClick={initiateConfirmation}>
                {cardStatus == 1 && requestStatus == "payment_required" ? "Confirm & Process Payment" :
                cardStatus == 0 && requestStatus == "payment_required" ?"Confirm & Enter Payment Details" : "Confirm"} {submittingCreateRequest == true ? <LoadingOutlined type="sync" spin />: ''}
            </Button>
        </span>
        ]}/>
        <SubscriptionPackageUpdate
        subscriptionPlan={subscriptionPlan}
        setSubscriptionPlan={setSubscriptionPlan}
        currentPackage={currentPackage}
        currentSubscriptionPlan={currentPackage}
        isSubscriptionActive={isSubscriptionActive}
        visible={subscriptionPackageUpdateModal}
        onCancel={() => setSubscriptionPackageUpdateModal(false)}
        heading={<span>You have suceeded the quota for uploading existing products in your current plan. Do you wannt to upgrade your current plan?</span>}
        text={<span>Your current package is selected by default. Select another package to upgrade. </span>}
        footer={[
            <div>
                {
                    requestStatus == 'update_subscription' ?
                    <span style={{color:"#276DD7"}} className='justify-in-start manrope f-14'>
                        Please upgrade your package to keep using this service.
                    </span>
                    :
                    ''
                }
                <span className="justify-in-end">
                <Button key="back" style={{textAlign:"center"}} className="modal-okay-gray square font-14" onClick={() => setSubscriptionPackageUpdateModal(false)}>
                    Cancel
                </Button>
                    {(cardStatus == 1)?
                    <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={initiateSubscriptionConfirmation}>
                        {buttonLoader ? <span>Upgrading Plan <LoadingOutlined spin /></span>:<span>Confirm & Process Payment </span>}
                    </Button>
                    :
                    <Button disabled={(subscriptionPlan == '') || cardSaveFlowTriggered || buttonLoader} key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={initiateSubscriptionConfirmation}>
                    {buttonLoader ? <span>Upgrading Plan <LoadingOutlined spin /></span>:<span>Confirm & Enter Payment Details </span>}
                    </Button>
                    }

                </span>
            </div>
          ]}
        />
        <CardSaveDrawer
        setCardSaveFailure={setCardSaveFailure}
        saveRequestIntermedietely={saveRequestIntermedietely}
        visible={cardSaveDrawer}
        onClose={() => setCardSaveDrawer(false)}

        />

        <WarningModal
        visible={cardSaveFailure}
        onCancel={() => setCardSaveFailure(false)}
        heading={"Unable to Save Payment Details"}
        text={"Failed to process payment details. Please try another payment method."}
        footer={[
        <div className="justify-in-end">
            <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => setCardSaveFailure(false)}>
                Okay
            </Button>
        </div>
        ]}/>

        <WarningModal
        visible={cardPaymentFailure}
        onCancel={() => setCardPaymentFailure(false)}
        heading={"Unable to Make a Card Payment"}
        text={"We are very sorry but we were unable to process your payment. Please try again."}
        footer={[
        <div className="justify-in-end">
            <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => setCardPaymentFailure(false)}>
                Okay
            </Button>
        </div>
        ]}/>

        {/* <Modal
        visible={paymentFailureModal}
        centered={true}
        onCancel={() => setPaymentFailureModal(false)}
        text={""}

        footer={null}>

            <div style={{padding:"30px", textAlign:"center", paddingBottom: 24}}>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <img style={{width: 50,height:50}} src={"/img/alert-triangle.svg"}></img>
                </div>
                <div>
                    <h2  className="manrope f-16 black-00" style={{marginTop: 6}}>{"Payment Failed"}</h2>
                    <p className="manrope f-14 black-55">{paymentFailureMessage + " Click on button below to try different payment method."}</p>
                </div>
            </div>
            <div style={{display: "flex", justifyContent:"center"}}>

            <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => updatePaymentDetailsOnFailure()}>
                Update Payment Details
            </Button>
        </div>
        </Modal> */}
        <PaymentFailureModal
        paymentFailureMessage={paymentFailureMessage}
        setPaymentFailureModal={setPaymentFailureModal}
        updatePaymentDetailsOnFailure={updatePaymentDetailsOnFailure}
        paymentFailureModal={paymentFailureModal}
        />
    </div>
}

const UploadExistingForm = Form.create({ name: 'product_info' })(ProductCombinedForm);


const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    createProduct: (product) => {
        dispatch(createProduct(product));
    },
    updateProduct: (product) => {
        dispatch(updateProduct(product));
    },
});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
    )(UploadExistingForm)
)
