import React, { useState, useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import DottedLoader from '../../DottedLoader';
import ProductQAPage from '../ProdcutQAPage/ProductQAPage';
import ProductDetails from '../ProductDetails/ProductDetails';
import ENVIRONMENT from '../../../../environments';
import CustomerMainLayout from '../CustomerMainLayout';


let CUSTOMER_USERNAME = localStorage.getItem("username");
let COMPANY_ID = localStorage.getItem("company_id");
const ProductQAPageRedirect = (props) => {
    const [userPreferenceLoader, setUserPreferenceLoader] = useState(true);
    const [legacyView, setLegacyView] = useState(null);
    const { match: { params } } = props;

    useEffect(() => {
        setLocalStorageValues();
        fetchUserPreferenceInfo();
    }, []);

    const setLocalStorageValues = () => {
        CUSTOMER_USERNAME = localStorage.getItem("username");
        COMPANY_ID = localStorage.getItem("company_id");
    }

    const fetchUserPreferenceInfo = () => {
        setUserPreferenceLoader(true);
        let payload = {
            'username': CUSTOMER_USERNAME,
            'action': 'get_product_legacy_view'
        }
        if (COMPANY_ID != undefined) {
            payload['company_id'] = COMPANY_ID;
        }
        axios.post(ENVIRONMENT.USER_PREFERENCE_CONTROLLER, payload)
            .then(res => {
                if (res && res.data) {
                    if (res.data == 'True') {
                        setLegacyView(true);
                    } else if (res.data == 'False') {
                        setLegacyView(false);
                    }
                } else { // in case legacy view is not set
                    setLegacyView(true);
                }
                setUserPreferenceLoader(false);
            });
    }

    return (<CustomerMainLayout selected='4' product_page={true}>
        {userPreferenceLoader ? <DottedLoader /> :
            legacyView == false ?
                <ProductQAPage props={props} match={props.match} params={params} /> :
                <ProductDetails props={props} match={props.match} params={params} />}
    </CustomerMainLayout>)

}

export default ProductQAPageRedirect;