import React, { useEffect, useState } from "react";
import { Button } from 'antd';
import SuccessModal from '../../SuccessModal/SuccessModal';
import SpaceVariationRequestModal from '../SpaceVariationRequestModal/SpaceVariationRequestModal';


const AutomatedSpaceVariationRequestModal = (props) => {

    const [variationSuccessModal, setVariationSuccessModal] = useState(false);

    const closeVariationSuccessModal = () => {
        setVariationSuccessModal(false);
    }

    const submitVariation = () => {
        props.setVariationModal(false);
        setVariationSuccessModal(true);
    }

    return <div>
        <SpaceVariationRequestModal
            requestType={"variation_room_model"}
            savedID={-1}
            submitVariation={submitVariation}
            variationLoader={false}
            automated_variation={true}
            variationModal={props.variationModal}
            closeVariationModal={()=>props.setVariationModal(false)}
            space_details={props.space_details}
            space_id={props.space_id}
            space_variation={props.space_variation}
        />

        <SuccessModal
            visible={variationSuccessModal}
            onCancel={closeVariationSuccessModal}
            heading={"Space Model Variation Requested Successfully!"}
            text={"Your Space Model's Variation request has been submitted. You will be notified via email once your Space Model is ready to be reviewed."}
            footer={[
                <div className="justify-in-center">

                    <Button className="modal-okay square font-14" htmlType="submit" onClick={() => closeVariationSuccessModal()}>
                    Okay
                    </Button>
                </div>
            ]}
        />
    </div>
}

export default AutomatedSpaceVariationRequestModal;

