import React from 'react';
import 'antd/dist/antd.css';
import ArtistMainLayout from '../ArtistMainLayout';
import RoomStaticGuidelines from '../RoomStaticGuidelines';
import NotAuthorized from '../NotAuthorized'
import { Link } from "react-router-dom";
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Input, Button, Upload, message, Form, Modal, Tabs, Menu, Dropdown } from 'antd';
import axios from 'axios';
import * as JSZip from 'jszip';
import {  LoadingOutlined, DownloadOutlined, DownOutlined } from '@ant-design/icons';
import * as Constants from '../../CustomerComponents/Constants'
import ENVIRONMENT from '../../../../environments'
import AWS from 'aws-sdk';
import { SPACE_GUIDELINES, BUCKET_NAME } from '../../../../environments/env';
import DottedLoader from '../../DottedLoader';
const { Dragger } = Upload;

const retry_count = 5;


class ArtistRoomGuidelines extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modelFileList: [],
      upload_error: "",
      upload_warning: "",
      rejections_data: [],
      request_submitted: false,
      validation_report: [],
      room_id: this.props.match.params.id,
      success_modal: false,
      open_input_modal: false,
      validation_generated: false,
      status: 2,
      inside_interval: false,
      call_times: false,
      file_info: '',
      reupload_flag: false,
      confirmation_modal: false,
      req_btn_loader: false,
      validation_check: false,
      time1: 0,
      time3: 0,
      input_fields_rules: {
        'time1': { required: true, message: 'This field is mandatory' },
        'time3': { required: true, message: 'This field is mandatory' },
        'fix_time': { required: false, message: 'This field is mandatory' },
      },
      space_valid: true,
      automatically_fixed_high: [],
      successfully_validated_high:[],
      invalid_data_high: [],
      success_val_length: 0,
      invalid_val_length: 0,
      auto_val_length: 0,
      loading_state: true,
      rejected_upload_modal: false,
      uploading: false,
      hasError: false,
      upload_failed_message: '',
      new_file_list: [],
      test_render_upload_error: '',
      testRenderList: [],
      uploading_model_flag: false,
      test_products: [],
      platform: 'aws',
      disable_upload: true
    }
  }

  handleRetry = (type_of_file,type) => { 
    let fileList = [];        
    if (type_of_file == 'zip_file') {
        fileList = [...this.state.modelFileList];
    } 

    fileList.map((file,index) => {
      this.setState({
        uploading: true
      });
        if (file.status == 'error') {
        var jwt_token = localStorage.getItem('all3d_jwt_token');
        var cognito_credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'us-west-2:5f6370ec-44f1-4603-918e-afcdee671162',
            Logins: { 
                'cognito-idp.us-west-2.amazonaws.com/us-west-2_dmOay1z0j': jwt_token,
            }
        });
    
        cognito_credentials.refresh(() => {
        AWS.config.update({
            region: 'us-west-2',
            credentials: cognito_credentials
        });
        const S3 = new AWS.S3({
            httpOptions: {
            timeout: 240000
            }
        });
        const objParams = {
            Bucket: BUCKET_NAME,
            Key: "test_files" + "/" + file.originFileObj.uid + "/" + file.originFileObj.name,
            ACL: 'public-read',
            Body: file.originFileObj,
            ContentType: file.originFileObj.type
            };
            S3.upload(objParams)
            .on("httpUploadProgress", ({ loaded, total }) => {
              this.setState({
                uploading: true
              });
                file.status = 'uploading';
                let percent = Math.round((loaded / total) * 100);
                file.percent = percent;
            })
            .send((err, data) => {
                if (err) {
                    console.log(err.code);
                    console.log(err.message);
                    file.status = 'error';
                    this.setState({
                      upload_failed_message: err.code.concat('.  ' + err.message)
                    });
                    if (err.code.includes('Credentials')) {
                        this.setState({
                          upload_failed_message: err.code.concat('. Your credentials have expired. Please refresh the page.')
                        });
                    } else if (err.code.includes('Network')) {  
                        this.setState({
                          upload_failed_message: err.code.concat('. We are unable to upload due to an issue with your internet connection.')
                        });
                    }
                    this.setState({
                      uploading: false,
                      hasError: true
                    });
                } else {
                    console.log("RETRY SEND FINISHED",data.response,file);
                    file.status = 'done';
                    file.error = '';
                    this.setState({
                      upload_failed_message: '',
                      uploading: false,
                      hasError: false
                    });
                    let new_file_list = [];
                    new_file_list.push(file);
                    if (type_of_file == 'zip_file') {
                      this.setState({
                        modelFileList: new_file_list,
                        new_file_list: new_file_list
                      }, () => {
                        // if (type == 'reupload') {
                        //   console.log('check 1');
                        //   this.onSetTimeIntervalPassed();
                        // } else if (type == "rejected") {
    
                        // } else {
                        //   console.log('check');
                        //   this.onSetTimeIntervalPassed();
                        // }
                      })
                    } 
                    
                }
                
                
            })
        });
    }
    });
  }

  handleChange = info => {
    let fileList = info.fileList;
    if (this.state.new_file_list && this.state.new_file_list.length > 0) {
      fileList = this.state.new_file_list;
    }
    if (fileList != undefined && this.state.room_guidelines) {
      fileList = fileList.slice(-1);
      if (this.state.inside_interval == false) {
        this.setState({
          file_info: info
        });
      }
      if (this.state.new_file_list && this.state.new_file_list.length > 0) {
        fileList = this.state.new_file_list;
      } else {
        if (info.file.status === "error") {
          console.log('set to error')
          this.setState({
            upload_failed_message: info.file.error.code.concat('   ' + info.file.error.message)
          });
        
          if (info.file.error.code.includes('Credentials')) {
            this.setState({
              upload_failed_message: info.file.error.code.concat('. Your session has expired. Please reload the page.')
            });
          }  else if (info.file.error.code.includes('Network')) {
            this.setState({
              upload_failed_message: info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.')
            });
          } 
          
        } else if (info.file.status === "done") {
          this.setState({
            upload_failed_message: ''
          });
        }
      }
      this.setState({modelFileList: fileList},() => {  
        if (fileList.length > 0 && this.state.upload_error == "" && this.state.upload_failed_message == "" && this.state.inside_interval && this.state.call_times == false) {
          this.setState({
            inside_interval: false,
            call_times: true
          });
          var room_id = this.props.match.params.id;
          var archive_name = fileList[0].name;
          if (this.state.room_guidelines.model_retries >= 0 && this.state.room_guidelines.model_retries <= retry_count){
            if (archive_name != room_id + '.zip') {
              message.error('Invalid File Uploaded. Names must be ' + room_id + '.zip');
            }
          }
          else {
            message.error('Retry count exceeded!');
          }
        }
      });
    }
  };

  resetUploadStatus = () => {
    let isRoomGLBTested = localStorage.getItem(`room_glb_tested_${this.props.match.params.id}`)
    if (isRoomGLBTested) {
      localStorage.removeItem(`room_glb_tested_${this.props.match.params.id}`);
    }
  }
  setUploadStatus = () => {
    let isRoomGLBTested = localStorage.getItem(`room_glb_tested_${this.props.match.params.id}`)
    if (isRoomGLBTested && isRoomGLBTested == "true") {
        this.setState({
          disable_upload: false
        })
    }
  }

      componentDidMount() {

        this.setUploadStatus();
        axios.post(ENVIRONMENT.GET_ROOM_TEST_PRODUCTS, {})
        .then(res => {
          this.setState({test_products: res.data})
        });
        axios.post(ENVIRONMENT.GET_ROOM_REJECTIONS, {
          room_id: this.props.match.params.id,
        })
          .then(res => {
            console.log("Rejection Data", res.data);
            this.setState({
                rejections_data: res.data
            },() => {
              if (this.state.rejections_data.length != 0) {
                let input_fields_rules = {
                  'time1': { required: false, message: 'This field is not mandatory' },
                  'time3': { required: false, message: 'This field is not mandatory' },
                  'fix_time': { required: true, message: 'This field is mandatory' },
                }
                this.setState({
                  input_fields_rules: input_fields_rules
                });
              }
            });

          });

          axios.post(ENVIRONMENT.FETCH_ROOM, {room_id: this.props.match.params.id})
            .then(res => {
                this.setState({
                    room_guidelines: res.data,
                    validation_report: res.data.validation_report
                },() => {
                  this.setValidationData();
               
                  this.setState({
                    loading_state: false,
                    platform: res.data.platform
                  });
                
                  console.log('Validation Report', this.state.validation_report);
                });
                console.log("Room data",res.data);
            });
            window.setInterval(this.onSetTimeIntervalPassed, 1000, this);
            window.setInterval(this.onTwoMinuteInterval, 60*3000, this);
      }

      componentDidUpdate(prevProps) {
        if (prevProps!= this.props) {
        }
      }

      onSetTimeIntervalPassed = () => {
        let uploadStatus = this.validateFileUploads(this.state.modelFileList);
        if (uploadStatus == 'error') {
          if (this.state.file_info) {
            this.setState({
              upload_failed_message: this.state.file_info.file.error.code.concat('   ' + this.state.file_info.file.error.message)
            });
           
            if (this.state.file_info.file.error.code.includes('Credentials')) {
              this.setState({
                upload_failed_message: this.state.file_info.file.error.code.concat('. Your session has expired. Please reload the page.')
              });
            }  else if (this.state.file_info.file.error.code.includes('Network')) {
              this.setState({
                upload_failed_message: this.state.file_info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.')
              });
            } 
          } else {
            this.setState({
              upload_failed_message: 'Error occured in uploading bundle, please re-upload your model bundle.'
            });
          }
        }
        else if (uploadStatus == 'done' && this.state.inside_interval == false && this.state.room_guidelines.model_status != -4) {
          this.setState({
            inside_interval: true
          }, () => {
            this.handleModelUpload(this.state.file_info);
          });
        }
        if ((this.state.status == 6 || this.state.status == 8)) {
          this.setState({
            status: 4,
            loading_state: true
          });
          axios.post(ENVIRONMENT.FETCH_ROOM, {
            room_id: this.props.match.params.id,
          })
          .then(res => {
              this.setState({ 
                room_guidelines: res.data,
                validation_report: res.data.validation_report},() => {
                // if (this.state.room_guidelines.quick_renders == undefined || this.state.room_guidelines.quick_renders == null) {
                //   this.setState({
                //     quick_renders: false,
                //     reupload_flag: true,
                //   })
                // }
                // else {
                //   if (this.state.room_guidelines.quick_renders.length == 0) {
                //     this.setState({
                //       reupload_flag: true,
                //       quick_renders: false
                //     });
                //   } else {
                //     this.setState({
                //       quick_renders: true
                //     });
                //   }
                // }
                this.setValidationData();
                this.setState({
                  loading_state: false
                });
                console.log("Data on Interval: ",res.data);
              });
          });
          this.forceUpdate();
        }
      }

      onTwoMinuteInterval = () => {
        if (this.state.room_guidelines && this.state.room_guidelines.model_status == 6) {
          this.setState({
            loading_state: true
          });
          axios.post(ENVIRONMENT.FETCH_ROOM, {
            room_id: this.props.match.params.id,
          })
          .then(res => {
              this.setState({ 
                room_guidelines: res.data,
                validation_report: res.data.validation_report,
                upload_error: "",
                upload_warning:"",
                upload_failed_message: '',
                call_times: false,
                modelFileList: [],
                inside_interval: false
              },() => {
                this.setValidationData();

                // if (this.state.room_guidelines.quick_renders == undefined  || this.state.room_guidelines.quick_renders == null) {
                //   this.setState({
                //     quick_renders: false,
                //     reupload_flag: true,
                //   })
                // }
                // else {
                //   if (this.state.room_guidelines.quick_renders.length == 0) {
                //     this.setState({
                //       reupload_flag: true,
                //       quick_renders: false
                //     });
                //   } else {
                //     this.setState({
                //       quick_renders: true
                //     });
                //   }
                // }
               
                console.log("Data on 2 min Interval: ",this.state.room_guidelines);
                

                this.setState({
                  loading_state: false
                });
                this.forceUpdate();

              });
          });
        }
      }

    inputBundleFiles = e => {
        if (Array.isArray(e)) {
          return e;
        }
        var raw_files = {}
        raw_files['input_bundle'] = e.fileList;
        return e && raw_files;
      };

    validateFileUploads = (files) => {
      let status_done = false;
      if ((files != undefined)) {
        for(var file of files) {
              if (file['status'] == 'uploading') {
                  return 'uploading';
              }
              else if (file['status'] == 'error') {
                  return 'error';
              }
              else if (file['status'] == 'done') {
                  status_done = true;
              }
          }
      }
      if (status_done) {
          return 'done'
      }
      return 'not_started';
  }

    handleSubmit = (values) => {
      if(this.state.upload_error == "" && this.state.upload_failed_message == ""){
        this.setState({
          upload_error: '',
          upload_warning: '',
          upload_failed_message: '',
          request_submitted: true
        });
        var room_id = this.props.match.params.id;

        var body = {};
        let archive_name = room_id + '.zip';
        body['room_id'] = room_id;
        body['model_type'] = 'low_poly_max';
        body['model_archive'] = archive_name;
        body["artist_approved"] = true;
        console.log('data to be sent is', body);
        axios.post(ENVIRONMENT.ROOM_MODEL_CREATE, {body})
        .then(res => {
            axios.post(ENVIRONMENT.UPDATE_ROOM, {
              room_id: room_id,
              model_status: '6'    
            })
            .then(res => {
              console.log('RESPONSE FOR MODEL_CREATE',res);
            });

            if(this.state.rejections_data.length == 0) {
                axios.post(ENVIRONMENT.ARTIST_HOURS, {
                  room_id: room_id,
                  artist_username: localStorage.getItem("username"),
                  time1: values['time1'],
                  time3: values['time3'],
                }).then(res => {
                    this.setState({
                      open_input_modal: false,
                      success_modal: true,
                      modelFileList: [],
                      request_submitted: false
                    });
                    this.forceUpdate();
                    console.log('RESPONSE FOR ARTIST HOURS',res);
                });
            }
            else {
                axios.post(ENVIRONMENT.ARTIST_ROOM_FIX, {
                  room_id: room_id,
                  artist_username: localStorage.getItem("username"),
                  fix_time: values['fix_time'],
                  rejection_message: this.state.rejections_data[0].rejection_details,
                }).then(res => {
                    this.setState({
                      open_input_modal: false,
                      success_modal: true,
                      modelFileList: [],
                      request_submitted: false
                    });
                    console.log('RESPONSE FOR ARTIST ROOM FIX',res);
                });
            }
            this.resetUploadStatus();
        })
      }
    }

    
    handleRejectedUploadChange = info => {
      if (this.state.upload_error == "" && this.state.upload_failed_message == "") {
        this.setState({
          file_info: info
        }, ()=> {
          let fileList = info.fileList;
          if (fileList != undefined) {
            fileList = fileList.slice(-1);
            if (this.state.inside_interval == false) {
              this.setState({
                file_info: info
              });
            }
            this.setState({modelFileList: fileList});
          }
        });
      }
    }

    handleRejectedSubmit = (values) => {
      
      if(this.state.upload_error == "" && this.state.upload_failed_message == "") {
        this.setState({
          upload_error: '',
          upload_warning: '',
          upload_failed_message: '',
          request_submitted: true
        });
        var room_id = this.props.match.params.id;
        var body = {};
        var archive_name = room_id + '.zip';
        body['room_id'] = room_id;
        body['model_type'] = 'low_poly_max';
        body['model_archive'] = archive_name;
        body["artist_approved"] = false;
        body["rejected_case"] = true;
        let update_payload = {
          "room_id": room_id,
          "test_renders": this.state.testRenderList
        }
        console.log('update payload = ', update_payload)
        axios.post(ENVIRONMENT.UPDATE_ROOM, update_payload)
        .then(res => {
        })
        axios.post(ENVIRONMENT.ROOM_MODEL_CREATE, { body })
        .then(res => {
            axios.post(ENVIRONMENT.ARTIST_ROOM_FIX, {
              room_id: room_id,
              artist_username: localStorage.getItem("username"),
              fix_time: values['fix_time'],
              rejection_message: this.state.rejection_details,
            }).then(res => {
              message.info('Model is uploaded successfully and is being processed by automation.')
              this.setState({
                rejected_upload_modal: false,
                modelFileList: [],
                success_modal: true,
              });;
              this.resetUploadStatus();
            })
        });
      }
    };

    checkFolderStructure = (file) => {
      let return_check = true;
      let parent = this;
      let vray_exist = false;
      let high_exist = false;
      let max_high_id = false;
      let upload_error = '';
      
      let new_zip = new JSZip();
      return new_zip.loadAsync(file)
      .then((zip) => {
        for(let key in zip['files']){
          if ((!high_exist || !max_high_id) && key.includes('High/')) {
            high_exist = true;
            if (!max_high_id) {
              let max_id = key.split('/')[1];
              console.log("max_id", key, max_id)
              if (max_id == (parent.props.match.params.id + '.max')) {
                max_high_id = true;
              }
            }
          }
          if (!vray_exist && key.includes('VrayTextures/')){
            vray_exist = true;
          }
        }
        if (!high_exist && !vray_exist) {
          upload_error = "High and VrayTextures Folders are missing. Correct your folder structure and reupload file."
          return_check = false;

        }
        else if (high_exist && !vray_exist) {
          upload_error = "Incorrect folder structure! VrayTextures folder is missing. Correct your folder structure and reupload file."
          return_check = false;
  
        }
        else if (!high_exist && vray_exist) {
          upload_error = "Incorrect folder structure! High folder is missing. Correct your folder structure and reupload file."
          return_check = false;
        }
        else if (high_exist && vray_exist) {
          upload_error = '';
          if (!max_high_id) {
            upload_error = "Invalid max file name. It must be named " + parent.props.match.params.id + '.max'
            return_check = false;
          }
        }
        parent.setState({
          upload_error: upload_error
        })
        return return_check;

      }).catch (error => {
        parent.setState({
          upload_warning: "Warning: File is too large",
        });
      });
    }

    changeReuploadStatus = () => {
      this.setState({
        status: 2,
        confirmation_modal: false
      });
    }

    reuploadModel = () => {
      var body = {};
      body['room_id'] = this.props.match.params.id;
      body['model_status'] = '2';
      body['username'] = localStorage.getItem('username');
      body['model_delete_request'] = true;
      this.setState({
        req_btn_loader: true
      });
  
      axios.post(ENVIRONMENT.UPDATE_ROOM, body)
      .then(res => {
        this.setState({
          upload_error: '',
          upload_warning:'',
          upload_failed_message: '',
          reupload_flag: false,
          inside_interval: false,
          modelFileList: [],
          confirmation_modal: false,
          req_btn_loader: false
        });
        if (this.state.room_guidelines && (this.state.room_guidelines.model_status == 8 || this.state.room_guidelines.model_status == -4)) {
          this.setState({
            status: 8,
            call_times: false
          });
        }
        this.forceUpdate();
      });
    }
    
    submitModelUpdateRequest = () => {
      this.setState({uploading_model_flag: true});
      var room_id = this.props.match.params.id;
      var archive_name = this.state.modelFileList[0].name;
      console.log('in submit model update request function')
      if (this.state.room_guidelines.model_retries >= 0 && this.state.room_guidelines.model_retries <= retry_count){
        if (archive_name == room_id + '.zip' && this.state.testRenderList.length > 0) {
          var body = {};
          let archive_name = room_id + '.zip';
          body['room_id'] = room_id;
          body['model_type'] = 'low_poly_max';
          body['model_archive'] = archive_name;
          body["artist_approved"] = false;
          let update_payload = {
            "room_id": room_id,
            "test_renders": this.state.testRenderList
          }
          console.log('update payload = ', update_payload)
          axios.post(ENVIRONMENT.UPDATE_ROOM, update_payload)
          .then(res => {
          })
          axios.post(ENVIRONMENT.ROOM_MODEL_CREATE, { body })
          .then(res => {
            this.setState({
              upload_error: '',
              upload_warning: '',
              reupload_flag: false,
              inside_interval: false,
              modelFileList: [],
              testRenderList: [],
              uploading_model_flag: false
            });

            if (this.state.room_guidelines.model_status == 2) {
              this.setState({
                status: 6
              });
            }
            else if (this.state.room_guidelines.model_status == 8) {
              this.setState({
                status: 8
              });
            }
            if (res.status != 200) {
              this.setState({
                call_times: false,
              });
            }
            this.forceUpdate();
          })
          .catch((error) => {
            message.error('Failed to process request for model update');
          });
        }
        else {
          if(archive_name != room_id + '.zip')
            message.error('Invalid File Uploaded. Names must be ' + room_id + '.zip');
          else if(this.state.testRenderList.length == 0){
            message.error('You must upload Test Renders first to continue')
          }
        }
      }
      else {
        message.error('Retry count exceeded!');
      }

  }


    handleModelUpload = info => {
      let fileList = info.fileList;
      if (this.state.new_file_list && this.state.new_file_list.length > 0) {
        fileList = this.state.new_file_list;
      }
      if (fileList != undefined && this.state.room_guidelines) {
        fileList = fileList.slice(-1);
        if (this.state.inside_interval == false) {
          this.setState({
            file_info: info
          });
        }
        if (this.state.new_file_list && this.state.new_file_list.length > 0) {
          fileList = this.state.new_file_list;
        } else {
          if (info.file.status === "error") {
            console.log('set to error')
            this.setState({
              upload_failed_message: info.file.error.code.concat('   ' + info.file.error.message)
            });
          
            if (info.file.error.code.includes('Credentials')) {
              this.setState({
                upload_failed_message: info.file.error.code.concat('. Your session has expired. Please reload the page.')
              });
            }  else if (info.file.error.code.includes('Network')) {
              this.setState({
                upload_failed_message: info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.')
              });
            } 
            
          } else if (info.file.status === "done") {
            this.setState({
              upload_failed_message: ''
            });
          }
        }
        this.setState({modelFileList: fileList},() => {  
          if (this.state.upload_error == "" && this.state.upload_failed_message == "" && this.state.inside_interval && this.state.call_times == false) {
            this.setState({
              inside_interval: false,
              call_times: true
            });
          }
          this.forceUpdate();
        });
      }
    }

    handleTestRenderUpload = info => {
      let fileList = info.fileList;
      if (fileList != undefined && this.state.room_guidelines) {
        this.setState({testRenderList: fileList});
        if(fileList.length < 2)
          this.changeTestRenderUploadError("You must upload 2 or more test renders to continue.");
        else
          this.changeTestRenderUploadError("");
        this.forceUpdate();
      }
    }

    changeUploadError = (error) => {
      this.setState({
        upload_error: error
      });
    }

    changeUploadWarning = (warning) => {
      this.setState({
        upload_warning: warning
      });
    }

    changeTestRenderUploadError = (error) => {
      this.setState({
        test_render_upload_error: error
      });
    }

    setReuploadConfirmationModel = (flag) => {
      this.setState({confirmation_modal: flag});

    }

    changeUploadFailedError = (error) => {
      this.setState({
        upload_failed_message: error
      });
    }

    handleReUploadChange = info => {
      if (this.state.upload_error == "" && this.state.upload_failed_message == "") {
        this.setState({
          file_info: info
        }, () => {
          this.handleModelUpload(info);
          if (info.file.status == 'done') {
            var room_id = this.props.match.params.id;
            var body = {};
            let archive_name = room_id + '.zip';
            body['room_id'] = room_id;
            body['model_type'] = 'low_poly_max';
            body['model_archive'] = archive_name;
            body["artist_approved"] = false;
            axios.post(ENVIRONMENT.ROOM_MODEL_CREATE, { body })
            .then(res => {
              window.location.reload();
              this.forceUpdate();
            })
            .catch((error) => {
              message.error('Failed to process request for model update');
            });
          }
        });
      }
    }

    changeModelFileList = (fileList) => {
      this.setState({
        modelFileList: fileList
      });
    }

    setValidationData = () => {
      let automatically_fixed_high = [];
      let successfully_validated_high = [];
      let invalid_data_high = [];
      let success_val_length = 0;
      let invalid_val_length = 0;
      let auto_val_length = 0;

      if (this.state.validation_report != undefined) {
        if (this.state.validation_report['High'] != undefined && Object.keys(this.state.validation_report['High']).length != 0) {
          if (this.state.validation_report['High']['dimensions']) {
            successfully_validated_high.push(`Depth is ${this.state.validation_report['High']['dimensions'].depth} inches`);
            successfully_validated_high.push(`Height is ${this.state.validation_report['High']['dimensions'].height} inches`);
            successfully_validated_high.push(`Width is ${this.state.validation_report['High']['dimensions'].width} inches`);
          }
          if (this.state.validation_report['High'].vertexCount) {
            successfully_validated_high.push(`Vertex Count is ${this.state.validation_report['High'].vertexCount}`);
          }
          if (this.state.validation_report['High']['space_information']) {
            if (this.state.validation_report['High']['space_information']['sun']) {
              if (this.state.validation_report['High']['space_information']['sun'].valid && !this.state.validation_report['High']['space_information']['sun'].fixed) {
                successfully_validated_high.push('Sun Node');
              }
              if (!this.state.validation_report['High']['space_information']['sun'].valid && this.state.validation_report['High']['space_information']['sun'].fixed) {
                automatically_fixed_high.push('Sun Node');
              }
              if (!this.state.validation_report['High']['space_information']['sun'].valid && !this.state.validation_report['High']['space_information']['sun'].fixed) {
                invalid_data_high.push(<span>Sun Node (Refer to Section 8 of <a href={SPACE_GUIDELINES} target="_blank">guidelines</a>)</span>);
              }
            }
            if (this.state.validation_report['High']['space_information']['lights'] != undefined) {
              if (this.state.validation_report['High']['space_information']['lights'].valid) {
                successfully_validated_high.push('Lights');
              } else if (!this.state.validation_report['High']['space_information']['lights'].valid) {
                invalid_data_high.push(<span>Lights are Missing (Refer to Section 8 of <a href={SPACE_GUIDELINES} target="_blank">guidelines</a>)</span>);
              }
            }

            if (this.state.validation_report['High']['space_information']['asset_node']) {
              if (!this.state.validation_report['High']['space_information']['asset_node'].valid && this.state.validation_report['High']['space_information']['asset_node'].fixed) {
                automatically_fixed_high.push('Space Node');
              }
          
              if (this.state.validation_report['High']['space_information']['asset_node'].valid && !this.state.validation_report['High']['space_information']['asset_node'].fixed) {
                successfully_validated_high.push('Space Node');
              }
          
              if (!this.state.validation_report['High']['space_information']['asset_node'].valid && !this.state.validation_report['High']['space_information']['asset_node'].fixed) {
                invalid_data_high.push(<span>Space Node (Refer to Section 5 of <a href={SPACE_GUIDELINES} target="_blank">guidelines</a>)</span>);
              }
            }
          
            if (this.state.validation_report['High']['space_information']['areas']) {
              this.state.validation_report['High']['space_information']['areas'].map((area) => {
                if (area.valid) {

                } else if (!area.valid && !area.fixed) {
                  if (area.structure && !area.structure.valid) {
                    invalid_data_high.push(<span>Invalid Structure for {area.name} (Refer to section 6 of <a href={SPACE_GUIDELINES} target="_blank">guidelines</a>)</span>);
                  } 
                  if (area.windows) {
                    if (!area.windows.valid) {
                      invalid_data_high.push(<span>Invalid Windows for {area.name} (Refer to section 6 of <a href={SPACE_GUIDELINES} target="_blank">guidelines</a>)</span>);
                    }
                  }
                  if (!area.structure && !area.windows) {
                    invalid_data_high.push(<span>Incorrect Area {area.name} found (Refer to section 5 and 6 of <a href={SPACE_GUIDELINES} target="_blank">guidelines</a>)</span>);
                  }
                } 
                else if (!area.valid && area.fixed) {
                  if (area.structure && area.structure.fixed) {
                    automatically_fixed_high.push(`Fixed Structure for ${area.name}`);
                  } 
                  if (area.windows) {
                    if (area.structure && area.structure.fixed) {
                      automatically_fixed_high.push(`Fixed Windows for ${area.name}`);
                    }
                  }
                }
              });
            }
          }
          if (this.state.validation_report['High']['vray_materials']) {
            this.state.validation_report['High']['vray_materials'].map((material) => {
              if (!material.valid) {
                invalid_data_high.push(<span> {material.name} is not a valid VRay Material (Refer to section 10 of <a href={SPACE_GUIDELINES} target="_blank">guidelines</a>)</span>);
              }
            });
          }

          if (this.state.validation_report['High']['vray_textures']) {
            this.state.validation_report['High']['vray_textures'].map((texture) => {
              if (!texture.found) {
                invalid_data_high.push(<span>{texture.name} was missing in VRay Textures (Refer to section 10 of <a href={SPACE_GUIDELINES} target="_blank">guidelines</a>)</span>);
              }
            });
          }
        }
      }
      success_val_length = successfully_validated_high.length;
      auto_val_length = automatically_fixed_high.length;
      invalid_val_length = invalid_data_high.length;

      if (invalid_val_length == 0) {
        this.setState({
          reupload_flag: false
        });
      }

      if (this.state.validation_report != undefined  && (success_val_length != 0 || auto_val_length != 0 || invalid_val_length != 0)) {
        if (this.state.validation_report.length == 0) {
          this.setState({
            validation_generated: false,
          });
        } else {
          this.setState({
            validation_generated: true,
          });
          if (this.state.validation_report['High']) {
            if (this.state.validation_report['High'].report) {
              this.setState({
                validation_check: true,
              });
              if (invalid_val_length > 0) {
                this.setState({
                  reupload_flag: true
                });
              } else if (invalid_val_length == 0) {
                if (this.state.validation_report['High'].space_valid) {
                  this.setState({
                    reupload_flag: false
                  });
                }
                else {
                  this.setState({
                    reupload_flag: true,
                    space_valid: false
                  });
                }
              }
            }
            else {
              this.setState({
                validation_check: false,
                reupload_flag: true
              });
              if (!this.state.validation_report['High'].space_valid) {
                this.setState({
                  space_valid: false
                });
              }
            }
          }
        }
      } else {
        if (this.state.validation_report && this.state.validation_report['High']) {
            if (this.state.validation_report['High'].report) {
              this.setState({
                validation_check: true,
              });
              if (invalid_val_length > 0) {
                this.setState({
                  reupload_flag: true
                });
              } else if (invalid_val_length == 0) {
                if (this.state.validation_report['High'].space_valid) {
                  this.setState({
                    reupload_flag: false
                  });
                }
                else {
                  this.setState({
                    reupload_flag: true,
                    space_valid: false
                  });
                }
              }
            }
            else {
              this.setState({
                validation_check: false,
                reupload_flag: true
              });
              if (!this.state.validation_report['High'].space_valid) {
                this.setState({
                  space_valid: false
                });
              }
            }
          } else {
            this.setState({
              validation_generated: false,
              reupload_flag: true
            });
          }

      }


      this.setState({
        automatically_fixed_high: automatically_fixed_high,
        successfully_validated_high: successfully_validated_high,
        invalid_data_high: invalid_data_high,
        success_val_length: success_val_length,
        invalid_val_length: invalid_val_length,
        auto_val_length: auto_val_length
      });
    }

    render() {
        let download_product_max_files = null;
        if (this.state && this.state.test_products && this.state.test_products.length > 0){
          download_product_max_files = <Menu style={{ width: "200px",fontFamily:"Avenir" }}>	
          {this.state.test_products.map((item, index) => {
            return (<Menu.Item key={index+1}>	
                <a target="_blank" href={item.max_link}> {item.product_name} <DownloadOutlined style={{float: "right"}} type="download" /></a>
              </Menu.Item>)
          })}
          </Menu>
        }

        let modal_rejections_data = '';

        if (this.state.rejections_data[0] != undefined) {
            modal_rejections_data = <div style={{margin: '24px 16px'}}>
                <div className='artist-upload-txt small align-text'>Date <span style={{color:"#333333"}}>{this.state.rejections_data[0].qa_date || '-'}</span></div>
                <div className="flagged-txt gray small">
                  Comment
                </div>
                <div className="flagged-txt black small" style={{marginBottom: 16}}>
                  <ul> 
                    {this.state.rejections_data[0].rejection_details.split('\n').map((item, index) => {
                      return <li>{item}</li>
                    })}
                  </ul>
                </div>
      
                {(this.state.rejections_data[0].reference_files != [] && this.state.rejections_data[0].reference_files != undefined) && (typeof (this.state.rejections_data[0].reference_files) == "object" && Object.keys(this.state.rejections_data[0].reference_files).length != 0) ?
      
                  <span>
                  <div className="flagged-txt gray small">
                    Uploads
                  </div>
                  {this.state.rejections_data[0].reference_files && this.state.rejections_data[0].reference_files.map((file,index) => (
                      <a className="upload-a-settings" target="_blank" href={ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file['uid'] + '/' + file['name'])}>
                        {(file.name.includes('.jpg') || file.name.includes(".png") || file.name.includes(".svg")) ? 
                        <img className="upload-image-settings" src={ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(file['uid'] + '/' + file['name'])}/> :
                        (file.name.includes(".tiff") ) ?  <img className="upload-image-settings" src={'/img/tiff_icon.png'}/> : 
                        <img className="upload-image-settings" src={'/img/file-icon.png'}/> }
                        <div className="flagged-txt upload small">{file.name}</div>
                      </a>
                    ))}
                </span>: ''}
              </div>
          }

        return (
            this.state.loading_state ? <DottedLoader/> : <ArtistMainLayout selected='2'>
                {this.state.room_guidelines?.assigned_artist == localStorage.getItem('username') ?
                <React.Fragment>
                <Row style={{display:"flex",justifyContent:"flex-start",marginTop: 60}}>
                    <Col span={24}>
                        <h2 className="product-h2" style={{marginBottom:"42px"}}>
                            <span><Link to={'/artist_rooms'} className="store-bd">Artist Rooms</Link>  / <span className='active-route-link-color'>{this.state.room_guidelines && this.state.room_guidelines.room_name}</span></span>
                        </h2>
                    </Col>
                </Row>
                <Row style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                <Col>
                    <span className="artist-product-heading">{this.state.room_guidelines && this.state.room_guidelines.room_name}</span>
                </Col>
                <Col>
                    {this.state.room_guidelines && (this.state.room_guidelines.model_status == 2 || this.state.room_guidelines.model_status == -4) && !this.state.disable_upload ?
                    <div style={{marginBottom: 10}}>
                        <Button className="modal-okay square" onClick={() => window.location.href = ('/artist_space_test/' + this.props.match.params.id)}>
                            <span className="modal-okay-text">Test Your GLB</span>
                        </Button>
                    </div>:''}
                    {this.state.room_guidelines && 
                    <div className="note-bg-artist">
                    {this.state.room_guidelines.model_retries < retry_count ?
                    <span>
                        <span className="note-text">
                        Attempt {this.state.room_guidelines.model_retries}/{retry_count}.&nbsp;
                        </span> 
                        <span className="note-text">
                        You have a total of {retry_count - this.state.room_guidelines.model_retries} attempts to get this model right.
                        </span> 
                    </span>: (this.state.room_guidelines.model_retries == retry_count ?
                    <span>
                        <span className="note-text red">
                        Attempt {retry_count}/{retry_count}.&nbsp;
                        </span> 
                        <span className="note-text red">
                        You can no longer upload another Model. Contact Admin.
                        </span>
                    </span> : 
                        <span className="note-text">
                        You have a total of {retry_count - this.state.room_guidelines.model_retries} attempts to get this model right.
                        </span>) }
                    </div>}
                </Col>
                </Row>

                {this.state.room_guidelines && <Row style={{marginTop: 22,marginBottom: 24}}>
                {this.state.room_guidelines.category == "" ? "" : <Col style={{display:"flex",alignItems:"center",marginRight:'40px'}}>
                    <span className="category-artist" style={{marginRight:16}}>Category</span>
                    <div className ="note-bg-artist gray">
                    <span className="note-text gray">{this.state.room_guidelines.category}</span>
                    </div>
                </Col>}
                <Col style={{display:"flex",alignItems:"center"}}>
                    <span className="category-artist" style={{marginRight:16}}>ID</span>
                    <div className ="note-bg-artist gray">
                    <span className="note-text gray">{this.props.match.params.id}</span>
                    </div>
                </Col>
                </Row>}

                {this.state.room_guidelines && this.state.room_guidelines.model_status == -4 ? <span>
                  
                  {this.state.room_guidelines.model_retries < retry_count ? <Row style={{marginBottom: 32}}>
                    <Col span={24}>
                      <div style={{background: "rgba(39, 109, 215, 0.06)",borderRadius: "4px",padding:"10px 16px",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                          <span className="scene-note flag">
                            Do you want to resubmit your model?
                          </span>
                          <span style={{display:"flex"}}>
                            <Button className="modal-okay-orange" style={{marginRight:"16px"}} disabled={this.state.room_guidelines.model_retries >= retry_count} onClick={() => { this.setState({rejected_upload_modal: true})}}>
                              <span className="modal-okay-text">Re-Upload Your File</span>
                            </Button>
                          </span>
                      </div>
                    </Col>
                  </Row>: ''}
                  </span> : ''
                }
                {this.state.room_guidelines && this.state.room_guidelines.model_status == 8 && this.state.validation_check ? 
                  !this.state.reupload_flag ? 
                    <Row style={{marginBottom: 32}}>
                        <Col span={24}>
                            <div style={{background: "rgba(39, 109, 215, 0.06)",borderRadius: "4px",padding:"10px 16px",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                <span className="scene-note flag">
                                Uploaded model is ready to be submitted. Review to re-upload or Submit.
                                </span>
                                <span style={{display:"flex"}}>
                                  <Button className="modal-okay-orange" style={{marginRight:"16px"}} disabled={this.state.room_guidelines.model_retries >= retry_count} onClick={() => { this.setState({confirmation_modal: true})}}>
                                    <span className="modal-okay-text">Delete and Re-Upload Your File</span>
                                  </Button>
                                  <Button className="modal-okay" onClick={() => this.setState({open_input_modal: true})}>
                                      <span className="modal-okay-text">Submit Your Model</span>
                                  </Button>
                                </span>
                            </div>
                        </Col>
                    </Row>: '' : ''}
                
                { this.state.room_guidelines && (this.state.room_guidelines.model_status == 2 || this.state.room_guidelines.model_status == 6 || this.state.status == 6) ? 
                    <Row>
                    <Col span={18} style={{marginBottom: this.state.room_guidelines.model_status != 2 ? 8 : 40,display:"block"}}>
                    {/* Display option for downloading products */}
                    { this.state.room_guidelines && this.state.room_guidelines.model_status == 2 ?
                      <div style={{display: 'inline'}}>
                        <span style={{color: '#555555'}}>Please create two renders on your machine using the space model and sample models and upload these renders.<br />
                        <strong>Requirements:</strong><br />
                        - Download the sample models by clicking <div style={{display: 'inline'}}>
                          <Dropdown overlay={download_product_max_files} trigger={['click']} >
                            <span style={{color: '#276DD7', textDecoration: 'underline', cursor: 'pointer', display: 'inline-block'}}>here</span> 
                          </Dropdown>
                        </div><br />
                        - Put these models in your space. Take two renders of 1K at most: one head on shot and another angled perpective.<br />
                        - Check that there are no issues in terms of lighting and upload these two renders.<br />
                        - Upload the space model (without any sample models).</span>                        
                      </div>:""
                    } 
                    {this.state.room_guidelines && (this.state.room_guidelines.model_status == 2 || this.state.room_guidelines.model_status == -4) && this.state.disable_upload ?
                    <div className="artist-upload-txt upload-overlay align-bottom ">
                        Test your GLB first and submit checklist to enable space upload
                        <Button className="modal-okay square upload-test-button" onClick={() => window.location.href = ('/artist_space_test/' + this.props.match.params.id)}>
                            <span className="modal-okay-text">Test Your GLB</span>
                        </Button>
                    </div>:''}
                    {/* Upload Model Archive */}
                    <div style={{opacity: (this.state.room_guidelines && (this.state.room_guidelines.model_status == 2 || this.state.room_guidelines.model_status == -4) && this.state.disable_upload) ? 0.1 : ''}}>
                    <h4 style={{paddingTop: 25}}>Upload Space Model <span style={{color: 'red'}}>*</span></h4>
                    <div style={{marginBottom: this.state.room_guidelines.model_status != 2 ? 8 : 40,display:"block"}}>
                      <div className="dragger-upload-div" style={{padding:"20px"}}>
                        <Dragger 
                        style={{display: 'inline-block', width: "100%"}}
                            className="upload-dragger-artist"
                            multiple={false}
                            progress= {{
                              strokeColor: {
                              '0%': '#108ee9',
                              '100%': '#87d068',
                              },
                              strokeWidth: 3,
                              format: percent => `${parseFloat(percent.toFixed(2))}%`,
                              }}
                            disabled={this.state.room_guidelines.model_retries >= retry_count || this.state.status == 6 || this.state.room_guidelines.model_status == 6}
                            beforeUpload={file => {
                              if(file.name != this.props.match.params.id + '.zip'){
                                this.setState({
                                  upload_error: "Invalid archive name, archive must be named " + this.props.match.params.id + '.zip'
                                });
                                return false;
                              }
                              else{
                                return this.checkFolderStructure(file);
                              }
                            }}
                            {...(Constants.getRoomModelUploadProp(this.state.platform))}
                            onChange = {this.handleChange}
                            openFileDialogOnClick={!(this.validateFileUploads(this.state.modelFileList) == 'error')}
                            onRemove={file => {
                              this.setState(state => {
                                const newFileList = []
                                return {
                                  modelFileList: newFileList,
                                };
                              });
                              this.setState({
                                upload_error: '',
                                upload_warning: '',
                                upload_failed_message: ''
                              });
                            }}
                            listType="text"
                            fileList = {this.state.modelFileList}>
                              <span className='model-upload-dragger'></span>
                              {this.validateFileUploads(this.state.modelFileList) == "uploading"  ? 
                                <span>
                                  <p className="artist-upload-txt large">Upload in Progress... </p>
                                </span> : 
                                this.validateFileUploads(this.state.modelFileList) == "done" ? 
                                <span>
                                  <div className="justify-in-center">
                                    <img height="25px" width="25px" src="/img/success.png" style={{marginRight: 5}}></img>
                                    <p className="artist-upload-txt large" style={{color:"#25D6A4"}}>Upload Successful</p>
                                  </div>                        
                                </span>
                                :
                                this.validateFileUploads(this.state.modelFileList) == "error" ? 
                                <span>
                                  <div className="justify-in-center">
                                    <img height="25px" width="25px" src="/img/alert-triangle.png" style={{marginRight: 5}}></img>
                                    <p className="artist-upload-txt large" style={{color:"#D93025"}}>Upload Error</p>
                                    {(this.validateFileUploads(this.state.modelFileList) == 'error') && (<Button
                                          onClick={() => Constants.triggerFailedFilesUpload('.model-upload-dragger', this.state.modelFileList, this.changeModelFileList)}
                                          disabled={this.state.modelFileList.length === 0}
                                          style={{position: "unset",marginLeft: 8}}
                                          loading={this.state.uploading}
                                          className="retry-btn" ghost
                                          >
                                          {this.props.uploading ? <span>Retrying...</span>  : 'Upload Failed. Click to Retry.'}
                                      </Button>)}
                                  </div>                        
                                </span> 
                                 :
                            this.state.room_guidelines.model_status == 2 ? 
                            <span>
                              <p className="ant-upload-drag-icon">
                              <img src="/img/exclude.png"></img>
                              </p>
                              <p className="artist-upload-txt">Drop your product model’s <span style={{color:"#333333"}}>Archive</span> folder here, or <span style={{color: "#276DD7"}}>Browse it</span>.</p>
                              <p className="artist-upload-txt small">
                                Only .zip files are supported     
                              </p>
                            </span>: (this.state.room_guidelines.model_status == 6 || this.state.status == 6) ?
                            <span>
                            <p className="ant-upload-drag-icon">
                            <LoadingOutlined className="loading-bg"/>
                            </p>
                            <p className="artist-upload-txt">Please wait. We are running some validation checks on your upload.</p>
                          </span> : (this.state.status == 3) ?
                            <span>
                            <p className="ant-upload-drag-icon">
                            <LoadingOutlined className="loading-bg"/>
                            </p>
                            <p className="artist-upload-txt">Thanks for waiting. Your model validation is almost complete. You will be directed to validation summary page shortly.</p>
                          </span>: ""}
                        </Dragger>
                      </div>
                      {this.state.upload_error == "" ? "" :
                        <div style={{marginBottom: 8}}>
                          <span className="category-artist red">{this.state.upload_error}</span>
                        </div>}
                      {this.state.upload_warning == "" ? "" :
                      <div style={{marginBottom: 8}}>
                        <span className="category-artist yellow">{this.state.upload_warning}</span>
                      </div>}

                      {/* Upload Artist Test renders */}
                      {this.state.room_guidelines.model_status == 6 && (this.state.room_guidelines.test_renders && this.state.room_guidelines.test_renders.length == 0) ? "" :
                      <React.Fragment>
                        <h4 style={{paddingTop: 15}}>Upload Sample Renders <span style={{color: 'red'}}>*</span></h4>
                        <div style={{padding:"20px", marginBottom: 10}} className="dragger-upload-div">
                            <Dragger multiple={true}
                            className="upload-dragger-artist"
                            accept=".jpg,.jpeg,.png"
                            progress= {{
                              strokeColor: {
                              '0%': '#108ee9',
                              '100%': '#87d068',
                              },
                              strokeWidth: 3,
                              format: percent => `${parseFloat(percent.toFixed(2))}%`,
                              }}
                            disabled={this.state.room_guidelines.model_retries >= retry_count || this.state.status == 6 || this.state.room_guidelines.model_status == 6}
                            {...(Constants.getArtistTestRendersProps(this.state.platform))}
                            onChange = {this.handleTestRenderUpload}
                            onRemove={file => {
                              this.setState(state => {
                                const index = state.testRenderList.indexOf(file);
                                const newFileList = state.testRenderList.slice();
                                newFileList.splice(index, 1);
                                return {
                                  testRenderList: newFileList,
                                };
                              });
                            }}
                            listType="text"
                            fileList = {this.state.testRenderList}>
                              {this.validateFileUploads(this.state.testRenderList) == "uploading"  ? 
                                <span>
                                  <p className="artist-upload-txt large">Upload in Progress... </p>
                                </span> : 
                                this.validateFileUploads(this.state.testRenderList) == "done" ? 
                                <span>
                                  <div className="justify-in-center">
                                    <img height="25px" width="25px" src="/img/success.png" style={{marginRight: 5}}></img>
                                    <p className="artist-upload-txt large" style={{color:"#25D6A4"}}>Upload Successful</p>
                                  </div>                        
                                </span> :
                              this.state.room_guidelines.model_status == 2 ? 
                              <span>
                                <p className="ant-upload-drag-icon">
                                <img src="/img/exclude.png"></img>
                                </p>
                                <p className="artist-upload-txt">Drop your space model’s <span style={{color:"#333333"}}>Artist Sample Renders</span> here, or <span style={{color: "#276DD7"}}>Browse them</span>.</p>
                                <p className="artist-upload-txt small">
                                  Only .PNG and .JPG files are supported     
                                </p>
                              </span>: ((this.state.room_guidelines.model_status == 6 || this.state.status == 6) ?
                              <span>
                              <p className="ant-upload-drag-icon">
                              <LoadingOutlined className="loading-bg"/>
                              </p>
                              <p className="artist-upload-txt">Please wait. We are running some validation checks on your upload.</p>
                            </span> : (this.state.status == 3) ?
                              <span>
                              <p className="ant-upload-drag-icon">
                              <LoadingOutlined className="loading-bg"/>
                              </p>
                              <p className="artist-upload-txt">Thanks for waiting. Your model validation is almost complete. You will be directed to validation summary page shortly.</p>
                            </span>: "")}
                          </Dragger>
                        </div>
                      </React.Fragment>}
                      
                      {this.state.test_render_upload_error == "" ? "" :
                        <div style={{marginBottom: 8}}>
                          <span className="category-artist red">{this.state.test_render_upload_error}</span>
                        </div>}
                      </div>
                        {this.state.room_guidelines && this.state.room_guidelines.model_status == 2 ?
                        <div style={{marginTop: 15}}>
                          <span style={{color: '#777777'}}><span style={{color: 'red'}}>*</span> Mandatory Fields</span>
                          <Button style={{float: 'right', marginTop: 5}} className="modal-okay square" onClick={this.submitModelUpdateRequest} disabled={this.state.uploading_model_flag==true || this.state.testRenderList.length < 2 || this.state.modelFileList.length == 0 || this.validateFileUploads(this.state.testRenderList) != "done" || this.validateFileUploads(this.state.modelFileList) != "done" || this.state.upload_error != "" || this.state.test_render_upload_error != ""}>
                            <span className="modal-okay-text">Upload Model and Sample Renders  {(this.state.uploading_model_flag == true)? <LoadingOutlined />:""}</span>
                          </Button>
                        </div> : ""}
                        </div>
                    </Col>
                    </Row> : ""}
                    {/* The following snippet of code has been moved form roomStaticGuidelines */}
                    {/* {!this.state.validation_generated && this.state.room_guidelines.model_status==8 ?
                    <Row>
                      <Col span={24} style={{paddingRight: "5%"}}>
                        <div style={{background: " #FEF6F6",padding:"16px",justifyContent:"flex-start",alignItems:"center"}}>
                            <span className="scene-note flag red" style={{display: 'block'}}>
                              <span>
                                Validation Summary could not be generated. Please reupload your file.
                              </span>
                                {this.state.reupload_flag ?
                                <span style={{float: 'right'}}>
                                  <Button className="modal-okay-orange" disabled={this.state.room_guidelines.model_retries >= retry_count} onClick={() => { this.setState({confirmation_modal: true})}}>
                                    <span className="modal-okay-text">Delete and Re-Upload Your File</span>
                                  </Button>
                                </span>: ""}
                            </span>
                        </div>
                      </Col>
                    </Row>:""} */}
                    {this.state.upload_failed_message == "" ? "" :
                        <div style={{marginBottom: 8}}>
                          <span className="category-artist red">{this.state.upload_failed_message}</span>
                        </div>}
                    <Row type="flex" style={{paddingTop: 15}}>
                      <Col span={24}>
                      <RoomStaticGuidelines
                            changeUploadFailedError={this.changeUploadFailedError}
                            setReuploadConfirmationModel={this.setReuploadConfirmationModel}
                            handleRetry={this.handleRetry}
                            uploading={this.state.uploading}
                            hasError={this.state.hasError}
                            validateFileUploads={this.validateFileUploads}
                            changeModelFileList={this.changeModelFileList}
                            room_guidelines={this.state.room_guidelines}
                            space_valid={this.state.space_valid}
                            modelFileList={this.state.modelFileList}
                            validation_check={this.state.validation_check}
                            changeUploadError={this.changeUploadError}
                            changeUploadWarning={this.changeUploadWarning}
                            retry_count={retry_count}
                            status={this.state.status}
                            upload_error={this.state.upload_error}
                            upload_warning={this.state.upload_warning}
                            upload_failed_message={this.state.upload_failed_message}
                            handleReUploadChange={this.handleReUploadChange} 
                            reupload_flag={this.state.reupload_flag}
                            validation_generated={this.state.validation_generated}
                            invalid_data_high={this.state.invalid_data_high}
                            automatically_fixed_high={this.state.automatically_fixed_high}
                            auto_val_length={this.state.auto_val_length}
                            invalid_val_length={this.state.invalid_val_length}
                            successfully_validated_high={this.state.successfully_validated_high}
                            validation_report={this.state.validation_report} 
                            success_val_length={this.state.success_val_length} 
                            room_id={this.props.match.params.id}
                            platform={this.state.platform}
                            disable_upload={this.state.disable_upload}/>
                      </Col>
                    </Row>                 

            <Modal visible={this.state.open_input_modal}
            className="model-time-modal"
            maskClosable={false}
            onCancel={()=> { this.setState({open_input_modal: false})}}>
            <div style={{padding: "32px 32px 2px 32px"}}>
              <div className="model-time-h" style={{marginBottom: 30}}>Upload Model and Enter Modelling Time (In Hours)</div>
              <Form className="artist-form"  onFinish={this.handleSubmit}>
              {(this.state.rejections_data && this.state.rejections_data.length == 0)?
              <span>
                  <Form.Item name="time1" className="artist-upload-txt align-text" label={"High Poly"} colon={false}
                  rules={[this.state.input_fields_rules['time1']]}>
                      <Input
                          step="0.01"
                          type="number"
                          min="0.01" max="30"
                          defaultValue={this.state.time1}
                          placeholder="Please enter time (in hours) it took to make high poly."
                      />
                  </Form.Item>
                  <Form.Item name="time3" rules={[this.state.input_fields_rules['time3']]} className="artist-upload-txt align-text" label={"VRay Textures"} colon={false}>
                    <Input
                        step="0.01"
                        type="number"
                        min="0.01" max="30"
                        defaultValue={this.state.time3}
                        placeholder="Please enter time (in hours) it took to create VRay Textures."
                    />
                  </Form.Item>
              </span>:
              <span>
                  <div style={{ padding: "12px", marginBottom: "24px", marginTop: "24px" }}>
                      <Row type="flex" >
                          <Col span={24}>
                          <div className="flagged-bg border custom">
                              {modal_rejections_data}  
                          </div>
                          </Col>
                      </Row>
                  </div>
                  
                  <Form.Item rules={[this.state.input_fields_rules['fix_time']]} name="fix_time" className="artist-upload-txt align-text" label={"Enter Time It Took To Fix The Issue(s)"} colon={false}>
                    <Input
                        step="0.01"
                        type="number"
                        min="0.01" max="30"
                        placeholder="Please enter time (in hours) it took to fix the issue(s). Example, 2 hours."
                    />
                  </Form.Item>
              </span>
              }

              <span>
                  {(this.state.upload_error != "")?
                  <div>
                      <span className="category-artist red">{this.state.upload_error}</span>
                  </div>:""
                  }
                   {(this.state.upload_failed_message != "")?
                  <div>
                      <span className="category-artist red">{this.state.upload_failed_message}</span>
                  </div>:""
                  }
              </span>
              <br />
              <Form.Item style={{textAlign:'right',marginTop:"-30px"}}>
                  <Button type="primary" className="modal-okay square" htmlType="submit" disabled={(this.state.request_submitted == true)}>
                    <div className="modal-okay-text">  Submit {(this.state.request_submitted == true)? <LoadingOutlined />:""}</div>
                  </Button>
              </Form.Item>
              </Form>
            </div>

            </Modal>

            <Modal
            className="silo-success-modal"
            centered={true}
            maskClosable={false}
            closable
            width={800}
            onCancel={() => this.setState({success_modal: false})}
            visible={this.state.success_modal}
            bodyStyle={{padding: 0}}
            centered={true}
            footer={[
                <Button className="modal-okay square" htmlType="submit" onClick={() => {
                    window.location.href = "/artist_rooms/";
                }}>
                    <div className="modal-okay-text">Okay</div>
                </Button>
            ]}>
                <div style={{padding:"40px"}}>
                    <div style={{textAlign: "center"}}>
                        <img style={{width: 85,height:85}} src={require("../../../../assets/images/success-icon.png")}></img>
                    </div>
                    <div style={{textAlign: "center",margin: "20px 0"}}>
                        <h2 className="modal-heading">Model Submitted Successfully!</h2>
                        <p className="modal-text">You will be notified once the model is reviewed by the admin and the customer.</p>
                    </div>     
                </div>       
            </Modal>

            <Modal
            className="modal-lifestyle-request"
            centered={true}
            maskClosable={false}
            width={800}
            onCancel={() => this.setState({confirmation_modal: false})}
            visible={this.state.confirmation_modal}
            bodyStyle={{padding: 0}}
            centered={true}
            footer={[
              <div className="justify-in-center">
                <Button className="modal-okay-gray square font-14" disabled={this.state.req_btn_loader} htmlType="submit" onClick={() => this.setState({confirmation_modal: false})}>
                    Cancel
                </Button>
                <Button className="modal-okay-orange square" disabled={this.state.req_btn_loader} htmlType="submit" onClick={this.reuploadModel}>
                  <div className="modal-okay-text">{(this.state.room_guidelines && this.state.room_guidelines.model_status == -4) ? <span>Re-upload Model</span> : <span>Delete and Re-upload Model</span>} {this.state.req_btn_loader ? <LoadingOutlined/> : ''}</div>
                </Button>
              </div>
            ]}>
                <div style={{padding:"40px"}}>
                    <div style={{textAlign: "center",margin: "20px 0"}}>
                        <h2 className="modal-heading">{(this.state.room_guidelines && this.state.room_guidelines.model_status == -4) ? <span>Are you sure you want to re-upload your model?</span> : <span>Are you sure you want to delete and re-upload your model?</span>}</h2>
                        <p className="artist-upload-txt">You will be redirected to model upload page.</p>
                    </div>     
                </div>       
            </Modal>
            <Modal
            visible={this.state.rejected_upload_modal}
            className="modal-space-rejected-reupload"
            maskClosable={false}
            footer={false}
            onCancel={()=> { this.setState({rejected_upload_modal: false})}}>
              <div style={{padding: "32px 32px 2px 32px"}}>
                <div className="model-time-h" style={{marginBottom: 30}}>Upload Model and Enter Modelling Time (In Hours)</div>
                <div style={{display: 'inline'}}>
                  <span style={{color: '#555555'}}>Please create two renders on your machine using the space model and sample models and upload these renders.<br />
                  <strong>Requirements:</strong><br />
                  - Download the sample models by clicking <div style={{display: 'inline'}}>
                    <Dropdown overlay={download_product_max_files} trigger={['click']} >
                      <span style={{color: '#276DD7', textDecoration: 'underline', cursor: 'pointer', display: 'inline-block'}}>here</span> 
                    </Dropdown>
                  </div><br />
                  - Put these models in your space. Take two renders of 1K at most: one head on shot and another angled perpective.<br />
                  - Check that there are no issues in terms of lighting and upload these two renders.<br />
                  - Upload the space model (without any sample models).</span>                        
                </div>
                {this.state.room_guidelines && (this.state.room_guidelines.model_status == 2 || this.state.room_guidelines.model_status == -4) && this.state.disable_upload ?
                  <div className="artist-upload-txt upload-overlay">
                      Test your GLB first and submit checklist to enable space upload
                      <Button className="modal-okay square upload-test-button" onClick={() => window.location.href = ('/artist_space_test/' + this.props.match.params.id)}>
                          <span className="modal-okay-text">Test Your GLB</span>
                      </Button>
                  </div>
                :''}
                {/* Upload Model Archive */}
                <div style={{opacity: (this.state.room_guidelines && (this.state.room_guidelines.model_status == 2 || this.state.room_guidelines.model_status == -4)) && this.state.disable_upload ? 0.1 : ''}}>
                <h4 style={{paddingTop: 25}}>Upload Space Model <span style={{color: 'red'}}>*</span></h4>
                <div style={{marginBottom: this.state.room_guidelines.model_status != 2 ? 8 : 40,display:"block"}}>
                    <div className="dragger-upload-div" style={{padding:"20px"}}>
                      <Dragger 
                      style={{display: 'inline-block', width: "100%"}}
                          className="upload-dragger-artist"
                          multiple={false}
                          progress= {{
                            strokeColor: {
                            '0%': '#108ee9',
                            '100%': '#87d068',
                            },
                            strokeWidth: 3,
                            format: percent => `${parseFloat(percent.toFixed(2))}%`,
                            }}
                          disabled={this.state.room_guidelines.model_retries >= retry_count || this.state.status == 6 || this.state.room_guidelines.model_status == 6}
                          beforeUpload={file => {
                            if(file.name != this.props.match.params.id + '.zip'){
                              this.setState({
                                upload_error: "Invalid archive name, archive must be named " + this.props.match.params.id + '.zip'
                              });
                              return false;
                            }
                            else{
                              return this.checkFolderStructure(file);
                            }
                          }}
                          {...(Constants.getRoomModelUploadProp(this.state.platform))}
                          onChange = {this.handleChange}
                          openFileDialogOnClick={!(this.validateFileUploads(this.state.modelFileList) == 'error')}
                          onRemove={file => {
                            this.setState(state => {
                              const newFileList = []
                              return {
                                modelFileList: newFileList,
                              };
                            });
                            this.setState({
                              upload_error: '',
                              upload_warning: '',
                              upload_failed_message: ''
                            });
                          }}
                          listType="text"
                          fileList = {this.state.modelFileList}>
                            <span className='model-upload-dragger2'></span>
                            {this.validateFileUploads(this.state.modelFileList) == "uploading"  ? 
                              <span>
                                <p className="artist-upload-txt large">Upload in Progress... </p>
                              </span> : 
                              this.validateFileUploads(this.state.modelFileList) == "done" ? 
                              <span>
                                <div className="justify-in-center">
                                  <img height="25px" width="25px" src="/img/success.png" style={{marginRight: 5}}></img>
                                  <p className="artist-upload-txt large" style={{color:"#25D6A4"}}>Upload Successful</p>
                                </div>                        
                              </span>
                              :
                              this.validateFileUploads(this.state.modelFileList) == "error" ? 
                              <span>
                                <div className="justify-in-center">
                                  <img height="25px" width="25px" src="/img/alert-triangle.png" style={{marginRight: 5}}></img>
                                  <p className="artist-upload-txt large" style={{color:"#D93025"}}>Upload Error</p>
                                  {(this.validateFileUploads(this.state.modelFileList) == 'error') && (<Button
                                        onClick={() => Constants.triggerFailedFilesUpload('.model-upload-dragger2', this.state.modelFileList, this.changeModelFileList)}
                                        disabled={this.state.modelFileList.length === 0}
                                        style={{position: "unset",marginLeft: 8}}
                                        loading={this.state.uploading}
                                        className="retry-btn" ghost
                                        >
                                        {this.props.uploading ? <span>Retrying...</span>  : 'Upload Failed. Click to Retry.'}
                                    </Button>)}
                                </div>                        
                              </span> 
                                :
                            this.state.room_guidelines.model_status == -4 || this.state.room_guidelines.model_status == -5 ? 
                            <span>
                              <p className="ant-upload-drag-icon">
                              <img src="/img/exclude.png"></img>
                              </p>
                              <p className="artist-upload-txt">Drop your space model’s <span style={{color:"#333333"}}>Archive</span> folder here, or <span style={{color: "#276DD7"}}>Browse it</span>.</p>
                              <p className="artist-upload-txt small">
                                Only .zip files are supported     
                              </p>
                            </span>: (this.state.room_guidelines.model_status == 6 || this.state.status == 6) ?
                            <span>
                              <div className="justify-in-center">
                                <img height="25px" width="25px" src="/img/success.png" style={{marginRight: 5}}></img>
                                <p className="artist-upload-txt large" style={{color:"#25D6A4"}}>Upload Successful</p>
                              </div>                        
                            </span> :
                          this.state.room_guidelines.model_status == 2 ? 
                          <span>
                            <p className="ant-upload-drag-icon">
                            <img src="/img/exclude.png"></img>
                            </p>
                            <p className="artist-upload-txt">Drop your product model’s <span style={{color:"#333333"}}>Archive</span> folder here, or <span style={{color: "#276DD7"}}>Browse it</span>.</p>
                            <p className="artist-upload-txt small">
                              Only .zip files are supported     
                            </p>
                          </span>: (this.state.room_guidelines.model_status == 6 || this.state.status == 6) ?
                          <span>
                          <p className="ant-upload-drag-icon">
                          <LoadingOutlined className="loading-bg"/>
                          </p>
                          <p className="artist-upload-txt">Please wait. We are running some validation checks on your upload.</p>
                        </span> : (this.state.status == 3) ?
                          <span>
                          <p className="ant-upload-drag-icon">
                          <LoadingOutlined className="loading-bg"/>
                          </p>
                          <p className="artist-upload-txt">Thanks for waiting. Your model validation is almost complete. You will be directed to validation summary page shortly.</p>
                        </span>: ""}
                      </Dragger>
                    </div>
                    {this.state.upload_error == "" ? "" :
                      <div style={{marginBottom: 8}}>
                        <span className="category-artist red">{this.state.upload_error}</span>
                      </div>}
                    {this.state.upload_warning == "" ? "" :
                      <div style={{marginBottom: 8}}>
                        <span className="category-artist yellow">{this.state.upload_warning}</span>
                      </div>}

                    {/* Upload Artist Test renders */}
                    <h4 style={{paddingTop: 15}}>Upload Sample Renders <span style={{color: 'red'}}>*</span></h4>
                    <div style={{padding:"20px", marginBottom: 10}} className="dragger-upload-div">
                        <Dragger multiple={true}
                        className="upload-dragger-artist"
                        accept=".jpg,.jpeg,.png"
                        progress= {{
                          strokeColor: {
                          '0%': '#108ee9',
                          '100%': '#87d068',
                          },
                          strokeWidth: 3,
                          format: percent => `${parseFloat(percent.toFixed(2))}%`,
                          }}
                        disabled={this.state.room_guidelines.model_retries >= retry_count || this.state.status == 6 || this.state.room_guidelines.model_status == 6}
                        {...(Constants.getArtistTestRendersProps(this.state.platform))}
                        onChange = {this.handleTestRenderUpload}
                        onRemove={file => {
                          this.setState(state => {
                            const index = state.testRenderList.indexOf(file);
                            const newFileList = state.testRenderList.slice();
                            newFileList.splice(index, 1);
                            return {
                              testRenderList: newFileList,
                            };
                          });
                        }}
                        listType="text"
                        fileList = {this.state.testRenderList}>
                          {this.validateFileUploads(this.state.testRenderList) == "uploading"  ? 
                            <span>
                              <p className="artist-upload-txt large">Upload in Progress... </p>
                            </span> : 
                            this.validateFileUploads(this.state.testRenderList) == "done" ? 
                            <span>
                              <div className="justify-in-center">
                                <img height="25px" width="25px" src="/img/success.png" style={{marginRight: 5}}></img>
                                <p className="artist-upload-txt large" style={{color:"#25D6A4"}}>Upload Successful</p>
                              </div>                        
                            </span> :
                          this.state.room_guidelines.model_status == -4 || this.state.room_guidelines.model_status == -5 ? 
                          <span>
                            <p className="ant-upload-drag-icon">
                            <img src="/img/exclude.png"></img>
                            </p>
                            <p className="artist-upload-txt">Drop your space model’s <span style={{color:"#333333"}}>Artist Sample Renders</span> here, or <span style={{color: "#276DD7"}}>Browse them</span>.</p>
                            <p className="artist-upload-txt small">
                              Only .PNG and .JPG files are supported     
                            </p>
                          </span>: ((this.state.room_guidelines.model_status == 6 || this.state.status == 6) ?
                          <span>
                          <p className="ant-upload-drag-icon">
                          <LoadingOutlined className="loading-bg"/>
                          </p>
                          <p className="artist-upload-txt">Please wait. We are running some validation checks on your upload.</p>
                        </span> : (this.state.status == 3) ?
                          <span>
                          <p className="ant-upload-drag-icon">
                          <LoadingOutlined className="loading-bg"/>
                          </p>
                          <p className="artist-upload-txt">Thanks for waiting. Your model validation is almost complete. You will be directed to validation summary page shortly.</p>
                        </span>: "")}
                      </Dragger>
                    </div>
                    
                    {this.state.test_render_upload_error == "" ? "" :
                      <div style={{marginBottom: 8}}>
                        <span className="category-artist red">{this.state.test_render_upload_error}</span>
                      </div>}
                      
                      {this.state.room_guidelines && this.state.room_guidelines.model_status == 2 ?
                      <div style={{marginTop: 15}}>
                        <span style={{color: '#777777'}}><span style={{color: 'red'}}>*</span> Mandatory Fields</span>
                        <Button style={{float: 'right', marginTop: 5}} className="modal-okay square" onClick={this.submitModelUpdateRequest} disabled={this.state.uploading_model_flag==true || this.state.testRenderList.length < 2 || this.state.modelFileList.length == 0 || this.validateFileUploads(this.state.testRenderList) != "done" || this.validateFileUploads(this.state.modelFileList) != "done" || this.state.upload_error != "" || this.state.test_render_upload_error != ""}>
                          <span className="modal-okay-text">Upload Model and Sample Renders  {(this.state.uploading_model_flag == true)? <LoadingOutlined />:""}</span>
                        </Button>
                      </div> : ""}
                      </div>
                {this.state.upload_failed_message == "" ? "" :
                <div style={{marginBottom: 8}}>
                  <span className="category-artist red">{this.state.upload_failed_message}</span>
                </div>}
                {(this.state.rejections_data != undefined && this.state.rejections_data.length != 0) ? 
                <Row style={{marginTop: 10,marginBottom: 10}}>
                  <Col span={24}>
                    <div className="flagged-bg border custom">
                    {modal_rejections_data}  
                    </div>
                  </Col>
                </Row> : ''}
                <Form className="artist-form" onFinish={this.handleRejectedSubmit}>
                {(this.state.rejections_data != undefined && this.state.rejections_data.length != 0) ? 
                <Form.Item name="fix_time" className="artist-upload-txt align-text" rules={[this.state.input_fields_rules['fix_time']]} label={"Enter Time It Took To Fix The Issue(s)"} colon={false}>
                  <Input
                    type="number"
                    min="0.01" max="30"
                    step="0.01"
                    placeholder="Please enter time (in hours) it took to fix the issue(s)."
                  />
                </Form.Item>: ''}
                <Form.Item className="artist-form-button">
                  <Button disabled={(this.state.request_submitted === true || this.state.uploading_model_flag==true || this.state.testRenderList.length < 2 || this.state.modelFileList.length == 0 || this.validateFileUploads(this.state.testRenderList) != "done" || this.validateFileUploads(this.state.modelFileList) != "done" || this.state.upload_error != "" || this.state.test_render_upload_error != "")} className="modal-okay square" htmlType="submit">
                    <div className="modal-okay-text">Submit {this.state.request_submitted === true?<LoadingOutlined spin/>:""}</div>
                  </Button>
                </Form.Item>
              </Form>
              </div>
              </div>
            </Modal>
            </React.Fragment>
        :
        <NotAuthorized model_type="room"></NotAuthorized>
      }
            </ArtistMainLayout>
        );
    }
}


export default ArtistRoomGuidelines;