import React, { useState, useEffect, useCallback } from 'react';
import ENVIRONMENT from '../../../../environments';
import axios from 'axios';
import $ from 'jquery';
import * as Utilities from '../../Utilities';
import FileConstants from '../../../../FileConstants';
import AWS from "aws-sdk";
import ProductPageConstants from '../ProductComponents/ProductPageConstants';
import {
    message,
} from 'antd';
import CollaborateQAContext from '../../ContextFiles/CollaborateQAContext';
import {
    QA_IMG_URI, getBaseURL, SCANS_BASE_URL, BUCKET_NAME, PRODUCT_QA_COMB_URL,
    MATERIAL_FILE_URL, IMAGE_DIMENSIONAL_URI, PRODUCT_QA_COMB_URI
} from '../../../../environments/env';
import ProductQAPageView from './ProductQAPageView';
import CustomerMainLayout from '../CustomerMainLayout';
import CanvasEditor from '../../../../CanvasEditor';
import CommentHistory from '../CollaborateTool/CommentHistory';
import ShareProductModal from '../ProductComponents/ShareProductModal';
import ProductSelectionImageModal from './ProductSelectionImageModal';
import CollaborateAccess from '../CollaborateTool/CollaborateAccess';
import { isEqual, isEqualWith } from 'lodash-es';
import _ from 'lodash-es';
import CollaborateEmailSender from '../CollaborateTool/CollaborateEmailSender';
import CollaborateConstants from '../CollaborateTool/Constants';
import CustomerChangeLog from '../CustomerChangeLog/CustomerChangeLog';

const CANVAS_ID = 'canvas-editor-qa';
let CUSTOMER_USERNAME = localStorage.getItem('username');
let COMPANY_ID = localStorage.getItem("company_id");
let SHARED_ENTITIES = localStorage.getItem("shared_entities");
let SEQUENCE_IDS = FileConstants.SEQUENCE_IDS;
let MANAGED_CUSTOMER_USERNAME = localStorage.getItem('managed_customer_username');
let MANAGED_CUSTOMER_EMAIL = localStorage.getItem('managed_customer_email');
let IS_MSPROVIDER = localStorage.getItem("is_msprovider") === 'true' ? true : false;


// Function to recursively find and log differences
const findDifferences = (objValue, otherValue, path = []) => {
    if (_.isEqual(objValue, otherValue)) {
        return;
    }

    if (_.isObject(objValue) && _.isObject(otherValue)) {
        const keys = _.union(_.keys(objValue), _.keys(otherValue));

        for (const key of keys) {
            findDifferences(
                objValue[key],
                otherValue[key],
                [...path, key]
            );
        }
    } else {
        console.log('Difference at path:', path);
    }
};

// Customizer function to compare objects without considering property order
const customizer = (objValue, otherValue, key, object, source, stack) => {
    findDifferences(objValue, otherValue);
    return undefined; // Continue with the default comparison
};

const ProductQAPage = (props) => {
    const [productData, setProductData] = useState(null);
    const [productAssetData, setProductAssetData] = useState(null);
    const [cryptoMatteExists, setCryptoMatteExists] = useState(false);
    const [statusOf360Spin, setStatusOf360Spin] = useState("");
    const [dimensionCrypto, setDimensionCrypto] = useState(false);
    const [productQAImages, setProductQAImages] = useState([]);
    const [selectedProductImage, setSelectedProductImage] = useState(null);
    const [canvasEditor, setCanvasEditor] = useState(null);
    const [initialLoader, setInitialLoader] = useState(true);
    const [canvasJson, setCanvasJson] = useState([]);
    const [selectedAction, setSelectedAction] = useState("select");
    const [secondSelectedOption, setSecondSelectedOption] = useState("");
    const [objectColor, setObjectColor] = useState("#EE4B2B");
    const [objectThickness, setObjectThickness] = useState("thin");
    const [annotateLoader, setAnnotateLoader] = useState(false);
    const [saveLoader, setSaveLoader] = useState(false);
    const [collaborateMode, setCollaborateMode] = useState(false);
    const [tagUserList, setTagUserList] = useState([]);
    const [annotationDictionary, setAnnotationDictionary] = useState([]);
    const [taggedAnnotations, setTaggedAnnotations] = useState([]);
    const [allAnnotations, setAllAnnotations] = useState([]);
    const [userAccessLevel, setUserAccessLevel] = useState("view");
    const [selectedComment, setSelectedComment] = useState(null);
    const [initialJSON, setInitialJSON] = useState({});
    const [displayName, setDisplayName] = useState("");
    const [commentList, setCommentList] = useState([]);
    const [selectedCollaborationID, setSelectedCollaborationID] = useState(-1);
    const [modelWidth, setModelWidth] = useState("N/A");
    const [modelDepth, setModelDepth] = useState("N/A");
    const [modelHeight, setModelHeight] = useState("N/A");
    const [archiveLength, setArchiveLength] = useState(0);
    const [glbLength, setGlbLength] = useState(0);
    const [archiveSize, setArchiveSize] = useState(0);
    const [glbSize, setGlbSize] = useState(0);
    const [lowPolyVertexCount, setLowPolyVertexCount] = useState(0);
    const [highPolyVertexCount, setHighPolyVertexCount] = useState(0);
    const [totalFileSize, setTotalFileSize] = useState(0);
    const [highPolyModelSize, setHighPolyModelSize] = useState(0);
    const [lowPolyModelSize, setLowPolyModelSize] = useState(0);
    const [highPolyTextureSize, setHighPolyTextureSize] = useState(0);
    const [lowPolyTextureSize, setLowPolyTextureSize] = useState(0);
    const [selectedCommentOption, setSelectedCommentOption] = useState("all_comments");
    const [displayedCommentList, setDisplayedCommentList] = useState([]);
    const [showResolvedComments, setShowResolvedComments] = useState(false);
    const [selectedAsset, setSelectedAsset] = useState("images");
    const [sharingModal, setSharingModal] = useState(false);
    const [ifIframeLoadedOnce, setIfIframeLoadedOnce] = useState(false);
    const [referenceLinks, setReferenceLinks] = useState([]);
    const [productRefImages, setProductRefImages] = useState([]);
    const [libraryMaterial, setLibraryMaterial] = useState([]);
    const [baseVariantPlatform, setBaseVariantPlatform] = useState('aws')
    const [selectedComparisonImage, setSelectedComparisonImage] = useState('');
    const [selectComparisonImageModal, setSelectComparisonImageModal] = useState(false);
    const [uploadingImageLoader, setUploadingImageLoader] = useState(false);
    const [refImagePhotoList, setRefImagePhotoList] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [collaborateAccessModal, setCollaborateAccessModal] = useState(false);
    const [collaborators, setCollaborators] = useState([]);
    const [isCollaborationOwner, setIsCollaborationOwner] = useState(false)
    const [platform, setPlatform] = useState("aws");
    const [firstTimeLoad, setFirstTimeLoad] = useState(false);
    const [automatedProductVariant, setAutomatedProductVariant] = useState(false);
    const [activeCollaboratorList, setActiveCollaboratorList] = useState([]);
    const [lastModified, setLastModified] = useState(null);
    const [lastModifiedBy, setLastModifiedBy] = useState(null);
    const [refreshLoader, setRefreshLoader] = useState(false);
    const [dataAdded, setDataAdded] = useState(false);
    const [approveLoader, setApproveLoader] = useState(false);
    const [commentsForAllCollabs, setCommentsForAllCollabs] = useState([]);
    const [allCommentsLoaded, setAllCommentsLoaded] = useState(true);
    const [companyUsers, setCompanyUsers] = useState([]);
    const [collabSendEmailModal, setCollabSendEmailModal] = useState(false);
    const [collabEmailLoader, setCollabEmailLoader] = useState(false);
    const [userAnnotationIDs, setUserAnnotationIDs] = useState([]);
    const [annotationsData, setAnnotationsData] = useState([]);
    const [rejectionConfirmation, setRejectionConfirmation] = useState(false);
    const [rejectModal, setRejectModal] = useState(false);
    const [rejectFileList, setRejectFileList] = useState([]);
    const [rejectLoader, setRejectLoader] = useState(false);
    const [rejectionDetails, setRejectionDetails] = useState([])
    const [requestStatus, setRequestStatus] = useState(null);
    const [cardStatus, setCardStatus] = useState(null);
    const [rejectionPrice, setRejectionPrice] = useState(-1);
    const [paymentFailureMessage, setPaymentFailureMessage] = useState('');
    const [inAppLimitExceededModal, setInAppLimitExceededModal] = useState(false);
    const [cardSaveFlowTriggered, setCardSaveFlowTriggered] = useState(false);
    const [paymentFailureModal, setPaymentFailureModal] = useState(false);
    const [saveCommentLoader, setSaveCommentLoader] = useState(false);
    const [signedURL, setSignedURL] = useState("");
    const [imageUploadLoader, setImageUploadLoader] = useState(false);
    const [dataAutoSaved, setDataAutoSaved] = useState(false);
    const [validationState, setValidationState] = useState(-2);
    const [canvasLoader, setCanvasLoader] = useState(false);
    const [imageReloaded, setImageReloaded] = useState(false);
    const [annotationDisplayToggle, setAnnotationDisplayToggle] = useState(false);
    const [disablePDF, setDisablePDF] = useState(true);
    const [hideProductBanner, setHideProductBanner] = useState(false);
    const [repopulated, setRepopulated] = useState(false);
    const [autoSaveLoader, setAutoSaveLoader] = useState(false);
    const [displayLoader, setDisplayLoader] = useState(false);
    const [showDetailedRejection, setShowDetailedRejection] = useState(false);
    const [rejectionImageUrl, setRejectionImageUrl] = useState('');
    const [showRejectionImage, setShowRejectionImage] = useState(false);
    const [customerRejectionComments, setCustomerRejectionComments] = useState([]);
    const [latestRejection, setLatestRejection] = useState(null);
    const [changesMadeInCollab, setChangesMadeInCollab] = useState(false);
    const [legacyViewLoader, setLegacyViewLoader] = useState(false);
    const [productQAImageryLoader, setProductQAImageryLoader] = useState(true);
    const [productAnnouncementModal, setProductAnnouncementModal] = useState(false);
    useEffect(() => {
        let changes_made = new URLSearchParams(window.location.search).get("changes_made");
        if (changes_made) {
            setChangesMadeInCollab(true);
        }
        
    }, [window.location.search]);

    const loadUserRequestStatus = () => {
        let payload = {
            "username": CUSTOMER_USERNAME,
            "action": "check",
            "request_type": "customer_model_fix",
        }

        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, payload)
            .then(res => {
                setRequestStatus(res.data['request_allowed']);
                setCardStatus(res.data['card_status']);
                setRejectionPrice(res.data['price']);
            });
    }

    const sendEmailNotification = (emailContent, taggedUsers) => {
        setCollabEmailLoader(true);
        let payload = {
            'product_id': props.params.id,
            'username': CUSTOMER_USERNAME,
            'tagged_users': taggedUsers,
            'email_content': emailContent
        }

        axios.post(ENVIRONMENT.SEND_COLLABORATION_NOTIFICATION, payload)
            .then(res => {
                setCollabEmailLoader(false);
                setCollabSendEmailModal(false);
            });
    }

    const getCompanyUsersEmails = () => {
        let emails = []
        if (MANAGED_CUSTOMER_USERNAME) {
            emails.push({
                'email': MANAGED_CUSTOMER_EMAIL,
                'username': MANAGED_CUSTOMER_USERNAME
            })
            setCompanyUsers(emails)
        }

        if (COMPANY_ID) {
            let payload = {
                "required_fields": ["email", "username"],
                "filter_string": `(company_id__exact=${COMPANY_ID})`,
                "order_by": "email asc"
            }
            axios.post(ENVIRONMENT.COMPANY_ENTITY_GET_BATCH, payload)
                .then(res => {
                    if (res && res.data) {
                        emails = res.data.filter(item => item.username != CUSTOMER_USERNAME)
                        setCompanyUsers(emails)
                    }
                })
        }
    }

    const filterUserAnnotations = (annotations) => {
        const userAnnotations = []
        annotations.map((annotation) => {
            if (annotation.created_by != CUSTOMER_USERNAME) {
                userAnnotations.push(annotation.annotation_id)
            }
        })
        setUserAnnotationIDs(userAnnotations)
    }

    useEffect(() => {
        if (canvasEditor != null) {
            if (userAnnotationIDs.length > 0) {
                canvasEditor.setUserAnnotations(userAnnotationIDs);
            }
        }
    }, [canvasEditor, userAnnotationIDs])


    useEffect(() => {
        if (userAccessLevel != '') {
            if (!["owner", "co-owner"].includes(userAccessLevel)) {
                filterUserAnnotations(annotationsData)
            }
        }
    }, [userAccessLevel, annotationsData])


    const getQAID = () => {
        const selectedImage = (productQAImages || []).find(item => item && item.url === selectedProductImage);
        return selectedImage ? selectedImage.id : -1;
    }

    useEffect(() => {
        if (productQAImages && productQAImages.length > 0) {
            setCommentsForAllCollabs([]);
            setAllCommentsLoaded(true);
            let nonempty_jsons = productQAImages.filter(img => img.state && Object.entries(img.state).length != 0);
            let non_empty_state_count = nonempty_jsons.length;
            if (non_empty_state_count == 0) {
                setDisablePDF(true); // check if json is not empty
                setAllCommentsLoaded(false); // check if all comments have loaded
            } else {
                setDisablePDF(false);
                let index = 1;
                productQAImages.map((product) => {
                    // only fetch those comments which have annotations on them
                    if (product.state && Object.entries(product.state).length != 0) {
                        getAllCommentsForCollabs(product.id, index, non_empty_state_count);
                        index = index + 1;
                    }
                });
            }
        }
    }, [productQAImages]);


    const getAllCommentsForCollabs = (qa_id, index, non_empty_state_count) => {
        let payload = {
            "required_fields": ["comment_id", "annotation_id", "message", "sequence_no",
                "created_by", "created_on", "attachment", "tagged_users", "is_resolved", "product_qa_collab_id"],
            "filter_string": `(product_qa_collab_id__exact=${qa_id}&&is_hidden__exact=false)`,
            "order_by": "created_on desc"
        }

        axios.post(ENVIRONMENT.COMMENT_GET_BATCH, payload)
            .then(res => {
                if (res && res.data && res.data.length > 0) {
                    setCommentsForAllCollabs(prev_comments => [...prev_comments, ...res.data]);
                }
                if (index == non_empty_state_count) {
                    setAllCommentsLoaded(false);
                }
            });
    }

    useEffect(() => {
        if (allCommentsLoaded == false && canvasEditor) {
            canvasEditor.setAllComments(commentsForAllCollabs);
        }
    }, [commentsForAllCollabs, allCommentsLoaded, canvasEditor]);

    useEffect(() => {
        setActiveCollaboraters();
    }, [userAccessLevel]);

    const setActiveCollaboraters = () => {
        if (CollaborateConstants.edit_access_levels.includes(userAccessLevel)) {
            let payload = {
                'product_id': props.params.id,
                'customer_username': CUSTOMER_USERNAME,
                'action': 'set_active_users_product'
            }
            axios.post(ENVIRONMENT.ACTIVE_COLLABORATORS, payload)
                .then(res => {
                });
        }
    }

    useEffect(() => {
        // This effect will run when the component mounts
        // Fetch & set active collaborators after every 1 minute
        const INTERVAL_ID = setInterval(() => {
            // This function will be called repeatedly at the specified interval
            if (CollaborateConstants.edit_access_levels.includes(userAccessLevel)) {
                setActiveCollaboraters();
                fetchActiveCollaboraters();
            }
        }, 60000); // 60000 milliseconds (1 min)

        // cleanup function to clear the interval when the component unmounts
        return () => {
            clearInterval(INTERVAL_ID);
        };
    }, [userAccessLevel]);

    const fetchActiveCollaboraters = () => {
        let payload = {
            'product_id': props.params.id,
            'action': 'get_active_users_product'
        }
        axios.post(ENVIRONMENT.ACTIVE_COLLABORATORS, payload)
            .then(res => {
                if (res.data && res.data.length > 0) {
                    let updated_collaborators = res.data.filter(user => user.customer_username != CUSTOMER_USERNAME);
                    setActiveCollaboratorList(updated_collaborators);
                }
            });
    }

    useEffect(() => {
        // This effect will run when the component mounts
        // refresh everything after every 10 seconds
        const INTERVAL_ID = setInterval(() => {
            // This function will be called repeatedly at the specified interval
            if (CollaborateConstants.edit_access_levels.includes(userAccessLevel)) {
                refreshEverything();
            }
        }, 10000); // Interval is set to 10000 milliseconds (10 seconds)

        // cleanup function to clear the interval when the component unmounts
        return () => {
            clearInterval(INTERVAL_ID);
        };
    }, [userAccessLevel, lastModified, lastModifiedBy, initialLoader]);


    const refreshEverything = () => {
        const payload = {
            "required_fields": ['last_modified',
                'last_modified_by'],
            "filter_string": "(product_id__exact=" + props.params.id + ")",
            "order_by": "last_modified desc"
        }
        axios.post(ENVIRONMENT.COLLABORATE_QA_IMAGERY, payload)
            .then(res => {
                if (res.data && res.data.length > 0) {
                    let last_modified_new = res.data[0].last_modified;
                    let last_modified_by_new = res.data[0].last_modified_by;
                    console.log("Old last modified by", lastModified, lastModifiedBy);
                    console.log("Last Modified by current CUSTOMER USERNAME", CUSTOMER_USERNAME, initialLoader);
                    console.log("New last modified by", last_modified_new, last_modified_by_new);
                    if ((last_modified_by_new != lastModifiedBy || last_modified_new != lastModified) && !initialLoader && (CUSTOMER_USERNAME != last_modified_by_new)) {
                        resetStates(last_modified_new, last_modified_by_new);
                    }
                }
            });
    }

    useEffect(() => {
        // This effect will run when the component mounts
        // autosave everything after 1 minute
        const INTERVAL_ID = setInterval(() => {
            // This function will be called repeatedly at the specified interval
            if (autoSaveLoader || displayLoader || saveLoader || annotateLoader || refreshLoader || canvasLoader) {

            } else {
                if (CollaborateConstants.edit_access_levels.includes(userAccessLevel) && collaborateMode) {
                    autoSaveData();
                }
            }
        }, 60000); // 60000 milliseconds (1 min)

        // cleanup function to clear the interval when the component unmounts
        return () => {
            clearInterval(INTERVAL_ID);
        };
    }, [userAccessLevel, canvasEditor, selectedAction, initialJSON, collaborateMode]);


    const checkIfJSONIsEqual = () => {
        let old_json = {};
        let selected_id = getQAID();
        if (canvasJson && canvasJson[selected_id] &&
            Object.entries(canvasJson[selected_id]).length !== 0 && canvasEditor) {
            old_json = initialJSON;
        }
        let new_state_value = {};
        if (canvasEditor) {
            new_state_value = canvasEditor.convertToJSON();
        }

        return isEqualWith(old_json, new_state_value, customizer);
    }

    useEffect(() => {
        if (canvasEditor && initialLoader == false) {
            getProductQAImagery('reload');
        }
    }, [initialJSON]);



    const autoSaveData = () => {
        let json_equality = checkIfJSONIsEqual(); // check for json equality

        if (json_equality == false && selectedAction != "annotate" && getQAID() != -1) {
            let new_state_value = canvasEditor.convertToJSON();

            // json before color reset
            canvasEditor.changeHotspotColor(allAnnotations);
            let state_value = canvasEditor.convertToJSON();

            // json after color reset
            canvasEditor.changeHotspotColor(taggedAnnotations, "blue");
            let collab_payload = {
                'product_id': props.params.id,
                'state': state_value,
                'last_modified_by': CUSTOMER_USERNAME,
                'product_qa_collab_id': getQAID(),
                'action': 'update_product_qa_collab'
            }
            setAutoSaveLoader(true);
            axios.post(ENVIRONMENT.COLLABORATION_ENTITY_UPDATE, collab_payload)
                .then(res => {
                    const dict = { ...canvasJson };
                    dict[getQAID()] = new_state_value;
                    setCanvasJson(dict);
                    setInitialJSON(new_state_value);
                    getProductQAImagery('autosave');
                    setAutoSaveLoader(false);
                });
        }
    }

    const resetStates = (last_modified, last_modified_by) => {
        setRefreshLoader(true);
        setAnnotationDictionary([]);
        setDisplayName('');
        setCanvasJson([]);
        setSelectedCommentOption("all_comments");
        setSelectedAction("select");
        setTagUserList([]);
        setSecondSelectedOption("");
        setCollaborators([]);
        setCommentList([]);
        setDisplayedCommentList([]);
        setSelectedComment(null);
        setLastModified(last_modified);
        setLastModifiedBy(last_modified_by);
        if (collaborateMode) {
            let message_string = 'Please wait. Loading changes made by ' + last_modified_by;
            message.loading(message_string, 5);
        }

        getProductQAImagery('refresh');
        getSharedUsers();
        getCommentHistory();
    }


    const openShareModal = () => {
        setSharingModal(true);
    }

    const changeAssetTab = (key) => {
        setSelectedAsset(key);
    }

    const getSharedUsers = () => {
        let payload = {
            'action': 'get_product',
            'product_id': props.params.id
        };
        let company_id = COMPANY_ID;
        if (company_id) {
            payload['company_id'] = company_id;
        }
        axios.post(ENVIRONMENT.COLLABORATE_ACCESS_CONTROLLER, payload)
            .then(res => {
                if (res.data && res.data.length > 0) {
                    setCollaborators(res.data);
                    let shared_customers = res.data.filter(item => item.username != CUSTOMER_USERNAME).map(item => {
                        return {
                            id: item.username,
                            display: item.username
                        }
                    });
                    console.log('Shared Users', shared_customers)
                    setTagUserList(shared_customers);
                    let user_access = res.data.filter(item => item.username == CUSTOMER_USERNAME).map(item => item.access_level);
                    console.log('Access Level', user_access);
                    if (user_access && user_access.length > 0) {
                        setUserAccessLevel(user_access[0]);
                    }
                    else {
                        setUserAccessLevel('restricted');
                    }
                } else {
                    setUserAccessLevel('restricted');
                }

            });
    }


    // Functions for Annotation.jsx file
    const toggleAnnotateDisplay = (display_value) => {
        let annotate_modal = document.getElementById("annotate-modal");
        if (annotate_modal) {
            annotate_modal.style.display = display_value;
            setTimeout(() => {
                let comment_container = document.querySelector('.comment-container');
                if (comment_container) {
                    comment_container.scrollTop = comment_container.scrollHeight;
                }
            }, 1000);
        }
    }

    useEffect(() => {
        let collaborate_container = document.getElementById("collaborate-container");
        if (collaborateMode) {
            if (collaborate_container) {
                collaborate_container.style.display = "block";
            }
        } else {
            if (collaborate_container) {
                collaborate_container.style.display = "none";
            }
        }
    }, [collaborateMode]);

    useEffect(() => {
        if (selectedProductImage && productQAImages && productQAImages.length > 0) {
            getCommentHistory();
        }
    }, [selectedProductImage, productQAImages]);


    const repopulateSavedProgress = (image_id, url) => {
        if (canvasJson && canvasJson[image_id]
            && Object.entries(canvasJson[image_id]).length !== 0) {
            canvasEditor.changeImage(url, canvasJson[image_id]);

        } else {
            canvasEditor.clearCanvasData();
            canvasEditor.changeImage(url, null, setCanvasLoaderVal);
        }
    }

    useEffect(() => {
        //restore old data
        let selected_id = getQAID();
        if (canvasEditor && userAccessLevel != "") {
            canvasEditor.setAccessLevel(userAccessLevel);
        }

        if ((canvasJson && Object.entries(canvasJson).length != 0) && userAccessLevel == "edit") {
            setFirstTimeLoad(true);
        }
        if (dataAutoSaved == false && selected_id != -1
            && collaborateMode
            && selectedProductImage
            && canvasJson
            && canvasJson[selected_id]
            && Object.entries(canvasJson[selected_id]).length !== 0
            && canvasEditor && userAccessLevel != "") {
            setRepopulated(false);
            canvasEditor.reloadImageAndAspectRatio(canvasJson[selected_id], setRepopulatedLoaderVal, imageReloaded);
            setImageReloaded(true);  // set image reloaded as true so that aspect ratio is not resized again and again
            setDataAdded(true); // add check so that when it goes to tagged annotation check, it'll mark it as true
        }
    }, [canvasEditor, canvasJson, selectedProductImage, userAccessLevel, getQAID(), dataAutoSaved, collaborateMode]);//

    useEffect(() => {
        let selected_id = getQAID();
        if (dataAutoSaved == false && selected_id != -1 && dataAdded && repopulated
            && selectedProductImage
            && canvasJson
            && canvasJson[selected_id]
            && Object.entries(canvasJson[selected_id]).length !== 0
            && canvasEditor
            && taggedAnnotations.length > 0 && canvasLoader == false) {
            canvasEditor.changeHotspotColor(taggedAnnotations, "blue");
            setInitialJSON(canvasEditor.convertToJSON());
            setDataAdded(false);
            setRepopulated(false);
        }

    }, [taggedAnnotations, dataAdded, repopulated]);


    const setCanvasLoaderVal = () => {
        setCanvasLoader(false);
        setInitialJSON(canvasEditor.convertToJSON());
    }

    const setRepopulatedLoaderVal = () => {
        setCanvasLoader(false);
        setRepopulated(true);
        setInitialJSON(canvasEditor.convertToJSON());
    }

    useEffect(() => {
        if (firstTimeLoad) {
            setCollaborateMode(true);
        }
    }, [firstTimeLoad]);

    // Functions for CanvasToolbar.jsx file
    const selectAction = (main_action, secondary_action = "") => {
        setSecondSelectedOption(secondary_action);
        setSelectedAction(main_action);
        canvasEditor.enableAction(main_action, secondary_action, objectColor);
    }

    const extraOption = (main_action, secondary_action) => {
        canvasEditor.enableAction(selectedAction, main_action, objectColor, secondary_action);
        setObjectThickness(secondary_action)
    }

    const changeColor = (e) => {
        setObjectColor(e.target.value);
        setSecondSelectedOption("");
        setSelectedAction("color");
        canvasEditor.enableAction("color", "", e.target.value);
    }

    const onColorClick = () => {
        setSecondSelectedOption("");
        setSelectedAction("color");
        canvasEditor.enableAction("color", "", objectColor);
    }


    useEffect(() => {
        getLocalStorageValues();
        fetchProductInfo();
        getSharedUsers();
        fetchActiveCollaboraters();
        getCompanyUsersEmails();
        getRejectionDetails();
    }, []);

    const getLocalStorageValues = () => {
        CUSTOMER_USERNAME = localStorage.getItem('username');
        COMPANY_ID = localStorage.getItem("company_id");
        SHARED_ENTITIES = localStorage.getItem("shared_entities");
        MANAGED_CUSTOMER_USERNAME = localStorage.getItem('managed_customer_username');
        MANAGED_CUSTOMER_EMAIL = localStorage.getItem('managed_customer_email');
        IS_MSPROVIDER = localStorage.getItem("is_msprovider") === 'true' ? true : false;
    }

    const getRejectionDetails = () => {
        axios.post(ENVIRONMENT.GET_PRODUCT_REJECTIONS, { product_id: props.params.id })
            .then(res => {
                setRejectionDetails(res.data);
                let customer_rejection_comments = [];
                if (res.data && res.data.length > 0) {
                    customer_rejection_comments = res.data.filter(ele => !ele.customer_rejection && ele.qa_role !== "admin");
                    setCustomerRejectionComments(customer_rejection_comments);
                }
                if (customer_rejection_comments.length > 0) {
                    setLatestRejection(customer_rejection_comments[0]);
                }
                if (res.data && res.data.length > 0 && res.data[0] && res.data[0].reference_files) {
                    setRejectFileList(res.data[0].reference_files);
                }
            })
    }


    useEffect(() => {
        if (productAssetData != null) {
            getGlbSize();
            getArchiveSize();
            checkCryptomatte();

            getProductQAImagery('initial');
        }
    }, [productAssetData]);


    useEffect(() => {
        if (productAssetData != null) {
            getDimensionalData();
        }
    }, [productAssetData, glbLength, archiveLength]);


    useEffect(() => {
        if (productData != null) {
            getAllLinks();
            getAllReferenceImages();
            if (productData.uploaded_model && productData.assigned_artist == '' && !productData.variant_of) {
                loadUserRequestStatus('customer_model_fix')
            }
            setTheValidationState();
        }
    }, [productData]);


    const setTheValidationState = () => {
        if (productData.model_status == 4 && productData.validation_report && productData.uploaded_model && !productData.variant_of) {
            if (productData.validation_report && productData.validation_report.High && productData.validation_report.High.hidden_objects_count > 0) {
                setValidationState(0)
            }
            else if (productData.validation_report && productData.validation_report.High && productData.validation_report.High.hidden_objects_count == 0) {
                setValidationState(1)
            }
        }

        if (productData.uploaded_model
            && !productData.variant_of
            && productData.model_status == -6
            && productData.validation_report != undefined
            && productData.validation_report.High != undefined
            && productData.validation_report.High.product_valid == false) {
            setValidationState(2)
        }
    }


    const getAllLinks = () => {
        let links = [];
        if (productData.reference_urls && productData.reference_urls.length > 0) {
            productData.reference_urls.map((link) => {
                links.push(link);
            })
        }
        if (productData.product_manuals && productData.product_manuals.length > 0) {
            productData.product_manuals.map((link) => {
                let new_link = ENVIRONMENT.getBaseURL(productData.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent((link.originFileObj ? link.originFileObj.uid : link.uid) + '/' + link.name);
                links.push(new_link);
            })
        }
        if (productData.raw_files && productData.raw_files.length > 0) {
            productData.raw_files.map((link) => {
                let new_link = ENVIRONMENT.getBaseURL(productData.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent((link.originFileObj ? link.originFileObj.uid : link.uid) + '/' + link.name);
                links.push(new_link);
            })
        }
        if (productData.scans && productData.scans.length > 0) {
            productData.scans.map((link) => {
                let new_link = SCANS_BASE_URL + encodeURIComponent(link);
                links.push(new_link);
            })
        }
        if (productData.model_files && productData.model_files.length > 0) {
            productData.model_files.map((link) => {
                let new_link = ENVIRONMENT.getBaseURL(productData.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent((link.originFileObj ? link.originFileObj.uid : link.uid) + '/' + link.name);
                links.push(new_link);
            })
        }

        setReferenceLinks(links);
    }

    const downloadReferences = () => {
        let reference_files = [];
        if (productRefImages && productRefImages.length > 0) {
            productRefImages.map((img) => {
                reference_files.push(img.link);
            })
        }
        if (productData.model_files && productData.model_files.length > 0) {
            productData.model_files.map((link) => {
                let new_link = ENVIRONMENT.getBaseURL(productData.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent((link.originFileObj ? link.originFileObj.uid : link.uid) + '/' + link.name);
                reference_files.push(new_link);
            })
        }
        if (reference_files && reference_files.length > 0) {
            Utilities.saveToZip(props.params.id + '_References.zip', reference_files, true);
        } else {
            message.info('No references to download');
        }
    }

    const getAllReferenceImages = () => {
        let reference_images = [];
        let product_photos = []
        if (productData.product_photos != undefined) {
            Object.keys(productData.product_photos).forEach((key) => {
                let photos_array = productData.product_photos[key];
                if (!Array.isArray(photos_array)) {
                    if (key in photos_array) {
                        photos_array = productData.product_photos[key][key];
                    }
                    else {
                        photos_array = [];
                    }
                }
                let product_photos_subset = (photos_array || []).map((photo) => {
                    let file_url = ENVIRONMENT.getBaseURL(productData.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(photo.uid + '/' + photo.name);
                    reference_images.push({
                        'link': file_url,
                        'type': 'reference_image',
                        'format': Utilities.getImageFormat(photo.name)
                    });
                });

                if (key == 'product_photos_all') {
                    product_photos.unshift(product_photos_subset);
                }
                else {
                    product_photos.push(product_photos_subset);
                }

                if (productData.product_photos && Array.isArray(productData.product_photos) && product_photos && product_photos[0] && product_photos[0].length == 0) {
                    product_photos = []
                    productData.product_photos.map((photo) => {
                        let file_url = ENVIRONMENT.getBaseURL(productData.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(photo.uid + '/' + photo.name);
                        reference_images.push({
                            'link': file_url,
                            'type': 'reference_image',
                            'format': Utilities.getImageFormat(photo.name)
                        });
                    });
                }
            });
        }

        if (productData.model_files && productData.model_files.length > 0) {
            productData.model_files.map((link) => {
                let new_link = ENVIRONMENT.getBaseURL(productData.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent((link.originFileObj ? link.originFileObj.uid : link.uid) + '/' + link.name);
                reference_images.push({
                    'link': new_link,
                    'type': 'model_file',
                    'format': Utilities.getImageFormat(link.name)
                });
            })
        }
        setProductRefImages(reference_images);
        getCategoryAttachments(reference_images);
    }

    const getCategoryAttachments = (images) => {
        let category_attachment_links = images;
        if (productData.category_attachment && productData.category_attachment.length > 0) {
            productData.category_attachment.map((image) => {
                let file_url = ENVIRONMENT.getBaseURL(productData.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(image.uid + '/' + image.name);
                category_attachment_links.push({
                    'link': file_url,
                    'type': 'category_attachment',
                    'format': Utilities.getImageFormat(image.name)
                })
            })
        }
        setProductRefImages(category_attachment_links);
        getMaterialFiles(category_attachment_links);
    }

    const getMaterialFiles = (images) => {
        let material_files = images;
        if (productData.material_files && productData.material_files.length > 0) {
            productData.material_files.map((image) => {
                let file_url = ENVIRONMENT.getBaseURL(productData.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(image.uid + '/' + image.name)
                material_files.push({
                    'link': file_url,
                    'type': 'material_file',
                    'format': Utilities.getImageFormat(image.name)
                })
            })
        }
        setProductRefImages(material_files);
        getLibraryMaterial(material_files);
    }

    const getLibraryMaterial = (images) => {
        let library_materials = images;
        if (libraryMaterial && libraryMaterial.length > 0) {
            libraryMaterial.map(image => {
                if (image.renders.data && image.renders.data.thumbnail) {
                    let file_url = MATERIAL_FILE_URL + image.id + '/' + encodeURIComponent(image.renders.data.thumbnail);
                    library_materials.push({
                        'link': file_url,
                        'type': 'material_file',
                        'format': Utilities.getImageFormat(image.name)
                    })
                }
            });
        }
        setProductRefImages(library_materials);
        getColorPhotos(library_materials);
    }


    const getColorPhotos = (images) => {
        let other_photos = images;
        if (productData.color_photos && productData.color_photos.length > 0) {
            productData.color_photos.map(image => {
                let file_url = ENVIRONMENT.getBaseURL(productData.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(image.uid + '/' + image.name);
                other_photos.push({
                    'link': file_url,
                    'type': 'color_photos',
                    'format': Utilities.getImageFormat(image.name)
                })

            });
        }
        setProductRefImages(other_photos);
    }




    const updateCommentList = () => {
        let comment_list = [];
        if (selectedCommentOption == "all_comments" && showResolvedComments) {
            comment_list = commentList.filter(comment => comment.is_resolved == true);
        } else if (selectedCommentOption == "my_comments" && showResolvedComments) {
            comment_list = commentList.filter(comment => comment.created_by == CUSTOMER_USERNAME && comment.is_resolved == true);
        } else if (selectedCommentOption == "mentions" && showResolvedComments) {
            comment_list = commentList.filter(item => item.tagged_users.includes(`${CUSTOMER_USERNAME}`) && item.is_resolved == true);
        } else if (selectedCommentOption == "all_comments" && !showResolvedComments) {
            comment_list = commentList.filter(comment => comment.is_resolved == false);
        } else if (selectedCommentOption == "my_comments" && !showResolvedComments) {
            comment_list = commentList.filter(comment => comment.created_by == CUSTOMER_USERNAME && comment.is_resolved == false);
        } else if (selectedCommentOption == "mentions" && !showResolvedComments) {
            comment_list = commentList.filter(item => item.tagged_users.includes(`${CUSTOMER_USERNAME}`) && item.is_resolved == false);
        }
        setDisplayedCommentList(comment_list);
    }

    useEffect(() => {
        updateCommentList();
    }, [selectedCommentOption, showResolvedComments, commentList]);


    const getDownloadUrl = (type) => {
        let link = (productAssetData.download_links || []).find((link) => link.display_name == type);
        if (link) {
            return link.model_url.replace(ENVIRONMENT.DEFAULT_BASE_URL, getBaseURL(productAssetData['platform']));
        } else {
            return '';
        }
    }

    const getDimensionalData = () => {
        let high_poly_model_size = 0;
        let high_poly_texture_size = 0;
        let low_poly_model_size = 0;
        let low_poly_texture_size = 0;
        let total_file_size = 0;
        let glb_size = 0;
        if (productAssetData.model_info != undefined) {
            high_poly_model_size = productAssetData.model_info.size_high_poly_model || 0;
            high_poly_texture_size = productAssetData.model_info.size_high_poly_textures || 0;
            low_poly_model_size = productAssetData.model_info.size_low_poly_model || 0;
            low_poly_texture_size = productAssetData.model_info.size_low_poly_textures || 0;
            glb_size = productAssetData.model_info.glb_size || 0;
            if (glb_size == 0) {
                glb_size = glbLength / (1024 * 1024);
            }
            total_file_size = high_poly_model_size + high_poly_texture_size + low_poly_model_size + low_poly_texture_size + glb_size;
        }

        if (productAssetData.model_info == undefined || total_file_size == 0 || total_file_size == glb_size) {

        }

        let model_height = "N/A", model_depth = "N/A", model_width = "N/A";
        if (productAssetData.model_info) {
            // If model_info has low poly info
            if (productAssetData.model_info.low) {
                model_height = productAssetData.model_info.low.height || "N/A";
                model_depth = productAssetData.model_info.low.depth || "N/A";
                model_width = productAssetData.model_info.low.width || "N/A";
            }
            else if (productAssetData.model_info.high) {
                // if model_info has high poly info
                model_height = productAssetData.model_info.high.height || "N/A";
                model_depth = productAssetData.model_info.high.depth || "N/A";
                model_width = productAssetData.model_info.high.width || "N/A";
            }
            else {
                // for backward compatibility, where model_info only contains model_dimenions and vertex count
                model_height = productAssetData.model_info.height || "N/A";
                model_depth = productAssetData.model_info.depth || "N/A";
                model_width = productAssetData.model_info.width || "N/A";
            }
        } else if (model_depth == 'N/A' && model_height == 'N/A' && model_height == 'N/A' && productAssetData.width && productAssetData.height && productAssetData.length) {
            model_depth = parseInt(productAssetData.length);
            model_height = parseInt(productAssetData.height);
            model_width = parseInt(productAssetData.width);
        }

        setModelDepth(model_depth);
        setModelHeight(model_height);
        setModelWidth(model_width);

        let high_poly_vertex = null, low_poly_vertex = null;
        if (productAssetData.model_info) {
            if (productAssetData.model_info.low) {
                low_poly_vertex = productAssetData.model_info.low.vertex_count;
            }
            else if (productAssetData.need_to_model == 'ar') {
                // for backward compatibility
                low_poly_vertex = productAssetData.model_info.vertex_count;
            }

            if (productAssetData.model_info.high) {
                high_poly_vertex = productAssetData.model_info.high.vertex_count;
            }
            else if (productAssetData.need_to_model != 'ar') {
                // for backward compatibility
                high_poly_vertex = productAssetData.model_info.vertex_count;
            }
        }

        if (total_file_size > 0 && (total_file_size != glb_size)) {
            setTotalFileSize(convertByteSize(total_file_size));
            if (high_poly_model_size) {
                setHighPolyModelSize(convertByteSize(high_poly_model_size));
            }
            if (high_poly_texture_size) {
                setHighPolyTextureSize(convertByteSize(high_poly_texture_size));
            }
            if (low_poly_model_size) {
                setLowPolyModelSize(convertByteSize(low_poly_model_size));
            }
            if (low_poly_texture_size) {
                setLowPolyTextureSize(convertByteSize(low_poly_texture_size));
            }
            if (glb_size > 0) {
                setGlbSize(convertByteSize(glb_size));
            }
        } else if (archiveLength > 0 || glbLength > 0) {
            setTotalFileSize(convertByteSize((archiveLength + glbLength) / (1024 * 1024)));
            if (archiveLength > 0) {
                setArchiveSize(convertByteSize(archiveLength / (1024 * 1024)));
            }
            if (glbLength > 0) {
                setGlbSize(convertByteSize(glbLength / (1024 * 1024)));
            }
        }

        if (high_poly_vertex) {
            setHighPolyVertexCount(parseInt(high_poly_vertex).toLocaleString());
        }
        if (low_poly_vertex) {
            setLowPolyVertexCount(parseInt(low_poly_vertex).toLocaleString())
        }
    }

    const convertByteSize = (size_in_mbs) => {
        let result = size_in_mbs.toFixed(2) + " MBs";
        if (size_in_mbs / 1024 > 1024) // means more then 1 GB
        {
            size_in_mbs = (size_in_mbs / (1024)).toFixed(1); // size in GBs
            result = size_in_mbs + " GBs";
        }
        return result;
    }


    const getArchiveSize = () => {
        let link = getDownloadUrl('MAX');
        let size = 0;

        if (link) {
            axios.head(link).then(res => {
                if (res.headers['content-length']) {
                    size = parseInt(res.headers['content-length']);
                    setArchiveLength(size);
                }
            });
        }
    }


    const getGlbSize = () => {
        let link = getDownloadUrl('GLB');
        let size = 0;
        if (link) {
            axios.head(link).then(res => {
                if (res.headers['content-length']) {
                    size = parseInt(res.headers['content-length']);
                    setGlbLength(size);
                }
            });
        }
    }



    const downloadImagesArchive = () => {
        let downloadData = [];
        if (productAssetData) {
            if (productAssetData['2d'] && productAssetData['2d'].length > 0) {
                productAssetData['2d'].map((url) => {
                    let new_url = url.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(productAssetData['platform']))
                    downloadData.push(new_url);
                });
            }
            Utilities.saveToZip(props.params.id + '_Product_Images.zip', downloadData, true);
        }
    }


    const checkCryptomatte = () => {
        if (productAssetData != null) {
            if (productAssetData['2d'] != undefined && productAssetData['2d'].length > 0) {
                productAssetData['2d'].map((url) => {
                    let splitted_url = url.split('/')
                    let last_token = splitted_url[splitted_url.length - 1];
                    let image_name = last_token.split('.');
                    let image_name_w_format = image_name[0] + '_cMatte.jpg'
                    let new_url = ENVIRONMENT.getBaseURL(productAssetData.platform) + ENVIRONMENT.QA_IMG_URI + image_name_w_format;
                    if (image_name[0].includes('_Dimensions')) {
                        new_url = ENVIRONMENT.getBaseURL(productAssetData.platform) + ENVIRONMENT.IMAGE_DIMENSIONAL_URI + image_name_w_format;
                    }
                    if (cryptoMatteExists == false) {
                        axios.head(new_url, {})
                            .then(res => {
                                setCryptoMatteExists(true);

                                if (image_name[0].includes('_Dimensions')) {
                                    setDimensionCrypto(true);
                                }
                            })
                    }
                });
            }
        }
    }

    const getProductQAImagery = (action = null, new_image_id = -1, url = '') => {
        const payload = {
            "required_fields": ['id', 'combination_image', 'preview_image',
                'reference_image', 'state', 'annotation_count', 'last_modified', 'last_modified_by'],
            "filter_string": "(product_id__exact=" + props.params.id + ")",
            "order_by": "id desc"
        }
        if (action == 'refresh') {
            setRefreshLoader(true);
        }
        if (action == 'autosave') {
            setDataAutoSaved(true);
        } else {
            setDataAutoSaved(false);
        }
        axios.post(ENVIRONMENT.COLLABORATE_QA_IMAGERY, payload)
            .then(res => {
                
                console.log(res);
                if (res.data && res.data.length > 0) {
                    let image_list = [];
                    let state_dict = {};
                    let state_value = {};

                    res.data.map((img) => {
                        setLastModified(img.last_modified);
                        setLastModifiedBy(img.last_modified_by);
                        let low_url = ENVIRONMENT.getBaseURL(productAssetData.platform) + PRODUCT_QA_COMB_URI + props.params.id + '/' + img.combination_image;
                        let url = ENVIRONMENT.getBaseURL(productAssetData.platform) + PRODUCT_QA_COMB_URI +  props.params.id + '/' + img.combination_image;
                        
                        let type = "comb_image";
                        if (img.preview_image && !img.combination_image && !img.reference_image) {
                            type = "low_res_preview";
                            url = ENVIRONMENT.getBaseURL(productAssetData.platform) + QA_IMG_URI + img.preview_image;
                            low_url = url.replace('/perspective_renders/', '/low_res_perspective_renders/').replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(productAssetData.platform));
                            if (img.preview_image.includes("_Dimensions")) {
                                url = ENVIRONMENT.getBaseURL(productAssetData.platform) + IMAGE_DIMENSIONAL_URI + img.preview_image;
                                low_url = url.replace('/dimensional_renders/', '/low_res_dimensional_renders/').replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(productAssetData.platform));
                            }
                        } else if (img.reference_image && !img.preview_image && !img.combination_image) {
                            type = "reference_image";
                            url = ENVIRONMENT.getBaseURL(productAssetData.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(img.reference_image);
                            low_url = ENVIRONMENT.getBaseURL(productAssetData.platform) + ENVIRONMENT.BASE_URI + encodeURIComponent(img.reference_image);
                        }

                        state_value = {};
                        let group_objects = [];
                        if (img.state && Object.entries(img.state).length !== 0) {
                            state_value = img.state['data'];
                            let obj_dict = {};

                            state_value.objects.map((obj) => {
                                if (obj.type == "group") {
                                    obj_dict = {
                                        'group_objects': obj.objects,
                                        'annotation_id': obj.annotation_id
                                    }
                                    group_objects.push(obj_dict);
                                }
                            });
                        }
                        image_list.push({
                            "id": img.id,
                            "url": url,
                            "low_url": low_url,
                            "type": type,
                            "main_type": type,
                            "status": "rendered",
                            "state": img.state,
                            "group_objects": group_objects,
                            "annotation_count": img.annotation_count
                        });
                        state_dict[img.id] = state_value;
                        if (img.annotation_count) {
                            setChangesMadeInCollab(true);
                        }
                    });
                    if (action != 'reload') {
                        setCanvasJson(state_dict);
                    }

                    let first_url = image_list.filter(item => item.type == "low_res_preview");
                    let first_id = first_url[0].id;
                    if (action == 'initial') {
                        if (first_url[0].state && Object.entries(first_url[0].state).length !== 0) {
                            setCanvasLoader(true);
                        }
                        setSelectedCollaborationID(first_id);
                        setAnnotationDisplayToggle(false);
                        getAnnotations(first_id);
                        first_url = first_url[0].url;
                        setSelectedProductImage(first_url);
                        let canvas_editor = new CanvasEditor(first_url, setLoadingState, CANVAS_ID);
                        setCanvasEditor(canvas_editor);
                    }
                    else if (action == 'refresh') {
                        refreshCanvas(true);
                        let selected_state = image_list.filter(item => item.id == getQAID());
                        if (selected_state[0].state && Object.entries(selected_state[0].state).length !== 0) {
                            setCanvasLoader(true);
                        }
                        setSelectedCollaborationID(getQAID());
                        setAnnotationDisplayToggle(false);
                        getAnnotations(getQAID());

                        let canvas_editor = new CanvasEditor(selectedProductImage, setLoadingState, CANVAS_ID);
                        setCanvasEditor(canvas_editor);
                    }
                    else if (action == 'image_upload') {
                        scrollToItem(first_id);
                        message.info("Scroll down to view your image");
                    } else if (action == 'save_current_image') {
                        repopulateSavedProgress(new_image_id, url);
                    }
                    if (isEqualWith(productQAImages, image_list, customizer) == false) {
                        setProductQAImages(image_list);
                    }
                    setImageUploadLoader(false);
                    setProductQAImageryLoader(false);
                }
            });

    }

    const setLoadingState = () => {
        setInitialLoader(false);
        setRefreshLoader(false);
    }

    useEffect(() => {
        if (productData && canvasEditor) {
            canvasEditor.setPlatform(productData.platform);
        }
    }, [canvasEditor, productData])

    const fetchProductInfo = () => {
        const payload = {
            'product_id': props.params.id
        }
        if (!IS_MSPROVIDER) {
            payload['username'] = CUSTOMER_USERNAME;
            payload['customer_company_id'] = COMPANY_ID;
        }
        axios.post(ENVIRONMENT.FETCH_PRODUCT, payload)
            .then(res => {
                let company_id = null;

                if (res.data['company_id'] != undefined) {
                    if (COMPANY_ID === res.data['company_id'].toString()) {
                        company_id = res.data['company_id'].toString();
                    }
                    
                    let payload = {
                        product_id: props.params.id
                    };
                    axios.post(ENVIRONMENT.PRODUCT_MATERIAL_GET_BATCH, payload)
                        .then(res => {
                            let response = res.data;
                            if (response) {
                                response = response.body;
                                setLibraryMaterial(response);
                            }
                        });

                    if (res.data.variant_of != null) {
                        let variant_payload = {
                            product_id: props.params.id
                        };
                        axios.post(ENVIRONMENT.PRODUCT_VARIATION_REQUEST_GET, variant_payload)
                            .then(res => {
                                let response = res.data;
                                if (response) {
                                    if (response['status'] == FileConstants.PRODUCT_VARIANT_AUTOMATION_STATUS["COMPLETED"]) {
                                        setAutomatedProductVariant(true)
                                    }
                                }
                            });
                        let product_id = res.data.variant_of;
                        axios.post(ENVIRONMENT.FETCH_PRODUCT, { product_id })
                            .then(res => {
                                setBaseVariantPlatform(res.data.platform);
                            })
                    }

                }
                loadProductAssets(company_id, res.data);
                setPlatform(res.data.platform);
                setDisplayName(res.data.product_name);
                if (CUSTOMER_USERNAME == res.data.customer_username) {
                    setIsCollaborationOwner(true);
                }
            });
    }


    const loadProductAssets = (company_id, product_data) => {
        let payload = { product_id: props.params.id, username: CUSTOMER_USERNAME }
        if (company_id != null) {
            payload.company_id = company_id
        }
        axios.post(ENVIRONMENT.RENDER_PRODUCT, payload)
            .then(res => {
                setProductAssetData(res.data);
                setProductData(product_data);
            });
    }



    const downloadCryptoImagesArchive = () => {
        if (cryptoMatteExists) {
            let downloadData = [];
            if (productAssetData) {
                if (productAssetData['2d'] && productAssetData['2d'].length > 0) {
                    productAssetData['2d'].map((url) => {
                        let splitted_url = url.split('/')
                        let last_token = splitted_url[splitted_url.length - 1];
                        let image_name = last_token.split('.');
                        let image_name_w_format = image_name[0] + '_cMatte.jpg';
                        let new_url = getBaseURL(productAssetData['platform']) + QA_IMG_URI + image_name_w_format;

                        if (!image_name[0].includes('_Debug') && !image_name[0].includes('_Dimensions')) {
                            downloadData.push(new_url);
                        }
                        if (image_name[0].includes('_Dimensions') && dimensionCrypto) {
                            new_url = getBaseURL(productAssetData['platform']) + IMAGE_DIMENSIONAL_URI + image_name_w_format;
                            downloadData.push(new_url);
                        }

                    });

                }
                Utilities.saveToZip(props.params.product_id + '_Cryptomatte.zip', downloadData, true);
            }
        } else {
            message.error("Cryptomatte for this product aren't available.");
        }
    }


    const getCommentHistory = () => {
        let payload = {
            "required_fields": ["comment_id", "annotation_id", "message", "sequence_no",
                "created_by", "created_on", "attachment", "tagged_users", "is_resolved"],
            "filter_string": `(product_qa_collab_id__exact=${getQAID()}&&is_hidden__exact=false)`,
            "order_by": "created_on desc"
        }

        axios.post(ENVIRONMENT.COMMENT_GET_BATCH, payload)
            .then(res => {
                console.log('Comment Get batch', res);
                if (res && res.data && res.data.length > 0) {
                    setCommentList(res.data);
                    let unresolved_comments = res.data.filter(comment => comment.is_resolved == false);
                    if (unresolved_comments && unresolved_comments.length > 0) {
                        openCommentHistory("block");
                    }

                    let tagged_user_annotation = [...new Set(res.data.filter(item => item.tagged_users.includes(CUSTOMER_USERNAME))
                        .map(item => item.annotation_id))];
                    setTaggedAnnotations(tagged_user_annotation);
                    let all_annotations = [...new Set(res.data.map(item => item.annotation_id))];
                    setAllAnnotations(all_annotations);
                    if (collaborateMode == false) {
                        closeCommentHistoryDrawer();
                    }
                }
            });
    }

    const openCommentHistory = () => {
        toggleCommentDrawer("block");
    }

    const openComment = (comment) => {
        setSelectedComment(comment);
    }

    const closeCommentHistoryDrawer = () => {
        toggleCommentDrawer("none");
    }

    const handleChangeCommentOption = (value) => {
        setSelectedCommentOption(value);
    }

    const toggleResolveComments = (e) => {
        setShowResolvedComments(e.target.checked);
    }


    const toggleCommentDrawer = (display_value) => {
        let element = document.getElementById('comment_drawer');
        if (element) {
            element.style.display = display_value;
        }
    }

    const saveCollaboration = (go_back = false) => {
        if (canvasEditor && (selectedAction == "annotate")) {
            canvasEditor.removePrevHotspot();
        }
        setSaveLoader(true);
        let new_state_value = canvasEditor.convertToJSON();
        // json before color reset
        canvasEditor.changeHotspotColor(allAnnotations);
        let state_value = canvasEditor.convertToJSON();
        // json after color reset
        canvasEditor.changeHotspotColor(taggedAnnotations, "blue");
        let loading_message = message.loading('Saving your current progress here', 0);
        let collab_payload = {
            'product_qa_collab_id': getQAID(),
            'product_id': props.params.id,
            'state': state_value,
            'last_modified_by': CUSTOMER_USERNAME,
            'action': 'update_product_qa_collab'
        }
        axios.post(ENVIRONMENT.COLLABORATION_ENTITY_UPDATE, collab_payload)
            .then(res => {
                const dict = { ...canvasJson };
                dict[getQAID()] = new_state_value;
                setCanvasJson(dict);
                setInitialJSON(new_state_value);
                getProductQAImagery('reload');
                setSaveLoader(false);
                if (go_back) {
                    if (!['owner', 'co-owner'].includes(userAccessLevel)) {
                        window.location.href = '/home';
                    } else {
                        setCollaborateMode(false);

                    }
                }
                toggleAnnotateDisplay("none");
                closeCommentHistoryDrawer();
                setTimeout(loading_message);
            });
    }

    const getMetadata = () => {
        if (productData["metadata"] && !Array.isArray(productData["metadata"])) {
            return productData["metadata"];
        }
        return null;
    }

    useEffect(() => {
        open3DModelPreview(selectedAsset);
    }, [selectedAsset]);

    const open3DModelPreview = (key) => {
        if (key == '3d_preview') {
            let viewer = document.getElementById('model-viewer-3d');
            let message = {
                key: ProductPageConstants.MODEL_VIEWER_KEY,
                glbURL: getDownloadUrl("GLB"),
                metadata: getMetadata(),
                debugMode : true
            }

            if (viewer && viewer.contentWindow) {
                // display block once the viewer loads
                if (ifIframeLoadedOnce == false) {
                    viewer.onload = () => {
                        viewer.contentWindow.postMessage(message, "*");
                        if (document.getElementById('model-inspector-frame') != undefined) {
                            document.getElementById('model-inspector-frame').style.display = 'block';
                        }
                        setIfIframeLoadedOnce(true);
                    }
                } else {
                    if (document.getElementById('model-inspector-frame') != undefined) {
                        document.getElementById('model-inspector-frame').style.display = 'block';
                    }
                }
            }
        }
        else {
            if (document.getElementById('model-inspector-frame') != undefined) {
                document.getElementById('model-inspector-frame').style.display = 'none';
            }
        }
    }

    const uploadImage = () => {
        setUploadingImageLoader(true);
        if (Utilities.validateFileUploads(refImagePhotoList) == "done") {
            if (!selectedComparisonImage) {
                setErrorMessage("Please select an image");
                setUploadingImageLoader(false);
            } else {
                setErrorMessage('');
                let reference_img = {};
                reference_img['data'] = refImagePhotoList[0];
                let payload = {
                    product_id: props.params.id,
                    action: 'create_new_combination_image',
                    platform: productData.platform,
                    reference_image: JSON.stringify(reference_img),
                    preview_image: selectedComparisonImage,
                    index_number: productQAImages.length + 1,
                    username: CUSTOMER_USERNAME
                }
                axios.post(ENVIRONMENT.POST_PRODUCT_CREATION_HELPER, payload)
                    .then(res => {
                        setUploadingImageLoader(false);
                        message.success("Your image was successfully uploaded!");
                        setSelectComparisonImageModal(false);
                        setSelectedComparisonImage('');
                        setRefImagePhotoList([]);
                        setImageUploadLoader(true);

                        getProductQAImagery('image_upload');
                    })
            }

        } else {
            setUploadingImageLoader(false);
            setErrorMessage("Please please while upload completes");
        }
    }

    const scrollToItem = (id) => {
        let element = document.getElementById(`${id}`)
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const refreshCanvas = (refresh) => {
        if (refresh) {
            // clear and dispose old canvas
            // remove old canvas classes
            canvasEditor.clearFabricCanvas();
            setCanvasEditor(null);
            let canvas_id = '#' + CANVAS_ID;
            $(canvas_id).hide();
            $(canvas_id).siblings('.upper-canvas').remove();
            $(canvas_id).parent('.canvas-container').before($(canvas_id));
            $('.canvas-container').remove();
            $(canvas_id).show();
        }
    }

    const getUSDZUrl = () => {
        let link = getDownloadUrl('USDZ');
        if (link) {
            let shortened_link = '';
            if (productData['platform'] == 'aws') {
                shortened_link = window.location.origin + '/usdz/' + props.params.id;
            } else {
                shortened_link = window.location.origin + '/usdz/g/' + props.params.id;
            }
            return shortened_link;
        } else {
            return '';
        }
    }

    const getAndroidUrl = () => {
        let link = getDownloadUrl('GLB');

        if (link) {
            let shortened_link = window.location.origin + '/android/' + props.params.id;
            return shortened_link.replace(ENVIRONMENT.DEFAULT_BASE_URL, getBaseURL(productData['platform']))
        } else {
            return '';
        }
    }

    const getAnnotations = (product_qa_collab_id) => {
        let payload = {
            "required_fields": ["comment_id", "annotation_id", "message", "sequence_no", "is_hidden",
                "last_modified", "created_by", "created_on", "attachment", "tagged_users", "is_resolved"],
            "filter_string": `(product_qa_collab_id__exact=${product_qa_collab_id}&&is_hidden__exact=false)`,
            "order_by": "created_on desc"
        }

        axios.post(ENVIRONMENT.COMMENT_GET_BATCH, payload)
            .then(res => {
                if (res && res.data && res.data.length > 0) {
                    let comment_data = res.data;
                    comment_data = createAllAnnotationsWithComments(comment_data);
                    setAnnotationDictionary(comment_data);
                }
            });
    }


    const createAllAnnotationsWithComments = (input_array) => {
        const inputArray = input_array;
        const outputArray = [];

        inputArray.forEach((item) => {
            const {
                annotation_id, is_resolved, last_modified, sequence_no, attachment,
                comment_id, created_by, created_on, message, tagged_users
            } = item;

            let existingEntry = outputArray.find((entry) => entry.annotation_id === annotation_id);

            if (!existingEntry) {
                existingEntry = {
                    annotation_id, is_resolved, last_modified, sequence_no,
                    comments: [],
                };
                outputArray.push(existingEntry);
            }

            existingEntry.comments.push({
                attachment, comment_id, created_by, created_on, message,
                tagged_users,
            });
        });

        return outputArray;
    }


    const submitFeedback = () => {
        if (productData.uploaded_model == true) {
            setRejectionConfirmation(true);
        } else {
            setRejectModal(true);
        }

    }

    const switchToLegacy = () => {
        const hide = message.loading('Switching to legacy view..', 0, () => {
            message.success('Switched to legacy view');
        });
        let payload = {
            'product_legacy_view': true,
            'username': CUSTOMER_USERNAME,
            'action': 'set_product_legacy_view'
        }
        if (COMPANY_ID != undefined) {
            payload['company_id'] = COMPANY_ID;
        }
        setLegacyViewLoader(true);
        axios.post(ENVIRONMENT.USER_PREFERENCE_CONTROLLER, payload)
            .then(res => {
                setTimeout(hide, 0);
                setLegacyViewLoader(false);
                window.location.reload();
            });
       
    }

    const approveModel = () => {
        setApproveLoader(true);
        setRejectionConfirmation(false);
        let payload = {
            product_id: props.params.id,
            username: CUSTOMER_USERNAME,
            model_status: "5"
        }
        axios.post(ENVIRONMENT.UPDATE_PRODUCT, payload)
            .then(res => {
                if (IS_MSPROVIDER && productData.requested_for != undefined && productData.requested_for != '') {
                    const transfer_payload = {
                        entity_type: 'product',
                        product_id: props.params.id,
                        requested_for: productData.requested_for
                    }
                    axios.post(ENVIRONMENT.TRANSFER_OWNERSHIP, transfer_payload)
                        .then(res => {
                            if (automatedProductVariant == true) {
                                approveAutomatedVariantRequestStatus()
                            } else {
                                markCollaborationAsResolved();
                            }
                        })
                        .catch((error) => {
                            message.error('Error in transferring product.');
                        });
                } else {
                    if (automatedProductVariant == true) {
                        approveAutomatedVariantRequestStatus()
                    } else {
                        markCollaborationAsResolved();
                    }
                }
            })
            .catch((error) => {
                setApproveLoader(false);
                message.error('Error in approving product.');
            });
    }

    const markCollaborationAsResolved = () => {
        let collab_payload = {
            'product_id': props.params.id,
            'is_resolved': true,
            'is_hidden': true,
            'last_modified_by': CUSTOMER_USERNAME,
            'action': 'resolve_collaboration_product'
        }

        axios.post(ENVIRONMENT.COLLABORATION_ENTITY_UPDATE, collab_payload)
            .then(res => {
                successMessage();
            });
    }

    const openRejectModal = () => {
        setRejectionConfirmation(false);
        setRejectModal(true);
    }

    const closeRejectModal = () => {
        setRejectModal(false);
    }

    const approveAutomatedVariantRequestStatus = () => {
        let payload = {
            product_id: props.params.id,
            status: FileConstants.PRODUCT_VARIANT_AUTOMATION_STATUS["APPROVED"]
        }
        axios.post(ENVIRONMENT.PRODUCT_VARIATION_REQUEST_UPDATE, payload)
            .then(res => {

                markCollaborationAsResolved();

            })
    }

    const successMessage = () => {
        setApproveLoader(false);
        message.success("Product successfully approved and moved to your library!");
        window.location.href = '/products/' + props.params.id;
    }


    const performStoreAction = () => {

    }

    const convertProductFeedbackToPDF = () => {
        let loader = message.loading('Preparing to download..', 0)
        canvasEditor.convertProductFeedbackToPDF(productQAImages, props.params.id, true, loader);
    }


    const performRejection = async (form, reject) => {
        let values = form.getFieldsValue();
        var currentDateUTC = new Date(); // Create a new Date object for the current date and time in UTC
        var timestampUTC = currentDateUTC.getTime(); // Get the current timestamp in milliseconds for UTC
        let pdf_name = props.params.id + "_" + CUSTOMER_USERNAME + "_" + timestampUTC;
        values.reference_files = rejectFileList; // setting the file list manually for the saved comments
        if (values.reference_files != undefined) {
            let validationStatus = Utilities.validateFileUploads(values.reference_files);
            if (validationStatus == 'uploading') {
                setErrorMessage("Uploads are in progress, please wait for uploads to complete");
            }
            else if (validationStatus == 'error') {
                setErrorMessage("There was an error uploading you attachments, kindly try again.");
                setRejectFileList([]);
            }
            else {
                setErrorMessage("");
                if (reject) {
                    setRejectLoader(true);
                } else {
                    setSaveCommentLoader(true);
                }
                if (disablePDF && allCommentsLoaded == false) {
                    onReject(values, reject, '');
                } else {
                    try {
                        let blobi = await canvasEditor.convertProductFeedbackToPDF(productQAImages, props.params.id, false);
                        if (blobi != -1) {
                            // Extract the base64 data from the Data URL
                            uploadPDFFeedback(blobi, pdf_name, values, reject, onReject);
                        }
                       
                    } catch (error) {
                        console.error('Error converting to PDF:', error);
                        // Handle the error as needed
                    }
                }
            }
        }
        else {
            setErrorMessage("");
            if (reject) {
                setRejectLoader(true);

            } else {
                setSaveCommentLoader(true);
            }
            if (disablePDF && allCommentsLoaded == false) {
                onReject(values, reject, '');
            } else {
                try {
                    let blobi = await canvasEditor.convertProductFeedbackToPDF(productQAImages, props.params.id, false);
                    if (blobi != -1) {
                        // Extract the base64 data from the Data URL
                        uploadPDFFeedback(blobi, pdf_name, values, reject, onReject);
                    }
                } catch (error) {
                    console.error('Error converting to PDF:', error);
                    // Handle the error as needed
                }
            }
        }
    }

    const onReject = (values, reject = true, pdf_name) => {
        if (productData.uploaded_model == true && productData.assigned_artist == '' && reject == true && !productData.variant_of) {
            let model_status = "2";
            if (automatedProductVariant == true) {
                model_status = FileConstants.AUTOMATED_VARIANT_CUSTOMER_REJECT_STATUS[productData.variation_type]
            }
            let payload = {
                "product_id": props.params.id,
                "username": CUSTOMER_USERNAME,
                "rejection_details": values.msg,
                "reference_files": values.reference_files,
                "customer_rejection": false, //should only be true in save comment case
                "model_status": model_status,
                "feedback_link": pdf_name
            }

            let gateway_payload = {
                "username": CUSTOMER_USERNAME,
                "request_type": "customer_model_fix",
                "action": "perform",
                "request_payload": payload,
                "request_id": Date.now().toString()
            }
            if (requestStatus == 'allowed' || requestStatus == 'always_allowed' || requestStatus == 'not_allowed') {
                axios.post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload)
                    .then(res => {
                        if (res.data.status != undefined && res.data.status == 1) {
                            // success of rejection

                            setSaveCommentLoader(false);
                            setRejectModal(false);
                            message.success('Model rejected!');
                            window.location.href = "/home";
                            setRejectLoader(false);
                        }
                        else {
                            if (res.data.request_allowed == 'not_allowed') {
                                setInAppLimitExceededModal(true);
                                //in-app limit exceeded
                            } else {
                                message.error('Something went wrong');
                                //error in API Call
                            }
                        }
                    })
            }
            if (requestStatus == 'payment_required') {
                window.addEventListener('card_saved', (e) => {
                    let action = e.detail.action;
                    setCardSaveFlowTriggered(false);
                    if (action == 'customer_model_fix') {
                        onRejectRequestAPICall(gateway_payload);
                    }

                }, false);

                if (cardStatus == 1) {
                    onRejectRequestAPICall(gateway_payload);
                }
                if (cardStatus == 0) {
                    let child_window = window.open('/save-card?action=customer_model_fix', '', "width=600,height=600");
                    let refreshInterval = setInterval(() => {
                        if (child_window.closed) {
                            setCardSaveFlowTriggered(false);
                            clearInterval(refreshInterval);
                        }
                    }, 500);
                    setCardSaveFlowTriggered(true);
                }
            }
        }
        else {
            let payload = {
                "product_id": props.params.id,
                "rejection_details": values.msg,
                "rejection_against_artist": productData.assigned_artist,
                "reference_files": values.reference_files,
                "username": CUSTOMER_USERNAME,
                "feedback_link": pdf_name,
                "customer_rejection": !reject, //should only be true in save comment case
            }
            axios.post(ENVIRONMENT.ENTITY_MODEL_REJECT, payload)
                .then(res => {
                    if (reject === true) {
                        let model_status = "-4";
                        if (automatedProductVariant == true) {
                            model_status = FileConstants.AUTOMATED_VARIANT_CUSTOMER_REJECT_STATUS[productData.variation_type]
                        }
                        let payload = {
                            product_id: props.params.id,
                            model_status: model_status,
                            currently_assigned_artist: productData.assigned_artist,
                            username: CUSTOMER_USERNAME,
                            feedback_link: pdf_name
                        }
                        axios.post(ENVIRONMENT.UPDATE_PRODUCT, payload)
                            .then(res => {
                                message.success('Model rejected!');
                                window.location.href = "/home";
                            })
                            .catch((error) => {
                                message.error('Error in rejecting product.');
                            });
                    } else {
                        message.success('Comment saved!');
                        setRejectLoader(false);
                        setSaveCommentLoader(false);
                        setRejectModal(false);
                    }
                })
                .catch((error) => {
                    message.error('Error in rejecting product.');
                });

        }
    }

    const onRejectRequestAPICall = (gateway_payload) => {
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, gateway_payload)
            .then(res => {
                if (res.data.status != undefined && res.data.status == 1) {
                    // Success of rejection
                    setRejectLoader(false);
                    setSaveCommentLoader(false);
                    setRejectModal(false);
                    message.success('Model rejected!');
                    window.location.href = "/home";
                }
                else {
                    if (res.data.request_allowed == 'not_allowed') {
                        setInAppLimitExceededModal(true);
                    } else {
                        setPaymentFailureMessage(res.data.error_message);
                    }
                }
            })
    }

    const updatePaymentDetailsOnFailure = () => {
        window.addEventListener('card_saved', (e) => {
            let action = e.detail.action;
            setCardSaveFlowTriggered(false);
            if (action == 'save_card') {
                message.success('Payment Method Updated Successfully.');
                loadUserRequestStatus();
            }
        }, false);

        setPaymentFailureModal(false);
        let child_window = window.open('/save-card?action=save_card', '', "width=600,height=600");
        let refreshInterval = setInterval(() => {
            if (child_window.closed) {
                setCardSaveFlowTriggered(false);
                clearInterval(refreshInterval);
            }
        }, 500);
        setCardSaveFlowTriggered(true);
    }



    const uploadPDFFeedback = (blobData, name, values, reject, callback = undefined) => {
        if (productData.platform == 'google') {
            uploadPDFToGoogle(blobData, name, values, reject, callback)
        } else {
            uploadPDFToAWS(blobData, name, values, reject, callback)
        }
    }

    const convertToProperFormat = (blobData) => {
        // Ensure blobData is a base64-encoded string
        let base64Data = blobData.split(',')[1];

        // Use atob to decode base64
        let binaryData = atob(base64Data);

        // Create a Uint8Array from the binary data
        let array = new Uint8Array(binaryData.length);
        for (let i = 0; i < binaryData.length; i++) {
            array[i] = binaryData.charCodeAt(i);
        }

        return array;
    }

    const uploadPDFToAWS = (blobData, name, values, reject, callback = undefined) => {

        // Create a Buffer from the Uint8Array
        let buf = Buffer.from(convertToProperFormat(blobData));

        var jwt_token = localStorage.getItem('all3d_jwt_token');
        var cognito_credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: 'us-west-2:5f6370ec-44f1-4603-918e-afcdee671162',
            Logins: {
                'cognito-idp.us-west-2.amazonaws.com/us-west-2_dmOay1z0j': jwt_token,
            }
        });
        cognito_credentials.refresh(
            () => {
                AWS.config.update({
                    region: 'us-west-2',
                    credentials: cognito_credentials
                });
                const S3 = new AWS.S3();
                const objParams = {
                    Bucket: BUCKET_NAME,
                    Key: "product_assets/product_feedback/" + props.params.id + "/" + name + '.pdf',
                    ACL: 'public-read',
                    Body: buf,
                    ContentEncoding: 'base64',
                    ContentType: "application/pdf"
                };


                S3.upload(objParams, (err, data) => {
                    console.log(err, data, objParams)
                    if (callback != undefined) {
                        if (!err) {
                            callback(values, reject, name);
                            console.log("Callback")
                        }
                        else {
                            message.error("Unable to upload feedback");
                        }
                    }
                });
            });
    }

    const isSignedURLvalid = (url) => {
        if (url == '')
            return false;
        const urlSearchParams = new URLSearchParams(new URL(url).search);
        const expirationTimeString = urlSearchParams.get('X-Goog-Expires');
        if (!expirationTimeString) {
            return false;
        }
        const expirationTime = new Date(parseInt(expirationTimeString) * 1000);
        const currentTime = new Date();
        return currentTime > expirationTime;
    }

    const uploadPDFToGoogle = (blobData, name, values, reject, callback = undefined) => {
        // Create a Buffer from the Uint8Array
        let buf = Buffer.from(convertToProperFormat(blobData));

        let thumbnail_path = "product_assets/product_feedback/" + props.params.id + "/" + name + '.pdf';

        if (isSignedURLvalid(signedURL)) {
            let signedUrl = signedURL;
            const xhr = new XMLHttpRequest();
            xhr.open('PUT', signedUrl, true);

            xhr.onload = function () {
                if (xhr.status === 200) {
                    axios.post(ENVIRONMENT.GOOGLE_UPLOAD_CONTROLLER, {
                        'action': 'make_file_public',
                        'file_uri': thumbnail_path
                    })
                    if (callback != undefined) {
                        callback(values, reject, name);
                    }
                }
            };

            xhr.send(buf);
        }
        else {
            axios.post(ENVIRONMENT.GOOGLE_UPLOAD_CONTROLLER, {
                'action': 'get_asigned_urls',
                'file_uri': thumbnail_path
            })
                .then(res => {
                    let signedUrl = res.data;
                    setSignedURL(signedUrl);

                    const xhr = new XMLHttpRequest();
                    xhr.open('PUT', signedUrl, true);

                    xhr.onload = function () {
                        if (xhr.status === 200) {
                            axios.post(ENVIRONMENT.GOOGLE_UPLOAD_CONTROLLER, {
                                'action': 'make_file_public',
                                'file_uri': thumbnail_path
                            })
                            if (callback != undefined) {
                                callback(values, reject, name);
                            }
                        }
                    };

                    xhr.send(buf);
                });
        }
    }

    return (<>
        <CollaborateQAContext.Provider
            value={{
                legacyViewLoader: legacyViewLoader,
                switchToLegacy: switchToLegacy,
                submitFeedback: submitFeedback,
                getUSDZUrl: getUSDZUrl,
                getAndroidUrl: getAndroidUrl,
                product_id: props.params.id,
                productAssetData: productAssetData,
                productData: productData,
                downloadCryptoImagesArchive: downloadCryptoImagesArchive,
                statusOf360Spin: statusOf360Spin,
                setStatusOf360Spin: setStatusOf360Spin,
                productQAImages: productQAImages,
                selectedProductImage: selectedProductImage,
                setSelectedProductImage: setSelectedProductImage,
                canvas_editor: canvasEditor,
                selected_action: selectedAction,
                setSelectedAction: setSelectedAction,
                selectAction: selectAction,
                secondary_selected_action: secondSelectedOption,
                objectColor: objectColor,
                objectThickness: objectThickness,
                annotateLoader: annotateLoader,
                save_loader: saveLoader,
                extraOption: extraOption,
                changeColor: changeColor,
                onColorClick: onColorClick,
                setCanvasJson: setCanvasJson,
                canvasJson: canvasJson,
                collaborateMode: collaborateMode,
                setCollaborateMode: setCollaborateMode,
                collaboration_id: selectedCollaborationID,
                tag_user_list: tagUserList,
                annotateLoader: annotateLoader,
                setAnnotateLoader: setAnnotateLoader,
                setAnnotationDictionary: setAnnotationDictionary,
                annotation_dict: annotationDictionary,
                taggedAnnotations: taggedAnnotations,
                allAnnotations: allAnnotations,
                toggleAnnotateDisplay: toggleAnnotateDisplay,
                access_level: userAccessLevel,
                collaborators: collaborators,
                getCollaborators: getSharedUsers,
                getCommentHistory: getCommentHistory,
                scene_id: -1,
                platform: platform,
                display_name: displayName,
                setSelectedComment: setSelectedComment,
                setInitialJSON: setInitialJSON,
                product_id: props.params.id,
                modelWidth: modelWidth,
                modelHeight: modelHeight,
                modelDepth: modelDepth,
                totalFileSize: totalFileSize,
                lowPolyModelSize: lowPolyModelSize,
                lowPolyVertexCount: lowPolyVertexCount,
                lowPolyTextureSize: lowPolyTextureSize,
                highPolyVertexCount: highPolyVertexCount,
                highPolyModelSize: highPolyModelSize,
                highPolyTextureSize: highPolyTextureSize,
                archiveSize: archiveSize,
                glbSize: glbSize,
                initialLoader: initialLoader,
                closeCommentHistoryDrawer: closeCommentHistoryDrawer,
                openComment: openComment,
                displayedCommentList: displayedCommentList,
                handleChangeCommentOption: handleChangeCommentOption,
                toggleResolveComments: toggleResolveComments,
                selectedCommentOption: selectedCommentOption,
                openCommentHistory: openCommentHistory,
                sharingModal: sharingModal,
                setSharingModal: setSharingModal,
                openShareModal: openShareModal,
                repopulateSavedProgress: repopulateSavedProgress,
                setSelectedAsset: setSelectedAsset,
                selectedAsset: selectedAsset,
                referenceLinks: referenceLinks,
                productRefImages: productRefImages,
                baseVariantPlatform: baseVariantPlatform,
                setSelectComparisonImageModal: setSelectComparisonImageModal,
                selectComparisonImageModal: selectComparisonImageModal,
                selectedComparisonImage: selectedComparisonImage,
                setSelectedComparisonImage: setSelectedComparisonImage,
                uploadImage: uploadImage,
                uploadingImageLoader: uploadingImageLoader,
                refImagePhotoList: refImagePhotoList,
                setRefImagePhotoList: setRefImagePhotoList,
                setErrorMessage: setErrorMessage,
                errorMessage: errorMessage,
                collaborate_access_modal: collaborateAccessModal,
                setCollaborateAccessModal: setCollaborateAccessModal,
                collaborators: collaborators,
                setCollaborators: setCollaborators,
                downloadReferences: downloadReferences,
                isCollaborationOwner: isCollaborationOwner,
                getAnnotations: getAnnotations,
                changeAssetTab: changeAssetTab,
                selectedComment: selectedComment,
                isStorePage: false,
                performStoreAction: performStoreAction,
                activeCollaboratorList: activeCollaboratorList,
                setSelectedCollaborationID: setSelectedCollaborationID,
                initialJSON: initialJSON,
                approveModel: approveModel,
                approveLoader: approveLoader,
                convertProductFeedbackToPDF: convertProductFeedbackToPDF,
                allCommentsLoaded: allCommentsLoaded,
                companyUsers: companyUsers,
                sendEmailNotification: sendEmailNotification,
                collabEmailLoader: collabEmailLoader,
                collabSendEmailModal: collabSendEmailModal,
                rejectionConfirmation: rejectionConfirmation,
                setRejectionConfirmation: setRejectionConfirmation,
                openRejectModal: openRejectModal,
                rejectModal: rejectModal,
                closeRejectModal: closeRejectModal,
                rejectFileList: rejectFileList,
                setRejectFileList: setRejectFileList,
                rejectLoader: rejectLoader,
                performRejection: performRejection,
                rejectionDetails: rejectionDetails,
                rejectionPrice: rejectionPrice,
                cardStatus: cardStatus,
                requestStatus: requestStatus,
                updatePaymentDetailsOnFailure: updatePaymentDetailsOnFailure,
                inAppLimitExceededModal: inAppLimitExceededModal,
                paymentFailureMessage: paymentFailureMessage,
                cardSaveFlowTriggered: cardSaveFlowTriggered,
                paymentFailureModal: paymentFailureModal,
                setPaymentFailureModal: setPaymentFailureModal,
                setInAppLimitExceededModal: setInAppLimitExceededModal,
                saveCommentLoader: saveCommentLoader,
                imageUploadLoader: imageUploadLoader,
                dataAutoSaved: dataAutoSaved,
                setDataAutoSaved: setDataAutoSaved,
                validationState: validationState,
                canvasLoader: canvasLoader,
                setCanvasLoader: setCanvasLoader,
                saveCollaboration: saveCollaboration,
                refreshLoader: refreshLoader,
                setAnnotationDisplayToggle: setAnnotationDisplayToggle,
                annotationDisplayToggle: annotationDisplayToggle,
                downloadImagesArchive: downloadImagesArchive,
                disablePDF: disablePDF,
                setHideProductBanner: setHideProductBanner,
                hideProductBanner: hideProductBanner,
                autoSaveLoader: autoSaveLoader,
                autoSaveData: autoSaveData,
                displayLoader: displayLoader,
                setDisplayLoader: setDisplayLoader,
                repopulated: repopulated,
                showDetailedRejection: showDetailedRejection,
                setShowDetailedRejection: setShowDetailedRejection,
                setRejectionImageUrl: setRejectionImageUrl,
                rejectionImageUrl: rejectionImageUrl,
                setShowRejectionImage: setShowRejectionImage,
                showRejectionImage: showRejectionImage,
                customerRejectionComments: customerRejectionComments,
                latestRejection: latestRejection,
                setCollabSendEmailModal: setCollabSendEmailModal,
                changesMadeInCollab: changesMadeInCollab,
                setProductAnnouncementModal: setProductAnnouncementModal,
                productAnnouncementModal: productAnnouncementModal,
                setLatestRejection: setLatestRejection
            }}>
            <ProductQAPageView />
            <ShareProductModal context_for={"collaborate_qa"} />
            <CommentHistory context_for={"collaborate_qa"} />
            <ProductSelectionImageModal />
            <CollaborateAccess context_for={"collaborate_qa"} />
            <CollaborateEmailSender context_for={"collaborate_qa"} />
            {productAnnouncementModal ? <CustomerChangeLog active_key={"product_collab_qa_change"}/> : ""}
        </CollaborateQAContext.Provider>
    </>)

}

export default ProductQAPage;