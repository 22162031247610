import React from "react";
import AdminMainLayout from "../AdminMainLayout";
import {Row, Col, Card } from "antd";
import { DollarOutlined, HighlightOutlined, UserOutlined } from "@ant-design/icons";

const UserManagement = (props) => {
    const iconSize = 50;
    const iconColor = 'black';
    const iconStyle = {fontSize: iconSize, color: iconColor}
    const userMngOptions = [
        {
            "name" : "Customers",
            "icon" : <DollarOutlined style={iconStyle} />,
            "uri"  : "/admin_customers",
        },
        {
            "name" : "Managed Service Providers",
            "icon" : <HighlightOutlined style={iconStyle} />,
            "uri"  : "/msproviders",
        },
        {
            "name" : "Artists",
            "icon" : <UserOutlined style={iconStyle} />,
            "uri"  : "/admin_artist_list",
        },
    ]
    return (
        <>
        <AdminMainLayout selected={"20"} color={true}>
            <div>
                <div style={{marginTop: 30}}>
                    <Row gutter={[10,10]}>
                        {
                            userMngOptions.map ( option =>
                                <Col span={6}>
                                    <Card 
                                    style={{padding:50}} 
                                    bodyStyle={{textAlign:"center"}}
                                    hoverable={true}
                                    onClick={ () => {
                                        props.history.push(option.uri);
                                    }}>
                                        {option.icon}
                                        <span style={{marginTop: 20}}>
                                            <h2 style={{marginTop: 20}} className="manrope f-16 black-14 w-600 justify-in-center">{option.name}</h2>
                                        </span>
                                    </Card>
                                </Col>
                            )
                        }
                    </Row>
                </div>
            </div>
        </AdminMainLayout>
        </>
    );
};

export default UserManagement;