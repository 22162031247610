import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Card, Checkbox, Tooltip } from "antd";
import "@ant-design/compatible/assets/index.css";
import {
  ClockCircleOutlined,
  PlusOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import "../../../../styles/helper.scss";
import * as Utilities from "../../Utilities";
import _ from "lodash";
import ENVIRONMENT from "../../../../environments";
import EntityConstants from "./Constants";
import "./EntityCard.scss";

const EntityItem = (props) => {
  const {
    ele,
    selectable,
    selected,
    setSelected,
    singleSelect,
    entityType,
    store,
    setSelectedElementDetials,
    checked,
    requestStatus,
    isCheckboxStyle = false,
  } = props;
  let updateTime = Utilities.timestampToTimeSince(
    selectable ? ele.last_modified_stamp : ele.last_modified
  );
  const entity_type = entityType;
  if (updateTime.includes("h")) {
    updateTime = "Today";
  }

  const checkIfItemNotFreeOrAddedToLibrary = () => {
    return store && (ele?.price != 0 || ele?.price != "")
  };
  
  const isRedirectToStore = (!(requestStatus == 'always_allowed' || requestStatus == 'allowed') && checkIfItemNotFreeOrAddedToLibrary());

  const handleSelectClick = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((ele) => ele !== id));
    } else if (singleSelect) {
      setSelected([id]);
    } else {
      setSelected([...selected, id]);
    }
    setSelectedElementDetials(ele);
  };

  const entityData = EntityConstants.entity_data;

  const select_id = ele[entityData[entity_type].select_id];

  const getThumbnail = () => {
    if (entity_type === "room") {
      return (
        ENVIRONMENT.getBaseURL(ele.platform) +
        ENVIRONMENT.SPACE_THUMBNAIL_URI +
        (selectable ? ele.room_id : ele.id) +
        "/" +
        ele.thumbnail.split("/")[ele.thumbnail.split("/").length - 1]
      );
    } else if (entity_type === "product") {
      return (
        ENVIRONMENT.getBaseURL(ele.platform) +
        ENVIRONMENT.THUMBNAIL_URI +
        ele.thumbnail.split("/")[ele.thumbnail.split("/").length - 1]
      );
    } else if (entity_type === "scene") {
      return (
        ENVIRONMENT.getBaseURL(ele.platform) +
        ENVIRONMENT.SCENE_THUMBNAIL_URI +
        ele.id +
        "/" +
        ele.thumbnail
      );
    }
  };

  const redirectToEntity = () => {
    if (store) {
      window.location.href = `/store`;
    } else if (entity_type === "room") {
      window.location.href = `/rooms/${ele.id}?entity_id=${ele.id}`;
    } else if (entity_type === "product") {
      window.location.href = `/products/${ele.id}?entity_id=${ele.id}`;
    } else if (entity_type === "scene") {
      window.location.href = `/scene_renders/${ele.id}?entity_id=${ele.id}`;
    }
  };

  const handleCardClick = () => {
    if (selectable && !isRedirectToStore) {
      !checked && handleSelectClick(select_id);
    } else {
      redirectToEntity();
    }
  };

  const getEntityDimensions = () => {
    if (Array.isArray(ele.dimensions)) {
      return !!ele.dimensions.length ? ele.dimensions.join(" x ") : "N/A";
    } else if (ele.dimensions) {
      return `D${Math.trunc(ele.dimensions.depth)}  x  W${Math.trunc(
        ele.dimensions.width
      )}  x H${Math.trunc(ele.dimensions.height)}`;
    } else {
      return "N/A";
    }
  };

  const AddIconCheck = () => {
    return (
      <div>
            {checked ? (
              <CheckOutlined
                title="Added To Scene Already"
                className="group-settings plus-icon"
              />
            ) : selected?.includes(select_id) ? (
              <CheckOutlined
                title="Added To Scene"
                className="group-settings plus-icon card-button-checked"
                onClick={() => handleCardClick()}
              />
            ) : isRedirectToStore ? (
              <a href="/store" target="_blank">
                <Tooltip
                  title={
                    <span className="manrope f-12 white">
                      Please go to store to purchase this item
                    </span>
                  }
                >
                  <img
                    src="/img/redirect-icon.svg"
                    className="item-icons plus card-icon"
                  />
                </Tooltip>
              </a>
            ) : (
              <PlusOutlined
                title="Add To Scene"
                className="group-settings plus-icon"
                onClick={() => handleCardClick()}
              />
            )}
          </div>
    )
  }

  const CheckBox = () => {
    return (
      <Checkbox
        className="group-settings"
        onChange={() => handleCardClick()}
        checked={selected?.includes(select_id)}
      ></Checkbox>
    );
  }

  return (
    <Card
      bodyStyle={{ padding: "5px" }}
      className={`entity-card ${isRedirectToStore ? `product-hover` : ``}`}
      bordered={false}
      data-tut="reactour__approved_scene"
      style={{ width: "94%" }}
      onClick={() => handleCardClick()}
    >
      <div className="stacked-project-card-inner">
        {selectable && (
          isCheckboxStyle 
          ? <CheckBox/>
          : <AddIconCheck />
        )}
        <div
          className="suggested-product"
          style={{ zIndex: "owned", cursor: "pointer" }}
        >
          <div className="box">
            <img
              src={
                ele.thumbnail
                  ? getThumbnail()
                  : entityType == "product"
                    ? require("../../../../assets/images/chair.png")
                    : require("../../../../assets/images/scene_incomplete.svg")
              }
              style={{ objectFit: "contain", width: "100%", height: 260 }}
            />
          </div>
          <div style={{ margin: "2px 10px 0px 10px" }}>
            <div className="tag-section">
              <div className={`tag ${entityData[entity_type].className}`}>
                <span>{entityData[entity_type].title}</span>
              </div>
              <div className={`tag ${updateTime == "Today" && "today-tag"}`}>
                <ClockCircleOutlined />
                <span className="ml-4">{updateTime}</span>
              </div>
            </div>
            <div className="entity-name">
              <Tooltip title={ele.name}>
                <span className="manrope f-14 w-500 card-name">
                  {selectable
                    ? entity_type == "room"
                      ? ele.room_name
                      : ele.name
                    : ele.name}
                </span>
              </Tooltip>
            </div>
            <div className="ml-8 mb-4">
              <div>
                <span className="manrope f-14 w-500 lh-28 black-73 white-space-text">
                  Category
                </span>
                <span className="manrope f-14 w-500 black-d9 ml-8">
                  {ele.category || "N/A"}
                </span>
              </div>
              <div>
                <span className="manrope f-14 w-500 lh-28 black-73 white-space-text">
                  Item ID
                </span>
                <span className="manrope f-14 w-500 black-d9 ml-8">
                  {ele.brand_id || "N/A"}
                </span>
              </div>
              <div>
                <span className="manrope f-14 w-500 lh-28 black-73 white-space-text">
                  Dims
                </span>
                <span className="manrope f-14 w-500 black-d9 ml-8">
                  {getEntityDimensions()}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default EntityItem;
