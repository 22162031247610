import React, { useState, useRef, useEffect } from "react";
import "antd/dist/antd.css";
import axios from 'axios';
import ENVIRONMENT from '../../../../environments';
import { FilterOutlined, PictureOutlined, LoadingOutlined, SendOutlined } from "@ant-design/icons";
import { Col, Select, Image, Tooltip, Divider, Drawer, Avatar, Upload, Button, Checkbox } from "antd";
import "@ant-design/compatible/assets/index.css";
import "../../../../styles/helper.scss";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getBaseURL, BASE_URI } from '../../../../environments/env';
import * as Utilities from "../../Utilities";
import * as Styles from '../../../../Styles';
import { MentionsInput, Mention } from "react-mentions";
import mentionsInputStyle from "../CollaborateTool/mentionsInputStyle";
import * as Constants from '../Constants';

const { Option } = Select;
const progress_bar = Styles.progress_bar;
const platform = 'google';

const ProjectComments = (props) => {
    const {
        selectedModelType,
        selectedReviewStatus,
        setSelectedReviewStatus,
        setSelectedModelType,
        isReviewFilter,
        isCommentsDrawer,
        setIsCommentsDrawer,
        tagUserList,
        projectId,
        projectName,
        commentCount,
        setCommentCount,
        customGroups,
        usersAndCustomGroupsList
    } = props;

    const [nonParsedUserComment, setNonParsedUserComment] = useState("");
    const [attachments, setAttachments] = useState([]);
    const [commentLoader, setCommentLoader] = useState(false);
    const [emailCheck, setEmailCheck] = useState(false);
    const [commentList, setCommentList] = useState([]);
    const [userComment, setUserComment] = useState("");
    const [taggedUsers,setTaggedUsers] = useState([]);
    const [attachmentsInProgress, setAttachmentsInProgress] = useState(false)

    useEffect(() => {
        getCommentBatch();
    }, []);

    const getCommentBatch = () => {
        const payload = {
            "required_fields": ["comment_id","message","project_id", "created_by", "created_on", "attachment", "tagged_users"],
            "filter_string": `(project_id__exact=${projectId})`,
            "order_by": "created_on asc"
        }
        axios.post(ENVIRONMENT.COMMENT_GET_BATCH, payload)
            .then(res => {
                if (res && res.data && res.data.length > 0)
                {
                    setCommentList(res.data);
                }
            });
    }

    const getTaggedUserList = (sentence) => {
        let regex = /@\[([^[\]]+)\]/g;
        let matches = sentence.match(regex);
        let userList = [];
        if (matches && matches.length > 0) {
            userList = matches.map(match => match.slice(2, -1));
        }
        return userList;
    }

    const convertSentence = (input) => {
        const regex = /@\[([^[\]]+)\]\([^()]+\)|@\[([^[\]]+)\]/g;
        return input.replace(regex, (_, usernameWithText, usernameWithoutText) => "@" + (usernameWithText || usernameWithoutText));
    }

    const onCheckboxToggle = (e) => {
        setEmailCheck(e.target.checked);
    };

    // Utility function to filter out the current user and get email or username
    function filterUsers(users, key) {
        return users
            .filter(user => user.username !== localStorage.getItem('username'))
            .map(user => user[key]);
    }

    // Utility function to get emails or usernames from groups
    function getGroupData(groups, key, taggedUsers) {
        return groups
            .filter(group => taggedUsers.includes(group.group_name))
            .flatMap(group => filterUsers(group.users, key));
    }

    const handleCommentSubmit = () => {
        setCommentLoader(true);
        
        // Combine emails from tagged users and tagged groups
        let emails = tagUserList
        .filter(item => 
            item.username !== localStorage.getItem('username') && 
            taggedUsers.includes(item.display)
        )
        .map(item => item.email)
        .filter(Boolean); // Filter out undefined values

        emails = emails.concat(getGroupData(customGroups, 'email', taggedUsers));

        // Combine usernames from tagged users and tagged groups
        let tagged_users = tagUserList
        .filter(item => 
            item.username !== localStorage.getItem('username') && 
            taggedUsers.includes(item.display)
        )
        .map(item => item.id)
        .filter(Boolean);

        tagged_users = tagged_users.concat(getGroupData(customGroups, 'username', taggedUsers));

        if (taggedUsers.includes('all')) {
            let group_names = customGroups.map(group => group.group_name)
            // Add all users' emails/usernames
            emails = tagUserList
                .filter(item => 
                    item.access_level && 
                    item.access_level !== 'restricted' && 
                    item.username !== localStorage.getItem('username')
                )
                .map(item => item.email);

            emails = emails.concat(getGroupData(customGroups, 'email', group_names));

            tagged_users = tagUserList
                .filter(item => 
                    item.access_level && 
                    item.access_level !== 'restricted' && 
                    item.username !== localStorage.getItem('username')
                )
                .map(item => item.id);

            tagged_users = tagged_users.concat(getGroupData(customGroups, 'username', group_names));
        }

        // Remove duplicates
        emails = [...new Set(emails)];
        tagged_users = [...new Set(tagged_users)];

        const payload = {
            'message': userComment,
            'attachment': attachments,
            'created_by': localStorage.getItem('username'),
            'tagged_users': tagged_users,
            'tagged_user_emails': emails,
            'display_name': projectName,
            'project_id': projectId,
            'email_check': emailCheck
        }

        axios.post(ENVIRONMENT.COMMENT_CREATE, payload)
            .then(res => {
                console.log("RES",res);
                if (res && res.data && res.data.statusCode == 200) {
                    const comment = {'created_by' : localStorage.getItem('username'),
                        'message': userComment,
                        'attachment' : attachments,
                        'created_on': Date.now(),
                    }

                    const updatedCommentList = [...commentList, comment];
                    setCommentList(updatedCommentList);
                    setNonParsedUserComment("");
                    setAttachments([]);
                    setUserComment("")
                }
            }).finally(() => {
                setCommentLoader(false);
            });

            setCommentCount(commentCount + 1)
    };

    const handleAttachments = (info) => {
        let fileList = [...info.fileList];
        setAttachments(fileList);
        setUploadsInprogress(fileList)
    };

    const setUploadsInprogress = (attachmentList) => {
        let inProgress = false
        attachmentList.map((item) => {
            if(item.status !== 'done'){
                inProgress = true
            }
        })
        setAttachmentsInProgress(inProgress)
    }

    const handleMessageChange = (event) => {
        let sentence = convertSentence(event.target.value);
        setNonParsedUserComment(event.target.value);
        setUserComment(sentence);

        let tagged_users = getTaggedUserList(event.target.value);
        setTaggedUsers(tagged_users);
        console.log(event.target.value, "value");
    };

    const handleDrawerClose = () => {
        setIsCommentsDrawer(false);
    };

    return (
        <Drawer
            width={360}
            visible={isCommentsDrawer}
            onClose={handleDrawerClose}
            placement="right"
        >
            <div className="comments-drawer">
                <div className="comment-header">
                    <h2 className="manrope f-18 black-14 w-700">
                        Project Messages
                    </h2>
                </div>
                <Divider className="message-divider" />
                    <div className="comments-container">
                        {commentList.map((item, index) => (
                            <div key={index}>
                                <div className="message display-flex p-10">
                                    <div className="w-16 text-center display-flex justify-in-center align-start">
                                        <Avatar
                                            className={`cursor-pointer manrope f-14 white lh-32`}
                                        >
                                            {Utilities.getInitials(item.created_by)}
                                        </Avatar>
                                    </div>
                                    <div className="w-80">
                                        <div className="w-100 display-flex justify-space-between align-start">
                                            <span>{item.created_by}</span>
                                            <span className="manrope f-14 lh-28 black-73 white-space-text">
                                                {Utilities.convertToCustomFormat(item.created_on)}
                                            </span>
                                        </div>
                                        <div>
                                            <p dangerouslySetInnerHTML={{ __html: Utilities.makeMentionsBold(item.message, usersAndCustomGroupsList.flatMap(item => item.display)) }}/>
                                            <div />
                                        </div>
                                        <div className='display-flex a-c j-s wrap'>
                                        {item.attachment && item.attachment.length > 0 &&
                                            item.attachment.map((file) => (
                                                <div className="comment-img-container" style={{maxHeight:'47px', maxWidth:'47px'}}>
                                                    {Utilities.isImage(file.name) ?
                                                        <Image className="comment-img" src={getBaseURL(platform) + BASE_URI + encodeURIComponent(file.uid + '/' + file.name)} /> :
                                                        <a href={getBaseURL(platform) + BASE_URI + encodeURIComponent(file.uid + '/' + file.name)} target='_blank'>
                                                            <Tooltip title={<span className='manrope f-12 white'>Click to View/Download</span>}>
                                                                <img
                                                                    className="comment-img pd-10" src={'/img/file-icon.png'}
                                                                />
                                                            </Tooltip>
                                                        </a>}
                                                </div>
                                            ))
                                        }

                                        </div>
                                    </div>
                                </div>
                                <Divider className="message-divider" />
                            </div>
                        ))}
                    </div>
                <div className="add-comment-container">
                    <Col >
                        <MentionsInput
                            placeholder="Add a comment"
                            className="comment-input-box manrope f-14"
                            value={nonParsedUserComment}
                            onChange={handleMessageChange}
                            style={mentionsInputStyle}
                        >
                            <Mention
                                trigger="@"
                                displayTransform={(username) => `@${username}`}
                                data={usersAndCustomGroupsList.filter(item => item.access_level != null && item.access_level != 'restricted')}
                                appendSpaceOnAdd={true}
                            />
                        </MentionsInput>
                    </Col>
                    <Col
                        span={24}
                        className="comment-action-container display-flex a-s j-s-b"
                    >
                        <Upload
                            className="comment-upload-box"
                            {...Constants.getUploadProps('google')}
                            multiple={true}
                            fileList={attachments}
                            onChange={handleAttachments}
                            progress={progress_bar}
                        >
                            <Button
                                disabled={commentLoader}
                                className="action-icon-btn grey"
                                ghost
                                icon={<PictureOutlined style={{ fontSize: '23px' }}/>}
                            />
                        </Upload>
                        <Button
                            disabled={commentLoader || attachmentsInProgress || (userComment === "" && attachments.length === 0)}
                            className="action-icon-btn blue"
                            icon={commentLoader ? <LoadingOutlined style={{ fontSize: '23px' }}/> : <SendOutlined style={{ fontSize: '23px', color: commentLoader || attachmentsInProgress || (userComment === "" && attachments.length === 0) ? 'lightgray' : '' }}/>}
                            ghost
                            onClick={handleCommentSubmit}
                        />
                    </Col>
                    <Col span={24}>
                        <Checkbox
                            style={{
                                marginTop: 8,
                                marginBottom: 8,
                                marginLeft: -15,
                                transform: 'scale(0.8)'
                            }}
                            className="green-checkbox"
                            checked={emailCheck}
                            onChange={onCheckboxToggle}>
                            Notify Through Email
                        </Checkbox>
                    </Col>
                </div>
            </div>
        </Drawer>
    );
};

const mapStateToProps = (state) => state;

export default withRouter(connect(mapStateToProps)(ProjectComments));
