import React, {useState} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import AWS from 'aws-sdk';
import { Row, Button, message, Modal, Col, Input, Upload } from 'antd';
import * as Constants from "../Constants";
import { BUCKET_NAME } from '../../../../environments/env';
import { useEffect } from 'react';
import FileConstants from '../../../../FileConstants';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments';

const COMPANY_ID = FileConstants.COMPANY_ID;
const MANAGED_COMPANY_ID = FileConstants.MANAGED_COMPANY_ID;
const IS_MSPROVIDER = FileConstants.isMSProvider;

const { TextArea } = Input;

const ReviewProduct = (props) =>  {
    const url = new URL(window.location);
    const [rejectionLoader, setRejectionLoader] = useState(false);
    const [rejectionConfirmation, setRejectionConfirmation] = useState(false);
    // const [visible, props.setVisible] = useState(false);
    const [formInValidMessage, setFormInValidMessage] = useState("");
    const [fileList, setFileList] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [hasError, setHasError] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [isApproveAccess, setIsApproveAccess] = useState(false);
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched , getFieldValue , setFieldsValue, resetFields} = props.form;
    const projectId = new URLSearchParams(url.search).get("project_id");

    useEffect(() => {
        if (projectId) {
            getSharedUsers();
        } else {
            setIsApproveAccess(true);
        }
    }, []);

    useEffect(()=>{
        if(props.modelStatus == "-6"){
            props.setVisible(true)
        }
    })

    const onApprove = () => {
        props.onApprove();
        setRejectionConfirmation(false);
    }

    const handleCancel = () => {
        props.setVisible(false);
        if(props.modelStatus == "-6"){
            props.setShowArtistModal(false)
        }
    }

    const handleChange = info => {
        let fileList = [...info.fileList];

        let reference_files = props.form.getFieldValue('reference_files');
        if (reference_files != undefined)
        {
            let validationStatus = validateFileUploads(reference_files);
            if (validationStatus == 'done')
            {
                setFormInValidMessage("");
            }
        }

        setFileList(fileList);
        setUploading(true);
        console.log(info,info.file);
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);
            setHasError(false);
            setErrorMessage('');
            setUploading(false);
        } else if (info.file.status === "error") {
            console.log(info.file.error.message,info.file);
            setErrorMessage(info.file.error.code.concat('   ' + info.file.error.message));
            if (info.file.error.code.includes('Credentials')) {
                setErrorMessage(info.file.error.code.concat('. Your session has expired. Please reload the page.'));
            }  else if (info.file.error.code.includes('Network')) {
                setErrorMessage(info.file.error.code.concat('. We are unable to upload due to an issue with your internet connection.'));
            }
            message.error(`${info.file.name} file upload failed.`);
            setHasError(true);
            setUploading(false);
        }
    }

    const validateFileUploads = (files) => {
        let status_done = false;
        if (files != undefined) {
            for(var file of files) {
                if (file['status'] == 'uploading') {
                    return 'uploading';
                }
                else if (file['status'] == 'error') {
                    return 'error';
                }
                else if (file['status'] == 'done') {
                    status_done = true;
                }
            }
        }
        if (status_done) {
            return 'done'
        }
        return 'not_started';
    }

    const handleSubmit = (e, reject = true) => {
        console.log(reject);
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err && formInValidMessage == "") {
                values.reference_files = fileList; // setting the file list manually for the saved comments
                if (values.reference_files != undefined) 
                {
                    let validationStatus = validateFileUploads(values.reference_files);
                    if (validationStatus == 'uploading')
                    {
                        setFormInValidMessage("Uploads are in progress, please wait for uploads to complete");
                    }
                    else if (validationStatus == 'error')
                    {
                        setFormInValidMessage("There was an error uploading you attachments, kindly try again.");
                        setFileList([]);
                    }
                    else{
                        if(props.modelStatus != -6){
                            // props.setVisible(false)
                            // setRejectionLoader(true);
                        }
                        props.setRejectLoader(true)
                        props.onReject(values, reject);
                    }

                }
                else {
                    if(props.modelStatus != -6){
                        // props.setVisible(false)
                        // setRejectionLoader(true);
                    }
                    props.setRejectLoader(true)
                    props.onReject(values, reject);
                }
            }
        });
    }

    const openRejectionPopup = () => {
        if (props.uploaded_model && props.variantModel == false) {
            setRejectionConfirmation(true);
        } else {
            props.setVisible(true)
        }
    }

    const getSharedUsers = () => {
        let payload = {
          'action': 'get',
          'project_id': projectId
        };
        let company_id = COMPANY_ID;
        if (IS_MSPROVIDER && MANAGED_COMPANY_ID) {
          company_id = MANAGED_COMPANY_ID;
        }
        if (company_id) {
          payload['company_id'] = company_id;
        }
        axios.post(ENVIRONMENT.PROJECT_ACCESS_CONTROLLER, payload)
          .then(res => {
            if (res.data && res.data.length > 0) {
              let user_access = res.data.filter(item => item.username == localStorage.getItem('username')).map(item => item.access_level);
    
              if (user_access && user_access == 'quality_control' || user_access == 'owner' || user_access == 'co_owner') {
                setIsApproveAccess(true);
              } 
            }
          });
    };

    return <div className="product-review-btns" style={{background:"#FFFFFF",boxShadow:"0px -8px 25px rgba(0, 0, 0, 0.04)", zIndex: 8}}>
        {props.modelStatus == "-6" || !isApproveAccess ?
        ''
        :
        <Row type="flex">
            <Col span={24} className={props.modelStatus == 4 ? `justify-space-between` : `justify-in-end`}>
                <div className='justify-in-start'>
                    <img src='/img/collaborate_qa/wand.png' className='mr-8' />
                    <div>You are using the old version of Product QA. Try the new collaboration mode.                       
                    </div>
                    <Button className='basic-grey-btn f-14 ml-12'
                        onClick={props.switchToNewView}
                        disabled={props.legacyViewLoader}
                        loading={props.legacyViewLoader}>Switch to new version</Button>
                </div>
                <div className='justify-in-end'>
                    <Button className="outline-red-btn square font-14" disabled={false} type="default" onClick={openRejectionPopup} shape="circle" size="large">
                        {props.uploaded_model && props.variantModel == false ? "Fix" : "Reject"}
                    </Button>
                    <Button className="modal-okay-green square font-14" type="danger" onClick={()=> onApprove()} style={{marginLeft: "10px"}} shape="circle" size="large" disabled={props.approveLoader} loading={props.approveLoader}>
                        {props.uploaded_model && props.variantModel == false ? "Add to Product Library" : "Approve & Generate Images" }
                    </Button>
                </div>
            </Col>
        </Row>}

        <Modal
            className='approve-modal'
            closable={true}
            visible={rejectionConfirmation}
            onCancel={() => setRejectionConfirmation(false)}
            footer={[
                <div className='justify-in-end'>
                    <Button className='modal-okay square font-12' onClick={()=> onApprove()}>
                        Add to Library
                    </Button>
                    <Button className='modal-okay square font-12' onClick={()=> {props.setVisible(true); setRejectionConfirmation(false);}}>
                       Fix as a New Model
                    </Button>
                </div>
            ]}
            title={<span className='manrope f-14 black-33 w-600'>Reject and fix as a new model?</span>}>
            <>
                <p className='manrope f-14 black-55'>
                    You will be charged the same amount for the fix as you would be for creating a new model.
                </p>
            </>
        </Modal>
        <Modal
            className="model-time-modal"
            visible={props.visible}
            onOk={handleSubmit}
            maskClosable={false}
            onCancel={handleCancel}>
            <div style={{padding: "32px"}}>
                <div className="manrope f-16 black-00 w-600" style={{marginBottom: 20}}>{props.modelStatus == -6?  "Create a New Model" : "Confirm Rejection"}</div>
                    <Form className="input-form" onSubmit={handleSubmit}>
                        <Form.Item className="artist-upload-txt align-text" name="msg" label={<span className='manrope f-14 black-14'>{props.modelStatus == -6 ? "Message" : "Rejection Message"}</span>} colon={false}>
                            {getFieldDecorator('msg', {
                                rules: [{required: true, message: props.modelStatus == -6 ? "Message is required":"Rejection message is required!"}],
                            })(
                                <TextArea rows={5}
                                className="custom-input-contain manrope f-12"
                                style={{height: "auto !important"}}
                                placeholder = {props.modelStatus == -6 ? "Enter Message" :"Enter Rejection Message"}
                                />
                            )}
                        </Form.Item>
                        <Form.Item className="artist-upload-txt align-text confirm-height" name ={'reference_files'} label={<span  className='manrope f-14 black-14'>Reference Files</span>}  colon={false}>
                            {getFieldDecorator('reference_files', {
                                valuePropName: 'reference_files',
                                getValueFromEvent: e => e && e.fileList,
                            })(
                                <Upload {...Constants.getUploadProps(props.platform)} 
                                onChange={handleChange}
                                // accept=".zip,.png,.jpg,.jpeg,.pdf"
                                onRemove={file => {
                                    message.success(`${file.name} removed successfully!`)
                                    const index = fileList.indexOf(file);
                                    const newFileList = fileList.slice();
                                    newFileList.splice(index, 1);
                                    setFileList(newFileList);
                                    console.log(newFileList)
                                    newFileList.forEach((file) => {
                                        if (file.status !== "error"){
                                            setHasError(false)
                                        }
                                        else {
                                            setHasError(true)
                                        }
                                    })
                                    return true;
                                }} 
                                fileList={fileList}
                                multiple="true" 
                                openFileDialogOnClick={!(validateFileUploads(fileList) == 'error')}
                                listType="text"  
                                className="upload-lg-btn ant-upload-picture-card-wrapper upload-box-height-confirm">
                                    <div className="d-flex" style={{justifyContent:"center",position:"relative",left:"0",right:"0"}}>
                                        <div style={{textAlign:"center"}}>
                                            <img className="" src={require("../../../../assets/images/upload.png")} alt=""></img>
                                            <div className="manrope f-12 black-55" >
                                                <div className="up-info">Drop your reference files</div>
                                                {/* <div>SUPPORTED FORMATS: ZIP, PDF, PNG, PDF, DOCS</div> */}
                                            </div>
                                            {(validateFileUploads(fileList) == 'error') && (<Button
                                            onClick={() => Constants.triggerFailedFilesUpload('reference_files', fileList, setFileList)}
                                            disabled={fileList.length === 0}
                                            style={{position:'relative',top: 0}}
                                            loading={uploading}
                                            className="retry-btn manrope f-12" ghost
                                            >
                                            {uploading ? 'Retrying...'  : 'Upload Failed. Click to Retry.'}
                                        </Button>)}
                                        </div>
                                    </div>
                                </Upload>
                            )}
                        </Form.Item>
                        {(validateFileUploads(fileList) == 'error') ? 
                            <div className='retry-error-message left'>{errorMessage}</div>
                        : ''}
                        <Form.Item >
                            <div className="justify-in-end">    
                            {
                                props.assignedArtist == '' && props.uploadedModel == true && props.variantModel == false && props.requestStatus == 'payment_required'?
                                <span className='manrope f-14 justify-in-start' style={{color:"#25D6A4", position:"absolute", left:"1%"}}>
                                    Price: ${props.rejectionPrice}
                                </span>
                                :
                                ''
                            }
                            <Button loading={props.rejectLoader} onClick={(e) => handleSubmit(e, true)} className="modal-okay square font-12" type="primary" htmlType="submit">
                                {
                                    props.assignedArtist == '' && props.uploadedModel == true && props.variantModel == false &&  props.requestStatus == 'payment_required' && props.cardStatus == 1?
                                    <span>
                                        Confirm & Process Payment
                                    </span>
                                    :
                                    props.assignedArtist == '' && props.uploadedModel == true && props.variantModel == false && props.requestStatus == 'payment_required' && props.cardStatus == 0?
                                    <span>
                                        Confirm & Enter Payment Details
                                    </span>
                                    :
                                    <span>
                                        Confirm
                                    </span>
                                }
                            </Button>
                            </div>
                        </Form.Item>
                    </Form>
                    {(formInValidMessage != '')?
                        <div className="manrope f-12 red" style={{color: "#ff4d4f", textAlign: "center"}}>{formInValidMessage}</div>
                    :""}
            </div>
        </Modal>
    </div>

}
export default Form.create({ name: 'review-form' })(ReviewProduct)