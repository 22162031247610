
import * as THREE from 'three'
import Constants from "../Constants.js"
import PillowPlacementManager from "./PillowPlacement/PillowPlacementManager.js"
import ObjectSnappingManager from "./ObjectSnapping/ObjectSnappingManager.js"
import {ConvexGeometry} from "../../ConvexGeometry"
import { PVB } from '../../PVB.js'
import {roundedEqual, getRootNodeFromObject, isAncestor, isConvexHull, getNormalForIntersect, setHighlightedState, isSnapped, rounded, multiplyVectorsElementWise, getObjectFromRootByName, disposeScene} from "../HelperFunctions"
import SceneCreator from "../../SceneCreatorAgent.js"
import { speed } from 'jquery'

var TransformHelperPlane = function () {

    'use strict';

    THREE.Mesh.call( this,
        new THREE.PlaneBufferGeometry( 1000, 1000, 2, 2 ),
        new THREE.MeshBasicMaterial( { visible: false, wireframe: true, side: THREE.DoubleSide, transparent: true, opacity: 0.1 } )
    );

    this._item = null;

    var unitY = new THREE.Vector3( 0, 1, 0 );
    var unitZ = new THREE.Vector3( 0, 0, 1 );

    var tempVector = new THREE.Vector3();
    var dirVector = new THREE.Vector3();
    var alignVector = new THREE.Vector3();
    var tempMatrix = new THREE.Matrix4();
    var identityQuaternion = new THREE.Quaternion();

    this.position.set(0,0,0);
    unitY.set( 0, 1, 0 ).applyQuaternion( identityQuaternion );
    unitZ.set( 0, 0, 1 ).applyQuaternion( identityQuaternion );

    // Align the plane for current transform mode, axis and space.

    alignVector.copy( unitZ );
    dirVector.copy( unitY );

    tempMatrix.lookAt( tempVector.set( 0, 0, 0 ), dirVector, alignVector );
    this.quaternion.setFromRotationMatrix( tempMatrix );

    this.attachHelperPlane = function ( item ) {
        this._item = item;
    };

    this.detachHelperPlane = function () {
        this._item = null;
    };
};

TransformHelperPlane.prototype = Object.assign( Object.create( THREE.Mesh.prototype ), {
    constructor: TransformHelperPlane,
} );

export default class ObjectPlacementManager {

    
    scene = null;
    sceneContainer = null;
    sceneCreator = null;
    sceneAssets = null;

    mouse = null;

    rotationControls = null;
    transformControls = null;
    navControl = null;
    assetManager = null;
    helperPlane = null;
    spaceManager = null;
    raycastManager = null;
    pillowPlacementManager = null;
    objectSnappingManager = null;
    LFAAreaManager = null;
    debugEngine = null;
    setDisclaimer = null;
    autoPlacementManager = null;

    ignoreList = [ "door", "frame", "handle", "wall", "moulding", "cap", "ceiling", "rod"]
    ignoreStackingList = ["curtain", "drape"];

    focusedAsset = null;

    isPlacementCorrectionRequired = false;

    /**
     * Struct that contains all the required information for the selected object.
     */
    selection = {
        object : null,
        placementType : Constants.PlacementType.FLOOR,
        state : Constants.AssetState.PLACED,
        worldPosition : new THREE.Vector3(),
        worldQuaternion: new THREE.Quaternion(),
        worldScale : new THREE.Vector3(),
        worldDirection : new THREE.Vector3(),
        parentPosition : new THREE.Vector3(), // in world space
        parentQuaternion : new THREE.Quaternion(), // in world space
        parentScale : new THREE.Vector3(), // in world space

        refreshSelectionTransform () {
            if ( this.object != null ) {
                this.object.updateMatrixWorld();
                this.object.matrixWorld.decompose ( this.worldPosition, this.worldQuaternion, this.worldScale );
                this.object.parent.matrixWorld.decompose ( this.parentPosition, this.parentQuaternion, this.parentScale );
                this.object.getWorldDirection( this.worldDirection );
            }
        }
    }

    /**
     * Struct that contains references to all the event callbacks for this module.
     */
    events = {
        scope : null,

        onMouseDown () {
            this.scope.#onMouseDown();
        },
        onMouseMove () {
            this.scope.#onMouseMove();
        },
        onMouseUp () {
            this.scope.#onMouseUp();
        }
    }

    // Variables for keeping track of movement origin & termination
    pointStart = new THREE.Vector3();
    pointEnd = new THREE.Vector3();
    pullOrigin = new THREE.Vector3();
    pullVector = new THREE.Vector3();

    // Array for tracking intersect targets for movement of scene assets
    placementInfoTargets = null;

    /**
     * Object Placement Manager allows you to translate and stack assets in scene
     * @param {SceneCreator} sceneCreator - The main scene creator instance.
     * @param {THREE.Scene} scene - The main Three JS scene that contains all of the objects.
     * @param {HTMLElement} sceneContainer - The HTML Div that contains the Three JS Scene.
     * @param {Array} sceneAssets - An array that contains references to all the assets currently in the scene.
     * @param {THREE.Vector2} mouse - Reference to vector2 containing mouse position in NDC (normalized device coordinates).
     * @param {Object} managersDict - A dictionary that contains all the available managers.
     */
    constructor(sceneCreator, scene, sceneContainer, sceneAssets, fixNormal, mouse, managersDict, setDisclaimer) {
        this.sceneCreator = sceneCreator;
        this.scene = scene;
        this.sceneContainer = sceneContainer;
        this.sceneAssets = sceneAssets;
        this.mouse = mouse;
        this.setDisclaimer = setDisclaimer;
        this.events.scope = this;
        this.fixNormal = fixNormal;
        this.clipping = false;
        this.enableTransformMenu = false;

        this.objectMovementSpeed = 0.01;
        this.wallNormal = undefined;
        this.helperPlane = this.buildHelperPlane();
        this.scene.add( this.helperPlane );
        this.currentWallIntersect = null;

        this.resetSelection();

        this.setupRequiredManagers(managersDict);
    }

    /**
     * Setup the managers required by this module to work.
     */
    setupRequiredManagers(managersDict) {
        this.rotationControls = managersDict[Constants.Manager.RotationControls];
        this.transformControls = managersDict[Constants.Manager.TransformControls];
        this.navControl = managersDict[Constants.Manager.NavigationControl];
        this.assetManager = managersDict[Constants.Manager.AssetManager];
        this.spaceManager = managersDict[Constants.Manager.SpaceManager];
        this.raycastManager = managersDict[Constants.Manager.RaycastManager];
        this.debugEngine = managersDict[Constants.Manager.DebugEngine];
        this.LFAAreaManager = managersDict[Constants.Manager.LostAndFoundAreaManager];
        this.autoPlacementManager = managersDict[Constants.Manager.AutoPlacementManager];

        this.pillowPlacementManager = this.buildPillowPlacementManager(managersDict);
        this.objectSnappingManager = this.buildObjectSnappingManager(managersDict);
    }

    /**
     * Build pillow placement manager used for auto orientation & position validation of pillows.
     * @param {Object} managersDict - A dictionary that contains all the available managers.
     */
    buildPillowPlacementManager(managersDict) {
        const manager = new PillowPlacementManager(this.sceneCreator, this.sceneAssets, managersDict);
        managersDict[Constants.Manager.PillowPlacementManager] = manager;
        return manager;
    }

    /**
     * Build object snapping manager used for auto snapping(translation and rotation) of objects to walls and identical objects.
     * @param {Object} managersDict - A dictionary that contains all the available managers.
     */
    buildObjectSnappingManager(managersDict) {
        const manager = new ObjectSnappingManager(this.scene, this.selection, managersDict);
        managersDict[Constants.Manager.ObjectSnappingManager] = manager;
        return manager;
    }

    /**
     * Build the helper plane used for moving the item on floor surface.
     */
    buildHelperPlane() {
        const plane = new TransformHelperPlane();
        plane.name = "helperPlane";
        return plane;
    }

    /**
     * Get selection
     */
    getSelection() {
        return this.selection;
    }

    attachFreeModeControls ( mode ) {
        this.sceneCreator.attachFreeModeControls( mode, this.selection.object );
    }

    detachFreeModeControls = () => {
        this.sceneCreator.detachFreeModeControls();
        if ( this.selection.object != null && this.selection.placementType != Constants.PlacementType.WALL ) {
            this.sceneCreator.attachRotationControls(this.selection.object);
        }
    }

    resetFreeModeTransform = ( mode ) => {
        this.restoreAssetPreviousState();
        if (this.selection.placementType == Constants.PlacementType.FLOOR || this.selection.placementType == Constants.PlacementType.CEILING) {
            this.selection.object.quaternion.copy(new THREE.Quaternion());
        }
    }

    showTransformMenu (state) {
        this.enableTransformMenu = state;
    }

    /**
     * Preserve last state of selected asset;
     */
    preserveAssetPreviousState() {
        this.selection.refreshSelectionTransform();
        this.selection.object.userData.lastValidPosition.copy(this.selection.worldPosition);
        this.selection.object.userData.lastValidQuaternion.copy(this.selection.worldQuaternion.clone());
        this.selection.object.userData.lastValidParent = this.selection.object.parent;
    }

    /**
     * Restore last state of selected asset;
     */
    restoreAssetPreviousState() {
        if (this.selection.object.userData.lastValidPosition && this.selection.object.userData.lastValidQuaternion) {
            this.scene.attach(this.selection.object);
            this.selection.object.position.copy(this.selection.object.userData.lastValidPosition);
            this.selection.object.quaternion.copy(this.selection.object.userData.lastValidQuaternion)
            this.updateParent(this.selection.object, this.selection.object.userData.lastValidParent);
            if (this.selection.object.parent != this.scene) {
                this.selection.object.userData.isStacked = true;
            }
            else {
                this.selection.object.userData.isStacked = false;
            }
        }
    }

     /**
     * Check if item asset is completely on a rug within its bounds
     * @param {THREE.Scene} rugObj - the rug object 
     * @param {THREE.Scene} item - floor item to check if it is completely on the rug or not
     */
    isItemIntersectingRugBounding(rugObj, item) {
        // if object is bounded in the rug place it on rug else place on the floor;
        var bbox = new THREE.Box3().setFromObject( item);
        var corners = [
            new THREE.Vector3(bbox.max.x, bbox.max.y, bbox.max.z),
            new THREE.Vector3(bbox.max.x, bbox.max.y, bbox.min.z),
            new THREE.Vector3(bbox.min.x, bbox.max.y, bbox.max.z),
            new THREE.Vector3(bbox.min.x, bbox.max.y, bbox.min.z)
        ];
        let itemsArray = [rugObj];
        for (let i in corners) {
            let rugIntersect = this.raycastManager.setAndIntersectAll(corners[i], new THREE.Vector3(0,-1,0), itemsArray);
            if (rugIntersect == null || (rugIntersect != null && rugIntersect.length == 0)){
                // not on rug place on floor 
                return true;
            }
        }
        return false;
    }

    /**
     * Check if item is intersecting a rug object
     * @param {THREE.Scene} object - object to check if it is a rug or not
     * @param {THREE.Scene} item - the item to check if it is on a rug or floor
     */
    isItemIntersectingRug(object, item) {
        // return false on rug, true on floor
        if (item != null && (item.userData.isPillow || item.userData.isRug)) {
            return true;
        }
        let isRugVal = false;
        if (object.userData.isSceneAsset) {
            let objectNode = getRootNodeFromObject( this.scene, object );
            if (objectNode != false) {
                isRugVal = objectNode.userData.isRug ;
            }
            if (isRugVal == true) {
                isRugVal = this.isItemIntersectingRugBounding(object, item);
            }
        }
        return !isRugVal;
    }

    /**
     * to detect if the wall item is being attempted to be placed on a moulding on the wall
     * @param {THREE.Vector3} wallPosition - vector position of the object on the wall
     * @param {THREE.Vector3} directionVectorsWall - list of direction vectors
     * @param {THREE.Vector3} direction - world direction of wall item object
     * @param {THREE.Scene} wallMesh - wall object
     * @param {THREE.Scene} objectMesh - wall item object
     */
    detectWallMoulding(itemPositionOnWall, directionVectors, wallMesh, raycastLengths) {
        let mouldingIntersect = null;
        for ( let i = 0; i < directionVectors.length; i++ ) {
            // raycast to find intersection from the point on the wall to left right up and down directions to detect moulding on the wall
            this.raycastManager.updateRaycasterProperties(itemPositionOnWall, directionVectors[i], raycastLengths[i]);
            mouldingIntersect = this.raycastManager.setAndIntersect(itemPositionOnWall, directionVectors[i], wallMesh);
            this.raycastManager.resetFarValue();
            if (mouldingIntersect != false) {
                return true;
            }
        }
        return false;
    }

    /**
     * try to adjust wall item incase it is placed on the moulding on the wall
     */
    adjustWallItemIntersectingMoulding () {
        if (this.selection.placementType == Constants.PlacementType.WALL) {
            this.selection.refreshSelectionTransform();
            let pvb = this.selection.object.userData.pvb;
            let objBoundsData = pvb.getDataForSnapTest();
            // locate wall object
            this.raycastManager.updateRaycasterProperties(this.selection.worldPosition, objBoundsData.backDir, pvb.halfDepth);
            let wallIntersect = this.raycastManager.setAndIntersect(this.selection.worldPosition, objBoundsData.backDir, this.sceneCreator.space.walls);
            this.raycastManager.resetFarValue();
            if (wallIntersect != false) {
                var leftDir = objBoundsData.leftDir;
                var rightDir = objBoundsData.rightDir;
                var upDir = objBoundsData.topDir;
                var downDir = objBoundsData.bottomDir;
                var directionVectors = [leftDir, rightDir, upDir, downDir];
                var raycastLengths = [pvb.halfWidth, pvb.halfWidth, pvb.halfHeight, pvb.halfHeight ];
                let itemPositionOnWall = new THREE.Vector3();
                var wallMesh = [wallIntersect.object];
                let previousPosition = this.selection.worldPosition.clone();
                let offset = 0.03;
                itemPositionOnWall.copy(this.selection.object.position).add(objBoundsData.backDir.multiplyScalar(pvb.halfDepth));
                while (this.detectWallMoulding(itemPositionOnWall, directionVectors, wallMesh, raycastLengths) == true) {
                    this.selection.object.position.copy(previousPosition).add(this.selection.worldDirection.multiplyScalar(offset));
                    this.selection.object.updateMatrixWorld();
                    offset += 0.01;
                    // move in from center to the back of the item to detect moulding accurately
                    itemPositionOnWall.copy(this.selection.object.position).add(objBoundsData.backDir.multiplyScalar(pvb.halfDepth));
                }
                this.selection.refreshSelectionTransform();
            }
        }
    }

    /**
     * Adjust floor item accurately on the correct floor height 
     */
    adjustItemOnFloor() {
        if ( this.selection.object && !this.selection.object.userData.isStacked ) {
            this.selection.refreshSelectionTransform();
            let sourceVector = this.selection.worldPosition.clone();
            sourceVector.y += 0.5;
            let floorResult = this.raycastManager.setAndIntersect(sourceVector, new THREE.Vector3(0, -1, 0), this.spaceManager.floors);
            if(floorResult != false) {
                this.selection.object.position.y = floorResult.point.y;
                this.positionSelectionVertically();
            }
        }
    }

    /**
     * check if the selected item is intersecting with any wall. if yes then find intersection with the wall and then move it
     */
    validateFloorItemIntersectingWall() {
        if (this.selection.object!= null && this.selection.object!= undefined && (this.selection.placementType == Constants.PlacementType.FLOOR || this.selection.placementType == Constants.PlacementType.CEILING)) {
            // check if its bounding box intersects with any of the walls
            let intersectObjects = this.spaceManager.walls.concat(this.spaceManager.miscNodes);
            let pvb = this.selection.object.userData.pvb;
            let cornersList = pvb.getIntersectingPoints();
            let corners = [cornersList.c1, cornersList.c2, cornersList.c3, cornersList.c4, cornersList.c5, cornersList.c6, cornersList.c7, cornersList.c8];
            for ( let j = 0 ; j < corners.length; j++ ) {
                for ( let i = j + 1; i < corners.length; i++ ) {
                    let directionVector = new THREE.Vector3().subVectors(corners[j], corners[i]).normalize().negate();
                    let lineOrigin = corners[j].clone();
                    let raycastLength = corners[j].distanceTo(corners[i]);
                    this.raycastManager.updateRaycasterProperties(lineOrigin, directionVector, raycastLength);
                    let results = this.raycastManager.setAndIntersect( lineOrigin, directionVector, intersectObjects );
                    if (results != false) {
                        this.raycastManager.resetFarValue();
                        return false;
                    }
                    
                }
            }
        } 
        this.raycastManager.resetFarValue();
        return true;
    }
    /**
     * Get placement info for some Constants.PlacementType.
     * @param {PlacementType} placementType - The PlacementType to use.
     */
    getPlacementInfo( placementType ) {
        let results = null;
        let placementInfo = {
            helperPoint : null,
            intersectionPoint : null,
            intersectedObj: null,
            direction: null,
            isMiscAsset : false
        }

        // Sample over a small area around mouse position for finding intersects when item is stacked
        if(this.selection.object != null && this.selection.object.userData.isStacked) {
            let numOfRaycasts = 3;
            let offset = 0.01;
            let offsetForCurrentIteration = offset * Math.floor(numOfRaycasts / 2) * -1;

            let raycastPoint = new THREE.Vector2();
            let raycastResultsWithSmallestDistance;
            for (let index = 0; index < numOfRaycasts; index++) {
                raycastPoint.copy(this.mouse);
                raycastPoint.x += offsetForCurrentIteration;
                raycastPoint.y += offsetForCurrentIteration;

                results = this.raycastManager.setFromCameraAndIntersect(raycastPoint, this.sceneCreator.activeCamera, this.placementInfoTargets, true);
                
                if(raycastResultsWithSmallestDistance == null) {
                    raycastResultsWithSmallestDistance = results;
                }
                if(results != false && results.length > 0 && raycastResultsWithSmallestDistance != false && raycastResultsWithSmallestDistance.length > 0) {
                    if(results[0].distance < raycastResultsWithSmallestDistance[0].distance) {
                        raycastResultsWithSmallestDistance = results;
                    }
                }

                this.debugEngine.debugLog(results[0]);
                this.debugEngine.drawRayCastGizmo(this.raycastManager.raycaster);

                offsetForCurrentIteration += offset;
            }

            results = raycastResultsWithSmallestDistance;
        }
        else {
            results = this.raycastManager.setFromCameraAndIntersect(this.mouse, this.sceneCreator.activeCamera, this.placementInfoTargets, true);
        }

        if ( placementType === Constants.PlacementType.FLOOR ) {

            if ( results.length > 0 ) {
				for ( var result of results ) {
					if ( this.spaceManager.objectBelongsToFloor( result.object ) ) {
                        this.helperPlane.position.y = result.point.y;
						break;
					}
                }

                let intersectsHelper = results.find( ( item ) => { return ( item.object instanceof TransformHelperPlane ); } );
                if ( intersectsHelper ) {
                    placementInfo.helperPoint = new THREE.Vector3().copy( intersectsHelper.point );
                }

                if (this.selection.object.userData.isRug) {
                    results = results.filter( ( item ) => {
                        if (item.object.userData && item.object.userData.isSceneAsset) {
                            return getRootNodeFromObject(this.scene, item.object).userData.isRug;
                        }
                        else {
                            return ( (!this.spaceManager.ceilings.includes( item.object ) &&
                            !(  item.object instanceof TransformHelperPlane ) &&
                            !this.spaceManager.walls.includes( item.object ) &&
                            !this.spaceManager.doors.includes( item.object ) && 
                            !this.spaceManager.windows.includes( item.object ) &&
                            !(item.object.userData && item.object.userData.isGrid) &&
                            !isAncestor( this.selection.object, item.object, this.scene ) &&
                            ( !isConvexHull(item.object)))) ;
                        }
                    } );
                }
                else {
                    results = results.filter( ( item ) => {
                        return ( !this.spaceManager.ceilings.includes( item.object ) &&
                        !(  item.object instanceof TransformHelperPlane ) &&
                        !this.spaceManager.walls.includes( item.object ) &&
                        !this.spaceManager.doors.includes( item.object ) && 
                        !this.spaceManager.windows.includes( item.object ) &&
                        !(item.object.userData && item.object.userData.isGrid) &&
                        !isAncestor( this.selection.object, item.object, this.scene ) &&
                        ( !isConvexHull(item.object))) ;
                    } );
                }
                
                let firstContact = results[ 0 ];
                // if no valid intersection found, then return;
                if ( firstContact == undefined || firstContact == null ) {
                    results = this.raycastManager.setAndIntersectAll(this.selection.object.position.clone(), new THREE.Vector3(0, -1, 0), this.placementInfoTargets);
                    results = results.filter( ( item ) => {
                        return ( !this.spaceManager.ceilings.includes( item.object ) &&
                        !(  item.object instanceof TransformHelperPlane ) &&
                        !this.spaceManager.walls.includes( item.object ) &&
                        !this.spaceManager.doors.includes( item.object ) && 
                        !this.spaceManager.windows.includes( item.object ) &&
                        !(item.object.userData && item.object.userData.isGrid) &&
                        !isAncestor( this.selection.object, item.object, this.scene ) &&
                        ( !isConvexHull(item.object))) ;
                    } );
                    firstContact = results[ 0 ];
                    if (firstContact == undefined || firstContact == null) {
                        return placementInfo;
                    }
                }

                if ( !this.spaceManager.objectBelongsToFloor( firstContact.object ) ) {
                    this.debugEngine.debugLog( "stack on item")
                    if ( true || firstContact.face.normal.z >= 0.95 && firstContact.face.normal.z <= 1.05 ) {
                        if ( firstContact.object.userData.isSceneAsset ) {
                            let intersectedObjRoot = getRootNodeFromObject(this.scene, firstContact.object );
                            placementInfo.intersectedObj = intersectedObjRoot;
                            placementInfo.intersectionPoint = new THREE.Vector3().copy( firstContact.point );
                            if(this.selection.object.userData.isRug && intersectedObjRoot.userData.isRug && this.selection.object.userData.size.y < intersectedObjRoot.userData.size.y) {
                                placementInfo.intersectionPoint.y += 0.005;
                            }
                            placementInfo.direction = getNormalForIntersect(firstContact, this.LFAAreaManager);
                            return placementInfo;
                        }

                        else if ( firstContact.object.userData.isSceneMisc ) {

                            let firstContactName = firstContact.object.name.toLowerCase();

                            for ( let ignoreKeyword of this.ignoreList ) {
                                if ( firstContactName.includes( ignoreKeyword )) {
                                    return placementInfo;
                                }
                            }
                            for ( let ignoreKeyword of this.ignoreStackingList ) {
                                if ( firstContactName.includes( ignoreKeyword )) {
                                    this.selection.object.userData.isStacked = false;
                                    return placementInfo;
                                }
                            }
                            placementInfo.intersectedObj = getRootNodeFromObject(this.scene, firstContact.object);
                            placementInfo.intersectionPoint = new THREE.Vector3().copy( firstContact.point );
                            placementInfo.direction = getNormalForIntersect(firstContact, this.LFAAreaManager, this.fixNormal);
                            placementInfo.isMiscAsset = true;
                            return placementInfo;
                        }

                    }
                }
                else if ( this.spaceManager.objectBelongsToFloor( firstContact.object )) {
                    this.debugEngine.debugLog( "stack on floor")
                    this.adjustItemOnFloor();
                }
                return placementInfo;
            }

            return false;
        }

        else if ( placementType === Constants.PlacementType.WALL ) {

            if ( results.length > 0 ) {

                results = results.filter( ( item ) => {
                    return ( !this.spaceManager.ceilings.includes( item.object ) && !(item.object.userData && item.object.userData.isGrid)  && !(item.object.geometry instanceof ConvexGeometry)) ;
                } );

                let intersectsHelper = results.find( ( item ) => { return ( item.object instanceof TransformHelperPlane ); } );
                if ( intersectsHelper ) {
                    placementInfo.helperPoint = new THREE.Vector3().copy( intersectsHelper.point );
                }

                let firstContact = results[ 0 ];

                if( firstContact != null && this.spaceManager.walls.includes( firstContact.object ) ) {
                    placementInfo.direction = getNormalForIntersect(firstContact, this.LFAAreaManager, this.fixNormal);
                    if( Math.round(placementInfo.direction.y) == 0 ) {
                        placementInfo.intersectionPoint = new THREE.Vector3().copy( firstContact.point );
                    }
                }

                return placementInfo;
            }

            return false;
        }

        else if ( placementType === Constants.PlacementType.CEILING ) {

            if ( results.length > 0 ) {

                for ( var result of results ) {
                    if ( this.spaceManager.objectBelongsToCeiling( result.object ) ) {

                        if ( result.object.name == "LFAArea_Ceiling" && result.face.normal.round().y == 1 ) {
                            result.point.y -= 0.1;
                        }

                        else if ( result.face.normal.round().z == 1 ) {
                            result.point.y -= result.object.userData.height;    
                            
                            // raycast from result position to the ceiling up vector to find the ceiling's highest

                            let ceilingResult = this.raycastManager.setAndIntersectAll(result.point.clone(), new THREE.Vector3(0,1,0), this.sceneCreator.space.ceilings);
                            if (ceilingResult!= null && ceilingResult.length > 0) {
                                result.point.y += ceilingResult[0].distance
                            }
                               
                        }

                        this.helperPlane.position.y = result.point.y;
                        break;
                    }
                }

                let intersectsHelper = results.find( ( item ) => { return ( item.object instanceof TransformHelperPlane ); } );
                if ( intersectsHelper ) {
                    placementInfo.helperPoint = new THREE.Vector3().copy( intersectsHelper.point );
                }

                return placementInfo;
            }

            return false;
        }

        return false;
    }

    /**
     * Update movement origin variables according to placement info.
     */
    setMovementOrigin() {
        if(this.selection.object != null) {
            let placementInfo = this.getPlacementInfo( this.selection.placementType );

            if ( placementInfo != null ) {

                if ( placementInfo.intersectionPoint != null ) {
                    this.pointStart.copy(placementInfo.intersectionPoint);
                }
                else if ( placementInfo.helperPoint != null ) {
                    this.pointStart.copy(placementInfo.helperPoint);
                }
            }
            else {
                this.pointStart.set( NaN, NaN, NaN );
            }

            this.pullOrigin.copy( this.pointStart );
        }
    }

    /**
     * Set selection to currently focused asset (if any), only if edit mode is enabled.
     */
    selectFocusedAsset(){
        if( this.focusedAsset != null && this.sceneCreator.editModeOn ){

            this.setSelection ( this.focusedAsset );
            this.selection.state = Constants.AssetState.PLACING;

            this.placementInfoTargets = this.scene.children.filter( ( item ) => { return ( item != this.selection.object && item.type == "Scene" && !(item.userData && item.userData.isGrid) ) } );
            this.placementInfoTargets.push ( this.helperPlane );

            if( this.selection.placementType === Constants.PlacementType.WALL) {
                this.spaceManager.floors[0].getWorldPosition( this.helperPlane.position );
            }
            else {
                this.helperPlane.position.copy ( this.selection.worldPosition );    
            }

            this.helperPlane.updateMatrixWorld();

            this.setMovementOrigin();
        }
    }

    /**
     * Reset selection.
     */
    resetSelection () {
        if ( this.selection.object != null ) {
            let selectionObj = getObjectFromRootByName(this.selection.object, this.selection.object.name) || this.selection.object;
            setHighlightedState( selectionObj, false );
            this.detachFreeModeControls();
            this.sceneCreator.hideSelectedAssetUI();
            this.sceneCreator.showProductSizeControls(false);
            this.sceneCreator.detachRotationControls();
        }

        this.selection.object = null;
        this.selection.placementType = Constants.PlacementType.FLOOR;
        this.selection.state = Constants.AssetState.PLACED;
    }

    /**
     * Select an asset.
     * @param {THREE.Scene} asset - The asset to select.
     */
    setSelection ( asset ) {
        
        if ( this.selection.object == asset ) {
            this.objectSnappingManager.snapGuide.setTarget( asset );
            return;
        }
        else if ( this.selection.object != null) {
            let selectionObj = getObjectFromRootByName(this.selection.object, this.selection.object.name)  || this.selection.object;
            setHighlightedState( selectionObj, false );
            this.detachFreeModeControls();
            this.sceneCreator.detachRotationControls();
            this.sceneCreator.hideSelectedAssetUI();
        }

        this.selection.object = asset;
        this.selection.placementType = asset.userData.placementType;
        this.selection.state = Constants.AssetState.PLACED;

        this.selection.object.userData.snapped = false;
        this.selection.object.userData.snapType = "";

        asset.updateMatrixWorld();

        asset.matrixWorld.decompose ( this.selection.worldPosition, this.selection.worldQuaternion, this.selection.worldScale );
        asset.parent.matrixWorld.decompose ( this.selection.parentPosition, this.selection.parentQuaternion, this.selection.parentScale );
        asset.getWorldDirection( this.selection.worldDirection );

        let assetObj = getObjectFromRootByName(asset, asset.name) || asset;
        setHighlightedState(assetObj, true);
        this.sceneCreator.showSelectedAssetUI();
        if(this.selection.placementType != Constants.PlacementType.WALL) {
            this.sceneCreator.attachRotationControls( this.selection.object );
        }
        else {
            this.detachFreeModeControls();
            this.sceneCreator.detachRotationControls();
        }
        if ( this.selection.object != null && this.selection.placementType.toLowerCase() == Constants.PlacementType.WALL.toLowerCase()) { 
            const forwardDirection = this.selection.worldDirection.clone(); // Clone the forward direction
            const backwardDirection = forwardDirection.negate(); // Negate the vector to get the backward direction
            this.currentWallIntersect = this.raycastManager.setAndIntersect(this.selection.worldPosition, backwardDirection, this.sceneCreator.space.walls);
        }

        this.objectSnappingManager.snapGuide.setTarget( asset );
        if (this.selection.object && (!this.selection.object.userData.lastValidPosition || !this.selection.object.userData.lastValidQuaternion)) {
            this.selection.object.userData.lastValidPosition = new THREE.Vector3();
            this.selection.object.userData.lastValidQuaternion = new THREE.Quaternion();
            this.preserveAssetPreviousState();
        }

        if (this.enableTransformMenu) {
            this.sceneCreator.showProductSizeControls(true);
        }

        if (this.sceneCreator.activeCamera.name == "topDown" || this.sceneCreator.activeCamera.name == "topDownOrtho") {
            this.spaceManager.disableCeiling();
        }
        this.sceneCreator.setArrowKeysDisclaimer(true);
    }

    setProductSize(length, height, depth) {
        if (this.selection.object != null) {
            let assetSize = this.selection.object.userData.size;
            let lengthInScale = length / assetSize.x; 
            let heightInScale = height / assetSize.y; 
            let depthInScale = depth / assetSize.z; 
            const rotation = this.selection.object.rotation.x;
            this.rotateSelectionVertically(-rotation);
            this.setProductScale(this.selection.object,lengthInScale, heightInScale, depthInScale);
            this.rotateSelectionVertically(rotation);
            this.selection.object.updateMatrixWorld();
        }
    }

    getProductSize() {
        if (this.selection.object != null) {
            let assetSize = this.selection.object.userData.size;
            let scale = this.getProductScale(this.selection.object);
            let length =  scale.x * assetSize.x ;
            let height =  scale.y *  assetSize.y ;
            let depth = scale.z *  assetSize.z;
            
            let productSize = {
                'length': length,
                'height': height,
                'depth': depth
            }
            return productSize;
        }
    }

    resetProductSize() {
        if (this.selection.object != null) {
            const rotation = this.selection.object.rotation.x;
            this.rotateSelectionVertically(-rotation);
            this.setProductScale(this.selection.object,1,1,1);
            this.rotateSelectionVertically(rotation);
            this.selection.object.updateMatrixWorld();
        }
    }

    getProductScale(asset) {
        let assetObj = getObjectFromRootByName(asset, asset.name);
        if (assetObj) {
            return assetObj.scale;
        }
        return new THREE.Vector3(1,1,1);
    }

    setProductScale(asset, lengthInScale, heightInScale, depthInScale) {
        let assetObj = getObjectFromRootByName(asset, asset.name);
        if (assetObj) {
            assetObj.scale.set(lengthInScale, heightInScale, depthInScale);
            asset.userData.scale.set(lengthInScale, heightInScale, depthInScale);
            asset.userData.pvb.update();
        }
    }
    
    /**
     * Clone currently selected asset.
     */
    cloneSelectedAsset() {
        if ( this.selection.object != null ) {
            let clonedAsset = this.autoPlacementManager.cloneSelectedAsset( this.selection.object, this.selection.placementType );
            this.setSelection( clonedAsset );
        }
    }

    /*
    *load New asset, delete the selected one and change selection
    */
    swapSelectedAsset(swappedAssetId) {
        if ( this.selection.object != null ) {
            const rotation = this.selection.object.rotation.x;
            this.rotateSelectionVertically(-rotation);
            let swappedAsset = this.autoPlacementManager.swapSelectedAsset( this.selection.object, swappedAssetId );
            this.deleteSelectedAsset(true);
            this.setSelection( swappedAsset );
            this.rotateSelectionVertically(rotation);
        }
    }

    /**
     * Delete currently selected asset.
      * @param {THREE.Scene} isSwapped - Boolean for asset deleted on swap
     */
    deleteSelectedAsset(isSwapped = false) {
        if ( this.selection.object != null ) {
            let itemToDelete = this.selection.object;
            itemToDelete.userData.isSwapped = isSwapped;
            itemToDelete.userData.visible = false;

            let objectToDelete = getObjectFromRootByName(this.selection.object, this.selection.object.name) || itemToDelete;

            this.resetSelection();

            objectToDelete.traverse( function ( child ) {
                if ( child.isMesh ) {
                    // Make invisible
                    child.visible = false;
                }
            });

            this.raycastManager.buildFocusTargetList();
        }
    }

    /**
     * Reset focused asset
     */
    resetFocusedAsset() {
        if (this.focusedAsset != null && this.focusedAsset != this.selection.object ) {
            let focusedAssetObj = getObjectFromRootByName(this.focusedAsset, this.focusedAsset.name) || this.focusedAsset;
            setHighlightedState( focusedAssetObj, false );
            this.focusedAsset = null;
        }
    }

    /**
     * Returns true if the currently focused asset is also the selected asset.
     */
    isFocusedAssetSelected() {
        return (this.focusedAsset != null && this.selection.object != null && this.focusedAsset == this.selection.object);
    }

    /**
     * Checks wether the currently selected asset is inside valid space bounds & update the AssetState & highlighted color accordingly.
     */
    validatePlacement () {
        let corners = this.selection.object.userData.pvb.getCorners();
        let placementType = this.selection.object.userData.placementType;

        let c1 = corners.c1;
        let c2 = corners.c2;
        let c3 = corners.c3;
        let c4 = corners.c4;
        let dir = new THREE.Vector3();
        let intersectTarget = null;
        let validPlacement = false;

        if ( placementType == Constants.PlacementType.FLOOR ) {
            c1.y += 0.01;
            c2.y += 0.01;
            c3.y += 0.01;
            c4.y += 0.01;
            dir.set(0,-1,0);

            if (this.selection.object.userData.isPillow) {
                let halfHeight = this.selection.object.userData.pvb.halfHeight;
                c1.y += halfHeight;
                c2.y += halfHeight;
                c3.y += halfHeight;
                c4.y += halfHeight;
            }
            
            intersectTarget = this.spaceManager.floors;
            intersectTarget = intersectTarget.concat(this.spaceManager.miscNodes);

            if ( this.sceneCreator.LFAArea != undefined && this.sceneCreator.LFAArea != null ) {
                intersectTarget.concat( this.sceneCreator.LFAArea.areaObj.floor );    
            }
        }

        else if ( placementType == Constants.PlacementType.CEILING ) {
            c1.y -= 0.01;
            c2.y -= 0.01;
            c3.y -= 0.01;
            c4.y -= 0.01;
            dir.set(0,1,0);
            intersectTarget = this.spaceManager.ceilings;

            if ( this.sceneCreator.LFAArea != undefined && this.sceneCreator.LFAArea != null ) {
                intersectTarget.concat( this.sceneCreator.LFAArea.areaObj.ceiling );
            }
        }

        let c1Intersects = this.raycastManager.setAndIntersect(c1, dir, intersectTarget);
        let c2Intersects = this.raycastManager.setAndIntersect(c2, dir, intersectTarget);
        let c3Intersects = this.raycastManager.setAndIntersect(c3, dir, intersectTarget);
        let c4Intersects = this.raycastManager.setAndIntersect(c4, dir, intersectTarget);

        validPlacement = c1Intersects && c2Intersects && c3Intersects && c4Intersects && this.validateFloorItemIntersectingWall();


        if (validPlacement && this.selection.object.userData.isPillow) {
            validPlacement = this.pillowPlacementManager.orientAndOffsetPillow(this.selection.object) ;
        }

        if ( validPlacement) {
            if ( this.selection.state == Constants.AssetState.INVALID ) {
                this.selection.state = Constants.AssetState.PLACING;
                let selectionObj = getObjectFromRootByName(this.selection.object, this.selection.object.name)  || this.selection.object;
                setHighlightedState( selectionObj, true, Constants.defaultHighLightColor );
            }
            this.preserveAssetPreviousState();
        }

        else {

            if ( this.selection.state == Constants.AssetState.PLACING ) {
                this.selection.state = Constants.AssetState.INVALID;
                let selectionObj = getObjectFromRootByName(this.selection.object, this.selection.object.name)  || this.selection.object;
                setHighlightedState( selectionObj, true, Constants.invalidHighLightColor );
            }
        }

    }

    /***
     * Checks wether the currently selected pillow has valid placement on rotation.
     */
    validatePillowPlacementOnRotation() {
        if (this.selection.object != null && this.selection.object.userData.isPillow) {
            let validPlacement = this.pillowPlacementManager.validatePillowRotation(this.selection.object);
            if ( validPlacement) {
                let selectionObj = getObjectFromRootByName(this.selection.object, this.selection.object.name) || this.selection.object;
                setHighlightedState( selectionObj, true, Constants.defaultHighLightColor );
                this.preserveAssetPreviousState();
                this.selection.state = Constants.AssetState.ROTATING;
            }
            else {
                if (this.selection.state == Constants.AssetState.ROTATING) {
                    this.selection.state = Constants.AssetState.INVALID_ROTATION;
                    let selectionObj = getObjectFromRootByName(this.selection.object, this.selection.object.name) || this.selection.object;
                    setHighlightedState( selectionObj, true, Constants.invalidHighLightColor );
                }
            }
        }
    }

    /**
     * Translate asset to some position. (The asset may not be translated to the exact position if any snapping conditions are met).
     * @param {THREE.Scene} asset - The asset to translate.
     * @param {THREE.Vector3} newPosition - The position to translate to.
     */
    moveAsset ( asset , newPosition ) {
        if ( asset != null && newPosition != null ) {
            asset.position.copy( newPosition );
            this.pillowPlacementManager.setCurrentPositionHolder(newPosition);
            this.selection.refreshSelectionTransform();
    
            if ( !this.selection.object.userData.isPillow && this.sceneCreator.snappingEnabled && this.selection.object.parent == this.scene ) {
    
                let didSnap = false;
                // Try snapping to identical items
                if ( this.selection.state != Constants.AssetState.INVALID && !isSnapped(this.selection.object ) ) {
                    didSnap = this.objectSnappingManager.snapToIdenticalItem(this.sceneAssets);
                }
    
                // If still not snapped, try snapping to wall
                if ( this.selection.state != Constants.AssetState.INVALID && !isSnapped(this.selection.object ) ) {
                    didSnap = this.objectSnappingManager.snapToWall();
                }

                if( didSnap ) {
                    this.pullOrigin.copy ( this.pointEnd );
                }
            }
            this.positionSelectionVertically();
            this.validatePlacement();
            this.sceneCreator.save_scene_edit = true;
        }
    }

    positionSelectionVertically () {
        const parent = this.selection.object.parent;
        this.scene.attach(this.selection.object);
        this.selection.refreshSelectionTransform();
        let object = this.selection.object;
        let assetObj = getObjectFromRootByName(object, object.name) || object;
        var size = new THREE.Box3().setFromObject(assetObj).getSize();
        let center = new THREE.Box3().setFromObject(assetObj).getCenter();
        object.position.y += (this.selection.worldPosition.y - (center.y - size.y/2.0) );
        object.updateMatrixWorld();
        if (parent != this.scene) {
            parent.attach(this.selection.object);
        }
        this.selection.refreshSelectionTransform();
    }

    rotateSelectionVertically ( rotation = THREE.MathUtils.degToRad(90) ) {
        //Attach object to world to set world transformations
        const parent = this.selection.object.parent;
        this.scene.attach(this.selection.object);
        this.resetFocusedAsset();
        this.selection.refreshSelectionTransform();
        let object = this.selection.object;
        let assetObj = getObjectFromRootByName(object, object.name) || object;
        let prevSize = new THREE.Box3().setFromObject(assetObj).getSize();
        let prevCenter = new THREE.Box3().setFromObject(assetObj).getCenter();
        object.rotateX(rotation);
        object.updateMatrixWorld();
        let size = new THREE.Box3().setFromObject(assetObj).getSize();
        let center = new THREE.Box3().setFromObject(assetObj).getCenter();
        object.position.y = this.selection.worldPosition.y + ((prevCenter.y - (prevSize.y/2.0)) - ((center.y - (size.y/2.0)))) ;
        if (parent != this.scene) {
            parent.attach(this.selection.object);
        }
        this.selection.refreshSelectionTransform();
    }

    flipProduct() {
        this.rotateSelectionVertically();
    }

    buildPVBObject = (object) => {
        let pvb = object.userData.pvb;
        disposeScene(pvb);
        object.remove(pvb);
        object.userData.pvb = null;
        object.userData.pvb = new PVB (object)
    }

    getPlacementCorrectedHeight() {
        const object = this.selection.object
        if (object.userData.currentPlacement) {
            const boundingBox =  new THREE.Box3().setFromObject(object);
            let height = boundingBox.max.y - boundingBox.min.y;
            if (object.userData.originalPlacement == Constants.PlacementType.WALL) {
                height = height/2;
            }
            return height;
        } 
        return 0;
    }

    wallPlacementCorrection (position) {
        const object = this.selection.object

        if (!this.isPlacementCorrectionRequired) {
            return
        }

        const objectSize = object.userData.pvb.getDataForSnapTest();
        const objectDepth = objectSize.frontOrigin.distanceTo(objectSize.backOrigin) / 2.0;      
        // get directin of object
        const direction = new THREE.Vector3();
        object.getWorldDirection(direction);
        direction.normalize();
        const scaledDirection = direction.multiplyScalar(objectDepth);
        // add calculated direction to end point of object
        position.add(scaledDirection);
    }

    ceilingPlacementCorrection (position) {
        const object = this.selection.object

        const boundingBox =  new THREE.Box3().setFromObject(object);
        const height = boundingBox.max.y - boundingBox.min.y;
        // add calculated direction to end point of object
        if (object.userData.originalPlacement == 'wall') {
            position.y -= height/2;
        } else {
            position.y -= height;
        }
    }

    floorPlacementCorrection (position) {
        const object = this.selection.object

        if (!this.isPlacementCorrectionRequired) {
            return
        }

        const boundingBox =  new THREE.Box3().setFromObject(object);
        const height = boundingBox.max.y - boundingBox.min.y;
        // add calculated direction to end point of object
        if (object.userData.originalPlacement == 'wall') {
            position.y += height/2;
        } else {
            position.y += height;
        }
    }

    applyPlacementCorrection(position) {
        const object = this.selection.object

        switch (object.userData.currentPlacement) {
            case Constants.PlacementType.WALL:
                this.wallPlacementCorrection(position)
                break;
            case Constants.PlacementType.CEILING:
                this.ceilingPlacementCorrection(position)
                break;
            case Constants.PlacementType.FLOOR:
                this.floorPlacementCorrection(position)
                break;
        }
    }

    changeObjectPlacementType (placementType) {
        const selectedObject = this.selection.object;
        let isOrignalPlacement = true

        // if changed placement type is same as current placement then return 
        if (selectedObject.userData.placementType == placementType) { 
            return 
        }

        // save the original placement of object to reset its value
        if (!selectedObject.userData.originalPlacement) {
            selectedObject.userData.originalPlacement = selectedObject.userData.placementType;
        }

        // reset rotation for floor items
        if ( selectedObject.userData.placementType == Constants.PlacementType.FLOOR) {
            selectedObject.rotation.set( 0, 0, 0 )
        }
        
        // if placement is changed back to its original placement else apply change
        if (selectedObject.userData.originalPlacement == placementType) {
            selectedObject.userData.placementType = placementType;
            selectedObject.userData.currentPlacement = null;
        } else {
            isOrignalPlacement = false;
            selectedObject.userData.currentPlacement = placementType;
            selectedObject.userData.placementType = placementType;
        }

        // build new pov according to placement type
        this.buildPVBObject(selectedObject);

        // reset stack if stacked
        if (selectedObject.userData.isStacked) {
            this.scene.attach(selectedObject);
            selectedObject.userData.isStacked = false;
        }

        this.placeObjectsOnPlacementChange(placementType, isOrignalPlacement);
        this.resetSelection();
        this.setSelection(selectedObject);
        this.setMovementOrigin();
    }

    placeObjectsOnPlacementChange (placementType, isOrignalPlacement) {
        const selectedObject = this.selection.object;
        this.isPlacementCorrectionRequired = true;

        if (placementType == Constants.PlacementType.WALL) {
            this.autoPlacementManager.autoPlaceWallAssets([selectedObject]);
        } else if (placementType == Constants.PlacementType.FLOOR) {
            selectedObject.position.y = 0;
        } else if (placementType == Constants.PlacementType.CEILING) {
            const spaceArea = this.spaceManager.areas[Object.keys(this.spaceManager.areas)[0]];
            const spaceBoundingBox = new THREE.Box3().setFromObject(spaceArea.root);
            const spaceSize = new THREE.Vector3();
            spaceBoundingBox.getSize(spaceSize);
            selectedObject.position.y = spaceSize.y - 0.2;
        }

        if (!isOrignalPlacement) {
            this.applyPlacementCorrection(selectedObject.position);
        }
    }

    autoPlaceLostAssets(items) {
        this.autoPlacementManager.autoPlaceLostAssets(items);
    }

    setBabylonExported(value) {
        this.autoPlacementManager.setBabylonExported(value);
    }

    /**
     * Check if asset is being moved from floor surface to a scene asset surface
     */
    isJumpingToItem ( placementInfo ) {
        if(placementInfo.intersectedObj != null && this.selection.object.parent != placementInfo.intersectedObj) {
            // Only allow stacking on floor items if intersected object is an item
            if(placementInfo.intersectedObj.userData != null) {
                if(placementInfo.intersectedObj.userData.placementType == Constants.PlacementType.FLOOR || this.isValidBaseItemForStacking(placementInfo.intersectedObj)) {
                    return true;
                }
                else {
                    return false;
                }
            }
            return true;
        }
        else {
            return false;
        }
    }

    /**
     * Check if asset is being moved from a scene asset surface to floor surface
     */
    isJumpingToFloor ( placementInfo ) {
        return ( ( placementInfo.intersectionPoint == null ) &&
            ( this.selection.object.parent != this.scene ||
                rounded( this.selection.worldPosition.y ) != rounded( this.helperPlane.position.y ) ) );
    }

    /**
     * Check if asset is being moved on floor surface
     */
    isMovingOnFloor ( placementInfo ) {
        return ( placementInfo.intersectionPoint == null );
    }

    /**
     * Check if asset is being moved on a scene asset surface
     */
    isMovingOnItem ( placementInfo ) {
        return ( placementInfo.intersectedObj != null && this.selection.object.parent == placementInfo.intersectedObj );
    }

    /**
     * Check if asset is being moved on misc area surface
     */
    isJumpingToMisc ( placementInfo ) {
        return ( placementInfo.isMiscAsset );
    }

    /**
     * NOTE: Use this with the assumption that isMovingOnItem == true
     * Check if asset is changing height on a scene asset surface
     */
    isChangingHeightOnItem ( placementInfo ) {
        return ( rounded( this.selection.worldPosition.y ) != rounded( placementInfo.intersectionPoint.y ) );
    }

    updateParent(object, newParent) {
        this.scene.attach(object);
        if (newParent != this.scene) {
            newParent.attach(object);
        }
    }

    /**
     * Translate currently selected asset to some position & make the asset child of a new parent node.
     * @param {THREE.Vector3} newPosition - The position to translate to.
     * @param {THREE.Scene} newParent - The asset to use as parent node.
     * @param {Boolean} updateParent - Use newParent as parent node when true, else use scene as parent node.
     */
    processJump ( newPosition, newParent, updateParent = true ) {
        if ( newParent != this.scene ) {

            if ( updateParent ) {
                this.updateParent(this.selection.object, newParent);
                this.moveAsset( this.selection.object, ( newParent.worldToLocal( newPosition.clone() ) ) );
            }
            else {

                if ( this.selection.object.userData.isStacked ) { // if already stacked
                    this.updateParent(this.selection.object, this.scene);
                }

                this.moveAsset( this.selection.object, newPosition );
            }

            this.selection.object.userData.isStacked = true;

            if( this.selection.object.userData.isPillow && this.sceneCreator.activeCamera.name != 'topDown' && this.sceneCreator.activeCamera.name != 'topDownOrtho' && this.selection.object.userData.isStacked) {
                this.rotationControls.showAxis( 'X', true );
            }

        }
        else {
            this.updateParent(this.selection.object, newParent);
            this.selection.object.userData.isStacked = false;
            this.moveAsset( this.selection.object, newPosition );
            if( this.selection.object.userData.isPillow && !this.selection.object.userData.isStacked ) {
                this.rotationControls.showAxis( 'X', false );
            }
        }
        
    }

    /**
     * Move asset to movement termination point.
     * The logic for moving the asset independent of where it was grabbed from is also contained in this function.
    */
   processTranslation () {
       
        this.applyPlacementCorrection(this.pointEnd);
        let offset = this.pointEnd.clone().sub( this.pointStart );
        let inverseParentQuaternion = new THREE.Quaternion();
        inverseParentQuaternion.copy( this.selection.parentQuaternion ).inverse();
        offset.applyQuaternion( inverseParentQuaternion );

        if ( this.selection.placementType === Constants.PlacementType.WALL ) {
            this.selection.object.position.add( offset );
        }
        else {

            if( this.selection.object.userData.snapped ) {
                this.pullVector.copy( this.pointEnd ).sub ( this.pullOrigin );

                let pullMag = this.pullVector.length();
                let normalizedPullVector = this.pullVector.clone().normalize();
                let dotProduct = normalizedPullVector.clone().dot( this.selection.worldDirection );

                // Positive Value == Clockwise and Negative Value == Anti-Clockwise
                let pullDir = normalizedPullVector.clone().cross( this.selection.worldDirection ).y;
                let validatePlacementCheck = true;
                
                switch( this.selection.object.userData.snapDir ) {

                    case "back":
                    if ( Math.abs( dotProduct ) > 0.65 ) {
                        if( pullMag > 0.1 ) {

                            this.selection.object.userData.snapped = false;
                            this.pullVector.y = 0;
                            this.moveAsset( this.selection.object, this.selection.object.position.clone().add( this.pullVector ));
                            this.pointStart.copy( this.pointEnd );
                            validatePlacementCheck = false;

                        }

                        // ELSE DON'T UPDATE PULL ORIGIN

                    }

                    if( dotProduct <= 0.7 ) {

                        if( pullDir > 0 ) {
                            this.selection.object.translateOnAxis( Constants._unit.aX, pullMag );
                            this.pullOrigin.copy ( this.pointEnd );
                        }
                        else {
                            this.selection.object.translateOnAxis( Constants._unit.X, pullMag );
                            this.pullOrigin.copy ( this.pointEnd );
                        }
                    }

                    break;

                    case "front":
                    if ( Math.abs( dotProduct ) > 0.65 ) {

                        if( pullMag > 0.1 ) {

                            this.selection.object.userData.snapped = false;
                            this.pullVector.y = 0;
                            this.moveAsset( this.selection.object, this.selection.object.position.clone().add( this.pullVector ));
                            this.pointStart.copy( this.pointEnd );
                            validatePlacementCheck = false;

                        }
                        // ELSE DON'T UPDATE PULL ORIGIN
                        
                    }

                    else if( dotProduct >= -0.7 ) {

                        if( pullDir > 0 ) {
                            this.selection.object.translateOnAxis( Constants._unit.aX, pullMag );
                            this.pullOrigin.copy ( this.pointEnd );
                        }
                        else {
                            this.selection.object.translateOnAxis( Constants._unit.X, pullMag );
                            this.pullOrigin.copy ( this.pointEnd );
                        }
                        
                    }
                   
                    break;

                    case "left" :
                    if ( Math.abs( dotProduct ) < 0.50 ) {

                        if( pullMag > 0.1 ) {

                            this.selection.object.userData.snapped = false;
                            this.pullVector.y = 0;
                            this.moveAsset( this.selection.object, this.selection.object.position.clone().add( this.pullVector ));
                            this.pointStart.copy( this.pointEnd );
                            validatePlacementCheck = false;

                        }

                        // ELSE DON'T UPDATE PULL ORIGIN
                        
                    }

                    else {

                        if( dotProduct > 0 ) {
                            this.selection.object.translateOnAxis( Constants._unit.Z, pullMag );
                            this.pullOrigin.copy ( this.pointEnd );
                        }
                        else {
                            this.selection.object.translateOnAxis( Constants._unit.aZ, pullMag );
                            this.pullOrigin.copy ( this.pointEnd );
                        }
                    }

                    break;

                    case "right" :
                    if ( Math.abs( dotProduct ) < 0.50 ) {

                        if( pullMag > 0.1 ) {

                            this.selection.object.userData.snapped = false;
                            this.pullVector.y = 0;
                            this.moveAsset( this.selection.object, this.selection.object.position.clone().add( this.pullVector ));
                            this.pointStart.copy( this.pointEnd );
                            validatePlacementCheck = false;

                        }

                        // ELSE DON'T UPDATE PULL ORIGIN
                        
                    }

                    else {

                        if( dotProduct > 0 ) {
                            this.selection.object.translateOnAxis( Constants._unit.Z, pullMag );
                            this.pullOrigin.copy ( this.pointEnd );
                        }
                        else {
                            this.selection.object.translateOnAxis( Constants._unit.aZ, pullMag );
                            this.pullOrigin.copy ( this.pointEnd );
                        }
                        
                    }

                    break;

                    default:
                    break;

                }
                if (validatePlacementCheck === true) {
                    this.validatePlacement();
                }
            }
            else {
                this.moveAsset( this.selection.object, this.selection.object.position.clone().add( offset ));        
            }
        }

        this.pointStart.copy( this.pointEnd );
    }


    /**
     * Check if item is valid for stacking.
     * @param {THREE.Scene} item - The asset to check stacking validation on. 
     */
    isValidBaseItemForStacking ( item ) {
        if (this.sceneCreator.itemCategoryList[ item.name ] != undefined ) {
            for (let index = 0; index < this.sceneCreator.validBaseItemCategories.length; index++) {
                const category = this.sceneCreator.validBaseItemCategories[index];
                    if (this.sceneCreator.itemCategoryList[ item.name ].toLowerCase().includes(category)) {
                       return true;
                    }   
            }
        }
        return false;
    }  
          
    /**
     * Toggle clipping.
     * @param {boolean} clipping - The value of clipping ( true / false ).
     */
    toggleClipping ( clipping ) {
        this.clipping = clipping;
    }

    /**
     * Check if one asset can be stacked on the other.
     * @param {THREE.Scene} itemToBeStacked - The asset to stack.
     * @param {THREE.Scene} baseItem - The base asset on which to stack the itemToBeStacked.
     */
    compareForStacking ( itemToBeStacked, baseItem ) {

        let itemAisRug = itemToBeStacked.userData.isRug;
        let itemBisRug = baseItem.userData.isRug;

        if ( itemBisRug ) { // If both are rugs, allow stacking
            return { isStackable: true, linkToParent: false };
        }
        else if( itemAisRug && !itemBisRug ) {
            return { isStackable: false, linkToParent: false };
        }

        else if( !itemAisRug && !itemBisRug ) { // else if neither of the items idenitfy as rug

            let dimensionsA = multiplyVectorsElementWise(itemToBeStacked.userData.size,itemToBeStacked.userData.scale) ;
            let dimensionsB = multiplyVectorsElementWise(baseItem.userData.size, baseItem.userData.scale);
            let coveredAreaA = ( dimensionsA.x * dimensionsA.z );
            let coveredAreaB = ( dimensionsB.x * dimensionsB.z );

            return { isStackable: ( coveredAreaA < coveredAreaB * 4.5 ), linkToParent: true };

        }

    }

    

    // Private methods

    /**
     * Mouse down event handler
     */
    #onMouseDown = () => {
        
        if (this.transformControls.enabled) return;

        if( this.selection.object != null ) {
            this.objectSnappingManager.snapGuide.setTarget( this.selection.object );
        }
        if( this.selection.object != null && !this.rotationControls.active ) {
            if (this.selection.placementType == Constants.PlacementType.CEILING && (this.sceneCreator.activeCamera.name == "topDown" || this.sceneCreator.activeCamera.name == "topDownOrtho")) {
                this.spaceManager.enableTransparentCeiling();
            }
            let intersect = this.raycastManager.cameraIntersectSceneAssets(this.clipping);
            if (intersect && getRootNodeFromObject(this.scene, intersect.object) == this.selection.object) {
                this.setMovementOrigin();
                this.selection.state = Constants.AssetState.PLACING;
            }
        }
        else if( this.selection.object != null && this.selection.object.userData.isPillow) {
            this.selection.refreshSelectionTransform();
            if (this.pillowPlacementManager.currentPillowObject == null || this.pillowPlacementManager.currentPillowObject != this.selection.object) {
                let placementInfo = this.getPlacementInfo( this.selection.placementType );
                this.pillowPlacementManager.setBaseObject(placementInfo.intersectedObj);
                this.preserveAssetPreviousState();
            }
            // set pillow state to rotating if rotation controls are active in case of pillows
            if (this.rotationControls.active) {
                this.selection.state = Constants.AssetState.ROTATING;
            }
            
        }
        
    }

    /**
     * Mouse move event handler
     */
    #onMouseMove = () => {

        // remove red gizmo from pillow if in unstacked state
        if (this.selection.object != null && this.selection.object.userData.isPillow && !this.selection.object.userData.isStacked) {
            this.rotationControls.showAxis( 'X', false );
        }
        if ( this.selection.object != null && this.selection.placementType.toLowerCase() == Constants.PlacementType.WALL.toLowerCase()) { 
            const forwardDirection = this.selection.worldDirection.clone(); // Clone the forward direction
            const backwardDirection = forwardDirection.negate(); // Negate the vector to get the backward direction
            this.currentWallIntersect = this.raycastManager.setAndIntersect(this.selection.worldPosition, backwardDirection, this.sceneCreator.space.walls);
        }
        // check for valid rotation in case of pillows if they are in rotation mode.
        if ( !this.sceneCreator.isPanning && this.selection.object != null &&
            ( this.selection.state == Constants.AssetState.ROTATING || this.selection.state == Constants.AssetState.INVALID_ROTATION )) {
                this.selection.refreshSelectionTransform();
                // this.validatePillowPlacementOnRotation();
        }
        else if ( !this.transformControls.enabled && !this.sceneCreator.isPanning && this.selection.object != null &&
            ( this.selection.state == Constants.AssetState.PLACING  ||
                this.selection.state == Constants.AssetState.INVALID) ) {

            this.sceneCreator.panningEnabled = false;
            this.sceneCreator.isPanned = true;
            this.selection.refreshSelectionTransform();
            let placementInfo = this.getPlacementInfo( this.selection.placementType );

            if ( placementInfo ) {
                if (this.selection.object.userData.isPillow) {
                    let checkPillowBaseObject = this.pillowPlacementManager.currentBaseObject == placementInfo.intersectedObj;
                    this.pillowPlacementManager.setBaseObject(placementInfo.intersectedObj);
                    if (!checkPillowBaseObject) {
                        this.pillowPlacementManager.resetPillowRotation(this.selection.object);
                    }
                }
                this.sceneContainer.style.cursor = "grabbing";
                if (this.selection.object.userData.isRug) {
                    this.setDisclaimer(true);
                }
                if ( this.selection.placementType === Constants.PlacementType.FLOOR ) {
                    if ( this.isJumpingToMisc( placementInfo ) ) {
                        if( !this.selection.object.userData.isRug) {
                            this.updateParent(this.selection.object, placementInfo.intersectedObj);
                            this.selection.object.userData.isStacked = true;
                            this.moveAsset( this.selection.object, placementInfo.intersectionPoint);
                            this.pointStart.copy( placementInfo.intersectionPoint );
                        }
                        else {
                            if ( placementInfo != null && placementInfo.helperPoint != null ) {
                                this.pointStart.y = placementInfo.helperPoint.y;
                                this.pointEnd.copy( placementInfo.helperPoint );
                                this.processTranslation();
                            }
                            
                        }

                        return;
                    }

                    if ( this.isJumpingToItem ( placementInfo ) ) {

                        let stackingInfo = this.compareForStacking( this.selection.object, placementInfo.intersectedObj );

                        if ( stackingInfo.isStackable ) {
                            this.processJump( placementInfo.intersectionPoint, placementInfo.intersectedObj, stackingInfo.linkToParent );
                            this.pointStart.copy( placementInfo.intersectionPoint );
                            return;
                        }
                        else {
                            placementInfo.intersectionPoint = null;
                            placementInfo.intersectedObj = null;
                        }
                    }

                    if ( this.isMovingOnItem( placementInfo ) ) {
                        if ( this.isChangingHeightOnItem( placementInfo ) ) {
                            this.processJump( placementInfo.intersectionPoint, placementInfo.intersectedObj );
                            this.pointStart.copy( placementInfo.intersectionPoint );
                            return;
                        }
                        else{
                            this.pointEnd.copy( placementInfo.intersectionPoint );
                            this.processTranslation();
                            return;
                        }
                    }

                    if ( this.isJumpingToFloor( placementInfo ) ) {
                        if (placementInfo != null && placementInfo.helperPoint != null) {
                            this.processJump( placementInfo.helperPoint, this.scene);
                            this.pointStart.copy( placementInfo.helperPoint );
                            return;
                        }
                       
                    }

                    if ( this.isMovingOnFloor( placementInfo ) ) {
                        if ( placementInfo != null && placementInfo.helperPoint != null ) {
                            this.pointStart.y = placementInfo.helperPoint.y;
                            this.pointEnd.copy( placementInfo.helperPoint );
                            this.processTranslation();
                            return;
                        }    
                    }

                    else if ( this.selection.object.parent == this.scene  &&
                        rounded( this.selection.worldPosition.y ) != rounded( this.helperPlane.position.y ) ) {
                        this.selection.object.position.y = this.helperPlane.position.y;
                        return;
                    }
                    
                }

                else if ( this.selection.placementType === Constants.PlacementType.WALL ) {

                    if ( placementInfo != null && placementInfo.intersectionPoint != null ) {

                        if ( isNaN(this.pointStart.x) ) {
                            this.pointStart.copy( placementInfo.intersectionPoint );
                        }

                        if ( this.selection.state == Constants.AssetState.INVALID ) {
                            this.selection.state = Constants.AssetState.PLACING;
                            let selectionObj = getObjectFromRootByName(this.selection.object, this.selection.object.name) || this.selection.object;
                            setHighlightedState( selectionObj, true, Constants.defaultHighLightColor );
                        }
                        else {
                            this.preserveAssetPreviousState();
                        }

                        if ( !roundedEqual( this.selection.worldDirection, placementInfo.direction ) ) {
                            this.selection.worldDirection.copy( placementInfo.direction );
                            this.selection.object.position.copy( placementInfo.intersectionPoint );

                            let lookAtPoint = new THREE.Vector3();
                            lookAtPoint.copy( placementInfo.intersectionPoint ).add( placementInfo.direction );
                            this.selection.object.lookAt( lookAtPoint );
                            this.pointStart.copy ( this.selection.object.position );
                            this.isPlacementCorrectionRequired = true;
                            return;
                        }

                        this.pointEnd.copy( placementInfo.intersectionPoint );
                        this.processTranslation();

                    }

                    else if ( placementInfo != null && placementInfo.helperPoint != null )
                    {
                        if ( this.selection.state == Constants.AssetState.PLACING ) {

                            this.selection.state = Constants.AssetState.INVALID;
                            let selectionObj = getObjectFromRootByName(this.selection.object, this.selection.object.name) || this.selection.object;
                            setHighlightedState( selectionObj, true, Constants.invalidHighLightColor );
                        }

                        let lookAtPoint = new THREE.Vector3();

                        if ( this.sceneCreator.activeCamera.name == "topDown" || this.sceneCreator.activeCamera.name == "topDownOrtho") {
                            lookAtPoint.copy( this.selection.object.position ).add( Constants._unit.Y );    
                        }

                        else {
                            lookAtPoint.copy( this.sceneCreator.activeCamera.position );  
                        }

                        lookAtPoint.copy( this.selection.object.position ).add( Constants._unit.Y );    
                        this.selection.object.lookAt( lookAtPoint );
                        this.pointStart.copy ( this.selection.object.position );
                        this.pointEnd.copy( placementInfo.helperPoint );
                        this.processTranslation();
                    }
                    
                    return;
                }

                else if ( this.selection.placementType === Constants.PlacementType.CEILING ) {
                    
                    if ( placementInfo != null && placementInfo.helperPoint != null ) {
                        this.pointStart.y = placementInfo.helperPoint.y;
                        this.pointEnd.copy( placementInfo.helperPoint );
                        this.processTranslation();

                        if ( this.selection.object.parent == this.scene  &&
                            rounded( this.selection.worldPosition.y ) != rounded( this.helperPlane.position.y ) ) {
                            this.selection.object.position.y = this.helperPlane.position.y - this.getPlacementCorrectedHeight();
                        }

                        
                    }

                    return;

                }
            }
        }

        let intersect = this.raycastManager.cameraIntersectSceneAssets(this.clipping);

        if( intersect && !this.transformControls.enabled && !this.sceneCreator.isPanning && this.sceneCreator.panningEnabled && !this.rotationControls.active ) {
            if (this.sceneCreator.editModeOn) {
                if ( this.focusedAsset != null && this.focusedAsset != this.selection.object ) {
                    let selectionObj = getObjectFromRootByName(this.focusedAsset, this.focusedAsset.name) || this.focusedAsset;
                    setHighlightedState( selectionObj, false );
                }

                this.focusedAsset = getRootNodeFromObject(this.scene, intersect.object );

                if ( this.focusedAsset != this.selection.object ) {
                        let selectionObj = getObjectFromRootByName(this.focusedAsset, this.focusedAsset.name) || this.focusedAsset;
                        setHighlightedState( selectionObj, true, Constants.focusedHighlightColor );
                }

                let item = this.scene.getObjectById( intersect.object.userData.rootNode );

                if( this.selection.object == null )
                {
                    // Update Hover Image UI
                    this.scene.getObjectById( intersect.object.userData.rootNode )
                    this.sceneCreator.showHoverImage( item.name );
                    this.sceneCreator.updateHoverImagePosition( item );
                }
                else
                {
                    if( item.name != this.selection.object.name && this.selection.state == Constants.AssetState.PLACING )
                    {
                        this.sceneCreator.showHoverImage( item.name );
                        this.sceneCreator.updateHoverImagePosition( item );
                    }
                    else
                    {
                        this.sceneCreator.hideHoverImage();
                    }
                }

                this.sceneContainer.style.cursor = "grab";
            }

            this.navControl.hide = true;
        }
        else {

            if (this.sceneCreator.panningEnabled) {
                if ( this.focusedAsset != null && this.focusedAsset != this.selection.object ) {
                    let selectionObj = getObjectFromRootByName(this.focusedAsset, this.focusedAsset.name) || this.focusedAsset;
                    setHighlightedState( selectionObj, false );
                }
    
                this.focusedAsset = null;
                this.sceneCreator.hideHoverImage();
    
                if(this.sceneCreator.isPanning) {
                    this.sceneContainer.style.cursor = "move";
                    this.navControl.hide = true;
                }
                else {
                    this.sceneContainer.style.cursor = "auto";
                    
                    if(this.selection.object || !this.raycastManager.cameraIntersectFloor()) {
                        this.navControl.hide = true;
                    }
                    else {
                        this.navControl.hide = false;
                    }
                }
                if(this.sceneCreator.cameraControls.isConnected){
                    if(this.setSliderValue != null){
                        this.setSliderValue(this.getCameraPolarAngle());
                    }
                }
            }
            
        }
        
    }

    setObjectMovementSpeed = (speed) => {
        this.objectMovementSpeed = speed;
    }

    // Function to move the selected object by 1 inch (or any specified distance) in a given direction
    moveSelectedObject(direction) {
    
        // Ensure an object is selected, if not return true to move camera instead
        if (!this.selection.object) return true;

        // Get the camera's direction and project it onto the x-z plane for movement for floor and ceiling items
        const cameraDirection = new THREE.Vector3();
        this.sceneCreator.activeCamera.getWorldDirection(cameraDirection);
        cameraDirection.y = 0;  // Make sure no movement in y-axis so that object does not move up and down
        cameraDirection.normalize();
        
        // Calculate the right direction relative to the camera on the x-z plane
        const cameraRight = new THREE.Vector3();
        cameraRight.crossVectors(cameraDirection, new THREE.Vector3(0, 1, 0)).normalize();
    
        const movement = new THREE.Vector3(); // Vector to store the movement direction    

        // locate wall object
        if (this.selection.placementType.toLowerCase() === Constants.PlacementType.WALL.toLowerCase()) {

            const forwardDirection = this.selection.worldDirection.clone(); // Clone the forward direction
            const backwardDirection = forwardDirection.negate(); // Negate the vector to get the backward direction
            let wallIntersect = this.raycastManager.setAndIntersect(this.selection.worldPosition, backwardDirection, this.sceneCreator.space.walls);
            if(this.currentWallIntersect == null) { 
                this.currentWallIntersect = wallIntersect
            }
            if (wallIntersect?.object != this.currentWallIntersect?.object) {
                return
            }
            if( this.currentWallIntersect && this.currentWallIntersect.face && this.currentWallIntersect.face.normal ) {
                this.wallNormal = this.currentWallIntersect.face.normal;
            }
            
            if(this.wallNormal == null || this.wallNormal == undefined) {
                return
            } 

            console.log("Checking wall normal", this.wallNormal)

            // Determine if the wall is aligned along the x-axis or z-axis
            if (Math.abs(this.wallNormal.x) > 0) {
                switch (direction) {
                    case 'up':
                        movement.set(0, this.objectMovementSpeed, 0); // Move up along the y-axis
                        break;
                    case 'down':
                        movement.set(0, -this.objectMovementSpeed, 0); // Move down along the y-axis
                        break;
                    case 'left':
                        movement.set(0, 0, this.objectMovementSpeed * Math.sign(this.wallNormal.x)); // Move left along the z-axis
                        break;
                    case 'right':
                        movement.set(0, 0, -this.objectMovementSpeed * Math.sign(this.wallNormal.x) ); // Move right along the z-axis
                        break;
                    default:
                        console.error('Invalid or restricted direction for this wall orientation');
                        return;
                }
            } else {
                switch (direction) {
                    case 'up':
                        movement.set(0, this.objectMovementSpeed, 0); // Move up along the y-axis
                        break;
                    case 'down':
                        movement.set(0, -this.objectMovementSpeed, 0); // Move down along the y-axis
                        break;
                    case 'left':
                        movement.set( -this.objectMovementSpeed * (this.wallNormal.y == 0 ? Math.sign(this.wallNormal.z) : Math.sign(this.wallNormal.y)), 0, 0); // Move left along the x-axis
                        break;
                    case 'right':
                        movement.set( this.objectMovementSpeed * (this.wallNormal.y == 0 ? Math.sign(this.wallNormal.z) : Math.sign(this.wallNormal.y)) ,0, 0); // Move right along the x-axis
                        break;
                    default:
                        console.error('Invalid or restricted direction for this wall orientation');
                        return;
                }
            }
            
        } else {
            switch (direction) {
                case 'up':
                    movement.copy(cameraDirection).multiplyScalar(this.objectMovementSpeed); // Move forward relative to camera
                    break;
                case 'down':
                    movement.copy(cameraDirection).multiplyScalar(-this.objectMovementSpeed); // Move backward relative to camera
                    break;
                case 'left':
                    movement.copy(cameraRight).multiplyScalar(-this.objectMovementSpeed); // Move left relative to camera
                    break;
                case 'right':
                    movement.copy(cameraRight).multiplyScalar(this.objectMovementSpeed); // Move right relative to camera
                    break;
                default:
                    console.error('Invalid direction');
                    return;
            }
        }

        // Apply movement to the object’s position
        if (this.selection.object != null) { 
            const parent = this.selection.object.parent;
            this.scene.attach(this.selection.object);        
            this.selection.object.position.add(movement);
            let newPosition = this.selection.object.position.clone()
            this.selection.object.position.copy( newPosition );
            this.selection.refreshSelectionTransform();
            if (parent != this.scene) {
                parent.attach(this.selection.object);
            }
            return false; // return false to make sure camera does not move
        }
        
    }
    
    


    /**
     * Mouse up event handler
     */
    #onMouseUp = () => {
        if ( this.selection.object != null ) {
            this.sceneCreator.panningEnabled = true;
            if ( this.selection.state == Constants.AssetState.INVALID || this.selection.state == Constants.AssetState.INVALID_ROTATION  ) {
                this.restoreAssetPreviousState();
                let selectionObj = getObjectFromRootByName(this.selection.object, this.selection.object.name) || this.selection.object;
                setHighlightedState( selectionObj, true, Constants.defaultHighLightColor );
                this.selection.object.userData.snapped = false;
            }
        }

        let intersect = this.raycastManager.cameraIntersectSceneAssets(this.clipping);
        if( this.sceneCreator.mouseDown && !this.rotationControls.active && !this.transformControls.enabled && !this.sceneCreator.isPanning && !intersect) {
            this.resetSelection();
            if (this.sceneCreator.activeCamera.name == "topDown" || this.sceneCreator.activeCamera.name == "topDownOrtho") {
                this.spaceManager.disableCeiling();
            }
        }

        if (this.selection.object != null && this.selection.object.userData.isPillow && this.selection.state != Constants.AssetState.ROTATING && this.selection.state != Constants.AssetState.INVALID_ROTATION  ) {
            this.pillowPlacementManager.adjustPillowHeight(this.selection.object);
        }

        if(!this.rotationControls.active && !this.transformControls.enabled) {
            console.log("On Click of Mouse on item")
            this.selectFocusedAsset();
        }
        
        
        this.selection.state = Constants.AssetState.PLACED;
        this.isPlacementCorrectionRequired = false;
        this.pointStart.set( 0, 0, 0 );
        this.pointEnd.set( 0, 0, 0 );

        if (!this.transformControls.enabled) {
            this.adjustWallItemIntersectingMoulding();
        }

        this.objectSnappingManager.snapGuide.setTarget( null )
        this.adjustWallItemIntersectingMoulding();
        
        if (this.focusedAsset == null ) {
            if ( !this.transformControls.enabled ) {
                this.setDisclaimer(false);
            }
            this.currentWallIntersect = null;
        }
        
    }
 }