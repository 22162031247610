import React, { useState, useEffect }  from 'react';
import { PlusOutlined, LoadingOutlined, CheckOutlined, ArrowRightOutlined, InfoCircleOutlined } from '@ant-design/icons';
import CustomerMainLayout from '../CustomerMainLayout';
import { Row, Col, Menu, Dropdown, notification, Form, Input, Select, Button, Card, Drawer, message, TreeSelect, Checkbox, Tag, Tooltip, Slider, Tabs, Radio, Breadcrumb, Empty, Pagination } from 'antd';
import AddCollection from "../CustomerProducts/AddCollection";
import { default as AddCollectionRoom } from "../CustomerRooms/AddCollection";
import {withRouter, Link} from 'react-router-dom'
import ENVIRONMENT from '../../../../environments'
import DottedLoader from '../../DottedLoader/DottedLoader';
import { connect} from 'react-redux';
import {listForStoreProducts, LIST_FOR_STORE_PRODUCT} from '../../../../redux/actions';
import axios from 'axios';
import '../../../../styles/helper.scss'
import * as Utilities from '../../Utilities'
import PaymentConfirmationModal from '../../PaymentConfirmationModalSingle/PaymentConfirmationModalSingle';
import SuccessModal from '../../SuccessModal/SuccessModal';
import CardSaveDrawer from '../../CardSaveDrawer/CardSaveDrawer';
import Modal from 'antd/lib/modal/Modal';
import SubscriptionPackageUpdate from '../../SubscriptionPackageUpdate';
import * as Styles from '../../../../Styles';
import FileConstants from '../../../../FileConstants';
import PaymentFailureModal from '../../PaymentFailureModal/PaymentFailureModal';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import StoreContent from './StoreContent';
import InAppPurchaseLimitModal from './../InAppPurchaseLimitModal';
import StyleCategory from '../../FormUtilities/StyleCategorySelect';
import ColorSelect from '../../FormUtilities/ColorSelect';
import AddEntityToProject from '../AddEntityToProject/AddEntityToProject';

const { Option } = Select;

const categoryStyle = Styles.categoryStyle;

const responsive = {
  screen_6: {
	  breakpoint: { max: 4000, min: 1920 },
	  items: 9
	},
  screen_7: {
	  breakpoint: { max: 1920, min: 1700 },
	  items: 8
	},
  screen_8: {
	  breakpoint: { max: 1700, min: 1525 },
	  items: 7
	},
	screen_1: {
	  breakpoint: { max: 1525, min: 1475 },
	  items: 7
	},
  screen_5: {
	  breakpoint: { max: 1475, min: 1280 },
	  items: 6
	},
  screen_2: {
	  breakpoint: { max: 1280, min: 1024 },
	  items: 5
	},
  screen_3: {
	  breakpoint: { max: 1024, min: 464 },
	  items: 3
	},
	screen_4: {
	  breakpoint: { max: 464, min: 0 },
	  items: 2
	}
};

const TYPE_MAPPING = {
    "products": "Product",
    "spaces": "Space",
    "scenes": "Scene Template"
}

const CUSTOMER_USERNAME = localStorage.getItem('username');
const COMPANY_ID = localStorage.getItem('company_id')

const Store = (props) => {
  let baseitemType = new URL(window.location.href).searchParams.get("item_type");
  let selectedDisplayType = new URL(window.location.href).searchParams.get("item_type");

  if (baseitemType == undefined || !(['products','spaces','scenes'].includes(baseitemType))) {
    baseitemType = 'products'
  }
  const [selectedProductIdForCollection, setSelectedProductIdForCollection] = useState(null);
  const [selectedRoomIdForCollection, setSelectedRoomIdForCollection] = useState(null);
  const [collectionModalVisible, setCollectionModalVisible] = useState(false);
  const [roomCollectionModalVisible, setRoomCollectionModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchKeywords, setSearchKeywords] = useState([]);
  const [storeState, setStoreState] = useState(true);
  const [companyData, setCompanyData] = useState(null);
  const [brandLoader, setBrandLoader] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [brandCompanyData, setBrandCompanyData] = useState(null);
  const [storeData, setStoreData] = useState(null);
  const [itemType, setItemType] = useState(baseitemType);
  const [loader, setLoader] = useState(true);
  const [productModels, setProductModels] = useState(null);
  const [spaceModels, setSpaceModels] = useState(null);
  const [filtersDrawer, setFiltersDrawer] = useState(false);
  const [productCategoriesData, setProductCategoriesData] = useState([])
  const [productParentCateogries, setProductParentCateogries] = useState({})
  const [productCategoryValue, setProductCategoryValue] = useState([]);
  const [productCategoryValueWithChilds, setProductCategoryValueWithChilds] = useState([]);
  const [categorySearchValue, setCategorySearchValue] = useState([]);
  const [companyFilter, setCompanyFilter] = useState([]);
  const [productModelType, setProductModelType] = useState([]);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [spaceCategoryValue, setSpaceCategoryValue] = useState([]);
  const [spaceCategories, setSpaceCategories] = useState([]);
  const [pageLoad, setPageLoad] = useState(true);
  const [requestStatusProduct, setRequestStatusProduct] = useState(null);
  const [cardStatusProduct, setCardStatusProduct] = useState(null);
  const [requestStatusRoom, setRequestStatusRoom] = useState(null);
  const [requestStatusScene, setRequestStatusScene] = useState(null);
  const [cardStatusRoom, setCardStatusRoom] = useState(null);
  const [showSuccesProduct, setShowSuccessProduct] = useState(false);
  const [showSuccessRoom, setShowSuccessRoom] = useState(false);
  const [roomCardFailure, setRoomCardFailure] = useState(false);
  const [productCardFailure, setProductCardFailure] = useState('');
  const [requestType, setRequestType] = useState('');
  const [productCardSaveDrawer, setProductCardSaveDrawer] = useState(false);
  const [roomCardSaveDrawer, setRoomCardSaveDrawer] = useState(false);
  const [paymentRequestID, setPaymentRequestID] = useState(-1);
  const [roomPrices, setRoomPrices] = useState([]);
  const [productPrices, setProductPrices] = useState([]);
  const [entityName, setEntityName] = useState('');
  const [entityRoomPrice, setEntityRoomPrice] = useState(-1);
  const [entityProductPrice, setEntityProductPrice] = useState(-1);
  const [entityScenePrice, setEntityScenePrice] = useState(-1);
  const [paymentConfirmationModal, setPaymentConfirmationModal] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [cardSaveDrawer, setCardSaveDrawer] = useState(false);
  const [libraryPayload, setLibraryPayload] = useState(null);
  const [addingFreeItem, setAddingFreeItem] = useState(false)
  const [successPopup, setSuccessPopup] = useState(false);
  const [cardSaveFailure, setCardSaveFailure] = useState(false);
  const [selectedPriceCategory, setSelectedPriceCategory] = useState('');
  const [disablePopup, setDisablePopup] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [subscriptionModal, setSubscriptionModal] = useState(false)
  const [currentPackage, setCurrentPackage] = useState('');
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState('');
  const [confirmRequest, setConfirmRequest] = useState(false);
  const [isSubscriptionUpdateRequest, setIsSubscriptionUpdateRequest] = useState(false);
  const [subsriptionTypeProduct, setSubscriptionTypeProduct] = useState('');
  const [subsriptionTypeRoom, setSubscriptionTypeRoom] = useState('');
  const [purchaseTypeProduct, setPurchaseTypeProduct] = useState('');
  const [purchaseTypeRoom, setPurchaseTypeRoom] = useState('');
  const [usedCountProducts, setUsedCountProducts] = useState(0);
  const [availableCountProducts, setAvailableCountProducts] = useState(0);
  const [usedCountRooms, setUsedCountRooms] = useState(0);
  const [availableCountRooms, setAvailableCountRooms] = useState(0);
  const [maxPriceProduct, setMaxPriceProduct] = useState(0);
  const [maxPriceScene, setMaxPriceScene] = useState(0);
  const [maxPriceSpace, setMaxPriceSpace] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [minPrice, setMinPrice] = useState(0);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [paymentFailureMessage, setPaymentFailureMessage] = useState('');
  const [paymentFailureModal, setPaymentFailureModal] = useState(false);
  const [cardSaveFlowTriggered, setCardSaveFlowTriggered] = useState(false);
  const [isALL3DEntity, setIsALL3DEntity] = useState(true);
  const [productsAddedToLibrary, setProductsAddedToLibrary] = useState([]);
  const [spacesAddedToLibrary, setSpacesAddedToLibrary] = useState([]);
  const [featuredBrands, setFeaturedBrands] = useState([]);
  const [selectedDisplay, setSelectedDisplay] = useState(selectedDisplayType == 'brands' ? selectedDisplayType : baseitemType);
  const [brandsSelected, setBrandsSelected] = useState(false);
  const [itemCount, setItemCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(33);
  const [initialLoadProducts, setInitialLoadProducts] = useState(false);
  const [initialLoadSpaces, setInitialLoadSpaces] = useState(false);
	const [carousel, setCarousel] = useState(null);
  const [productLoad, setProductLoad] = useState(true);
  const [showBrandsSpaces, setShowBrandsSpaces] = useState(false);
  const [showBrandsProducts, setShowBrandsProducts] = useState(false);
  const [logoWidth, setLogoWidth] = useState(0);
  const [inAppLimitExceededModal, setInAppLimitExceededModal] = useState(false);
  const [comingSoonCompanies, setComingSoonCompanies] = useState([]);
  const [loadingInitialProducts, setLoadInitialProducts] = useState(true);
  const [loadingInitialSpaces, setLoadInitialSpaces] = useState(true);
  const [templateLoader, setTemplateLoader] = useState(false);
  const [initialLoadScenes, setInitialLoadScenes] = useState(false);
  const [templateScenes, setTemplateScenes] = useState([]);
  const [sceneNameModal, setSceneNameModal] = useState(false);
  const [sceneName, setSceneName] = useState('');
  const [sceneDetails, setSceneDetails] = useState(null);
  const [purchaseTypeTemplate, setPurchaseTypeTemplate] = useState(null);
  const [loadingInitialScenes, setLoadInitialScenes] = useState(true);
  const [usedCountTemplates, setUsedCountTemplates] = useState(null);
  const [subscriptionTypeTemplates, setSubscriptionTypeTemplates] = useState(null);
  const [requetStatusTemplate, setRequestStatusTemplate] = useState(null);
  const [availableCountTemplates, setAvailableCountTemplates] = useState(null);
  const [cardStatusTemplate, setCardStatusTemplate] = useState(null);
  const [templatesAddedToLibrary, setTemplatesAddedToLibrary] = useState([]);
  const [relatedScenes, setRelatedScenes] = useState([]);
  const [checkingIfScenesExist, setCheckingIfScenesExist] = useState(false);
  const [styleCategoryValue, setStyleCategoryValue] = useState([]);
  const [templateProductCategories, setTemplateProductCategories] = useState([]);
  const [selectedProductCategory, setSelectedProductCategory] = useState([]);
  const [productStyleCategory, setProductStyleCategory] = useState([])
  const [colorValue, setColorValue] = useState([]);
  const [productMaterialsData, setProductMaterialsData] = useState([]);
  const [productMaterials, setProductMaterials] = useState([]);
  const [materialsCategoryValueWithChilds, setMaterialsCategoryValueWithChilds] = useState([]);
  const [appliedFiltersList, setAppliedFiltersList] = useState([])
  const [selectedItemType, setSelectedItemType] = useState(null)
  const [selectedItemId, setSelectedItemId] = useState(null)
  const [projectModalVisible, setProjectModalVisible] = useState(false)
  const [projects, setProjects] = useState([]);
  const [showPaymentRequiredBeforeAddToProjectPopup, setShowPaymentRequiredBeforeAddToProjectPopup] = useState(false)
  const { match: { params } } = props;


  useEffect(() => {
    setModelsData();
  },[itemType,productModels,spaceModels, successPopup, templateScenes]);

  useEffect(() =>  {
    if (!filtersApplied) {
      if (itemType === 'products') {
        setStoreData(productModels);
      }
    }
  }, [filtersApplied])

  useEffect(() => {
    if (initialLoadProducts) {
      populateProducts();
    }
    if (initialLoadSpaces) {
      populateSpaces();
    }
    if (initialLoadScenes) {
      populateScenes();
    }
  },[initialLoadProducts,initialLoadSpaces, initialLoadScenes]);

  useEffect(() => {
    if (itemType == 'products') {
      setMaxPrice(maxPriceProduct);
    } else if (itemType == 'spaces') {
      setMaxPrice(maxPriceSpace);
    } else if (itemType == 'scenes') {
      setMaxPrice(maxPriceScene)
    }

  },[maxPriceProduct,maxPriceSpace,maxPriceScene,itemType])

  useEffect(() => {
    let payload = {
        order_by: "last_modified_stamp",
        required_fields: [
            "id",
            "name",
            "customer_username",
            "last_modified_stamp"
        ],
        filter_string: `(status__notexact='approved')&&(username__exact='${CUSTOMER_USERNAME}')&&(access_level__in=['owner','co_owner','editor'])`,
      };

    axios.post(ENVIRONMENT.GET_PROJECT_BATCH, payload).then((res) => 
    {
        setProjects(res.data)
    });
}, [])

  function checkPaymentRequired(price, brand, itemType){
    let payment_required = false
    if(price == '' || price == 0){
      payment_required = false
    }
    else{
      if(brand == FileConstants.ALL3D_COMPANY){
        //  All3d company, ask for payment if payment_authorizer does not allows it
        if((itemType == 'products' && (requestStatusProduct == 'update_subscription' || requestStatusProduct == 'payment_required')) ||
          (itemType == 'spaces' && (requestStatusRoom == 'update_subscription' || requestStatusRoom == 'payment_required'))){
          payment_required = true
        }
      }
      else{
        // Not all3d item and price is not 0, ask for payment
        payment_required = true
      }
    }

    return payment_required
  }

  let get_add_options = (onAddSceneClick, addToLibrary, id, name, price, brand, itemType) => {
    return <Menu>
    {itemType == 'products' && <Menu.Item>
      <a onClick={() => onAddSceneClick(id, price)}>Add to Scene</a>
    </Menu.Item>}
    <Menu.Item>
    < a onClick={() => addToLibrary(id,name,price,brand)}>Add to My Library</a>
    </Menu.Item>
    {itemType == 'products' && itemType == 'spaces'}{
      <Menu.Item>
        < a onClick={() => {
          if(checkPaymentRequired(price, brand, itemType)){
            setShowPaymentRequiredBeforeAddToProjectPopup(true)
          }
          else{
            setSelectedItemId(id);
            if(itemType == 'products'){
              setSelectedItemType('Product')
            }
            else if (itemType == 'spaces'){
              setSelectedItemType('Room')
            }
            setProjectModalVisible(true)
          }
        } }>Add to Project</a>
      </Menu.Item>
    }
  </Menu>
  };

  const setModelsData = () => {
    if (itemType == 'products') {
      if (storeState) {//if models state is selected
       productDataLoad();
      } else {//if explore brands is clicked
        brandProductDataLoad();
      }
    } else if (itemType == 'spaces') {
      if (storeState) {//if models state is selected
        spaceDataLoad();
      } else {//if explore brands is clicked
       brandSpaceDataLoad();
      }
    } else if (itemType == 'scenes') {
      if (storeState) {//if models state is selected
        sceneDataLoad();
      } else {//if explore brands is clicked
        brandSceneDataLoad();
      }
    }
  }

  const loadFeaturedBrandsData = () => {
    let product_domains = extractCompanies('products');
    let space_domains = extractCompanies('spaces');
    let brands = product_domains.concat(space_domains);
    var brands_union = brands.filter((item, pos) => brands.indexOf(item) === pos)

    //Doing this would extract count for products and spaces
    //for all brands on store even when theyre not published on store yet
    let count = {}
    let product_brands_count = setForBrand(productModels);
    for (const [key, value] of Object.entries(product_brands_count)) {
      count[key] = value.length;
    }

    let space_brands_count = setForBrand(spaceModels);
    for (const [key, value] of Object.entries(space_brands_count)) {
      count[key] = count[key] + value.length;
    }

    if (templateScenes && templateScenes.length > 0) {
      let scene_brands_count = setForBrand(templateScenes);
      for (const [key, value] of Object.entries(scene_brands_count)) {
        count[key] = count[key] + value.length;
      }
    }

    //populate with featured brands -
    //this would populate those brands which dont have any product or space on store yet
    //but they are published
    let coming_soon_companies = []
    let featured_companies = []
    if (companyData.length > 0) {
      companyData.map((company,index) => {
        featured_companies.push(company.company_domain);
        if (!count[company.company_domain]) {
          if ((!loadingInitialProducts && itemType == 'products')
          || (!loadingInitialSpaces && itemType == 'spaces')
          || (!loadingInitialScenes && itemType == 'scenes')) {
            coming_soon_companies.push(company.company_domain)
          }
          count[company.company_domain] = 0;
        }
      });
    }
    setComingSoonCompanies(coming_soon_companies);

    brands_union = featured_companies.filter((item, pos) => featured_companies.indexOf(item) === pos)

    setItemCount(count);
    setFeaturedBrands(brands_union);
    console.log('Brands Union', brands_union, count)
  }


  useEffect(() => {
    if (spaceModels && spaceModels.length > 0 && productModels && productModels.length > 0 && companyData && companyData.length > 0) {
      loadFeaturedBrandsData();
      forceUpdate();

    }
  },[spaceModels,productModels, companyData, loadingInitialProducts, loadingInitialSpaces, templateScenes])

  const productDataLoad = () => {
    //load data for product models by defaut and reset filters
    setStoreData(productModels);
    let product = setForBrand(productModels);
    setBrandCompanyData(product);
    setSpaceCategoryValue([]);
    setStyleCategoryValue([]);
  }

  const sceneDataLoad = () => {
    //load data for space model by default and reset filters
    setStoreData(templateScenes);
    let scene = setForBrand(templateScenes);
    setBrandCompanyData(scene);
    setProductModelType([]);
  }

  const spaceDataLoad = () => {
    //load data for space model by default and reset filters
    setStoreData(spaceModels);
    let space = setForBrand(spaceModels);
    setBrandCompanyData(space);
    setProductCategoryValue([]);
    setProductCategoryValueWithChilds([]);
    setProductModelType([]);
    setStyleCategoryValue([]);
  }

  const brandProductDataLoad = () => {
    //load data for product models
    let product = setForBrand(productModels);
    setBrandCompanyData(product);
    setSpaceCategoryValue([]);
    setStyleCategoryValue([]);
    if (selectedCompany) {
      setSelectedCompany(selectedCompany);
      if (product && product[selectedCompany.company_domain]) {
        setStoreData(product[selectedCompany.company_domain]);
      } else {
        setStoreData([]);
      }
    }
  }

  const brandSpaceDataLoad = () => {
    //load data for space models
    let space = setForBrand(spaceModels);
    setBrandCompanyData(space);
    setProductCategoryValue([]);
    setProductCategoryValueWithChilds([]);
    setProductModelType([]);
    setStyleCategoryValue([]);
    if (selectedCompany) {
      setSelectedCompany(selectedCompany);
      if (space && space[selectedCompany.company_domain]) {
        setStoreData(space[selectedCompany.company_domain]);
      } else {
        setStoreData([]);
      }
    }
  }

  const brandSceneDataLoad = () => {
    //load data for scene models
    let scene = setForBrand(templateScenes);
    setBrandCompanyData(scene);
    setProductModelType([]);
    if (selectedCompany) {
      setSelectedCompany(selectedCompany);
      if (scene && scene[selectedCompany.company_domain]) {
        setStoreData(scene[selectedCompany.company_domain]);
      } else {
        setStoreData([]);
      }
    }
  }

  useEffect(() => {
    let brand_name = props.match.params.brand;
    if (brand_name && pageLoad) {
      setBrandsSelected(true);
      let baseitemType = new URL(window.location.href).searchParams.get("item_type");
      let selectedDisplayType = new URL(window.location.href).searchParams.get("item_type");

      if (baseitemType == undefined || !(['products','spaces','scenes'].includes(baseitemType))) {
        baseitemType = 'products'
      }
      setSelectedDisplay(selectedDisplayType == 'brands' ? selectedDisplayType : baseitemType);
      setItemType(baseitemType);
      if ((brandCompanyData && Object.keys(brandCompanyData).length > 0) && (companyData && companyData.length > 0)) {
        let selected_company = companyData && companyData.filter((company) => {
          return company.company_domain.includes(brand_name) == true;
        });
        setStoreState(!storeState);
        setSelectedCompany(selected_company[0]);

        //calculate new width of the logo according to their aspect ratio
        let aspect_ratio = 0;
        const img = new Image();
        img.onload = function() {
            aspect_ratio = 200 / this.height;
            let new_width = aspect_ratio * this.height;
            new_width = new_width/10;
            setLogoWidth(new_width);
        }
        img.src = ENVIRONMENT.BASE_URL + selected_company[0].logo.uid + '/' + selected_company[0].logo.name;

        if (brandCompanyData[selected_company[0].company_domain]) {
          setStoreData(brandCompanyData[selected_company[0].company_domain]);
        } else {
          setStoreData([]);
        }
        setPageLoad(false);
        forceUpdate();
      }
    } else if (pageLoad) {
      let selectedDisplayType = new URL(window.location.href).searchParams.get("item_type");

      if (selectedDisplayType && selectedDisplayType == 'brands' && storeState) {

        resetEverything();
        changeStoreState();
        setPageLoad(false);

      }


    }
  },[brandCompanyData,companyData])


  const changeSearchValue = (value) => {
    setSearchValue(value);
    setSearchKeywords(value.toLowerCase().split(" "));
  }

  const onAddSceneClick = (id, price) => {
    if(price == '' || price == 0){
        window.location.href = `/add-product-to-existing-scene?product_id=${id}`
    }
    else{
        window.location.href = `/add-product-to-existing-scene?product_id=${id}&storeItem=true`
    }
  }

  const onAddCollectionClick = (id) => {
    if (itemType == 'products') {

      for(let i = 0 ; i< storeData.length; i++){
        if ( storeData[i].id == id  ){
          setEntityProductPrice(storeData[i].price);
          //Changing request status if the brand is All3D, so that payment is required
          setIsALL3DEntity(storeData[i].company_display_name == FileConstants.ALL3D_COMPANY);
          if(storeData[i].company_display_name != FileConstants.ALL3D_COMPANY && storeData[i].price != 0){
            setRequestStatusProduct('payment_required')
            setSelectedProductIdForCollection(id);
            setSubscriptionModal(true)
          }
          else if(requestStatusProduct == 'update_subscription' && storeData[i].price != 0){
            // update subscription plan modal here
            setSelectedProductIdForCollection(id);
            setSubscriptionModal(true)
          }
          else{
            setSelectedProductIdForCollection(id);
            setCollectionModalVisible(true)
          }
          break
        }
      }
    } else {

      for(let i = 0 ; i< storeData.length; i++){
        if ( storeData[i].room_id == id  ){
          setEntityRoomPrice(storeData[i].price);
          //Changing request status if the brand is All3D, so that payment is required
          setIsALL3DEntity(storeData[i].company_display_name == FileConstants.ALL3D_COMPANY);
          if(storeData[i].company_display_name != FileConstants.ALL3D_COMPANY  && storeData[i].price != 0){
            setRequestStatusRoom('payment_required');
            onAddRoomCollectionClickForRoom(id);
          }
          else if(requestStatusRoom == 'update_subscription'  && storeData[i].price != 0){
            // update subscription plan modal here
            setSubscriptionModal(true);
            // onAddRoomCollectionClickForRoom(id);
          }
          else{
            onAddRoomCollectionClickForRoom(id);
          }
          break
        }
      }
    }
  }

  const onAddRoomCollectionClickForRoom = (id) => {
    setSelectedRoomIdForCollection(id);
    setRoomCollectionModalVisible(true);
  }

  const changeSceneName = (e) => {
    setSceneName(e.target.value);
  }

  const cancelSceneNameModal = () => {
    setSceneNameModal(false);
  }


  const addToLibrary = (id,name,price,brand) => {
   if (itemType == 'products') {
    addProductToLibrary(id,name,price,brand)
   } else if (itemType == 'spaces') {
    addToLibraryRoom(id,name,price,brand);
   } else {
    let details = {
      name: name,
      id: id,
      price: price,
      brand: brand
    }
    setSceneDetails(details);
    setSceneName(details.name);
    setSceneNameModal(true);
    getSceneRelatedDetail(id);

   }
  }

  const getSceneRelatedDetail = (id) => {
    setCheckingIfScenesExist(true);
    let payload = {
      action: 'check',
      scene_template_id: id,
      customer_username: CUSTOMER_USERNAME
    }
    if (COMPANY_ID) {
      payload.company_id = COMPANY_ID
    }

    axios.post(ENVIRONMENT.TEMPLATE_ACTIONS, payload)
    .then(res => {
      setRelatedScenes(res.data);
      setCheckingIfScenesExist(false);
    })
    .catch((error) => {
    });

  }

  const AddFreeItem = () => {
    setButtonLoader(true)
    let payload = libraryPayload.request_payload;
    let id = ''
    if(itemType === 'products'){
      id = payload.product_id
    }
    else if (itemType == 'spaces') {
      id = payload.room_id
    } else {
      id = payload.id
      payload.action = 'add_to_library_free'
    }
    setAddingFreeItem(false)
    axios.post(ENVIRONMENT.ADD_FREE_ITEMS_TO_LIBRARY,payload).
    then((res) => {
      setPaymentConfirmationModal(false);
      setConfirmationModal(false)
      setSuccessPopup(true);
      if(itemType === 'products'){
        let product_added = productsAddedToLibrary;
        product_added.push(id);
        setProductsAddedToLibrary(product_added)
      }
      else if (itemType == 'spaces') {
        let space_added = spacesAddedToLibrary;
        space_added.push(id);
        setSpacesAddedToLibrary(space_added)
      } else {
        let templates_added = templatesAddedToLibrary;
        templates_added.push(id);
        setTemplatesAddedToLibrary(templates_added);
      }
      setButtonLoader(false)
    })
  }

  const addProductToLibrary = (id,name,price,brand) => {
    setIsALL3DEntity(true);
    setEntityName(name);
    let payload = {
      product_id: id,
      username: localStorage.getItem('username')
    }
    let gateway_payload = {
      "username": localStorage.getItem('username'),
      "request_type": "product_prop",
      "action": "perform",
      "request_payload": payload,
      "category": selectedPriceCategory,
      "request_id": Date.now().toString()
    }
    setRequestType("product_prop");
    if (price == 0 ){
      setLibraryPayload(gateway_payload);
      setAddingFreeItem(true)
      setConfirmationModal(true)
    }
    else{
      setEntityProductPrice(price);
      if(brand == FileConstants.ALL3D_COMPANY){
        if(requestStatusProduct == 'update_subscription'){
          // update subscription plan modal here
          setLibraryPayload(gateway_payload);
          setSubscriptionModal(true)
        }
        if (requestStatusProduct == "allowed" || requestStatusProduct == "always_allowed") {
          setConfirmationModal(true);
          setLibraryPayload(gateway_payload);
        }
        if (requestStatusProduct == "payment_required" ) {
          setPaymentConfirmationModal(true);
          setLibraryPayload(gateway_payload);
        }
      }
      else{
        setIsALL3DEntity(false);
        setRequestStatusProduct("payment_required")
        setPaymentConfirmationModal(true);
        setLibraryPayload(gateway_payload);
      }
    }

  }

  const updatePaymentDetailsOnFailure = () => {

    window.addEventListener('card_saved', (e) => {
      let action = e.detail.action;
      setCardSaveFlowTriggered(false);
      if (action == 'save_card') {
        message.success('Payment Method Updated Successfully.')
      }
    }, false);

    setPaymentFailureModal(false);
    let child_window = window.open('/save-card?action=save_card', '', "width=600,height=600");
    let refreshInterval = setInterval(() => {
      if(child_window.closed) {
        setCardSaveFlowTriggered(false);
        clearInterval(refreshInterval);
      }
    }, 500);
    setCardSaveFlowTriggered(true);
  }

  const addToLibraryScene = () => {
    let id = sceneDetails.id;
    let price = sceneDetails.price;
    let brand = sceneDetails.brand;
    let name = sceneName;
    setSceneNameModal(false);
    setEntityName(name);
    setIsALL3DEntity(true);
    let payload = {
      action: 'add_to_library',
      id: id,
      name: name,
      requested_by: localStorage.getItem('username')
    }
    if (COMPANY_ID) {
      payload.company_id = COMPANY_ID;
    }
    let gateway_payload = {
      "username": localStorage.getItem('username'),
      "request_type": "scene_template_prop",
      "action": "perform",
      "request_payload": payload,
      "category": selectedPriceCategory,
      "request_id": Date.now().toString()
    }
    setEntityScenePrice(price);
    setRequestType('scene_template_prop');
    if(price == 0 ){
      setLibraryPayload(gateway_payload);
      setAddingFreeItem(true)
      setConfirmationModal(true)
    }
    else{
      setEntityScenePrice(price);
      if(brand == FileConstants.ALL3D_COMPANY){
        if(requestStatusScene == 'update_subscription'){
          // update subscription plan modal here
          setSubscriptionModal(true);
          setLibraryPayload(gateway_payload);
        }
        if (requestStatusScene == "allowed" || requestStatusScene == "always_allowed" || (relatedScenes && relatedScenes.length > 0)) {
          setConfirmationModal(true);
          setLibraryPayload(gateway_payload);
        }
        if (requestStatusScene == "payment_required") {
          setPaymentConfirmationModal(true);
          setLibraryPayload(gateway_payload);
        }
      }
      else {
          setIsALL3DEntity(false);
          setRequestStatusScene('payment_required')
          setPaymentConfirmationModal(true);
          setLibraryPayload(gateway_payload);
        }
      }
  }

  const addToLibraryRoom = (id,name,price,brand) => {
    setEntityName(name);
    setIsALL3DEntity(true);
    let payload = {
      room_id: id,
      username: localStorage.getItem('username')
    }
    let gateway_payload = {
      "username": localStorage.getItem('username'),
      "request_type": "room_prop",
      "action": "perform",
      "request_payload": payload,
      "category": selectedPriceCategory,
      "request_id": Date.now().toString()
    }
    setRequestType("room_prop");
    if(price == 0 ){
      setLibraryPayload(gateway_payload);
      setAddingFreeItem(true)
      setConfirmationModal(true)
    }
    else{
      setEntityRoomPrice(price);
      if(brand == FileConstants.ALL3D_COMPANY){
        if(requestStatusRoom == 'update_subscription'){
          // update subscription plan modal here
          setSubscriptionModal(true);
          setLibraryPayload(gateway_payload);
        }
        if (requestStatusRoom == "allowed" || requestStatusRoom == "always_allowed") {
          setConfirmationModal(true);
          setLibraryPayload(gateway_payload);
        }
        if (requestStatusRoom == "payment_required") {
          setPaymentConfirmationModal(true);
          setLibraryPayload(gateway_payload);
        }
      }
      else {
          setIsALL3DEntity(false);
          setRequestStatusRoom('payment_required')
          setPaymentConfirmationModal(true);
          setLibraryPayload(gateway_payload);
        }
      }

  }


  const openCompanyItems = (company) => {
    let company_name = company.company_domain.split('.')[0];
    window.location.href = '/store/' + company_name;

    //might use for later
    // window.location.href = '/store?brand_name=' + company.company_domain+ '&item_type='+itemType;
    //open selected company's data
    // setSelectedCompany(company);
    // setStoreData(brandCompanyData[company.company_domain]);
  }

   //for switch
   const changeItemType = (e, popStateAction = false) => {
     resetEverything();
     setCurrentPage(1);
    if (selectedDisplay == 'brands') {
      goBack(e.target.value);
    }
    setSelectedDisplay(e.target.value);
    if (e.target.value == 'brands') {
      changeStoreState();
    } else {
      //item type is products or spaces or scenes

      //might be used later
      // if (e.target.value == 'products') {
      //   setMaxPrice(maxPriceProduct);
      // } else if (e.target.value == 'spaces') {
      //   setMaxPrice(maxPriceSpace);
      // } else if (e.target.value == 'scenes') {
      //   setMaxPrice(maxPriceScene);
      // }
      setItemType(e.target.value);
    }
    if (popStateAction == false)
    {
      const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?item_type=' + e.target.value;
      window.history.pushState({path: newurl},'', newurl);
    }
  }


  const goBack = (type = 'brands') => {
    //go back to the main page and reset everything
    setBrandsSelected(false);
    setSelectedDisplay('brands');
    if (selectedCompany != null) {
      setSelectedCompany(null);
    } else {
      if (type != 'brands') {
        setStoreState(!storeState);
        setSelectedCompany(null);
        if (itemType == 'products') {
          setSelectedDisplay('products');
          productDataLoad();
        } else if (itemType == 'spaces') {
          setSelectedDisplay('spaces');
          spaceDataLoad();
        } else if (itemType == 'scenes') {
          setSelectedDisplay('scenes');
          sceneDataLoad();
        }
      }
    }
  }

  useEffect(() => {
    //might need it for later
    // window.addEventListener("popstate", (event) => {
    //   let baseitemType = new URL(window.location.href).searchParams.get("item_type");
    //   let selectedDisplayType = new URL(window.location.href).searchParams.get("item_type");
    //   if (baseitemType == undefined || !(['products','spaces','scenes'].includes(baseitemType))) {
    //     baseitemType = itemType;
    //   }
    //   let e = {
    //     target: {
    //       value: selectedDisplayType == 'brands' ? selectedDisplayType : baseitemType
    //     }
    //   };
    //  changeItemType(e, true);
    // }, false);

    let brand_name = props.match.params.brand;
    getURLParameters();
    if (!brand_name) {
      populateProducts(true);
      populateSpaces(true);
      populateScenes(true);
      populateBrandData();
    } else {
      populateBrandData();
    }
    setProductCategoryData();
    setSpaceCategoriesData();
    getTemplateProductsCategories();
    loadProductMaterialsData();

  }, []);

  const getURLParameters = () => {
      let request_type = new URLSearchParams(window.location.search).get("request_type");
      let payment_redirected = new URLSearchParams(window.location.search).get("payment_redirected");
      let payment_failure = new URLSearchParams(window.location.search).get("payment_failure");
      let payment_request_id = new URLSearchParams(window.location.search).get("request_id");
      setRequestType(request_type)
      if (payment_redirected) {
        if (request_type == 'collection_product') {
          // setShowSuccessProduct(true);
        }
        if (request_type == 'collection_room') {
          // setShowSuccessRoom(true);
        }
      }
      if (payment_failure) {
        if (request_type == 'collection_product') {
          // setProductCardFailure(true);
          setProductCardSaveDrawer(true);
        }
        if (request_type == 'collection_room') {
          // setRoomCardFailure(true);
          setRoomCardSaveDrawer(true);
        }
        if (payment_request_id) {
            setPaymentRequestID(payment_request_id);
        }
    }
    loadUserRequestStatusProduct();
    loadUserRequestStatusRooms();
    loadUserRequestStatusScenes();
  }

  const populateProducts = (initial=false, selected_company = '') => {
    let payload = {
      username: localStorage.getItem('username'),
      item_type: 'product',
    }
    if (initial) {
      setProductLoad(true);
      payload.limit = '';
      if (selected_company != '') {
        payload.company_id = selected_company.company_id
      }

      setInitialLoadProducts(true);
    }
    axios.post(ENVIRONMENT.STORE_LIST, payload)
      .then( (response) => {
        setProductLoad(false);
        let products = Utilities.getProductsDataWithKeywords(response.data, 'store')
        let max_price = products.reduce((prevValue, currentValue) => prevValue = prevValue > currentValue.price ? prevValue : currentValue.price, 0);
        setMaxPriceProduct(max_price);
        setProductModels(products);
        if (!initial) {
          setShowBrandsProducts(true);
          setLoadInitialProducts(false);
        }
      })
      .catch((error) => {
      });
  }

  const populateScenes = (initial=false) => {
    let payload = {
      action: 'get_batch',
      status: '3'
    }
    if (initial) {
      setTemplateLoader(true);
      payload.limit = '100';
      setInitialLoadScenes(true);
    }
    axios.post(ENVIRONMENT.TEMPLATE_ACTIONS, payload)
    .then(res => {
      setTemplateLoader(false)
      let scenes = res.data.map((obj) => {
        let keywords = []
        for(let keyword of obj.name.replace(/[^a-zA-Z0-9 ]/g, " ").split(" ")) {
          if (!keywords.includes(keyword)) {
            if (keyword.length > 1) {
              keywords.push(keyword.toLowerCase())
            }
          }
        }

        for(let keyword of obj.room_name.replace(/[^a-zA-Z0-9 ]/g, " ").split(" ")) {
          if (!keywords.includes(keyword)) {
            if (keyword.length > 1) {
              keywords.push(keyword.toLowerCase())
            }
          }
        }
        return { ...obj, 'keywords': keywords };
      });
      let max_price = scenes.reduce((prevValue, currentValue) => prevValue = prevValue > currentValue.price ? prevValue : currentValue.price, 0);
      setMaxPriceScene(max_price)
      setTemplateScenes(scenes);
      if (!initial) {
        setLoadInitialScenes(false);
      }
    })
    .catch((error) => {
    });
  }

  // Get categories of products that are present in template scenes
  const getTemplateProductsCategories = () => {
    let payload = {"action": "filter_by_products" }
    axios.post(ENVIRONMENT.TEMPLATE_ACTIONS, payload)
    .then( res => {
      setTemplateProductCategories(res.data);
    })
  }


  const loadUserRequestStatusProduct = (confirm_request=null) => {
    let check_payload = {
      "username": localStorage.getItem('username'),
      "action": "check",
      'request_type': 'product_prop'
    }
    axios.post(ENVIRONMENT.PAYMENT_GATEWAY, check_payload)
    .then(res => {
      setPurchaseTypeProduct(res.data['purchase_type']);
      setUsedCountProducts(res.data['usage_count']);
      setAvailableCountProducts(res.data['available_limit']);
      setSubscriptionTypeProduct(res.data['subscription_plan']);
      setRequestStatusProduct(res.data['request_allowed']);
      setCardStatusProduct(res.data['card_status']);
      setCurrentPackage(res.data['subscription_plan']);
      setIsSubscriptionActive(res.data['is_subscription_active']);
      setEntityProductPrice(res.data['price'])
      if (requestType == 'product_prop' && confirm_request != null) {
        setConfirmRequest(true);
      }

    })
  }

  const populateSpaces = (initial = false, selected_company = '') => {

    let payload = {
      username: localStorage.getItem('username'),
      item_type: 'room',
    }
    if (initial) {
      payload.limit = '';
      if (selected_company != '') {
        payload.company_id = selected_company.company_id
      }
      setLoader(true);
      setInitialLoadSpaces(true);
    }
    axios.post(ENVIRONMENT.STORE_LIST, payload)
      .then( (response) => {
        let rooms = Utilities.getRoomsDataWithKeywords(response.data)
        let max_price = rooms.reduce((prevValue, currentValue) => prevValue = prevValue > currentValue.price ? prevValue : currentValue.price, 0);
        setMaxPriceSpace(max_price);
        setSpaceModels(rooms);
        setLoader(false);
        if (!initial) {
          setShowBrandsSpaces(true);
          setLoadInitialSpaces(false);
        }
      })
      .catch((error) => {
      });
  }

  const onChangePageSize = (page, pageSize) => {
    document.getElementById('layout-section').scrollTo({ top: 0, behavior: 'smooth' })
    setCurrentPage(page);
  }

  const getRequestPlan = (item_type) => {
    if (itemType == 'products')
      return subsriptionTypeProduct;
    else if (itemType == 'spaces')
      return subsriptionTypeRoom;
    else
      return subscriptionTypeTemplates;
  }

  const populateBrandData = () => {
    let payload = {}
    setBrandLoader(true);
    axios.post(ENVIRONMENT.COMPANY_GET_BATCH,payload)
    .then(res => {
      if (res && res.data) {
        let companies = [];
        res.data.map((company,index) => {
          if (company.featured_brand) {
            companies.push(company);
          }
        })
        setCompanyData(companies);
        setBrandLoader(false);
        let brand_name = props.match.params.brand;
        if (brand_name) {
          let selected_company = companies && companies.filter((company) => {
            return company.company_domain.includes(brand_name) == true;
          });
          setSelectedCompany(selected_company[0]);
          populateProducts(true, selected_company[0]);
          populateSpaces(true, selected_company[0]);
          populateScenes(true);
        }
      }
    });
  }

  const extractCompanies = (model_type) => {
    let models = model_type == 'products' ? productModels : spaceModels;
    let companies = []
    models.map((model,index) => {
      if (model.company_domain && !companies.includes(model.company_domain)) {
        companies.push(model.company_domain);
      }
    })
    return companies;
  }

  const setForBrand = (models) => {
    //group models according to the brands in dictionary format
    if (models && models.length > 0) {
      let groups = models.reduce((groups, entity) => {
        let company_domain = entity.company_domain;
        if (!groups[company_domain]) {
          groups[company_domain] = [];
        }
        groups[company_domain].push(entity);
        return groups;
      }, {});
      return groups;
    }
  }

  const resetEverything = () => {
    setSearchValue('');
    setSearchKeywords([]);
    setFiltersApplied(false);
    setAppliedFiltersList([])
    setCompanyFilter([]);
    setProductModelType([]);
    setSpaceCategoryValue([]);
    setStyleCategoryValue([]);
    setProductCategoryValue([]);
    setProductCategoryValueWithChilds([]);
    setCategorySearchValue([]);
    setProductStyleCategory([]);
    setColorValue([]);
    setProductMaterials([]);
    setMaterialsCategoryValueWithChilds([]);
  }

  const changeStoreState = () => {
    //when explore brands is clicked, load brand cards and reset everything else
    setStoreState(!storeState);
    resetEverything();
    if (storeState) {
      setSelectedCompany(null);
    }
  }

  const openFilters = () => {
    setFiltersDrawer(true);
  }

  const changeProductModelType = (value) => {
    //whether product model is ar or high_res
    setProductModelType(value);
    if (value.includes('ar') && value.includes('high_res')) {
      let model_types = value;
      model_types.push('high_res_and_ar');
      setProductModelType(model_types);
    }
  }

  const setProductCategoryData = () => {

    let payload = {
        output: 'tree'
    };
    axios.post(ENVIRONMENT.ADMIN_CATEGORY_GET_BATCH, payload)
    .then(res => {
      console.log(res.data)
      setProductCategoriesData(res.data);
    });

    payload = {
      output: 'serial'
    };
    axios.post(ENVIRONMENT.ADMIN_CATEGORY_GET_BATCH, payload)
    .then(res => {
      console.log(res.data)
      setProductParentCateogries(res.data);
    });
  }

  const loadProductMaterialsData = () => {
    let data = [];
        axios.post(ENVIRONMENT.ADMIN_MATERIAL_BATCH, {
        })
        .then(res => {
            let categoriesData = Utilities.getMaterialsData( res.data );
            for (let i = 1; i <= (res.data).length; i++) {
                if( res.data[i - 1].parent_id == "")
                {
                    data.push({
                        key: res.data[i - 1]['material_id'],
                        title: res.data[i - 1]['name'],
                        value: res.data[i - 1]['name'],
                        children: categoriesData[res.data[i - 1]['material_id']],
                    });
                }

            }
            setProductMaterialsData(data);
        });
  }
  
  const setSpaceCategoriesData = () => {
    let payload = {
      'request_type': 'room_model'
    }
    axios.post(ENVIRONMENT.GET_CATEGORY_AND_PRICE,payload)
    .then(res => {
      let categories = []
      if (res.data) {
        res.data.map((request) => {
          if (!categories.includes(request.category)) {
            categories.push(request.category);
          }
        })
        categories = categories.filter(item => item !== '')
        setSpaceCategories(categories);
      }
    })
  }

  useEffect(() => {
    //display tags when this is clicked
    if (productCategoryValue.length > 0
      || companyFilter.length > 0 || productModelType.length > 0 || spaceCategoryValue.length > 0
      || styleCategoryValue.length > 0
      || (minPrice != 0) || (itemType == 'products' && maxPrice != maxPriceProduct)
      || itemType == 'spaces' && maxPrice != maxPriceSpace
      || itemType == 'scenes' && maxPrice != maxPriceScene
      || (itemType == 'products' && productStyleCategory.length > 0)
      || (itemType == 'products' && colorValue.length > 0)
      || (itemType == 'products' && productMaterials.length > 0)) {
      setFiltersApplied(true);
      setAppliedFiltersList(getAppliedFilters())
      forceUpdate();
    } else {
      setFiltersApplied(false);
      setAppliedFiltersList([])
    }
  },[productCategoryValue,companyFilter,productModelType,spaceCategoryValue,minPrice, maxPrice, styleCategoryValue, productStyleCategory, colorValue, productMaterials])

  const getAppliedFilters = () => {
    let appliedFilters = [...productCategoryValueWithChilds, ...productStyleCategory, ...colorValue,
                          ...materialsCategoryValueWithChilds, ...productModelType]
    appliedFilters = appliedFilters.map(item => item.toLowerCase())
    return appliedFilters;
  }

  const closeFiltersDrawer = () => {
    setFiltersDrawer(false);
  }

  const onChangePrice = (value) => {
    setMinPrice(value[0]);
    setMaxPrice(value[1]);
  }

  const findItemsByParentId = (data, targetItemName, parentId) => {
    const items = [];
    for (const item of data) {
        if (item.parent_id === parentId) {
            items.push(item.name);
            items.push(...findItemsByParentId(data, targetItemName, item.category_id));
        }
    }
    return items;
  }

  const changeProductCategory = (value) => {
    let values = []

    value.map((name)=>{
      const targetItemName = name;
      const targetItem = productParentCateogries.find(item => item.name === targetItemName);

    if (targetItem) {
        const result = findItemsByParentId(productParentCateogries, targetItemName, targetItem.category_id);
        result.unshift(name);
        values = [...values,...result]
    }});

    if (value.length > 0
      || companyFilter.length > 0 || productModelType.length > 0 || spaceCategoryValue.length > 0
      || styleCategoryValue.length > 0
      || (minPrice != 0) || (itemType == 'products' && maxPrice != maxPriceProduct)
      || (itemType == 'spaces' && maxPrice != maxPriceSpace)
      || (itemType == 'scenes' && maxPrice != maxPriceScene)
      || (itemType == 'products' && productStyleCategory.length > 0)
      || (itemType == 'products' && colorValue.length > 0)
      || (itemType == 'products' && productMaterials.length > 0)) {
      setFiltersApplied(true);
    } else {
      setFiltersApplied(false);
    }
    
    console.log(value,values)
    setProductCategoryValue(value);
    setProductCategoryValueWithChilds(values);
  }

  const changeProductStyleCategory = (value) => {
    if (value.length > 0
      || companyFilter.length > 0 || productModelType.length > 0 || spaceCategoryValue.length > 0
      || styleCategoryValue.length > 0
      || (minPrice != 0) || (itemType == 'products' && maxPrice != maxPriceProduct)
      || (itemType == 'spaces' && maxPrice != maxPriceSpace)
      || (itemType == 'scenes' && maxPrice != maxPriceScene)
      || (itemType == 'products' && colorValue.length > 0)
      || (itemType == 'products' && productMaterials.length > 0)) {
      setFiltersApplied(true);
    } else {
      setFiltersApplied(false);
    }

    setProductStyleCategory(value);
  }

  const changeColorValue = (value) => {
    if (value.length > 0
      || companyFilter.length > 0 || productModelType.length > 0 || spaceCategoryValue.length > 0
      || styleCategoryValue.length > 0
      || (minPrice != 0) || (itemType == 'products' && maxPrice != maxPriceProduct)
      || (itemType == 'spaces' && maxPrice != maxPriceSpace)
      || (itemType == 'scenes' && maxPrice != maxPriceScene)
      || (itemType == 'products' && productStyleCategory.length > 0)
      || (itemType == 'products' && productMaterials.length > 0)) {
      setFiltersApplied(true);
    } else {
      setFiltersApplied(false);
    }

    setColorValue(value);
  }

  const findChildrenByParentTitle = (parentTitle, node) => {
    if (node.title === parentTitle) {
        return node.children.map(child => child.title);
    } else if (node.children) {
        let result = [];
        for (const childNode of node.children) {
            result = result.concat(findChildrenByParentTitle(parentTitle, childNode));
        }
        return result;
    } else {
        return [];
    }
  }

  const changeProductMaterial = (value) => {

    let values = []

    value.map((name)=>{
      const targetItemTitle = name;
      const targetItem = productMaterialsData.find(item => item.title === targetItemTitle);

    if (targetItem) {
        const result = findChildrenByParentTitle(targetItemTitle, targetItem);
        result.unshift(name);
        values = [...values,...result]
    }});

    if (value.length > 0
      || companyFilter.length > 0 || productModelType.length > 0 || spaceCategoryValue.length > 0
      || styleCategoryValue.length > 0
      || (minPrice != 0) || (itemType == 'products' && maxPrice != maxPriceProduct)
      || (itemType == 'spaces' && maxPrice != maxPriceSpace)
      || (itemType == 'scenes' && maxPrice != maxPriceScene)
      || (itemType == 'products' && productStyleCategory.length > 0)
      || (itemType == 'products' && colorValue.length > 0)) {
      setFiltersApplied(true);
    } else {
      setFiltersApplied(false);
    }

    if(values.length <= 0){
      values = value 
    }

    setProductMaterials(value);
    setMaterialsCategoryValueWithChilds(values);
    
  }

  const changeSpaceCategory = (value) => {
    setSpaceCategoryValue(value);
  }

  const changeStyleCategory = (value)=> {
    setStyleCategoryValue(value);
  }

  const changeCompany = (value) => {
    setCompanyFilter(value);
  }

  const changeProductSearchCategory = (value) => {
    setCategorySearchValue(value);
  }

  const returnSearchValue = (entity) => {
    //return value according to the filters applied
    let brand_name = entity.company_domain;
    if (storeState || selectedCompany != null) {
      if (itemType == 'products')
      {
       return getProductSearchValue(entity);
      } else if (itemType == 'spaces') {
        return getSpaceSearchValue(entity);
      } else if (itemType == 'scenes') {
        return getSceneSearchValue(entity);
      }
    } else {
      if (companyFilter.length > 0 && companyFilter.includes(brand_name)) {
        return true;
      }
    }
    return false;
  }

  const getProductSearchValue = (entity) => {
    let brand_name = entity.company_domain;
    let category = entity.category;
    let need_to_model = entity.need_to_model;
    let price = entity.price;
    let style_category = entity.style_category;
    let color = entity.color;
    let materials = []
    if (entity.materials && entity.materials != ""){
      materials = entity.materials.split(", "); 
    }
    let product_category = false;
    let company_filter = false;
    let product_model_type = false;
    let style_category_matched = false;
    let color_matched = false;
    let material_matched = false;
    let product_price = false;
    if (category != '') {
      if ((productCategoryValueWithChilds.length > 0 && productCategoryValueWithChilds.includes(category)) || productCategoryValueWithChilds.length == 0){
        product_category = true;
      }
    }
    if ((companyFilter.length > 0 && companyFilter.includes(brand_name)) || companyFilter.length == 0) {
      company_filter = true;
    }

    if ((productModelType.length > 0 && productModelType.includes(need_to_model)) || productModelType.length == 0) {
      product_model_type = true;
    }
    if (minPrice <= price && maxPrice >= price) {
      product_price = true;
    }

    if ((productStyleCategory.length > 0 && productStyleCategory.includes(style_category)) || productStyleCategory.length == 0){
      style_category_matched = true;
    }

    if ((colorValue.length > 0 && colorValue.includes(color)) || colorValue.length == 0){
      color_matched = true;
    }

    if ((materialsCategoryValueWithChilds.length > 0 && materialsCategoryValueWithChilds.some(element => materials.includes(element))) || materialsCategoryValueWithChilds.length == 0){
      material_matched = true;
    }

    if (product_category && company_filter && product_model_type && product_price && style_category_matched && material_matched && color_matched) {
      return true;
    }
  }

  const getSpaceSearchValue = (entity) => {  
    let brand_name = entity.company_domain;
    let category = entity.category;
    let price = entity.price;
    let space_category = false;
    let company_filter = false;
    let space_price = false;
    if ((spaceCategoryValue.length > 0 && spaceCategoryValue.includes(category)) || spaceCategoryValue.length == 0) {
      space_category = true;
    }
    if ((companyFilter.length > 0 && companyFilter.includes(brand_name)) || companyFilter.length == 0) {
      company_filter = true;
    }
    if (minPrice <= price && price <= maxPrice) {
      space_price = true;
    }

    if (space_category && company_filter && space_price) {
      return true;
    }
  }

  const getSceneSearchValue = (entity) => {
    let brand_name = entity.company_domain;
    let price = entity.price;
    let category = entity.room_name;
    let style = entity.style;
    let scene_product_category = entity.id;
    let space_category = false;
    let company_filter = false;
    let scene_price = false;
    let style_category = false;
    let template_category = false;
    if ((spaceCategoryValue.length > 0 && spaceCategoryValue.includes(category)) || spaceCategoryValue.length == 0) {
      space_category = true;
    }
    if ((styleCategoryValue.length > 0 && styleCategoryValue.includes(style)) || styleCategoryValue.length == 0) {
      style_category = true;
    }
    if ((companyFilter.length > 0 && companyFilter.includes(brand_name)) || companyFilter.length == 0) {
      company_filter = true;
    }
    if (minPrice <= price && price <= maxPrice) {
      scene_price = true;
    }
    if (productCategoryValueWithChilds.length > 0 && Object.keys(templateProductCategories).length > 0) {
      productCategoryValueWithChilds.map(product_category => {
        if (templateProductCategories[product_category] && templateProductCategories[product_category].includes(scene_product_category)) {
          template_category = true;
        }
      })
    } else if (productCategoryValueWithChilds.length == 0) {
      template_category = true;
    }

    if (space_category && company_filter && scene_price && style_category && template_category) {
      return true;
    }
  }


  const closeTags = (entity,type) => {
    //remove the tag when this is clicked from showing result section
    if (type == 'product_category') {
      let arr = productCategoryValue.filter(category => {return entity != category});
      changeProductCategory(arr)
    } else if (type == 'space_category') {
      let arr = spaceCategoryValue.filter(category => {return entity != category});
      setSpaceCategoryValue(arr);
    } else if (type == 'style_category') {
      let arr = styleCategoryValue.filter(category => {return entity != category});
      setStyleCategoryValue(arr);
    } else if (type == 'brand') {
      let arr = companyFilter.filter(brand => {return entity != brand});
      setCompanyFilter(arr);
    } else if (type == 'model_type') {
      let arr = productModelType.filter(model_type => {return entity != model_type});
      setProductModelType(arr);
    } else if (type == 'product_style_category') {
      let arr = productStyleCategory.filter(style_category => {return entity != style_category});
      setProductStyleCategory(arr);
    } else if (type == 'product_color') {
      let arr = colorValue.filter(color => {return entity != color});
      setColorValue(arr);
    } else if (type == 'product_material') {
      let arr = productMaterials.filter(material => {return entity != material});
      setProductMaterials(arr);
      setMaterialsCategoryValueWithChilds(arr);
    } else if (type == 'price') {
      setMinPrice(0);
      if (itemType == 'products') {
        setMaxPrice(maxPriceProduct);
      } else if (itemType == 'spaces') {
        setMaxPrice(maxPriceSpace);
      } else if (itemType == 'scenes') {
        setMaxPrice(maxPriceScene);
      }
    }
  }

  const returnCompanyDisplayName = (company_domain) => {
    //return company name when this is clicked
    if (companyData && companyData.length > 0) {
      let company = companyData.filter((company) => {
        return company.company_domain == company_domain;
      })
      if (company && company.length > 0) {
        return company[0].company_display_name;
      }
    }
  }

  const onMouseOut = (evt) => {
    let element = document.getElementById('filter');
    if (element) {
      element.setAttribute("src","/img/filter_blue.png");
      element.setAttribute("height","20px");
      element.setAttribute("width","20px");

    }
  }

  const onMouseOver = (evt) => {
    let element = document.getElementById('filter')
    if (element) {
      element.setAttribute("src","/img/filter.png");
      element.setAttribute("height","20px");
      element.setAttribute("width","20px");
    }
  }

    useEffect(() => {
      if (confirmRequest) {
        initiateConfirmation();
      }
    },[confirmRequest]);

    const loadUserRequestStatusRooms = (confirm_request = null) => {
      let check_payload = {
        "username": localStorage.getItem('username'),
        "action": "check",
        'request_type': 'room_prop'
      }
      axios.post(ENVIRONMENT.PAYMENT_GATEWAY, check_payload)
      .then(res => {
        setPurchaseTypeRoom(res.data['purchase_type']);
        setUsedCountRooms(res.data['usage_count']);
        setAvailableCountRooms(res.data['available_limit']);
        setSubscriptionTypeRoom(res.data['subscription_plan'])
        setRequestStatusRoom(res.data['request_allowed']);
        setCardStatusRoom(res.data['card_status']);
        setEntityRoomPrice(res.data['price'])
        if (requestType == 'room_prop' && confirm_request != null) {
          setConfirmRequest(true);
        }

      })
    }

    const loadUserRequestStatusScenes = (confirm_request = null) => {
      let check_payload = {
        "username": localStorage.getItem('username'),
        "action": "check",
        'request_type': 'scene_template_prop'
      }
      axios.post(ENVIRONMENT.PAYMENT_GATEWAY, check_payload)
      .then(res => {
        console.log('Scene',res)
        setPurchaseTypeTemplate(res.data['purchase_type']);
        setUsedCountTemplates(res.data['usage_count']);
        setAvailableCountTemplates(res.data['available_limit']);
        setSubscriptionTypeTemplates(res.data['subscription_plan'])
        setRequestStatusScene(res.data['request_allowed']);
        setCardStatusTemplate(res.data['card_status']);
        setEntityScenePrice(res.data['price'])
        if (requestType == 'scene_template_prop' && confirm_request != null) {
          setConfirmRequest(true);
        }

      })
    }

    const countLimitFull = () => {
      if (itemType == 'products') {
        return usedCountProducts == (usedCountProducts + availableCountProducts);
      } else if (itemType == 'spaces') {
        return usedCountRooms == (usedCountRooms + availableCountRooms);
      } else {
        return usedCountTemplates == (usedCountTemplates + availableCountTemplates)
      }
    }

    const upgradeSubscriptionPlan = () => {
      let payload ={
        username: localStorage.getItem('username'),
        subscription_type: subscriptionPlan
      }
      setButtonLoader(true);
      axios.post(ENVIRONMENT.UPGRADE_SUBSCRIPTION,payload)
      .then( (response) => {
        setSubscriptionModal(false)
        setButtonLoader(false);
        setPaymentConfirmationModal(false);
        setConfirmationModal(true);
        setTimeout(() => {
          loadUserRequestStatusProduct();
          loadUserRequestStatusRooms();
          loadUserRequestStatusScenes();
        }, 3000)
        message.success('Your subscription plan has been successfully upgraded.');
        processSubmission();
    })
      .catch((error) => {
      });

    }


    const initiateConfirmation = () => {
      window.addEventListener('card_saved', (e) => {
        let action = e.detail.action;
        setCardSaveFlowTriggered(false);
        if (action == 'product_prop' || action == 'room_prop' || action == 'scene_template_prop') {
            processSubmission();
        }

      }, false);

      if (requestType == 'product_prop') {
        if (requestStatusProduct == "allowed" || requestStatusProduct == "always_allowed") {
          processSubmission();
        }
        if (requestStatusProduct == "payment_required") {
          if (cardStatusProduct == 0) {
            let child_window = window.open('/save-card?action=' + requestType, '', "width=600,height=600");
            let refreshInterval = setInterval(() => {
              if(child_window.closed) {
                setCardSaveFlowTriggered(false);
                clearInterval(refreshInterval);
              }
            }, 500);
            setCardSaveFlowTriggered(true);
          }
          if (cardStatusProduct == 1) {
            processSubmission();
          }

        }
      }

      if (requestType == 'room_prop') {
        if (requestStatusRoom == "allowed" || requestStatusRoom == "always_allowed") {
          processSubmission();
        }
        if (requestStatusRoom == "payment_required") {
          if (cardStatusProduct == 0) {
            let child_window = window.open('/save-card?action=' + requestType, '', "width=600,height=600");
            let refreshInterval = setInterval(() => {
              if(child_window.closed) {
                setCardSaveFlowTriggered(false);
                clearInterval(refreshInterval);
              }
            }, 500);
            setCardSaveFlowTriggered(true);
          }
          if (cardStatusProduct == 1) {
            processSubmission();
          }
        }
      }
      if (requestType == 'scene_template_prop') {
        if (requestStatusScene == "allowed" || requestStatusScene == "always_allowed") {
          processSubmission();
        }
        if (requestStatusScene == "payment_required") {
          if (cardStatusProduct == 0) {
            let child_window = window.open('/save-card?action=' + requestType, '', "width=600,height=600");
            let refreshInterval = setInterval(() => {
              if(child_window.closed) {
                setCardSaveFlowTriggered(false);
                clearInterval(refreshInterval);
              }
            }, 500);
            setCardSaveFlowTriggered(true);
          }
          if (cardStatusProduct == 1) {
            processSubmission();
          }
        }
      }

  }

  const initiateSubscriptionConfirmation = () => {
    window.addEventListener('card_saved', (e) => {
      let action = e.detail.action;
      setCardSaveFlowTriggered(false);
      if (action == 'subscription_update') {
        upgradeSubscriptionPlan();
      }

    }, false);

    if (requestType == 'product_prop' || requestType == 'room_prop' || requestType == 'scene_template_prop') {
      if (cardStatusProduct == 1) {
          upgradeSubscriptionPlan();
      }
      if (cardStatusProduct == 0) {
          setIsSubscriptionUpdateRequest(true);
          let child_window = window.open('/save-card?action=subscription_update', '', "width=600,height=600");
            let refreshInterval = setInterval(() => {
              if(child_window.closed) {
                setCardSaveFlowTriggered(false);
                clearInterval(refreshInterval);
              }
          }, 500);
          setCardSaveFlowTriggered(true);
      }
    }
  }

    const processSubmission = () => {
      setButtonLoader(true);
      axios.post(ENVIRONMENT.PAYMENT_GATEWAY,libraryPayload)
          .then( (response) => {
            if (response.data.status!=undefined && response.data.status == 1) {
              setButtonLoader(false);
              setSuccessPopup(true);
              setConfirmationModal(false);
              setPaymentConfirmationModal(false);
              if(itemType == 'products'){
                let product_added = productsAddedToLibrary;
                product_added.push(libraryPayload.request_payload.product_id);
                setProductsAddedToLibrary(product_added)
                loadUserRequestStatusProduct()
              }
              else if (itemType == 'spaces') {
                let space_added = spacesAddedToLibrary;
                space_added.push(libraryPayload.request_payload.room_id);
                setSpacesAddedToLibrary(space_added)
                loadUserRequestStatusRooms()
              } else {
                let templates_added = templatesAddedToLibrary;
                templates_added.push(libraryPayload.request_payload.id);
                setTemplatesAddedToLibrary(templates_added)
                loadUserRequestStatusScenes()
              }
            }
            else{
              if ('request_allowed' in response.data && response.data['request_allowed'] == 'not_allowed') {
                setInAppLimitExceededModal(true);
                setButtonLoader(false);
                setConfirmationModal(false)
                setPaymentConfirmationModal(false);
              } else {
                setPaymentFailureMessage(response.data.error_message);
                setPaymentFailureModal(true);
                setButtonLoader(false);
                setConfirmationModal(false)
              }
            }
            })
          .catch((error) => {
          });
    }

    const saveRequestIntermedietely = (callback = null) => {
      setButtonLoader(true);
      let payload = {
          'request_type': requestType,
          'request_body': libraryPayload,
          'processed': false
      }

      if (isSubscriptionUpdateRequest) {
        payload.subscription_update_body = {
            "subscription_type": subscriptionPlan,
            "username": CUSTOMER_USERNAME
        }
      }

      if (paymentRequestID == -1) {
          axios.post(ENVIRONMENT.SAVE_REQUEST_INTERMEDIATE, payload)
          .then(res => {
              setButtonLoader(false);
              setConfirmationModal(false);
              if (callback!= null)
              {
                  callback(res.data[0].request_id,requestType);
              }
          });
      } else {
          setButtonLoader(false);
          setConfirmationModal(false);
          if (callback!= null)
          {
              callback(paymentRequestID,requestType);
          }
      }
  }

    const collectionPrice = (request_type,payment_type) => {
      let payload = {
        "request_type": request_type,
        "payment_type": payment_type,
      }

      axios.post(ENVIRONMENT.GET_CATEGORY_AND_PRICE, payload)
      .then(res => {
          if (res.data) {
            if (request_type == "collection_room") {
              setRoomPrices(res.data);
            } else if (request_type == "collection_product") {
              setProductPrices(res.data);
            } else if (request_type == "product_prop") {
              if (res.data && res.data[0]) {
                setEntityProductPrice(parseInt(res.data[0].price));
                setSelectedPriceCategory(res.data[0].category);
              }
            } else  {
              if (res.data && res.data[0]) {
                setEntityRoomPrice(parseInt(res.data[0].price));
                setSelectedPriceCategory(res.data[0].category);
              }
            }

          }
      })
    }

    const returnMaxPrice = (type) => {
      if (type == 'products') {
        return maxPriceProduct;
      } else if (type == 'spaces') {
        return maxPriceSpace;
      } else {
        return maxPriceScene;
      }
    }

    const libraryUrl = () => {
      if (itemType == 'products') {
        window.location.href = '/list-products';
      } else if (itemType == 'spaces') {
        window.location.href = '/rooms';
      } else {
        window.location.href = '/my_scenes';
      }
    }

    const getStoreContent = () => {
      let storeDataComp = [];
      let storeDataByRelevance = {} 
      storeData && storeData.map((entity, index) => {
          if (searchValue == '' && !filtersApplied && (index >= ((currentPage-1)*pageSize) && index < ((currentPage-1)*pageSize+(pageSize - 1)))){
            let storeEntry = (<StoreContent
            index={index}
            item_type={entity.item_type}
            get_add_options={get_add_options}
            onAddCollectionClick={onAddCollectionClick}
            onAddSceneClick={onAddSceneClick}
            addToLibrary={addToLibrary}
            id={itemType == 'spaces' ? entity.room_id : entity.id}
            name={itemType == 'spaces' ? entity.room_name : entity.name}
            price={entity.price}
            customer_username={itemType == 'scenes' ? entity.requested_by : entity.customer_username}
            company_display_name={entity.company_display_name}
            company_id={entity.company_id}
            getRequestPlan={getRequestPlan}
            itemType={itemType}
            productsAddedToLibrary={productsAddedToLibrary}
            spacesAddedToLibrary={spacesAddedToLibrary}
            thumbnail={entity.thumbnail}
            category={itemType == 'scenes' ? entity.room_name : entity.category}
            subscriptionPlan={subscriptionPlan}
            platform = {entity.platform}
            />)
          if (searchValue == '' && !filtersApplied) {
            storeDataComp.push(storeEntry)
          }
      
        }
        else if (searchValue != '' || filtersApplied) {
          let storeEntry = (<StoreContent
            index={index}
            item_type={entity.item_type}
            get_add_options={get_add_options}
            onAddCollectionClick={onAddCollectionClick}
            onAddSceneClick={onAddSceneClick}
            addToLibrary={addToLibrary}
            id={itemType == 'spaces' ? entity.room_id : entity.id}
            name={itemType == 'spaces' ? entity.room_name : entity.name}
            price={entity.price}
            company_display_name={entity.company_display_name}
            company_id={entity.company_id}
            customer_username={itemType == 'scenes' ? entity.requested_by : entity.customer_username}
            getRequestPlan={getRequestPlan}
            itemType={itemType}
            productsAddedToLibrary={productsAddedToLibrary}
            spacesAddedToLibrary={spacesAddedToLibrary}
            thumbnail={entity.thumbnail}
            category={itemType == 'scenes' ? entity.room_name : entity.category}
            subscriptionPlan={subscriptionPlan}
            platform = {entity.platform}
            />)
            if (searchValue == '' && filtersApplied) {
              if (returnSearchValue(entity)) {
                storeDataComp.push(storeEntry)
      
              }
            } else if (searchValue != '' && filtersApplied) {

                if (returnSearchValue(entity)) {
                  if (itemType == 'products' || itemType == 'spaces' || itemType == 'scenes') {
                    let name = itemType == 'spaces' ? entity.room_name:entity.name;
                    let nameSearchRelevance = Utilities.getStringsRelevance(searchValue.toLowerCase(), name.toLowerCase(), searchKeywords, entity.keywords)
                    let relevance = nameSearchRelevance
                    
                    if (relevance > 0 ) {
                      if (storeDataByRelevance[relevance] == undefined) {
                        storeDataByRelevance[relevance] = [];
                      }
                      storeDataByRelevance[relevance].push(storeEntry); 
                    }      
                  }
                }

            } else if (searchValue != '' && !filtersApplied) {
              if (itemType == 'products' || itemType == 'spaces' || itemType == 'scenes') {
                let name = itemType == 'spaces' ? entity.room_name:entity.name;
                let nameSearchRelevance = Utilities.getStringsRelevance(searchValue.toLowerCase(), name.toLowerCase(), searchKeywords, entity.keywords)
                let relevance = nameSearchRelevance
                
                if (relevance > 0 ) {
                  if (storeDataByRelevance[relevance] == undefined) {
                    storeDataByRelevance[relevance] = [];
                  }
                  storeDataByRelevance[relevance].push(storeEntry); 
                }      
              }
            }
        }
        })

        if (searchValue != '') {
          storeDataComp = Object.keys(storeDataByRelevance)
          .sort((a, b) => b - a) // Sort keys in descending order
          .reduce((acc, key) => acc.concat(storeDataByRelevance[key]), []);
        }

        let  MainStoreContent =  <>{loader || productLoad || storeData && storeData.length == 0 ?
          storeData && storeData.length == 0 && (itemType == 'spaces' || itemType == 'scenes' || itemType === 'products') ?
          <Empty style={{marginTop: 40, marginBottom: 40}}/> :
          <DottedLoader/> : <>
          {storeState && !filtersApplied && <h3 className='manrope f-20 black-14 w-500'
          style={{lineHeight: '20px',marginTop: 40, marginBottom: 0,padding: 10}}>
          {itemType == 'products' ?
          'Add products to your library to use in your own designs' :
          itemType == 'spaces' ?
          'Add rooms to your library to place your own products in and generate images' :
          'Add designed rooms as templates to your library, add your own products and generate new images'}</h3>}
          <Row type="flex" style={{justifyContent: "start",margin:"0px 0px 40px 0px"}}>
            {storeDataComp}
          </Row></>}
          </>

        return MainStoreContent;
    }

    let BrandStoreContent = <>
    {brandLoader || (companyData && companyData.length == 0)  ? <DottedLoader/> :
    <Row type="flex" style={{justifyContent: "start",margin:"0px 0px 40px 0px"}}>
    {itemCount && companyData  && companyData.map((company, index) => {
        let brandEntry = (<Col span={6} style={{padding : "6px", cursor: "pointer"}} key={index}>
            <Card bodyStyle={{ padding: "10px" }} className="card-shadow-product" bordered={false}>
              <div onClick={() => openCompanyItems(company)} className="suggested-product" style={{zIndex:"1"}}>
                <div>
                  <div className="box">
                      <img style={{objectFit: "contain",height:"275px",zIndex:1, padding: 20 }} className="product-image" src={company.featured_brand ? ENVIRONMENT.BASE_URL + company.logo.uid + '/' + company.logo.name : '/img/image_placeholder.png'} />
                  </div>
                  <hr style={{marginTop: 0, marginBottom: 0}}/>

                  <Tooltip title={<span className='manrope f-12 white'>{company.company_display_name}</span>}>
                    <div className="manrope f-14 w-500 black-00 j-end clamp-text w-90" style= {{margin:"12px 12px 8px 12px"}}>
                        {company.company_display_name}
                    </div>
                  </Tooltip>
                  <div className="justify-in-start manrope f-12 grey-77 capitalize" style= {{marginLeft:"12px",marginRight:"12px",marginBottom:"12px",textAlign:"left"}}>
                    <div>{itemCount[company.company_domain] || 0} Items</div>
                  </div>
                </div>
              </div>
            </Card>
        </Col>)
          if(searchValue == '' && !filtersApplied){
            return brandEntry;
          } else if (searchValue != '' && !filtersApplied) {
            if (Utilities.isMatching(searchValue.toLowerCase(), company.company_display_name.toLowerCase())){
              return brandEntry;
            }
          }
    })}
    </Row>
    }
  </>;

    return <CustomerMainLayout selected='11'>
      <>
          <Col span={24} className="store-search-bar">
            <div className="product-filter-form justify-space-between">
              <Col lg={2} xs={6} sm={4} md={3} style={{padding : "0px 10px 0px 0"}}>
                <h1 className='manrope w-600 f-24 black-55' style={{marginBottom: 0, lineHeight: '24px'}}>STORE</h1>
              </Col>
                <Input.Group compact size="large" className="d-inline">
                    <Input className="manrope f-14 grey-99" style={{padding: 12, borderRadius: 4}} placeholder="Search Store" onChange={e => {changeSearchValue(e.target.value)}}></Input>
                </Input.Group>

                <Col  className='switch-bg' style={{marginLeft: 10}}>
                    <Radio.Group className='radio-header' onChange={changeItemType}  defaultValue={selectedDisplay} value={selectedDisplay}  buttonStyle="solid">
                      <Radio.Button className='radio-btn-header' value="products">Products</Radio.Button>
                      <Radio.Button className='radio-btn-header' value="spaces">Spaces</Radio.Button>
                      <Radio.Button className='radio-btn-header' value="scenes">Templates</Radio.Button>

                      {!brandsSelected && <Radio.Button className='radio-btn-header' value="brands">Brands</Radio.Button>}
                    </Radio.Group>
                </Col>

              {selectedCompany != null || storeState ?
                <Col style={{marginLeft: 10,padding : "10px"}}>
                    <Button className="modal-okay-blue square font-14"  onClick={openFilters} onMouseOut={onMouseOut} onMouseOver={onMouseOver}>
                      {/* <img style={{width: 22, height: 22}} src="/img/filter.png"/> */}
                      <img height='20px' width='20px' src="/img/filter_blue.png" id="filter" />

                    </Button>
                </Col>: ''}
            </div>
          </Col>
          {!storeState &&
          <Breadcrumb className='breadcrumb-settings'>
            <Breadcrumb.Item>
              <a className='manrope f-16 w-400 grey-6b' href="/store">Store</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <a className='manrope f-16 w-400 grey-6b' href="#" onClick={() => goBack()}>Brands</a>
            </Breadcrumb.Item>
            {selectedCompany != null &&
            <Breadcrumb.Item>
              <a className='manrope f-16 w-400 grey-6b' href='#'>{selectedCompany.company_display_name}</a>
            </Breadcrumb.Item>}
          </Breadcrumb>}
         {showBrandsProducts && showBrandsSpaces && storeState && !filtersApplied && companyData && companyData.length > 0 && featuredBrands && featuredBrands.length > 0 &&
         <>
        <h3 className='manrope f-20 black-14 w-500' style={{marginTop: 28, lineHeight: '20px'}}>Brands</h3>
         <Carousel
            ref={(el) => {setCarousel(el)}}
						swipeable={true}
						draggable={true}
						showDots={false}
						responsive={responsive}
						ssr={true} // means to render carousel on server-side.
						infinite={true}
						autoPlay={false}
						arrows={true}
						autoPlaySpeed={8000}
						keyBoardControl={false}
						containerClass="carousel-container-store">
            {companyData && companyData.map((company,index) => (

              <div className="logo-container justify-in-center" style={{marginRight: 10}} onClick={() => openCompanyItems(company)}>
                <img className="logo-image" src={ENVIRONMENT.BASE_URL + company.logo.uid + '/' + company.logo.name} />
                <p style={{marginBottom: 0,marginLeft: 5}} className='manrope f-14 black-55 w-700'>{company.company_display_name}</p>
              </div>
            ))}
          </Carousel>
          </>}
          {!storeState && selectedCompany != null && !loader && !productLoad && selectedCompany && selectedCompany.featured_brand && (storeData && storeData.length > 0 || (selectedCompany && itemCount && itemCount[selectedCompany.company_domain]== 0)) &&
          <Col span={24} style={{marginBottom: 20}}>
            <div className='banner-container'>
              <img className='banner-style' src='/img/Banner.png'/>
              <div className='logo-website-container'>
                <div className='logo-banner'>
                  <img className='logo-placement' style={{width: logoWidth + '%'}} src={ENVIRONMENT.BASE_URL + selectedCompany.logo.uid + '/' + selectedCompany.logo.name}/>
                </div>
                <div className='website-container'>
                  <a className='manrope f-16' href={selectedCompany.website} target='_blank'>Visit Website <ArrowRightOutlined/></a>
                </div>
              </div>
            </div>
        </Col>}

          {filtersApplied && <Col span={24} style={{marginLeft: 10, marginTop: 20}}>
            <h4 className='manrope f-14 black-55 italic'>Showing results for</h4>
            <div style={{marginBottom: 20}}>
              {itemType == 'products' || itemType == 'scenes' ?
                productCategoryValue.length > 0 && productCategoryValue.map((category) => {
                  return <Tag onClose={() => closeTags(category,'product_category')} closable visible={true} className='manrope f-12 black-55 tag-filter'>Category: <span className='manrope f-12 grey-77'>{category}</span></Tag>
                }) : ''}

              {itemType == 'spaces' || itemType == 'scenes' ?
                spaceCategoryValue.length > 0 && spaceCategoryValue.map((category) => {
                  return <Tag onClose={() => closeTags(category,'space_category')} closable visible={true} className='manrope f-12 black-55 tag-filter'>Category: <span className='manrope f-12 grey-77'>{category}</span></Tag>
                }) : ''
              }

              {itemType == 'scenes' ?
                styleCategoryValue.length > 0 && styleCategoryValue.map((category) => {
                  return <Tag onClose={() => closeTags(category,'style_category')} closable visible={true} className='manrope f-12 black-55 tag-filter'>Style: <span className='manrope f-12 grey-77'>{category}</span></Tag>
                }) : ''}

              {companyFilter.length > 0 && companyFilter.map((company) => {
                return <Tag  onClose={() => closeTags(company,'brand')} closable visible={true} className='manrope f-12 black-55 tag-filter'>Brand: <span className='manrope f-12 grey-77'>{returnCompanyDisplayName(company)} </span></Tag>
              })}

              {itemType == 'products' ?
                productStyleCategory.length > 0 && productStyleCategory.map((category) => {
                  return <Tag onClose={() => closeTags(category,'product_style_category')} closable visible={true} className='manrope f-12 black-55 tag-filter'>Style Category: <span className='manrope f-12 grey-77'>{category}</span></Tag>
                }) : ''}

              {itemType == 'products' ?
                colorValue.length > 0 && colorValue.map((category) => {
                  return <Tag onClose={() => closeTags(category,'product_color')} closable visible={true} className='manrope f-12 black-55 tag-filter'>Color: <span className='manrope f-12 grey-77'>{category}</span></Tag>
                }) : ''}

              {itemType == 'products' ?
                productMaterials.length > 0 && productMaterials.map((category) => {
                  return <Tag onClose={() => closeTags(category,'product_material')} closable visible={true} className='manrope f-12 black-55 tag-filter'>Material: <span className='manrope f-12 grey-77'>{category}</span></Tag>
                }) : ''}

              {itemType == 'products' ?
              productModelType.length > 0 && productModelType.map((model) => {
                return <Tag closable   onClose={() => closeTags(model,'model_type')} visible={true} className='manrope f-12 black-55 tag-filter'>Model Type: <span className='manrope f-12 grey-77'>{FileConstants.getModelType(model)}</span></Tag>
              }) : ''}
              {itemType == 'products' && (minPrice != 0 || maxPrice != maxPriceProduct) ?
                <Tag closable  onClose={() => closeTags('none','price')} visible={true} className='manrope f-12 black-55 tag-filter'>Price: <span className='manrope f-12 grey-77'>
                 {minPrice != maxPrice ?
                  "$" + minPrice + " - $" + maxPrice
                  : "$" + minPrice}
                  </span>
                </Tag> :
                itemType == 'spaces' && (minPrice != 0 || maxPrice != maxPriceSpace) ?
                <Tag closable  onClose={() => closeTags('none','price')} visible={true} className='manrope f-12 black-55 tag-filter'>Price: <span className='manrope f-12 grey-77'>
                {minPrice != maxPrice ?
                 "$" + minPrice + " - $" + maxPrice
                 : "$" + minPrice}
                 </span>
                </Tag>: itemType == 'scenes' && (minPrice != 0 || maxPrice != maxPriceScene) ?
                <Tag closable  onClose={() => closeTags('none','price')} visible={true} className='manrope f-12 black-55 tag-filter'>Price: <span className='manrope f-12 grey-77'>
                {minPrice != maxPrice ?
                 "$" + minPrice + " - $" + maxPrice
                 : "$" + minPrice}
                 </span>
                </Tag>: ''}


            </div>

          </Col>}
          <Col span={24}>
            {(storeState || selectedCompany != null) ?
            (selectedCompany && itemCount && itemCount[selectedCompany.company_domain]== 0) ?
            (comingSoonCompanies.includes(selectedCompany.company_domain) ?
            <h3 className='manrope f-20 black-14 w-500' style={{lineHeight: '20px',marginTop: 40, marginBottom: 40,padding: 10}}>Coming Soon</h3> :
            <Row>
              <DottedLoader  type="flex" style={{justifyContent: "center",margin:"40px auto"}} />
            </Row>)
            :
            getStoreContent()
            : BrandStoreContent}
            {(storeState || selectedCompany != null) && (searchValue == '' && !filtersApplied)  && !loader && !productLoad && storeData && storeData.length > 0 ?
            <Pagination style={{marginBottom: 40}} showSizeChanger={false} pageSize={pageSize} current={currentPage} onChange={onChangePageSize} total={storeData && storeData.length} />: ''}
          </Col>
      </>
      <AddCollection
      entityPrice={entityProductPrice}
      entityName={entityName}
      cardStatus={cardStatusProduct}
      requestStatus={requestStatusProduct}
      purchaseType={purchaseTypeProduct}
      addedToLibrary = {false}
      usedCount = {usedCountProducts}
      availableCount = {availableCountProducts}
      cardSaveFailure={productCardFailure}
      setCardSaveFailure={setProductCardFailure}
      showSuccesProduct={showSuccesProduct}
      cardSaveDrawer={productCardSaveDrawer}
      setCardSaveDrawer={setProductCardSaveDrawer}
      prices={productPrices}
      paymentRequestID={paymentRequestID}
      productId={selectedProductIdForCollection}
      productsAddedToLibrary={productsAddedToLibrary}
      setProductsAddedToLibrary= {setProductsAddedToLibrary}
      visible={collectionModalVisible}
      setCollectionModalVisible={setCollectionModalVisible}
      itemType={itemType}
      subscriptionPlan={subscriptionPlan}
      setSubscriptionPlan={setSubscriptionPlan}
      currentPackage={currentPackage}
      loadUserRequestStatusProduct={loadUserRequestStatusProduct}
      isALL3DEntity={isALL3DEntity}
      setInAppLimitExceededModal={setInAppLimitExceededModal}
      currentSubscriptionPlan={currentPackage}
      isSubscriptionActive={isSubscriptionActive}
      />
      <AddCollectionRoom
      entityPrice={entityRoomPrice}
      addedToLibrary = {false}
      setSubscriptionModal={setSubscriptionModal}
      entityName={entityName}
      cardStatus={cardStatusProduct}
      requestStatus={requestStatusRoom}
      purchaseType={purchaseTypeRoom}
      usedCount = {usedCountRooms}
      availableCount = {availableCountRooms}
      cardSaveDrawer={roomCardSaveDrawer}
      setCardSaveDrawer={setRoomCardSaveDrawer}
      cardSaveFailure={roomCardFailure}
      prices={roomPrices}
      setCardSaveFailure={setRoomCardFailure}
      paymentRequestID={paymentRequestID}
      showSuccessRoom={showSuccessRoom}
      roomId={selectedRoomIdForCollection}
      spacesAddedToLibrary={spacesAddedToLibrary}
      setSpacesAddedToLibrary={setSpacesAddedToLibrary}
      loadUserRequestStatusRooms={loadUserRequestStatusRooms}
      visible={roomCollectionModalVisible}
      setCollectionModalVisible={setRoomCollectionModalVisible}
      itemType={itemType}
      subscriptionPlan={subscriptionPlan}
      setSubscriptionPlan={setSubscriptionPlan}
      currentPackage={currentPackage}
      isALL3DEntity={isALL3DEntity}
      setInAppLimitExceededModal={setInAppLimitExceededModal}
      currentSubscriptionPlan={currentPackage}
      isSubscriptionActive={isSubscriptionActive}
      />
           <SuccessModal
            visible={successPopup}
            onCancel={() => setSuccessPopup(false)}
            heading={`${TYPE_MAPPING[itemType]} Added to Library Successfully!`}
            text={<span>{TYPE_MAPPING[itemType]} has been added to your library successfully!</span>}
            footer={[
                <div className="justify-in-center">
                    <Button className="modal-okay-gray square font-14" htmlType="submit" onClick={() => {setSuccessPopup(false)} } >
                        Stay on Store
                    </Button>
                    <Button className="modal-okay square font-14" htmlType="submit" onClick={libraryUrl} >
                        Go to Library
                    </Button>
                </div>
            ]}
            />
            <CardSaveDrawer
            saveRequestIntermedietely={saveRequestIntermedietely}
            setCardSaveFailure={setCardSaveFailure}
            visible={cardSaveDrawer}
            onClose={() => setCardSaveDrawer(false)}
            />
          <Modal
          className="modal-space"
          width={700}
          visible={confirmationModal}
          closable={false}
          bodyStyle={{padding: 0}}
          footer={[
              <span className="justify-in-end">
                <Button disabled={buttonLoader} key="back" style={{textAlign:"center"}} className="modal-okay-gray square font-12" onClick={() => setConfirmationModal(false)}>
                    Cancel
                </Button>
                  <Button disabled={buttonLoader} key="back" style={{textAlign:"center"}} className="modal-okay square font-12" onClick={ ()=> { if (addingFreeItem == true){ AddFreeItem()} else {initiateConfirmation()} }}>
                      {buttonLoader? <span>Adding <LoadingOutlined spin /></span>:<span>Confirm </span>}
                  </Button>
              </span>
          ]}
          >
          <div style={{padding: 30, display: 'flex'}}>
            <Col span={24}>
              <h2 className="modal-heading manrope f-14 black-00 w-600">Add this {requestType == 'product_prop' ? 'product' : requestType == 'room_prop' ? 'space' : 'scene'} to your library? </h2>
              <p className="manrope f-12 black-33">This {requestType == 'product_prop' ? 'product' : requestType == 'room_prop' ? 'space' : 'scene'} will be added to your library once you click 'Confirm'. </p>
            </Col>
          </div>
      </Modal>
      <InAppPurchaseLimitModal visible={inAppLimitExceededModal} setVisible={setInAppLimitExceededModal} />
      <SubscriptionPackageUpdate
      subscriptionPlan={subscriptionPlan}
      setSubscriptionPlan={setSubscriptionPlan}
      currentPackage={currentPackage}
      currentSubscriptionPlan={currentPackage}
      isSubscriptionActive={isSubscriptionActive}
      visible={subscriptionModal}
      requestStatus={requestType == 'product_prop' ? requestStatusProduct : requestStatusRoom == 'room_prop' ? requestStatusRoom : requestStatusScene}
      onCancel={() => setSubscriptionModal(false)}
      heading={<span>You have succeeded the quota for adding {requestType == 'product_prop' ? 'product props' : 'space props'} to your library. Do you want to upgrade your current plan?</span>}
      text={<span>Your current package is selected by default. Select another package to upgrade. </span>}
      footer={[
        <div>
          {
          requestType == 'product_prop' && requestStatusProduct == 'update_subscription' ?
          <span style={{color:"#276DD7"}} className='justify-in-start manrope f-14'>
            Please upgrade your package to keep using this service.
          </span>
          :
          requestType == 'room_prop' && requestStatusRoom == 'update_subscription' ?
          <span style={{color:"#276DD7"}} className='justify-in-start manrope f-14'>
            Please upgrade your package to keep using this service.
          </span>
          :
          requestType == 'scene_template_prop' && requestStatusScene == 'update_subscription' ?
          <span style={{color:"#276DD7"}} className='justify-in-start manrope f-14'>
            Please upgrade your package to keep using this service.
          </span> :
          ''
          }
        <span className="justify-in-end">
          <Button key="back" className="modal-okay-gray square font-14" onClick={() => setSubscriptionModal(false)}>
              Cancel
          </Button>
            {(cardStatusProduct == 1)?
                <Button disabled={subscriptionPlan == '' || cardSaveFlowTriggered || buttonLoader} key="back"  className="modal-okay square font-14" onClick={upgradeSubscriptionPlan}>
                  {buttonLoader? <span>Upgrading Plan <LoadingOutlined spin /></span>:<span>Confirm & Process Payment </span>}
                </Button>
                :
                <Button disabled={subscriptionPlan == '' || cardSaveFlowTriggered || buttonLoader} key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={initiateSubscriptionConfirmation}>
                  {buttonLoader ? <span>Upgrading Plan <LoadingOutlined spin /></span>:<span>Confirm & Enter Payment Details </span>}
                </Button>
                }
        </span>
        </div>
      ]}
      />
      <PaymentFailureModal
        paymentFailureMessage={paymentFailureMessage}
        setPaymentFailureModal={setPaymentFailureModal}
        updatePaymentDetailsOnFailure={updatePaymentDetailsOnFailure}
        paymentFailureModal={paymentFailureModal}
        />
      <PaymentConfirmationModal
          storeProp={true}
          itemType={itemType}
          purchaseType={itemType == 'products' ? purchaseTypeProduct : itemType == 'spaces' ? purchaseTypeRoom : purchaseTypeTemplate}
          usedCount = {itemType == 'products' ? usedCountProducts : itemType == 'spaces' ? usedCountRooms : usedCountTemplates}
          availableCount = {itemType == 'products' ? availableCountProducts : itemType == 'spaces' ? availableCountRooms : availableCountTemplates}
           name={entityName}
           disable={disablePopup}
           visible={paymentConfirmationModal}
           setSubscriptionModal={isALL3DEntity ? setSubscriptionModal : undefined}
           amount={<span>${itemType == "products" ? entityProductPrice : itemType == 'spaces' ? entityRoomPrice : entityScenePrice}</span>}
           onCancel={() => {setPaymentConfirmationModal(false); loadUserRequestStatusProduct(); loadUserRequestStatusRooms(); loadUserRequestStatusScenes();}}
           text={countLimitFull()  ? "In order to proceed with the request for adding this entity to your library, please pay the required amount." : 'Are you sure you want to add this item to your library?'}
           footer={[
           <span style={{display: 'flex', justifyContent:'center'}}>
               <Button disabled={buttonLoader} className="modal-okay-gray font-14 square" onClick={() => {setPaymentConfirmationModal(false);loadUserRequestStatusProduct(); loadUserRequestStatusRooms(); loadUserRequestStatusScenes();}}>
               Cancel
               </Button>
               <Button disabled={buttonLoader || disablePopup || cardSaveFlowTriggered} className="modal-okay font-14 square" onClick={initiateConfirmation}>
               {
                 itemType == 'products' ?
                 (cardStatusProduct == 1 && requestStatusProduct == "payment_required" ? "Confirm & Process Payment" :
                 cardStatusProduct == 0 && requestStatusProduct == "payment_required" ?"Confirm & Enter Payment Details" : "Confirm")
                 : itemType == 'spaces' ?
                 (cardStatusProduct == 1 && requestStatusRoom == "payment_required" ? "Confirm & Process Payment" :
                cardStatusProduct == 0 && requestStatusRoom == "payment_required" ?"Confirm & Enter Payment Details" : "Confirm") :
                (cardStatusTemplate == 1 && requestStatusScene == "payment_required" ? "Confirm & Process Payment" :
                cardStatusTemplate == 0 && requestStatusScene == "payment_required" ?"Confirm & Enter Payment Details" : "Confirm")
               }
               {buttonLoader == true ? <LoadingOutlined type="sync" spin />: ''}
               </Button>
           </span>]}
          />
      <Drawer
      width={360}
      visible={filtersDrawer}
      onClose={closeFiltersDrawer}
      placement="right">
        <div style={{padding: 20}}>
          <h2 className="manrope f-18 black-14 w-700">Filters</h2>
          <div>
              {(storeState || selectedCompany != null) &&
              <>
              {(itemType == 'products' || itemType == 'scenes') ?
              <>
              <h5 className='manrope f-16 black-14' style={{marginTop: 20}}>
                {itemType == 'products' ? 'Product Category' : 'Search Templates by Product Category'}
              </h5>
              <TreeSelect
                value={productCategoryValue}
                showSearch={true}
                className="tree-select-material f-14 filter bg-light"
                style={{ width: '100%' }}
                multiple
                dropdownStyle={categoryStyle}
                placeholder="Search and select category"
                treeData={productCategoriesData}
                onChange={changeProductCategory}
                onSearch={changeProductSearchCategory}
              />
              </>: '' }

            {(itemType == 'spaces' || itemType == 'scenes') ?
              <>
                <h5 className='manrope f-16 black-14' style={{marginTop: 20}}>
                  {itemType == 'spaces' ? 'Space Category' : 'Search Templates by Space Category'}
                </h5>
                <Select
                className={`tree-select-material f-14 filter bg-light`}
                showSearch={true}
                value={spaceCategoryValue}
                onChange={changeSpaceCategory}
                placeholder="Search and select space category" mode="multiple">
                  {spaceCategories && spaceCategories.map((category,index) => {
                    return <Option className="manrope f-12 select-view" value={category}>{category}</Option>
                  })}
                </Select>
              </> : ''}
            </>}

            {(storeState  || selectedCompany != null) && itemType == 'scenes' ? <>
            <h5 className='manrope f-16 black-14'  style={{marginTop: 20}}>Style</h5>
            <Select
              className={`tree-select-material f-14 filter bg-light`}
              showSearch={true}
              value={styleCategoryValue}
              onChange={changeStyleCategory}
              placeholder="Search and select style" mode="multiple">
                {FileConstants.STYLE_CATEGORY.map((category,index) => {
                  return <Option className="manrope f-12 select-view" value={category}>{category}</Option>
                })}
            </Select>
          </>: ''}

            {selectedCompany == null ? <>
            <h5 className='manrope f-16 black-14'  style={{marginTop: 20}}>Brands</h5>
            <Select
              className={`tree-select-material f-14 filter bg-light`}
              showSearch={true}
              value={companyFilter}
              onChange={changeCompany}
              placeholder="Search and select brands" mode="multiple">
                {companyData && companyData.map((company,index) => {
                  return <Option className="manrope f-12 select-view" value={company.company_domain}>{company.company_display_name}</Option>
                })}
            </Select>
          </>: ''}

          {itemType == 'products' ?
            <>
              <h5 className='manrope f-16 black-14'  style={{marginTop: 20}}>Style Category</h5>
              <StyleCategory
              className="tree-select-material f-14 filter bg-light"
              onChange={changeProductStyleCategory}
              value={productStyleCategory}
              placeholder="Search and select style category" 
              mode="multiple"
              />
            </>
            :''
          }

          {itemType == 'products' ?
          <>
            <h5 className='manrope f-16 black-14'  style={{marginTop: 20}}>Product Color</h5>
            <ColorSelect  
            className="tree-select-material f-14 filter bg-light"
            value={colorValue}
            onChange={changeColorValue}
            placeholder="Search and select color"
            mode="multiple"
            />
          </>:''
          }

          {itemType == 'products' ?
          <>
            <h5 className='manrope f-16 black-14'  style={{marginTop: 20}}>Product Material</h5>
            <TreeSelect
              value={productMaterials}
              showSearch={true}
              className="tree-select-material f-14 filter bg-light"
              style={{ width: '100%' }}
              multiple
              dropdownStyle={categoryStyle}
              placeholder="Search and select material"
              treeData={productMaterialsData}
              onChange={changeProductMaterial}
              onSearch={changeProductSearchCategory}
          >
          </TreeSelect>
            </>:''
          }

            {itemType == 'products' && (storeState || selectedCompany != null ) ?
            <>
              <h5 className='manrope f-16 black-14'  style={{marginTop: 20}}>Search In</h5>
              <Checkbox.Group value={productModelType} onChange={changeProductModelType}>
                <Checkbox  className='manrope f-14 black-14 w-600' value='ar'>
                  AR Model
                </Checkbox>
                <Checkbox  className='manrope f-14 black-14 w-600' value='high_res'>
                  High Res Model
                </Checkbox>
              </Checkbox.Group>
            </> : ''}

            <>
              <h5 className='manrope f-16 black-14'  style={{marginTop: 20}}>Price</h5>
              <Slider value={[minPrice, maxPrice]} className='adjust-pattern'onChange={onChangePrice} min={0} max={returnMaxPrice(itemType)} range defaultValue={[0, returnMaxPrice(itemType)]} />
            </>

        </div>

        </div>
      </Drawer>

      <Modal
        visible={sceneNameModal}
        onCancel={cancelSceneNameModal}
        width={700}
        className="modal-space"
        bodyStyle={{padding: 0}}
        footer={[
            <div className="justify-in-end">
                <Button className="modal-okay-gray square font-12" key="back" onClick={cancelSceneNameModal}>
                  Cancel
                </Button>
                <Button className="modal-okay square font-12" key="ok" onClick={addToLibraryScene}>
                    Add Scene To Library
                </Button>
            </div>
        ]}>
        <div style={{padding:"30px",display:"flex"}}>
            <Col span={24}>
                <h2 className="modal-heading manrope f-14 black-00 w-600">Add Scene Name</h2>
                <h5 className='manrope f-12 bg grey'><InfoCircleOutlined/>&nbsp;This will be the name of your scene and its associated collection.</h5>
                <Input value={sceneName} className='manrope f-12 black-55' placeholder='Enter Scene Name' style={{width: "100%",height:"40px"}} onChange={changeSceneName}/>
            </Col>
        </div>
    </Modal>

    <AddEntityToProject 
        entityId={selectedItemId}
        entityType={selectedItemType}
        modalVisible={projectModalVisible}
        setModalVisible={setProjectModalVisible}
        projects={projects}
    />

    <Modal
      visible={showPaymentRequiredBeforeAddToProjectPopup}
      onCancel={() => setShowPaymentRequiredBeforeAddToProjectPopup(false)}
      footer={[
        <div className="justify-in-center">
            <Button className="modal-okay-gray square font-14"  onClick={() => {setShowPaymentRequiredBeforeAddToProjectPopup(false)} } >
                Okay
            </Button>
        </div>
    ]}
    >
      <div>
        <h3>Payment Required</h3>
        <span className='manrope f-14'>You are required to purchase this model and add it to the library before adding to the project.</span>
      </div>
    </Modal>
    </CustomerMainLayout>
};


const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    listForStoreProducts: () => {
        dispatch(listForStoreProducts(LIST_FOR_STORE_PRODUCT));
    },
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Store))