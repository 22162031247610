import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments';
import { Row, Modal, Button } from 'antd';
import './AddProductsToScene.scss'
import ProductGridWrapper from '../ProductGridWrapper/ProductGridWrapper';
import ProductFiltersDrawer from '../ProductFiltersDrawer/ProductFilterDrawer'
import * as Utilities from '../../Utilities';
import AddProductsToSceneContext from '../../ContextFiles/AddProductsToSceneContext'
import ProductSearch from './ProductSearch';

const COMPANY_ID = localStorage.getItem('company_id');
const MANAGED_COMPANY_ID = localStorage.getItem('managed_company_id');
const MANAGED_CUSTOMER_USERNAME = localStorage.getItem('managed_customer_username');
const CUSTOMER_USERNAME = localStorage.getItem("username");
const SHARED_ENTITIES = localStorage.getItem('shared_entities');
const IS_MSPROVIDER = localStorage.getItem('is_msprovider');;

const AddProductsToScene = (props) => {
    const { add_product_modal, toggleAddProductModal, addProductsToScene, showAddProductsError,
        setAddProductsLoader, space_areas, collection_id, deletedProducts, updateSceneProductsList, 
        restoreProducts, updateSceneCollection, collection_products, project_ids } = props;

    const [loader, setLoader] = useState(true);
    const [libraryType, setLibraryType] = useState("library");
    const [productsAddedToScene, setProductsAddedToScene] = useState([]);
    const [productsRestoredToScene, setProductsRestoredToScene] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [libraryData, setLibraryData] = useState([]);
    const [storeData, setStoreData] = useState([]);
    const [displayedData, setDisplayedData] = useState([]);
    const [productsInScene, setProductsInScene] = useState([]);
    const [filtersDrawer, setFiltersDrawer] = useState(false);
    const [productCategoriesData, setProductCategoriesData] = useState([])
    const [productParentCateogries, setProductParentCateogries] = useState({})
    const [productMaterialsData, setProductMaterialsData] = useState([]);
    const [filtersApplied, setFiltersApplied] = useState(false);
    const [appliedFiltersList, setAppliedFiltersList] = useState([])
    const [filterTagFunction, setFilterTagFunction] = useState(null);
    const [returnSearchValFunction, setReturnSearchValFunction] = useState(null);
    const [productsForLibrary, setProductsForLibrary] = useState([]);
    const [maxPriceProduct, setMaxPriceProduct] = useState(0);
    const [currentTabType, setCurrentTabType] = useState("5");
    const [storeDataLoader, setStoreDataLoader] = useState(false);
    const [allStoreDataLoader, setAllStoreDataLoader] = useState(true);
    const [requestStatusProduct, setRequestStatusProduct] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const [productLoader, setProductLoader] = useState(true);
    const [originalProjectProductsData, setOriginalProjectProductsData] = useState([]);
        
    useEffect(() => {
        loadProducts();
        setProductCategoryData();
        loadUserRequestStatusProducts();
        loadProductMaterialsData();
    }, []);

    useEffect(() => {
        if(project_ids.length > 0){
            getProjectProductData()
            setLibraryType("ProjectProducts")
        }
    }, [project_ids]);

    useEffect(() => {
        if (collection_id != -1 || add_product_modal == true) {
            loadProductsInScene();
        }
    }, [collection_id, add_product_modal]);

    useEffect(() => {
        if (IS_MSPROVIDER && libraryType == "library") {
            let currentDisplayedData = getFilteredLibraryData(libraryData, parseInt(currentTabType));
            let sortedData = rearrangeDeletedProductsData(currentDisplayedData);
            setDisplayedData(sortedData);
        } else if (libraryType == "library" && libraryData.length > 0) {
            let sortedData = rearrangeDeletedProductsData(libraryData);
            setDisplayedData(sortedData);
        } else if (libraryType == "store" && storeData.length > 0) {
            let sortedData = rearrangeDeletedProductsData(storeData);
            setDisplayedData(sortedData);
        } else if (libraryType == "ProjectProducts" && originalProjectProductsData.length > 0){
            let sortedData = rearrangeDeletedProductsData(originalProjectProductsData);
            setDisplayedData(sortedData);
        }
    }, [libraryType, libraryData, storeData, deletedProducts, currentTabType]);

    const loadProductsInScene = () => {
        updateSceneProductsList((sceneProducts) => {
            setProductsInScene(sceneProducts);
        })
    }

    const getFilteredLibraryData = (currentData, modelStatus) => {
        let newData = []
        currentData.filter ((item) => {
            if (item.model_status == modelStatus) {
                newData.push(item)
            }
        })
        return newData
    }

    const setProductCategoryData = () => {
        let payload = {
            output: 'tree'
        };
        axios.post(ENVIRONMENT.ADMIN_CATEGORY_GET_BATCH, payload)
            .then(res => {
                setProductCategoriesData(res.data);
            });

        payload = {
            output: 'serial'
        };
        axios.post(ENVIRONMENT.ADMIN_CATEGORY_GET_BATCH, payload)
            .then(res => {
                setProductParentCateogries(res.data);
            });
    }

    const loadProductMaterialsData = () => {
        let data = [];
        axios.post(ENVIRONMENT.ADMIN_MATERIAL_BATCH, {
        })
            .then(res => {
                let categoriesData = Utilities.getMaterialsData(res.data);
                for (let i = 1; i <= (res.data).length; i++) {
                    if (res.data[i - 1].parent_id == "") {
                        data.push({
                            key: res.data[i - 1]['material_id'],
                            title: res.data[i - 1]['name'],
                            value: res.data[i - 1]['name'],
                            children: categoriesData[res.data[i - 1]['material_id']],
                        });
                    }

                }
                setProductMaterialsData(data);
            });
    }

    const loadProducts = () => {
        if (IS_MSPROVIDER && (MANAGED_CUSTOMER_USERNAME != null || MANAGED_COMPANY_ID != null)) {
            getCustomerDataForMsprovider();
        } else {
            getProductData([], true);
        }
        getStoreItems(true);
    }

    useEffect(() => {
        if (!initialLoad && productLoader) {
            getProductData();
        }
      }, [initialLoad]);

    let getMSPProductPayload = (type = 'owned') => {
        let payload = {
            "required_fields": ["id", "name", "brand_id", "category", "height", "customer_username",
                "width", "depth", "model_status", "thumbnail", "last_modified_stamp", "variant_of",
                "is_store_item", "group_id", "dimensions", "company_id", "is_shared", "platform"],
        }
        payload["order_by"] = "is_shared desc, last_modified_stamp desc"
        let filter_string = "";
        let customer_username = "customer_username__exact='" + MANAGED_CUSTOMER_USERNAME + "'"
        let company_id = "";
        let model_status = "model_status__in=[3,4,5]"
        let is_hidden = "is_hidden__not=true"
        let has_access_to = "has_access_to__like='%\"" + MANAGED_CUSTOMER_USERNAME + "\"%'"

        if (MANAGED_COMPANY_ID) {
            company_id = "company_id__exact=" + parseInt(MANAGED_COMPANY_ID) + "||additional_company_ids__contains='" + MANAGED_COMPANY_ID + "'"
            filter_string = "(" + company_id + "||" + customer_username + ")&&(" + is_hidden + ")"
        } else {
            filter_string = "(" + customer_username + "&&" + is_hidden + ")"
        }
        filter_string = filter_string + "&&(" + model_status + ")" + "||(" + has_access_to + ")"

        payload['filter_string'] = filter_string

        return payload;
    }

    const getCustomerDataForMsprovider = () => {
        setLoader(true);
        let payload = getMSPProductPayload();
        axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
            .then(res => {
                if (res.data) {
                    let owned_data = Utilities.getProductsDataWithKeywords(res.data);
                    getProductData(owned_data, true);
                }
            });
    }

    const getProjectProductData = () => {
        setLoader(true);
        let payload = getProjectProductsPayload(project_ids);
        console.log("Payload: ", payload)
        axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
            .then((response) => {
                if (response.data) {
                    let owned_products = Utilities.getProductsDataWithKeywords(response.data);;
                    setOriginalProjectProductsData(owned_products);
                    setDisplayedData(owned_products);
                    setProductLoader(false);
                    setLoader(false);
                }
            })
            .catch((error) => {
            });
    }

    let getProjectProductsPayload = (project_ids) => {
        let payload = {
            "required_fields": ["id", "name", "brand_id", "category", "height", "customer_username",
                "width", "depth", "model_status", "thumbnail", "last_modified_stamp", "variant_of",
                "is_store_item", "group_id", "dimensions", "company_id", "is_shared", "platform", "placement_type", "material_type"],
        }
        payload["order_by"] = "is_shared desc, last_modified_stamp desc"
        payload["filter_string"] =  `(project_ids__in=[${project_ids.join(",")}]) && (model_status__in=[4, 5])`

        return payload;
    }

    const getProductData = (products = [], initial = false) => {
        setLoader(true);
        if (!initial) {
            setLoader(true);
          } else {
            setInitialLoad(true);
        }
        let payload = getProductPayload(initial);
        axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
            .then(res => {
                if (res.data) {
                    let owned_products = res.data;
                    if (products.length > 0) {
                        owned_products = owned_products.concat(products);
                    }
                    owned_products = Utilities.getProductsDataWithKeywords(owned_products);
                    setLibraryData(owned_products);
                    setDisplayedData(owned_products);
                    setLoader(false);
                }
            });
        if(initial){
            setInitialLoad(false)
        }
        else{
            setProductLoader(false)
        }
    }

    let getProductPayload = (initial) => {
        let payload = {
            "required_fields": ["id", "name", "brand_id", "category", "height", "customer_username",
                "width", "depth", "model_status", "thumbnail", "last_modified_stamp", "variant_of",
                "is_store_item", "group_id", "dimensions", "company_id", "is_shared", "platform", "placement_type", "material_type"],
        }
        payload["order_by"] = "is_shared desc, last_modified_stamp desc"
        let filter_string = "";
        let customer_username = "customer_username__exact='" + CUSTOMER_USERNAME + "'"
        let company_id = "";
        let model_status = "model_status__in=[5]"
        let is_hidden = "is_hidden__not=true"
        let has_access_to = "has_access_to__like='%\"" + CUSTOMER_USERNAME + "\"%'"

        if (SHARED_ENTITIES != undefined && COMPANY_ID && SHARED_ENTITIES.split("_").includes("product")) {
            company_id = "company_id__exact=" + COMPANY_ID + "||additional_company_ids__contains='" + COMPANY_ID + "'"
            filter_string = "(" + company_id + "||" + customer_username + ")&&(" + is_hidden + ")"
        } else {
            filter_string = "(" + customer_username + "&&" + is_hidden + ")"
        }
        filter_string = filter_string + "&&(" + model_status + ")" + "||(" + has_access_to + ")"

        payload['filter_string'] = filter_string
        if (initial) {
            payload['pagination_filters'] = {
                'limit': 100,
                'offset': 0
            }
        }
        return payload;
    }

    const getStoreItems = (initial = false) => {
        let payload = {
            username: CUSTOMER_USERNAME,
            item_type: 'product',
        }
        if (initial) {
            payload.limit = "";
            setStoreDataLoader(true);
        }
        axios.post(ENVIRONMENT.STORE_LIST, payload)
            .then((res) => {
                let store_products = Utilities.getProductsDataWithKeywords(res.data, "store");
                store_products = store_products.sort(customSort);

                let max_price = store_products.reduce((prevValue, currentValue) => prevValue = prevValue > currentValue.price ? prevValue : currentValue.price, 0);
                setMaxPriceProduct(max_price);
                setStoreData(store_products);
                if (initial) {
                    setStoreDataLoader(false);
                } else {
                    setAllStoreDataLoader(false);
                }
            });
    }

    useEffect(() => {
        if (storeDataLoader == false && storeData.length > 0) {
            getStoreItems();
        }
    }, [storeDataLoader]);

    // Custom sorting function
    const customSort = (a, b) => {
        // Parse prices as numbers or set them to Infinity if empty or 0
        const priceA = a.price === "" ? Infinity : parseFloat(a.price || 0.0);
        const priceB = b.price === "" ? Infinity : parseFloat(b.price || 0.0);

        // Compare prices
        if (priceA < priceB) {
            return 1;
        } else if (priceA > priceB) {
            return -1;
        } else {
            // If prices are equal, maintain the original order
            return b.id - a.id;
        }
    }

    const loadUserRequestStatusProducts = async () => {
        const check_payload = {
            username: localStorage.getItem("username"),
            action: 'check',
            request_type: 'product_prop',
        };
        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, check_payload).then((res) => {
            setRequestStatusProduct(res.data['request_allowed']);
        });
    };

    const cancelAddProductModal = () => {
        setProductsAddedToScene([])
        setProductsForLibrary([])
        setProductsRestoredToScene([]);
        toggleAddProductModal(false);
        setSearchValue("");
        setDisplayedData(project_ids.length == 0 ? libraryData : originalProjectProductsData);
        setCurrentTabType("5");
        setLibraryType(project_ids.length == 0 ? "library" : "ProjectProducts");
    }

    const openFilters = () => {
        setFiltersDrawer(true);
    };

    const onChangeLibraryType = (e) => {
        setLibraryType(e.target.value);
    }

    const changeSearchValue = (value) => {
        setSearchValue(value);
    }

    const restoreProductsToScene = () => {
        toggleAddProductModal(false);
        let productsList = [];

        // check if restored product is in collection
        productsRestoredToScene.forEach(product => {
            if (!collection_products.includes(product.id.toString())) {
                    let productInfo = {}
                    productInfo["product_id"] = product.id.toString();
                    if (space_areas.length > 0) {
                        productInfo["area_name"] = space_areas[0];
                    }
                    productsList.push(productInfo)
            }
        });

        // if restored product is not in collection add it
        if (productsList.length > 0) {
            let payload = {
                "collection_id": collection_id,
                "products_list": productsList,
                "update_collection_metadata": true,
                'username': CUSTOMER_USERNAME
            }
            if (space_areas.length > 0) {
                payload["space_allocation"] = true
            }
            axios.post(ENVIRONMENT.ADD_PRODUCTS_BATCH_TO_COLLECTION, payload).then(res => {
            });
        }

        restoreProducts(productsRestoredToScene);
        setProductsRestoredToScene([]);
    }

    const addProductsToCollectionAndScene = () => {
        setAddProductsLoader(true);
        toggleAddProductModal(false)
        setSearchValue("");
        setDisplayedData(libraryData);
        setCurrentTabType("5");
        setLibraryType(project_ids.length == 0 ? "library" : "ProjectProducts");
        let productAssetsInfo = []
        productsAddedToScene.forEach(product => {
            let productInfo = {}
            productInfo["product_id"] = product.product_id;
            if (product.area) {
                productInfo["area_name"] = product.area;
            }
            productAssetsInfo.push(productInfo)
        })
        let payload = {
            "collection_id": collection_id,
            "products_list": productAssetsInfo,
            "update_collection_metadata": true,
            'username': CUSTOMER_USERNAME
        }
        if (space_areas.length > 0) {
            payload["space_allocation"] = true
        }
        if (productsForLibrary.length > 0) {
            payload['add_to_library'] = productsForLibrary
            payload['shared_product_type'] = 'added_from_store'
        }
        axios.post(ENVIRONMENT.ADD_PRODUCTS_BATCH_TO_COLLECTION, payload)
            .then(res => {
                if (productsForLibrary.length > 0) {
                    setProductsForLibrary([])
                    refreshProducts();
                }
                if (res.data && res.data.length > 0) {
                    addProductsToScene(productsAddedToScene);
                    updateSceneCollection(res.data)
                    setProductsAddedToScene([])
                    let productsInScene = [];
                    res.data.forEach(product => {
                        productsInScene.push(product.id.toString())
                    })
                    setProductsInScene(productsInScene)
                }
                else {
                    setAddProductsLoader(false);
                    showAddProductsError();
                }
            });
    }

    const refreshProducts = () => {
        loadProductsInScene();
        loadProducts();
    }

    const customSortDeletedProductsData = (a, b) => {
        if (deletedProducts.includes(a.id.toString())) {
            return -1;
        }
        return 1;
    }

    const rearrangeDeletedProductsData = (unsortedData) => {
        let sortedData = unsortedData;
        if (deletedProducts.length > 0) {
            sortedData = sortedData.sort(customSortDeletedProductsData);
        }
        return sortedData;
    }

    const onProductTabChange = (key) => {
        setCurrentTabType(key)
    }

    return (
        <Modal
            destroyOnClose={true}
            closable={true}
            onCancel={cancelAddProductModal}
            title={<span className='manrope f-16 black-33 w-600'>Add Product(s)</span>}
            className="add-product-modal"
            bodyStyle={{ padding: 0 }}
            footer={null}
            visible={add_product_modal}
        >
            <>
                <Row className='pd-30 display-flex'>
                    <AddProductsToSceneContext.Provider
                        value={{
                            loader: loader,
                            filtersApplied: filtersApplied,
                            setFiltersApplied: setFiltersApplied,
                            setAppliedFiltersList: setAppliedFiltersList,
                            appliedFiltersList: appliedFiltersList,
                            displayedData: displayedData,
                            libraryType: libraryType,
                            searchValue: searchValue,
                            space_areas: space_areas,
                            collection_products: productsInScene,
                            deletedProducts: deletedProducts,
                            collection_id: collection_id,
                            productsAddedToScene: productsAddedToScene,
                            setProductsAddedToScene: setProductsAddedToScene,
                            productsRestoredToScene: productsRestoredToScene,
                            setProductsRestoredToScene: setProductsRestoredToScene,
                            filtersDrawer: filtersDrawer,
                            setFiltersDrawer: setFiltersDrawer,
                            productCategoriesData: productCategoriesData,
                            productParentCateogries: productParentCateogries,
                            productMaterialsData: productMaterialsData,
                            onChangeLibraryType: onChangeLibraryType,
                            changeSearchValue: changeSearchValue,
                            openFilters: openFilters,
                            setFilterTagFunction: setFilterTagFunction,
                            filterTagFunction: filterTagFunction,
                            returnSearchValFunction: returnSearchValFunction,
                            setReturnSearchValFunction: setReturnSearchValFunction,
                            refreshProducts: refreshProducts,
                            productsForLibrary: productsForLibrary,
                            setProductsForLibrary: setProductsForLibrary,
                            maxPriceProduct: maxPriceProduct,
                            onProductTabChange: onProductTabChange,
                            currentTabType: currentTabType,
                            storeDataLoader: storeDataLoader,
                            allStoreDataLoader: allStoreDataLoader,
                            requestStatusProduct: requestStatusProduct,
                            initialLoad: initialLoad,
                            originalProjectProductsData: originalProjectProductsData,
                            project_ids: project_ids
                        }}>
                        <ProductSearch />
                        <ProductGridWrapper />
                        <ProductFiltersDrawer />
                    </AddProductsToSceneContext.Provider>
                </Row>
                <Row className={`add-product-modal-footer justify-in-end`} onClick={()=>{
                            if (Object.keys(productsAddedToScene).length > 0) {
                                addProductsToCollectionAndScene()
                            }
                            if (productsRestoredToScene.length > 0) {
                                restoreProductsToScene();
                            }
                        }
                    }
                    >
                    <Button className="modal-okay square font-14" disabled={Object.keys(productsAddedToScene).length === 0 && productsRestoredToScene.length == 0}>Continue</Button>
                </Row>
            </>
        </Modal>
    );

}

export default AddProductsToScene;
