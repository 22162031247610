import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import ENVIRONMENT from '../../../../environments';
import _ from "lodash" // Import the entire lodash library
import * as Utilities from '../../Utilities';
import CollaborateContext from '../../ContextFiles/CollaborateContext';
import CollaborateQAContext from '../../ContextFiles/CollaborateQAContext';
import './CollaborateTool.scss'
import * as Styles from '../../../../Styles';
import { Button, List, Upload, Row, Col, Tooltip, message, Image, Card, Checkbox } from 'antd';
import {
    CheckCircleFilled, CheckCircleOutlined, DeleteOutlined,
    LoadingOutlined, PictureOutlined, SendOutlined
} from '@ant-design/icons';
import * as Constants from '../Constants';
import { getBaseURL, BASE_URI } from '../../../../environments/env';
import { MentionsInput, Mention } from 'react-mentions'
import mentionsInputStyle from './mentionsInputStyle';
import CollaborateConstants from './Constants';
import FileConstants from '../../../../FileConstants';

const progress_bar = Styles.progress_bar;
const isMSProivder = (localStorage.getItem("is_msprovider") == "true");
const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;

const Annotation = (props) => {
    const { canvas_editor, collaboration_id, selected_action,
        tag_user_list, annotateLoader, setAnnotateLoader, setSelectedAction,
        setAnnotationDictionary, annotation_dict, taggedAnnotations, allAnnotations,
        toggleAnnotateDisplay, access_level, collaborators, getCollaborators,
        getCommentHistory, scene_id, platform, selectedComment,
        display_name, setInitialJSON, setSelectedComment,
        product_id, canvasJson, setCanvasJson, setAnnotationDisplayToggle, annotationDisplayToggle } = useContext(props.context_for == "collaborate_qa" ? CollaborateQAContext : CollaborateContext);

    const [userComment, setUserComment] = useState("");
    const [nonParsedUserComment, setNonParsedUserComment] = useState("");
    const [attachments, setAttachments] = useState([]);
    const [taggedUsers, setTaggedUsers] = useState([]);
    const [comments, setComments] = useState([]);
    const [annotationID, setAnnotationID] = useState(-1);
    const [sequenceNo, setSequenceNo] = useState(-1);
    const [deleteAnnotationLoader, setDeleteAnnotationLoader] = useState(false);
    const [resolveAnnotationLoader, setResolveAnnotationLoader] = useState(false);
    const [resolvedState, setResolvedState] = useState(false);
    const [annotationFetchWait, setAnnotationFetchWait] = useState(false);
    const [commentValidations, setCommentValidations] = useState("");
    const [annotationCreated, setAnnotationCreated] = useState(false);
    const [emailCheck, setEmailCheck] = useState(true);

    useEffect(() => {
        openAnnotation();
    }, [annotation_dict, annotationID, sequenceNo, annotationDisplayToggle]);

    useEffect(() => {
        if (canvas_editor && annotationID != -1 && comments.length > 0) {
            canvas_editor.setGroupPosition(annotationID, false)
        }
    }, [comments]);

    useEffect(() => {
        // if annotation is newly created, 
        // then keep the comment box open
        if (annotationCreated) {
            toggleAnnotateDisplay("block");
            setAnnotationCreated(false);
            setAnnotationDisplayToggle(false);
        } else { // otherwise close it
            toggleAnnotateDisplay("none");
            setResolvedState(false);
            setComments([]);
            setAnnotationID(-1);
            setSequenceNo(-1);
        }
        if (canvas_editor && (selected_action == "" || selected_action != "annotate")) {
            canvas_editor.removePrevHotspot();
        }
    }, [selected_action]);

    useEffect(() => {
        // if issue number is clicked, then open annotation comment
        // container
        if (selectedComment != null) {
            setAnnotationDisplayToggle(false);
            setAnnotationID(selectedComment.annotation_id);
            setSequenceNo(selectedComment.sequence_no);
            toggleAnnotateDisplay("block");
            setCommentValidations("");
            canvas_editor.setGroupPosition(selectedComment.annotation_id, selectedComment.is_resolved);
            setSelectedComment(null);
        }
    }, [selectedComment]);


    const openAnnotation = () => {
        if (annotationID != -1) {
            // Get details for annotation id that is clicked
            let annotation_detail = annotation_dict.filter(annotation => (annotation.annotation_id == annotationID && annotation.sequence_no == sequenceNo));
            console.log('Selected Annotation Detail', annotation_detail);
            // if annotation exists, then set these states
            if (annotation_detail.length > 0) {
                setResolvedState(annotation_detail[0]['is_resolved']);
                setComments(annotation_detail[0]['comments']);
                console.log(annotationDisplayToggle)
                if (annotationDisplayToggle) {
                    toggleAnnotateDisplay("none");
                } else {
                    toggleAnnotateDisplay("block");
                }
                setCommentValidations("");
            }
        }
    }

    const getTaggedUserList = (sentence) => {
        let regex = /@\[([^[\]]+)\]/g;
        let matches = sentence.match(regex);
        let userList = [];
        if (matches && matches.length > 0) {
            userList = matches.map(match => match.slice(2, -1));
        }
        return userList;
    }

    const convertSentence = (input) => {
        const regex = /@\[([^[\]]+)\]\([^()]+\)|@\[([^[\]]+)\]/g;
        return input.replace(regex, (_, usernameWithText, usernameWithoutText) => "@" + (usernameWithText || usernameWithoutText));
    }


    const handleMessageChange = (e) => {
        let sentence = convertSentence(e.target.value);
        setNonParsedUserComment(e.target.value);
        setUserComment(sentence);

        let tagged_users = getTaggedUserList(e.target.value);
        setTaggedUsers(tagged_users);
    };


    const createNewAnnotation = () => {
        setAnnotateLoader(true);
        let payload = {
            'created_by': localStorage.getItem('username'),
        }
        if (props.context_for == "collaborate_qa") {
            payload["product_qa_collab_id"] = collaboration_id;
            payload['product_id'] = product_id;
        } else {
            payload["collaboration_id"] = collaboration_id;
        }
        console.log('Create Annotation Payload ', payload);

        canvas_editor.enableAnnotationLoading();
        canvas_editor.disableAllActions();
        axios.post(ENVIRONMENT.ANNOTATION_CREATE, payload)
            .then(res => {
                console.log(res, 'ress')
                if (res && res.data && res.data.body) {
                    let annotation_entity = res.data.body;
                    let annotation_id = res.data.body.annotation_id;
                    let sequence_no = res.data.body.sequence_no;
                    setAnnotationID(annotation_id);
                    setAnnotationDisplayToggle(false);
                    setSequenceNo(sequence_no);
                    canvas_editor.setHotspotIDs(annotation_id, sequence_no);
                    updateExistingAnnotation(annotation_id, annotation_entity, "create");
                }
            });
    };

    const updateExistingAnnotation = (annotation_id = undefined, annotation_entity = undefined, action = "update") => {
        let annotation_id_value = (annotation_id !== undefined ? annotation_id : annotationID)
        setAnnotateLoader(true);
        canvas_editor.clearGroup();
        //setting timeout to make loader appear for a bit and also to make sure state is updated
        setTimeout(() => {
            canvas_editor.enableAnnotationLoading();
            canvas_editor.disableAllActions();
            updateExistingAnnotationPerform(annotation_id_value);

            let userCommentDetails = {
                "annotation_id": annotation_id_value,
                "attachment": attachments,
                "created_by": localStorage.getItem('username'),
                "created_on": Utilities.getDateTime(),
                "id": -1,
                "message": userComment,
                "tagged_users": taggedUsers
            }
            if (annotation_entity != undefined) {
                let dictionary_value = annotation_entity;
                dictionary_value['comments'] = [userCommentDetails];
                setAnnotationDictionary(oldArray => [...oldArray, dictionary_value]);
            } else {
                addCommentToAnnotation(userCommentDetails, annotation_id_value);
            }

            setUserComment('');
            setNonParsedUserComment('');
            setAttachments([]);
            setTaggedUsers([]);
            if (action == "update") {
                toggleAnnotateDisplay("block");
                setCommentValidations("");
            } else {
                setAnnotationCreated(true);
                setSelectedAction('select');
                toggleAnnotateDisplay("block");
            }
            canvas_editor.setGroupPosition(annotation_id_value, false);
            message.success("New Comment Added!");
            setAnnotateLoader(false);

        }, 500);
    }


    const updateExistingAnnotationPerform = (annotation_id) => {
        let emails = collaborators.filter(item => taggedUsers.includes(item.username)).map(item => item.email);
        let send_email = true
        if (isMSProivder) {
            send_email = emailCheck
        }
        let annotation_payload = {
            'annotation_id': annotation_id,
            'message': userComment,
            'attachment': attachments,
            'created_by': localStorage.getItem('username'),
            'tagged_users': taggedUsers,
            'tagged_user_emails': emails,
            'display_name': display_name,
            'email_check' : send_email
        }
        if (props.context_for == "collaborate_qa") {
            annotation_payload["product_qa_collab_id"] = collaboration_id;
            annotation_payload["product_id"] = product_id;
        } else {
            annotation_payload["collaboration_id"] = collaboration_id;
            annotation_payload["scene_id"] = scene_id;
        }
        console.log('Update Annotation Payload', annotation_payload, Utilities.validateFileUploads(attachments));
        setCommentValidations("");
        axios.post(ENVIRONMENT.COMMENT_CREATE, annotation_payload)
            .then(res => {
                getCommentHistory();
                let initial_json = null;
                // json before color reset
                if (props.context_for == "collaborate_qa") {
                    let canvas_data = canvas_editor.convertToJSON();
                    console.log("Canvas data", canvas_data);
                    const dict = { ...canvasJson };
                    dict[collaboration_id] = canvas_data;
                    setCanvasJson(dict);
                    initial_json = canvas_data;
                } else {
                    initial_json = canvas_editor.convertToJSON();
                }

                canvas_editor.changeHotspotColor(allAnnotations);
                // json after color reset
                let state_value = canvas_editor.convertToJSON();
                console.log("adter color reset", state_value)
                canvas_editor.changeHotspotColor(taggedAnnotations, "blue");
                let collab_payload = {
                    'state': state_value,
                    'last_modified_by': localStorage.getItem('username'),
                }
                if (props.context_for == "collaborate_qa") {
                    collab_payload["product_qa_collab_id"] = collaboration_id;
                    collab_payload['action'] = 'update_product_qa_collab';
                    collab_payload['product_id'] = product_id;
                } else {
                    collab_payload["collaboration_id"] = collaboration_id;
                    collab_payload['action'] = 'update_state';
                }
                console.log('Collaboration Update Payload', collab_payload);
                axios.post(ENVIRONMENT.COLLABORATION_ENTITY_UPDATE, collab_payload)
                    .then(res => {
                        setInitialJSON(initial_json)
                        canvas_editor.disableAnnotationLoading();
                        if (taggedUsers.length > 0) {
                            updateCollaborationAccess();
                        }
                    })
            });

    }

    const convertArrayToString = (arr) => {
        if (arr.length === 0) {
            return '';
        } else if (arr.length === 1) {
            return arr[0];
        } else if (arr.length === 2) {
            return arr.join(' and ');
        } else {
            const lastElement = arr.pop();
            const sentence = arr.join(', ') + ', and ' + lastElement;
            return sentence;
        }
    }


    const updateCollaborationAccess = () => {
        let emails = collaborators.filter(item => taggedUsers.includes(item.username) && !CollaborateConstants.edit_access_levels.includes(item.access_level)).map(item => item.email);
        let users = collaborators.filter(item => taggedUsers.includes(item.username) && !CollaborateConstants.edit_access_levels.includes(item.access_level)).map(item => item.username);

        if (emails && emails.length > 0) {
            let payload = {
                'username': localStorage.getItem('username'),
                'emails': emails,
                'access_level': 'edit',
                'send_email': false,
                'display_name': display_name
            };
            if (props.context_for == "collaborate_tool") {
                payload['collaboration_id'] = collaboration_id;
                payload['action'] = 'insert';
                payload['scene_id'] = scene_id
            } else {
                payload['action'] = 'insert_product';
                payload['product_id'] = product_id
            }
            console.log(payload)
            axios.post(ENVIRONMENT.COLLABORATE_ACCESS_CONTROLLER, payload)
                .then(res => {
                    getCollaborators();
                    let usernames = convertArrayToString(users);
                    message.success(usernames + " have been granted edit access!");
                });
        }
    }

    const addCommentToAnnotation = (new_comment, annotation_id) => {
        setAnnotationDictionary((prevAnnotations) => {
            return prevAnnotations.map(annotation => {
                if (annotation.annotation_id === annotation_id) {
                    return {
                        ...annotation,
                        comments: [...annotation.comments, new_comment]
                    };
                }
                return annotation;
            });
        });
    };


    const submitAndSaveState = () => {
        if (userComment != "" || ((attachments.length != 0 && Utilities.validateFileUploads(attachments) == 'done'))) {
            setCommentValidations("");
            if (annotationID != -1) {
                updateExistingAnnotation();
            } else {
                createNewAnnotation();
            }
        } else if (userComment == "" && attachments.length == 0) {
            setAnnotateLoader(false);
            setCommentValidations("Please enter a comment or add an attachment");
        } else if (attachments.length != 0 && Utilities.validateFileUploads(attachments) != 'done') {
            setAnnotateLoader(false);
            setCommentValidations("Please wait. Your upload is in progress.");
        }
    }

    const handleAttachments = (info) => {
        let fileList = [...info.fileList];
        console.log('attachment file list', fileList)
        setAttachments(fileList);
    }

    const handlePostMessage = (event) => {
        if (event && event.data && event.data.annotation_id && event.data.sequence_no) {
            console.log(event.data)
            let annotation_id = event.data.annotation_id;
            let sequence_no = event.data.sequence_no;
            
            setAnnotationID(annotation_id);
            setSequenceNo(sequence_no);
            setAnnotationDisplayToggle(false);
            toggleAnnotateDisplay("block", annotation_id);
            setCommentValidations("");
        }
        if (event && event.data && event.data.clear_comment) {
            setComments([]);
            setAnnotationID(-1);
            setSequenceNo(-1);
            setResolvedState(false);
        }
    };

    const deleteComment = () => {
        setDeleteAnnotationLoader(true);
        let annote_payload = {
            'annotation_id': annotationID,
            'is_hidden': true
        }
        console.log('Delete Annotation Payload', annote_payload);
        axios.post(ENVIRONMENT.ANNOTATION_UPDATE, annote_payload)
            .then(res => {
                getCommentHistory();
                let initial_json = null;
                // remove selected group (annotation) from canvas
                canvas_editor.removeGroup(annotationID);
                // json before color reset
                if (props.context_for == "collaborate_qa") {
                    let canvas_data = canvas_editor.convertToJSON();
                    const dict = { ...canvasJson };
                    dict[collaboration_id] = canvas_data;
                    setCanvasJson(dict);
                    initial_json = canvas_data;
                } else {
                    initial_json = canvas_editor.convertToJSON();
                }
    
                canvas_editor.changeHotspotColor(allAnnotations);
                // json after color reset
                let state_value = canvas_editor.convertToJSON();
                canvas_editor.changeHotspotColor(taggedAnnotations, "blue");
                let collab_payload = {
                    'state': state_value,
                    'last_modified_by': localStorage.getItem('username'),
                }
                if (props.context_for == "collaborate_qa") {
                    collab_payload["product_qa_collab_id"] = collaboration_id;
                    collab_payload['action'] = 'update_product_qa_collab';
                    collab_payload['product_id'] = product_id;

                } else {
                    collab_payload["collaboration_id"] = collaboration_id;
                    collab_payload['action'] = 'update_state';

                }
                console.log('Update Collaboration Payload', collab_payload);
                axios.post(ENVIRONMENT.COLLABORATION_ENTITY_UPDATE, collab_payload)
                    .then(res => {
                        console.log('Delete Annotation Response', res);
                        setInitialJSON(initial_json);
                        toggleAnnotateDisplay("none");
                        setComments([]);
                        setResolvedState(false);
                        setAnnotationID(-1);
                        setSequenceNo(-1);
                        setDeleteAnnotationLoader(false);
                        canvas_editor.clearGroup();
                        message.success("Annotation deleted successfully!");
                    });
            });
    }

    const resolveComment = () => {
        setResolveAnnotationLoader(true);
        let annote_payload = {
            'annotation_id': annotationID,
            'is_resolved': true
        }
        console.log('Resolve Annotation Payload', annote_payload);
        axios.post(ENVIRONMENT.ANNOTATION_UPDATE, annote_payload)
            .then(res => {
                console.log(res);
                canvas_editor.setGroupResolvedState(annotationID);
                canvas_editor.addAnnotationIDsinGroupObject(annotationID, sequenceNo, true);
                canvas_editor.changeHotspotColor(allAnnotations); // change all annotations back to original color
                canvas_editor.changeHotspotColor(allAnnotations, "basic", annotationID); // change selected annotation to green
                
                let state_value = canvas_editor.convertToJSON();
                
                if (props.context_for == "collaborate_qa") {
                    const dict = { ...canvasJson };
                    dict[collaboration_id] = state_value;
                    setCanvasJson(dict);
                }
                canvas_editor.changeHotspotColor(taggedAnnotations, "blue"); // change color back to blue for tagged annotations
                let collab_payload = {
                    'state': state_value,
                    'last_modified_by': localStorage.getItem('username'),
                }
                if (props.context_for == "collaborate_qa") {
                    collab_payload["product_qa_collab_id"] = collaboration_id;
                    collab_payload['action'] = 'update_product_qa_collab';
                    collab_payload['product_id'] = product_id;
                } else {
                    collab_payload["collaboration_id"] = collaboration_id;
                    collab_payload['action'] = 'update_state';

                }
                getCommentHistory();
                canvas_editor.disableAllActions();
                console.log('Collaboration Update Payload', collab_payload);
                axios.post(ENVIRONMENT.COLLABORATION_ENTITY_UPDATE, collab_payload)
                    .then(res => {
                        setInitialJSON(state_value);
                        markAnnotationResolved(annotationID);
                        setResolveAnnotationLoader(false);
                        toggleAnnotateDisplay("none");
                        canvas_editor.clearGroup();
                        setSelectedAction('select');
                        setUserComment('');
                        setNonParsedUserComment('');
                        setAttachments([]);
                        setTaggedUsers([]);
                        message.success("Annotation resolved successfully!");

                    })
            });
    }

    const markAnnotationResolved = (annotation_id) => {
        const updatedAnnotations = annotation_dict.map(annotation => {
            if (annotation.annotation_id === annotation_id) {
                return {
                    ...annotation,
                    is_resolved: true
                };
            }
            return annotation;
        });

        setAnnotationDictionary(updatedAnnotations);
    };

    useEffect(() => {
        // Listen for messages from the Fabric.js canvas
        window.addEventListener('message', handlePostMessage);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('message', handlePostMessage);
        };
    }, [canvas_editor]);

    const onCheckboxToggle = (e) => {
        setEmailCheck(e.target.checked);
    }

    return (
        <div className='annotate-box' id='annotate-modal'>
            {comments.length > 0 && (CollaborateConstants.edit_access_levels.includes(access_level)) && (comments[0].created_by == CUSTOMER_USERNAME || ['owner', 'co-owner'].includes(access_level)) ?
                <Row className='annotate-top-bar display-flex a-c j-e'>
                    <Col>
                        <Tooltip title={<span className='manrope f-12 white'>{resolvedState ? "Resolved" : "Resolve"}</span>}>
                            <span>
                                <Button disabled={resolveAnnotationLoader || resolvedState}
                                    className='action-icon-btn green'
                                    icon={resolveAnnotationLoader ? <LoadingOutlined /> :
                                        resolvedState ? <CheckCircleFilled /> : <CheckCircleOutlined />} ghost onClick={resolveComment} />
                            </span>
                        </Tooltip>
                        <Tooltip title={<span className='manrope f-12 white'>Delete Annotation</span>}>
                            <Button disabled={deleteAnnotationLoader} className='action-icon-btn red' icon={deleteAnnotationLoader ? <LoadingOutlined /> : <DeleteOutlined />} ghost onClick={deleteComment} />
                        </Tooltip>
                    </Col>
                </Row> : ""}
            {comments.length == 0 ? "" : <List
                className={`comment-container ${props.context_for == "collaborate_qa" ? `qa` : ``} custom-scroll`}
                dataSource={comments}
                renderItem={(item) =>
                    <List.Item
                        className='comment-list'>
                        <Row className='comment-box'>
                            <Col span={24} className='display-flex a-c j-s-b mb-8'>
                                <h4 className='manrope f-14 w-700 black-00 mb-0'>
                                    {item.created_by}
                                </h4>
                                <p className='manrope f-14 black-55 mb-0'>
                                    {Utilities.convertToCustomFormat(item.created_on)}
                                </p>

                            </Col>

                            <Col span={24}>
                                <p className='manrope f-14 black-33 text-left mb-0'
                                    dangerouslySetInnerHTML={{ __html: Utilities.makeMentionsBold(item.message) }} />
                            </Col>
                            <Col span={24} className='display-flex a-c j-s wrap'>
                                {item.attachment && item.attachment.length > 0 &&
                                    item.attachment.map((file) => (
                                        <Card className="comment-img-container">
                                            {Utilities.isImage(file.name) ?
                                                <Image className="comment-img" src={getBaseURL(platform) + BASE_URI + encodeURIComponent(file.uid + '/' + file.name)} /> :
                                                <a href={getBaseURL(platform) + BASE_URI + encodeURIComponent(file.uid + '/' + file.name)} target='_blank'>
                                                    <Tooltip title={<span className='manrope f-12 white'>Click to View/Download</span>}>
                                                        <img
                                                            className="comment-img pd-10" src={'/img/file-icon.png'}
                                                        />
                                                    </Tooltip>
                                                </a>}
                                        </Card>
                                    ))
                                }
                            </Col>
                        </Row>
                        <hr />
                    </List.Item>} />}

            {(annotationFetchWait) && (
                // Show the loader when the list is empty
                <Row className="display-flex j-c a-c comment-box">
                    <p className="empty-section-text manrope f-12 black-55 mb-0">
                        Please wait..
                        <LoadingOutlined className='manrope f-12 ml-12' />
                    </p>
                </Row>
            )}

            {((CollaborateConstants.edit_access_levels.includes(access_level)) &&
                !resolvedState) &&
                <Row>
                    <Col span={24} className={`add-comment-bar ${comments.length == 0 ? `pd-0` : ``}`}>
                        <MentionsInput placeholder="Add a comment"
                            className="comment-input-box manrope f-14"
                            value={nonParsedUserComment} onChange={handleMessageChange}
                            style={mentionsInputStyle}
                        >
                            <Mention
                                trigger="@"
                                displayTransform={username => `@${username}`}
                                data={tag_user_list}
                                appendSpaceOnAdd={true}
                            />
                        </MentionsInput>
                        <Col span={24} className='comment-action-container display-flex a-s j-s-b'>
                            <Upload className='comment-upload-box'
                                {...Constants.getUploadProps(platform)}
                                multiple={true} fileList={attachments}
                                onChange={handleAttachments}
                                progress={progress_bar}>
                                <Button disabled={annotateLoader} className='action-icon-btn grey' ghost icon={<PictureOutlined />} />
                            </Upload>
                            <Button disabled={annotateLoader} className='action-icon-btn blue' icon={annotateLoader ? <LoadingOutlined /> : <SendOutlined />} ghost onClick={submitAndSaveState} />
                        </Col>
                        {isMSProivder ?
                        <Col span={24}>
                            <Checkbox
                            style={{
                                marginTop: 8,
                                marginBottom: 8,
                                marginLeft: -15,
                                transform: 'scale(0.8)'}}
                            className="green-checkbox"
                            checked={emailCheck}
                            onChange={onCheckboxToggle}>
                                Notify Through Email
                            </Checkbox>
                        </Col> 
                        : ""}
                        {commentValidations &&
                            <Col span={24}>
                                <p className="manrope f-12 red mb-0 comment-error-msg">
                                    {commentValidations}
                                </p>
                            </Col>}
                    </Col>
                </Row>}
        </div>
    );
}


export default Annotation;
