import React from "react";

class AmazonOauth extends React.Component {

    handleAuthorize = () => {
        const authUrl = "https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.b3115f5f-3b39-4f02-881b-85165e2b7a6b&version=beta&redirect_uri=https://app.all3d.ai/amazon&state=1"; // Replace with the actual URL for Amazon OAuth
        window.location.href = authUrl;
    }

    render() {
        return (
            <div>
                <p>Authorize to Amazon</p>
                <button onClick={this.handleAuthorize}>Authorize</button>
            </div>
        );
    }
}

export default AmazonOauth;
