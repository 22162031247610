import React, { useEffect, useState, useRef } from "react";
import "antd/dist/antd.css";
import CustomerMainLayout from "../CustomerMainLayout";
import {
  FilterOutlined,
  PlusCircleOutlined,
  ShareAltOutlined,
  LoadingOutlined
} from "@ant-design/icons";
import {
  Input,
  Col,
  Row,
  Card,
  Select,
  Radio,
  Form,
  Button,
  Modal,
  Checkbox,
  Tooltip,
  Breadcrumb,
  message,
  Tabs,
} from "antd";
import DottedLoader from "../../DottedLoader";
import "@ant-design/compatible/assets/index.css";
import "../../../../styles/helper.scss";
import ENVIRONMENT from "../../../../environments";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FileConstants from "../../../../FileConstants";
import ProjectsList from "../DashboardProjects/ProjectsList";
import Filter from "../CustomerProject/Filter";

const { TabPane } = Tabs;
const { Option } = Select;
const formRef = { useRef };

let SEQUENCE_ID_OTHER = -1;
let SEQUENCE_ID_OWNED = -1;

const COMPANY_ID = FileConstants.COMPANY_ID;
const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;
const MANAGED_CUSTOMER_USERNAME = FileConstants.MANAGED_CUSTOMER_USERNAME;
const MANAGED_COMPANY_ID = FileConstants.MANAGED_COMPANY_ID;
const MANAGED_CUSTOMER_EMAIL = FileConstants.MANAGED_CUSTOMER_EMAIL;
const IS_MS_PROVIDER = FileConstants.isMSProvider;
const SHARED_ENTITIES = FileConstants.SHARED_ENTITIES;
const SEQUENCE_IDS = FileConstants.SEQUENCE_IDS;

const ProjectLibrary = (props) => {
  const [tabActiveKey, setTabActiveKey] = useState('active');
  const [reviewData, setReviewData] = useState([]);
  const [inProgressData, setInProgressData] = useState([]);
  const [filteredInProgressData, setFilteredInProgressData] = useState([]);
  const [filteredReviewData, setFilteredReviewData] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedModelType, setSelectedModelType] = useState([]);
  const [selectedReviewStatus, setSelectedReviewStatus] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [currentPage, setCurrentPage] = useState(props.match.path)
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [currentProjectId, setCurrentProjectId] = useState(false);
  const [currentProjectAccessLevel, setCurrentProjectAccessLevel] = useState("");

  const tabs = [
    {
      key: 'active',
      title: 'Active Projects',
      data: filteredReviewData
    },
    {
      key: 'requested',
      title: 'Requested Projects',
      data: filteredInProgressData
    },
    {
        key: 'approved',
        title: 'Approved Projects',
        data: filteredData
    }
  ];

  const handleTabChange = (key) => {
    setTabActiveKey(key);
    window.history.pushState(null, null, `?tab=${key}`);
  };

  const getProjectPayload = (type) => {
    let status = "";
    if (type == "approved"){
      status = "status__exact='approved'"
    } else if (type === "requested") {
      status = "status__exact='in_progress'"
    } else {
      status = "status__in=['in_review', 'ready_for_scenes']"
    }
    let payload = {
      order_by: "last_modified_stamp",
      required_fields: [
        "id",
        "name",
        "customer_username",
        "thumbnail",
        "company_id",
        "meta_data",
        "status",
        "last_modified",
        "access_level",
        "last_modified_stamp"
      ],
      filter_string: `(${status})&&(username__exact='${CUSTOMER_USERNAME}')&&(access_level__in=['owner','viewer','co_owner','quality_control', 'editor'])&&(is_hidden__not=true)`,
    };

    return payload;
  };

  const fetchProjectData = async (type) => {
    let payload = getProjectPayload(type);

    await axios.post(ENVIRONMENT.GET_PROJECT_BATCH, payload).then((res) => {
        switch(type) {
            case 'approved':
              setProjectData(res.data);
              setFilteredData(res.data);
            case 'active':
              setReviewData(res.data);
              setFilteredReviewData(res.data);
              break;
            case 'requested':
              setInProgressData(res.data);
              setFilteredInProgressData(res.data);
              break;
          }
    });
  };

  useEffect(() => {
    const fetchAllProjectData = async (type) => {
      setIsDataLoading(true);
      await fetchProjectData('approved');
      await fetchProjectData('active');
      await fetchProjectData('requested');
      setIsDataLoading(false);
    };

    fetchAllProjectData();
  }, []);

  const searchFilter = (searchValue, data) => {
    return data.filter((item) => {
      return item.name.toLowerCase().includes(searchValue.toLowerCase());
    });
  };

  useEffect(() => {
    let filteredData = projectData;
    if (selectedModelType.length > 0) {
      filteredData = filteredData.filter((item) => {
        return selectedModelType.includes(item.entity_type);
      });
    }
    if (selectedReviewStatus.length > 0) {
      filteredData = filteredData.filter((item) => {
        return selectedReviewStatus.includes(item.status);
      });
    }
    if (searchValue.length > 0) {
      filteredData = filteredData.filter((item) => {
        return item.name.toLowerCase().includes(searchValue.toLowerCase());
      });
    }
    setFilteredData(filteredData);

    const filteredInProgressData = searchFilter(searchValue, inProgressData);
    const filteredReviewData = searchFilter(searchValue, reviewData);
    setFilteredInProgressData(filteredInProgressData);
    setFilteredReviewData(filteredReviewData);
  }, [searchValue, selectedModelType, selectedReviewStatus]);

  const handleNewProject = () => {
    props.history.push(`/new_project?origin=${currentPage}`);
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab');
    if (tab) {
      setTabActiveKey(tab);
    }
  }, []);

  const onDelete = () => {
    setDeleteLoader(true);

    let payload = {
        "username": CUSTOMER_USERNAME,
        "project_id": currentProjectId
    }

    if(currentProjectAccessLevel == 'owner' || currentProjectAccessLevel == 'co_owner'){
        payload["action"] = "delete_project"
    }
    else{
        payload["action"] = "hide_project"
    }

    console.log(payload)

    axios.post(ENVIRONMENT.UPDATE_PROJECT, payload)
        .then((response) => {
            message.info('Project Deleted.');
            setDeleteLoader(false);
            setDeleteModal(false);
            window.location.href = 'library-projects?tab=' + tabActiveKey;
        })
        .catch((error) => {
            console.log(error);
            message.error('Error in deleting project');
            setDeleteLoader(false);
            setDeleteModal(false);
        });
  }

  return (
    <div className="my-products">
      <CustomerMainLayout>
        <Row
          className="justify-space-between"
          style={{ marginTop: 16, marginBottom: 16 }}
        >
          <>
            <Col lg={10} xl={10}>
              <div
                className="manrope f-24 w-700"
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                Project Library
              </div>
            </Col>
            <Col lg={12} xl={12} className="justify-in-end">
              <Col lg={15} xl={15}>
                <Form>
                  <Input.Group compact size="large" className="d-inline">
                    <Input
                      placeholder="Search by product, space, etc..."
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                      className="manrope f-14 mat-search-bar"
                      style={{ marginLeft: IS_MS_PROVIDER ? 5 : "" }}
                    ></Input>
                  </Input.Group>
                </Form>
              </Col>
              <Col>
                <Tooltip
                  title={
                    <span className="manrope f-12 white">New Project</span>
                  }
                >
                  <Button
                    onClick = {handleNewProject}
                    className="invite-button ml-8"
                    style={{
                      background: "#D93025",
                      color: "white",
                    }}
                  >
                    <PlusCircleOutlined />
                    New Project
                  </Button>
                </Tooltip>
              </Col>
            </Col>
          </>
        </Row>
        <div>
          <span>
            <Col span={24}>
            <Tabs
                onChange={(key) => handleTabChange(key)}
                className="product-tab library"
                tabBarGutter={16}
                size="large"
                activeKey={tabActiveKey}
            >
                {
                tabs?.map((tab) => {
                    return (
                    <TabPane tab={tab.title + ` (${tab.data.length})` } key={tab.key} style={{ padding: "0px 10px" }}>
                        <div className="product-tab library">
                        {
                            isDataLoading ? <DottedLoader /> : 
                            <ProjectsList 
                            data={tab.data} 
                            setDeleteModal={setDeleteModal}
                            setCurrentProjectId={setCurrentProjectId}
                            setCurrentProjectAccessLevel={setCurrentProjectAccessLevel}
                            />
                        }
                        </div>
                    </TabPane> )
                })
                }
                </Tabs>
            </Col>
          </span>
        </div>
      </CustomerMainLayout>

      <Modal
        className="modal-share-success"
        width={600}
        centered={true}
        visible={deleteModal}
        closable={false}
        footer={[
            <span className="justify-in-center">
                <Button style={{ textAlign: "center" }} className="modal-okay-gray square font-12" onClick={() => setDeleteModal(false)}>
                    Cancel
                </Button>
                <Button style={{ textAlign: "center" }} className="modal-okay square font-12" onClick={() => onDelete()}>
                    {deleteLoader ? <span> Deleting <LoadingOutlined spin /></span> : <span>Yes, Delete Project </span>}
                </Button>
            </span>
        ]}>
        <div style={{ margin: "30px auto 0px auto", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <h2 className="manrope f-16 black-00 w-600">{(currentProjectAccessLevel == 'owner' || currentProjectAccessLevel == "co_owner") ? 
                "You are about to delete this project" : "You are about to Delete this project for your account."}</h2>
            <p className="manrope f-14 black-55">
                {(currentProjectAccessLevel == 'owner' || currentProjectAccessLevel == "co_owner") ? 
                "Since you are the owner of this project, it will be deleted for all users." : "Since you were not the owner of this project, it will still be visible to other users"}
            </p>
        </div>
    </Modal>
    </div>
  );
};

const mapStateToProps = (state) => state;

export default withRouter(connect(mapStateToProps)(ProjectLibrary));
