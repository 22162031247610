import React, { useState, useEffect, createRef, useDebugValue } from 'react';
import { Modal, Card, Button, Image, Tabs, Row, Col, message, Tooltip, Switch, Empty } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';
import ENVIRONMENT from '../../../../environments';
import FileConstants from '../../../../FileConstants'
import * as Utilities from '../../Utilities';
import axios from 'axios';
import GenerateHighResModal from "./GenerateHighResModal"
import ComplaintModal from './ComplaintModal';
import _ from "lodash"

const { TabPane } = Tabs;
const CUSTOMER_USERNAME = localStorage.getItem('username')
const highResModalRef = createRef()

export default function LifestylesList({ projectId, selectedResolutionFilter, 
    setSelectedResolutionFilter,lifestyleRenders = [], projectMetadata, setProjectMetadata, 
    setLifestyleRenders, loadingLifestyles}) {
    
    const [visibleImage, setVisibleImage] = useState([])
    const [expandImage, setExpandImage] = useState(false)
    const [renderGeneration, setRenderGeneration] = useState(true)
    const [deleteLifestyleModal, setDeleteLifestyleModal] = useState(false)
    const [deleteLifestyleLoader, setDeleteLifestyleLoader] = useState(false)
    const [deleteLifestyleSceneId, setDeleteLifestyleSceneId] = useState(null)
    const [deleteLifestyleCameraName, setDeleteLifestyleCameraName] = useState(null)
    const [filteredLifestyleRenders, setFilteredLifestyleRenders] = useState([])
    const [collabButtonLoader, setCollabButtonLoader] = useState(false)
    const [collaborationDisplayName, setCollaborationDisplayName] = useState('')
    const [collaborationCameraName, setCollaborationCameraName] = useState('')
    const [collaborationSceneId, setCollaborationSceneId] = useState(null)
    const [highResModalVisible, setHighResModalVisible] = useState(false)
    const [selectedHighResRender, setSelectedHighResRender] = useState(null)
    const [selectedRenderForComplaint, setSelectedRenderForComplaint] = useState(null)
    const [selectedHighResRenderPlatform, setSelectedHighResRenderPlatform] = useState('aws')
    const [highResSceneId, setHighResSceneId] = useState(null)
    const [showFlaggedTag, setShowFlaggedTag] = useState(null)
    const [confirmHighResModal, setConfirmHighResModal] = useState(false)
    const [whiteBalancedImages, setWhiteBalancedImages] = useState({});
    const [complaintModal, setComplaintModal] = useState(false);
    const [editableRenderNameId, setEditableRenderNameId] = useState(null)
    const [renderNameBeingEdited, setRenderNameBeingEdited] = useState(null)
    const [renderNameButtonLoader, setRenderNameButtonLoader] = useState(false)
    const [rendersGroupedByDate, setRendersGroupedByDate] = useState([])

    useEffect(() => {
        if (selectedResolutionFilter == 'all') {
            setFilteredLifestyleRenders(lifestyleRenders)
        }
        else {
            setFilteredLifestyleRenders(lifestyleRenders.filter(item => item.resolution == selectedResolutionFilter))
        }
    }, [selectedResolutionFilter, lifestyleRenders])

    useEffect(() => {

        filteredLifestyleRenders.sort(function(a,b){
            return new Date(a.time_updated) - new Date(b.time_updated)
        });
    
        let groups = filteredLifestyleRenders.filter((ele) => ele.status != 'hidden' && !ele.template_render).reduce((groups, render) => {
            let date = new Date(render.time_updated).toDateString();
            if (!groups[date]) {
            groups[date] = [];
            }
            groups[date].push(render);
            return groups;
        }, {});
    
    
        // To add it in the array format instead
        let groupArrays = Object.keys(groups).map((date) => {
            return {
            date,
            render: groups[date]
            };
        });
    
        setRendersGroupedByDate(groupArrays)
    }, [filteredLifestyleRenders])
    

    const imageFormat = (img_filename) => {
        let extension = '';
        let format_text = '';
        extension = img_filename.split('.').pop()
        if (extension == 'jpg')
            format_text = 'JPG'
        else if (extension == 'jpeg')
            format_text = 'JPEG'
        else if (extension == 'tiff')
            format_text = 'TIFF'
        else if (extension == 'png')
            format_text = 'PNG'

        return format_text;
    }

    const onImageExpand = (name) => {
        let temp_arr = visibleImage;
        lifestyleRenders.map(x => {
            if (x.unique_filename == name) {
                temp_arr[x.unique_filename] = true;
            } else {
                temp_arr[x.unique_filename] = false;
            }
        });

        setVisibleImage(temp_arr)
        setExpandImage(true)
    }

    const createCollaborateEntity = () => {
        let payload = {
            'scene_id': collaborationSceneId,
            'camera_name': collaborationCameraName,
            'display_name': collaborationDisplayName,
            'created_by': localStorage.getItem('username'),
            'last_modified_by': localStorage.getItem('username'),
            'state': {}
        }
        payload['is_msprovider'] = FileConstants.isMSProvider;

        if (projectId)
            payload['project_id'] = projectId

        axios.post(ENVIRONMENT.CREATE_COLLABORATE_ENTITY, payload)
            .then(res => {
                console.log('Response', res);
                if (res.data && res.data.body) {
                    collaborateCloseConfirmModal();
                    let collaboration_id = res.data.body.collaboration_entity_id;
                    let url = '/collaborate-tool/' + collaboration_id;
                    if (projectId) {
                        url = `${url}?project_id=${projectId}`
                    }
                    let new_window = window.open(url, '_blank');
                    if (new_window) {
                        new_window.focus();
                    } else {
                        window.location.href = url;
                    }
                }
            })
    }

    const openCollaborateTool = () => {
        setCollabButtonLoader(true)

        let payload = {
            'scene_id': collaborationSceneId,
            'camera_name': collaborationCameraName
        }
        axios.post(ENVIRONMENT.GET_COLLABORATE_ENTITY, payload)
            .then(res => {
                console.log('res', res)
                if (res.data && res.data.length == 0) {
                    createCollaborateEntity();
                } else {
                    collaborateCloseConfirmModal();
                    let collaboration_id = res.data.collaboration_entity_id;
                    let url = '/collaborate-tool/' + collaboration_id;
                    if (projectId) {
                        url = `${url}?project_id=${projectId}`
                    }
                    let new_window = window.open(url, '_blank');
                    if (new_window) {
                        new_window.focus();
                    } else {
                        window.location.href = url;
                    }
                }
            });
    }

    const changeVisibility = (visible, prevVisible) => {
        let temp_arr = [];
        if (complaintModal) {
            lifestyleRenders.map(x => {
                temp_arr[x.unique_filename] = false;
            });
            console.log("temp arr", temp_arr)

            setVisibleImage(temp_arr)
            setExpandImage(false)
        }
        else {
            if (expandImage) {
                lifestyleRenders.map(x => {
                    if (visibleImage[x.unique_filename] && !visible) {
                        temp_arr[x.unique_filename] = false;
                    }
                    else if (visibleImage[x.unique_filename] && visible) {
                        temp_arr[x.unique_filename] = true;
                    } else {
                        temp_arr[x.unique_filename] = false;
                    }
                });

                setVisibleImage(temp_arr)
                setExpandImage(false)

            } else {
                lifestyleRenders.map(x => {
                    temp_arr[x.unique_filename] = false;
                });
                setVisibleImage(temp_arr)
                setExpandImage(false)
            }
        }
    }

    const openDeleteLifestyleModal = (filename, scene_id) => {
        setDeleteLifestyleSceneId(scene_id)
        setDeleteLifestyleCameraName(filename)
        setDeleteLifestyleModal(true)
    }

    function getResolutionType(image_width, image_height) {
        const width = parseInt(image_width, 10);
        const height = parseInt(image_height, 10);
        
        const maxDimension = Math.max(width, height);
        if (maxDimension >= 0 && maxDimension <= 1024) {
            return '1K';
        } else if (maxDimension >= 1025 && maxDimension <= 2048) {
            return '2K';
        } else if (maxDimension >= 2049) {
            return '4K';
        }
    }

    const deleteLifestyle = () => {
        setDeleteLifestyleLoader(true)
        let deletedRender = null;
        let cameraToBeDeleted = deleteLifestyleCameraName;
        let renders = lifestyleRenders
            .filter(item => item.scene_id == deleteLifestyleSceneId)
            .map(item => ({ ...item }));

        renders.forEach(item => {
            delete item.request_link
            delete item.unique_filename
            delete item.scene_id
            delete item.resolution
        })

        for (let render of renders) {
            if (render.filename == cameraToBeDeleted) {
                deletedRender = render;
                render['status'] = 'hidden';
                render['deleted_by'] = CUSTOMER_USERNAME;
            }
        }

        let payload = {
            "scene_id": deleteLifestyleSceneId,
            "final_renders": renders
        };

        axios.post(ENVIRONMENT.UPDATE_SCENE, payload)
            .then((response) => {
                let metadata_payload = {"action": 'update_metadata' ,"project_id" : projectId}
                axios.post(ENVIRONMENT.UPDATE_PROJECT, metadata_payload)
            })
            .catch((error) => {
                message.error('Error in deleting Lifestyle Image');
            });

        for (let imageObj of lifestyleRenders) {
            if (imageObj.filename == cameraToBeDeleted && imageObj.scene_id == deleteLifestyleSceneId) {
                imageObj.status = 'hidden';
            }
        }

        let resolution = getResolutionType(deletedRender.image_width, deletedRender.image_height)
        let metadata = _.cloneDeep(projectMetadata);
        metadata['lifestyle_count'][resolution] = metadata['lifestyle_count'][resolution] - 1;
        setProjectMetadata(metadata);

        message.success('Lifestyle Image Deleted.');
        setSelectedResolutionFilter(selectedResolutionFilter);
        setDeleteLifestyleModal(false);
        setDeleteLifestyleLoader(false);
    }

    const isComplaintInprogress = (render) => {
        let complaint_inprogress = false;
        if (render.flagged_by_user) {
            complaint_inprogress = true
            for (let final_render of lifestyleRenders) {
                if (final_render.scene_id == render.scene_id && final_render.revision_of == render.filename) {
                    complaint_inprogress = false;
                    break;
                }
            }
        }
        return complaint_inprogress
    }

    const getCollaborationDisplayName = (camera_name, display_name) => {
        let collab_display_name = display_name;
        if (!display_name) {
            collab_display_name = 'Untitled ' + camera_name.split('.')[0].split('_')[1]
        }
        return collab_display_name;
    }

    const getCollaborateImageFilepath = (final_render) => {
        /* 
        This function returns the image icon name for the collaborate icon based on the status
        of the collaboration i.e. create new, resolved, inprogress
        */
        let collab_icon_filename = '/img/collaborate_tool/new-collab.svg';
        if (final_render.collaboration_id && final_render.collaboration_resolved) {
            // collaboration is resolved
            collab_icon_filename = '/img/collaborate_tool/resolved-collab.svg';
        }
        else if (final_render.collaboration_id && !final_render.collaboration_resolved) {
            collab_icon_filename = '/img/collaborate_tool/in-progress-collab.svg'
        }

        return collab_icon_filename
    }

    const collaborateConfirmModal = (camera_name, display_name, scene_id) => {
        setCollaborationCameraName(camera_name)
        setCollaborationDisplayName(getCollaborationDisplayName(camera_name, display_name))
        setCollaborationSceneId(scene_id)
    }

    const collaborateCloseConfirmModal = () => {
        setCollabButtonLoader(false)
        setCollaborationCameraName('')
        setCollaborationDisplayName('')
        setCollaborationSceneId('')
    }

    const getCollaborationStatusTooltip = (final_render) => {
        /* 
        This function returns the status string for tooltip on collab icon hover
        */
        let collab_status = 'Collaborate';
        if (final_render.collaboration_id && final_render.collaboration_resolved) {
            // collaboration is resolved
            collab_status = 'Collaboration Resolved';
        }
        else if (final_render.collaboration_id && !final_render.collaboration_resolved) {
            collab_status = `Collaboration Inprogress<br/>Total Comments ${final_render.annotation_count || 0}`
        }

        return collab_status
    }

    const addToWhiteBalancedImages = (filename) => {
        let white_balance_images = whiteBalancedImages;
        white_balance_images[filename] = true;
        setWhiteBalancedImages(white_balance_images)
    }

    const removeFromWhiteBalancedImages = (filename) => {
        let white_balance_images = whiteBalancedImages;
        white_balance_images[filename] = false;
        setWhiteBalancedImages(filename)
    }

    const getWhiteBalancedImageUrl = (originalImageLink, whiteBalanceImageFilename) => {
        let url_parts = originalImageLink.split('/')
        url_parts.pop()
        url_parts.push(whiteBalanceImageFilename)
        
        let white_balanced_url = url_parts.join('/')
        console.log('whiete a, ', white_balanced_url)
        return white_balanced_url
    }

    const openComplaintModal = (filename, scene_id, platform) => {
        setExpandImage(false);
        setSelectedRenderForComplaint(filename)
        setHighResSceneId(scene_id);
        setSelectedHighResRenderPlatform(platform)
        setComplaintModal(true);
    }

    const checkIfComplaintInProgress = (data) => {
        if (!data.complaint_status || (data.complaint_status && data.complaint_status != "rejected" && data.complaint_status != "resolved")) {
            return false;
        }
        return true;
    }

    const checkIfFlagIsDisabled = (x) => {
        if (FileConstants.isMSProvider) {
            if (x.flagged_by_user !== undefined) {
                return true
            }
        } else if (x.flagged_by_user !== undefined) {
            if (x.generated_by_admin && x.captured_by != CUSTOMER_USERNAME) {
                return false;
            } else {
                return true;
            }
        }
    }

    const renameSceneRender = (elem_id, render = null) =>{
        setRenderNameButtonLoader(true)
        let scene_render_field = document.getElementById(elem_id);
        if(scene_render_field && scene_render_field.innerText.trim().length)
        {
            // if the render name string does not consist only on white spaces
            scene_render_field.contentEditable = false;
            let scene_render_name = scene_render_field.innerText;

            const renderIndex = lifestyleRenders.findIndex(item=>item.unique_filename==elem_id)
            const lifestyleRendersCopy = [...lifestyleRenders];

            lifestyleRendersCopy[renderIndex]["display_name"] = scene_render_name
            setLifestyleRenders(lifestyleRendersCopy)

            if(scene_render_name != renderNameBeingEdited && scene_render_name != ''){
                let payload = {
                    'scene_id': elem_id.split('_')[0], // elem_id is the unique_filename, which is scene_id before the filename, separated by _
                    'render_name': {}
                }
                payload['render_name'] = {
                    [lifestyleRendersCopy[renderIndex]['filename']]: scene_render_name 
                }
                axios.post(ENVIRONMENT.UPDATE_SCENE, payload)
                .then( (response) => {
                    if(response.status == 200){
                        message.info('Lifestyle Image Renamed Successfully!')
                        setRenderNameButtonLoader(false)
                        setEditableRenderNameId(null)
                    }
                });
            }

            if(scene_render_name == '' || scene_render_name == renderNameBeingEdited){
                // User tried to update empty name, reset the field. Same Behaviour as pressing ESC
                let scene_render_field = document.getElementById(elem_id);
                scene_render_field.innerText = renderNameBeingEdited
                scene_render_field.contentEditable = false;
                setRenderNameButtonLoader(false)
                editableRenderNameId(null)
            }
            setRenderNameBeingEdited('')
        }
        else if(scene_render_field && !scene_render_field.innerText.trim().length){
            // render name only consists on white spaces
            scene_render_field.contentEditable = false;
            scene_render_field.innerText = renderNameBeingEdited
            setEditableRenderNameId(null)
            setRenderNameButtonLoader(false)
        } else {
            setRenderNameButtonLoader(false)
        }
    }

    const handleEnterForSceneRenderRename = (keyCode, id, render) => {
        console.log('in handle rename func');
        if(keyCode == 13){
            // Enter is Pressed
            renameSceneRender(id, render)
        }
        else if(keyCode == 27){
            // ESC is pressed
            let scene_render_field = document.getElementById(id);
            scene_render_field.contentEditable = false;
            scene_render_field.innerText = renderNameBeingEdited;
            
            setEditableRenderNameId(null)
            setRenderNameButtonLoader(false)
        }
    }

    const makeSceneRenderNameEditable = (id) => {
        let scene_render_field = document.getElementById(id);
        scene_render_field.contentEditable = true;
        setEditableRenderNameId(id)
        setRenderNameBeingEdited(scene_render_field.innerText)
    }


    return (<>
    {
        loadingLifestyles ?
        <div>
            <LoadingOutlined style={{ fontSize: 50 }} className="loading-center"/>
        </div>
        :
        filteredLifestyleRenders && filteredLifestyleRenders.length == 0 ?
         <Empty 
        style={{ margin: 'auto', marginTop: 20, marginBottom: 20 }}
        description={`No lifestyles to show`}
        className="manrope f-14"/>
        :
        <Row type="flex" style={{ padding: "0 0", margin: 0, paddingTop: 0,marginTop: 0,display:"flex",justifyContent:'space-between',alignItems:'center'}}>
            {filteredLifestyleRenders && filteredLifestyleRenders.length > 0 ?
            rendersGroupedByDate.slice(0).reverse().map(final_renders => (

                <Col span={24}>
                <Row>
                    <div className="modal-text grey-black font-14" style={{margin: "15px 0"}}>Date Created: <span className='modal-text black'>{final_renders.date}</span></div>
                </Row>
                <Row gutter={[16, 16]} className="justify-in-start" style={{ marginBottom: 10 }}>
                {final_renders.render && final_renders.render.slice(0).reverse().map((x) => (
            (
                x.status == 'rendered' ?
                    <Col span={12} hoverable={true} onMouseEnter={() => { setShowFlaggedTag(x.unique_filename) }} onMouseLeave={() => { setShowFlaggedTag(null) }} className="lifestyle-image-card">
                        <div style={{display: "flex"}}>
                            <h4 className={`scene-render-name${editableRenderNameId == x.unique_filename ? `-editable` : ``}`} id={x.unique_filename} onKeyDown={(e)=>{handleEnterForSceneRenderRename(e.keyCode, x.unique_filename, x)}} style={{marginTop: 11, width: 'fit-content'}}>{x.display_name ? x.display_name : 'Untitled'}</h4>
                            {editableRenderNameId == x.unique_filename ?
                                <Button disabled={renderNameButtonLoader} loading={renderNameButtonLoader} className="save-button font-12" onClick={()=> {renameSceneRender(x.unique_filename, x)}} style={{marginLeft: 15}}>
                                    Save
                                </Button>
                            :
                            <div onClick={()=>{makeSceneRenderNameEditable(x.unique_filename)}} className="edit-icon-data-small edit-icon-blue" >
                                <EditOutlined/>
                            </div>
                            }
                        </div>
                        <Card className="lifestyle-image-card">
                            <div className="position-flag-dim">
                                <div className="resolution-btn">
                                    <span className="category-artist white">{Math.round(x.image_width) + ' x ' + Math.round(x.image_height)}</span>
                                </div>
                                {!x.generated_by_admin && !x.flagged_by_user && !x.flag_rejected_by_admin ? "" :
                                    showFlaggedTag === x.unique_filename && x.flagged_by_user && <div className="flag-tag">
                                        <span className="category-artist red">Flagged</span>
                                    </div>}
                            </div>
                            <div className="img-format-tag">
                                <div className="resolution-btn">
                                    <span className="category-artist white">{imageFormat(x.filename)}</span>
                                </div>
                            </div>
                            <Tooltip title={<span className='manrope f-12 white' dangerouslySetInnerHTML={{ __html: getCollaborationStatusTooltip(x) }}></span>}>
                                <div className={`collaborate-icon ${x.white_balanced_image != undefined ? `` : `right`} ${x.collaboration_resolved ? 'green' : x.collaboration_id == undefined ? 'white' : ''} `} onClick={() => collaborateConfirmModal(x.filename, x.display_name, x.scene_id)}>
                                    <img className='collaborate-img' src={getCollaborateImageFilepath(x)} />
                                </div>
                            </Tooltip>
                            {
                                x.white_balanced_image != undefined ?
                                    <div className="img-auto-white-switch">
                                        <Tooltip title={whiteBalancedImages[x.unique_filename] != undefined && whiteBalancedImages[x.unique_filename] == true ? "Auto White Balanced Image : On" : "Auto White Balanced Image : Off"}>
                                            <Switch onClick={(check) => {
                                                if (check) {
                                                    addToWhiteBalancedImages(x.unique_filename)
                                                }
                                                else {
                                                    removeFromWhiteBalancedImages(x.unique_filename)
                                                }
                                            }} checkedChildren={<img src="/img/white_balance_icon_white.svg"></img>} unCheckedChildren={<img src="/img/white_balance_icon.svg"></img>} />
                                        </Tooltip>
                                    </div>
                                    :
                                    ""
                            }
                            <Image preview={{
                                visible: visibleImage[x.unique_filename],
                                maskClassName: 'customize-mask',
                                mask: (
                                    <span>
                                        {renderGeneration == true ?
                                            <img className="image-thumbnail" src="/img/delete.png" onClick={(e) => { e.preventDefault(); openDeleteLifestyleModal(x.filename, x.scene_id) }} style={{ marginRight: 16 }} />
                                            : ''}
                                        <img className="image-thumbnail" src="/img/expand_thumbnail.png" onClick={(e) => { e.preventDefault(); onImageExpand(x.unique_filename) }} style={{ marginRight: 16 }} />
                                        
                                        <Tooltip title={FileConstants.isMSProvider || !checkIfFlagIsDisabled(x) ? checkIfComplaintInProgress(x) ?
                                                "You can't flag this image unless your complaint is resolved." : 
                                                checkIfFlagIsDisabled(x) ? 
                                                "You cannot flag this image" : "" :
                                                "You cannot flag more than once"}>
                                            <img className="image-thumbnail mr-16" src="/img/flag_thumbnail.png"
                                            onClick={!checkIfFlagIsDisabled(x) || FileConstants.isMSProvider ? 
                                                () => checkIfComplaintInProgress(x) ? "" : 
                                                checkIfFlagIsDisabled(x) ? ()=>{} : 
                                            openComplaintModal(x.filename, x.scene_id, x.platform): ()=>{}}
                                            style={{cursor: checkIfComplaintInProgress(x) || checkIfFlagIsDisabled(x)  ? "not-allowed" : "pointer"}}/>
                                        </Tooltip>
                                        <a onClick={() => {
                                            let download_link = x.request_link
                                            if (x.filename.includes('.tiff')) {
                                                download_link.replace('.jpg', '.tiff')
                                            }
                                            Utilities.downloadFile(download_link, x.filename)
                                        }}>
                                            <img className="image-thumbnail" src="/img/download.png" />
                                        </a>
                                        {/* display generate high res button if the image does not has in_progress collaboration */}
                                        {((x.collaboration_id && x.collaboration_resolved) || x.collaboration_id == null) ?
                                            <div className="thumbnail-btn-bg" onClick={(e) => {
                                                e.preventDefault()
                                                setHighResSceneId(x.scene_id)
                                                setSelectedHighResRender(x)
                                                if (isComplaintInprogress(x)) {
                                                    setConfirmHighResModal(true)
                                                }
                                                else {
                                                    setHighResModalVisible(true)
                                                }
                                            }}><span className="thumbnail-btn">Generate High Resolution Image</span></div>
                                            : (x.collaboration_id && !x.collaboration_resolved) ?
                                            <div className="thumbnail-btn-bg">
                                                <span className="thumbnail-btn">Collaboration in Progress</span>
                                            </div>
                                            : ''}
                                    </span>
                                ),
                                onVisibleChange: changeVisibility,
                            }}
                                className="lifestyle-image-settings" src={whiteBalancedImages[x.unique_filename] ? getWhiteBalancedImageUrl(x.request_link, x.white_balanced_image) : x.request_link}
                            />
                        </Card>
                    </Col>
                    :
                    (x.status == 'pending' && x.filename != '360Camera.jpg') ?
                        <Col span={12}>
                            <div style={{display: "flex"}}>
                                <h4 className={`scene-render-name${editableRenderNameId == x.unique_filename ? `-editable` : ``}`} id={x.unique_filename} onKeyDown={(e)=>{handleEnterForSceneRenderRename(e.keyCode, x.unique_filename, x)}} style={{marginTop: 11, width: 'fit-content'}}>{x.display_name ? x.display_name : 'Untitled'}</h4>
                                {editableRenderNameId == x.unique_filename ?
                                        <Button disabled={renderNameButtonLoader} loading={renderNameButtonLoader} className="save-button font-12" onClick={()=> {renameSceneRender(x.unique_filename, x)}} style={{marginLeft: 15}}>
                                            Save
                                        </Button>
                                    :
                                    <div onClick={()=>{makeSceneRenderNameEditable(x.unique_filename)}} className="edit-icon-data-small edit-icon-blue" >
                                        <EditOutlined/>
                                    </div>
                                }
                            </div>
                            <Card className="lifestyle-image-card">
                                <div className="thumbnail-btn-bg blur"><span className="thumbnail-btn">{x.preview_render.includes("preview") ? 'Render in Progress...' : 'High Resolution Render in Progress...'}</span></div>
                                <Image preview={false}
                                    className="lifestyle-image-settings preview" src={x.preview_render ? x.request_link.split('/').slice(0, -1).concat(x.preview_render.replace('.tiff', '.jpg')).join('/') : ''}
                                />
                            </Card>

                        </Col> : ''
            )
            ))}
            </Row>
            </Col>
            )): ''}
        </Row>
        }

        <Modal
            className="modal-share-success"
            width={600}
            visible={deleteLifestyleModal}
            closable={false}
            footer={[
                <span className="justify-in-center">
                    <Button key="back" style={{ textAlign: "center" }} className="modal-okay-gray square font-14" onClick={() => {
                        setDeleteLifestyleModal(false)
                        setDeleteLifestyleCameraName(null)
                        setDeleteLifestyleSceneId(null)
                    }}>
                        Cancel
                    </Button>
                    <Button disabled={deleteLifestyleLoader} key="back" style={{ textAlign: "center" }} className="modal-okay square font-14" onClick={() => deleteLifestyle()}>
                        {deleteLifestyleLoader ? <span> Deleting <LoadingOutlined spin /></span> : <span> Okay </span>}
                    </Button>
                </span>
            ]}
            centered={true}
        >
            <div style={{ margin: "0px auto 0px auto", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <div>
                    <h2 className="manrope f-16 black-00 w-600">You're about to delete a Lifestyle Image</h2>
                    <p className="manrope f-14 black-55">This will remove your lifestyle image from your scene. Are you sure you want to delete?</p>
                </div>
            </div>
        </Modal>

        <Modal
            className="modal-space"
            width={700}
            visible={collaborationCameraName}
            closable={false}
            bodyStyle={{ padding: 0 }}
            footer={[
                <span className="justify-in-end">
                    <Button disabled={collabButtonLoader} key="back" style={{ textAlign: "center" }} className="modal-okay-gray square font-12" onClick={collaborateCloseConfirmModal}>
                        Cancel
                    </Button>
                    <Button disabled={collabButtonLoader} key="back" style={{ textAlign: "center" }} className="modal-okay square font-12" onClick={openCollaborateTool}>
                        {collabButtonLoader ? <span>Please wait.. <LoadingOutlined spin /></span> : <span>Go to tool </span>}
                    </Button>
                </span>
            ]}
        >
            <div style={{ padding: 30, display: 'flex' }}>
                <Col span={24}>
                    <h2 className="modal-heading manrope f-14 black-00 w-600">Go to collaborate tool? </h2>
                    <p className="manrope f-12 black-33">This tool will allow you to post any feedback you have for this render. </p>
                </Col>
            </div>
        </Modal>


        <GenerateHighResModal confirmHighResModal={confirmHighResModal} setConfirmHighResModal={setConfirmHighResModal} renders={lifestyleRenders} setModalVisible={setHighResModalVisible} modalVisible={highResModalVisible} selectedRender={selectedHighResRender} sceneId={highResSceneId} />
        <ComplaintModal complaintModal={complaintModal} setComplaintModal={setComplaintModal} selectedRender={selectedRenderForComplaint} sceneId={highResSceneId} setSelectedRender={setSelectedRenderForComplaint} renders={lifestyleRenders}
        checkIfComplaintInProgress={checkIfComplaintInProgress} checkIfFlagIsDisabled={checkIfFlagIsDisabled} platform={selectedHighResRenderPlatform} />


    </>)
}