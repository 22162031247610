import React, { useEffect, useState } from 'react';
import { Row, Col, Collapse, Table, Empty } from 'antd';
import FileConstants from '../../../FileConstants';
import ENVIRONMENT from '../../../environments';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import './index.scss'
import _ from "lodash" // Import the entire lodash library

const { Panel } = Collapse;
let cancelToken;

const SystemUsageSummary = (props) => {

    const [requestTypeCount, setRequestTypeCount] = useState([]);

    useEffect(() => {
        if (props.company_id || props.username) {
            getDataSummary();
        }
    }, [props.company_id, props.username, props.start_date, props.end_date, props.date_filter_type])

    const getDataSummary = () => {
        props.setSummaryLoader(true);
        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Previous Operation canceled due to new request.")
        }
        //Save the cancel token for the current request
        cancelToken = axios.CancelToken.source()
        let payload = {};
        payload.action = 'get_counts'
        payload.date_filter_type = props.date_filter_type;
        if (props.company_id != -1) {
            payload.company_id = props.company_id;
        } else {
            payload.customer_username = props.username;
        }
        payload.start_date = props.start_date;
        payload.end_date = props.end_date;
        payload.data_type = "overall";

        axios.post(ENVIRONMENT.GET_SYSTEM_USAGE, payload, { cancelToken: cancelToken.token })
            .then(res => {
                if (res.data) {
                    let request_data_count = props.prepareSystemUsageCounts(res.data);
                    setRequestTypeCount(request_data_count);
                    props.setSummaryLoader(false);
                }
            }).catch(err => {
                console.log(err)
            });
    }

    const getRequestTypeNames = (request_type) => {
        let regex = /(1k|2k|3k|4k)/;
        const containsSearchString = FileConstants.RESOLUTION_REQUESTS.some(searchString => request_type.startsWith(searchString) && !regex.test(request_type));

        if (containsSearchString) {
            const lastIndex = request_type.lastIndexOf("_");
            const beforeLastUnderscore = request_type.substring(0, lastIndex);
            const afterLastUnderscore = request_type.substring(lastIndex + 1);
            return `${FileConstants.setRequestTypeText(beforeLastUnderscore)} (${afterLastUnderscore})`;
        }
        else {
            return FileConstants.setRequestTypeText(request_type);
        }

    }
    const getCountColumns = () => [
        {
            title: <span className="manrope f-14 black-14">Request</span>,
            dataIndex: 'request_type',
            key: 'request_type',
            render: text => <span className="manrope f-12 black-14 capitalize">{getRequestTypeNames(text)}</span>,
        },
        {
            title: <span className="manrope f-14 black-14">Count</span>,
            dataIndex: 'request_count',
            key: 'request_count',
            render: text => <span className="manrope f-12 black-14">{text}</span>,
            sorter: (a, b) => a.request_count - b.request_count,
        }
    ];

    const getRowClassName = (record, index) => {
        return index % 2 === 0 ? 'even-row' : 'odd-row';
    };
    

    return <>{props.display_data ?
        props.summaryLoader ? <div className="justify-in-center" style={{ width: '100%' }}>
            <LoadingOutlined style={{ fontSize: 32, marginTop: 20, marginBottom: 20 }} />
        </div> :
            <Row style={{ marginLeft: 10, marginRight: 10 }}>
                <Col span={24}>
                    <Collapse style={{ marginTop: 20 }} className="collapse-panel bg-f7" ghost expandIconPosition={'right'} accordion>
                        {FileConstants.typesOfServices.map((service, index) => (
                            service == "shared_items" || service == "managed_services" ? '' :
                                <Panel
                                    showArrow={props.totalCount[service] > 0 ? true : false}
                                    style={{ marginBottom: 20 }}
                                    header={<span className="manrope f-14 black-55 w-600">{FileConstants.typeOfDict(service)}&nbsp;({props.totalCount[service]})</span>}
                                    key={service}>
                                    {props.totalCount[service] > 0 ?
                                        <Table pagination={false} columns={getCountColumns()} dataSource={requestTypeCount[service]} 
                                        rowClassName={getRowClassName}
                                        
                                        /> : ""}
                                </Panel>
                        ))}

                    </Collapse>
                </Col>
            </Row> :
        <Empty
            style={{ marginTop: 20, marginBottom: 20 }}
            description={"Billing data for this month does not exist yet."}
            className="manrope f-14"
        />
    }
    </>
}

export default SystemUsageSummary;