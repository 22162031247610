
import React, { useEffect, useState, useRef } from "react";
import "antd/dist/antd.css";
import CustomerMainLayout from "../CustomerMainLayout";
import {
  LoadingOutlined,
  MessageOutlined,
  ArrowLeftOutlined,
  FilterOutlined,
  UserAddOutlined,
  CheckCircleFilled
} from "@ant-design/icons";
import {
  Input,
  Col,
  Row,
  Card,
  Select,
  Radio,
  Form,
  Button,
  Modal,
  Checkbox,
  Tooltip,
  Breadcrumb,
  Tag,
  Tabs,
  Drawer,
} from "antd";
import DottedLoader from "../../DottedLoader";
import "@ant-design/compatible/assets/index.css";
import "../../../../styles/helper.scss";
import ENVIRONMENT from "../../../../environments";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FileConstants from "../../../../FileConstants";
import { Header } from "antd/lib/layout/layout";
import * as Utilities from '../../Utilities';
import EntitiesList from "../CustomerProject/EntitiesList";
import Filters from "../AddProductLifestyleFlow/Filters";

const { TabPane } = Tabs;

const AddNewProjectProducts = (props) => {
  const COMPANY_ID = FileConstants.COMPANY_ID;
  const SHARED_ENTITIES = FileConstants.SHARED_ENTITIES;
  const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;
  const MANAGED_COMPANY_ID = FileConstants.MANAGED_COMPANY_ID;
  const MANAGED_CUSTOMER_USERNAME = localStorage.getItem('managed_customer_username');
  const IS_MS_PROVIDER = localStorage.getItem("is_msprovider") === 'true' ? true : false;
  const [productData, setProductData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [dataLoading, setDataLoading] = useState(false);
  const { } = props;
  const [productFilteredData, setProductFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tabActiveKey, setTabActiveKey] = useState('spaces');
  const [spaceData, setSpaceData] = useState([]);
  const [spaceFilteredData, setSpaceFilteredData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [spaceCategories, setSpaceCategories] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [sceneData, setSceneData] = useState([]);


  const tabs = [
    {
      key: 'spaces',
      title: 'Spaces',
      data: spaceFilteredData,
      setSelected: props.setSelectedRooms,
      selected: props.selectedRooms,
    },
    {
      key: 'products',
      title: 'Products',
      data: productFilteredData,
      setSelected: props.setSelectedProducts,
      selected: props.selectedProducts,
    },
    {
      key: 'scenes',
      title: 'Scenes',
      data: sceneData,
      setSelected: props.setSelectedScenes,
      selected: props.selectedScenes,
    },
  ];

  const getSceneDataWithKeywords = (scenes) => {
    let result = scenes.map(obj => {
      let keywords = []

      if (obj.id) {
        keywords.push(obj.id)
      }

      for (let keyword of obj.name.replace(/[^a-zA-Z0-9 ]/g, " ").split(" ")) {
        if (!keywords.includes(keyword)) {
          if (keyword.length > 1) {
            keywords.push(keyword.toLowerCase())
          }
        }
      }

      obj.tags.map(keyword => {
        if (!keywords.includes(keyword)) {
          if (keyword.length > 1) {
            // should we split tags or not?
            keywords = keywords.concat(keyword.toLowerCase().split(" "))
            // keywords.push(keyword.toLowerCase())  
          }
        }
      })

      return { ...obj, 'keywords': keywords };
    });
    return result
  }

  let getScenePayload = () => {
    let payload = {
      "order_by": "id desc",
      "required_fields": [
        "id", "name", "scene_status", "thumbnail", "designed_for", "company_id",
        "scene_template_id", "render_generation", "collection_id", "customer_username",
        "img_render_count", "three_sixty_count", "threed_tour_count", "platform", "tags",
        "last_modified_stamp"
      ]
    }

    let filter_string = "";
    let scene_status = "scene_status__in=[3]";
    let company_id = "";
    let is_hidden = "is_hidden__not=true"
    let customer_username = `customer_username__exact='${CUSTOMER_USERNAME}'`;
    let scene_template_null_true = "(scene_template_id__isnull=true)"

    if (SHARED_ENTITIES != undefined && COMPANY_ID != undefined && SHARED_ENTITIES.split("_").includes("scene")) {
      company_id = `company_id__exact=${COMPANY_ID}`;
      filter_string = `(${company_id}||${customer_username})&&(${scene_status}&&${is_hidden})&&${scene_template_null_true}`;
    }
    else {
      filter_string = `(${customer_username}&&${scene_status}&&${is_hidden})&&${scene_template_null_true}`;
    }

    filter_string = filter_string + "&&(has_active_project__not=true)"
    payload['filter_string'] = filter_string

    return payload;
  }

  const fetchSceneData = async () => {
    const payload = getScenePayload();
    const data = [];
    await axios.post(ENVIRONMENT.GET_SCENE_BATCH, payload)
      .then((res) => {
        if (res.data) {
          const dataWithKeywords = getSceneDataWithKeywords(res.data);
          dataWithKeywords.length > 0 && dataWithKeywords.map((scene => {
            scene['entity_type'] = "scene";
          }))
          setSceneData(dataWithKeywords)
        }
      });
    return data;
  };

  let getProductPayload = () => {
    let payload = {
      "required_fields": ["id", "name", "brand_id", "category", "customer_username",
        "model_status", "thumbnail", "last_modified_stamp", "is_store_item",
        "dimensions", "company_id", "platform", "product_model_type", "is_shared"],
    }
    payload["order_by"] = "last_modified_stamp desc"
    let filter_string = "";
    let customer_username = "customer_username__exact='" + localStorage.getItem('username') + "'"
    let company_id = "";
    let model_status = "model_status__in=[5]"
    let is_hidden = "is_hidden__not=true"

    if (SHARED_ENTITIES != undefined && COMPANY_ID && SHARED_ENTITIES.split("_").includes("product")) {
      company_id = "company_id__exact=" + parseInt(COMPANY_ID) + "||additional_company_ids__contains='" + parseInt(COMPANY_ID) + "'"
      filter_string = "(" + company_id + "||" + customer_username + ")&&(" + is_hidden + ")"
    } else {
      filter_string = "(" + customer_username + "&&" + is_hidden + ")"
    }
    filter_string = filter_string + "&&(" + model_status + ")" + "&&(has_active_project__not=true)"

    payload['filter_string'] = filter_string

    return payload;
  }

  const fetchProductData = async () => {
    const payload = getProductPayload();
    const data = [];
    await axios.post(ENVIRONMENT.LIST_PRODUCT, payload)
      .then((res) => {
        if (res.data) {
          const dataWithKeywords = Utilities.getProductsDataWithKeywords(res.data);
          dataWithKeywords.length > 0 && dataWithKeywords.map((product => {
            product['entity_type'] = "product";
          }))
          setProductData(dataWithKeywords)
        }
      });
    return data;
  };

  const getRoomsDataWithKeywords = (rooms) => {
    const result = rooms.map((obj) => {
      let keywords = [];

      for (const keyword of obj.room_name.replace(/[^a-zA-Z0-9 ]/g, ' ').split(' ')) {
        if (!keywords.includes(keyword)) {
          if (keyword.length > 1) {
            keywords.push(keyword.toLowerCase());
          }
        }
      }

      for (const keyword of obj.category.replace(/[^a-zA-Z0-9 ]/g, ' ').split(' ')) {
        if (!keywords.includes(keyword)) {
          if (keyword.length > 1) {
            keywords.push(keyword.toLowerCase());
          }
        }
      }

      if (obj?.style && obj.style != '') {
        keywords.push(obj.style.toLowerCase());
      }

      for (const keyword of obj.tags.split(', ')) {
        if (!keywords.includes(keyword)) {
          if (keyword.length > 1) {
            keywords = keywords.concat(keyword.toLowerCase().split(' '));
          }
        }
      }

      if (obj.folder_name) {
        keywords = keywords.concat(obj.folder_name.toLowerCase().split(' '));
      }

      return { ...obj, 'keywords': keywords };
    });
    return result;
  };

  const getRoomPayload = () => {
    let payload = {
      "required_fields": ["room_id", "room_name", "brand_id", "category", "dimensions",
        "customer_username", "model_status", "thumbnail", "last_modified_stamp", "variant_of",
        "is_store_item", "company_id", "platform", "tags"],
    }

    payload["order_by"] = "last_modified_stamp desc"

    let filter_string = "";
    let sequence_ids = localStorage.getItem('sequence_ids')
    let customer_username = "customer_username__exact='" + localStorage.getItem('username') + "'"

    let company_id = "";
    let model_status = "model_status__in=[5]"
    let is_hidden = "is_hidden__not=true"

    if (SHARED_ENTITIES != undefined && COMPANY_ID && SHARED_ENTITIES.split("_").includes("room")) {
      company_id = "company_id__exact=" + COMPANY_ID + "||additional_company_ids__contains='" + COMPANY_ID + "'"
      filter_string = "(" + company_id + "||" + customer_username + ")&&(" + is_hidden + ")"
    } else {
      filter_string = "(" + customer_username + "&&" + is_hidden + ")"
    }
    filter_string = filter_string + "&&(" + model_status + ")" + "&&(has_active_project__not=true)"

    if (sequence_ids) {
      sequence_ids = JSON.parse(sequence_ids)
      sequence_ids.map((item => {
        if (item['entity_type'] == 'owned_space') {
          filter_string = filter_string + "&&(sequence_id__exact=" + item['sequence_id'] + "||sequence_id__isnull=true)"
        }
      }))
    }
    payload['filter_string'] = filter_string
    return payload;
  }

  const fetchRoomData = async () => {
    const payload = getRoomPayload();
    const data = [];
    await axios.post(ENVIRONMENT.LIST_ROOMS, payload)
      .then((res) => {
        if (res.data) {
          const dataWithKeywords = getRoomsDataWithKeywords(res.data);
          dataWithKeywords.length > 0 && dataWithKeywords.map((space => {
            space['entity_type'] = "room";
          }))
          setSpaceData(dataWithKeywords)
        }
      });
    return data;
  };

  useEffect(() => {
    const fetchData = async () => {
      setDataLoading(true);
      await fetchProductData();
      await fetchRoomData();
      await fetchSceneData();
      fetchSpaceCategories();
      fetchProductCategories();
      setDataLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    let filteredData = productData;
    let filteredSpaceData = spaceData;
    if (searchValue.length > 0) {
      filteredData = filteredData.filter((item) => {
        return item.name.toLowerCase().includes(searchValue.toLowerCase());
      });

      filteredSpaceData = filteredSpaceData.filter((item) => {
        return item.room_name.toLowerCase().includes(searchValue.toLowerCase());
      }
      );
    }

    if (selectedFilters.length > 0) {
      filteredData = filteredData.filter((item) => {
        return selectedFilters.includes(item.category);
      });

      filteredSpaceData = filteredSpaceData.filter((item) => {
        return selectedFilters.includes(item.category);
      }
      );
    }

    setProductFilteredData(filteredData);
    setSpaceFilteredData(filteredSpaceData);
  }, [searchValue, selectedFilters]);

  useEffect(() => {
    setProductFilteredData(productData)
    setSpaceFilteredData(spaceData)
  }, [productData, spaceData]);

  const handleCancel = (e) => {
    window.location.href = 'projects';
  }

  const fetchSpaceCategories = () => {
    const payload = {
      request_type: 'room_model',
    };
    axios.post(ENVIRONMENT.GET_CATEGORY_AND_PRICE, payload).then((res) => {
      let categories = [];
      if (res.data) {
        res.data.map((request) => {
          if (!categories.includes(request.category)) {
            categories.push(request.category);
          }
        });
        categories = categories.filter((item) => item !== '');
        setSpaceCategories(categories);
      }
    });
  };

  const fetchProductCategories = () => {
    let payload = {
      "request_type": 'product',
    }

    axios.post(ENVIRONMENT.GET_CATEGORY_AND_PRICE, payload)
      .then(res => {
        if (res.data) {
          let categories = [];
          res.data.map((request) => {
            if (!categories.includes(request.category)) {
              categories.push(request.category);
            }
            categories = categories.filter((item) => item !== '');
            setProductCategories(categories);
          });
        }
      })
  }

  const closeTags = (entity) => {
    const filters = selectedFilters.filter((item) => {
      return entity != item;
    });
    setSelectedFilters(filters);
  };

  const handleTabChange = (key) => {
    setTabActiveKey(key);
  };

  return (
    <div className="spaces-list">
      <Filters
        title={"Add items to your project"}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        searchPlaceholder='Search All Products'
        handleValueChange={setSelectedFilters}
        selectedValue={selectedFilters}
        filters={tabActiveKey == 'spaces' ? spaceCategories : productCategories}
      />
      <Tabs
        onChange={(key) => handleTabChange(key)}
        className="product-tab library"
        tabBarGutter={16}
        size="large"
        activeKey={tabActiveKey}
      >
        {
          tabs?.map((tab) => {
            return (
              <TabPane tab={tab.title + ` (${tab.data.length})`} key={tab.key} style={{ padding: "0px 10px" }}>
                <div className="product-tab library">
                  {selectedFilters.length > 0 && (
                    <Col span={24} style={{ marginLeft: 10, marginBottom: 6 }}>
                      <h4 className="manrope f-14 black-55 italic">
                        Showing results for
                      </h4>
                      <div>
                        {selectedFilters.map((category) => {
                          return (
                            <Tag
                              key={category}
                              onClose={() => closeTags(category, "space_category")}
                              closable
                              visible={true}
                              className="manrope f-12 black-55 tag-filter"
                            >
                              Category:{" "}
                              <span className="manrope f-12 grey-77">{category}</span>
                            </Tag>
                          );
                        })}
                      </div>
                    </Col>
                  )}
                  {
                    dataLoading ? <DottedLoader /> :
                      <EntitiesList
                        data={tab.data}
                        selectable={true}
                        selected={tab.selected}
                        setSelected={tab.setSelected}
                      />
                  }
                </div>
              </TabPane>)
          })
        }
      </Tabs>
      {
        <div className='bottom-menu'>
          {<div className='manrope black-d9 f-16 mr-16'><CheckCircleFilled className='mr-2' /> {props.selectedProducts.length} Products, {props.selectedRooms.length} Rooms, {props.selectedScenes.length} Scenes</div>}
          <Button className="outline-red-btn square font-16" onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={props.handleUpdateProject}
            className={(tabActiveKey == "spaces" && props.selectedRooms.length == 0) || (tabActiveKey == "products" && props.selectedProducts.length == 0) || (tabActiveKey == "scenes" && props.selectedScenes.length == 0) ? 'product-btn f-16 light-blue ml-16 br-4 pd-16' : 'product-btn dark-blue f-16 pd-16 ml-16 br-4'}>
            {(tabActiveKey == "spaces" && props.selectedRooms.length == 0) || (tabActiveKey == "products" && props.selectedProducts.length == 0) || (tabActiveKey == "scenes" && props.selectedScenes.length == 0) ? 'Add Later' : 'Continue'} {props.isLoading ? <LoadingOutlined /> : ''}
          </Button>
        </div>
      }
    </div>
  );
}

const mapStateToProps = (state) => state;

export default withRouter(connect(mapStateToProps)(AddNewProjectProducts));