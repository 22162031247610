import React, { useState, useEffect, useRef } from 'react';
import 'antd/dist/antd.css';
import '@ant-design/compatible/assets/index.css';
import _ from 'lodash'; // Import the entire lodash library
import { Grid, AutoSizer } from 'react-virtualized';
import { Col, Empty, Tag } from 'antd';
import { getRelevanceBasedOrderedSearchResult } from '../../Utilities';
import ENVIRONMENT from '../../../../environments';
import FileConstants from '../../../../FileConstants';
import * as Utilities from '../../Utilities';
import ProductCard from './ProductCard';
import LibraryGrid from '../LibraryGrid/LibraryGrid';

const ProductListVirtualized = (props) => {
    const virtualizerContainer = useRef();
    const previousProductsLength = useRef(null);
    const [currentProductData, setCurrentProductData] = useState([]);
    const [filteredValues, setFilteredValues] = useState([]);
    const [columnCount, setColumnCount] = useState(4);
    const [rowCount, setRowCount] = useState(0);
    const maxColWidth = 280;
    const {
        productsAddedToScene,
        setProductsAddedToScene,
        productsData,
        type,
        searchValue,
        requestPlan,
        filtersApplied,
        filterTagFunction,
        selectedRoom,
        isStore,
        requestStatusRoom,
        returnSearchValFunction,
        appliedFiltersList,
        updateTabCount,
        tabKey
    } = props;
    const filtersHeight = `calc(100vh - ${filtersApplied ? '300px' : '220px'})`;


    const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
        const data = filteredValues;
        const itemIndex = rowIndex * columnCount + columnIndex;
        const ele = data[itemIndex] && data[itemIndex];
        if (!ele) {
            return null;
        }
        const id = ele.id;
        const name = ele.name;
        let thumbnail = ele.thumbnail;
        thumbnail = thumbnail.replace(
            ENVIRONMENT.DEFAULT_BASE_URL,
            ENVIRONMENT.getBaseURL(ele.platform),
        );    

        return (
            <div key={key} style={style}>
                <ProductCard
                    selectedRoom={selectedRoom}
                    setProductsAddedToScene={setProductsAddedToScene}
                    productsAddedToScene={productsAddedToScene}
                    item_type={ele.item_type}
                    id={ele.id}
                    name={ele.name}
                    price={ele.price}
                    customer_username={ele.customer_username}
                    company_display_name={ele.company_display_name}
                    company_id={ele.company_id}
                    getRequestPlan={requestPlan}
                    itemType={type}
                    thumbnail={thumbnail}
                    category={ele.category}
                    subscriptionPlan={''}
                    platform={ele.platform}
                    ele={ele}
                    isStore = {isStore}
                    requestStatusRoom = {requestStatusRoom}
                />
            </div>
        );
    };

    const getDataForProduct = () => {
        // no of rows based on the data length
        let data = [];
        // validationns to apply filters and search
        if (searchValue == '' && !filtersApplied) {
            data = [...productsData];
            if (data.length !== currentProductData.length) {
                updateTabCount(-1, tabKey);
            }
        } 
        else if (searchValue != '' || filtersApplied) {
            data = props.checkFilteredResults(productsData);
            if (data.length == 0 && filtersApplied && searchValue == "" && props.tabChangeThroughClick == false) {
                props.switchTabIfEmpty();
            }
            if (props.tabChangeThroughClick == true && data.length > 0) {
                props.setTabChangeThroughClick(false);
            }
            const newProuctListLength = data.length;
            if (newProuctListLength !== previousProductsLength.current) {
                updateTabCount(newProuctListLength, tabKey);
            }
        }
        // Update the ref after the check
        previousProductsLength.current = data.length;
        setCurrentProductData(data);
        return data;
    }

    useEffect(()=>{
        let data = getDataForProduct();
        setFilteredValues(data);
    }, [searchValue, appliedFiltersList, productsData, filtersApplied, tabKey]);

    useEffect(() => {
        setRowCount(Math.ceil(filteredValues.length / columnCount));
    }, [filteredValues]);

    return (
        <>
            <div
                ref={virtualizerContainer}
                style={{ width: '100%', height: filtersHeight, marginBottom: 144 }}>
                {currentProductData?.length > 0 ?
                    <LibraryGrid
                        setColumnCount = {setColumnCount}
                        setRowCount = {setRowCount}
                        maxColumnWidth = {maxColWidth}
                        data={currentProductData}
                        cellRenderer={cellRenderer}
                        columnCount={columnCount}
                        rowCount={rowCount}
                        getEstimatedRowSize={390}
                    /> :
                    <div className="no-models-div" style={{ marginTop: "25%"}}>
                        <Empty description={"No Models To Show"} className="manrope f-14 no-models-span" />
                    </div>}
            </div>
        </>
    );
};

export default ProductListVirtualized;
