import React from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import $ from 'jquery';
import { ArrowRightOutlined, DownloadOutlined, PaperClipOutlined, LoadingOutlined, DownOutlined, InfoCircleOutlined, CheckCircleOutlined, ShareAltOutlined, CarOutlined, ShoppingCartOutlined, PrinterOutlined, InfoOutlined, FlagOutlined, ArrowLeftOutlined, EditOutlined } from '@ant-design/icons';
import { Dropdown, Row, Col, Button, message, Card, Modal, Tabs, Select, Checkbox, Radio, Form, Tooltip, Image, Input, Upload, Typography, Menu, InputNumber, Empty, Switch } from 'antd';
import CustomerMainLayout from '../CustomerMainLayout';
import DottedLoader from "../../DottedLoader";
import PanoramaThumbnailViewer from '../PanoramaThumbnailViewer';
import VirtualShowroom from '../../../../VirtualShowroom';
import ENVIRONMENT from '../../../../environments';
import FileConstants from '../../../../FileConstants'
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import * as Constants from "../Constants";
import ThreeSixtyViewer from '../../../../ThreeSixtyViewer';
import SuccessModal from '../../SuccessModal/SuccessModal'
import _ from "lodash" // Import the entire lodash library
import { BUCKET_NAME, GET_COMPLAINT_BATCH } from '../../../../environments/env';
import AWS from "aws-sdk";
import * as Utilities from '../../Utilities';
import CardSaveDrawer from '../../CardSaveDrawer/CardSaveDrawer';
import WarningModal from '../../WarningModal/WarningModal';
import PaymentFailureModal from '../../PaymentFailureModal/PaymentFailureModal';
import SubscriptionPackageUpdate from '../../SubscriptionPackageUpdate';
import TemplateRequestModal from '../TemplateRequestModal';
import AddCollectionFlow from '../AddCollectionFlow/AddCollectionFlow';
import ComplaintDetailCard from '../ComplaintDetails/ComplaintDetailCard';
import ComplaintInfo from '../ComplaintDetails/ComplaintInfo';
import PanoramaViewer from '../PanoramaViewer';
import MspWarningModal from '../MspWarningModal/MspWarningModal';
import { LIFESTYLE_TYPE } from './constants';
import SharingModal from '../SharingModal/SharingModal';
import EntityAccessDenied from '../EntityAccessDenied/EntityAccessDenied';

const { Option } = Select;
const { TabPane } = Tabs;
const { confirm } = Modal;
const { Paragraph } = Typography;
const COMPANY_ID = FileConstants.COMPANY_ID;
const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;
const MANAGED_CUSTOMER_USERNAME = FileConstants.MANAGED_CUSTOMER_USERNAME;
const MANAGED_COMPANY_ID = FileConstants.MANAGED_COMPANY_ID;
const MANAGED_CUSTOMER_EMAIL = FileConstants.MANAGED_CUSTOMER_EMAIL;
const SHARED_ENTITIES = FileConstants.SHARED_ENTITIES;
const IS_MS_PROVIDER = FileConstants.isMSProvider;
const QUERY_PARAMS_TO_RETAIN = ['search'];

var sceneInput= '';
var tourInput='';
var imageInput = '';

function saveToZip(filename, downloadData) {
    let loader = message.loading('Preparing to download..', 0);
    const zip = new JSZip();
    const addedFiles = new Set(); // To keep track of added filenames

    downloadData.forEach((file) => {
        const blobPromise = fetch(file['url'], { cache: 'no-store' }).then((r) => {
            if (r.status === 200) return r.blob();
            return Promise.reject(new Error(r.statusText));
        });

        const originalName = file['display_name'];
        let name = originalName;
        let index = 1;

        // Check for duplicate filenames and modify if necessary
        while (addedFiles.has(name)) {
            const extension = originalName.split('.').pop(); // Get file extension
            const fileNameWithoutExtension = originalName.replace(`.${extension}`, '');

            // Append an index to the filename
            name = `${fileNameWithoutExtension}_${index}.${extension}`;
            index++;
        }

        addedFiles.add(name); // Add the modified filename to the set
        zip.file(name, blobPromise);
    });

    zip.generateAsync({ type: 'blob' })
        .then((blob) => {
            saveAs(blob, filename);
            setTimeout(loader);
        })
        .catch((e) => console.log(e));
}

function downloadFile(url, filename) {

    let loader = message.loading('Preparing to download..',0)

    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.setRequestHeader('Cache-Control', 'no-cache');
    xhr.responseType = 'blob';
    xhr.onload = function(e) {
    if (this.status == 200) {
        setTimeout(loader);

        var myBlob = this.response;
        saveAs(myBlob, filename);

    }
    };
    xhr.send();

}

const radioStyleInline = {
    display: 'inline',
    height: '20px',
    lineHeight: '120%'
};

class SceneRenders extends React.Component {

    virtualShowroom = null;
    formRef = React.createRef();
    virtualShowroomFlag = null;
    threeSixtyViews = [];
    state = {
        renders: [],
        isLoading: false,
        isFullScreen: false,
        visible: false,
        embed_visible: false,
        print_ready_visible: false,
        printable_image_request: false,
        name: '',
        edit_permission: false,
        three_sixties: [],
        viewable_three_sixties: [],
        threeSixtyClick: true,
        percent: 0,
        threed_tour: "",
        activeTabKey: "",
        display360: false,
        displayimg: false,
        displayTour: false,
        scene_shared: false,
        scene_embed: false,
        flag_scene_modal: false,
        screenshotSceneList: [],
        screenshotImageList: [],
        screenshotTourList: [],
        checkedScene: [],
        checkSceneState: false,
        selectAllScenesFlag: false,
        selectAllImagesFlag: false,
        flag_image_modal: false,
        checkedImage: [],
        checkImageState: false,
        single_image: false,
        selected_image: '',
        selected_scene: '',
        single_scene: false,
        flag_tour_modal: false,
        username: '',
        buttonLoader: false,
        triggerUpdate: false,
        reportSuccess: false,
        image_visible: false,
        expand_image: false,
        config_modal: false,
        dpi: 300,
        image_format: 'jpg',
        resolution_status: 1,
        resolution_value: '1024x640',
        imageResArr: [],
        aspect_ratio_selected: 1.6,
        orientation: 'Landscape',
        image_width: 1,
        image_height: 1,
        image_width_res: 1,
        image_height_res: 1,
        success_res: false,
        camera_index: 1,
        cameras: [],
        display_width: 1,
        display_height: 1,
        request_image_loader: false,
        design: '',
        render_by_date: [],
        config_exists: -1,
        config_data: {},
        uploading: false,
        errorMessage: '',
        hasError: false,
        deleteLifestyleModal: false,
        deleteLifestyleLoader: false,
        selected_lifestyle_del: '',
        delete360Modal: false,
        delete360Loader: false,
        disabled_images: [],
        disabled_scenes: [],
        confirmation_modal: false,
        scene_name_edit: '',
        button_scene_loader: false,
        editable: false,
        scene_error: '',
        generated_by_flag: false,
        render_names_being_edited: {},
        scene_sharing_option: 'collaborate',
        asset_sharing_option: true,
        collection_id: null,
        space_areas: [],
        collection_name: '',
        sharing_loader: false,
        render_name_being_edited: '',
        editable_render_name_id: '',
        render_name_button_loader: false,
        scene_name: '',
        crypto_exists: false,
        crypto_images: [],
        crypto_360s: [],
        request_status: '',
        card_status: 0,
        current_package : '',
        subscription_plan: '',
        available_tokens : 0,
        used_tokens: 0,
        high_res_price : 0,
        requested_render_categories : ['4k'],
        card_save_failure: false,
        card_save_drawer: false,
        scene_payload: '',
        payment_request_id: -1,
        request_type: 'scene_lifestyle_image',
        payment_failure_message : '',
        payment_failure_modal : false,
        card_save_flow_triggered: false,
        subscription_modal: false,
        subscription_loader: false,
        scene_template_modal: false,
        space_categories: [],
        send_to_store_loader: false,
        bundle_found: false,
        render_generation: true,
        template_scene: false,
        room_id: 0,
        collection_view: false,
        template_renders: [],
        white_balance_renders_switch : {},
        store_render_choices: [],
        render_complaint_details: false,
        complaint_info_lifestyle: [],
        complaint_info_lifestyle_360:[],
        img_flagged_once:{},
        img_360_flagged_once:{},
        selected_render:'',
        complaint_data:[],
        complaint_data_360:[],
        show_flagged_tag: null,
        spaces_checked: false,
        folderParentID: null,
        allowed_flags: "",
        flag_1k: false,
        flag_4k: false,
        platform: '',
        designed_for: '',
        is_subscription_active: false,
        clipping_value : 0,
        edit_modal: false,
        tags: [],
        error_message: '',
        collaborate_camera: '',
        collaborate_display_name:'',
        collab_button_loader: false,
        access_update_loader: false,
        updating_access_email: {},
        company_users: [],
        is_msp_user_warning_modal: false,
        is_wrong_msp_user: false,
        project_id: null,
        public_links: {},
        user_access_level: null
    };

    setUserAccess = (access_level) => {
        this.setState({
            user_access_level: access_level
        })
    }

    getURLParamsAndSetFolderParentID = () => {
        const url = new URL(window.location);
        let id = new URLSearchParams(url.search).get("folder_id");
        this.setState({folderParentID:id});
    }

    checkIfFlagIsDisabled = (render) => {
        // If user is a customer and their scene is designed by an msp
        if (!FileConstants.isMSProvider) {
            // if (this.state.designed_for != '') {
            //     let width = render['image_width']
            //     let height = render['image_height']
            //     if (Utilities.nearestThousand(height) > 1000 || Utilities.nearestThousand(width) > 1000) {
            //         return this.state.flag_4k;
            //     } else {
            //         return this.state.flag_1k;
            //     }
            // } else {
                return this.state.img_flagged_once[render.filename.split('.')[0]]
            // }
        }
        return false;
    }

    setSpaceCategoriesData = () => {
        let payload = {
          'request_type': 'room_model'
        }
        axios.post(ENVIRONMENT.GET_CATEGORY_AND_PRICE,payload)
        .then(res => {
          let categories = []
          if (res.data) {
            res.data.map((request) => {
              if (!categories.includes(request.category)) {
                categories.push(request.category);
              }
            })
           this.setState({
            space_categories: categories
           })
          }
        })
      }

    addToStore = () => {
        this.setState({
            scene_template_modal: true
        });
    }

    sendTemplateRequest = (values) => {
        this.setState({
            send_to_store_loader: true
        });
        let payload = {
            name: values.name,
            room_name: values.room_name,
            style: values.style,
            scene_id: this.props.match.params.id,
            requested_by: localStorage.getItem('username'),
            company_id: FileConstants.isMSProvider ? FileConstants.COMPANY_ID_FOR_TEMPLATES : COMPANY_ID,
            action: 'create',
            status: '2',
        }
        let lifestyle_dict_arr = []
        this.state.renders.map((render) => {
          if (values.lifestyle_renders.includes(render['filename'])) {
            lifestyle_dict_arr.push(render);
          }
        })

        payload.lifestyle_renders = JSON.stringify(lifestyle_dict_arr);

        axios.post(ENVIRONMENT.TEMPLATE_ACTIONS, payload)
        .then(res => {
            this.setState({
                send_to_store_loader: false,
                scene_template_modal: false
            });
            message.success('Successfully Sent!');
            window.location.reload();
        });
    }

    displayFlagIfUnhappyButton = () => {
        if (IS_MS_PROVIDER) {
            if (this.state.render_generation == true && this.state.render_by_date && this.state.render_by_date.length > 0) {
                return true;
            }
        } else {
            // if (this.state.designed_for == '') {
            if (this.state.render_generation == true && this.state.render_by_date && this.state.render_by_date.length > 0) {
                return true;
            }
            // } else {
            //     return false;
            // }
        }

        return false;
    }

    cancelTemplateModal = () => {
        this.setState({
            scene_template_modal: false
        });
    }

    returnPrice = (resolution, request_name, type='price') => {
        let width = resolution.split('x')[0]
        let height = resolution.split('x')[1]
        let category = '[]';
        // if (this.state.prices.length > 0) {
            if (Utilities.nearestThousand(height) == 4000 || Utilities.nearestThousand(width) == 4000 || height >= 4000 || width >= 4000) {
                category = "4k"
            } else if(Utilities.nearestThousand(height) == 3000 || Utilities.nearestThousand(width) == 3000) {
                category = "2k"
            } else if (Utilities.nearestThousand(height) == 2000 || Utilities.nearestThousand(width) == 2000) {
                category = "2k"
            } else if ((Utilities.nearestThousand(height) == 1000 || Utilities.nearestThousand(width) == 1000) && (height >= 1000 || width >= 1000)) {
                category = "1k"
            }
            // console.log(prices)
            // if (prices.length > 0 && prices[0]) {
                if (type == 'price') {
                    // return parseInt(prices[0].price);
                } else {
                    return category;
                }

            // }
        // }
    }


    loadUserRequestStatus = (request_category=null,request_type = null, confirm_request = null) => {
        let payload = {
        "username": localStorage.getItem('username'),
        "action": "check",
        "request_type" : this.state.request_type,
        "categories" : ['4k']
        }
        if (request_category!=null && request_type != null) {
            payload.categories = request_category;
            payload.request_type = request_type;
        }



        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, payload)
        .then(res => {

            this.setState({
                request_status: res.data['request_allowed'],
                card_status: res.data['card_status'],
                current_package: res.data['subscription_plan'],
                available_tokens :res.data['available_lifestyle_tokens'],
                used_tokens:res.data['used_lifestyle_tokens'],
                high_res_price: res.data['price'],
                is_subscription_active: res.data['is_subscription_active']
            })
        });
    }

    onChangeSharingOption = value => {
        this.setState({
            scene_sharing_option: value,
        });
    };

    onChangeAssetSharingOption = e => {

        this.setState({
            asset_sharing_option: e.target.checked,
        });
    }

    setDeleteLifestyleModal = (value) => {
        this.setState({
            deleteLifestyleModal: value
        });
    }

    setDeleteLifestyleLoader = (value) => {
        this.setState({
            deleteLifestyleLoader: value
        });
    }

    insertIntoRenderNameBeingEdited = (id, render_name) => {
        let current_dic = this.state.render_names_being_edited;
        current_dic[id] = render_name
        this.setState({
            render_names_being_edited: current_dic
        })
    }

    getValueForRenderNameBeingEdited = (id) => {
        let render_name = ''
        if(this.state.render_names_being_edited[id])
            render_name = this.state.render_names_being_edited[id];
        return render_name;
    }


    imageFormat = (img_filename) => {

        let extension = '';
        let format_text = '';
        extension = img_filename.split('.').pop()
        if(extension == 'jpg')
            format_text = 'JPG'
        else if(extension == 'jpeg')
            format_text = 'JPEG'
        else if(extension == 'tiff')
            format_text = 'TIFF'
        else if(extension == 'png')
            format_text = 'PNG'

        return format_text;
    }

    makeSceneRenderNameEditable = (id) => {
        let scene_render_field = document.getElementById(id);
        scene_render_field.contentEditable = true;
        this.setState({
            editable_render_name_id: id,
            render_name_being_edited: scene_render_field.innerText
        })
    }

    renameSceneRender = (elem_id, render = null) =>{
        this.setState({
            render_name_button_loader: true
        })
        let scene_render_field = document.getElementById(elem_id);
        if(scene_render_field && scene_render_field.innerText.trim().length)
        {
            // if the render name string does not consist only on white spaces
            scene_render_field.contentEditable = false;
            
            let scene_render_name = scene_render_field.innerText;
            
            const renderIndex = this.state.renders.findIndex(item=>item.filename==elem_id)
            const stateCopy = [...this.state.renders];

            stateCopy[renderIndex]["display_name"] = scene_render_name
            this.setState({renders: stateCopy, ...this.state})

            if(scene_render_name != this.state.render_name_being_edited && scene_render_name != ''){
                let payload = {
                    'scene_id': this.props.match.params.id,
                    'render_name': {}
                }
                payload['render_name'] = {
                    [elem_id]: scene_render_name
                }
                axios.post(ENVIRONMENT.UPDATE_SCENE, payload)
                .then( (response) => {
                    if(response.status == 200){
                        message.info('Lifestyle Image Renamed Successfully!')
                        this.setState({
                            render_name_button_loader: false,
                            editable_render_name_id: null
                        })
                    }
                });
            }

            if(scene_render_name == '' || scene_render_name == this.state.render_name_being_edited){
                // User tried to update empty name, reset the field. Same Behaviour as pressing ESC
                let scene_render_field = document.getElementById(elem_id);
                scene_render_field.innerText = this.state.render_name_being_edited;
                scene_render_field.contentEditable = false;
                this.setState({
                    render_name_button_loader: false,
                    editable_render_name_id: null
                })
            }
            this.setState({
                render_name_being_edited: ''
            })
        }
        else if(scene_render_field && !scene_render_field.innerText.trim().length){
            // render name only consists on white spaces
            scene_render_field.contentEditable = false;
            scene_render_field.innerText = this.state.render_name_being_edited;
            this.setState({
                editable_render_name_id: null,
                render_name_button_loader: false,
            })

        } else {
            this.setState({
                render_name_button_loader: false
            })
        }
    }

    handleEnterForSceneRenderRename = (keyCode, id, render) => {
        console.log('in handle rename func');
        if(keyCode == 13){
            // Enter is Pressed
            this.renameSceneRender(id, render)
        }
        else if(keyCode == 27){
            // ESC is pressed
            let scene_render_field = document.getElementById(id);
            scene_render_field.contentEditable = false;
            scene_render_field.innerText = this.state.render_name_being_edited;
            this.setState({
                editable_render_name_id: null,
                render_name_button_loader: false,
            })

        }
    }

    handleFocusOut = (e) => {
        let id = e.target.id;
        let elem = document.getElementById(id);
        if(elem.contentEditable == "true")
        {
            // Hack for calling the rename function with a payload which will be interpreted as 'Enter' Pressed.
            this.renameSceneRender({
                keyCode: 13,
                target:{
                    id: id
                }
            });
        }
    }

    deleteLifestyle = () => {
        this.setState({
            deleteLifestyleLoader: true
        });
        let cameraToBeDeleted = this.state.selected_lifestyle_del;
        let renders = this.state.renders;

        for (let render of renders) {
            if (render.filename == cameraToBeDeleted) {
                render['status'] = 'hidden';
                render['deleted_by'] = CUSTOMER_USERNAME;
            }
        }


        let payload = {
            "scene_id": this.props.match.params.id,
            "final_renders": renders
        };

        axios.post(ENVIRONMENT.UPDATE_SCENE, payload)
            .then( (response) => {
                message.info('Lifestyle Image Deleted.');
                window.location.href = '/scene_renders/' + this.props.match.params.id;
            })
            .catch((error) => {
                message.error('Error in deleting Lifestyle Image');
            });
    }

    openDeleteLifestyleModal = (camera_name) => {
        this.setState({
            selected_lifestyle_del: camera_name
        }, () => {
            this.setState({
                deleteLifestyleModal: true
            });
        });
    }

    setDelete360Modal = (value) => {
        this.setState({
            delete360Modal: value
        });
    }

    setDelete360Loader = (value) => {
        this.setState({
            delete360Loader: value
        });
    }

    delete360 = () => {
        this.setState({
            delete360Loader: true
        });
        let cameraToBeDeleted = this.state.selected_lifestyle_del;
        let renders = this.state.three_sixties;

        for (let render of renders) {
            if (render.filename.split('.')[0] == cameraToBeDeleted ||render.filename == cameraToBeDeleted) {
                render['status'] = 'hidden';
                render['deleted_by'] = CUSTOMER_USERNAME;
            }
        }

        let payload = {
            "scene_id": this.props.match.params.id,
            "three_sixties": renders
        };
        axios.post(ENVIRONMENT.UPDATE_SCENE, payload)
            .then( (response) => {
                message.info('Lifestyle 360 Image Deleted.');
                window.location.href = '/scene_renders/' + this.props.match.params.id;
            })
            .catch((error) => {
                message.error('Error in deleting Lifestyle 360 Image');
            });
    }

    openDelete360Modal = (camera_name) => {
        this.setState({
            selected_lifestyle_del: camera_name
        }, () => {
            this.setState({
                delete360Modal: true
            });
        });
    }

    escFunction = (event) => {
        if (event.keyCode === 27) {
            this.setState({
                isFullScreen: false
            });
        }
    }

    cancelSuccessModal = () => {
        this.setState({
            success_res: false
        }, () => {
            window.location.reload();
        });
    }

    setResolutions(width,height,type) {
        // calculate high, medium, low resolutions;
        let imgResolutions = [];
		if (type == 'Portrait') {
			let res4k = Math.round(4096 * (width/height));
			let res2k = Math.round(2048 * (width/height));
			imgResolutions.push(res4k + 'x4096'); // high
            imgResolutions.push(res2k + 'x2048'); // medium

		}
		else {
			let res4k = Math.round(4096 * (height/width));
			let res2k = Math.round(2048 * (height/width));
			imgResolutions.push('4096x' + res4k); // high
			imgResolutions.push('2048x' + res2k); // medium
        }

		return imgResolutions;
    }

    onResolutionChange = (e) => {
        this.setState({
            resolution_status: e.target.value
        });
        if (e.target.value == 1) {
            this.setState({
                resolution_value: this.state.imageResArr[0]
            },
            () => {
                let this_resolution =[]
                this_resolution[0] = this.returnPrice(this.state.resolution_value,this.state.request_type, 'category')
                this.loadUserRequestStatus(this_resolution, this.state.request_type, null)
                this.setState({
                    requested_render_categories : this_resolution
                })
            }
            );
        }
        else if (e.target.value == 2) {
            this.setState({
                resolution_value: this.state.imageResArr[1]
            },
            ()=> {
                let this_resolution = []
                this_resolution[0] = this.returnPrice(this.state.resolution_value,this.state.request_type, 'category')
                this.loadUserRequestStatus(this_resolution, this.state.request_type, null)
                this.setState({
                    requested_render_categories : this_resolution
                })
            }
            );
        }
        else if (e.target.value == 4) {
            this.setState({
                image_width: parseInt(this.state.imageResArr[0].split('x')[0]),
                image_height: parseInt(this.state.imageResArr[0].split('x')[1])
            },
            () => {
                let this_resolution =[]
                this_resolution[0] = this.returnPrice(this.state.image_width+"x"+this.state.image_height,this.state.request_type, 'category')
                this.loadUserRequestStatus(this_resolution, this.state.request_type, null)
                    this.setState({
                        requested_render_categories : this_resolution
                })
            });
        }
    }

    onImageWidthChange = (value) => {
        let widthR = 1;
        let heightR = 1;
        let aspect_ratio = 1;
        if (this.state.aspect_ratio_selected == 1.6) {
            widthR = 16;
            heightR = 10;
        } else if (this.state.aspect_ratio_selected == 1.78) {
            widthR = 16;
            heightR = 9;
        } else if (this.state.aspect_ratio_selected == 1.91) {
            widthR = 1.91;
            heightR = 1;
        } else if (this.state.aspect_ratio_selected == 0.56) {
            widthR = 9;
            heightR = 16;
        } else if (this.state.aspect_ratio_selected == 0.63) {
            widthR = 10;
            heightR = 16;
        } else if (this.state.aspect_ratio_selected == 0.52) {
            widthR = 1;
            heightR = 1.91;
        }
        else if (this.state.aspect_ratio_selected == 1) {
            widthR = 1;
            heightR = 1;
        }
        aspect_ratio = widthR / heightR;
        if (typeof value != 'number') {
            value = 1
        }
        this.setState({
            image_width: value,
            image_height: value / aspect_ratio
        },
        () => {
            let this_resolution =[]
            this_resolution[0] = this.returnPrice(this.state.image_width+"x"+this.state.image_height,this.state.request_type, 'category')
            if (this_resolution != "[]"){
                this.loadUserRequestStatus(this_resolution, this.state.request_type, null)
                    this.setState({
                        requested_render_categories : this_resolution
                })
            }
        }
        );
    }

    onImageHeightChange = (value) => {
        let widthR = 1;
        let heightR = 1;
        let aspect_ratio = 1;
        if (this.state.aspect_ratio_selected == 1.6) {
            widthR = 16;
            heightR = 10;
        } else if (this.state.aspect_ratio_selected == 1.78) {
            widthR = 16;
            heightR = 9;
        } else if (this.state.aspect_ratio_selected == 1.91) {
            widthR = 1.91;
            heightR = 1;
        } else if (this.state.aspect_ratio_selected == 0.56) {
            widthR = 9;
            heightR = 16;
        } else if (this.state.aspect_ratio_selected == 0.63) {
            widthR = 10;
            heightR = 16;
        } else if (this.state.aspect_ratio_selected == 0.52) {
            widthR = 1;
            heightR = 1.91;
        }
        else if (this.state.aspect_ratio_selected == 1) {
            widthR = 1;
            heightR = 1;
        }
        aspect_ratio = widthR / heightR;
        if (typeof value != 'number') {
            value = 1
        }
        let recalculated_width = value * aspect_ratio
        this.setState({
            image_width: recalculated_width,
            image_height: value,
        },
        ()=> {
            let this_resolution = []
            this_resolution[0] = this.returnPrice(this.state.image_width+"x"+this.state.image_height,this.state.request_type, 'category')
            if (this_resolution != "[]"){
                this.loadUserRequestStatus(this_resolution, this.state.request_type, null)
                    this.setState({
                        requested_render_categories : this_resolution
                })
            }
        });
    }

    setSubscriptionPlan = (plan) => {
        this.setState({
            subscription_plan: plan
        })
    }

    onChangeWidthResolution = (value) => {
        let new_aspect = 1;
        let res = '';
        if (value == '' || value <= 0 || value >= 8096 || typeof value != 'number') {
            this.setState({
                image_width_res: 1
            });
            new_aspect = 1/this.state.image_height_res;
            res = '1x' + this.state.image_height_res;
        } else {
            this.setState({
                image_width_res: value
            });
            new_aspect = value/this.state.image_height_res;
            res = value + 'x' + this.state.image_height_res;
        }
        this.setState({
            resolution_value: res,
            aspect_ratio_selected: new_aspect
        });
    }

    onChangeHeightResolution = (value) => {
        let new_aspect = 1;
        let res = '';
        if (value == '' || value <= 0 || value >= 8096 || typeof value != 'number') {
            this.setState({
                image_height_res: 1
            });
            new_aspect = this.state.image_width_res/1;
            res = this.state.image_width_res + 'x1';
        } else {
            this.setState({
                image_height_res: value
            });
            new_aspect = this.state.image_width_res/value;
            res = this.state.image_width_res + 'x' + value;
        }
        this.setState({
            resolution_value: res,
            aspect_ratio_selected: new_aspect
        });
    }

    showModal = () => {
        this.setState({
            visible: true,
        }, () => {
            if (this.formRef && this.formRef.current && FileConstants.isMSProvider && (MANAGED_CUSTOMER_USERNAME != null)) {
                this.formRef.current.setFieldsValue({
                    emails:  [MANAGED_CUSTOMER_EMAIL],
                });
            }
        });
    };

    handleOk = e => {
        this.setState({
            visible: false,
        });
    };

    normFile = e => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };

    setScreenshotSceneList = (fileList) => {
        this.setState({
            screenshotSceneList: fileList,
        });
    }

    setScreenshotImageList = (fileList) => {
        this.setState({
            screenshotImageList: fileList,
        });
    }

    setScreenshotTourList = (fileList) => {
        this.setState({
            screenshotTourList: fileList,
        });
    }

    screenshotTourList

    handleSceneScreenshots = info => {
        let fileList = [...info.fileList];
        this.setState({
            screenshotSceneList: fileList,
            uploading: true
        });
        if (info.file.status === "done") {
            this.setState({
                hasError: false,
                uploading: false,
                errorMessage: ''
            });
        } else if (info.file.status === "error") {
            console.log(info.file.error.message,info.file);
            this.setState({
                hasError: true,
                uploading: false,
                errorMessage: info.file.error.message.concat('   ' + info.file.error.message)
            });
            if (info.file.error.code.includes('Credentials')) {
                this.setState({
                    hasError: true,
                    uploading: false,
                    errorMessage: info.file.error.message.concat('. Your session has expired. Please reload the page.')
                });
            }  else if (info.file.error.code.includes('Network')) {
                this.setState({
                    hasError: true,
                    uploading: false,
                    errorMessage: info.file.error.message.concat('. We are unable to upload due to an issue with your internet connection.')
                });
            }

        }
    };

    handleImageScreenshots = info => {
        let fileList = [...info.fileList];
        this.setState({
            screenshotImageList: fileList
        });

        this.setState({
            screenshotImageList: fileList,
            uploading: true
        });
        if (info.file.status === "done") {
            this.setState({
                hasError: false,
                uploading: false,
                errorMessage: ''
            });
        } else if (info.file.status === "error") {
            this.setState({
                hasError: true,
                uploading: false,
                errorMessage: info.file.error.message.concat('   ' + info.file.error.message)
            });
            if (info.file.error.code.includes('Credentials')) {
                this.setState({
                    hasError: true,
                    uploading: false,
                    errorMessage: info.file.error.message.concat('. Your session has expired. Please reload the page.')
                });
            }  else if (info.file.error.code.includes('Network')) {
                this.setState({
                    hasError: true,
                    uploading: false,
                    errorMessage: info.file.error.message.concat('. We are unable to upload due to an issue with your internet connection.')
                });
            }
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    handleTourScreenshots = info => {
        let fileList = [...info.fileList];

        this.setState({
            screenshotTourList: fileList,
            uploading: true
        });
        if (info.file.status === "done") {
            this.setState({
                hasError: false,
                uploading: false,
                errorMessage: ''
            });
        } else if (info.file.status === "error") {
            this.setState({
                hasError: true,
                uploading: false,
                errorMessage: info.file.error.message.concat('   ' + info.file.error.message)
            });
            if (info.file.error.code.includes('Credentials')) {
                this.setState({
                    hasError: true,
                    uploading: false,
                    errorMessage: info.file.error.message.concat('. Your session has expired. Please reload the page.')
                });
            }  else if (info.file.error.code.includes('Network')) {
                this.setState({
                    hasError: true,
                    uploading: false,
                    errorMessage: info.file.error.message.concat('. We are unable to upload due to an issue with your internet connection.')
                });
            }
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    cancelReportSceneIssue = () => {
        sceneInput = '';
        this.setState({triggerUpdate: !this.state.triggerUpdate});
        this.setState({
            flag_scene_modal: false,
            screenshotSceneList: [],
            checkedScene: [],
            checkSceneState: false,
            selectAllScenesFlag: [],
            single_scene: false
            //selected_scene: '',
        });
    }

    cancelReportImageIssue = () => {
        imageInput = '';
        this.setState({
            flag_image_modal: false,
            single_image: false,
            screenshotImageList: [],
            checkedImage: [],
            checkImageState: false,
            selected_image: '',
            selectAllImagesFlag: false
        });
    }

    openConfirmationModal = (name) => {
        this.setState({
            confirmation_modal: true,
            selected_image: name
        });
    }

    openConfigModal = (name) => {
        this.setState({
            confirmation_modal: false
        });

        let temp_arr = [];
        let image_format = 'jpg'
        let image_dpi = 300;
        let image_width = 1;
        let image_height = 1;
        let clipping_value = 0;
        let render_name = name.split(".")[0];
        this.state.renders.map(x=> {
            let filename = x.filename.split(".")[0];
            temp_arr[filename] = false;
            if (filename == render_name) {
                if (x.revision_of != undefined) {
                    this.setState({
                        config_exists: 0,
                    })
                }
                else {
                    if (x.filename.includes(".png")) {
                        image_format = 'png';
                    } else if (x.filename.includes(".jpg")) {
                        image_format = 'jpg';
                    } else if (x.filename.includes(".tiff")) {
                        image_format = 'tiff'
                    }
                    if (x.generated_by_admin) {
                        this.setState({
                            generated_by_flag: true
                        });
                    } else {
                        this.setState({
                            generated_by_flag: false
                        });
                    }
                    image_dpi = x.image_dpi;
                    if (x.image_width > x.image_height) {
                        image_width = parseInt(x.image_width * (4096/x.image_width));
                        image_height =  parseInt(x.image_height * (image_width/x.image_width));
                    } else {
                        image_height = parseInt(x.image_height * (4096/x.image_height));
                        image_width =  parseInt(x.image_width * (image_height/x.image_height));
                    }
                    let original_width = parseInt(x.image_width);
                    let original_height = parseInt(x.image_height);
    
                    this.setState({
                        resolution_value: (image_width.toString() + 'x' + image_height.toString())
                    })
                    this.orientationSettings(image_width,image_height, original_width, original_height);
                    if(x.clipping_value != undefined){
                        clipping_value = x.clipping_value
                        this.setState({
                            clipping_value : clipping_value
                        })
                    }
                    axios.post(ENVIRONMENT.SCENE_CONFIG_GET_BY_CAMERA, {
                        "scene_id" : this.props.match.params.id ,
                        "camera_name": render_name
                    })
                    .then(res => {
                        this.setState({
                            config_data: res.data
                        });
                        if (res.data.scene_config_id) {
                            this.setState({
                                config_exists: 1,
                            });
                        } else {
                            this.setState({
                                config_exists: 0,
                            })
                        }
                    })
                    .catch((error) => {
                        this.setState({
                            config_exists: 0,
                        })
                    });
                }
            }
        });

        this.setState({
            dpi: image_dpi,
            image_format: image_format
        }, () => {
            this.setState({
                image_visible: temp_arr,
                config_modal: true,
                selected_image: name,
            });
        });
        this.loadUserRequestStatus(['4k'], this.state.request_type, null)

    }

    orientationSettings = (width, height, original_width, original_height) => {
        let type = '';
        let ratio = parseFloat((width/height).toFixed(2));
        let resForOrientation = width;
        if (ratio == 0.56 || ratio == 0.63 || ratio == 0.52) {
            resForOrientation = height;
        }

        if (ratio == 1.60 || ratio == 1.78 || ratio == 1.91) {
            type = 'Landscape';
        }
        else if (ratio == 0.56 || ratio == 0.63 || ratio == 0.52) {
            type = 'Portrait';
        }
        else if (ratio == 1) {
           type = 'Square';
        }
        else {
            type = 'Custom';
        }

        if (resForOrientation == '4096') {
            this.setState({
                resolution_status: 1
            });
        } else if (resForOrientation == '2048') {
            this.setState({
                resolution_status: 1
            });
        }  else {
            this.setState({
                resolution_status: 1
            }, () => {
                if (type == 'Portrait') {
                    this.onImageHeightChange(parseFloat(resForOrientation));
                }
                else if (type == 'Square' || type == 'Landscape') {
                    this.onImageWidthChange(parseFloat(resForOrientation));
                }
            });
        }

        this.setState({
            aspect_ratio_selected: ratio,
            orientation: type,
            image_width: width,
            image_height: height,
            image_width_res: width,
            image_height_res: height,
            display_width: original_width,
            display_height: original_height,
            imageResArr:  this.setResolutions(width,height,type)
        });
    }

    onChangeDPI = (e) => {
        this.setState({
            dpi: e.target.value
        });
    }

    onImageFormatChange = (e) => {
        this.setState({
            image_format: e.target.value
        });
    }

    cancelSubscriptionModal = () => {
        this.setState({
            subscription_modal: false
        });
    }

    showSubscriptionModal = () => {
        this.setState({
            subscription_modal: true
        });
    }

    updatePaymentDetailsOnFailure = () => {
        window.addEventListener('card_saved', (e) => {
            let action = e.detail.action;
            this.setState({
                card_save_flow_triggered: false
            });
            if (action == 'save_card') {
              message.success('Payment Method Updated Successfully.')
            }
        }, false);

        this.setPaymentFailureModal(false);
        let child_window = window.open('/save-card?action=save_card', '', "width=600,height=600");
        let refreshInterval = setInterval(() => {
        if(child_window.closed) {
            this.setState({
                card_save_flow_triggered: false,
            });
            clearInterval(refreshInterval);
        }
        }, 500);
        this.setState({
            card_save_flow_triggered: true
        });
    }

    setPaymentFailureModal = (value) => {
        this.setState({
            payment_failure_modal : value
        })
    }

    setPaymentFailureMessage = (value) => {
        this.setState({
            payment_failure_message : value
        })
    }

    cancelConfigModal = () => {
        this.setState({
            config_modal: false,
            request_image_loader: false,
            config_exists: -1
        });
    }

    processImageRequest = (payload) => {

        axios.post(ENVIRONMENT.PAYMENT_GATEWAY, payload)
        .then( (response) => {
            if (response.data.status!=undefined && response.data.status == 1) {
            this.setState({
                success_res: true,
                config_modal: false,
                request_image_loader: false
            });
            }
            else{
                this.setPaymentFailureMessage(response.data.error_message);
                this.setPaymentFailureModal(true);
                this.setState({
                    request_image_loader: false
                })
            }
        })
        .catch((error) => {
            message.error('Error in submitting request!');
            this.setState({
                config_modal: false,
                request_image_loader: false
            });
        });

    }

    requestImage = () => {
        this.setState({
            request_image_loader: true
        });

        let image_format = this.state.image_format;
        let image_width = this.state.image_width;
        let image_height = this.state.image_height;
        let dpi = this.state.dpi;
        let final_renders = this.state.renders;
        let clipping_value = this.state.clipping_value;
        let camera_mode = FileConstants.SCENE_CREATOR_CAMERA_MODES.ThreeD;
        let design_obj = this.state.design;
        let scene_data = design_obj;
        if (scene_data['complaint_id']) {
            delete scene_data['complaint_id'];
        }
        let cameras_info = scene_data['cameras'];

        if (this.state.orientation == 'Custom') {
            image_width = parseFloat(this.state.image_width_res);
            image_height = parseFloat(this.state.image_height_res);
        }
        else if (this.state.resolution_status == 4 && this.state.orientation != 'Custom') {
            image_width = parseFloat(this.state.image_width);
            image_height = parseFloat(this.state.image_height);
        }
        else if (this.state.resolution_status != 4 && this.state.orientation != 'Custom') {
            image_width =  parseFloat(this.state.resolution_value.split('x')[0]);
            image_height = parseFloat(this.state.resolution_value.substring(this.state.resolution_value.indexOf('x') + 1));
        }

        let camera_name = this.placeCustomCamera(image_format, image_width, image_height,parseInt(dpi), clipping_value);
        let selected_camera_name = this.state.selected_image.split(".")[0];

        let configuration_id = _.cloneDeep(this.state.config_data.scene_config_id);
        let asset_info = _.cloneDeep(this.state.config_data.placement_info.assets);
        let light_info = _.cloneDeep(this.state.config_data.placement_info.lights);
        let sun_info = _.cloneDeep(this.state.config_data.placement_info.sun);
        let space_configuration = _.cloneDeep(this.state.config_data.placement_info.space_configuration);
        let scene_placement_state = {
            assets: asset_info,
            sun: sun_info,
            lights: light_info,
            space_configuration: space_configuration
        }
        let placement_cameras = _.cloneDeep(this.state.config_data.placement_info.cameras);
        let new_cameras = _.cloneDeep(this.state.config_data.cameras);
        new_cameras.push(camera_name);
        let new_selected_camera = {};
        for (let camera of placement_cameras) {
            if (camera.camera_name == selected_camera_name) {
                if(camera.clipping_value != undefined){
                    clipping_value = camera.clipping_value
                }
                if (camera.camera_mode != undefined){
                    camera_mode = camera.camera_mode;
                }
                new_selected_camera = {
                    camera_name: camera_name,
                    camera_fov: _.cloneDeep(camera.camera_fov),
                    camera_position: _.cloneDeep(camera.camera_position),
                    camera_target : _.cloneDeep(camera.camera_target),
                    camera_type: _.cloneDeep(camera.camera_type),
                    camera_mode: camera_mode,
                    image_dpi: dpi,
                    image_format: image_format,
                    image_height: Math.round(image_height),
                    image_width: Math.round(image_width),
                    scene_state_index: 0,
                    scene_config_id: configuration_id,
                    clipping_value : clipping_value
                }
            }
        }
        placement_cameras.push(new_selected_camera);
        scene_placement_state['cameras'] = placement_cameras;
        this.handleUpdatingPlacement(new_cameras,scene_placement_state,configuration_id);
        cameras_info.push(new_selected_camera);
        for (let camera of cameras_info) {
            if (camera.camera_name == new_selected_camera.camera_name) {
                camera['selected'] = true;
            } else {
                camera['selected'] = false;
            }
        }

        scene_data["cameras"] = cameras_info



        scene_data["username"] = CUSTOMER_USERNAME;
        final_renders.push({
            filename: camera_name + '.' + image_format,
            image_width: image_width,
            image_height: image_height,
            type: "still",
            captured_by: CUSTOMER_USERNAME,
            is_msprovider: FileConstants.isMSProvider,
            clipping_value : clipping_value,
            camera_mode: camera_mode,
            image_dpi: dpi,
            time_updated: Date.now(),
            preview_render: this.state.selected_image,
            status: 'pending'
        });



        scene_data['generate_360'] = 0;
        scene_data['generate_still'] = 1;

        let payload = {
            "scene_id": this.props.match.params.id,
            "username": CUSTOMER_USERNAME,
            "design": scene_data,
            "final_renders": final_renders,
            "genertate_renders": 1,
        };

        let requestPayload = payload;
        requestPayload['username'] = localStorage.getItem('username');
        let gateway_payload = {
            "username": localStorage.getItem('username'),
            "request_type": this.state.request_type,
            "action": "perform",
            "categories": this.state.requested_render_categories,
            "request_payload": requestPayload,
            "request_id": Date.now().toString()
        }


        this.setState({
            scene_payload: gateway_payload
        });

        if(this.state.request_status == 'allowed' || this.state.request_status =='always_allowed'){
            this.processImageRequest(gateway_payload)
        }
        else if(this.state.request_status == 'update_subscription'){
            this.setState({
                subscription_modal : true,
                config_modal: false,
                request_image_loader: false
            })
        }
        else if(this.state.request_status == 'payment_required'){
            if (this.state.card_status == 1) {
                this.processImageRequest(gateway_payload)
            }
            if (this.state.card_status == 0) {

                window.addEventListener('card_saved', (e) => {
                    let action = e.detail.action;
                    this.setState({
                        card_save_flow_triggered: false
                    });
                    if (action == 'scene_lifestyle_image') {
                        this.setState({
                            card_status: 1
                        }, () => {
                            this.requestImage();
                        })

                    }

                }, false);


                let child_window = window.open('/save-card?action=scene_lifestyle_image', '', "width=600,height=600");
                let refreshInterval = setInterval(() => {
                    if(child_window.closed) {
                        this.setState({
                            card_save_flow_triggered: false,
                        });
                        clearInterval(refreshInterval);
                    }
                }, 500);
                this.setState({
                    card_save_flow_triggered: true
                });
            }

        }
        // Else here for payment required

    }

    handleUpdatingPlacement = (new_cameras, scene_placement_state,scene_config_id) => {

        let payload = {
            scene_configuration_id: scene_config_id,
            cameras: new_cameras,
            placement_info: scene_placement_state
        }


        axios.post(ENVIRONMENT.SCENE_CONFIG_UPDATE, payload)
            .then( (response) => {
                console.log("Success response",response);
                message.success('Updated configuration data...');
            })
            .catch((error) => {
            });

   }

   uploadCustomCameraImageOnAWS = (upload_path, blobData) => 
   {
       console.log('Uploading on S3...')

       var jwt_token = localStorage.getItem('all3d_jwt_token');
       var cognito_credentials = new AWS.CognitoIdentityCredentials({
           IdentityPoolId: 'us-west-2:5f6370ec-44f1-4603-918e-afcdee671162',
           Logins: {
               'cognito-idp.us-west-2.amazonaws.com/us-west-2_dmOay1z0j': jwt_token,
           }
       });
       cognito_credentials.refresh(
       () => {
           AWS.config.update({
               region: 'us-west-2',
               credentials: cognito_credentials
           });
           const S3 = new AWS.S3();
           const objParams = {
               Bucket: ENVIRONMENT.BUCKET_NAME,
               Key: upload_path,
               ACL: 'public-read',
               Body: blobData,
               ContentType: "image/png"
           };

           S3.upload(objParams, function (err, data) {
                if(!err) {
                    console.log("Render preview uploaded successfully");
                }
                else{
                    console.log("Could not upload render preview");
                }
           });
       });
   }

    uploadCustomCameraImageOnGoogle = (upload_path, blobData) =>
   {
       console.log('Uploading on Google...')
       let key = upload_path
       axios.post(ENVIRONMENT.GOOGLE_UPLOAD_CONTROLLER, {
           'action': 'get_asigned_urls',
           'file_uri': key
       })
         .then(res => {
             let signedUrl = res.data;
             const xhr = new XMLHttpRequest();
             xhr.open('PUT', signedUrl, true); 
       
             xhr.onload = function() {
                 console.log(xhr)
                 if (xhr.status === 200) {
                   console.log(xhr);
                   console.log("SEND FINISHED", xhr.response);
                   axios.post(ENVIRONMENT.GOOGLE_UPLOAD_CONTROLLER, {
                     'action': 'make_file_public',
                     'file_uri': key
                   })
                 }
             };
       
             xhr.send(blobData);
         });
   }

    placeCustomCamera = (image_format, image_width, image_height, dpi, clipping_value, camera_name ) => {
        let img_data = ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + camera_name;
        let camera_index = this.state.camera_index;
        var camera_name = "camera_" + camera_index;

        this.addCamera(camera_name,img_data ,image_width,image_height,clipping_value);
        this.setState ({
            camera_index: camera_index + 1,
        });

        let buf = Buffer.from(img_data.replace(/^data:image\/\w+;base64,/, ""),'base64')
        let camera_render_upload_path = "scene_renders/" + this.props.match.params.id + '/' + camera_name + "_preview.png";
        console.log("*****", this.state.platform);
        if(this.state.platform == 'aws'){
            this.uploadCustomCameraImageOnAWS(camera_render_upload_path, buf);
        } else {
            this.uploadCustomCameraImageOnGoogle(camera_render_upload_path, buf);
        }
        return camera_name;
    }

    expandImage = (name) => {
        let temp_arr = this.state.image_visible;
        this.state.renders.map(x=> {
            if (x.filename == name) {
                temp_arr[x.filename.split(".")[0]] = true;
            } else {
                temp_arr[x.filename.split(".")[0]] = false;
            }
        });

        this.setState({
            image_visible: temp_arr,
            expand_image: true
        });
    }

    changeVisibility = (visible, prevVisible) => {
        let temp_arr = [];
        if (this.state.flag_image_modal || this.state.config_modal) {
            this.state.renders.map(x => {
                temp_arr[x.filename.split(".")[0]] = false;
            });

            this.setState({
                image_visible: temp_arr
            });
        }
        else {
            if (this.state.expand_image) {
                this.state.renders.map(x=> {
                    if (this.state.image_visible[x.filename.split(".")[0]] && !visible) {
                        temp_arr[x.filename.split(".")[0]] = false;
                    }
                    else if (this.state.image_visible[x.filename.split(".")[0]] && visible) {
                        temp_arr[x.filename.split(".")[0]] = true;
                    } else {
                        temp_arr[x.filename.split(".")[0]] = false;
                    }
                });

                this.setState({
                    image_visible: temp_arr,
                    expand_image: false
                });
            } else {
                this.state.renders.map(x=> {
                    temp_arr[x.filename.split(".")[0]] = false;
                });
                this.setState({
                    image_visible: temp_arr,
                    expand_image: false
                });
            }
        }

    }

    cancelReportTourIssue = () => {
        tourInput = '';
        this.setState({
            flag_tour_modal: false,
            screenshotTourList: [],
        });
    }

    onChangeFlagSceneCheck = (name) => {
        let area_name = name.filename.split('.')[0];
        if (this.state.checkedScene[area_name]) {
            this.state.checkedScene[area_name] = false;
            this.setState({
                checkedScene: this.state.checkedScene
            });
        }
        else {
            this.state.checkedScene[area_name] = true;
            this.setState({
                checkedScene: this.state.checkedScene
            });
        }

        let flag = false;
        let countTrue = 0;
        let countFalse = 0;
        this.state.viewable_three_sixties.map((name, index) => {
            if (this.state.checkedScene[name.filename.split('.')[0]]) {
                countTrue = countTrue + 1;
                flag= true
                this.setState({
                    checkSceneState: true
                });
            }
            else if (this.state.checkedScene[name.filename.split('.')[0]] == false) {
                countFalse = countFalse + 1;
            }
        });

        if (countTrue == this.state.viewable_three_sixties.length) {
            this.setState({
                selectAllScenesFlag: true
            });
        } else {
            this.setState({
                selectAllScenesFlag: false
            });
        }
        if (countFalse == this.state.viewable_three_sixties.length) {
            this.setState({
                selectAllScenesFlag: false
            });
        }

        if (!flag) {
            this.setState({
                checkSceneState: false
            });
        }
    }

    upgradeSubscriptionPlan = () => {
        if (this.state.card_status == 1) {
            this.upgradeSubscriptionPlanPerform();
        }
        if (this.state.card_status == 0) {
            window.addEventListener('card_saved', (e) => {
                let action = e.detail.action;
                this.setState({
                    card_save_flow_triggered: false
                });
                if (action == 'subscription_update') {
                    this.upgradeSubscriptionPlanPerform();
                }
            }, false);
            let child_window = window.open('/save-card?action=subscription_update', '', "width=600,height=600");
            let refreshInterval = setInterval(() => {
            if(child_window.closed) {
                this.setState({
                    card_save_flow_triggered: false,
                });
                clearInterval(refreshInterval);
            }
            }, 500);
            this.setState({
                card_save_flow_triggered: true
            });
        }
    }

    upgradeSubscriptionPlanPerform = () => {
        this.setState({
            subscription_loader: true
        })

        let payload ={
            username: localStorage.getItem('username'),
            subscription_type: this.state.subscription_plan
        }
        console.log('Subscription payload',payload);
        axios.post(ENVIRONMENT.UPGRADE_SUBSCRIPTION,payload)
        .then( (response) => {
            this.setState({
                subscription_plan: false,
                subscription_modal: false
            })

            this.loadUserRequestStatus(this.state.requested_render_categories,this.state.request_type);
            message.success('Your subscription plan has been successfully upgraded.');
        })
          .catch((error) => {
          });


        // if (this.state.card_status == 1) {
        //     this.processSceneRequest(this.state.scene_payload, final_renders, tour_request);
        // }
        // if (this.state.card_status == 0) {//save card before
        //     this.setState({
        //         scene_payload: gateway_payload,
        //         scene_tour: tour_request,
        //         card_save_drawer: true
        //     })
        // }
    }

    onChangeFlagImageCheck = (name) => {

        let render_name = name.filename.split('.')[0];
        if (this.state.checkedImage[render_name]) {
            this.state.checkedImage[render_name] = false;
            this.setState({
                checkedImage: this.state.checkedImage
            });
        }
        else {
            this.state.checkedImage[render_name] = true;
            this.setState({
                checkedImage: this.state.checkedImage
            });
        }

        let flag = false;
        let countTrue = 0;
        let countFalse = 0;
        let length = 0;
        this.state.renders.map((x, index) => {
            if (x.status == 'rendered' && x.filename != '360Camera.jpg') {
                length = length + 1;
                if (this.state.checkedImage[x.filename.split('.')[0]]) {
                    countTrue = countTrue + 1;
                    flag = true
                    this.setState({
                        checkImageState: true
                    });
                }
                else if (this.state.checkedImage[x.filename.split('.')[0]] == false) {
                    countFalse = countFalse + 1;
                }
            }
        });

        if (countTrue == length) {
            this.setState({
                selectAllImagesFlag: true
            });
        } else {
            this.setState({
                selectAllImagesFlag: false
            });
        }
        if (countFalse == length) {
            this.setState({
                selectAllImagesFlag: false
            });
        }

        if (!flag) {
            this.setState({
                checkImageState: false
            });
        }
    }

    selectAllScenes = () => {
        if (this.state.selectAllScenesFlag == false){
            this.state.viewable_three_sixties.map((name, index) => {
                this.state.checkedScene[name.filename.split('.')[0]] = true;
            });

            this.setState({
                checkSceneState: true,
                checkedScene: this.state.checkedScene,
                selectAllScenesFlag: true
            });
        }
        else {
            this.state.viewable_three_sixties.map((name, index) => {
                this.state.checkedScene[name.filename.split('.')[0]] = false;
            });

            this.setState({
                checkSceneState: false,
                checkedScene: this.state.checkedScene,
                selectAllScenesFlag: false
            });
        }
    }

    selectAllImages = () => {
        if (this.state.selectAllImagesFlag == false){
            this.state.renders.map((x, index) => {
                if (FileConstants.isMSProvider) {
                    if (!x.template_render && (this.hasComplaintResolved(x.filename, LIFESTYLE_TYPE.lifestyle_images).length===0) && (x.status == 'rendered' && x.filename != '360Camera.jpg')) {
                        this.state.checkedImage[x.filename.split('.')[0]] = true;
                    }
                }
                else {
                    if (((x.flagged_by_user===undefined || (!this.state.img_flagged_once[x.filename.split('.')[0]] ) ) && x.status == 'rendered' && x.filename != '360Camera.jpg') && !this.state.disabled_images.includes(x.filename.split('.')[0])) {
                        this.state.checkedImage[x.filename.split('.')[0]] = true;
                    }
                }
            });

            this.setState({
                checkImageState: true,
                checkedImage: this.state.checkedImage,
                selectAllImagesFlag: true
            });
        }
        else {
            this.state.renders.map((name, index) => {
                if (((name.flagged_by_user===undefined || ( FileConstants.isMSProvider || !this.state.img_flagged_once[name.filename.split('.')[0]] ) ) && name.status == 'rendered' && name.filename != '360Camera.jpg') && !this.state.disabled_images.includes(name.filename.split('.')[0])) {
                    this.state.checkedImage[name.filename.split('.').slice(0, -1).join('.')] = false;
                }
            });

            this.setState({
                checkImageState: false,
                checkedImage: this.state.checkedImage,
                selectAllImagesFlag: false
            });
        }
    }

    handleEmbedOk = e => {
        this.setState({
            embed_visible: false,
            scene_embed: true
        });
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val($("#iframe-text").text()).select();
        document.execCommand("copy");
        $temp.remove();
        message.success('Embed link copied to clipboard!');
    };

    handleGetPrintReadyImagesOk = e => {
        this.setState({
            print_ready_visible: false,
            printable_image_request: true
        });
        this.generatePrintReadyImages()
    }
    
    generatePrintReadyImages = () => {
        let payload = {
        username: CUSTOMER_USERNAME,
        entity_type: "scene",
        entity_id: this.props.match.params.id,
        };
        console.log(payload)
        axios.post(ENVIRONMENT.GENERATE_PRINT_READY_IMAGES,payload)
        .then(res => {
            console.log('Response',res.data)
        })
    }

    showEmbedModel = () => {
        this.setState({
            embed_visible: true,
        });
    }

    showPrintReadyModal = () => {
        this.setState({
            print_ready_visible: true,
        });
    }

    sceneShare = () => {
        this.setState({
            scene_shared: false
        })
    }

    printableImagesRequest = () => {
        this.setState({
          printable_image_request: false,
        });
    }

    getDisplayName = (item) => {
        let filename = null;
        if (item.display_name)
        {
            filename= item.display_name + '.jpg';
        } else {
            filename = item.filename;
        }
        return filename;
    }

    downloadSceneImagesArchive = () => {
        let downloadData = [];
        if (this.state.viewable_three_sixties != undefined) {
            if (this.state.viewable_three_sixties.length == 1) {
                if (this.state.viewable_three_sixties[0].status == 'rendered') {
                    let fileData = {};
                    fileData['url'] = (ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + this.state.viewable_three_sixties[0].filename);
                    fileData['display_name'] = this.getDisplayName(this.state.viewable_three_sixties[0]);
                    downloadData.push(fileData);
                }
            }
            else if (this.state.viewable_three_sixties.length > 0) {
                this.state.viewable_three_sixties.map((url) => {
                    if (url.status == 'rendered') {
                        let fileData = {};
                        fileData['url'] = (ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + url.filename);
                        fileData['display_name'] = this.getDisplayName(url);
                        downloadData.push(fileData);
                    }
                });
            }
        }
        saveToZip (this.props.match.params.id + '_Scene 360 Images.zip', downloadData);
    }


    downloadCrypto360Archive = () => {
        if (this.state.crypto_exists) {
            let downloadData = [];
            if (this.state.viewable_three_sixties != undefined) {
                if (this.state.viewable_three_sixties.length == 1) {
                    let displayName = this.getDisplayName(this.state.viewable_three_sixties[0]);
                    let display_name = displayName.split('.');
                    let display_name_w_format = display_name[0] +  '_cMatte.jpg'
                    let image_name = this.state.viewable_three_sixties[0].filename.split('.');
                    let image_name_w_format = image_name[0] + '_cMatte.jpg'
                    let new_url = ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + image_name_w_format;
                    if (this.state.viewable_three_sixties[0].status == 'rendered') {
                        let fileData = {};
                        fileData['url'] = new_url;
                        fileData['display_name'] = display_name_w_format;
                        downloadData.push(fileData);
                        saveToZip (this.props.match.params.id + '_Scene 360 Cryptomatte.zip', downloadData);
                    }


                }
                else if (this.state.viewable_three_sixties.length > 0) {
                    if (this.state.crypto_360s && this.state.crypto_360s.length > 0) {
                        this.state.viewable_three_sixties.map((url,index) => {
                            if (this.state.crypto_360s.includes(index)) {
                                let displayName = this.getDisplayName(url);
                                let display_name = displayName.split('.');
                                let display_name_w_format = display_name[0] +  '_cMatte.jpg'
                                let image_name = url.filename.split('.');
                                let image_name_w_format = image_name[0] + '_cMatte.jpg'
                                let new_url = ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + image_name_w_format;
                                if (url.status == 'rendered') {
                                    let fileData = {};
                                    fileData['url'] = new_url;
                                    fileData['display_name'] = display_name_w_format;
                                    downloadData.push(fileData);
                                }

                            }
                        });
                        saveToZip (this.props.match.params.id + '_Scene 360 Cryptomatte.zip', downloadData);

                    } else {
                        this.setState({
                            crypto_exists: false
                        })
                    }
                }
            }
        } else {
            this.setState({
                crypto_exists: false
            })
        }

    }

    downloadLifestyleImagesArchive = () => {
        let downloadData = [];
        if (this.state.renders != undefined && this.state.renders.length > 0) {
            this.state.renders.map((url) => {
                if((this.hasComplaintResolved(url.filename, LIFESTYLE_TYPE.lifestyle_images).length===0) && (url.status == 'rendered' && url.filename != '360Camera.jpg')) {
                    let fileData = {};
                    fileData['url'] = (ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + url.filename);
                    fileData['display_name'] = this.getDisplayName(url);
                    downloadData.push(fileData);
                }
            });
        }
        saveToZip (this.props.match.params.id + '_Scene Lifestyle Images.zip', downloadData);
    }

    downloadCryptoImagesArchive = () => {
        if (this.state.crypto_exists) {
            let downloadData = [];
            if (this.state.renders != undefined && this.state.renders.length > 0) {
                if (this.state.crypto_images && this.state.crypto_images.length > 0) {
                this.state.renders.map((url,index) => {
                        if (this.state.crypto_images.includes(index)) {
                            let displayName = this.getDisplayName(url);
                            let display_name = displayName.split('.');
                            let display_name_w_format = display_name[0] +  '_cMatte.jpg'
                            let image_name = url.filename.split('.');
                            let image_name_w_format = image_name[0] + '_cMatte.jpg'
                            let new_url = ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + image_name_w_format;
                            if (url.status == 'rendered'){
                                let fileData = {};
                                fileData['url'] = new_url;
                                fileData['display_name'] = display_name_w_format;
                                downloadData.push(fileData);
                            }
                        }

                    });
                    saveToZip (this.props.match.params.id + '_Scene Lifestyle Cryptomatte.zip', downloadData);

                } else {
                    this.setState({
                        crypto_exists: false
                    })
                }

            }


        } else {
            this.setState({
                crypto_exists: false
            })
        }

    }

    openFlagSceneModal = () => {
        sceneInput = '';
        this.setState({triggerUpdate: !this.state.triggerUpdate});
        this.setState({
            flag_scene_modal: true,
            single_scene: false,
        });
    }

    openFlagImageModal = () => {
        imageInput = '';
        this.setState({
            flag_image_modal: true,
            single_image: false
        });
    }

    openFlagTourModal = () => {
        tourInput = '';
        this.setState({
            flag_tour_modal: true
        }, () => {
            this.callbackFlagTour();
        });
    }

    openSingleFlagImage = (name) => {
        imageInput = '';
        this.setState({
            flag_image_modal: true,
            single_image: true,
            selected_image: name,

        });

        let temp_arr = [];
        this.state.renders.map(x=> {

            temp_arr[x.filename.split(".")[0]] = false;

        });

        this.setState({
            image_visible: temp_arr
        });
    }

    validateFileUploads = (files) => {
        let status_done = false;
        if (files != undefined) {
            for(var file of files) {
                if (file['status'] == 'uploading') {
                    return 'uploading';
                }
                else if (file['status'] == 'error') {
                    return 'error';
                }
                else if (file['status'] == 'done') {
                    status_done = true;
                }
            }
        }
        if (status_done) {
            return 'done'
        }
        return 'not_started';
    }

    handleSceneReport = (values) => {
        this.setState({
            buttonLoader: true
        });
        let payload = {};
        let scene_upload_status = this.validateFileUploads(values["upload_scene_screenshots"]);
        if (this.state.single_scene) {
            let scene = [];
            scene.push(this.state.selected_scene.split('.')[0]);
            payload = {
                complaint_against: "scene_360",
                entity_id: this.props.match.params.id,
                issue: values.scene_flag_reason,
                attachment: scene,
                reference_images: values.upload_scene_screenshots,
                status: "pending",
                username: this.state.username
            }
        }
        else {
            let selected_scenes = [];
            this.state.viewable_three_sixties.map((name, index) => {
                if (!this.state.disabled_scenes.includes(name.filename.split('.')[0])) {
                    if (this.state.checkedScene[name.filename.split('.')[0]]){
                        selected_scenes.push(name.filename.split('.')[0]);
                    }
                }
            })
            payload = {
                complaint_against: "scene_360",
                entity_id: this.props.match.params.id,
                issue: values.scene_flag_reason,
                attachment: selected_scenes,
                reference_images: values.upload_scene_screenshots,
                status: "pending",
                username: this.state.username,
                by_msprovider: FileConstants.isMSProvider
            }
        }

        if (scene_upload_status == "uploading") {
            this.setState({
                buttonLoader: false
            });
            message.info('Uploads are in progress, please wait till file uploads are complete.');

        }
        else if (scene_upload_status == "error") {
            this.setState({
                buttonLoader: false
            });
            message.error('Error occured in uploading file(s), please re-upload your file(s).');

        }
        else if (scene_upload_status == "done" || scene_upload_status == "not_started") {
            axios.post(ENVIRONMENT.FLAG_IF_UNHAPPY, payload)
                .then((res) => {
                    sceneInput = '';
                    this.setState({
                        flag_scene_modal: false,
                        screenshotSceneList: [],
                        checkedScene: [],
                        checkSceneState: false,
                        selectAllScenesFlag: [],
                       // selected_scene: '',
                        single_scene: false,
                        buttonLoader: false,
                        reportSuccess: true
                    });
                })
                .catch((error) => {
                    message.error('Error in sending your request to the admin.');
                    this.setState({
                        buttonLoader: false
                    });
                });
        }
    }

    handleImageReport = (values) => {
        this.setState({
            buttonLoader: true
        });
        let payload = {};
        let image_upload_status = this.validateFileUploads(values["upload_image_screenshots"]);
        if (this.state.single_image) {
            let image = [];
            image.push(this.state.selected_image.split('.')[0]);
            payload = {
                complaint_against: "scene_lifestyle",
                entity_id: this.props.match.params.id,
                issue: values.image_flag_reason,
                attachment: image,
                reference_images: values.upload_image_screenshots,
                status: "pending",
                username: this.state.username,
                by_msprovider: FileConstants.isMSProvider
            }
        }
        else {
            let selected_images = [];
            this.state.renders.map((name, index) => {
                if (this.hideFlaggedImages(name)) {
                    if (this.state.checkedImage[name.filename.split('.')[0]]){
                        selected_images.push(name.filename.split('.')[0]);
                    }
                }
            });
            payload = {
                complaint_against: "scene_lifestyle",
                entity_id: this.props.match.params.id,
                issue: values.image_flag_reason,
                attachment: selected_images,
                reference_images: values.upload_image_screenshots,
                status: "pending",
                username: this.state.username,
                by_msprovider: FileConstants.isMSProvider
            }
        }

        if (image_upload_status == "uploading") {
            this.setState({
                buttonLoader: false
            });
            message.info('Uploads are in progress, please wait till file uploads are complete.');
        }
        else if (image_upload_status == "error") {
            this.setState({
                buttonLoader: false
            });
            message.error('Error occured in uploading file(s), please re-upload your file(s).');

        }
        else if (image_upload_status == "done" || image_upload_status == "not_started") {
            axios.post(ENVIRONMENT.FLAG_IF_UNHAPPY, payload)
                .then((res) => {
                    imageInput = '';
                    this.setState({
                        flag_image_modal: false,
                        single_image: false,
                        screenshotImageList: [],
                        checkedImage: [],
                        checkImageState: false,
                        selected_image: '',
                        selectAllImagesFlag: false,
                        buttonLoader: false,
                        reportSuccess: true
                    });
                })
                .catch((error) => {
                    message.error('Error in sending your request to the admin.');
                    this.setState({
                        buttonLoader: false
                    });
                });
        }
    }

    handleTourReport = (values) => {
        this.setState({
            buttonLoader: true
        });
        let tour_upload_status = this.validateFileUploads(values["upload_tour_screenshots"]);

        let payload = {
            complaint_against: "scene_3dtour",
            entity_id: this.props.match.params.id,
            issue: values.tour_flag_reason,
            reference_images: values.upload_tour_screenshots,
            status: "pending",
            username: this.state.username
        }

        if (tour_upload_status == "uploading") {
            this.setState({
                buttonLoader: false
            });
            message.info('Uploads are in progress, please wait till file uploads are complete.');
        }
        else if (tour_upload_status == "error") {
            this.setState({
                buttonLoader: false
            });
            message.error('Error occured in uploading file(s), please re-upload your file(s).');

        }
        else if (tour_upload_status == "done" || tour_upload_status == "not_started") {
            axios.post(ENVIRONMENT.FLAG_IF_UNHAPPY, payload)
                .then((res) => {
                    tourInput = '';
                    this.setState({
                        flag_tour_modal: false,
                        buttonLoader: false,
                        screenshotTourList: [],
                        reportSuccess: true
                    });
                })
                .catch((error) => {
                    message.error('Error in sending your request to the admin.');
                    this.setState({
                        buttonLoader: false
                    });
                });
        }
    }

    sceneEmbed = () => {
        this.setState({
            scene_embed: false,
            buttonLoader: true
        })
    }

    handleShare = (email) => {
        this.setState({
            sharing_loader: true
        })

        let api_endpoint = ENVIRONMENT.SHARE_SCENE;
        let body = null;
        if (this.state.scene_sharing_option == 'collaborate') {
            body = this.generateSharingPayload(email);
        } else {
            api_endpoint = ENVIRONMENT.SCENE_REPLICA;
            body = this.generateSceneReplicatePayload(email);
        }

        this.setState({access_update_loader: true});
        // Keep track of email address whose access is being updated
        let emails = this.state.updating_access_email;
        emails[email] = true
        this.setState({updating_access_email: emails})


        axios.post(api_endpoint, { body })
            .then(res => {
                let updating_emails=this.state.updating_access_email
                updating_emails[email] = false
                message.success('Scene Shared Successfully!');
                this.setState({
                    visible: false,
                    scene_shared: true,
                    sharing_loader: false,
                    access_update_loader: false,
                    updating_access_email: updating_emails
                });
            });
    };

    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    embedCancel = () => {
        this.setState({
            scene_embed: false
        })
    }
    handleEmbedCancel = e => {
        this.setState({
            embed_visible: false,
        });
    };

    handlePrintReadyCancel = e => {
        this.setState({
            print_ready_visible: false,
        });
    };

    //before-> attachments, //optimize code
    /**
     * Maps the before and after image in a complaint
     * If complaint is pending, the after of those attachments is returned as undefined
     * If complaint is rejected, the after of those attachments is returned as a string of value -> 'rejected'
     * @param {*} attachments attachments included in the complaint info
     * @param {*} lifeStyleImageType type of life style image from enum LIFESTYLE_TYPE
     * @returns object of before and after images of renders
     */
    mapBeforeToAfterImg = (attachments, lifeStyleImageType) => {
        const beforeAfterImages = {}
        const finalRenders = lifeStyleImageType === LIFESTYLE_TYPE.lifestyle_images ? this.state.renders : this.state.viewable_three_sixties;

        if (attachments) {
            finalRenders.map((finalRender) => {
                const { revision_of, filename, flagged_by_user, flag_rejected_by_admin, generated_by_admin } = finalRender;
                if (flagged_by_user) {
                    finalRenders.map((render) => {
                        if (render.revision_of != undefined && !render.flag_rejected_by_admin) {
                            if (attachments.includes(render.revision_of.split('.')[0])) {
                                beforeAfterImages[render.revision_of] = render.filename;
                            }
                        }
                    })
                    if (beforeAfterImages[filename] === undefined) {
                        if (attachments.includes(filename.split('.')[0])) {
                            beforeAfterImages[filename] = undefined
                        }
                    }
                }
                else if (flagged_by_user != undefined && !flagged_by_user) {
                    if (flag_rejected_by_admin === true) {
                        if (attachments.includes(filename.split('.')[0])) {
                            beforeAfterImages[filename] = 'rejected'
                        }
                    }

                    else if (revision_of != undefined && generated_by_admin) {
                        if (attachments.includes(revision_of.split('.')[0])) {
                            beforeAfterImages[revision_of] = filename;
                        }
                        else if (lifeStyleImageType === LIFESTYLE_TYPE.lifestyle_360) {
                            if (attachments.includes(filename.split('.')[0])) {
                                beforeAfterImages[revision_of] = filename;
                            }
                        }
                    }

                }

            })
        }
        return beforeAfterImages;
    }

    /**
     * This function is responsible for mapping complaint data to the renders to prepare complaint data
     * @param {*} complaintInfo -> complaint information of the lifestyle renders
     * @param {*} lifeStyleImageType -> type of life style image from enum LIFESTYLE_TYPE
     */
    prepareComplaintData = (complaintInfo, lifeStyleImageType) => {
        if (complaintInfo != null && complaintInfo != {}) {
            complaintInfo.map((complaint) => {
                const { status, issue, admin_message, submitted_on, id } = complaint;
                let { reference_images, attachment } = complaint;
                if (!Array.isArray(attachment)) {
                    attachment = [attachment]
                }
                const beforeAndAfterImg = this.mapBeforeToAfterImg(attachment, lifeStyleImageType);

                if (reference_images && !Array.isArray(reference_images)) {
                    reference_images = reference_images.toString().split(' ');
                }
                lifeStyleImageType == LIFESTYLE_TYPE.lifestyle_images ? this.state.complaint_info_lifestyle.push({ status, issue, beforeAndAfterImg, admin_message, reference_images, submitted_on, id }) : this.state.complaint_info_lifestyle_360.push({ status, issue, beforeAndAfterImg, admin_message, reference_images, submitted_on, id });
            })
        }
    }

    mapFlaggedImages = (lifeStyleImageType) => {
        const img_once_flagged = {};
        const renders = lifeStyleImageType == LIFESTYLE_TYPE.lifestyle_images ? this.state.renders : this.state.viewable_three_sixties
        renders.map((render) => {
            const { flagged_by_user, filename } = render;
            //check here if its a revision of image and check if the complaint of this is done by msprovider
            if (FileConstants.isMSProvider) {
                if (flagged_by_user !== undefined) {
                    if (img_once_flagged[filename] === undefined) {
                        img_once_flagged[filename.split('.')[0]] = true;
                    }
                }
            } else {
                if (flagged_by_user !== undefined) {
                    if (img_once_flagged[filename] === undefined) {
                        if (render.generated_by_admin && render.captured_by != CUSTOMER_USERNAME) {

                        } else {
                            img_once_flagged[filename.split('.')[0]] = true;
                        }

                    }
                }
            }
        })
        return img_once_flagged;
    }

    showRender = (id) => {
        this.setState({
            selected_render: id
        });
    }

    areAllRendersFlagged = (renders, slice = false) => {
        let newRenders = slice ? renders.slice(0) : renders
        const flagged = newRenders.map(render => {
            if (!render.flagged_by_user) {
                return false
            }
        })

        if (flagged.includes(false)) {
            return false
        }
        return true
    }

    areAllRendersFlaggedOnce = (renders) => {
        const flagged = renders.map(render => {
            if (!render.template_render && render.flagged_by_user === undefined && render.status == 'rendered') {
                return false
            }
        })

        if (flagged.includes(false)) {
            return false
        }
        return true
    }

    hasComplaintResolved = (filename, lifeStyleImageType) => {
        const complaints = lifeStyleImageType === LIFESTYLE_TYPE.lifestyle_images ? this.state.complaint_data : this.state.complaint_data_360
        return complaints.filter(complaint => complaint.attachment.includes(filename.split('.')[0]) && complaint.status === "resolved")
    }

    hideFlaggedImages = (x) => {
        if (FileConstants.isMSProvider) {
            return (!x.template_render && (this.hasComplaintResolved(x.filename, LIFESTYLE_TYPE.lifestyle_images).length===0) && (x.status == 'rendered' && x.filename != '360Camera.jpg'))
        }
        return (((x.flagged_by_user===undefined || (!this.state.img_flagged_once[x.filename.split('.')[0]] ) ) && x.status == 'rendered' && x.filename != '360Camera.jpg') && !this.state.disabled_images.includes(x.filename.split('.')[0]))
    }

    setCollectionView = (value) => {
        this.setState({
            collection_view: value
        })
    }

    getCollectionPayload = () => {
        let payload = {
            "required_fields": ["id", "space_areas"],
            "order_by" : "id desc"
          }
        let filter_string = "";
        let customer_username = "customer_username__exact='" + CUSTOMER_USERNAME + "'"
        let company_id = "";
        let is_hidden = "is_hidden__not=true"
        let has_access_to = "has_access_to__like='%\"" + CUSTOMER_USERNAME + "\"%'"
    
        if (SHARED_ENTITIES != undefined && COMPANY_ID && SHARED_ENTITIES.split("_").includes("collection")) {
            company_id = "company_id__exact=" + COMPANY_ID
            filter_string = "("+company_id+"||"+customer_username+")&&(" + is_hidden  + ")&&(template_collection_id__is=null)"
        } else {
            filter_string = "("+customer_username+ "&&" + is_hidden +")&&(template_collection_id__is=null)"
        }
        filter_string = filter_string + "||(" + has_access_to + ")||(template_collection_id__not=NULL&&" + customer_username+ "&&"+ is_hidden +")"
       
        payload['filter_string'] = filter_string
    
        return payload;
      }

    checkIfSpaceAreasExist = (collection_id) => {
        console.log(collection_id)
        let payload = this.getCollectionPayload()
        axios.post(ENVIRONMENT.LIST_COLLECTION, payload)
        .then( res => {
            res.data.map(col => {
                if(col.id == collection_id.toString()){
                    this.setState({
                        space_areas: col.space_areas
                    })
                }
            })
            this.setState({
                spaces_checked: true
            })
        })
    }

    getCompanyUsersEmails = () => {
        let emails = []
        if(MANAGED_CUSTOMER_USERNAME){
            emails.push({
                'email': MANAGED_CUSTOMER_EMAIL,
                'username': MANAGED_CUSTOMER_USERNAME
            })
            this.setState({company_users: emails})
        }
        if(COMPANY_ID){
            let payload = {
                "required_fields": ["email", "username"],
                "filter_string": `(company_id__exact=${COMPANY_ID})`,
                "order_by": "email asc"
            }
            axios.post(ENVIRONMENT.COMPANY_ENTITY_GET_BATCH, payload)
            .then(res => {
                if(res && res.data){
                    console.log('Company User Emails', res.data);
                    emails = res.data.filter(item => item.username != CUSTOMER_USERNAME)
                }
                this.setState({company_users: emails})
            })
        }
    }

    constructPayload = () => {
        let payload = {
            "order_by": "id desc",
            "filter_string": `(id__exact=${this.props.match.params.id})&&(model_type__exact='glb'||model_type__exact='low_poly_max'||model_type__isnull=true)`,
            "required_fields": [
                "id",
                "name",
                "scene_status",
                "room_max_archive",
                "collection_id",
                "collection_name",
                "scene_template_id",
                "render_generation",
                "designed_for",
                "final_renders",
                "three_sixties",
                "threed_tour",
                "customer_username",
                "company_id",
                "design",
                "platform",
                "tags",
                "project_id"
            ]
        }
        return payload;
    }

    checkMspUserStatus = () => {
        if (FileConstants.isMSProvider) {
            const selectedUser = localStorage.getItem('managed_customer_username')
            if ((selectedUser == null) && (this.state.designed_for != '' )) {
                this.setState( {
                    is_msp_user_warning_modal: true
            })
            } else if ((selectedUser != null) && this.state.designed_for != selectedUser ) {
                this.setState({
                    is_msp_user_warning_modal: true,
                    is_wrong_msp_user: true
                })
            }
    }}

    componentDidMount() {
        this.getURLParamsAndSetFolderParentID();
        this.setState({ isLoading: true });
        this.setSpaceCategoriesData();
        this.getCompanyUsersEmails();
        Utilities.getUserAccess('scene', this.props.match.params.id, this.setUserAccess)
        let project_id = new URLSearchParams(window.location.search).get("project_id");

        if(project_id != undefined) {
                this.setState({
                    project_id_param: project_id
                })
        }

        /**On refresh, render complaints page: */
        const query = new URLSearchParams(this.props.location.search);
        const renderComplaints = query.get('complaints')

        var payload = this.constructPayload()//{ "scene_id": this.props.match.params.id };
        axios.post(ENVIRONMENT.GET_SCENE_BATCH, payload)
            .then(res => {
                if (res.data['room_max_archive']) {
                    let room_archive = res.data['room_max_archive']
                    room_archive = room_archive.split('/')
                    let token_length = room_archive.length - 1
                    this.setState({
                        room_id: room_archive[token_length].split('.')[0]
                    })
                }
                this.setState({
                    username: CUSTOMER_USERNAME,
                    collection_id: res.data['collection_id'],
                    collection_name: res.data['collection_name'],
                    template_scene: res.data['scene_template_id'] ? true : false,
                    render_generation: res.data['render_generation'] == false ? false : true,
                    // flag_1k: res.data["allowed_flags"].includes("1k") ? false : true,
                    // flag_4k: res.data["allowed_flags"].includes("4k") ? false : true,
                    designed_for: res.data['designed_for'],
                    platform: res.data['platform'],
                    tags: res.data['tags'],
                    project_id: res.data['project_id']
                })
                this.checkIfSpaceAreasExist(res.data['collection_id'])
                this.checkMspUserStatus();

                var final_renders_json = res.data['final_renders'];
                var three_sixties_json = res.data['three_sixties'];
                var final_renders = [];
                var edit_permission = true;
                if(CUSTOMER_USERNAME !== res.data['customer_username']){
                    edit_permission = false;
                }
                let flagged_images = [];
                let template_images = [];
                let disable_flag = [];
                let flagged_scenes = [];
                var final_renders_obj = {};
                final_renders_obj = [];
                let white_balance_renders_switch = {}
                let dispimg = false;
                if (final_renders_json !== "" && final_renders_json !== undefined) {
                    final_renders_obj = final_renders_json;
                    if(final_renders_obj != undefined) {
                        final_renders = final_renders_obj;
                        final_renders.map((name,index) => {
                            if (name['template_render'] && name['status'] == 'rendered') {
                                template_images.push(name);
                            }
                            if( name['white_balanced_image'] != undefined ) {
                                white_balance_renders_switch[name.filename] = false
                            }

                            let camera_tokens = name.filename.split("_");
                            if(camera_tokens[1] != undefined){
                                let cameraIndex = parseInt(camera_tokens[1]);
                                if(cameraIndex >= this.state.camera_index){
                                    this.setState({
                                        camera_index: cameraIndex + 1
                                    });
                                }
                            }
                            if (name.flagged_by_user) {
                                flagged_images.push(name);
                            }
                        });
                        this.setState({
                            template_renders: template_images,
                            white_balance_renders_switch : white_balance_renders_switch
                        })
                        let complaint_payload = {
                            entity_id: this.props.match.params.id,
                            complaint_against:"scene_lifestyle"
                        }

                        axios.post(GET_COMPLAINT_BATCH, complaint_payload)
                        .then(res => {
                            let check_if_flagged = {};
                            let complaint_data = res.data;
                            renderComplaints === 'true' ? this.setState({ render_complaint_details: true }) : this.setState({ render_complaint_details: false })

                            if (complaint_data && complaint_data.length > 0) {
                                this.prepareComplaintData(complaint_data, LIFESTYLE_TYPE.lifestyle_images);
                                let flaggedOnceImages = this.mapFlaggedImages(LIFESTYLE_TYPE.lifestyle_images);
                                this.setState({ img_flagged_once: flaggedOnceImages });


                                this.setState({ complaint_data: complaint_data, isLoading: false });

                                complaint_data.map((data,index) => {
                                    if (Array.isArray( data.attachment) == true) {
                                        data.attachment.map((img) => {
                                            img = img.split(".")[0];
                                            // if complaint is in progress
                                            if ((data.status != "rejected" && data.status != "resolved")) {
                                                check_if_flagged[img] = true;
                                            }
                                        })
                                    } else {
                                        let img = data.attachment;
                                        img = img.split(".")[0];
                                        // if complaint is in progress
                                        if ((data.status != "rejected" && data.status != "resolved")) {
                                            check_if_flagged[img] = true;
                                        }

                                    }
                                });
                            } else {
                                this.setState({ isLoading: false });
                            }
                            flagged_images.map((data,index) => {
                                let filename = data.filename.split('.')[0]
                                if (check_if_flagged[filename]) {
                                    disable_flag.push(filename);
                                }
                            });
                           //if selected image is not in img_flagged_once check, then allow user to flag
                            this.setState({
                                disabled_images: disable_flag
                            });

                        }).catch(err => {
                            console.log(err, ' Error in getting complaints');
                        })



                        if (final_renders_obj.filter((ele) => ele.status != 'hidden').length != 0) {
                            dispimg = true;
                        }
                    }
                }

                final_renders.sort(function(a,b){
                    return new Date(a.time_updated) - new Date(b.time_updated)
                });

                let groups = final_renders.filter((ele) => ele.status != 'hidden' && !ele.template_render).reduce((groups, render) => {
                    let date = new Date(render.time_updated).toDateString();
                    if (!groups[date]) {
                      groups[date] = [];
                    }
                    groups[date].push(render);
                    return groups;
                  }, {});


                  // To add it in the array format instead
                  let groupArrays = Object.keys(groups).map((date) => {
                    return {
                      date,
                      render: groups[date]
                    };
                  });


                this.setState({
                    render_by_date: groupArrays
                });




                var three_sixties_object = {};
                let three_sixties = [];
                three_sixties_object["three_sixties"] = [];
                if (three_sixties_json !== "" && three_sixties_json !== undefined) {
                    three_sixties_object = three_sixties_json;
                    if(three_sixties_object != undefined) {
                        let disable_flag = [];
                        three_sixties = three_sixties_object;
                        three_sixties.filter((ele) => ele.status != 'hidden').map((name,index) => {
                            if (name.flagged_by_user) {
                                flagged_scenes.push(name);
                            }
                        });
                        axios.post(GET_COMPLAINT_BATCH,{
                            entity_id: this.props.match.params.id,
                            complaint_against:"scene_360"})
                        .then(res => {

                            let check_if_flagged = {};
                            let complaint_data = res.data;
                            if (complaint_data && complaint_data.length > 0) {
                                this.prepareComplaintData(complaint_data, LIFESTYLE_TYPE.lifestyle_360);
                                const flaggedOnceImages = this.mapFlaggedImages(LIFESTYLE_TYPE.lifestyle_360);
                                this.setState({ img_360_flagged_once: flaggedOnceImages });
                                this.setState({ complaint_data_360: complaint_data });

                                complaint_data.map((data,index) => {
                                    if (Array.isArray( data.attachment) == true) {
                                        data.attachment.map((img) => {
                                            img = img.split(".")[0];
                                            if ((data.status != "rejected" && data.status != "resolved")) {
                                                check_if_flagged[img] = true;
                                            }
                                        })
                                    } else {
                                        let img = data.attachment;
                                        img = img.split(".")[0];
                                        if ((data.status != "rejected" && data.status != "resolved")) {
                                            check_if_flagged[img] = true;
                                        }

                                    }
                                });
                            }
                            // let check_for_validity = {};
                            flagged_scenes.map((data,index) => {
                                //checks if the flagged image was resolved and if it wasn't flagged again
                                // check_for_validity[data.filename] = three_sixties.filter(x => {return (x.revision_of && x.revision_of.includes(data.filename.split('.')[0])) && x.generated_by_admin});
                                // if (check_for_validity[data.filename].length == 0) {
                                //     disable_flag.push(data.filename);
                                // }
                                let filename = data.filename.split('.')[0]
                                if (check_if_flagged[filename]) {
                                    disable_flag.push(filename);
                                }
                            });

                            this.setState({
                                disabled_scenes: disable_flag
                            });
                            console.log(disable_flag,'scenes disable')
                        }).catch(err => {
                            console.log(err, ' Error in getting complaints');
                        })
                    }
                }

                for (let percent = 0 ; percent <= 100 ; percent ++) {
                    this.setState({percent: percent});
                }
                let disp3D=false;
                let threed_tour = "";
                if("threed_tour" in res.data) {
                    threed_tour = res.data["threed_tour"];
                    if (Array.isArray(threed_tour) && threed_tour.length == 0) {
                        disp3D = false;
                    } else {
                        disp3D=true;
                    }
                    
                }

                let disp360 = false;
                if (three_sixties_object.filter((ele) => ele.status != 'hidden').length != 0 && this.props.match.params.id != undefined) {
                    disp360 = true;
                }


                var design = res.data['design'];
                if (design) {
                    this.setState({
                        design: design
                    });
                    var design_obj = design;
                    design_obj['cameras'].forEach((cameraObj) => {
                        if(cameraObj.camera_type == "still"){
                            let cameraToks = cameraObj['camera_name'].split("_");
                            if(cameraToks[1] != undefined){
                                let cameraIndex = parseInt(cameraToks[1]);
                                if(cameraIndex >= this.state.camera_index){
                                    this.setState({
                                        camera_index: cameraIndex + 1
                                    });
                                }
                            }
                            let camera_render = null;

                            let camera_render_file_name = cameraObj['camera_name'] + "." + cameraObj['image_format'];
                            if (final_renders.includes(camera_render_file_name)){
                                camera_render = ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + cameraObj['camera_name'] + "." + cameraObj['image_format'];
                            }
                            else {
                                camera_render = ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + cameraObj['camera_name'] + "_preview.png";
                            }
                            let clipping_value = 0;
                            if (cameraObj.clipping_value != undefined){
                                clipping_value = cameraObj.clipping_value
                            }
                            this.addCamera(cameraObj['camera_name'], '', cameraObj.image_width, cameraObj.image_height,clipping_value, camera_render );
                        }
                    });
                }
                if (final_renders && final_renders.length > 0) {
                    this.setState({
                        store_render_choices: final_renders.filter(x => x.status == 'rendered' && x.filename != '360Camera.jpg')
                    })
                }
                let collection_view = new URLSearchParams(window.location.search).get("collection_view");
                if (collection_view) {
                    this.setState({
                        collection_view: collection_view
                    })
                }

                if (three_sixties_object != undefined) {
                    if (three_sixties_object.length > 0) {
                        this.setState({
                            isLoading: false,
                        })
                    }
                }

                this.setState({
                    renders: final_renders,
                    name: res.data['name'],
                    scene_name_edit: res.data['name'],
                    edit_permission: edit_permission,
                    three_sixties: three_sixties_object,
                    viewable_three_sixties: three_sixties_object.filter((ele) => ele.status != 'hidden'),
                    threed_tour: threed_tour,
                    displayTour: disp3D,
                    display360: disp360,
                    displayimg: dispimg
                }, () => {
                    this.generatePublicLinksDict()
                    if (this.state.display360 && this.state.viewable_three_sixties){
                        this.setState({activeTabKey: "1"})
                    } else if (this.state.displayimg) {
                        this.setState({activeTabKey: "2"})
                    }
                    if (this.state.viewable_three_sixties != "") {
                        this.state.viewable_three_sixties.map((name, index) => {
                            this.state.checkedScene[name.filename.split('.')[0]] = false;
                            this.threeSixtyViews[name.filename.split('.')[0]] = null;
                        });
                        this.setState({
                            selected_scene: this.state.viewable_three_sixties[0].filename.split('.')[0]
                        }, () => {
                            if (this.threeSixtyViews[this.state.selected_scene] == null) {
                                this.threeSixtyViews[this.state.selected_scene] = new ThreeSixtyViewer(this.state.viewable_three_sixties[0].filename.split('.')[0],`loading-container-${this.state.viewable_three_sixties[0].filename.split('.')[0]}`,ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + this.state.viewable_three_sixties[0].filename,false);
                            }
                        });
                    }

                    if (this.state.viewable_three_sixties != undefined) {
                        if (this.state.viewable_three_sixties.length == 1) {
                            let image_name = this.state.viewable_three_sixties[0].filename.split('.');
                            let image_name_w_format = image_name[0] + '_cMatte.jpg'
                            let new_url = ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + image_name_w_format;
                            axios.head(new_url,{})
                            .then(res => {
                                this.setState({
                                    crypto_exists: true
                                })
                            })
                        }
                        else if (this.state.viewable_three_sixties.length > 0) {
                            let temp_crypto_360 = []
                            this.state.viewable_three_sixties.map((url,index) => {
                                let image_name = url.filename.split('.');
                                let image_name_w_format = image_name[0] + '_cMatte.jpg'
                                let new_url = ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + image_name_w_format;
                                axios.head(new_url,{})
                                .then(res => {
                                    this.setState({
                                        crypto_exists: true
                                    })
                                    temp_crypto_360.push(index)
                                    this.setState({
                                        crypto_360s: temp_crypto_360
                                    })
                                })

                            });
                        }
                    }

                    let temp_arr = [];
                    let temp_crypto_img = []
                    this.state.renders.map((x,index)=> {
                        if (x.status == 'rendered' && !x.generated_by_admin) {
                            let temp_cam = x.filename.split(".")[0];
                            temp_arr[temp_cam] = false;
                            let image_name = x.filename.split('.');
                            let image_name_w_format = image_name[0] + '_cMatte.jpg'
                            let new_url = ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + image_name_w_format;
                            axios.head(new_url,{})
                            .then(res => {
                                this.setState({
                                    crypto_exists: true
                                })
                                temp_crypto_img.push(index)
                                this.setState({
                                    crypto_images: temp_crypto_img
                                })
                            })
                        }
                    });

                    this.setState({
                        image_visible: temp_arr
                    });

                    this.setState({
                        imageResArr: this.setResolutions(1920,1080,'Landscape')
                    });
                });

                this.setState({
                    checkedScene: this.state.checkedScene
                });

            })

        document.addEventListener("keydown", this.escFunction, false);

        //Following is a temporary hack
        var tabsDivs = document.getElementsByClassName("ant-tabs-nav-container");

        for (var i=0; i< tabsDivs.length;i++){
            tabsDivs[i].style.zIndex = 99;
        }
        this.loadUserRequestStatus(['4k'], 'scene_lifestyle_image', null)

        payload = {"scene_id":this.props.match.params.id}
        axios.post(ENVIRONMENT.GET_S3_FILE, payload)
            .then(
                res => {
                    this.setState({
                        bundle_found: res.data.bundle_found
                    })
                }
            )
    }

    openCollaborateTool = () => {
        this.setState({
            collab_button_loader: true
        })
        let payload = {
            'scene_id': this.props.match.params.id,
            'camera_name': this.state.collaborate_camera
        } 
        axios.post(ENVIRONMENT.GET_COLLABORATE_ENTITY, payload)
        .then(res => {
            console.log('res',res)
            if (res.data && res.data.length == 0) {
                this.createCollaborateEntity();
            } else {
                this.collaborateCloseConfirmModal();
                let collaboration_id = res.data.collaboration_entity_id;
                let url = '/collaborate-tool/'+ collaboration_id ;
                if (this.state.project_id) {
                    url = `${url}?project_id=${this.state.project_id_param}`
                }
                let new_window = window.open(url, '_blank');
                if (new_window) {
                    new_window.focus();
                } else {
                    window.location.href = url;
                }
            }
        });
    }

    createCollaborateEntity = () => {
        let payload = {
            'scene_id': this.props.match.params.id,
            'camera_name': this.state.collaborate_camera,
            'display_name': this.state.collaborate_display_name,
            'created_by': localStorage.getItem('username'),
            'last_modified_by': localStorage.getItem('username'),
            'state': {}
        }
        payload['is_msprovider'] = FileConstants.isMSProvider;

        if (this.state.project_id) {
            payload['project_id'] = this.state.project_id;
        }
     
        axios.post(ENVIRONMENT.CREATE_COLLABORATE_ENTITY, payload)
        .then(res => {
            console.log('Response', res);
            if (res.data && res.data.body) {
                this.collaborateCloseConfirmModal();
                let collaboration_id = res.data.body.collaboration_entity_id;
                let url = '/collaborate-tool/'+ collaboration_id;
                let new_window = window.open(url, '_blank');
                if (new_window) {
                    new_window.focus();
                } else {
                    window.location.href = url;
                }
            }
        })
    }

    getCollaborationDisplayName(camera_name, display_name) {
        let collab_display_name = display_name;
        if (!display_name) {
            collab_display_name = 'Untitled ' + camera_name.split('.')[0].split('_')[1]
        }
        return collab_display_name;
    }

    generatePublicLinksDict() {
        let public_links = {}
        if(this.state.display360){
            if(this.state.viewable_three_sixties.length == 1){
                public_links['Lifestyle 360'] = ENVIRONMENT.PORTAL_LINK + '360_public_viewer/scene/' + this.props.match.params.id + '/360Camera.jpg'
            }
            else{
                this.state.viewable_three_sixties.map((url) => {
                    public_links[url.filename] = `https://app.all3d.ai/360_public_viewer/scene/${this.props.match.params.id}/${url.filename}`
                })
            }
        }

        if(this.state.displayTour){
            public_links['3D Tour'] = `https://app.all3d.ai/3d_tour_viewer/${this.props.match.params.id}`
        }

        if(this.state.displayimg){
            this.state.renders.map((x) => {
                if(x.status == 'rendered' && !x.template_render){
                    let key = null
                    if(x.display_name)
                        key = x.display_name
                    else
                        key = x.filename
                    public_links[key] = ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + x.filename
                }
            })
        }

       
        

        public_links['Embed in your site'] = '<iframe style="width: 500px; height: 300px;" src="' + 'http://app.all3d.ai/360_viewer/abIal9ok)_ApI/' + this.props.match.params.id + '"></iframe>'

        this.setState({public_links: public_links})
    }

    setSharingModal = (value) => {
        this.setState({visible: value})
    }

    collaborateConfirmModal = (camera_name, display_name) => {
        this.setState({
            collaborate_camera: camera_name,
            collaborate_display_name: this.getCollaborationDisplayName(camera_name, display_name)
        });
    }

    collaborateCloseConfirmModal = () => {
        this.setState({
            collaborate_camera: '',
            collaborate_display_name: '',
            collab_button_loader: false
        });
    }

    componentDidUpdate () {
        window.dispatchEvent(new Event('resize'));
    }

    validateNotBlankSceneName = (_, value) => {
        if (value && value.trim() === '') {
          return Promise.reject('Scene name cannot be all blank spaces.');
        }
        return Promise.resolve();
      };

    updateScene = (values) => {
        console.log('values = ', values)
        let scene_name = values.scene_name;
        let tags = values.tags;

        this.setState({
            buttonLoader: true
        })
        
        if (scene_name != this.state.name || tags != this.state.tags) {
            this.setState({
                error_message: ''
            });
            let payload = {
                scene_id: this.props.match.params.id,
                scene_name: scene_name,
                tags: tags
            };
            axios.post(ENVIRONMENT.UPDATE_SCENE, payload)
            .then((res) => {
                message.success('Scene information updated successfully!');

                this.setState({
                    buttonLoader: false,
                    edit_modal: false,
                    name: scene_name,
                    tags: tags
                }, () => {
                    document.getElementById("scene_name").innerText = scene_name
                });
            })
            .catch((error) => {
                message.error('Error in editing scene name. Try again.');
                this.setState({
                    buttonLoader: false
                });
            });
        } else {
            this.setState({
                error_message: "You have not made any changes.",
                buttonLoader: false,
            });
        }
    }

    addCamera = (camera_name, img_data,width,height, clipping_value, camera_render = '') => {
        const {cameras} = this.state;
        let resolution = Math.round(width) + 'x' + Math.round(height);
        let camera_obj = {
            name: camera_name,
            thumbnail: img_data,
            clipping_value: clipping_value,
            camera_render: camera_render,
            resolution: resolution
        };
        return this.setState({
            cameras: cameras.concat(camera_obj),
        });
    }

    setFullScreenState = (name,index) => {
        if (this.state.isFullScreen) {
            this.setState({ isFullScreen: false });
            if (this.threeSixtyViews[name] != null || this.threeSixtyViews[name] != undefined) {
                this.threeSixtyViews[name].toggleFull(false);
            }
        }
        else {
            this.setState({ isFullScreen: true });
            message.info('Press ESC to exit full screen');
            if (this.threeSixtyViews[name] != null || this.threeSixtyViews[name] != undefined) {
                this.threeSixtyViews[name].toggleFull(true);
            }
        }
    }

    generateSharingPayload = (email) => {

        let payload = {};

        let permissions = {};
        permissions['download'] = true;
        permissions['embed'] = true;

        payload['permissions'] = permissions;
        let lowercase_emails = [email.toLowerCase()]
        payload['emails'] = lowercase_emails;
        payload['username'] = CUSTOMER_USERNAME;
        payload['scene_id'] = this.props.match.params.id;
        payload['share_company_wide'] = false
        if(FileConstants.isMSProvider){
            payload['share_company_wide'] = true
        }

        return payload;
    }

    selected360Instance = () => {
        let selected_360 = {};
        this.state.viewable_three_sixties.map((name, index) => {
            if (name.filename.split('.')[0] == this.state.selected_scene) {
                selected_360 = name;
            }
        });
        return selected_360;
    }

    generateSceneReplicatePayload = (email) => {

        let payload = {};

        let lowercase_emails = [email.toLowerCase()]
        payload['emails'] = lowercase_emails;
        payload['username'] = CUSTOMER_USERNAME;
        payload['collection_id'] = this.state.collection_id;
        payload['scene_id'] = this.props.match.params.id;
        if (this.state.asset_sharing_option == true) {
            payload['replicate_assets'] = true
        }
        payload['share_company_wide'] = false
        if(FileConstants.isMSProvider){
            payload['share_company_wide'] = true
        }
        return payload;
    }

    callback = (key) => {
        this.setState({activeTabKey: key});
        if (this.virtualShowroom === null){
            if(this.state.threed_tour != "" ) {
                if (key === "3") {
                    this.setState({activeTabKey: key});
                    setTimeout(function() {
                        this.virtualShowroom = new VirtualShowroom(this.state.threed_tour, this.props.match.params.id, this.state.isFullScreen, "hotspot","vs-container");
                    }.bind(this), 1000)
                }
            }
        }
        else if (!this.state.isFullScreen) {
        }
    }

    callbackFlagTour = () => {
        if (this.virtualShowroomFlag === null){
            setTimeout(function() {
                this.virtualShowroomFlag = new VirtualShowroom(this.state.threed_tour, this.props.match.params.id, this.state.isFullScreen, "3d-tour-flag","vs-container-flag");
            }.bind(this), 1000);
        }
        else if (!this.state.isFullScreen) {
        }
    }

    getFullScreenTour = () =>  {
        if (this.state.isFullScreen) {
            this.setState({ isFullScreen: false });
            if(this.state.threed_tour != "" ) {

                setTimeout(function() {
                    this.virtualShowroom.toggleFull(false);
                }.bind(this), 1000)
            }
        }
        else {
            this.setState({ isFullScreen: true });
            message.info('Press ESC to exit full screen');
            if(this.state.threed_tour != "" ) {
                this.virtualShowroom.toggleFull(true);
            }
        }
    }

    changeSingleImageState = () => {;
        this.state.checkedImage[this.state.selected_image.split('.')[0]] = true;
        this.setState({
            checkedImage: this.state.checkedImage,
            single_image: false
        });
        let flag = false;
        let countTrue = 0;
        let countFalse = 0;
        let length = 0;
        this.state.renders.map((x, index) => {
            if (x.status == 'rendered' && x.filename != '360Camera.jpg') {
                length = length + 1;
                if (this.state.checkedImage[x.filename.split('.')[0]]) {
                    countTrue = countTrue + 1;
                    flag = true
                    this.setState({
                        checkImageState: true
                    });
                }
                else if (this.state.checkedImage[x.filename.split('.')[0]] == false) {
                    countFalse = countFalse + 1;
                }
            }
        });

        if (countTrue == length) {
            this.setState({
                selectAllImagesFlag: true
            });
        } else {
            this.setState({
                selectAllImagesFlag: false
            });
        }
        if (countFalse == length) {
            this.setState({
                selectAllImagesFlag: false
            });
        }

        if (!flag) {
            this.setState({
                checkImageState: false
            });
        }
    }

    changeTourInput = (e) => {
        tourInput = e.target.value;
        this.setState({triggerUpdate: !this.state.triggerUpdate});
    }

    changeImageInput = (e) => {
        imageInput = e.target.value;
        this.setState({triggerUpdate: !this.state.triggerUpdate});
    }

    changeSceneInput = (e) => {
        if (e.target != undefined) {
            sceneInput = e.target.value;
        } else {
            sceneInput = e;
        }
        this.setState({triggerUpdate: !this.state.triggerUpdate});
    }

    goToSceneCreator = () => {
        this.setState({
            request_image_loader: true
        }, () => {
            window.location.href = "/scene_builder/" + this.props.match.params.id;
        });
    }

    changeLifestyle = (key) => {
        this.state.viewable_three_sixties.map((name, index) => {
            if ((key-1) == (index)) {
                if (this.threeSixtyViews[index] == null) {
                    setTimeout(function() {
                        this.threeSixtyViews[index] = new ThreeSixtyViewer(name.filename.split('.')[0],`loading-container-${name.split('.')[0]}`,ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + name.filename,false);
                    }.bind(this), 1000);
                }
            }
        });
    }
    changeSelectedScene = (name) => {
        this.state.viewable_three_sixties.map((x, index) => {
            if ((this.threeSixtyViews[x.filename.split('.')[0]] != null) && (x != name)) {
                this.threeSixtyViews[x.filename.split('.')[0]].changeDisplay("none");
            }
        });
        this.setState({
            selected_scene: name.filename.split('.')[0]
        }, () => {
            if (this.threeSixtyViews[name.filename.split('.')[0]] == null) {
                this.threeSixtyViews[name.filename.split('.')[0]] = new ThreeSixtyViewer(name.filename.split('.')[0],`loading-container-${name.filename.split('.')[0]}`,ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + name.filename,false);
            }
            else if (this.threeSixtyViews[name.filename.split('.')[0]] != null) {
                this.threeSixtyViews[name.filename.split('.')[0]].changeDisplay("block");
            }
        });
    }

    openSingleFlagScene = (name) => {
        sceneInput = '';
        this.setState({triggerUpdate: !this.state.triggerUpdate});
        this.setState({
            flag_scene_modal: true,
            single_scene: true,
            selected_scene: name,
        });
    }

    changeSingleSceneState = () => {
        this.state.checkedScene[this.state.selected_scene] = true;
        this.setState({
            checkedScene: this.state.checkedScene,
            single_scene: false
        });
        let flag = false;
        let countTrue = 0;
        let countFalse = 0;
        let length = 0;

        this.state.viewable_three_sixties.map((name, index) => {
            length = length + 1;
            if (this.state.checkedScene[name.filename.split('.')[0]]) {
                countTrue = countTrue + 1;
                flag = true
                this.setState({
                    checkSceneState: true
                });
            }
            else if (this.state.checkedScene[name.filename.split('.')[0]] == false) {
                countFalse = countFalse + 1;
            }

        });

        if (countTrue == length) {
            this.setState({
                selectAllScenesFlag: true
            });
        } else {
            this.setState({
                selectAllScenesFlag: false
            });
        }
        if (countFalse == length) {
            this.setState({
                selectAllScenesFlag: false
            });
        }

        if (!flag) {
            this.setState({
                checkSceneState: false
            });
        }
    }

    setCardSaveFailure = (value) => {
        this.setState({
            card_save_failure: value
        });
    }

    setCardSaveDrawer = (value) => {
        this.setState({
            card_save_drawer: value
        });
    }

    saveRequestIntermedietely = (callback = null) => {

        let payload = {
            'request_type': this.state.request_type,
            'request_body': this.state.scene_payload,
            'processed': false
        }
        if (this.state.payment_request_id == -1) {
            axios.post(ENVIRONMENT.SAVE_REQUEST_INTERMEDIATE, payload)
                .then(res => {
                    this.setState({
                        loader: false,
                    });


                    if (callback!= null)
                    {
                    callback(res.data[0].request_id,this.state.request_type);
                    }
                });
        } else {
            this.setState({
                loader: false,
            });
            if (callback!= null)
            {
                callback(this.state.payment_request_id,this.state.request_type);
            }
        }
    }

    viewComplaintsListener=()=>{
        this.setState({render_complaint_details:true});
        const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?complaints=true';
        window.history.pushState({path:newurl},'',newurl);
    }

    addToWhiteBalancedImages = (filename) => {
        let white_balance_images = this.state.white_balance_renders_switch;
        white_balance_images[filename] = true;
        this.setState({
            white_balance_renders_switch : white_balance_images
        })
    }

    removeFromWhiteBalancedImages = (filename) => {
        let white_balance_images = this.state.white_balance_renders_switch;
        white_balance_images[filename] = false;
        this.setState({
            white_balance_renders_switch : white_balance_images
        })
    }

    getCollaborateImageFilepath = (final_render) => {
        /* 
        This function returns the image icon name for the collaborate icon based on the status
        of the collaboration i.e. create new, resolved, inprogress
        */
        let collab_icon_filename = '/img/collaborate_tool/new-collab.svg';
        if(final_render.collaboration_id && final_render.collaboration_resolved){
        // collaboration is resolved
            collab_icon_filename = '/img/collaborate_tool/resolved-collab.svg';
        }
        else if(final_render.collaboration_id && !final_render.collaboration_resolved){
            collab_icon_filename = '/img/collaborate_tool/in-progress-collab.svg'
        }

        return collab_icon_filename 
    }

    getCollaborationStatusTooltip = (final_render) => {
        /* 
        This function returns the status string for tooltip on collab icon hover
        */
        let collab_status = 'Collaborate';
        if(final_render.collaboration_id && final_render.collaboration_resolved){
        // collaboration is resolved
            collab_status = 'Collaboration Resolved';
        }
        else if(final_render.collaboration_id && !final_render.collaboration_resolved){
            collab_status = `Collaboration Inprogress<br/>Total Comments ${final_render.annotation_count || 0}`
        }

        return collab_status 
    }

    redirectPage = (backButton) => {
        console.log('get library URL');
        const url = new URL(window.location.href);
        const searchParams = new URLSearchParams(url.search);
        let queryString = ''
        console.log('search params =', searchParams)
        for(const [key, value] of searchParams.entries()){
            if(key != 'entity_id' && QUERY_PARAMS_TO_RETAIN.includes(key)){
                queryString = `${queryString}&${key}=${encodeURIComponent(value)}`
            }
        }
        
        console.log("yogi bear", window.history)
        if (backButton && queryString.length == 0 && window.history.length > 1) {
            window.history.back();
        } 
        else {
            const destination = this.state.folderParentID ? `/my_scenes?folder_id=${this.state.folderParentID}` : `/my_scenes?entity_id=${this.props.match.params.id}`;
            window.location.href = destination + queryString;
        }
    }

    checkIfManagedScene = () => {
        return (FileConstants.isMSProvider && this.state.designed_for != '' && this.state.username == FileConstants.CUSTOMER_USERNAME)
    }

    handleWarningModalClose = () => {
        this.setState( {
            is_msp_user_warning_modal: false
        })
    }

    render() {
        const menu = (
            <Menu style={{ width: "165px" }}>
                <Menu.Item key={1}>
                    <a className='manrope f-12' target="_blank" onClick={() => this.downloadSceneImagesArchive()}> 360 Image(s) <DownloadOutlined style={{float: "right"}} type="download" /></a>
                </Menu.Item>
                <Menu.Item key={2} onClick={() => this.downloadCrypto360Archive()} disabled={!this.state.crypto_exists || this.state.crypto_360s.length == 0}>
                    <Tooltip className='manrope f-12' title={!this.state.crypto_360s  || this.state.crypto_360s.length == 0 ? "Cryptomattes for this scene don't exist." : ""}>
                        <span className='manrope f-12'> Cryptomatte <DownloadOutlined style={{float: "right"}} type="download" /></span>
                    </Tooltip>
                </Menu.Item>
                {
                    this.state.bundle_found == true ?
                    <Menu.Item key={3}>
                        <a className='manrope f-12' target="_blank" href={ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + this.props.match.params.id + "_bundle.zip"}> Scene Bundle <DownloadOutlined style={{float: "right"}} type="download" /></a>
                    </Menu.Item>
                    :
                    ''
                }
            </Menu>
        );
        const menu_images = (
            <Menu style={{ width: "165px" }}>
                <Menu.Item key={1}>
                    <a  className='manrope f-12' target="_blank" onClick={() => this.downloadLifestyleImagesArchive()}> Lifestyle Image(s) <DownloadOutlined style={{float: "right"}} type="download" /></a>
                </Menu.Item>
                <Menu.Item key={2} onClick={() => this.downloadCryptoImagesArchive()} disabled={!this.state.crypto_exists || this.state.crypto_images.length == 0}>
                    <Tooltip className='manrope f-12' title={!this.state.crypto_exists || this.state.crypto_images.length == 0 ? "Cryptomattes for this scene don't exist." : ""}>
                        <span className='manrope f-12'> Cryptomatte <DownloadOutlined style={{float: "right"}} type="download" /></span>
                    </Tooltip>
                </Menu.Item>
                {
                    this.state.bundle_found  == true ?
                    <Menu.Item key={3}>
                        <a className='manrope f-12' target="_blank" href={ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + this.props.match.params.id + "_bundle.zip"}> Scene Bundle <DownloadOutlined style={{float: "right"}} type="download" /></a>
                    </Menu.Item>
                    :
                    ''
                }
            </Menu>
        );

        const addToStoreButton = <>
            {((FileConstants.isMSProvider && (!MANAGED_CUSTOMER_USERNAME && !MANAGED_COMPANY_ID))
                && (this.state.render_by_date && this.state.render_by_date.length > 0)) &&
                <Tooltip title="Add To Store" placement="top">
                    <Button onClick={this.addToStore} className="icon-button-container mr-8">
                        <ShoppingCartOutlined/>
                    </Button>
                </Tooltip>}
            </>

        return (
            this.state.user_access_level == 'restricted' ?
            <EntityAccessDenied entityType='scene' /> 
            :
            !this.state.collection_view ?
            <CustomerMainLayout selected='6' >
                {this.state.isLoading ?  <DottedLoader/> :
        <div className="scene-side-margins">
                <Row style={{ padding:" 12px 0px 0 0px"}}>
                    <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', zIndex: "1" ,marginTop:"10px",marginBottom:"10px"}}>
                        <div className="manrope f-14 w-600" style={{marginBottom:0,alignSelf:"center", cursor: 'pointer'}}>
                            <span className="store-bd" onMouseDown={()=>this.redirectPage(false)}>Scene Library</span> / {this.state.render_complaint_details ?  <Link className="store-bd " to={`/scene_renders/${this.props.match.params.id}`} onClick={()=>{this.setState({render_complaint_details:false})}} >{this.state.name}</Link>: <span className='active-route-link-color'>{this.state.name}</span>} { this.state.render_complaint_details ? <span className='active-route-link-color'> / Complaints</span>:'' }
                        </div>
                    </Col>
                </Row>
                <Row gutter={{xs: 8,sm: 16,md: 24,lg: 32}} style={{marginBottom: this.state.scene_error == "" ? "10px" : 8}}>
                    <Col lg={14} xl={16} className="justify-in-start" style={{display: 'block', marginTop: 10}}>
                        <div className='justify-in-start'>
                            <div className="back-button-icon"  onClick={()=>(this.redirectPage(true))}>
                                <ArrowLeftOutlined/>
                            </div>
                            <div className="justify-in-start"  style={{marginRight: 12}}>
                                <Tooltip title={<span className="manrope f-12 white">{this.state.name}</span>}>
                                    <span>
                                        <div className="manrope f-20 black-33 clamp-text-400">
                                            {this.state.name}
                                        </div>
                                    </span>
                                </Tooltip>
                                {this.state.user_access_level == 'edit' &&
                                <Tooltip title={<span className="manrope f-12 white">Edit Scene Name</span>}>
                                    <span>
                                        <div onClick={() => this.setState({edit_modal: true})} className="edit-icon-data-small edit-icon-blue" >
                                        <EditOutlined/>
                                        </div>
                                    </span>
                                </Tooltip>}
                            </div>
                            {this.state.scene_error == "" ? '' :
                                <div className="justify-space-between err-bg manrope f-12 red-error" style={{marginRight: 8}}><img src="/img/error-small.png" style={{marginRight: 8}}/><span>{this.state.scene_error}</span></div>
                            }
                        </div>
                    </Col>
                    <Col span={8} className='gutter-row justify-in-end'>
                    {this.state.render_generation == true ?
                        <div className="justify-in-center">

                            {this.state.display360 && this.state.viewable_three_sixties && this.state.activeTabKey=="1" ?
                            <>
                            <Tooltip 
                            title={this.areAllRendersFlaggedOnce(this.state.viewable_three_sixties) && !FileConstants.isMSProvider  ?
                                "Oops! All renders have been flagged once." :
                                this.state.viewable_three_sixties.length == 1 &&
                                 this.state.disabled_scenes.includes(this.state.viewable_three_sixties[0].filename.split('.')[0]) ?
                                  "You can't flag 360 unless your complaint is resolved." :
                                   "Something does not look good? Report it to the admin."}
                                placement="top">
                                <span>
                                    <Button id="flag-button-lf" 
                                    disabled={(this.areAllRendersFlaggedOnce(this.state.viewable_three_sixties) 
                                    && !FileConstants.isMSProvider) 
                                    || (this.state.viewable_three_sixties.length == 1 
                                    && this.state.disabled_scenes.includes(this.state.viewable_three_sixties[0].filename.split('.')[0]))} 
                                    onClick={this.openFlagSceneModal} 
                                    className='red-icon-button-container mr-8'
                                    >
                                        <div className={this.state.flag_scene_modal && this.state.single_scene == false ? "modal-okay-custom" :"modal-okay-custom gray"}>
                                            <FlagOutlined/>
                                        </div>
                                    </Button>
                                </span>
                            </Tooltip>
                            {addToStoreButton}
                            <Tooltip title="View your Complaints" placement="top">
                                <span>
                                    <div className="icon-button-container mr-8"  onClick={this.viewComplaintsListener}>
                                        <InfoOutlined/>
                                    </div>
                                </span>
                            </Tooltip>
                            <div id="components-dropdown-demo-dropdown-button" style={{position:"relative",float:'left',top: '0',zIndex: 3}}>
                                <Tooltip title="Download Links" placement="top">
                                    <span>
                                        <Dropdown overlay={menu} trigger={['click']}>
                                            <div className="icon-button-container mr-8">
                                                <DownloadOutlined />
                                            </div>
                                        </Dropdown>
                                    </span>
                                </Tooltip>
                            </div>
                            </> : 
                            this.state.displayimg && this.state.activeTabKey=="2" ? 
                            <>
                                {this.displayFlagIfUnhappyButton() &&
                                <Tooltip title={this.areAllRendersFlaggedOnce(this.state.renders) && !FileConstants.isMSProvider ? "Oops! All renders have been flagged once.": this.state.renders && this.state.renders.length == 1 && this.state.disabled_images.includes(this.state.renders[0].filename.split('.')[0]) ? "You can't flag 360 unless your complaint is resolved." : "Something does not look good? Report it to the admin."} placement="top">
                                    <span>
                                        <Button id="flag-button" 
                                        disabled={(this.areAllRendersFlaggedOnce(this.state.renders) && !FileConstants.isMSProvider) || (this.state.renders && this.state.renders.length == 1 && this.state.disabled_images.includes(this.state.renders[0].filename.split('.')[0]))} 
                                        onClick={this.openFlagImageModal} 
                                        className='red-icon-button-container mr-8'
                                        >
                                            <div className={(this.state.flag_image_modal && this.state.single_image == false) ? "modal-okay-custom" :"modal-okay-custom gray"}>
                                                <FlagOutlined/>
                                            </div>
                                        </Button>
                                    </span>
                                </Tooltip>}
                                {addToStoreButton}
                                {(this.state.render_generation == true && this.state.render_by_date && this.state.render_by_date.length > 0) &&
                                <Tooltip title="View your Complaints" placement="top">
                                    <span>
                                        <div className="icon-button-container mr-8" onClick={this.viewComplaintsListener}>
                                            <InfoOutlined/>
                                        </div>
                                    </span>
                                </Tooltip>
                                }
                                {(this.state.render_generation == true  && this.state.render_by_date && this.state.render_by_date.length > 0) &&
                                <div id="components-dropdown-demo-dropdown-button">
                                    <Tooltip title="Download Links" placement="top">
                                        <span>
                                            <Dropdown overlay={menu_images} trigger={['click']}>
                                                <div className="icon-button-container mr-8">
                                                    <DownloadOutlined />
                                                </div>
                                            </Dropdown>
                                        </span>
                                    </Tooltip>
                                </div>}
                            </> : this.state.displayTour && this.state.activeTabKey=="3" ? 
                            <>
                            {this.state.user_access_level == 'edit' &&
                            <Tooltip title={`Something does not look good? Report it to the admin.`} placement="top">
                                <Button id="flag-button-t" 
                                className="red-icon-button-container mr-8"
                                onClick={this.openFlagTourModal} 
                                >
                                    <div className={this.state.flag_tour_modal ? "modal-okay-custom" :"modal-okay-custom gray"}>
                                        <FlagOutlined/>
                                    </div>
                                </Button>
                            </Tooltip>}
                            {addToStoreButton}
                            </> : ''}
                            
                            {(this.state.display360 || this.state.displayTour || this.state.displayimg) ?
                            <Tooltip title="Share or Embed Scene" placement="top">
                                <Button onClick={() => this.showModal()} className="icon-button-container mr-8">
                                    <ShareAltOutlined />
                                </Button>
                            </Tooltip>: ''}
                            {(this.state.display360 || this.state.displayTour || this.state.displayimg) ?
                                <Tooltip title="Get Printer Ready Images" placement="top">
                                <Button onClick={() => this.showPrintReadyModal()} className="icon-button-container mr-8">
                                    <PrinterOutlined/>
                                </Button>
                            </Tooltip>: ''}

                            {this.state.user_access_level == 'edit' &&
                                <Button className="modal-okay square font-16 pd-6-14 h-40"
                                href={`/scene_builder/` + this.props.match.params.id + '?project_id=' + this.state.project_id} 
                                data-tut="reactour__stil_images_button">
                                    Scene Creator
                                </Button>
                            }

                        </div> : <div className="justify-in-center">
                        <Button className="modal-okay square font-16 pd-6-14 h-40" onClick={() => this.setCollectionView(true)}>
                            Add Products to Scene
                        </Button>
                    </div>}
                    </Col>
                </Row>
                <Link className="store-bd"  target="_blank" to={`/collection/${this.state.collection_id}/products?name=${this.state.collection_name}&scene_id=${this.props.match.params.id}`}>
                    <Tooltip title={<span className="manrope f-12 white">Click to See Scene Items</span>} placement="topLeft">
                        <span>
                            <div className="manrope f-14 justify-in-start" style={{color:'#276DD7', background:'#eef5fc', width: '16%', padding:10, borderRadius:4}}>
                            View items in the Scene
                                <span className="manrope f-14 dark-blue clamp-text w-50 ml-1.5">
                                <ArrowRightOutlined style={{marginLeft: 5}}/>
                                </span>
                            </div>
                        </span>
                    </Tooltip>         
                </Link>
                {this.state.tags && this.state.tags.length > 0?
                <Row>
                    <Col span={24} className="justify-in-start" style={{marginBottom: "10px"}}>
                        <div className="manrope f-14 grey-6b capitalize">
                            Tags: 
                            {this.state.tags.map((tag, index) => (
                                <span style={{color: '#9D9D9D'}}>
                                    {index > 0 ? `, ${tag}` : `${tag}`}
                                </span>
                            ))
                            }
                        </div>
                    </Col>
                </Row> : ''}
                {(this.state.render_generation == false && this.state.template_scene) &&
                <Col span={24}>
                    <div className='manrope f-14 bg grey'><InfoCircleOutlined/>&nbsp;You must add your own products to this scene before you can request new images</div>
                </Col>}

                { !this.state.render_complaint_details? < Tabs
                    defaultActiveKey="1"
                    tabBarStyle={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin:"0 0 10px 0",
                        zIndex:"2"
                    }}
                    style={{marginTop: "0px"}}
                    onChange={this.callback}>

                     {this.state.display360 && this.state.viewable_three_sixties ?
                    <TabPane tab="Lifestyle 360" key="1" className="tabAttr1" >
                        <div style={{textAlign:"right"}}>
                        {

                            (this.state.viewable_three_sixties.length == 1) ?

                                <Card hoverable={true} onMouseEnter={() => { this.setState({ show_flagged_tag: this.state.viewable_three_sixties[0].filename }) }} onMouseLeave={() => { this.setState({ show_flagged_tag: null }) }} className={this.state.isFullScreen ? 'room-full-screen': 'room-viewer-normal'} bodyStyle={{padding: this.state.isFullScreen ?0:8}}>
                                <Tooltip title={(this.state.isFullScreen ? "Exit Full Screen" : "Full Screen")} placement="left">
                                    <img src={this.state.isFullScreen ? "/img/exit-fs.png" : "/img/fs.png"}  alt="Fullscreen Icon" style={{position:"absolute", top:"12px",zIndex:"999",cursor:"pointer",opacity:"0.8",borderRadius:"4px"}} className="product-full-screen-icon" onClick={() => this.setFullScreenState(this.state.viewable_three_sixties[0].filename.split('.')[0],0)} type={(this.state.isFullScreen) ? "fullscreen-exit":"fullscreen"} />
                                </Tooltip>
                                <Tooltip title={this.state.img_360_flagged_once[this.state.viewable_three_sixties[0].filename.split('.')[0]] && !FileConstants.isMSProvider ? "You cannot flag more than once": this.state.disabled_scenes.includes(this.state.viewable_three_sixties[0].filename.split('.')[0]) ? "You can't flag this 360 unless your complaint is resolved." : "Flag 360"} placement="left">
                                    <img className="flag-image single" style={{cursor: this.state.disabled_scenes.includes(this.state.viewable_three_sixties[0].filename.split('.')[0]) ? "not-allowed" : "pointer"}} src="/img/image_flag.png" onClick={this.state.img_360_flagged_once[this.state.viewable_three_sixties[0].filename.split('.')[0]]&& !FileConstants.isMSProvider ? ()=>{}: () => this.state.disabled_scenes.includes(this.state.viewable_three_sixties[0].filename.split('.')[0]) ? "" : this.openSingleFlagScene(this.state.viewable_three_sixties[0].filename.split('.')[0])}/>
                                </Tooltip>
                                <Tooltip title="Delete 360" placement="left">
                                    <img src="/img/delete2.png"  alt="Delte Icon" style={{position:"absolute", top:"96px",zIndex:"999",cursor:"pointer",opacity:"0.8",borderRadius:"4px"}} className="product-full-screen-icon" onClick={() => {this.openDelete360Modal(this.state.viewable_three_sixties[0].filename)}} />
                                </Tooltip>

                                {!this.state.viewable_three_sixties[0].generated_by_admin && !this.state.viewable_three_sixties[0].flagged_by_user && !this.state.viewable_three_sixties[0].flag_rejected_by_admin ? "" :
                                this.state.show_flagged_tag===this.state.viewable_three_sixties[0].filename && this.state.viewable_three_sixties[0].flagged_by_user && <div className="flag-tag three-sixty">
                                    <span className="category-artist red"> Flagged</span>
                                    </div>}

                                <div id={this.state.viewable_three_sixties[0].filename.split('.')[0]} style={{cursor:"grabbing",background:"white"}} className={`${this.state.viewable_three_sixties[0].status == 'pending' ? 'd-none important': ''}`}>
                                </div>
                              
                                {this.state.viewable_three_sixties[0].status == 'pending' ?
                                <>
                                    <div className="thumbnail-btn-bg blur"><span className="thumbnail-btn">{'Render in Progress...'}</span></div>
                                </> : ''}
                                
                                <div id={`loading-container-${this.state.viewable_three_sixties[0].filename.split('.')[0]}`} className={`${this.state.viewable_three_sixties[0].status == 'pending' ? 'd-none important': ''}`}>
                                    <DottedLoader id={`loadingImages-${this.state.viewable_three_sixties[0].filename.split('.')[0]}`}/>
                                </div>
                            </Card>

                            :
                            <div>
                            <span>
                                <div
                                style={{marginTop: "20px"}} >
                                    <Card hoverable={true} onMouseEnter={()=>{this.setState({show_flagged_tag: this.state.selected_scene})}} onMouseLeave={()=>{this.setState({show_flagged_tag:null})}} className={this.state.isFullScreen ? 'room-full-screen': 'room-viewer-normal'} bodyStyle={{padding: this.state.isFullScreen ?0:8}}>
                                        <Tooltip title={(this.state.isFullScreen ? "Exit Full Screen" : "Full Screen")} placement="left">
                                            <img src={this.state.isFullScreen ? "/img/exit-fs.png" : "/img/fs.png"}  alt="Fullscreen Icon" style={{position:"absolute", top:"20px",zIndex:"999",cursor:"pointer",opacity:"0.8",borderRadius:"4px",right:"20px"}} className="product-full-screen-icon" onClick={() => this.setFullScreenState(this.state.selected_scene)} type={(this.state.isFullScreen) ? "fullscreen-exit":"fullscreen"} />
                                        </Tooltip>
                                        <Tooltip title={this.state.img_360_flagged_once[this.state.selected_scene] && !FileConstants.isMSProvider ? "You cannot flag more than once": this.state.disabled_scenes.includes(this.state.selected_scene.split('.')[0]) ? "You can't flag this 360 unless your complaint is resolved." : "Flag 360"} placement="left">
                                            <img className="flag-image fs" style={{cursor: this.state.disabled_scenes.includes(this.state.selected_scene.split('.')[0]) ? "not-allowed" : "pointer"}} src="/img/image_flag.png" onClick={this.state.img_360_flagged_once[this.state.selected_scene] && !FileConstants.isMSProvider ? ()=>{}:  () => this.state.disabled_scenes.includes(this.state.selected_scene.split('.')[0]) ? "" : this.openSingleFlagScene(this.state.selected_scene)}/>
                                        </Tooltip>
                                        <Tooltip title="Delete 360" placement="left">
                                            <img src="/img/delete2.png"  alt="Delte Icon" style={{position:"absolute", top:"100px", right: "20px",zIndex:"999",cursor:"pointer",opacity:"0.8",borderRadius:"4px"}} className="product-full-screen-icon" onClick={() => {this.openDelete360Modal(this.state.selected_scene)}} />
                                        </Tooltip>

                                        {this.state.viewable_three_sixties.map((name,index) => (
                                            (name.filename.split('.')[0] == this.state.selected_scene) ?
                                            <span>
                                                {!name.generated_by_admin && !name.flagged_by_user && !name.flag_rejected_by_admin ? '' :
                                                this.state.show_flagged_tag===this.state.selected_scene && name.flagged_by_user && <div className="flag-tag three-sixty">
                                                    <span className="category-artist red">
                                                     Flagged
                                                    </span>
                                                </div>}
                                            </span>: ''

                                        ))}

                                        <div id={this.state.selected_scene} style={{cursor:"grabbing",background:"white"}} className={`${this.selected360Instance().status == 'pending' ? 'd-none important': ''}`}>
                                        </div>
                                        {this.selected360Instance().status == 'pending' ?
                                        <>
                                            <div className="thumbnail-btn-bg blur"><span className="thumbnail-btn">{'Render in Progress...'}</span></div>
                                        </> : ''}
                                        <div id={`loading-container-${this.state.selected_scene}`} className={`${this.selected360Instance().status == 'pending' ? 'd-none important': ''}`}>
                                            <DottedLoader id={`loadingImage-${this.state.selected_scene}`}/>
                                        </div>
                                    </Card>
                                </div>
                                <div style={{display:"flex"}}>
                                    {this.state.viewable_three_sixties.map((name,index) => (
                                    <Card onClick={() => this.changeSelectedScene(name)} className="scene-card-bg card-render" key={index} bodyStyle={{ padding: "2px", minWidth:40, textAlign: "-webkit-center" }} style={{border: name.filename.split(".")[0] == this.state.selected_scene ? "1px solid #ffffff" : "1px solid #333333"}}>
                                        <p className="flag-card-settings"> {name.filename.split(".")[0].split('_')[0]}</p>
                                    </Card>
                                    ))}
                                </div>
                            </span>
                            </div>

                        }

                        </div>
                    </TabPane>
                   : "" }

                  {this.state.displayimg ?
                    <TabPane tab="Lifestyle Images" key="2" className="tabAttr1" >
                        <div>
                            <Row type="flex" style={{ padding: "0 0", margin: 0, paddingTop: 0,marginTop: 0,display:"flex",justifyContent:'space-between',alignItems:'center'}}>

                            { this.state.render_by_date && this.state.render_by_date.slice(0).reverse().map(camera => (
                                <Col span={24}>
                                    { !this.areAllRendersFlagged( camera.render, true) && <Row>
                                        <div className="modal-text grey-black font-14" style={{margin: "15px 0"}}>Date Created: <span className='modal-text black'>{camera.date}</span></div>
                                    </Row>}
                                    <Row gutter={[16,16]}>
                                        {camera.render && camera.render.slice(0).reverse().map(x => {
                                            return (
                                         (this.hasComplaintResolved(x.filename, LIFESTYLE_TYPE.lifestyle_images).length===0) && (x.status == 'rendered' && x.filename != '360Camera.jpg') ?
                                            <Col span={12}>
                                                <div style={{display: "flex"}}>
                                                    <h4 className={`scene-render-name${this.state.editable_render_name_id == x.filename ? `-editable` : ``}`} id={x.filename} onKeyDown={(e)=>{this.handleEnterForSceneRenderRename(e.keyCode, x.filename, x)}} style={{marginTop: 11, width: 'fit-content'}}>{x.display_name ? x.display_name : `Untitled ${x.filename.split('.')[0].split('_')[1]}`}</h4>
                                                    {this.state.user_access_level == 'edit' &&
                                                    this.state.editable_render_name_id == x.filename ?
                                                        <Button disabled={this.state.render_name_button_loader} loading={this.state.render_name_button_loader} className="save-button font-12" onClick={()=> {this.renameSceneRender(x.filename, x)}} style={{marginLeft: 15}}>
                                                            Save
                                                        </Button>
                                                    : this.state.user_access_level == 'edit' ?
                                                    <div onClick={()=>{this.makeSceneRenderNameEditable(x.filename)}} className="edit-icon-data-small edit-icon-blue" >
                                                        <EditOutlined/>
                                                    </div> : ''
                                                    }
                                                </div>
                                                <Card hoverable={true} onMouseEnter={()=>{this.setState({show_flagged_tag:x.filename})}} onMouseLeave={()=>{this.setState({show_flagged_tag:null})}} className="lifestyle-image-card">
                                                    <div className="position-flag-dim">
                                                        <div className="resolution-btn">
                                                            <span className="category-artist white">{Math.round(x.image_width) + ' x ' + Math.round(x.image_height)}</span>
                                                        </div>
                                                        {!x.generated_by_admin && !x.flagged_by_user && !x.flag_rejected_by_admin ? "" :
                                                        this.state.show_flagged_tag===x.filename && x.flagged_by_user && <div className="flag-tag">
                                                            <span className="category-artist red">Flagged</span>

                                                        </div>}
                                                    </div>

                                                    <div className="img-format-tag">
                                                        <div className="resolution-btn">
                                                            <span className="category-artist white">{this.imageFormat(x.filename)}</span>
                                                        </div>
                                                    </div>

                                                    <Tooltip title={<span className='manrope f-12 white' dangerouslySetInnerHTML={{ __html: this.getCollaborationStatusTooltip(x)}}></span>}>
                                                        <div className={`collaborate-icon ${x.white_balanced_image != undefined ? `` : `right`} ${x.collaboration_resolved ? 'green' : x.collaboration_id == undefined ? 'white': ''} `} onClick={() => this.collaborateConfirmModal(x.filename, x.display_name)}>
                                                            <img className='collaborate-img' src={this.getCollaborateImageFilepath(x)} />
                                                        </div>
                                                    </Tooltip>

                                                    {
                                                        x.white_balanced_image != undefined ?
                                                            <div className="img-auto-white-switch">
                                                                <Tooltip title={this.state.white_balance_renders_switch[x.filename] != undefined &&  this.state.white_balance_renders_switch[x.filename] == true? "Auto White Balanced Image : On" : "Auto White Balanced Image : Off"}>
                                                                    <Switch onClick={(check)=>{
                                                                        if(check){
                                                                            this.addToWhiteBalancedImages(x.filename)
                                                                        }
                                                                        else {
                                                                            this.removeFromWhiteBalancedImages(x.filename)
                                                                        }
                                                                    }} checkedChildren={<img src="/img/white_balance_icon_white.svg"></img>} unCheckedChildren={<img src="/img/white_balance_icon.svg"></img>}/>
                                                                </Tooltip>
                                                            </div>
                                                        :
                                                            ""
                                                    }

                                                    <Image preview={{
                                                        visible: this.state.image_visible[x.filename.split(".")[0]],
                                                        maskClassName: 'customize-mask',
                                                        mask: (
                                                        <span>
                                                            <img className="image-thumbnail" src="/img/expand_thumbnail.png" onClick={() => this.expandImage(x.filename)} style={{marginRight: 16}}/>
                                                            {this.state.user_access_level == 'edit' ?
                                                            <Tooltip title={!this.checkIfFlagIsDisabled(x) || FileConstants.isMSProvider ? this.state.disabled_images.includes(x.filename.split('.')[0]) ? "You can't flag this image unless your complaint is resolved." : this.checkIfFlagIsDisabled(x) ? "You cannot flag this image" : "" :"You cannot flag more than once"}>
                                                                <img className="image-thumbnail" src="/img/flag_thumbnail.png"
                                                                onClick={!this.checkIfFlagIsDisabled(x) || FileConstants.isMSProvider ? () => this.state.disabled_images.includes(x.filename.split('.')[0]) ? "" : this.checkIfFlagIsDisabled(x) ? ()=>{} : this.openSingleFlagImage(x.filename): ()=>{}}
                                                                style={{marginRight: 16,cursor: this.state.disabled_images.includes(x.filename.split('.')[0]) || this.checkIfFlagIsDisabled(x)  ? "not-allowed" : "pointer"}}/>
                                                            </Tooltip> : ''}
                                                            {this.state.user_access_level == 'edit' ?
                                                                <img className="image-thumbnail" src="/img/delete.png"  onClick={() => this.openDeleteLifestyleModal(x.filename)} style={{marginRight: 16}}/>: ''
                                                            }
                                                            <a onClick={() => {
                                                                if(this.state.white_balance_renders_switch[x.filename] != undefined &&  this.state.white_balance_renders_switch[x.filename] == true){
                                                                    let filename = (document.getElementById(x.filename).innerText + '.' + x.filename.split('.')[x.filename.split('.').length - 1]).replace(".", "_white_balanced.");
                                                                    downloadFile(ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + x.white_balanced_image, filename)
                                                                }
                                                                else{
                                                                    let filename = document.getElementById(x.filename).innerText + '.' + x.filename.split('.')[x.filename.split('.').length - 1];
                                                                    downloadFile(ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + x.filename, filename)
                                                                }
                                                                }}>
                                                                <img className="image-thumbnail" src="/img/download.png"/>
                                                            </a>
                                                            {/* display generate high res button if the image does not has in_progress collaboration */}
                                                            {((x.collaboration_id && x.collaboration_resolved) || x.collaboration_id == null) && this.state.user_access_level == 'edit' ? 
                                                                <div className="thumbnail-btn-bg" onClick={() => this.state.disabled_images.includes(x.filename.split('.')[0]) ? this.openConfirmationModal(x.filename) : this.openConfigModal(x.filename) }><span className="thumbnail-btn">Generate High Resolution Image</span></div>
                                                            :
                                                            (x.collaboration_id && !x.collaboration_resolved) ? 
                                                                <div className="thumbnail-btn-bg" ><span className="thumbnail-btn">Collaboration in Progress</span></div> 
                                                            : ''}

                                                        </span>
                                                        ),
                                                        onVisibleChange: this.changeVisibility,
                                                    }}
                                                    className="lifestyle-image-settings" src={ this.state.white_balance_renders_switch[x.filename] != undefined &&  this.state.white_balance_renders_switch[x.filename] == true?
                                                        (ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + x.white_balanced_image).replace('tiff', 'jpg')
                                                        :
                                                        (ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + x.filename).replace('tiff', 'jpg')
                                                    }
                                                    />

                                                </Card>
                                            </Col>
                                            : (x.status == 'pending' && x.filename != '360Camera.jpg') ?
                                            <Col span={12}>
                                                <div style={{display: "flex"}}>
                                                    {x.display_name ? <h4 className={`scene-render-name${this.state.editable_render_name_id == x.filename ? `-editable` : ``}`} id={x.filename} onKeyDown={(e)=>{this.handleEnterForSceneRenderRename(e.keyCode, x.filename, x)}} style={{marginTop: 11, width: 'fit-content'}}>{x.display_name}</h4> : <h4 className={`scene-render-name${this.state.editable_render_name_id == x.filename ? `-editable` : ``}`} id={x.filename} onKeyDown={(e)=>{this.handleEnterForSceneRenderRename(e.keyCode, x.filename, x)}} style={{marginTop: 11, width: 'fit-content'}}>Untitled {x.filename.split('.')[0].split('_')[1]}</h4>}
                                                    {this.state.editable_render_name_id == x.filename ?
                                                        <Button disabled={this.state.render_name_button_loader} loading={this.state.render_name_button_loader} className="save-button font-12" onClick={()=> {this.renameSceneRender(x.filename, x)}} style={{marginLeft: 15}}>
                                                            Save
                                                        </Button>
                                                    :
                                                    <div onClick={()=>{this.makeSceneRenderNameEditable(x.filename)}} className="edit-icon-data-small edit-icon-blue" >
                                                        <EditOutlined/>
                                                    </div>
                                                    }
                                                </div>
                                                <Card className="lifestyle-image-card">
                                                    <div className="thumbnail-btn-bg blur"><span className="thumbnail-btn">{x.preview_render.includes("preview") ? 'Render in Progress...' : 'High Resolution Render in Progress...'}</span></div>
                                                    <Image preview={false}
                                                    className="lifestyle-image-settings preview" src={ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + x.preview_render.replace('tiff','jpg')}
                                                    />
                                                </Card>

                                            </Col>
                                        :""
                                        )})}
                                    </Row>
                                </Col>)) }
                                <Col span={24}>
                                {this.state.template_renders && this.state.template_renders.length > 0 && this.state.template_scene ?
                                <Row>
                                    <div className="modal-text grey-black font-14" style={{margin: "15px 0px 5px 0"}}>Sample Renders</div>
                                </Row>: ''}
                                {this.state.template_renders && this.state.template_renders.length > 0 &&  this.state.template_scene ?
                                <Row gutter={[16,16]}>
                                {this.state.template_renders.map((x) => (
                                    <Col span={12}>
                                        <Card className="lifestyle-image-card">
                                            <div className="position-flag-dim">
                                                <div className="resolution-btn">
                                                    <span className="category-artist white">{Math.round(x.image_width) + ' x ' + Math.round(x.image_height)}</span>
                                                </div>
                                            </div>
                                            <div className="img-format-tag">
                                                <div className="resolution-btn">
                                                    <span className="category-artist white">{this.imageFormat(x.filename)}</span>
                                                </div>
                                            </div>
                                            <Image preview={{
                                                visible: this.state.image_visible[x.filename.split(".")[0]],
                                                maskClassName: 'customize-mask',
                                                mask: (
                                                <span>
                                                    {this.state.render_generation == true ?
                                                    <img className="image-thumbnail" src="/img/delete.png"  onClick={() => this.openDeleteLifestyleModal(x.filename)} style={{marginRight: 16}}/> : ''}
                                                    <img className="image-thumbnail" src="/img/expand_thumbnail.png" onClick={() => this.expandImage(x.filename)} style={{marginRight: 16}}/>
                                                </span>
                                                ),
                                                onVisibleChange: this.changeVisibility,
                                            }}
                                            className="lifestyle-image-settings" src={ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + x.filename.replace('tiff', 'jpg')}
                                            />
                                        </Card>
                                    </Col>
                                ))}
                                </Row>: ''}
                                </Col>
                            </Row>
                        </div>

                    </TabPane>
                    : ""
                    }

                     {this.state.displayTour ?

                    <TabPane tab="3D Tour" key="3" className="tabAttr1" >
                        <div style={{textAlign:"right"}}>

                            {/* tried here */}


                            <Card id={`vs-container`} className={this.state.isFullScreen ? "tour-fullscreen" : ""} style={{cursor:"grabbing"}}>
                                <Tooltip title={(this.state.isFullScreen ? "Exit Full Screen" : "Full Screen")} placement="left">
                                    <img src={this.state.isFullScreen ? "/img/exit-fs.png" : "/img/fs.png"} alt="Fullscreen Icon" style={{position:"absolute", top:"20px",zIndex:"999",cursor:"pointer",borderRadius:"4px",right:"20px"}} className="product-full-screen-icon" onClick={() => {this.getFullScreenTour()}} type={(this.state.isFullScreen) ? "fullscreen-exit":"fullscreen"} />
                                </Tooltip>
                                <div className="hotspot" id="hotspot">
                                    <img className="hotspotImage" src="https://all3d-demos.s3.amazonaws.com/Virtual-Showroom/Resources/hotspot.gif" alt="Hotspot" />
                                    <span className="tooltiptext" id="toolTip"></span>
                                </div>
                            </Card>

                            <div id="loading-container">
                                <img id="loadingImage" className="loadingImage" src="" style={{width: "100%",height: "100%"}}></img>
                                <div class="profile-main-loader tour">
                                    <div class="loader">
                                    <svg class="circular-loader"viewBox="25 25 50 50" >
                                        <circle class="loader-path" cx="50" cy="50" r="20" fill="none" stroke="#808080" stroke-width="2" />
                                    </svg>
                                    </div>
                                </div>

                                <div style={{position: "absolute",bottom: 160,width: "100%"}}>
                                    <div style={{display: "flex", justifyContent: "center"}} className="my_font">
                                    powered by
                                    </div>
                                    <div style={{display: "flex", justifyContent: "center", marginTop: 10}} >
                                    <img className="logo" src="/img/logo.svg" width="111px" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        </TabPane> : "" }
                        </Tabs> :
                            this.state.complaint_info_lifestyle.length > 0 || this.state.complaint_info_lifestyle_360.length > 0 ?

                                <Tabs>
                                    {this.state.complaint_info_lifestyle.length > 0 &&
                                        <TabPane tab="Lifestyle Complaints" key="2" className="tabAttr1" >
                                            {this.state.complaint_info_lifestyle.map((complaint) => {
                                                return (<ComplaintDetailCard complaintInfo={complaint} baseURL={ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI} sceneId={this.props.match.params.id} is360={false} platform={this.state.platform} />)
                                            })
                                            }
                                        </TabPane>
                                    }

                                    {this.state.complaint_info_lifestyle_360.length > 0 &&
                                        <TabPane tab="Lifestyle 360 Complaints" key="1" className="tabAttr1" >
                                            {this.state.complaint_info_lifestyle_360.map((complaint) => {
                                                if (this.state.complaint_info_lifestyle_360.length === 1) {
                                                    return (<ComplaintDetailCard complaintInfo={complaint} baseURL={ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI} sceneId={this.props.match.params.id} is360={true} multiple360s={this.state.complaint_info_lifestyle_360.length > 1 ? true : false} platform={this.state.platform} />)
                                                }
                                                else {
                                                    const { status, issue, beforeAndAfterImg, admin_message, reference_images, submitted_on, id } = complaint
                                                    const sceneId = this.props.match.params.id
                                                    return (
                                                        <Row className="align-col-left" style={{ marginTop: 20, marginBottom: 20 }}>
                                                            <Col style={{ width: "100%" }}>
                                                                <Card className="complaint-card align-col-left card-shadow-product" bodyStyle={{ minWidth: 40, textAlign: "-webkit-center" }} style={{ marginTop: 10, width: '100%' }} bordered={false}>

                                                                    <div style={{ width: "100%", }} className="justify-in-start complaint-card-details" >
                                                                        <p style={{ paddingLeft: 15, overflow: 'hidden' }} className="manrope f-18 w-700">Issue:</p>
                                                                        <p style={{ paddingLeft: 15, overflow: 'hidden' }} className="manrope f-16 w-500"> {issue}</p>
                                                                    </div>

                                                                    <ComplaintInfo admin_message={admin_message} status={status} reference_images={reference_images} submitted_on={submitted_on} />
                                                                    {Object.keys(beforeAndAfterImg).map((key, index) => {
                                                                        const before = key
                                                                        const is360 = true
                                                                        const after = beforeAndAfterImg[key]

                                                                        return (<div>
                                                                            {id !== this.state.selected_render ?
                                                                                index===0 && <Button className="manrope f-14 w-500" style={{ margin: 10 }} onClick={() => this.showRender(id)}>Show renders</Button> :
                                                                                id === this.state.selected_render && <div style={{ width: '100%' }} className="display-flex-row manrope f-14 w-500" >
                                                                                    <div style={{ borderRight: "0.01px solid #E3E3E3", paddingRight: 15, width: "50%" }} className="align-col-left">
                                                                                        <p style={{ paddingLeft: 15, paddingTop: 12, paddingBottom: 8 }}>Before</p>
                                                                                        {is360 ? <PanoramaViewer id={'360Camera'} customClass={'panorama-default-styles complaint'} threeSixtyUrl={ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + sceneId + '/' + before.replace('tiff', 'jpg')} /> :
                                                                                            <img style={{ paddingLeft: 15, paddingBottom: 15 }} className="lifestyle-image-settings-custom" src={((ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + sceneId + '/' + before.replace('tiff', 'jpg')))}></img>
                                                                                        }
                                                                                    </div>
                                                                                    <div className="align-col-left" style={{ marginLeft: 15, width: "50%" }}>
                                                                                        <p style={{ paddingTop: 12, paddingBottom: 8 }} >{ status!=='rejected'? after? 'After' : 'In Progress': 'Reviewed & Resolved'}</p>
                                                                                        {status === 'rejected' ?
                                                                                        <div className='in-progress-paddings flex' >
                                                                                            <CheckCircleOutlined className="manrope f-14 set-svg-dims"/>
                                                                                        </div>
                                                                                        : (is360 && after) && id === this.state.selected_render ? <PanoramaViewer id={'360Camera'} customClass={'panorama-default-styles complaint'} threeSixtyUrl={ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + sceneId + '/' + after.replace('tiff', 'jpg')} /> :
                                                                                        <div className={`in-progress-paddings flex`} >
                                                                                            <img className={`lifestyle-image-settings-custom ${after?'fill':'h-100'}`} src={(require("../../../../assets/images/inProgress icon.png"))}></img>
                                                                                        </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>)
                                                                    })}

                                                                </Card>
                                                            </Col>

                                                        </Row>
                                                    )
                                                }
                                            })
                                            }
                                        </TabPane>
                                    }
                                </Tabs>


                                : <Empty  className="manrope f-14 centre-in-screen m-top-24 m-bottom-40" description="No complaints!"></Empty>
                        }

                {(!(this.state.display360 || this.state.displayTour || this.state.displayimg)) ?
                    <div style={{textAlign: 'center'}}>No images have been generated for this scene yet. Please go to the Scene Creator to request images.</div>:""
                }

                <SharingModal 
                    entityId={this.props.match.params.id}
                    entityType={'scene'}
                    setSharingModal={this.setSharingModal}
                    modalVisible={this.state.visible}
                    publicLinks={this.state.public_links}
                    collectionId={this.state.collection_id}
                    loggedInUserAccess={this.state.user_access_level}
                />

                <Modal
                    destroyOnClose={true}
                    maskClosable={false}
                    visible={this.state.flag_scene_modal}
                    onCancel={this.cancelReportSceneIssue}
                    width={800}
                    className="modal-flag">
                    <div style={{padding:"0"}}>
                        <h2 ><span className="manrope f-18 w-700">Report an Issue</span></h2>
                        {this.state.single_scene ?
                         <h3 className="flag-text" style={{display:"flex",justifyContent:"space-between"}}>
                            <span>Selected Lifestyle 360</span>
                            <span onClick={this.changeSingleSceneState} style={{color: "#276DD7",cursor:"pointer"}}>Select More Scenes</span>
                         </h3>
                        : <h3 className="flag-text" style={{display:"flex",justifyContent:"space-between"}}>
                            <span>Select the lifestyle 360s you would like to flag</span>
                            <span onClick={this.selectAllScenes} style={{color: "#276DD7",cursor:"pointer"}}>{this.state.selectAllScenesFlag == false ? `Select All` : `Deselect All`}</span>
                        </h3>}
                        <Form onFinish={this.handleSceneReport}>
                            <div className={(this.state.viewable_three_sixties.length > 3 && this.state.single_scene == false)? "request-silo-scrollable flag-height" : ""} style={{marginBottom: 24}}>
                                <Row gutter={16}>
                                    {this.state.single_scene ?
                                        <Col span={24} className="flag-col-margin single">
                                            <span className="position-scene-name single">
                                                <div className="flag-scene-card-bg">
                                                    <div className="flag-scene-card-txt">
                                                        {this.state.selected_scene}
                                                    </div>
                                                </div>
                                            </span>
                                            <PanoramaThumbnailViewer threeSixtyUrl={ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + this.state.selected_scene + ".jpg"} customClass="flag-panorama-thumbnail-styles single"/>
                                        </Col> :
                                        this.state.viewable_three_sixties.map((name, index) => (
                                            !this.state.disabled_scenes.includes(name.filename.split('.')[0])&& (name.flagged_by_user===undefined || ( FileConstants.isMSProvider && name.flagged_by_user!==true)) ?
                                            <Col span={8} key={index} className="flag-col-margin">
                                                <span className="flag-checkbox-settings" style={{zIndex:"10"}}>
                                                    <Checkbox className="black-checkbox" onChange={() => this.onChangeFlagSceneCheck(name)} checked={this.state.checkedScene[name.filename.split('.')[0]]}/>
                                                </span>
                                                <span className="position-scene-name">
                                                    <div className="flag-scene-card-bg">
                                                        <div className="flag-scene-card-txt">
                                                            {name.filename.split('.')[0].split('_')[0]}
                                                        </div>
                                                    </div>
                                                </span>
                                                <PanoramaThumbnailViewer threeSixtyUrl={ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + name.filename} customClass="flag-panorama-thumbnail-styles"/>
                                            </Col>: ''
                                        ))}
                                </Row>
                            </div>
                            <span className="flag-form" style={{display: (this.state.checkSceneState || this.state.single_scene) ? "flex" : "none",marginBottom: 18}}>
                                <Form.Item name="scene_flag_reason" label="I am flagging because..." className="share-modal-input" style={{display:"inline-block",width: 350,margin:"0 !important"}} colon={false}>
                                    <Input.TextArea
                                    className='manrope f-12 w-300'
                                    onChange={this.changeSceneInput}
                                    //value={sceneInput}
                                    rows={4}
                                    autoSize={{
                                        maxRows: 4,
                                        minRows: 4
                                    }}
                                    placeholder="Describe the issue, example lighting etc."/>
                                </Form.Item>
                                <Form.Item
                                    className="share-modal-input"
                                    style={{display:"inline-block",width: 350,margin:"0 !important"}}
                                    name="upload_scene_screenshots"
                                    colon={false}
                                    label="Additional Information"
                                    valuePropName="scene_filelist"
                                    getValueFromEvent={this.normFile}>
                                    <Upload {...Constants.getUploadProps(this.state.platform)}
                                    onRemove={file => {
                                        message.success(`${file.name} removed successfully!`)
                                        const index = this.state.screenshotSceneList.indexOf(file);
                                        const newFileList = this.state.screenshotSceneList.slice();
                                        newFileList.splice(index, 1);
                                        this.setState({
                                            screenshotSceneList: newFileList
                                        });
                                        newFileList.forEach((file) => {
                                            if (file.status !== "error"){
                                                this.setState({
                                                    hasError: false
                                                })
                                            }
                                            else {
                                                this.setState({
                                                    hasError: true
                                                })
                                            }
                                        })
                                        return true;
                                    }}
                                    progress= {{
                                        strokeColor: {
                                        '0%': '#108ee9',
                                        '100%': '#87d068',
                                        },
                                        strokeWidth: 3,
                                        format: percent => `${parseFloat(percent.toFixed(2))}%`,
                                        }}
                                        openFileDialogOnClick={!(this.validateFileUploads(this.state.screenshotSceneList) == 'error')}
                                        accept="application/msword,.zip,.rar,image/jpeg,image/tiff,image/gif,image/svg,image/png,application/pdf"
                                        fileList={this.state.screenshotSceneList}
                                        onChange={this.handleSceneScreenshots}
                                        className="material-upload-box scroll-upload"
                                        name="logo" multiple={true} listType="text">
                                        <Button className="material-photos-btn flag"><span className="material-photos-btn-txt">Attach files or screenshots here <PaperClipOutlined /></span></Button>
                                        {(this.validateFileUploads(this.state.screenshotSceneList) == 'error') && (<Button
                                            onClick={() => Constants.triggerFailedFilesUpload('upload_scene_screenshots', this.state.screenshotSceneList, this.setScreenshotSceneList)}
                                            disabled={this.state.screenshotSceneList.length === 0}
                                            loading={this.state.uploading}
                                            className="retry-btn flag" ghost
                                            >
                                            {this.state.uploading ? <span>Retrying...</span>  : 'Upload Failed. Click to Retry.'}
                                        </Button>)}
                                    </Upload>
                                </Form.Item>
                            </span>
                            {(this.validateFileUploads(this.state.screenshotSceneList) == 'error') ?
                            <div className='retry-error-message'>{this.state.errorMessage}</div>
                            : ''}
                            <div style={{padding:"10px 16px",marginTop: (this.state.single_scene ? 18 : 18)}} className="note-flag">
                                <span className="scene-note flag">
                                    Note: <div style={{color:"#333333"}}>Your issue will be reported to All3D admin. We will reach out to you via email. </div>
                                </span>
                            </div>
                            <Col className="justify-in-end">
                                <Form.Item style={{textAlign:"right",marginTop: 32,marginRight:"12px"}}>
                                    <div>
                                        <Button  onClick={this.cancelReportSceneIssue} className='modal-okay-gray square font-14'>
                                            Cancel
                                        </Button>
                                    </div>
                                </Form.Item>
                                <Form.Item style={{textAlign:"right",marginTop: 32}}>
                                    <Tooltip placement="top" title={<span className="manrope f-12 white">Please add a description of your issue to continue</span>}>
                                        <span>
                                            <Button className="modal-okay square font-14" htmlType="submit" disabled={(this.state.checkSceneState == false && this.state.single_scene == false)|| sceneInput == '' || this.state.buttonLoader}>
                                                Send {this.state.buttonLoader ? <LoadingOutlined/> : ""}
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </Form.Item>

                            </Col>
                        </Form>

                    </div>
                </Modal>
                <Modal
                    destroyOnClose={true}
                    visible={this.state.flag_image_modal}
                    onCancel={this.cancelReportImageIssue}
                    width={800}
                    maskClosable={false}
                    className="modal-flag">
                    <div style={{padding:"0"}}>
                    <h2><span className="manrope f-18 w-700">Report an Issue</span></h2>
                        {this.state.single_image ?
                        <h3 className="flag-text" style={{display:"flex",justifyContent:"space-between"}}>
                            <span>Selected Lifestyle Image</span>
                            {this.displayFlagIfUnhappyButton() &&<span onClick={this.changeSingleImageState} style={{color: "#276DD7",cursor:"pointer"}}>Select More Images</span>}
                        </h3>:
                        <h3 className="flag-text" style={{display:"flex",justifyContent:"space-between"}}>
                            <span>Select the lifestyle images you would like to flag</span>
                            <span onClick={this.selectAllImages} style={{color: "#276DD7",cursor:"pointer"}}>{this.state.selectAllImagesFlag == false ? `Select All` : `Deselect All`}</span>
                        </h3>}
                        <Form onFinish={this.handleImageReport}>
                            <div className={this.state.single_image ? "" : "request-silo-scrollable flag-height"}>
                                <Row gutter={16}>
                                    {this.state.single_image ?
                                        <Col span={24} className="flag-col-margin single">
                                            <img src={ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + this.state.selected_image.replace('tiff', 'jpg')}
                                            className="flag-image-thumbnail-single"/>
                                        </Col> :
                                        this.state.renders.slice(0).reverse().map(x => (
                                        this.hideFlaggedImages(x) ?
                                        <Col span={8} className="flag-col-margin">
                                            <span className="flag-checkbox-settings" style={{zIndex:"10"}}>
                                                <Checkbox className="black-checkbox" onChange={() => this.onChangeFlagImageCheck(x)} checked={this.state.checkedImage[x.filename.split('.')[0]]}/>
                                            </span>
                                            <Card className='fiu-selection-card' style={{flexDirection: 'column'}}>
                                                <img src={((ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + x.filename.replace('tiff', 'jpg')))}
                                                className="flag-image-thumbnail"  style={{border: 'none'}}/>
                                                <Tooltip title={<span className='manrope f-12 white'>
                                                    {x.display_name ? x.display_name :
                                                        "Untitled" + x.filename.split('.')[0].split('_')[1]}
                                                </span>}>
                                                    <p className='manrope f-12 black-33 fiu-text'>
                                                        {x.display_name ? x.display_name :
                                                        "Untitled" + x.filename.split('.')[0].split('_')[1]}
                                                    </p>
                                                </Tooltip>
                                            </Card>
                                        </Col> : ""
                                    ))}
                                </Row>
                            </div>
                            <span className="flag-form" style={{marginTop: 22,display: (this.state.checkImageState || this.state.single_image) ? "flex" : "none"}}>
                                <Form.Item name="image_flag_reason" label="I Am Flagging Because..." className="share-modal-input" style={{display:"inline-block",width: 350,margin: "0 !important"}} colon={false}>
                                    <Input.TextArea
                                    className='manrope f-12 w-300'
                                    // defaultValue={''}
                                    //value={imageInput}
                                    onChange={this.changeImageInput} id="image-text-row"
                                    autoSize={{
                                        maxRows: 4,
                                        minRows: 4
                                    }} rows={4} placeholder="Describe the issue, example lighting etc."/>
                                </Form.Item>
                                <Form.Item
                                    className="share-modal-input"
                                    style={{display:"inline-block",width: 350,margin: "0 !important"}}
                                    name="upload_image_screenshots"
                                    colon={false}
                                    label="Additional Information"
                                    valuePropName="image_filelist"
                                    getValueFromEvent={this.normFile}>
                                        <Upload {...Constants.getUploadProps(this.state.platform)}
                                        progress= {{
                                            strokeColor: {
                                            '0%': '#108ee9',
                                            '100%': '#87d068',
                                            },
                                            strokeWidth: 3,
                                            format: percent => `${parseFloat(percent.toFixed(2))}%`,
                                            }}
                                        onRemove={file => {
                                            message.success(`${file.name} removed successfully!`)
                                            const index = this.state.screenshotImageList.indexOf(file);
                                            const newFileList = this.state.screenshotImageList.slice();
                                            newFileList.splice(index, 1);
                                            this.setState({
                                                screenshotImageList: newFileList
                                            });
                                            newFileList.forEach((file) => {
                                                if (file.status !== "error"){
                                                    this.setState({
                                                        hasError: false
                                                    })
                                                }
                                                else {
                                                    this.setState({
                                                        hasError: true
                                                    })
                                                }
                                            })
                                            return true;
                                        }}
                                        openFileDialogOnClick={!(this.validateFileUploads(this.state.screenshotImageList) == 'error')}
                                        accept="application/msword,.zip,.rar,image/jpeg,image/tiff,image/gif,image/svg,image/png,application/pdf"
                                        fileList={this.state.screenshotImageList}
                                        onChange={this.handleImageScreenshots}
                                        className="material-upload-box scroll-upload"
                                        name="logo" multiple={true} listType="text">
                                            <Button className="material-photos-btn flag"><span className="material-photos-btn-txt">Attach files or screenshots here <PaperClipOutlined /></span></Button>
                                            {(this.validateFileUploads(this.state.screenshotImageList) == 'error') && (<Button
                                                onClick={() => Constants.triggerFailedFilesUpload('upload_image_screenshots', this.state.screenshotImageList, this.setScreenshotImageList)}
                                                disabled={this.state.screenshotImageList.length === 0}
                                                loading={this.state.uploading}
                                                className="retry-btn flag" ghost
                                                >
                                                {this.state.uploading ? <span>Retrying...</span>  : 'Upload Failed. Click to Retry.'}
                                            </Button>)}
                                        </Upload>
                                </Form.Item>
                            </span>
                            {(this.validateFileUploads(this.state.screenshotImageList) == 'error') ?
                            <div className='retry-error-message'>{this.state.errorMessage}</div>
                            : ''}
                            <div style={{padding:"10px 16px",marginTop: (this.state.single_image ? 18 : 18)}} className="note-flag">
                                <span className="scene-note flag">
                                    Note: <div style={{color:"#333333"}}>Your issue will be reported to All3D admin. We will reach out to you via email. </div>
                                </span>
                            </div>
                           <Col className="justify-in-end">
                                <Form.Item style={{textAlign:"right",marginTop: 32,marginRight:"12px"}}>
                                    <div>
                                        <Button onClick={this.cancelReportImageIssue} className='modal-okay-gray square font-14'>
                                            Cancel
                                        </Button>
                                    </div>
                                </Form.Item>
                                <Form.Item style={{textAlign:"right",marginTop: 32}}>
                                    <Tooltip placement="top" title={<span className="manrope f-12 white">Please add a description of your issue to continue</span>}>
                                        <span>
                                            <Button className="modal-okay square font-14" htmlType="submit" disabled={((this.state.checkImageState == false && this.state.single_image == false ) || imageInput == '' || this.state.buttonLoader)}>
                                                Send {this.state.buttonLoader ? <LoadingOutlined/> : ""}
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </Form.Item>

                            </Col>
                        </Form>
                    </div>
                </Modal>
                <Modal
                    destroyOnClose={true}
                    visible={this.state.flag_tour_modal}
                    onCancel={this.cancelReportTourIssue}
                    closable
                    width={800}
                    maskClosable={false}
                    className="modal-flag">
                    <div style={{padding:"0"}}>
                    <h2><span className="manrope f-18 w-700">Report an Issue</span></h2>
                    <h3 className="flag-text" style={{display:"flex",justifyContent:"flex-start"}}>
                        <span>Selected 3D Tour</span>
                    </h3>
                    <Form onFinish={this.handleTourReport}>
                            <Row gutter={16}>
                                <Col span={24} className="flag-col-margin">
                                    <Card id={`vs-container-flag`}>
                                        <div className="hotspot" id="3d-tour-flag">
                                            <img className="hotspotImage" src="https://all3d-demos.s3.amazonaws.com/Virtual-Showroom/Resources/hotspot.gif" alt="Hotspot" />
                                            <span className="tooltiptext" id="toolTip"></span>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        <span className="flag-form" style={{marginTop: 10,display: "flex"}}>
                            <Form.Item name="tour_flag_reason" label="I Am Flagging Because..." className="share-modal-input" style={{display:"inline-block",width: 350,margin:"0px"}} colon={false}>
                                <Input.TextArea
                                className='manrope f-12 w-300'
                                //value={tourInput}
                                // defaultValue={''}
                                onChange={this.changeTourInput}  autoSize={{
                                        maxRows: 4,
                                        minRows: 4
                                    }}  rows={4} placeholder="Describe the issue, example lighting etc."/>
                            </Form.Item>
                            <Form.Item
                                className="share-modal-input"
                                style={{display:"inline-block",width: 350,margin: "0 !important"}}
                                name="upload_tour_screenshots"
                                colon={false}
                                label="Additional Information"
                                valuePropName="tour_filelist"
                                getValueFromEvent={this.normFile}>
                                <Upload {...Constants.getUploadProps(this.state.platform)}
                                   accept="application/msword,.zip,.rar,image/jpeg,image/tiff,image/gif,image/svg,image/png,application/pdf"
                                    progress= {{
                                    strokeColor: {
                                    '0%': '#108ee9',
                                    '100%': '#87d068',
                                    },
                                    strokeWidth: 3,
                                    format: percent => `${parseFloat(percent.toFixed(2))}%`,
                                    }}
                                    onRemove={file => {
                                        message.success(`${file.name} removed successfully!`)
                                        const index = this.state.screenshotTourList.indexOf(file);
                                        const newFileList = this.state.screenshotTourList.slice();
                                        newFileList.splice(index, 1);
                                        this.setState({
                                            screenshotTourList: newFileList
                                        });
                                        newFileList.forEach((file) => {
                                            if (file.status !== "error"){
                                                this.setState({
                                                    hasError: false
                                                })
                                            }
                                            else {
                                                this.setState({
                                                    hasError: true
                                                })
                                            }
                                        })
                                        return true;
                                    }}
                                    openFileDialogOnClick={!(this.validateFileUploads(this.state.screenshotTourList) == 'error')}
                                    fileList={this.state.screenshotTourList}
                                    onChange={this.handleTourScreenshots}
                                    className="material-upload-box scroll-upload"
                                    name="logo" multiple={true} listType="text">
                                    <Button className="material-photos-btn flag"><span className="material-photos-btn-txt">Attach files or screenshots here <PaperClipOutlined /></span></Button>
                                    {(this.validateFileUploads(this.state.screenshotTourList) == 'error') && (<Button
                                        onClick={() => Constants.triggerFailedFilesUpload('upload_tour_screenshots',this.state.screenshotTourList, this.setScreenshotTourList)}
                                        disabled={this.state.screenshotTourList.length === 0}
                                        loading={this.state.uploading}
                                        className="retry-btn flag" ghost
                                        >
                                        {this.state.uploading ? <span>Retrying...</span>  : 'Upload Failed. Click to Retry.'}
                                    </Button>)}
                                </Upload>
                            </Form.Item>
                        </span>
                        {(this.validateFileUploads(this.state.screenshotTourList) == 'error') ?
                            <div className='retry-error-message'>{this.state.errorMessage}</div>
                        : ''}
                        <div style={{padding:"10px 16px",marginTop:18}} className="note-flag">
                            <span className="scene-note flag">
                                Note: <div style={{color:"#333333"}}>Your issue will be reported to All3D admin. We will reach out to you via email. </div>
                            </span>
                        </div>

                        <Col className="justify-in-end">
                            <Form.Item style={{textAlign:"right",marginTop: 32,marginRight:"12px"}}>
                                    <div>
                                        <Button onClick={this.cancelReportTourIssue} className='modal-okay-gray square font-14'>
                                           Cancel
                                        </Button>
                                    </div>
                                </Form.Item>
                            <Form.Item style={{textAlign:"right",marginTop: 32}}>
                                <Tooltip placement="top" title={<span className="manrope f-12 white">Please add a description of your issue to continue</span>}>
                                    <span>
                                        <Button className="modal-okay square font-14" htmlType="submit" disabled={tourInput == '' || this.state.buttonLoader}>
                                            Send {this.state.buttonLoader ? <LoadingOutlined/> : ""}
                                        </Button>
                                    </span>
                                </Tooltip>

                            </Form.Item>
                        </Col>
                    </Form>

                    </div>
                </Modal>
                <SuccessModal
                visible={this.state.scene_shared}
                onCancel={this.sceneShare}
                heading={"Scene Shared Successfully!"}
                footer={[
                    <div className="justify-in-center">
                    <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={this.sceneShare}>
                        Okay
                    </Button>
                    </div>
                ]}
                />
                
                <SuccessModal
                visible={this.state.printable_image_request}
                onCancel={this.printableImagesRequest}
                heading={"Request Sent Successfully!"}
                text={"Images will be shared in your email shortly."}
                footer={[
                    <div className="justify-in-center">
                    <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={this.printableImagesRequest}>
                        Okay
                    </Button>
                    </div>
                ]}
                />

                <Modal
                    visible={this.state.embed_visible}
                    onOk={this.handleEmbedOk}
                    onCancel={this.handleEmbedCancel}
                    className="modal-share"
                    footer={[
                        <div className="justify-in-end">
                        <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-12" onClick={this.handleEmbedOk}>
                            Embed
                        </Button>
                        </div>
                    ]}
                >
                    <div style={{padding:"16px 6px 0 14px"}}>
                      <h2 className='manrope f-18 black-00 w-600'>Embedding Details</h2>
                    <div style={{marginTop:"20px"}}>
                        {this.state.displayimg ?
                        <div >
                            <h4 className="manrope f-14 black-33">URL(s) for Lifestyle Images</h4>
                            {this.state.renders.map(x => (
                                (x.status == 'rendered' && !x.template_render)?
                                <Paragraph className="modal-radio-anchor bg manrope f-12"  copyable={{ text: `${ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI}${this.props.match.params.id}/${x.filename}` }}>
                                    <a href={ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + x.filename} target="_blank">
                                    {x.filename}
                                    </a>
                                </Paragraph>:''
                            ))}
                        </div>: ''}
                        {this.state.display360 ?
                        <div>
                            <h4 className="manrope f-14 black-33">URL(s) for Lifestyle 360s</h4>
                            {this.state.viewable_three_sixties.length == 1 ?
                            <Paragraph className="modal-radio-anchor bg manrope f-12" copyable={{ text: `https://app.all3d.ai/360_public_viewer/scene/${this.props.match.params.id}/${this.state.viewable_three_sixties[0].filename}` }}>
                                <Link to={'/360_public_viewer/scene/' + this.props.match.params.id + '/360Camera.jpg'} target="_blank">
                                    Default Space
                                </Link>
                            </Paragraph> :
                            this.state.viewable_three_sixties.map((url) => (
                                <Paragraph className="modal-radio-anchor bg manrope f-12"  copyable={{ text: `https://app.all3d.ai/360_public_viewer/scene/${this.props.match.params.id}/${url.filename}` }}>
                                    <Link to={'/360_public_viewer/scene/' + this.props.match.params.id + '/' + url.filename} target="_blank">
                                    {url.filename}
                                    </Link>
                                </Paragraph>
                            ))}
                        </div> : ''}

                        {this.state.displayTour ?
                            <div>
                                <h4 className="manrope f-14 black-33">URL for 3D Tour</h4>
                                    <Paragraph className="modal-radio-anchor bg manrope f-12" copyable={{ text: `https://app.all3d.ai/3d_tour_viewer/${this.props.match.params.id}` }}>
                                        <Link to={'/3d_tour_viewer/' + this.props.match.params.id} target="_blank">
                                            3D Tour
                                        </Link>
                                    </Paragraph>

                            </div> : ''}

                        <div><h4 className="manrope f-14 black-33">Embed in your site</h4></div>
                        <p className="modal-radio-anchor manrope f-12" style={{color:"#999999"}} id="iframe-text">{'<iframe style="width: 500px; height: 300px;" src="' + 'http://app.all3d.ai/360_viewer/abIal9ok)_ApI/' + this.props.match.params.id + '"></iframe>'}</p>
                    </div>
                    </div>
                </Modal>
                
                <Modal
                    visible={this.state.print_ready_visible}
                    onOk={this.handleGetPrintReadyImagesOk}
                    onCancel={this.handlePrintReadyCancel}
                    className="modal-share"
                    footer={[
                        <div className="justify-in-end">
                        <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-12" onClick={this.handleGetPrintReadyImagesOk}>
                            Confirm
                        </Button>
                        </div>
                    ]}
                >
                    <div style={{padding:"16px 6px 0 14px"}}>
                      <h2 className='manrope f-18 black-00 w-600'>Get Printer Ready Images</h2>
                    <div style={{marginTop:"20px"}}>
                                By confirming, Printer Ready Images will be generated and shared with you shortly via email.
                        </div>
                    </div>
                </Modal>
                
                <SuccessModal
                visible={this.state.reportSuccess}
                onCancel={() => this.setState({reportSuccess: false})}
                heading={"Issue Reported Successfully!"}
                text={"Your issue has been reported to the admin successfully. We will get back to you as soon as possible."}
                footer={[
                    <div className="justify-in-center">
                        <Button className="modal-okay square font-14" key="submit" onClick={() => {
                        this.setState({reportSuccess: false});
                        window.location.reload();
                        } }>
                            Okay
                        </Button>
                    </div>
                ]}
                />

                <Modal
                className="modal-lifestyle-request"
                centered={true}
                maskClosable={false}
                width={800}
                onCancel={() => this.setState({confirmation_modal: false})}
                visible={this.state.confirmation_modal}
                bodyStyle={{padding: 0}}
                footer={[
                <div className="justify-in-center">
                    <Button className="modal-okay-gray square font-14"  htmlType="submit" onClick={() => this.setState({confirmation_modal: false})}>
                        Cancel
                    </Button>
                    <Button className="modal-okay square font-14" htmlType="submit" onClick={() => this.openConfigModal(this.state.selected_image)}>
                        Yes, I am Sure
                    </Button>
                    </div>
                ]}>
                    <div style={{padding:"40px"}}>
                            <img src='/img/alert-triangle.svg' style={{width:"64px",height:"64px",margin:"auto"}} className="justify-in-center" alt="Alert"/>

                        <div style={{textAlign: "center",margin: "20px 0"}}>
                            <h2 className="modal-heading">This complaint hasn't been resolved yet! </h2>
                            <p className="artist-upload-txt" style={{textTransform:"unset"}}>Are you sure you still want to generate high resolution image for this render?</p>
                        </div>
                    </div>
                </Modal>
                <Modal
                    maskClosable={false}
                    className="aspect-ratio-modal"
                    visible={this.state.config_modal}
                    onCancel={this.cancelConfigModal}
                    destroyOnClose={true}
                    centered={true}
                    width={900}
                    footer={[
                        <div className={this.state.request_status == "payment_required"? "justify-space-between" : "justify-in-end"}>
                        {this.state.request_status == "payment_required" ?
                            <div className="manrope f-16 black-55">
                                Price: <span className="manrope f-16 w-700" style={{color: "#25D6A4"}}>${this.state.high_res_price}</span>
                            </div>: ''}
                        <div className="justify-in-end">
                        <Button disabled={this.state.request_image_loader} className="modal-okay-gray square font-14" onClick={this.cancelConfigModal}>
                            Cancel
                        </Button>
                        <Button disabled={this.state.request_image_loader || this.state.config_exists == -1 || this.state.card_save_flow_triggered} className="modal-okay square font-14" htmlType="submit" onClick={this.state.config_exists == 1 ? this.requestImage : this.goToSceneCreator}>
                        {this.state.config_exists == 1 ? (this.state.card_status == 1 && (this.state.request_status == "allowed" || this.state.request_status == "always_allowed" || this.state.request_status == "update_subscription") ? "Confirm" :
                        this.state.card_status == 0 && this.state.request_status == "payment_required" ?"Confirm & Enter Payment Details" : "Confirm") :this.state.config_exists == 0 ? 'Go to Scene Creator' : ''}
                        <>{(this.state.request_image_loader) ? <LoadingOutlined spin/> : ""}</>
                        </Button>
                        </div>
                        </div>
                    ]}
                    >
                    <div style={{padding:"40px"}}>
                        <h2 className="snapshot-modal-heading">Snapshot Configuration</h2>
                        <Row type="flex" className={this.state.config_exists == 1 ? "" : "justify-in-center"}>
                            <Col span={12}>
                                <img src={(ENVIRONMENT.getBaseURL(this.state.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + this.props.match.params.id + '/' + this.state.selected_image).replace('tiff', 'jpg')} className="snapshot-modal-img no-border">
                                </img>
                            </Col>
                            {this.state.config_exists == 1 ?
                            <Col span={12} style={{paddingLeft: 20}}>
                                <div style={{display:"flex",justifyContent:"flex-start"}}>
                                    <h5 className="modal-text small" style={{display:"flex",justifyContent:"flex-start",alignItems:'center'}}><span>Orientation and Resolution of Original Image</span><Tooltip placement="right" title={'These are the orientation and resolution of your original image. We will maintain the original orientation with the new resolution.'}><img style={{marginLeft: 10}} src="/img/info-btn.png"/></Tooltip></h5>
                                </div>
                                <Row gutter={12}>
                                    <Col>
                                        <div className="note-bg-artist res"><span className="width-height-label greyish-black s-modal">{this.state.orientation}</span></div>
                                    </Col>

                                    <Col style={{display:"contents"}}>
                                        <div className="note-bg-artist res"><span className="width-height-label greyish-black s-modal">{Math.round(this.state.display_width) + ' x ' + Math.round(this.state.display_height)}</span></div>
                                    </Col>
                                </Row>
                                <h5 className="modal-text small" style={{display:this.state.orientation =='Custom' ? "none" : "block",fontSize:"16px",marginTop:32}}>Resolution</h5>
                                <Row style={{display:this.state.orientation == 'Custom' ? "none" : "flex"}}>
                                    <Col>
                                        <Radio.Group style={{marginBottom: 8}} className="res-radio-settings" onChange={this.onResolutionChange} defaultValue={1} value={this.state.resolution_status}>
                                            <Radio style={{radioStyleInline,marginBottom: 24}} value={2}><span style={{color: this.state.resolution_status == 2 ? "#555555" : "#777777"}}  className="radio-btn-change scene-res" >2K ({this.state.imageResArr[1]})</span></Radio>
                                            <Radio style={{radioStyleInline,marginBottom: 24}} value={1}><span style={{color: this.state.resolution_status == 1 ? "#555555" : "#777777"}} className="radio-btn-change scene-res" >4K ({this.state.imageResArr[0]})</span></Radio>
                                            <Radio style={{radioStyleInline,marginBottom: 24}} value={4}>
                                                <span className={`radio-btn-change scene-res ${ this.state.resolution_status == 4 ? `custom-res-message` : ``}`} style={{color: this.state.resolution_status == 4 ? "#555555" : "#777777"}}> Add Custom Resolution
                                                {
                                                    this.state.resolution_status == 4 ?
                                                    <span style={{display:"flex",marginTop: 9}}>
                                                        <div className="custom-input-box" style={{marginRight:8}}>
                                                            <span>Width</span>
                                                            <div className="custom-input-contain">
                                                                <InputNumber onChange={this.onImageWidthChange} value={this.state.image_width}  min={1} max={8096} />
                                                            </div>
                                                        </div>

                                                        <div className="custom-input-box">
                                                            <span>Height</span>
                                                            <div className="custom-input-contain">
                                                                <InputNumber onChange={this.onImageHeightChange} value={this.state.image_height} min={1} max={8096} />
                                                            </div>
                                                        </div>
                                                    </span> : ""
                                                }
                                                </span>
                                            </Radio>
                                        </Radio.Group>
                                    </Col>
                                </Row>
                                <h2  style={{display: this.state.orientation == 'Custom' ? "block" : "none",fontSize: 16,marginTop:32}} className="modal-text bold">Enter Resolution</h2>
                                <Row style={{display: this.state.orientation == 'Custom' ? "block" : "none"}} >
                                    <Col className="custom-res-row">
                                        <div className="custom-aspects s-modal" style={{marginRight:20}}>
                                            <span className="width-height-label s-modal">Width</span>
                                            <InputNumber className="width-height-label s-modal" style={{width: 100,marginLeft:10}} autoFocus={false}  value={this.state.image_width_res}  min={1} max={8096} onChange={this.onChangeWidthResolution}/>
                                        </div>
                                        <div className="custom-aspects s-modal" style={{marginRight:20}}>
                                            <span className="width-height-label s-modal">Height</span>
                                            <InputNumber className="width-height-label s-modal" style={{width: 100,marginLeft:10}} autoFocus={false}  value={this.state.image_height_res}  min={1} max={8096} onChange={this.onChangeHeightResolution}/>
                                        </div>
                                        <div className="custom-aspects s-modal">
                                            <span className="width-height-label s-modal">Unit</span>
                                            <Input className="width-height-label s-modal" style={{width: 68,marginLeft:10}} value={'Pixels'} readOnly autoFocus={false} />
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{display: this.state.orientation == 'Custom' ? "block" : "none",marginBottom:32}} >
                                    <Col className="custom-res-row">
                                            <div className="note-bg">
                                                <span className="width-height-label s-modal" style={{color: "#262626"}}>Note:&nbsp;</span><span className="width-height-label s-modal" style={{color: "#595959"}}>The width and height you add here will be the resolution in pixels for your image.</span>
                                            </div>
                                    </Col>
                                </Row>
                                <Row gutter={[32, 32]} className="justify-in-start" style={{alignItems:"flex-start"}}>
                                    <Col>
                                        <h5 className="modal-text small" style={{fontSize:"16px"}}>Select DPI</h5>
                                        <Radio.Group
                                            defaultValue={300}
                                            value={this.state.dpi}
                                            onChange={this.onChangeDPI}
                                            buttonStyle="solid"
                                            className="dpi-radio">
                                            <Radio.Button value={300} className="dpi-settings">300 DPI</Radio.Button>
                                            <Radio.Button value={400} className="dpi-settings">400 DPI</Radio.Button>
                                            <Radio.Button value={600} className="dpi-settings">600 DPI</Radio.Button>
                                        </Radio.Group>
                                    </Col>

                                    <Col>
                                        <h5 className="modal-text small" style={{fontSize:"16px"}}>Image Format</h5>
                                        <Radio.Group value={this.state.image_format} defaultValue={'jpg'} onChange={this.onImageFormatChange} className="modal-text bold">
                                            <Radio style={radioStyleInline} value={'jpg'}><span style={{color: this.state.image_format == 'jpg' ? "#555555" : "#777777"}} className="radio-btn-change scene-res">JPG</span></Radio>
                                            <Radio style={radioStyleInline} value={'png'}><span span style={{color: this.state.image_format == 'png' ? "#555555" : "#777777"}} className="radio-btn-change scene-res">PNG</span></Radio>
                                            <Radio style={radioStyleInline} value={'tiff'}><span span style={{color: this.state.image_format == 'tiff' ? "#555555" : "#777777"}} className="radio-btn-change scene-res">TIFF</span></Radio>
                                        </Radio.Group>
                                    </Col>
                                </Row>
                            </Col>:
                            this.state.config_exists == 0 && !this.state.generated_by_flag ?
                            <Col span={12} style={{paddingLeft: 20}}>
                                <div className="note-bg config">
                                    <div className="width-height-label black" style={{marginBottom: 8}}>Oops! We can not process this request</div>
                                    <div className="width-height-label s-modal gray">This image belongs to an older scene. Unfortunately, we can not generate a new version with updated settings for this image. Please go to the Scene Creator to capture a new image and request a high resolution render.</div>
                                </div>
                            </Col> : this.state.config_exists == 0 && this.state.generated_by_flag ?
                            <Col span={12} style={{paddingLeft: 20}}>
                                <div className="note-bg config">
                                    <div className="width-height-label black" style={{marginBottom: 8}}>Oops! We can not process this request</div>
                                    <div className="width-height-label s-modal gray">
                                        Unfortunately, we can not generate a high resolution render for this image.
                                        Some key information was lost while updating this image for you which prevents us from generating a new high resolution render.</div>
                                </div>
                            </Col>
                            :
                            <Col span={12} style={{paddingLeft: 20,fontSize: 32}} className="justify-in-center"><LoadingOutlined/></Col>}
                        </Row>
                    </div>
                </Modal>

                <SuccessModal
                 onCancel={this.cancelSuccessModal}
                visible={this.state.success_res}
                heading={"Image Requested Successfully!"}
                text={"You have successfully requested a high resolution image. This image will be added to your Scene Library as soon as it is rendered."}
                footer={[
                    <div className="justify-in-center">
                        <Button className="modal-okay square font-14" key="submit" onClick={() => {
                        this.setState({reportSuccess: false});
                        window.location.reload();
                        } }>
                            Okay
                        </Button>
                    </div>
                ]}
                />

                <PaymentFailureModal
                paymentFailureMessage={this.state.payment_failure_message}
                setPaymentFailureModal={this.setPaymentFailureModal}
                updatePaymentDetailsOnFailure={this.updatePaymentDetailsOnFailure}
                paymentFailureModal={this.state.payment_failure_modal}
                />

                <SubscriptionPackageUpdate
                subscriptionPlan={this.state.subscription_plan}
                setSubscriptionPlan={this.setSubscriptionPlan}
                currentPackage={this.state.current_package}
                currentSubscriptionPlan={this.state.current_package}
                isSubscriptionActive={this.state.is_subscription_active}
                visible={this.state.subscription_modal}
                onCancel={this.cancelSubscriptionModal}
                heading={<span>You have suceeded the quota for request scene renders in your current plan. Do you wannt to upgrade your current plan?</span>}
                text={<span>Your current package is selected by default. Select another package to upgrade. </span>}
                footer={[
                    <div>
                        {
                            this.state.request_status == 'update_subscription' ?
                            <span style={{color:"#276DD7"}} className='justify-in-start manrope f-14'>
                                Please upgrade your package to keep using this service.
                            </span>
                            :
                            ''
                        }
                    <span className="justify-in-end">
                    <Button key="back" style={{textAlign:"center"}} className="modal-okay-gray square font-14" onClick={this.cancelSubscriptionModal}>
                        Cancel
                    </Button>
                        {(this.state.card_status == 1)?
                        <Button disabled={this.state.subscription_plan == '' || this.state.subscription_loader} key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={this.upgradeSubscriptionPlan}>
                        {this.state.subscription_loader ? <span>Upgrading Plan <LoadingOutlined spin /></span>:<span>Confirm & Process Payment </span>}
                        </Button>:
                        <Button disabled={this.state.subscription_plan == '' || this.state.subscription_loader} key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={this.upgradeSubscriptionPlan}>
                        {this.state.subscription_loader ? <span>Upgrading Plan <LoadingOutlined spin /></span>:<span>Confirm & Enter Payment Details </span>}
                    </Button>
                        }
                    </span>
                    </div>
                ]}
                />

            <Modal
                className="modal-share-success"
                width={600}
                visible={this.state.deleteLifestyleModal}
                closable={false}
                footer={[
                    <span className="justify-in-center">
                        <Button key="back" style={{textAlign:"center"}} className="modal-okay-gray square font-14" onClick={() => this.setDeleteLifestyleModal(false)}>
                            Cancel
                        </Button>
                        <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => this.deleteLifestyle()}>
                            {this.state.deleteLifestyleLoader? <span> Deleting <LoadingOutlined spin /></span>:<span> Okay </span>}
                        </Button>
                    </span>
                ]}
                centered={true}
                >
                <div style={{margin:"0px auto 0px auto",textAlign:"center",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                    <div>
                        <h2 className="manrope f-16 black-00 w-600">You're about to delete a Lifestyle Image</h2>
                        <p className="manrope f-14 black-55">This will remove your lifestyle image from your scene. Are you sure you want to delete?</p>
                    </div>
                </div>
            </Modal>

            <Modal
                visible={this.state.edit_modal}
                className="modal-share"
                width={788}
                maskClosable={false}
                closable={false}
                destroyOnClose={true}
                onCancel={() => {this.setState({edit_modal: false})}}
                footer={[]}
            >
                <div style={{ padding: "16px 6px 0 14px" }}>
                    <div className="justify-space-between" style={{ marginBottom: 20 }}>
                        <h2 className="manrope f-18 black-14 w-700">Edit Information</h2>
                        <h2 className="manrope f-18 grey-77" style={{ cursor: "pointer" }} onClick={() => {this.setState({edit_modal: false})}}>X</h2>
                    </div>
                    <Form
                        className="product-edit-form" initialValues={{
                            scene_name: this.state.name,
                            tags: this.state.tags
                        }}
                        onFinish={this.updateScene} layout="vertical">
                        <Row type='flex' gutter={12}>
                            <Col span={24} style={{ marginBottom: 10 }}>
                                <Form.Item colon={false} label={<span className="manrope f-14 black-55">Name</span>} name="scene_name" 
                                    rules={[
                                        { required: true, message: 'Please enter scene name.' },
                                        { validator: this.validateNotBlankSceneName}
                                        ]}>
                                    <Input disabled={this.state.buttonLoader} placeholder={'Enter Scene Name'} className="manrope f-12 black-55 library-search" />
                                </Form.Item>
                            </Col>
                            
                            <Col span={24} style={{ marginBottom: 10 }}>
                                <Form.Item colon={false} label={<span className="manrope f-14 black-55">Tag(s)</span>} name="tags" rules={[{ required: false, type: 'array', message: 'Please add tag(s).' }]}>
                                    <Select disabled={this.state.buttonLoader} className="manrope f-12 black-55 select-tags" mode="tags" placeholder="Enter tag(s)">
                                        <Option value="" className="manrope f-12 black-55 select-view"></Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            {this.state.error_message == "" ? '' :
                                <Col span={24} style={{ marginTop: 10 }}>
                                    <div className="justify-space-between err-bg manrope f-12 red-error" style={{ marginRight: 8 }}><img src="/img/error-small.png" style={{ marginRight: 8 }} /><span>{this.state.error_message}</span></div>
                                </Col>}
                        </Row>
                        <Row style={{display: 'flex', flexDirection: 'row-reverse'}}>
                            <Form.Item>
                                <Button className='modal-okay square font-14' disabled={this.state.buttonLoader} type="primary" htmlType="submit">
                                    Save {this.state.buttonLoader ? <LoadingOutlined /> : ''}
                                </Button>
                            </Form.Item>
                        </Row>

                    </Form>

                </div>
            </Modal>

            <Modal
                className="modal-share-success"
                width={620}
                visible={this.state.delete360Modal}
                closable={false}
                footer={[
                    <span className="justify-in-center">
                        <Button key="back" style={{textAlign:"center"}} className="modal-okay-gray square font-14" onClick={() => this.setDelete360Modal(false)}>
                            Cancel
                        </Button>
                        <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => this.delete360()}>
                            {this.state.delete360Loader ? <span> Deleting <LoadingOutlined spin /></span>:"Okay"}
                        </Button>
                    </span>
                ]}
                centered={true}
                >
                 <div style={{margin:"0px auto 0px auto",textAlign:"center",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
                    <div>
                        <h2 className="manrope f-16 black-00 w-600">You're about to delete a Lifestyle 360</h2>
                        <p className="manrope f-14 black-55">This will remove your lifestyle 360 from your scene. Are you sure you want to delete?</p>
                    </div>
                </div>
            </Modal>
            <Modal
            className="modal-space"
            width={700}
            visible={this.state.collaborate_camera}
            closable={false}
            bodyStyle={{padding: 0}}
            footer={[
                <span className="justify-in-end">
                    <Button disabled={this.state.collab_button_loader} key="back" style={{textAlign:"center"}} className="modal-okay-gray square font-12" onClick={this.collaborateCloseConfirmModal}>
                        Cancel
                    </Button>
                    <Button disabled={this.state.collab_button_loader} key="back" style={{textAlign:"center"}} className="modal-okay square font-12" onClick={this.openCollaborateTool}>
                        {this.state.collab_button_loader ? <span>Please wait.. <LoadingOutlined spin /></span>:<span>Go to tool </span>}
                    </Button>
                </span>
            ]}
            >
            <div style={{padding: 30, display: 'flex'}}>
                <Col span={24}>
                <h2 className="modal-heading manrope f-14 black-00 w-600">Go to collaborate tool? </h2>
                <p className="manrope f-12 black-33">This tool will allow you to post any feedback you have for this render. </p>
                </Col>
            </div>
        </Modal>
            <CardSaveDrawer
            setCardSaveFailure={this.setCardSaveFailure}
            visible={this.state.card_save_drawer}
            saveRequestIntermedietely={this.saveRequestIntermedietely}
            onClose={() => this.setCardSaveDrawer(false)}
            />
            <TemplateRequestModal
            submit_button_loader={this.state.send_to_store_loader}
            scene_id={this.props.match.params.id}
            renders={this.state.store_render_choices}
            space_categories={this.state.space_categories}
            visible={this.state.scene_template_modal}
            onCancel={this.cancelTemplateModal}
            onOk={this.sendTemplateRequest}
            platform={this.state.platform}
            />
            <WarningModal
            visible={this.state.card_save_failure}
            onCancel={() => this.setCardSaveFailure(false)}
            heading={"Unable to Save Payment Details"}
            text={"Failed to process payment details. Please try another payment method."}
            footer={[
            <div className="justify-in-end">
                <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => this.setCardSaveFailure(false)}>
                    Okay
                </Button>
            </div>
            ]}/>
            <MspWarningModal
                visible={this.state.is_msp_user_warning_modal}
                handleModalClose={this.handleWarningModalClose}
                isUserIncorrect = {this.state.is_wrong_msp_user}
            />
        </div>}
            </CustomerMainLayout> :
            this.state.spaces_checked ?
            <AddCollectionFlow
            space_areas={this.state.space_areas}
            collection_id={this.state.collection_id}
            room_id={this.state.room_id}
            scene_name={this.state.scene_name_edit}
            update_collection={true}
            step={4}
            model_selection_stage={2}
            collection_name={this.state.collection_name}
            scene_id={this.props.match.params.id}
            setCollectionView={this.setCollectionView}
            /> : <DottedLoader/>
        );
    }
}

export default SceneRenders;
