import './EntityUpdate.scss'
import React, { useEffect, useState } from 'react';
import { Col, Row, Input, Select, message, TreeSelect, Button, Modal} from "antd";
import { ArrowRightOutlined, LoadingOutlined } from '@ant-design/icons';
import ENVIRONMENT from '../../../../environments'
import axios from 'axios';
import _ from "lodash";
import * as Styles from '../../../../Styles';
const { Option } = Select;

const categoryStyle = Styles.categoryStyle;

const ProductActionForm = () => {
    const [inputValue, setInputValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [productData, setProductData] = useState({});
    const [hasError, setHasError] = useState(false);
    const [categoriesData, setCategoriesData] = useState([]);
    const [prevCategory, setPrevCategory] = useState('');
    const [categoryValue, setCategoryValue] = useState('');
    const [categoryTitle, setCategoryTitle] = useState('');
    const [placementType, setPlacementType] = useState('');
    const [updateVisible, setUpdateVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [updateLoader, setUpdateLoader] = useState(false);

    const replaceValueWithKey = (data) => {
        return data.map(item => {
            const updatedItem = {
                ...item,
                value: item.key || item.category_id,  // Use key or category_id as value
            };
    
            if (updatedItem.children && updatedItem.children.length > 0) {
                updatedItem.children = replaceValueWithKey(updatedItem.children);
            }
    
            return updatedItem;
        });
    };    

    useEffect(() => {
        if(categoryTitle !== prevCategory){
            setUpdateVisible(true);
        }
        else{
            setUpdateVisible(false);
        }
    }, [categoryValue])

    useEffect(() => {
        const payload = {
            output: 'tree',
            disable_parents: 'true'
        };
        axios.post(ENVIRONMENT.ADMIN_CATEGORY_GET_BATCH, payload)
        .then(res => {
            let category = replaceValueWithKey(res.data);
            console.log(category);
            setCategoriesData(category);
        });
    }, [])

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        if (hasError && e.target.value) {
            setHasError(false);
        }
    };

    const handleSubmit = () => {
        console.log('Input Value:', inputValue);
        if (!inputValue) {
            setHasError(true);
            message.error('Product ID cannot be empty.');
            return;
        }
        let payload = {
            product_id: `${inputValue}`
        }
        setIsLoading(true);
        setIsDataLoaded(false);
        axios.post(ENVIRONMENT.FETCH_PRODUCT, payload).then((res) => {
            if(!_.isEmpty(res.data)){
                const data = res.data;
                const placementType = data.placement_type || "Floor";
                setPlacementType(placementType);
                setCategoryValue(data.category);
                setPrevCategory(data.category);
                setProductData({ ...data, placement_type: placementType });
                message.info('Product data fetched successfully');
                setIsDataLoaded(true);
            }
            else{
                message.error('Error in fetching product data');
            }
            setIsLoading(false);
        })
        .catch(() => {
            message.error('Error in fetching product data');
            setIsLoading(false);
        });
    };

    const onChangeCategoryValue = (value, label) => {
        const findCategoryById = (categories, id) => {
            for (const category of categories) {
                if (category.key === id) {
                    return category;
                }
                if (category.children.length) {
                    const found = findCategoryById(category.children, id);
                    if (found) {
                        return found;
                    }
                }
            }
            return null;
        };

        const selectedCategory = findCategoryById(categoriesData, value);
        if (selectedCategory) {
            setCategoryValue(value);
            setCategoryTitle(selectedCategory.title);
            setPlacementType(selectedCategory.placement_type || "Floor");
        }
    }

    const handleUpdate = (regenerate_job = false) => {
        setUpdateLoader(true);
        let payload = {
            product_id: inputValue,
            category: categoryTitle,
            placement_type: placementType,
        }

        if(regenerate_job){
            payload.regenerate_job = true;
        }

        axios.post(ENVIRONMENT.CHANGE_PRODUCT_CATEGORY, payload).then((res) => {
            if(regenerate_job){
                message.info('Category updated successfully and Job Regenrated');
            }
            else
            {
            message.info('Category updated successfully');
            }
            setModalVisible(false);
            setUpdateLoader(false);
        })
        .catch(() => {
            message.error('Error in updating category');
        });
    }
    
    return (
        <div style={{ padding: '20px' }}>
          <Row gutter={16}>
            <Col span={8}>
              <div style={{ marginBottom: '16px' }}>
                <label>All3D ID</label>
                <div className="input-container">
                    <Input
                        placeholder="Please enter All3D product ID"
                        type="number"
                        value={inputValue}
                        onChange={handleInputChange}
                        className={`custom-input ${hasError ? 'input-error' : ''}`}
                        onPressEnter={handleSubmit}
                        suffix={
                        <div
                            className="suffix-container"
                            onClick={handleSubmit}
                        >
                            {isLoading ? <LoadingOutlined className="custom-icon" /> : <ArrowRightOutlined className="custom-icon" />}
                        </div>
                        }
                    />
                    </div>
              </div>
              {isDataLoaded ? 
              <>
                <div style={{ marginBottom: '16px' }}>
                     <label>Category</label>
                        <TreeSelect
                            showSearch
                            style={{ width: '100%' }}
                            className="manrope f-14 black-55 custom-tree-select"
                            dropdownStyle={categoryStyle}
                            placeholder="Select or type in your product category"
                            treeData={categoriesData}
                            notFoundContent={<span  style={{textAlign: 'center'}}><h5 style={{textAlign: 'center'}} className='manrope f-10 black-55'>No Category Found</h5></span>}
                            onChange={(value, label) => onChangeCategoryValue(value, label)}
                            filterTreeNode={(inputValue, treeNode) => 
                                treeNode.title.toLowerCase().includes(inputValue.toLowerCase())
                            }
                            value={categoryValue}
                        >
                        </TreeSelect>
                </div>
                <div style={{ marginBottom: '16px' }}>
                    <label>Placement Type</label>
                    <div className='manrope f-14 label-data'>
                        <span>{placementType}</span>
                    </div>
                </div>  
                
                {updateVisible ? <div style={{ marginBottom: '16px', display: 'flex', justifyContent: 'center'}}>
                    <Button className='new-product-btn red f-14' onClick={() => setModalVisible(true)}>
                        Update
                    </Button>
                </div> : ''} 
              </> : ''}
            </Col>
            {isDataLoaded ? <Col span={8} offset={4}>
              <div
                style={{
                  height: '100%',
                  maxHeight: '405px',
                  maxWidth: '405px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: '0.1px solid'
                }}
              >
                <img
                    src={productData.thumbnail.replace(ENVIRONMENT.DEFAULT_BASE_URL, ENVIRONMENT.getBaseURL(productData.platform))}
                    style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    }}
                />
              </div>
            </Col> : ''}
          </Row>

          <Modal
            className="modal-share-success"
            width={600}
            centered={true}
            visible={modalVisible}
            closable={false}
            footer={[
                <span className="justify-in-center">
                    <Button style={{ textAlign: "center" }} className="modal-okay-gray square font-12" onClick={() => setModalVisible(false)}>
                        Cancel
                    </Button>
                    <Button style={{ textAlign: "center" }} className="modal-okay square font-12" onClick={() => handleUpdate()}>
                        {updateLoader ? <span> Updating... <LoadingOutlined spin /></span> : <span>Update </span>}
                    </Button>
                    <Button style={{ textAlign: "center" }} className="modal-okay square font-12" onClick={() => handleUpdate(true)}>
                        {updateLoader ? <span> Updating... <LoadingOutlined spin /></span> : <span>Update and Regenerate</span>}
                    </Button>
                </span>
            ]}>
            <div style={{ margin: "30px auto 0px auto", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <h2 className="manrope f-16 black-00 w-600">You are about to change this Product's Category</h2>
            </div>
        </Modal>
        </div>
      );
};
export default ProductActionForm;