import React, { useState, useEffect } from 'react';
import { InputNumber, Slider, Switch} from 'antd';

function ClippingControls (props) {

    return (
        <>
            <div className='clipping-switch-div'>
                <span className='clipping-heading manrope f-12'>Clipping</span>
                <Switch checked={props.clipping} className='clipping-switch' onChange={props.toggleClipping}/>
            </div>
            <div className='clipping-setting-div'>
                <Slider className='clipping-slider' disabled={!props.clipping} onChange={props.setClipping} tooltipVisible={false} trackStyle={{backgroundColor:"#276dd7"}} railStyle={{backgroundColor:"#cccccc"}} handleStyle={{backgroundColor:"#ffffff", borderColor:"#276dd7"}} step={5} min={0} max={500} value={props.clipping_value}/>
                <InputNumber
                formatter={(value) => `${value} inches`}
                parser={(value) => (value.replace('inches', ''))}
                disabled={!props.clipping} onChange={props.setClipping} step="5" className='clipping-number-input' value={props.clipping_value} min={0} max={500}/>

            </div>

        </>
    )
}

export default ClippingControls