import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Drawer, Col, TreeSelect, Select, Tag, Slider } from 'antd';
import './ProductFilterDrawer.scss'
import _ from "lodash" // Import the entire lodash library
import FileConstants from '../../../../FileConstants';
import StyleCategory from '../../FormUtilities/StyleCategorySelect';
import ColorSelect from '../../FormUtilities/ColorSelect';
import * as Styles from '../../../../Styles';
import AddProductsToSceneContext from '../../ContextFiles/AddProductsToSceneContext';
import AddProductContext from '../../ContextFiles/AddProductContext';

const categoryStyle = Styles.categoryStyle;

const ProductFiltersDrawer = (props) => {
    const { filtersApplied, filtersDrawer, setFiltersApplied, setFiltersDrawer,
        productCategoriesData, productParentCateogries, productMaterialsData,
        setFilterTagFunction, setReturnSearchValFunction, libraryType, maxPriceProduct,
        setAppliedFiltersList } = useContext(props.scene_flow ? AddProductContext : AddProductsToSceneContext);

    const [productCategoryValue, setProductCategoryValue] = useState([]);
    const [productCategoryValueWithChilds, setProductCategoryValueWithChilds] = useState([]);
    const [, setCategorySearchValue] = useState([]);
    const [productStyleCategory, setProductStyleCategory] = useState([]);
    const [colorValue, setColorValue] = useState([]);
    const [productMaterials, setProductMaterials] = useState([]);
    const [materialsCategoryValueWithChilds, setMaterialsCategoryValueWithChilds] = useState([]);
    const [productModelType, setProductModelType] = useState([]);
    const [maxPrice, setMaxPrice] = useState(0);
    const [minPrice, setMinPrice] = useState(0);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        //display tags when this is clicked
        if (productCategoryValue.length > 0
            || (productStyleCategory.length > 0)
            || (minPrice != 0) || (maxPrice != maxPriceProduct)
            || (colorValue.length > 0)
            || (productMaterials.length > 0)
            || (productModelType.length > 0)) {
            setFiltersApplied(true);
            setAppliedFiltersList(getAppliedFilters())
            forceUpdate();
        } else {
            setFiltersApplied(false);
            setAppliedFiltersList([])
        }
    }, [productCategoryValue, productStyleCategory, colorValue, productMaterials, productModelType, minPrice, maxPrice])

    useEffect(() => {
        setMaxPrice(maxPriceProduct);
    }, [maxPriceProduct])

    const closeFiltersDrawer = () => {
        setFiltersDrawer(false);
    }

    const onChangePrice = (value) => {
        setMinPrice(value[0]);
        setMaxPrice(value[1]);
    }

    const getAppliedFilters = () => {
        let appliedFilters = [...productCategoryValueWithChilds, ...productStyleCategory, ...colorValue,
                              ...materialsCategoryValueWithChilds, ...productModelType]
        appliedFilters = appliedFilters.map(item => item.toLowerCase())
        return appliedFilters;
      }

    const returnMaxPrice = () => {
        return maxPriceProduct;
    }

    const changeProductSearchCategory = (value) => {
        setCategorySearchValue(value);
    }

    const findItemsByParentId = (data, targetItemName, parentId) => {
        const items = [];
        for (const item of data) {
            if (item.parent_id === parentId) {
                items.push(item.name);
                items.push(...findItemsByParentId(data, targetItemName, item.category_id));
            }
        }
        return items;
    }


    const changeProductCategory = (value) => {
        let values = []

        value.map((name) => {
            const targetItemName = name;
            const targetItem = productParentCateogries.find(item => item.name === targetItemName);

            if (targetItem) {
                const result = findItemsByParentId(productParentCateogries, targetItemName, targetItem.category_id);
                result.unshift(name);
                values = [...values, ...result];
            }
        });

        if (value.length > 0
            || (productStyleCategory.length > 0)
            || (colorValue.length > 0)
            || (minPrice != 0) || (maxPrice != maxPriceProduct)
            || (productMaterials.length > 0)
            || (productModelType.length > 0)) {
            setFiltersApplied(true);
        } else {
            setFiltersApplied(false);
        }
        setProductCategoryValue(value);
        setProductCategoryValueWithChilds(values);
    }

    const closeTags = (entity, type) => {
        //remove the tag when this is clicked from showing result section
        if (type == 'product_category') {
            let arr = productCategoryValue.filter(category => { return entity != category });
            changeProductCategory(arr);
        } else if (type == 'product_style_category') {
            let arr = productStyleCategory.filter(style_category => { return entity != style_category });
            setProductStyleCategory(arr);
        } else if (type == 'product_color') {
            let arr = colorValue.filter(color => { return entity != color });
            setColorValue(arr);
        } else if (type == 'product_material') {
            let arr = productMaterials.filter(material => { return entity != material });
            setProductMaterials(arr);
        } else if (type == 'product_model_type') {
            let arr = productModelType.filter(model => { return entity != model });
            setProductModelType(arr);
        } else if (type == 'price') {
            setMinPrice(0);
            setMaxPrice(maxPriceProduct);
        }
    }

    const changeProductStyleCategory = (value) => {
        if (value.length > 0
            || (colorValue.length > 0)
            || (productMaterials.length > 0)
            || (minPrice != 0) || (maxPrice != maxPriceProduct)
            || (productModelType.length > 0)) {
            setFiltersApplied(true);
        } else {
            setFiltersApplied(false);
        }

        setProductStyleCategory(value);
    }

    const changeColorValue = (value) => {
        if (value.length > 0
            || (productStyleCategory.length > 0)
            || (productMaterials.length > 0)
            || (minPrice != 0) || (maxPrice != maxPriceProduct)
            || (productModelType.length > 0)) {
            setFiltersApplied(true);
        } else {
            setFiltersApplied(false);
        }

        setColorValue(value);
    }

    const changeProductModelType = (value) => {
        if (value.length > 0
            || (productStyleCategory.length > 0)
            || (productMaterials.length > 0)
            || (minPrice != 0) || (maxPrice != maxPriceProduct)
            || (colorValue.length > 0)) {
            setFiltersApplied(true);
        } else {
            setFiltersApplied(false);
        }
        setProductModelType(value);
    }

    const findChildrenByParentTitle = (parentTitle, node) => {
        if (node.title === parentTitle) {
            return node.children.map(child => child.title);
        } else if (node.children) {
            let result = [];
            for (const childNode of node.children) {
                result = result.concat(findChildrenByParentTitle(parentTitle, childNode));
            }
            return result;
        } else {
            return [];
        }
    }

    const changeProductMaterial = (value) => {

        let values = []

        value.map((name) => {
            const targetItemTitle = name;
            const targetItem = productMaterialsData.find(item => item.title === targetItemTitle);

            if (targetItem) {
                const result = findChildrenByParentTitle(targetItemTitle, targetItem);
                result.unshift(name);
                values = [...values, ...result]
            }
        });

        if (value.length > 0
            || (productStyleCategory.length > 0)
            || (colorValue.length > 0)
            || (minPrice != 0) || (maxPrice != maxPriceProduct)
            || (productModelType.length > 0)) {
            setFiltersApplied(true);
        } else {
            setFiltersApplied(false);
        }

        if (values.length <= 0) {
            values = value
        }

        setProductMaterials(value);
        setMaterialsCategoryValueWithChilds(values);
    }

    useEffect(() => {
        setFilterTagFunction(getFilteredTags())
    }, [filtersApplied, productCategoryValue, productStyleCategory, colorValue, productMaterials, productModelType, minPrice, maxPrice])


    const getFilteredTags = () => {
        let filteredTags = <>
            {filtersApplied && <Col span={24} className="text-left ml-10 mt-20">
                <h4 className='manrope f-14 black-55 italic'>Showing results for</h4>
                <div style={{ marginBottom: 20 }}>
                    {productCategoryValue.length > 0 && productCategoryValue.map((category) => {
                        return <Tag
                            onClose={() => closeTags(category, 'product_category')}
                            closable visible={true}
                            className='manrope f-12 black-55 tag-filter mb-10'>Category: <span className='manrope f-12 grey-77'>{category}</span></Tag>
                    })}

                    {(libraryType == "store" && (minPrice != 0 || maxPrice != maxPriceProduct)) &&
                        <Tag closable onClose={() => closeTags('none', 'price')} visible={true} className='manrope f-12 black-55 tag-filter'>Price: <span className='manrope f-12 grey-77'>
                            {minPrice != maxPrice ?
                                "$" + minPrice + " - $" + maxPrice
                                : "$" + minPrice}
                        </span>
                        </Tag>}

                    {productStyleCategory.length > 0 && productStyleCategory.map((category) => {
                        return <Tag
                            onClose={() => closeTags(category, 'product_style_category')}
                            closable
                            visible={true}
                            className='manrope f-12 black-55 tag-filter mb-10'>Style Category: <span className='manrope f-12 grey-77'>{category}</span></Tag>
                    })}

                    {colorValue.length > 0 && colorValue.map((category) => {
                        return <Tag
                            onClose={() => closeTags(category, 'product_color')}
                            closable
                            visible={true}
                            className='manrope f-12 black-55 tag-filter mb-10'>Color: <span className='manrope f-12 grey-77'>{category}</span></Tag>
                    })}

                    {productMaterials.length > 0 && productMaterials.map((category) => {
                        return <Tag 
                            onClose={() => closeTags(category, 'product_material')}
                            closable
                            visible={true}
                            className='manrope f-12 black-55 tag-filter mb-10'>Material: <span className='manrope f-12 grey-77'>{category}</span></Tag>
                    })}

                    {productModelType.length > 0 && productModelType.map((category) => {
                        return <Tag onClose={() => closeTags(category, 'product_model_type')}
                            closable
                            visible={true}
                            className='manrope f-12 black-55 tag-filter mb-10'>Product Type: <span className='manrope f-12 grey-77'>{FileConstants.PRODUCT_MODEL_TYPE[category]}</span></Tag>
                    })}

                </div>
            </Col>}
        </>

        return filteredTags;
    }


    const returnSearchValue = useCallback(
        (entity) => {
            //return value according to the filters applied
            if (getProductSearchValue(entity)) {
                return true;
            } else {
                return false;

            }
        },
        [filtersApplied, productCategoryValueWithChilds, productStyleCategory, colorValue, materialsCategoryValueWithChilds, productModelType] // Include dependent state variables here
    );


    const getProductSearchValue = (entity) => {
        if (entity) {
            let category = entity.category;
            let style_category = entity.style_category;
            let price = libraryType == "store" ? entity.price : 0;
            let color = entity.color_name;
            let materials = []
            if (entity.materials && entity.materials.length > 0) {
                materials = entity.materials
            }
            let product_model_type = entity.product_model_type;
            let product_category = false;
            let product_price = false;
            let style_category_matched = false;
            let color_matched = false;
            let material_matched = false;
            let model_type_matched = false;

            if (category != '') {
                if ((productCategoryValueWithChilds.length > 0 && productCategoryValueWithChilds.includes(category)) || productCategoryValueWithChilds.length == 0) {
                    product_category = true;
                }
            }

            if (minPrice <= price && maxPrice >= price) {
                product_price = true;
            }

            if ((productStyleCategory.length > 0 && productStyleCategory.includes(style_category)) || productStyleCategory.length == 0) {
                style_category_matched = true;
            }

            if ((colorValue.length > 0 && colorValue.includes(color)) || colorValue.length == 0) {
                color_matched = true;
            }

            if ((materialsCategoryValueWithChilds.length > 0 && materialsCategoryValueWithChilds.some(element => materials.includes(element))) || materialsCategoryValueWithChilds.length == 0) {
                material_matched = true;
            }

            if ((productModelType.length > 0 && productModelType.includes(product_model_type) && entity.is_store_item != 1) || productModelType.length == 0) {
                model_type_matched = true;
            }

            if (product_category && style_category_matched && material_matched && color_matched && model_type_matched && product_price) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    useEffect(() => {
        // Set the memoized 'returnSearchValue' function as a state variable
        setReturnSearchValFunction(() => returnSearchValue);
    }, [filtersApplied, productCategoryValueWithChilds, productStyleCategory, colorValue, materialsCategoryValueWithChilds, productModelType]);

    return (
        <Drawer
            width={360}
            visible={filtersDrawer}
            onClose={closeFiltersDrawer}
            placement="right">
            <></>
            <div style={{ padding: 20 }}>
                <h2 className="manrope f-18 black-14 w-700">Filters</h2>
                <div>
                    <h5 className='manrope f-16 black-14' style={{ marginTop: 20 }}>
                        Product Category
                    </h5>
                    <TreeSelect
                        value={productCategoryValue}
                        showSearch={true}
                        className="tree-select-material f-14 filter bg-light"
                        style={{ width: '100%' }}
                        multiple
                        dropdownStyle={categoryStyle}
                        placeholder="Search and select category"
                        treeData={productCategoriesData}
                        onChange={changeProductCategory}
                        onSearch={changeProductSearchCategory}
                        notFoundContent={<span><h5>No Result Found</h5><p>Press enter to add your category</p></span>}
                    />

                    <h5 className='manrope f-16 black-14' style={{ marginTop: 20 }}>Style Category</h5>
                    <StyleCategory
                        className="tree-select-material f-14 filter bg-light"
                        onChange={changeProductStyleCategory}
                        value={productStyleCategory}
                        placeholder="Search and select style category"
                        mode="tags"
                    />

                    <h5 className='manrope f-16 black-14' style={{ marginTop: 20 }}>Product Color</h5>
                    <ColorSelect
                        className="tree-select-material f-14 filter bg-light"
                        value={colorValue}
                        onChange={changeColorValue}
                        placeholder="Search and select color"
                        mode="tags"
                    />

                    <h5 className='manrope f-16 black-14' style={{ marginTop: 20 }}>Product Material</h5>
                    <TreeSelect
                        value={productMaterials}
                        showSearch={true}
                        className="tree-select-material f-14 filter bg-light"
                        style={{ width: '100%' }}
                        multiple
                        dropdownStyle={categoryStyle}
                        placeholder="Search and select material"
                        treeData={productMaterialsData}
                        onChange={changeProductMaterial}
                        onSearch={changeProductSearchCategory}
                        notFoundContent={<span><h5>No Result Found</h5><p>Press enter to add your category</p></span>}
                    >
                    </TreeSelect>
                    <h5 className='manrope f-16 black-14' style={{ marginTop: 20 }}>Product Type</h5>
                    <Select
                        className="tree-select-material f-14 filter bg-light"
                        value={productModelType}
                        onChange={changeProductModelType}
                        showSearch
                        placeholder="Search and select product type"
                        mode="tags"
                    >
                        {Object.keys(FileConstants.PRODUCT_MODEL_TYPE).map((key) => (
                            <Select.Option
                                key={key}
                                className="manrope f-10"
                                value={key}
                            >
                                {FileConstants.PRODUCT_MODEL_TYPE[key]}
                            </Select.Option>
                        ))}
                    </Select>
                    {libraryType == "store" &&
                        <>
                            <h5 className='manrope f-16 black-14' style={{ marginTop: 20 }}>Price</h5>
                            <Slider value={[minPrice, maxPrice]} className='adjust-pattern' onChange={onChangePrice} min={0} max={returnMaxPrice()} range defaultValue={[0, returnMaxPrice()]} />
                        </>}
                </div>
            </div>
        </Drawer>
    );

}

export default ProductFiltersDrawer;
