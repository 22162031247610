import React, { useEffect, useState, useRef } from "react";
import "antd/dist/antd.css";
import CustomerMainLayout from "../CustomerMainLayout";
import {
  LoadingOutlined,
  MessageOutlined,
  ArrowLeftOutlined,
  FilterOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import {
  Input,
  Col,
  Row,
  Card,
  Select,
  Radio,
  Form,
  Button,
  Modal,
  Checkbox,
  Tooltip,
  Breadcrumb,
  Tag,
  Tabs,
  Drawer,
} from "antd";
import DottedLoader from "../../DottedLoader";
import "@ant-design/compatible/assets/index.css";
import "../../../../styles/helper.scss";
import ENVIRONMENT from "../../../../environments";
import axios from "axios";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FileConstants from "../../../../FileConstants";
import { Header } from "antd/lib/layout/layout";
import NewProject from "./NewProject";
import AddNewProjectEntities from "./AddNewProjectEntities";

const AddNewProject = (props) => {
    const [step, setStep] = useState(1);
    const [projectName, setProjectName] = useState('');
    const [projectId, setProjectId] = useState('');
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedRooms, setSelectedRooms] = useState([]);
    const [selectedScenes, setSelectedScenes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [redirectionPath, setRedirectionPath] = useState(null);

    useEffect(() => {
        let redirection_path = new URLSearchParams(window.location.search).get("origin");
        setRedirectionPath(redirection_path);
    }, []);
    const handleUpdateProject = () => {
        setIsLoading(true)
        if (selectedProducts.length == 0 && selectedRooms.length == 0 && selectedScenes.length == 0) {
            window.location.href = 'projects?tab=requested';
        }
        let payload = {"project_id": projectId}
        if (selectedProducts.length != 0) {
            payload.products_list = selectedProducts
        }
        if (selectedRooms.length != 0) {
            payload.rooms_list = selectedRooms
        }
        if(selectedScenes.length != 0){
            payload.scenes_list = selectedScenes
        }
        payload.generate_thumbnail = true
        axios.post(ENVIRONMENT.ADD_ENTITY_BATCH_TO_PROJECT, payload)
        .then((response) => {
            window.location.href = `projects/${projectId}`;
        })
        .catch((error) => {
            setIsLoading(false);
        });
    }

    return ( 
        <CustomerMainLayout selected='13'>
            <div>
                {(step == 1)?
                    <Row style={{marginTop: 50}}>
                        <Col span={12} offset={6}>
                            <NewProject
                            redirectionPath={redirectionPath}
                            setProjectId={setProjectId}
                            setProjectName={setProjectName}
                            setStep={setStep}
                            />
                        </Col>
                        <Col span={8}></Col>
                    </Row>
                    :
                    ""
                }
                {(step == 2)?
                    <div>
                        <AddNewProjectEntities 
                        setStep={setStep}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                        selectedRooms={selectedRooms}
                        setSelectedRooms={setSelectedRooms}
                        selectedScenes={selectedScenes}
                        setSelectedScenes={setSelectedScenes}
                        handleUpdateProject={handleUpdateProject}
                        isLoading={isLoading}
                        />
                    </div>
                    :
                    ""
                }
            </div>
        </CustomerMainLayout>
        )
}

const mapStateToProps = (state) => state;

export default withRouter(connect(mapStateToProps)(AddNewProject));