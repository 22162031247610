import React, { useEffect, useState, useRef } from "react";
import "antd/dist/antd.css";
import CustomerMainLayout from "../CustomerMainLayout";
import {
  LoadingOutlined,
  MessageOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  UserAddOutlined,
  CloseOutlined,
  CheckOutlined,
  LockOutlined,
  ExpandAltOutlined,
  EditOutlined,
  DownloadOutlined
} from "@ant-design/icons";
import {
  Input,
  Col,
  Row,
  Select,
  Form,
  Button,
  Tooltip,
  Breadcrumb,
  Tag,
  Modal,
  Divider,
  message,
  Radio, 
  Image,
  Spin,
  Tabs,
  Card,
  Empty,
  Dropdown,
  Menu
} from "antd";
import DottedLoader from "../../DottedLoader";
import "@ant-design/compatible/assets/index.css";
import "../../../../styles/helper.scss";
import ENVIRONMENT from "../../../../environments";
import axios from "axios";
import { connect } from "react-redux";
import EntitiesList from "./EntitiesList";
import LifestylesList from "./LifestylesList";
import { withRouter } from "react-router-dom";
import FileConstants from "../../../../FileConstants";
import { Header } from "antd/lib/layout/layout";
import Filter from "./Filter";
import { getRelevanceBasedOrderedSearchResult } from '../../Utilities';
import ProjectAccess from "./ProjectAccessModal";
import ProjectConstants from "./Constants";
import ProjectComments from "./ProjectComments";
import ProductAssetModals from "../ProductComponents/ProductAssetModals";
import GenerateHighResModal from "./GenerateHighResModal"
import { getProductRender } from "../../../../redux/actions";
import * as Utilities from '../../Utilities';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import ProjectActionContext from "../../ContextFiles/ProjectActionContext";
import FlagSiloModal from "../ProductComponents/FlagSiloModal";
const { Option } = Select;
const { TabPane } = Tabs

const COMPANY_ID = localStorage.getItem("company_id");
const MANAGED_COMPANY_ID = localStorage.getItem("managed_company_id");
const SHARED_ENTITIES = localStorage.getItem("shared_entities");
const IS_MSPROVIDER = localStorage.getItem("is_msprovider") === 'true' ? true : false;

// Sort the lifestyles by their resolution in following order
const resolutionSortingOrder = ["4k", "2k", "1k", "custom"];

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
      color: "black",
    }}
    spin
  />
);

const RestrictedContainer = () => {
  return (
    <div className="blur-background">
      <div className="load-container">
        <LockOutlined className='on-start-load loading-icon' size="large" />
        <h3 className="load-message large">Oops! You don't have access!</h3>
        <h3 className="load-message">Please request the owner of this collaboration to grant you access.</h3>
      </div>
    </div>)
}

const CustomerProject = (props) => {
  const {
    match: { params },
  } = props;
  const projectId = params.id;
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [selectedModelType, setSelectedModelType] = useState('all');
  const [selectedReviewStatus, setSelectedReviewStatus] = useState([]);
  const [projectStatus, setProjectStatus] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [isApproveModal, setIsApproveModal] = useState(false);
  const [isAccessModal, setIsAccessModal] = useState(false);
  const [accessLevel, setAccesLevel] = useState("");
  const [accessActions, setAccessActions] = useState([]);
  const [isCommentsDrawer, setIsCommentsDrawer] = useState(false);
  const [projectUsers, setProjectUsers] = useState([]);
  const [tagUserList, setTagUserList] = useState([]);
  const [customGroups, setCustomGroups] = useState([]);
  const [isEditName, setIsEditName] = useState(false);
  const [editNameLoader, setEditNameLoader] = useState(false)
  const [projectMetadata, setProjectMetadata] = useState({})
  const [displayCreateSceneButton, setDisplayCreateSceneButton] = useState(false)
  const [commentCount, setCommentCount] = useState(0)
  const [lifestyleRenders, setLifestyleRenders] = useState([])
  const [selectedLifestyleRender, setSelectedLifestyleRender] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedResolutionFilter, setSelectedResolutionFilter] = useState("all")
  const [silos, setSilos] = useState([]);
  const [expandImage, setExpandImage] = useState(false)
  const [visibleImage, setVisibleImage] = useState({})
  const [productBasicInfo, setProductBasicInfo] = useState(null);
  const [selectedAssetModal, setSelectedAssetModal] = useState(null);
  const [currentProductId, setCurrentProductId] = useState(0);
  const [currentPage, setCurrentPage] = useState(props.match.path)
  const [singleImage, setSingleImage] = useState(true);
  const [imageFlagModal, setImageFlagModal] = useState(false);
  const [selectedSiloImage, setSelectedSiloImage] = useState(null);

  const [deleteSiloModal, setDeleteSiloModal] = useState(false)
  const [deleteSiloLoader, setDeleteSiloLoader] = useState(false)
  const [deleteSiloProductId, setDeleteSiloProductId] = useState(null)
  const [deleteSiloCompanyId, setDeleteSiloCompanyId] = useState(null)
  const [deleteSiloCustomerUsername, setDeleteSiloCustomerUsername] = useState(null)
  const [deleteSiloCameraName, setDeleteSiloCameraName] = useState(null)
  const [deleteSiloData, setDeleteSiloData] = useState(null)
  const [usersAndCustomGroupsList, setUsersAndCustomGroupsList] = useState([])
  const [loadingLifestyles, setLoadingLifestyle] = useState(false)
  const [preparingArchive, setPreparingArchive] = useState(false);

  const editNameRef = useRef(null)

  const modelTypeFilters = [
    {
      label: "All",
      value: "all"
    },
    {
      label: "Products",
      value: "product",
    },
    {
      label: "Scenes",
      value: "scene",
    },
    {
      label: "Spaces",
      value: "room",
    },
    {
      label: "Props",
      value: "props",
    }
  ];

  const resolutionFilter = [
    {
      label: "All",
      value: "all"
    },
    {
      label: "1K",
      value: "1k",
    },
    {
      label: "2K",
      value: "2k",
    },
    {
      label: "4K",
      value: "4k",
    },
    {
      label: "Custom",
      value: "custom",
    }
  ];

  const handleModelTypeChange = (e) => {
    setSelectedModelType(e.target.value);
  };

  const getProjectEntitiesPayload = () => {
    let payload = {
      order_by: "id desc",
      required_fields: [
        "id",
        "name",
        "customer_username",
        "company_id",
        "thumbnail",
        "status",
        "project_id",
        "entity_type",
        "product_model_type",
        "last_modified",
        "category",
        "platform",
      ],
      filter_string: `(project_id__exact='${projectId}')`,
    };

    return payload;
  };

  const fetchProjectEntities = async () => {
    let payload = getProjectEntitiesPayload();
    await axios
      .post(ENVIRONMENT.GET_PROJECTS_ENTITY_BATCH, payload)
      .then((res) => {
        console.log(res.data)
        const data = transformStatusData(res.data);
        setData(data);
        isSceneCreationAllowed(data)
        setFilteredData(data);
      });
  };

  const classifyImageResolution = (height, width) => {
    const maxDimension = Math.max(height, width);

    if (Math.abs(maxDimension - 1024) < 100) {
        return '1k';
    } else if (Math.abs(maxDimension - 2048) < 100) {
        return '2k';
    } else if (Math.abs(maxDimension - 4096) < 100) {
        return '4k';
    } else {
        return 'custom';
    }
};

  // Create a mapping from resolution to its position in custom order
  const orderMap = resolutionSortingOrder.reduce((acc, resolution, index) => {
    acc[resolution] = index;
    return acc;
  }, {});

  // Custom sort function
  const customSortKey = (item) => orderMap[item.resolution];


  const populateSceneRendersData = (data) => {
    let renders = []
    for(let item of data){
      if(item.final_renders && item.final_renders['final_renders']){
        for(let render of item.final_renders['final_renders']){
            let render_link = ENVIRONMENT.getBaseURL(item.platform) + ENVIRONMENT.SCENE_THUMBNAIL_URI + item.id + '/' + render.filename.replace('.tiff', '.jpg')
            let unique_filename = item.id + '_' + render.filename.split('.')[0]
            let resolution = classifyImageResolution(render.image_height, render.image_width)
            renders.push({
              ...render,
              'request_link': render_link,
              'scene_id': item.id,
              'unique_filename': unique_filename,
              'resolution': resolution,
              'platform': item.platform
            })
        }
      }
    }
    console.log(renders)
    setLifestyleRenders(renders)
    setLoadingLifestyle(false)
  }

  const populateSilosData = (data) => {
    let silos = []
    for(let item of data){
        if(item.image_status == 'completed' && (!('is_hidden' in item) || (item.is_hidden !== true))){
        let silo_base_uri = '';
        let unique_attributer = 'username';
        if(item.company_id){
            silo_base_uri = 'company/' + item.company_id
            unique_attributer = 'company';
        }
        else{
            silo_base_uri = item.username
        }
        let imageUrl = ENVIRONMENT.getBaseURL(item['platform']) + ENVIRONMENT.QA_IMG_URI + item['product_id'] + '/' + silo_base_uri + '/' + item.camera_name + '.' + item.image_data.img_format.replace('tiff', 'jpg');
        let unique_filename = item.product_id + '_' + item.camera_name + '_' + unique_attributer;
        let resolution = classifyImageResolution(item.image_data.height, item.image_data.width)
        silos.push({
            ...item,
            'request_link': imageUrl,
            'unique_filename': unique_filename,
            'resolution': resolution,
            'filename': item.camera_name + '.' + item.image_data.img_format.replace('tiff', 'jpg')
        })
        }
    }

    console.log(silos)
    setSilos(silos)
  }

  const isSceneCreationAllowed = (data) => {
    // this function sets the value of displayCreateSceneButton to true when at least one space and one product is approved

    let approved_or_in_review_items = data.filter(item => item.status == 'approved' || item.state == 'in_review')
    let allow_scene_creation = false

    if(approved_or_in_review_items.length > 0){
          allow_scene_creation = true
      }

    setDisplayCreateSceneButton(allow_scene_creation)
  }

  const fetchProjectDetails = async () => {
    let payload = {
      order_by: "id desc",
      required_fields: [
        "id",
        "name",
        "customer_username",
        "company_id",
        "meta_data",
        "status",
      ],
      filter_string: `(id__exact='${projectId}')`,
    };

    await axios.post(ENVIRONMENT.GET_PROJECT_BATCH, payload).then((res) => {
      setProjectName(res.data[0].name);
      setProjectStatus(res.data[0].status);
      setProjectMetadata(res.data[0].meta_data)
      setCommentCount(res.data[0].meta_data.comment_count ? res.data[0].meta_data.comment_count : 0)
    });
  };

  const transformStatusData = (data) => {
    const transformedData = data.map((item) => {
      let status = "";
      if (item.status == "5") {
        status = "approved";
      } else if ([2, 3, 6, 8, 11, 7].includes(item.status)) {
        status = "in_progress";
      } else if (item.status == "4") {
        status = "in_review";
      } else if (item.status == "-4" || item.status == "-5") {
        status = "rejected";
      }
      else if (item.status == "1" || item.status == "10") {
        status = "incomplete";
      }
      else if (item.status == "0" && item.entity_type == "scene"){
        status = "incomplete"
      }
      return {
        ...item,
        status: status,
      };
    });

    return getProjectDataWithKeywords(transformedData);
  };

  const fetchProjectProductSilos = () => {
    let payload = {"project_ids": [projectId]};
    axios
      .post(ENVIRONMENT.GET_PROJECTS_SILOS, payload)
      .then((res) => {
        populateSilosData(res.data);
      });
  };

  const getProjectLifestyles = () => {
    setLoadingLifestyle(true)
    if(projectId){
      let payload = {
        'project_id': projectId
      }
      axios.post(ENVIRONMENT.GET_PROJECTS_LIFESTYLES, payload)
      .then(res => {
        if(res && res.data){
          populateSceneRendersData(res.data)
        }
      })
    }

  }

  useEffect(() => {
    const fetchPageData = async () => {
      setIsDataLoading(true);
      getSharedUsers();
      await getProjectLifestyles();
      await fetchProjectDetails();
      await fetchProjectEntities();
      await fetchProjectProductSilos();
      setIsDataLoading(false);
    };
    fetchPageData();
  }, []);

  useEffect(() => {
    let filteredData = data;
    if (selectedModelType != 'all' && selectedModelType != 'renders') {
      if(selectedModelType != 'props'){
        filteredData = filteredData.filter((item) => {
          return selectedModelType === item.entity_type && item.product_model_type != 'props';
        });
      }
      else{
        filteredData = filteredData.filter((item) => {
          return selectedModelType === item.product_model_type;
        });
      }
    }
    if (selectedReviewStatus.length > 0) {
      filteredData = filteredData.filter((item) => {
        return selectedReviewStatus.includes(item.status);
      });
    }
    if (searchValue.length > 0) {
      filteredData = getRelevanceBasedOrderedSearchResult(searchValue, filteredData)
    }
    setFilteredData(filteredData);
  }, [searchValue, selectedModelType, selectedReviewStatus]);


  useEffect(() => {
    combineUsersAndGroups()
  }, [customGroups, tagUserList])


  useEffect(() => {
    if (accessLevel) {
      setAccessActions(ProjectConstants.access_actions[accessLevel]);
    }
  }, [accessLevel]);

  const closeTags = (entity) => {
    const reviewFilters = selectedReviewStatus.filter((item) => {
      return entity != item;
    });
    setSelectedReviewStatus(reviewFilters);
  };

  const handleApproveProject = () => {
    setLoading(true);
    let payload = { action: "move_to_approved", project_id: projectId };
    axios.post(ENVIRONMENT.UPDATE_PROJECT, payload).then((res) => {
      message.success(`Project approved successfully`);
      setLoading(false);
      window.location.href = `/library-projects?origin=${currentPage}`;
    });
  };

  const getProjectDataWithKeywords = (projects) => {
    const result = projects.map((obj) => {
      let keywords = [];

      for (const keyword of obj.name.replace(/[^a-zA-Z0-9 ]/g, ' ').split(' ')) {
        if (!keywords.includes(keyword)) {
          if (keyword.length > 1) {
            keywords.push(keyword.toLowerCase());
          }
        }
      }

      for (const keyword of obj.category?.replace(/[^a-zA-Z0-9 ]/g, ' ')?.split(' ')) {
        if (!keywords.includes(keyword)) {
          if (keyword.length > 1) {
            keywords.push(keyword.toLowerCase());
          }
        }
      }

      return { ...obj, 'keywords': keywords };
    });
    return result;
  };

  const handleEditCancel = () => {
    setIsEditName(false)
  }

  const handleNameSubmit = () => {
    let project_name = editNameRef.current.input.value
    console.log('edit name = ', project_name)
    setEditNameLoader(true)
    let payload = {
      action: 'update_name',
      project_id: projectId,
      name: project_name
    }

    axios.post(ENVIRONMENT.UPDATE_PROJECT, payload)
    .then(res => {
      if(res){
        setIsEditName(false)
        setEditNameLoader(false)
        
        message.info(res.data.message)
        if(res.data.status == 200)
          setProjectName(project_name)
      }
    })

  }

  const combineUsersAndGroups = () => {
    let usersAndGroups = []
    if (tagUserList.filter(item => item.access_level && item.accessed_from != 'restricted').length > 0){
      usersAndGroups = [{
        id: 'all',
        display: 'all',
        access_level: 'viewer'}].concat(tagUserList.filter(item => item.access_level && item.accessed_from != 'restricted'))

    }
    
    customGroups.filter(item => item.access_level && item.access_level != 'restricted').map(item => {
        // if check to avoid duplicate groups
        if(!usersAndCustomGroupsList.map(item => item.display).includes(item.group_name)){
            usersAndGroups.push({
                id: item.group_name,
                display: item.group_name,
                access_level: item.access_level
            })
        }
    })
    setUsersAndCustomGroupsList(usersAndGroups)
}

  const getSharedUsers = () => {
    let payload = {
      'action': 'get',
      'project_id': params.id
    };
    let company_id = COMPANY_ID;
    if (IS_MSPROVIDER && MANAGED_COMPANY_ID) {
      company_id = MANAGED_COMPANY_ID;
    }
    if (company_id) {
      payload['company_id'] = company_id;
    }
    axios.post(ENVIRONMENT.PROJECT_ACCESS_CONTROLLER, payload)
      .then(res => {
        if (res.data && res.data.length > 0) {
          setProjectUsers(res.data.filter(item => !item.group_id));
          
          //  get company custom groups
          let payload = {
            'action': 'list_groups'
          }
          if(localStorage.getItem('company_id'))
            payload['company_id'] = localStorage.getItem('company_id')
          else
            payload['created_by'] = localStorage.getItem('username')

          axios.post(ENVIRONMENT.CUSTOM_GROUP_CONTROLLER, payload).then(res1 => {
              if (res1?.data) {
                  addAccessValueOfCustomGroups(res1.data, res.data)
              }
          })

          let shared_customers = res.data.filter(item => !item.group_id).map(item => {
            return {
              id: item.username,
              display: item.is_msprovider ? item.username + ' (ALL3D)' : item.username,
              email: item.email,
              access_level: item.access_level
            }
          });
          console.log('Shared Users', shared_customers)
          setTagUserList(shared_customers);
          let user_access = res.data.filter(item => item.username == localStorage.getItem('username')).map(item => item.access_level);

          if (user_access && user_access.length > 0) {
            setAccesLevel(user_access[0]);
          } else if (props && props.match && props.match.path.includes("admin-collaborate-tool")) {
            setAccesLevel('view');
          }
          else {
            setAccesLevel('restricted');
          }
        } else {
          setAccesLevel('restricted');
        }

      });
  };

  function addAccessValueOfCustomGroups(customGroups, users) {
    /* 
        customGroups: list of user's or company's groups
        users: list of users with whom the item is shared. If user is part of a group it will have group_id as well.
    */

    // Iterate list of users, if user is part of a group then set the group will have same access as of this user.
    let groupIdAccessMapping = {}
    users.map((user) => {
        if (user.group_id) {
            groupIdAccessMapping[user.group_id] = user.access_level
        }
    })

    // display only those groups that has users
    const updatedGroups = customGroups.filter(item => item.users.length > 0).map((group) => ({
        ...group,
        access_level: groupIdAccessMapping[group.group_id] || 'restricted'  // if group does not has access level set that means this item was never shared with that group thus restricted access
    }))

    setCustomGroups(updatedGroups)
}

const getCombinedRendersCount = (count_key) => {
  let count = 0;
  if(projectMetadata[count_key]){
    count = Object.values(projectMetadata[count_key]).reduce((a, b) => a+b, 0)
  }
  return count
}

  const getTagName = (entity) => {
    let tag = "";
    ProjectConstants.review_status_filters.map((category, index) => {
      if (category.value == entity) {
        tag = category.label;
      }});
    ProjectConstants.model_type_filters.map((category, index) => {
      if (category.value == entity) {
        tag = category.label;
      }})
    return tag;
  }

  const showModal = (render) => {
    render.request_link = render.request_link.replace("_512", "");
    setSelectedLifestyleRender(render);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedLifestyleRender(null);
  };

  const onImageExpand = (name) => {
    let temp_arr = visibleImage;
    silos.map(x => {
      if (x.unique_filename == name) {
        temp_arr[x.unique_filename] = true;
      } else {
        temp_arr[x.unique_filename] = false;
      }
    });

    setVisibleImage(temp_arr)
    setExpandImage(true);
  }
    const changeVisibility = (visible, prevVisible) => {
      let temp_arr = [];
      if (imageFlagModal) { // do not expand image when complaint modal is opened
        silos.map(x => {
          temp_arr[x.unique_filename] = false;
        });
        setVisibleImage(temp_arr)
        setExpandImage(false)
      } else {
        if (expandImage) {
          silos.map(x => {
            if (visibleImage[x.unique_filename] && !visible) {
              temp_arr[x.unique_filename] = false;
            }
            else if (visibleImage[x.unique_filename] && visible) {
              temp_arr[x.unique_filename] = true;
            } else {
              temp_arr[x.unique_filename] = false;
            }
          });

          setVisibleImage(temp_arr);
          setExpandImage(false)

        } else {
          silos.map(x => {
            temp_arr[x.unique_filename] = false;
          });
          setVisibleImage(temp_arr);
          setExpandImage(false)
        }
      }
    }

   const selectAssetAndSetBasicInfo = (id, entity, asset) => {
    setCurrentProductId(id);
    let payload = { product_id: id, username: entity.customer_username }
    if (entity.company_id) {
        payload.company_id = entity.company_id
    }
    props.getProduct(payload);
    setProductBasicInfo(entity);
    setSelectedAssetModal(asset);
  }
 
  const isComplaintInprogress = (render) => {
    let complaint_inprogress = false;
    if (render.flagged_by_user) {
        complaint_inprogress = true
        for (let final_render of lifestyleRenders) {
            if (final_render.scene_id == render.scene_id && final_render.revision_of == render.filename) {
                complaint_inprogress = false;
                break;
            }
        }
    }
    return complaint_inprogress
    }
  

    async function checkFileExists(url, loader) {
        console.log('file existing');
        try {
          const response = await fetch(url, { method: 'HEAD' });
          if (response.ok) {
            window.location.href = url;
            setPreparingArchive(false);
            loader();
          } else {
            setTimeout(() => checkFileExists(url, loader), 5000); // try again in 5 seconds
          }
        } catch (error) {
          setTimeout(() => checkFileExists(url, loader), 5000); // try again in 5 seconds
        }
      }
    
    const downloadSiloImagesArchive = () => {
        setPreparingArchive(true);
        const loader = message.loading('Preparing your Archive...', 0);
        let downloadData = [];
        if(silos.length > 0){
            silos.map((silo) => {
                let silo_base_uri = '';
                if(silo.company_id){
                    silo_base_uri = 'company/' + silo.company_id
                }
                else{
                    silo_base_uri = silo.username
                }
                let fileData = {};
                let imageUrl =ENVIRONMENT.QA_IMG_URI + silo['product_id'] + '/' + silo_base_uri + '/' + silo.camera_name + '.' + silo.image_data.img_format.replace('tiff', 'jpg');
                fileData['url'] = imageUrl
                fileData['platform'] = silo.platform
                downloadData.push(fileData)
            });
        }

        const payload = {
            'files': downloadData,
            'project_id': projectId,
            'type': 'silos',
        };
        axios.post(ENVIRONMENT.INIT_BULK_PROJECT_ARCHIVE, payload).then((res) => {
            console.log('res = ', res);
            if (res['data'] && res['data']['archiveUrl']) {
              checkFileExists(res['data']['archiveUrl'], loader);
            }
          });
    }

    const downloadLifestyleImagesArchive = () => {
        setPreparingArchive(true);
        const loader = message.loading('Preparing your Archive...', 0);
        let downloadData = [];
        if(lifestyleRenders.length > 0){
            lifestyleRenders.map((render) => {
                if(!isComplaintInprogress(render) && (render.status == 'rendered' && render.filename != '360Camera.jpg'))
                {
                    let fileData = {};
                    let render_link = ENVIRONMENT.SCENE_THUMBNAIL_URI + render.scene_id + '/' + render.filename.replace('.tiff', '.jpg')
                    fileData['url'] = render_link
                    fileData['platform'] = render.platform
                    downloadData.push(fileData)
                }
            });
        }
        
        const payload = {
            'files': downloadData,
            'project_id': projectId,
            'type': 'lifestyles',
        };
        axios.post(ENVIRONMENT.INIT_BULK_PROJECT_ARCHIVE, payload).then((res) => {
            console.log('res = ', res);
            if (res['data'] && res['data']['archiveUrl']) {
              checkFileExists(res['data']['archiveUrl'], loader);
            }
          });
    }

    const openSingleFlagImage = (image) => {
      setExpandImage(false);
      setSingleImage(true);
      setSelectedSiloImage(image);
      setImageFlagModal(true);
  }

  const openDeleteSiloModal = (filename, product_id, company_id, username, silo_data) => {
        setDeleteSiloProductId(product_id)
        setDeleteSiloCompanyId(company_id)
        setDeleteSiloCameraName(filename)
        setDeleteSiloCustomerUsername(username)
        setDeleteSiloData(silo_data)
        setDeleteSiloModal(true)
        setExpandImage(false);
    }

    function getResolutionType(imageData) {
        const width = parseInt(imageData.width, 10);
        const height = parseInt(imageData.height, 10);
        
        const maxDimension = Math.max(width, height);
        if (maxDimension >= 0 && maxDimension <= 1024) {
            return '1K';
        } else if (maxDimension >= 1025 && maxDimension <= 2048) {
            return '2K';
        } else if (maxDimension >= 2049) {
            return '4K';
        }
    }

    const deleteSilo = () => {
        setDeleteSiloLoader(true)
        let payload = {
            action: "delete",
            product_id: deleteSiloProductId,
            camera_name: deleteSiloCameraName,
            customer_username: deleteSiloCustomerUsername,
        }
        if(deleteSiloCompanyId){
            payload.company_id = deleteSiloCompanyId
        }

        axios.post(ENVIRONMENT.POST_PRODUCT_ASSET_UPDATE, payload)
        .then((response) => {
            let metadata_payload = {"action": 'update_metadata' ,"project_id" : projectId}
            axios.post(ENVIRONMENT.UPDATE_PROJECT, metadata_payload)
        })
        .catch((error) => {
            message.error('Error in deleting Silo Image');
        });

        let siloData = silos;

        //Delete silo locally
        for (let silo of siloData) {
            if (silo.camera_name === deleteSiloCameraName && silo.username === deleteSiloCustomerUsername
                && silo.company_id === deleteSiloCompanyId && silo.product_id === deleteSiloProductId) {
                silo.is_hidden = true;
            }
        }

        //updating metdata
        let resolution = getResolutionType(deleteSiloData.image_data);
        let metadata = projectMetadata;
        metadata['silo_count'][resolution] = metadata['silo_count'][resolution] - 1;

        message.success('Silo Image Deleted.');
        populateSilosData(siloData);
        setDeleteSiloLoader(false);
        setDeleteSiloModal(false);
    }

  const menu_images = (
    <Menu style={{ width: "165px" }}>
        <Menu.Item key={1} className={lifestyleRenders && lifestyleRenders.length == 0 ? "not-allowed-setting" : ""}>
            <a  className='manrope f-12' target="_blank" 
            onClick={() => downloadLifestyleImagesArchive()} 
            disabled={lifestyleRenders && lifestyleRenders.length == 0 ? true : false}> 
            Lifestyle Image(s) <DownloadOutlined style={{float: "right"}} type="download" />
            </a>
        </Menu.Item>
        <Menu.Item key={2} className={silos && silos.length == 0 ? "not-allowed-setting" : ""}>
            <a  className='manrope f-12' target="_blank" 
            onClick={() => downloadSiloImagesArchive()}
            disabled={silos && silos.length == 0 ? true : false}> 
            Silo Image(s) <DownloadOutlined style={{float: "right"}} type="download" />
            </a>
        </Menu.Item>
    </Menu>
);

  const ApproveModal = () => {
    return (
      <Modal
        className="approve-modal"
        width={600}
        centered={true}
        visible={isApproveModal}
        onCancel={() => setIsApproveModal(false)}
        footer={[
          <span className="justify-in-end">
            <Button
              style={{ textAlign: "center" }}
              disabled={loading}
              className="modal-okay square font-14"
              onClick={handleApproveProject}
            >
              Approve and Move to Libraries {loading ? <LoadingOutlined /> : ""}
            </Button>
          </span>,
        ]}
      >
        <h2 style={{padding:20}} className="manrope f-16 black-00 w-600">
          Is Your Project Complete?
        </h2>
        <Divider style={{ margin: 0 }} />
        <p style={{padding:20}} className="manrope f-14 black-55 p-20">
          Approving your project will move the project and all associated
          products, spaces, and scenes to your libraries.
        </p>
      </Modal>
    );
  };

  return (
    <div className="my-products">
      <CustomerMainLayout>
        <Header className="product-nav-header projects-nav-bar project-page-header">
          <Row className="display-flex j-s-b a-c">
            <Col span={12}>
              <Breadcrumb className="display-flex j-s a-c">
                <Breadcrumb.Item>
                  <a
                    className="manrope f-14 lh-28 black-73 white-space-text"
                    href={`/`}
                  >
                    <ArrowLeftOutlined className="mr-8" />
                    Your Dashboard
                  </a>
                </Breadcrumb.Item>
                <Breadcrumb.Item className="manrope f-14 lh-28 black-73 white-space-text">
                  <a
                    className="manrope f-14 lh-28 black-73 white-space-text"
                    href={`/projects`}
                  >
                    Your Active Projects
                  </a>
                </Breadcrumb.Item>
                <Breadcrumb.Item className="manrope f-14 lh-28 black-d9 limit-lines limit-1">
                  {projectName}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            {projectMetadata && projectMetadata['collaboration_count'] > 0 && (
              <Col span={12} className="justify-in-end ">
                <a
                  href={`/collaborations?project_id=${projectId}`}
                  className="manrope f-14 w-500 blue"
                >
                  See Collaborations for this Project
                  <ArrowRightOutlined />
                </a>
              </Col>
            )}
          </Row>
        </Header>
        <Row
          className="justify-space-between"
          style={{ marginTop: 16, marginBottom: 16 }}
        >
          <>
            <Col span={12} className='display-flex j-s a-c'>
                {(isEditName) ?
                <div id="name_form" className='manrope f-16 w-400 grey-8c nameEditField'>
                    <Input ref={editNameRef} className='manrope f-16' style={{width:"90%"}} defaultValue={projectName} bordered={false} />
                    <div className='nameEditIcons'>
                        {editNameLoader ?
                        <LoadingOutlined className='ml-16' /> :
                        <>
                            <CloseOutlined className='nameIcon' onClick={handleEditCancel} />
                            <CheckOutlined className='nameIcon' onClick={handleNameSubmit} />
                        </>}
                    </div>
                </div> :
                <Tooltip title={<span className="manrope f-12 white">{projectName}</span>}>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                  <div
                      className="manrope f-20 w-600 clamp-text-400"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {projectName}
                  </div>
                  {accessLevel == 'owner' &&
                    <EditOutlined style={{color: "#276DD7"}} className='ml-10' onClick={()=> {
                        setIsEditName(true)
                        }} 
                    />}
                  </div>
                </Tooltip>
                }
              </Col>
            <Col lg={12} xl={12} className="justify-in-end">
              <Col lg={15} xl={15}>
                <Form>
                  <Input.Group compact size="large" className="d-inline">
                    <Input
                      placeholder="Search by product, space, etc..."
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                      }}
                      className="manrope f-14 mat-search-bar"
                      style={{ marginLeft: IS_MSPROVIDER ? 5 : "" }}
                    ></Input>
                  </Input.Group>
                </Form>
              </Col>
              <Col></Col>
              <Col></Col>
              <Col>
                <Filter
                  selectedModelType={selectedModelType}
                  selectedReviewStatus={selectedReviewStatus}
                  setSelectedModelType={setSelectedModelType}
                  setSelectedReviewStatus={setSelectedReviewStatus}
                  isReviewFilter={true}
                />
              </Col>
              {accessActions.includes('comment') &&
                <Col>
                  <Tooltip
                    title={
                      <span className="manrope f-12 white">Add Comments</span>
                    }
                  >
                    <div>
                        <div
                            className="icon-button-container ml-8"
                            onClick={() => setIsCommentsDrawer(true)}
                        >
                            <MessageOutlined />
                        </div>
                        <div className='comment-count'>{commentCount}</div>
                    </div>
                  </Tooltip>
                </Col>
              }
              <div id="components-dropdown-demo-dropdown-button">
                <Tooltip title="Download Links" placement="top">
                    <span className={isDataLoading ? "not-allowed-setting" : ""} >
                        <div className={isDataLoading ? "pointer-none-setting": ""}>
                            <Dropdown overlay={menu_images} trigger={['click']} disabled={preparingArchive}>
                                <div className="icon-button-container ml-8">
                                    <DownloadOutlined />
                                </div>
                            </Dropdown> 
                        </div>
                    </span>    
                </Tooltip>
              </div>
              {(projectStatus && accessActions.includes('invite')) &&
                <Col>
                  <Tooltip
                    title={
                      <span className="manrope f-12 white">Invite people</span>
                    }
                  >
                    <Button onClick={() => setIsAccessModal(true)} type="primary" className="invite-button ml-8">
                      <UserAddOutlined />
                      Invite
                    </Button>
                  </Tooltip>
                </Col>}
              {(displayCreateSceneButton && accessActions.includes('create')) && (
                <Col>
                  <Tooltip
                    title={
                      <span className="manrope f-12 white">Create Scene</span>
                    }
                  >
                    <Button
                      onClick={() => {
                        // window.location.href = "/add_scene?origin=/projects&project_id=" + projectId;
                        window.location.href = "/create-empty-scene?origin=/projects&project_id=" + projectId;
                      }}
                      type="primary"
                      className="invite-button ml-8"
                    >
                      Create Scene
                    </Button>
                  </Tooltip>
                </Col>
              )}
            </Col>
          </>
        </Row>
        <Row className="mb-10">
          <Col span={12}>
            {/* models count */}
            <span className="manrope f-14 w-400 mr-10" style={{color: '#8C8C8C'}}>Models</span>
            <span className="manrope f-14 w-500 mr-10" style={{color: '#262626'}}>{projectMetadata['product_count']} {projectMetadata['product_count'] == 1 ? 'Product' : 'Products'}</span>
            <span className="manrope f-14 w-500 mr-10" style={{color: '#262626'}}>{projectMetadata['room_count']} {projectMetadata['room_count'] == 1 ? 'Space' : 'Spaces'}</span>
            <span className="manrope f-14 w-500 mr-10" style={{color: '#262626'}}>{projectMetadata['scene_count']} {projectMetadata['scene_count'] == 1 ? 'Scene' : 'Scenes'}</span>

            {/* Renders count */}
            <span className="manrope f-14 w-400 mr-10 ml-50" style={{color: '#8C8C8C'}}>Images</span>
            <span className="manrope f-14 w-500 mr-10" style={{color: '#262626'}}>{getCombinedRendersCount('silo_count')} {getCombinedRendersCount('silo_count') == 1 ? 'Silo' : 'Silos'}</span>
            <span className="manrope f-14 w-500 mr-10" style={{color: '#262626'}}>{getCombinedRendersCount('lifestyle_count')} {getCombinedRendersCount('lifestyle_count') == 1 ? 'Lifestyle' : 'Lifestyles'}</span>
            {getCombinedRendersCount('silo_count') + getCombinedRendersCount('lifestyle_count') > 0 ?
            <Tooltip title={
                <Row>
                    {projectMetadata.silo_count && 
                        <Col span={12} className="justify-in-start">
                            <span style={{marginLeft: 5}}>
                                <span className='manrope f-12 black w-600' style={{marginBottom: 10}}>
                                    Silos
                                </span>
                                {
                                Object.keys(projectMetadata.silo_count).map(key => (
                                    <div className="justify-in-start" style={{ textAlign: "right" }}>
                                        <span className='manrope f-12' style={{color: "#276DD7", marginRight: 15, marginTop: 8}}>{projectMetadata.silo_count[key]}</span>
                                        <span className='manrope f-12 black' style={{marginTop: 8}}>{key}</span>
                                    </div>
                                ))
                                }
                            </span>
                        </Col>
                    }
                    {projectMetadata.lifestyle_count &&
                        <Col span={12} className="justify-in-start">
                            <span style={{marginRight: 20, marginLeft: 10}}>
                                <span className='manrope f-12 black w-600' style={{marginBottom: 10}}>
                                    Lifestyles
                                </span>
                                {
                                Object.keys(projectMetadata.lifestyle_count).map(key => (
                                    <div className="justify-in-start" style={{ textAlign: "left" }}>
                                        <span className='manrope f-12' style={{color: "#276DD7", marginRight: 15, marginTop: 8}}>{projectMetadata.lifestyle_count[key]}</span>
                                        <span className='manrope f-12 black' style={{marginTop: 8}}>{key}</span>
                                    </div>
                                ))
                                }
                            </span>
                        </Col>
                    }
                </Row>
            }
            color={"white"}>
                <img src='/img/info-blue.svg' style={{marginLeft: 3, marginBottom: 2}}/>
              </Tooltip>
              : ''}
          </Col>
        </Row>
        
          <div>
            {isDataLoading ? (
              <DottedLoader />
            ) : 
            <Tabs className="product-tab library" tabBarGutter={16} size="large">
              <TabPane key="1" tab="Models">

                {/* Radio buttons for filtration */}
                <Col span={12} offset={12} className="mb-20">
                  <div className="manrope f-14 mb-20" style={{float: 'right'}}>
                      <span className="mr-20 w-400" style={{color: '#8C8C8C'}}>
                          Show
                      </span>
                      <Radio.Group onChange={handleModelTypeChange} value={selectedModelType}>
                        {
                          modelTypeFilters.map((item) => {
                            return <Radio value={item.value}>
                              <span className="manrope f-14 w-500" style={{color: '#262626'}}>{item.label}</span>
                            </Radio>
                          })
                        }
                      </Radio.Group>
                  </div>
                </Col>

                {/* Show applied filters */}
                {(selectedReviewStatus.length > 0) && (
                  <Col span={24} style={{marginBottom: 10 }}>
                    <h4 className="manrope f-14 black-55 italic">
                      Showing results for
                    </h4>
                    <div>
                      {selectedReviewStatus.map((category) => {
                        return (
                          <Tag
                            key={category}
                            onClose={() => closeTags(category, "space_category")}
                            closable
                            visible={true}
                            className="manrope f-12 black-55 tag-filter"
                          >
                            Review Status:{" "}
                            <span className="manrope f-12 grey-77">{getTagName(category)}</span>
                          </Tag>
                        );
                      })}
                    </div>
                  </Col>
                )}
                <EntitiesList selectAssetAndSetBasicInfo={selectAssetAndSetBasicInfo}
                projectStatus={projectStatus} accessActions={accessActions} data={filteredData} isFilter = {selectedModelType != 'all' > 0 ||
                  selectedReviewStatus.length > 0} />
              </TabPane>

              <TabPane key="2" tab="Lifestyles">

                {/* Show resolution filter */}
                <Row>
                  <Col span={12} offset={12} >
                    <div className="manrope f-14 mb-20" style={{float: 'right'}}>
                        <span className="mr-20 w-400" style={{color: '#8C8C8C'}}>
                            Resolution
                        </span>
                        <Radio.Group onChange={(e) => setSelectedResolutionFilter(e.target.value)} value={selectedResolutionFilter}>
                          {
                            resolutionFilter.map((item) => {
                              return <Radio value={item.value}>
                                <span className="manrope f-14 w-500" style={{color: '#262626'}}>{item.label}</span>
                              </Radio>
                            })
                          }
                        </Radio.Group>
                    </div>
                  </Col>
                </Row>
                <LifestylesList projectId={projectId} 
                    selectedResolutionFilter={selectedResolutionFilter}
                    setSelectedResolutionFilter={setSelectedResolutionFilter} 
                    lifestyleRenders={lifestyleRenders} 
                    projectMetadata={projectMetadata}
                    setProjectMetadata={setProjectMetadata}
                    setLifestyleRenders={setLifestyleRenders}
                    loadingLifestyles={loadingLifestyles}
                />
              </TabPane>
              <TabPane key="3" tab="Silos">
                <Col span={24} >
                    <Row gutter={[16, 16]} className="justify-in-start" style={{ marginBottom: 100 }}>
                        {silos && silos.length > 0 ? silos.map((silo, index) => {
                            return (
                            <Col span={12}>
                                <Card className="silo-image-card">
                                <div className="position-flag-dim">
                                    <div className="resolution-btn">
                                        <span className="category-artist white">{Math.round(silo.image_data.width) + ' x ' + Math.round(silo.image_data.height)}</span>
                                    </div>
                                </div>
                                <Image preview={{
                                            visible: visibleImage[silo.unique_filename],
                                            maskClassName: 'customize-mask',
                                            mask: (
                                                <span className="fullscreen-icon">
                                                    <img className="image-thumbnail" src="/img/delete.png" onClick={(e) => { e.preventDefault(); openDeleteSiloModal(silo.camera_name, silo.product_id, silo.company_id, silo.username, silo) }} style={{ marginRight: 16 }} />
                                                    <img className="image-thumbnail mr-16" src="/img/expand_thumbnail.png" 
                                                    onClick={(e) => {e.preventDefault(); onImageExpand(silo.unique_filename);}}/>
                                                    <a onClick={() => {
                                                        let download_link = silo.request_link
                                                        if (silo.image_data.img_format.includes('.tiff')) {
                                                            download_link.replace('.jpg', '.tiff')
                                                        }
                                                        Utilities.downloadFile(download_link, silo.filename)
                                                    }}>
                                                        <img className="image-thumbnail mr-16" src="/img/download.png" />
                                                   </a>
                                                  <Tooltip title={"Something does not look good? Report it to the admin."}>
                                                    <img onClick={() => openSingleFlagImage(silo)}
                                                      className="image-thumbnail"
                                                      src="/img/flag_thumbnail.png" />
                                                  </Tooltip>
                                                </span>
                                            ),
                                            onVisibleChange: changeVisibility,
                                        }}
                                            className="lifestyle-image-settings"
                                            src={silo.request_link}
                                            alt={silo.camera_display_name}
                                        />
                                </Card>
                            </Col>
                            );
                        }): <Empty 
                        style={{ margin: 'auto', marginTop: 20, marginBottom: 20 }}
                        description={`No silo images to show`}
                        className="manrope f-14"/>}
                    </Row>
                </Col>
              </TabPane>
            </Tabs>
            }
        </div>
        {(projectStatus == "ready_for_scenes" && accessActions.includes('approve_project')) && (
          <div className="bottom-menu">
            <Button
              onClick={() => setIsApproveModal(true)}
              disabled={loading}
              className="product-btn approve-button f-14 ml-16 br-4"
            >
              Approve Project
            </Button>
          </div>
        )}
        <ApproveModal />
        { accessLevel == 'restricted' &&
          <RestrictedContainer />
        }
        <ProjectComments
          projectId={projectId}
          projectName={projectName}
          isCommentsDrawer={isCommentsDrawer}
          setIsCommentsDrawer={setIsCommentsDrawer}
          tagUserList={tagUserList}
          commentCount={commentCount}
          setCommentCount={setCommentCount}
          customGroups={customGroups.filter(item => item.access_level != 'restricted')}
          usersAndCustomGroupsList={usersAndCustomGroupsList}
        />
        <ProjectAccess
          setProjectUsers={setProjectUsers}
          projectName={projectName}
          projectId={projectId}
          projectUsers={projectUsers}
          isAccessModal={isAccessModal}
          setIsAccessModal={setIsAccessModal}
          getSharedUsers={getSharedUsers}
          accessLevel={accessLevel}
          customGroups={customGroups}
        />
        <ProductAssetModals
        currentPage={currentPage}
        props={props}
        accessed_from={'project_products'}
        currentProductId={currentProductId}
        setCurrentProductId={setCurrentProductId}
        selectedAssetModal={selectedAssetModal}
        productBasicInfo={productBasicInfo}
        />
        {selectedSiloImage &&
        <ProjectActionContext.Provider
          value={{
          context_type: "project",
          product_id: selectedSiloImage.product_id,
          companyId: selectedSiloImage.company_id,
          imageFlagModal: imageFlagModal,
          selectedImageForFlag: selectedSiloImage.request_link,
          setImageFlagModal: setImageFlagModal,
          cognito_username: selectedSiloImage.captured_by,
          setSingleImage: setSingleImage,
          singleImage: singleImage,
          silos: silos,
          selectedSiloImage: selectedSiloImage
          }}>
          <FlagSiloModal context_type={"project"}/>
        </ProjectActionContext.Provider>}

      <Modal
        maskClosable={true}
        centered
        onCancel={handleCancel}
        visible={isModalOpen}
        destroyOnClose={true}
        width={"fit-content"}
        bodyStyle={{ padding: 0 }}
        style={{
          padding: "20px 0px 20px 0px",
          minWidth: "25%",
          maxWidth: "90%",
        }}
        footer={null}
        className="img-modal"
      >
        {selectedLifestyleRender ? (
          <>
            <Image
              src={selectedLifestyleRender.request_link}
              className="render-img"
              preview={false}
              placeholder={
                <div className="img-loader justify-in-center">
                  <Spin indicator={antIcon} />
                </div>
              }
            />
          </>
        ) : (
          ""
        )}
      </Modal>

      <Modal
            className="modal-share-success"
            width={600}
            visible={deleteSiloModal}
            closable={false}
            footer={[
                <span className="justify-in-center">
                    <Button key="back" style={{ textAlign: "center" }} className="modal-okay-gray square font-14" onClick={() => {
                        setDeleteSiloModal(false)
                        setDeleteSiloCameraName(null)
                        setDeleteSiloProductId(null)
                        setDeleteSiloCompanyId(null)
                    }}>
                        Cancel
                    </Button>
                    <Button disabled={deleteSiloLoader} key="back" style={{ textAlign: "center" }} className="modal-okay square font-14" onClick={() => deleteSilo()}>
                        {deleteSiloLoader ? <span> Deleting <LoadingOutlined spin /></span> : <span> Okay </span>}
                    </Button>
                </span>
            ]}
            centered={true}
        >
            <div style={{ margin: "0px auto 0px auto", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <div>
                    <h2 className="manrope f-16 black-00 w-600">You're about to delete a Silo Image</h2>
                    <p className="manrope f-14 black-55">This will remove your Silo image from your product. Are you sure you want to delete?</p>
                </div>
            </div>
        </Modal>
      </CustomerMainLayout>
    </div>
  );
};


const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    getProduct: (product) => {
        dispatch(getProductRender(product));
    }
});


export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerProject)
)