import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import axios from "axios";
import { Card, Checkbox, Dropdown, Menu, message, Modal, Button, Tooltip } from "antd";
import "@ant-design/compatible/assets/index.css";
import {
  ClockCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { ReactComponent as MoreIcon } from '../../../../icons/more.svg'
import "../../../../styles/helper.scss";
import * as Utilities from "../../Utilities";
import _ from "lodash";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LibraryGrid from "../LibraryGrid/LibraryGrid";
import ENVIRONMENT from "../../../../environments";
import ProjectConstants from "./Constants";
import "./CustomerProject.scss";


const COMPANY_ID = localStorage.getItem('company_id');
const CUSTOMER_USERNAME = localStorage.getItem("username");
const MANAGED_CUSTOMER_USERNAME = localStorage.getItem('managed_customer_username');
const MANAGED_COMPANY_ID = localStorage.getItem('managed_company_id');
const MANAGED_CUSTOMER_EMAIL = localStorage.getItem('managed_customer_email');
const IS_MS_PROVIDER = localStorage.getItem("is_msprovider") === 'true' ? true : false;

const EntitiesList = (props) => {
  const { data, selectable, selected, setSelected, projectStatus, isFilter, accessActions } =
    props;
  const [columnCount, setColumnCount] = useState(4);
  const [rowCount, setRowCount] = useState(1);
  const maxColumnWidth = 280;
  const rowSize =
    selectable ||
    projectStatus == "approved"
      ? 450
      : 500;

  const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
    const itemIndex = rowIndex * columnCount + columnIndex;
    const ele = data[itemIndex] && data[itemIndex];

    if (!ele) {
      return null;
    }

    return (
      <div key={key} style={style}>
        <EntityItem
          selectAssetAndSetBasicInfo={props.selectAssetAndSetBasicInfo}
          selectable={selectable}
          selected={selected}
          setSelected={setSelected}
          projectStatus={projectStatus}
          accessActions={accessActions}
          key={key}
          ele={ele}
        />
      </div>
    );
  };

  useEffect(() => {
    setRowCount(Math.ceil(data.length / columnCount));
  }, [data]);

  return (
    <>
      {data.length == 0 ? (
        <div
          className="empty-section"
          style={{
            marginTop: "10%",
            position: "relative",
            textAlign: "-webkit-center",
          }}
        >
          <img
            id="empty-section-image"
            style={{ width: "10%" }}
            src="../../../../img/Collections.svg"
          />
          <p className="empty-section-text manrope f-14 black-55">
            Nothing to show
          </p>
        </div>
      ) : (
        <LibraryGrid
          setColumnCount={setColumnCount}
          setRowCount={setRowCount}
          maxColumnWidth={maxColumnWidth}
          data={data}
          cellRenderer={cellRenderer}
          columnCount={columnCount}
          getColumnWidth={() => {}}
          rowCount={rowCount}
          getEstimatedRowSize={rowSize}
          handleColumnWidthChange={() => {}}
          contentHeight={isFilter || selectable ? " grid-content-320" : ""}
        />
      )}
    </>
  );
};

const EntityItem = (props) => {
  const { ele, selectable, selected, setSelected, projectStatus, accessActions } = props;

  const [removeItemModal, setRemoveItemModal] = useState(false)
  const [deleteItemModal, setDeleteItemModal] = useState(false)
  const [deleteLoader, setDeleteLoader] = useState(false)
  const [removingItemLoader, setRemovingItemLoader] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  let updateTime = Utilities.timestampToTimeSince(
    selectable ? ele.last_modified_stamp : ele.last_modified
  );
  if (updateTime.includes("h")) {
    updateTime = "Today";
  }

  const handleSelectClick = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((ele) => ele !== id));
    } else {
      setSelected([...selected, id]);
    }
  };

  const statusData = ProjectConstants.status_data;
  const sceneStatusData = ProjectConstants.scene_status_data;

  const entityData = ProjectConstants.entity_data;

  const select_id = ele[entityData[ele.entity_type].select_id];
  const getThumbnail = () => {
    if (ele.entity_type === "room") {
      return (
        ENVIRONMENT.getBaseURL(ele.platform) +
        ENVIRONMENT.SPACE_THUMBNAIL_URI +
        (selectable ? ele.room_id : ele.id) +
        "/" +
        ele.thumbnail.split("/")[ele.thumbnail.split("/").length - 1]
      );
    } else if (ele.entity_type === "product") {
      return (
        ENVIRONMENT.getBaseURL(ele.platform) +
        ENVIRONMENT.THUMBNAIL_URI +
        ele.thumbnail.split("/")[ele.thumbnail.split("/").length - 1]
      );
    } else if (ele.entity_type === "scene") {
      return (
        ENVIRONMENT.getBaseURL(ele.platform) +
        ENVIRONMENT.SCENE_THUMBNAIL_URI +
        ele.id +
        "/" +
        ele.thumbnail
      );
    }
  };

  const redirectToEntity = () => {
    if (ele.entity_type === "room") {
      if(ele.status !== 'in_review' || (ele.status == 'in_review' && accessActions.includes('approve_entity'))){
        window.location.href = `/rooms/${ele.id}?entity_id=${ele.id}&&project_id=${ele.project_id}`;
      }
    } else if ((ele.entity_type === "product" && ele.status === "approved") || (ele.entity_type === "product" && ele.status === "in_review" && !accessActions.includes('approve_entity'))) {
      window.location.href = `/products/${ele.id}?entity_id=${ele.id}&project_id=${ele.project_id}`;
    } else if(ele.entity_type === "product" && (ele.status === "in_progress" || ele.status === "rejected")) {
      window.location.href = `/product_page/${ele.id}`
    } else if (ele.entity_type === "product" && ele.status === "in_review" && accessActions.includes('approve_entity')) {
      window.location.href = `/product-qa/${ele.id}?entity_id=${ele.id}`;
    } else if (ele.entity_type === "scene" && ele.status == "in_progress") {
      window.location.href = `/scene_renders/${ele.id}?entity_id=${ele.id}&project_id=${ele.project_id}`;
    }
    else if (ele.entity_type === "scene") {
        window.location.href = `/scene_builder/${ele.id}?entity_id=${ele.id}&project_id=${ele.project_id}`;
      }
  };

  const handleCardClick = () => {
    if (selectable) {
      handleSelectClick(select_id);
    } else {
      redirectToEntity();
    }
  };

  const removeItemFromProject = () => {
      let ele = selectedItem;
      if (ele) {
        setRemovingItemLoader(true);
        let payload = {
          project_id: ele.project_id,
          generate_thumbnail: true,
        };
        if (ele.entity_type === "product") {
          payload['products_list'] = [ele.id];
        } else if (ele.entity_type === "room") {
          payload['rooms_list'] = [ele.id];
        } else if (ele.entity_type === "scene") {
          payload['scenes_list'] = [ele.id];
        }
  
        axios.post(ENVIRONMENT.DELETE_ENTITY_BATCH_TO_PROJECT, payload).then(
          (res) => {
            setRemovingItemLoader(false);
              if (res && res.data.status == 201) {
                message.success('Item Removed from the project');
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              } else {
                message.error('Something went wrong');
              }
          }
        ).catch((error) => {
          setRemovingItemLoader(false);
          message.error('Something went wrong.');
        });
      } else {
        message.error('Something went wrong.');
      }
  };
  
  const deleteIncompleteItem = (ele) => {
    setDeleteLoader(true);
    let entityTypeMapping = {
      product: 'Product',
      room: 'Space',
      scene: 'Scene',
      material: 'Material',
    };

    let api_end_point = ENVIRONMENT.DELETE_INCOMPLETE_REQUEST;
    let payload = {
      entity_id: ele.id,
      entity_type: entityTypeMapping[ele.entity_type],
    };

    axios.post(api_end_point, payload)
      .then((response) => {
        message.success(entityTypeMapping[ele.entity_type] + ' Deleted Successfully.');
        setTimeout(() => {
          window.location.reload();
        }, 600);
      })
      .catch((error) => {
        message.error('Error in deleting');
        setDeleteLoader(false);
        setDeleteItemModal(false);
      });
   
  };
  

  const getAssetImageCount = (product) => {
    let local_company_id = COMPANY_ID;
    if (IS_MS_PROVIDER) {
      if (MANAGED_COMPANY_ID) {
        local_company_id = MANAGED_COMPANY_ID;
      }
    }
    if (product.company_id && product.company_id == local_company_id) {
      if (product.company_render_count) {
        return product.company_render_count;
      } else {
        return 0;
      }
    } else {
      if (product.user_render_count) {
        return product.user_render_count;
      } else {
        return 0;
      }
    }
  }

  let menu = (id, entity, selectAssetAndSetBasicInfo) => {
    return <Menu style={{ width: "148px", marginLeft: "10px", backgroundColor: '#E6F7FF' }}>
      <Menu.Item className='manrope f-14' key={0} onClick={() => selectAssetAndSetBasicInfo(id, entity, 'silo')}>
        <Tooltip>
          <span className='cursor-pointer' style={{ color: '#276DD7' }}>Silo Images</span>
        </Tooltip>
      </Menu.Item>
      <Menu.Item className='manrope f-14' key={1} onClick={() => selectAssetAndSetBasicInfo(id, entity, 'mp4')}>
        <Tooltip>
          <span className='cursor-pointer' style={{ color: '#276DD7' }}>MP4 Video</span>
        </Tooltip>
      </Menu.Item>
      <Menu.Item key={2} className='manrope f-14' onClick={() => selectAssetAndSetBasicInfo(id, entity, 'dim')}>
        <span className='cursor-pointer' style={{ color: '#276DD7' }}>Dimensional Image</span>
      </Menu.Item>
      <Menu.Item className='manrope f-14' key={3} onClick={() => selectAssetAndSetBasicInfo(id, entity, 'lifestyle')}>
        <Tooltip>
          <span className='cursor-pointer' style={{ color: '#276DD7' }}>Lifestyle Images</span>
        </Tooltip>
      </Menu.Item>
      <Menu.Item className='manrope f-14' key={4} onClick={() => selectAssetAndSetBasicInfo(id, entity, '360')}>
        <Tooltip>
          <span className='cursor-pointer' style={{ color: '#276DD7' }}>Product 360 Spin</span>
        </Tooltip>
      </Menu.Item>
      <Menu.Item className='manrope f-14' key={5} onClick={() => selectAssetAndSetBasicInfo(id, entity, 'variant')}>
        <Tooltip>
          <span className='cursor-pointer' style={{ color: '#276DD7' }}>Product Variant</span>
        </Tooltip>
      </Menu.Item>
    </Menu>
  }

  return (
    <>
    <Card
      bodyStyle={{ padding: "5px" }}
      className={`project-entity-card`}
      bordered={false}
      data-tut="reactour__approved_scene"
      style={{ width: "94%" }}
    >
      {!props.selectable ?
        <Dropdown className="group-settings more-icon" overlay={
          <Menu>
            <Menu.Item>
                <a className="manrope f-14" onClick={() => {setRemoveItemModal(true); setSelectedItem(ele)}} >Remove from Project</a>
            </Menu.Item>
            {props.ele && props.ele.status === "incomplete" ?
              <Menu.Item>
                <a className="manrope f-14" onClick={() => {setDeleteItemModal(true); setSelectedItem(ele)}} >Delete {ele.entity_type}</a>
              </Menu.Item>
            : ''}
            </Menu>
          } trigger={['click']}>
          <MoreIcon />
        </Dropdown> : ''
      }
      <div className="stacked-project-card-inner" onClick={() => handleCardClick()}>
        {selectable && (
          <span className="group-settings-checkbox-reorder-items" style={{ zIndex: "10" }}>
            <Checkbox
              className="black-checkbox"
              checked={selected.includes(select_id)}
              id={select_id}
            />
          </span>
        )}
        <div
          className="suggested-product"
          style={{ zIndex: "owned", cursor: "pointer" }}
        >
          <div className="box">
            <img
              src={
                ele.thumbnail
                  ? getThumbnail()
                  : require("../../../../assets/images/scene_incomplete.svg")
              }
              style={{ objectFit: "contain", width: "100%", height: 290 }}
            />
          </div>
          <div style={{ margin: "2px 10px 0px 10px" }}>
            <div className="tag-section">
              <div className={`tag ${ele.product_model_type == 'props' ? entityData['prop'].className : entityData[ele.entity_type].className}`}>
                <span>{ ele.product_model_type == 'props' ? entityData['prop'].title : entityData[ele.entity_type].title}</span>
              </div>
              <div className={`tag ${updateTime == "Today" && "today-tag"}`}>
                <ClockCircleOutlined />
                <span className="ml-4">{updateTime}</span>
              </div>
            </div>
            <div className="project-name justify-in-start">
              <Tooltip title={<span className='manrope f-12 white'>{ele.name}</span>}>
              <span className="manrope f-14 w-500 clamp-text w-100 card-name">
                {selectable
                  ? ele.entity_type == "room"
                    ? ele.room_name
                    : ele.name
                  : ele.name}
              </span>
              </Tooltip>
            </div>
            {ele.entity_type == 'scene' ? '':   
             <div className="ml-8 mb-4 justify-in-start">
                <span className="manrope f-14 w-500 lh-28 black-73 white-space-text">
                Category
                </span>
                <Tooltip title={<span className='manrope f-12 white'>{ele.category}</span>}>
                  <span className="manrope f-14 w-500 black-d9 clamp-text w-100 ml-8">
                  {ele.category}
                  </span>
                </Tooltip>
            </div>}
            {selectable ||
            projectStatus == "approved" ? (
              ""
            ) : (
              <div className="status" style={{ marginBottom: ele.entity_type == 'scene' ? 26 : 0 }}>
                  {ele.entity_type !== 'scene' && statusData[ele.status] && (
                <span>
                    {statusData[ele.status].icon}
                    <span className="manrope f-14 w-700 ml-8">
                      {statusData[ele.status].title ??
                        statusData.in_review.title}
                    </span>
                </span>
                  )}
                  {ele.entity_type == 'scene' && sceneStatusData[ele.status] && 
                    <span>
                      {sceneStatusData[ele.status].icon}
                        <span className="manrope f-14 w-700 ml-8">
                          {sceneStatusData[ele.status].title ??
                            sceneStatusData.in_review.title}
                        </span>
                    </span>
                  }
              </div>
            )}

          </div>
          
        </div>
      </div>
        {(ele.entity_type == 'product' && (['approved', 'in_review']).includes(ele.status)) ?
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} className="ml-16 mr-14 mt-4">
            <Dropdown overlay={
              menu(ele.id, ele, props.selectAssetAndSetBasicInfo)
            } trigger={'click'} placement="topLeft">
              <span className="justify-in-start generate-asset-btn"> Generate Assets + </span>
            </Dropdown>
            <div className="justify-in-end" onClick={() => redirectToEntity(ele)}>
              <span className="info-text size-14">{getAssetImageCount(ele)} Images</span>
              <img src='/img/ArrowRight.png' className="icon-instance-node" style={{ marginLeft: '4px' }} />
            </div>

          </div>: <div className={`dummy-height ${ele.entity_type == 'scene' ? `scene` : ``}`}>
            </div>}
    </Card>

    <Modal
      visible={removeItemModal}
      onCancel={() => setRemoveItemModal(false)}
      footer={
        <div className="justify-in-end">
          <Button  className="modal-okay-gray square font-14 pd-20" onClick={() => {setRemoveItemModal(false)}}>
            Cancel
          </Button>,
          <Button className="modal-okay square font-14" disabled={removingItemLoader} htmlType="submit" onClick={() => removeItemFromProject()} >
            Remove {removingItemLoader && <LoadingOutlined />}
          </Button>
        </div>
      }
    >
      <div>
        <h3>Remove Item From Project</h3>
        <span>Are you sure you want to remove this item from the project?</span>
      </div>
    </Modal>


    {/* Modal for deleting the incomplete item */}
    <Modal
      className="silo-success-modal"
      width={800}
      centered={true}
      visible={deleteItemModal}
      onCancel={() => {setDeleteItemModal(false)}}
      closable={true}
      footer={[
        <span className="justify-in-center">
            <Button key="back" style={{textAlign:"center"}} className="modal-okay-gray square font-14" onClick={() => setDeleteItemModal(false)}>
                Cancel
            </Button>
            <Button key="back" style={{textAlign:"center"}} className="modal-okay square font-14" onClick={() => deleteIncompleteItem(selectedItem)}>
                {deleteLoader? <span> Deleting <LoadingOutlined spin /></span>:<span> Yes, Delete {selectedItem != null ? selectedItem.entity_type : ''} </span>}
            </Button>
        </span>
      ]}
        > 
         <div style={{ margin: "30px auto 0px auto", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <h2 className="manrope f-24 black-55 w-900">
            {`You are about to delete an incomplete ${selectedItem != null ? selectedItem.entity_type : ''}`}
          </h2>
          <p className="manrope f-18 black-33">Are you sure you want to proceed?</p>
        </div>  
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => state;

export default withRouter(connect(mapStateToProps)(EntitiesList));
