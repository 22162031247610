import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Empty, Tabs, Alert } from 'antd';
import './ProductGridWrapper.scss'
import ProductGrid from '../ProductGrid/ProductGrid';
import AddProductsToSceneContext from '../../ContextFiles/AddProductsToSceneContext';

import { LoadingOutlined } from '@ant-design/icons';
import FileConstants from '../../../../FileConstants';

const { TabPane } = Tabs;
const IS_MSPROVIDER = FileConstants.isMSProvider;
const MANAGED_CUSTOMER_USERNAME = FileConstants.MANAGED_CUSTOMER_USERNAME;

const ProductGridWrapper = () => {
    const { displayedData, loader, libraryType, onProductTabChange, storeDataLoader, allStoreDataLoader, initialLoad, currentTabType, filtersApplied } = useContext(AddProductsToSceneContext);

    const gridStyle = filtersApplied ? 'product-grid-row-filters'  : 'product-grid-row'

    const ModelsData = displayedData && displayedData.length > 0 ?
        <ProductGrid /> :
        <Col span={24} className="no-models-div">
            <Empty description={"No Models To Show Yet"} className="manrope f-14 no-models-span" />
        </Col>;
        
    const InfoAlert = 
        <Alert
        message="Please note that these items are not yet approved. Any changes made to the model files will automatically apply to the item used in the scene."
        type="info"
        showIcon
        />;

    const LoadingDiv = (props) => {
        return (
            <div className="justify-in-center" style={{ width: '100%', position: 'absolute', bottom: 0 }}>
                <p className="empty-section-text manrope f-16 black-55">
                    Please wait while we load all store products.
                    <LoadingOutlined style={{ fontSize: 24, marginLeft: 14 }} />
                </p>
            </div>
        );
    }

    return (
        <>
        {
            (IS_MSPROVIDER && MANAGED_CUSTOMER_USERNAME != undefined) ?
            <Row className={libraryType == 'library' ? 'w-100' : `w-100 ${gridStyle}`}>
                {
                    libraryType == 'library' ? 
                    initialLoad ?
                    <Col span={24} className='justify-in-center'>
                        <LoadingOutlined className='manrope f-32' />
                    </Col> :
                    <Tabs style={{ width: "100vw" }}
                    onChange={onProductTabChange}
                    defaultActiveKey={currentTabType}
                    size="small"
                    tabBarStyle={{ textAlign: "center" }}
                    animated={false}
                    tabBarGutter={32}
                    >
                        <TabPane tab="Approved Models" key="5" className={`w-100 ${gridStyle}`}>
                            {ModelsData}
                        </TabPane>
                        <TabPane tab="In Admin QA" key="3">
                            <Row>
                                <Col span={24} style={{marginBottom: 10}}>
                                    {InfoAlert}
                                </Col>
                                <Col span={24} className={`w-100 ${gridStyle}`}>
                                    {ModelsData}
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tab="In Customer QA" key="4" >
                            <Row>
                                <Col span={24} style={{marginBottom: 10}}>
                                    {InfoAlert}
                                </Col>
                                <Col span={24} className={`w-100 ${gridStyle}`}>
                                    {ModelsData}
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs> :
                    storeDataLoader ?
                    <Col span={24} className='justify-in-center'>
                        <LoadingOutlined className='manrope f-32' />
                    </Col> :
                    (displayedData && displayedData.length > 0 ?
                        <>
                            <ProductGrid />
                            {(allStoreDataLoader && libraryType == 'store') && <LoadingDiv/>}
                        </> :
                    <Col span={24} className="no-models-div">
                        <Empty description={"No Models To Show Yet"} className="manrope f-14 no-models-span" />
                    </Col>)
                }
            </Row> :
            <Row className={`w-100 ${gridStyle}`}>
                {
                    libraryType == 'library' ?
                    initialLoad ?
                    <Col span={24} className='justify-in-center'>
                        <LoadingOutlined className='manrope f-32' />
                    </Col> :
                    (displayedData && displayedData.length > 0 ?
                    <ProductGrid /> :
                    <Col span={24} className="no-models-div">
                        <Empty description={"No Models To Show Yet"} className="manrope f-14 no-models-span" />
                    </Col>) :
                    storeDataLoader ?
                    <Col span={24} className='justify-in-center'>
                        <LoadingOutlined className='manrope f-32' />
                    </Col> :
                    (displayedData && displayedData.length > 0 ?
                        <>
                            <ProductGrid />
                            {(allStoreDataLoader && libraryType == 'store') && <LoadingDiv/>}
                        </> :
                    <Col span={24} className="no-models-div">
                        <Empty description={"No Models To Show Yet"} className="manrope f-14 no-models-span" />
                    </Col>)
                }
            </Row>
        }
        </>
    );

}

export default ProductGridWrapper;