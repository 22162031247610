import React, { useState, useRef, useEffect } from 'react';
import { Modal, Form, Button, Collapse, Tooltip, Tabs, Row, Col, Input, message, Select, Typography, Checkbox, Empty } from 'antd';
import { LoadingOutlined, EditOutlined, LinkOutlined, InfoCircleOutlined } from '@ant-design/icons';
import ENVIRONMENT from '../../../../environments';
import FileConstants from '../../../../FileConstants';
import axios from 'axios';

const { Option } = Select;
const { Panel } = Collapse;
const { Paragraph } = Typography;
const { TabPane } = Tabs;
const formRef = { useRef };

const MANAGED_CUSTOMER_USERNAME = localStorage.getItem('managed_customer_username');
const MANAGED_CUSTOMER_EMAIL = localStorage.getItem('managed_customer_email');
const IS_MSP = localStorage.getItem("is_msprovider") === 'true' ? true : false

export default function SharingModal({ entityType, entityId, modalVisible, setSharingModal, publicLinks, collectionId, loggedInUserAccess }) {
    const [form] = Form.useForm();
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [updatingRecordAccess, setUpdatingRecords] = useState({});
    const [accessUpdateLoader, setAccessUpdateLoader] = useState(false);
    const [inviteLoader, setInviteLoader] = useState(false);
    const [sceneSharingOption, setSceneSharingOption] = useState('collaborate')
    const [accessList, setAccessList] = useState([])
    const [customGroups, setCustomGroups] = useState([])


    function handleUsersAccessChange(email, access_level) {
        setAccessUpdateLoader(true);
        // Keep track of email address whose access is being updated
        let records = updatingRecordAccess;
        records[email] = true
        setUpdatingRecords(records);
        forceUpdate();

        let payload = {
            'email': email,
            'access_level': access_level,
            'entity_id': entityId,
            'entity_type': entityType,
            'username': localStorage.getItem("username")
        }

        if(entityType == 'scene'){
            payload['share_company_wide'] = false
            if(FileConstants.isMSProvider){
                payload['share_company_wide'] = true
            }
        }

        axios.post(ENVIRONMENT.ENTITY_ACCESS_UPDATE, payload)
            .then(res => {
                setAccessUpdateLoader(false);
                let updating_records = updatingRecordAccess
                updating_records[email] = false
                setUpdatingRecords(updating_records);
                message.success(<span className="capitalize">{entityType + ' shared successfully!'}</span>);
                setInviteLoader(false);
                forceUpdate();
            });
    };

    function sceneReplicate(replicatePayload) {

        let payload = {};

        let lowercase_emails = []
        if(replicatePayload['email']){
            lowercase_emails = [replicatePayload['email'].toLowerCase()]
        }
        else if(replicatePayload['group_id']){
            const group_id = replicatePayload['group_id']
            for(let item of customGroups){
                if(item.group_id == group_id){
                    for(let user of item.users){
                        lowercase_emails.push(user['email'].toLowerCase())
                    }
                }
            }
        }
        payload['emails'] = lowercase_emails;
        payload['username'] = localStorage.getItem('username');
        if(collectionId)
            payload['collection_id'] = collectionId;
        payload['scene_id'] = entityId;
        if (sceneSharingOption == 'replicate') {
            payload['replicate_assets'] = true
        }
        payload['share_company_wide'] = false
        if(FileConstants.isMSProvider){
            payload['share_company_wide'] = true
        }

        axios.post(ENVIRONMENT.SCENE_REPLICA, {
            body: payload
        })
        message.info('Scene is being copied in the background for the user(s)')
    }

    function handleGroupAccessChange(group_id, access_level) {
        setAccessUpdateLoader(true);
        // Keep track of email address whose access is being updated
        let records = updatingRecordAccess;
        records[group_id] = true
        setUpdatingRecords(records);
        forceUpdate();

        let payload = {
            'group_id': group_id,
            'access_level': access_level,
            'entity_id': entityId,
            'entity_type': entityType,
            'username': localStorage.getItem("username")
        }

        if(entityType == 'scene'){
            payload['share_company_wide'] = false
            if(FileConstants.isMSProvider){
                payload['share_company_wide'] = true
            }
        }

        axios.post(ENVIRONMENT.ENTITY_ACCESS_UPDATE, payload)
            .then(res => {
                setAccessUpdateLoader(false);
                let updating_emails_or_group_ids = updatingRecordAccess
                updating_emails_or_group_ids[group_id] = false
                setUpdatingRecords(updating_emails_or_group_ids);
                message.success(<span className="capitalize">{entityType + ' shared successfully!'}</span>);
                setInviteLoader(false);
                forceUpdate();
            });
    }

    useEffect(() => {

        const company_id = localStorage.getItem('company_id')
        // get entity shared users
        let payload = {
            'entity_type': entityType,
            'entity_id': entityId,
            'company_id': company_id,
            'username': localStorage.getItem('username')
        }
        if(IS_MSP){
            payload['is_msp'] = IS_MSP
        }

        axios.post(ENVIRONMENT.ENTITY_SHARE_LIST, payload)
            .then((res1) => {
                if (res1?.data) {
                    if(MANAGED_CUSTOMER_EMAIL && 
                        res1.data.filter(item => item.email == MANAGED_CUSTOMER_EMAIL).length == 0){
                        // Add the managed customer to the first index of the accessList 
                        // if model is not already shared with managed user
                        setAccessList([
                            {
                                'email': MANAGED_CUSTOMER_EMAIL, 
                                'display_name': MANAGED_CUSTOMER_USERNAME,
                                'username': MANAGED_CUSTOMER_USERNAME,
                                'access_level': 'restricted',
                                'group_id': null
                            }
                        , ...res1.data])
                    }
                    else{
                        setAccessList(res1.data)
                    }
                }

                //  get company custom groups
                let payload = {
                    'action': 'list_groups'
                }
                if(localStorage.getItem('company_id'))
                    payload['company_id'] = localStorage.getItem('company_id')
                else
                    payload['created_by'] = localStorage.getItem('username')

                axios.post(ENVIRONMENT.CUSTOM_GROUP_CONTROLLER, payload).then(res => {
                    if (res?.data) {
                        addAccessValueOfCustomGroups(res.data, res1.data)
                    }
                })
            })

    }, [entityId, entityType])

    function addAccessValueOfCustomGroups(customGroups, users) {
        /* 
            customGroups: list of user's or company's groups
            users: list of users with whom the item is shared. If user is part of a group it will have group_id as well.
        */

        // Iterate list of users, if user is part of a group then set the group will have same access as of this user.
        let groupIdAccessMapping = {}
        users.map((user) => {
            if (user.group_id) {
                groupIdAccessMapping[user.group_id] = user.access_level
            }
        })

        // display only those groups that has users
        const updatedGroups = customGroups.filter(item => item.users.length > 0).map((group) => ({
            ...group,
            access_level: groupIdAccessMapping[group.group_id] || 'restricted'  // if group does not has access level set that means this item was never shared with that group thus restricted access
        }))

        setCustomGroups(updatedGroups)
    }


    return (<>

        <Modal
            visible={modalVisible}
            onCancel={() => setSharingModal(false)}
            className="modal-share"
            footer={null}>
            <div>
                <h2 className="manrope f-18 black-00 w-600 capitalize">Share and Embed {entityType} </h2>
                <Tabs>
                    <TabPane tab="Access Management" key="1" >
                        {/* In case of scene sharing Modal, show the collaborate and replicate option */}
                        {entityType == 'scene' &&
                        <Row className="modal-radio-text mb-10 mt-10">
                            <Col span={8}>
                                <Checkbox
                                    defaultChecked={true}
                                    className="black-checkbox"
                                    onChange={() => setSceneSharingOption('collaborate')}
                                    checked={(sceneSharingOption == 'collaborate')}>
                                    <span className='manrope f-12 black-55'>Collaborate <Tooltip  title={<span className="manrope f-12 white">All future assets of this scene generated by you and the above mentioned person will be available to both of you.</span>}>
                                        <InfoCircleOutlined />
                                    </Tooltip></span>
                                </Checkbox>
                            </Col>

                            <Col span={8}>
                                <Checkbox
                                        defaultChecked={true}
                                        className="black-checkbox"
                                        onChange={() => setSceneSharingOption('replicate')}
                                        checked={(sceneSharingOption == 'replicate')}>
                                        <span className='manrope f-12 black-55'>Copy <Tooltip  title={<span className="manrope f-12 white">A copy of this scene will be shared and no future assets generated by you will be shared with the above mentioned person.</span>}>
                                            <InfoCircleOutlined />
                                        </Tooltip></span>
                                    </Checkbox>
                            </Col>
                            <Col span={8}>
                            </Col>
                    </Row>
                        
                        }
                        <Collapse accordion={true} defaultActiveKey={['1']} >
                            {accessList?.length > 0 &&
                                // This panel contains the list of individual users with whom the 
                                <Panel header="Individuals" key="1">
                                    <div className="collaborators-list custom-scroll">
                                        {accessList.filter(item => item.group_id === null).map((item, index) => {
                                            return (
                                                <div key={index} className="collaborators-list-item">
                                                    <div className="collaborator-email-username">
                                                        <span className="manrope f-4 w-500" style={{ display: 'block' }}>{item.email} {item.username == localStorage.getItem('username') ? ' (You)' : ''}</span>
                                                    </div>
                                                    {sceneSharingOption == 'replicate' ? 
                                                    // When replicating scene, the concept of access level does not apply 
                                                    // since a new copy of the scene will be created for the new user(s) so, show the share button which will trigger the scene-replicate-lambda
                                                    <Button ghost
                                                        className='action-icon-btn blue manrope f-14 blue hover-change'
                                                        onClick={() => sceneReplicate({'email': item.email})}>
                                                        Share
                                                    </Button>
                                                    :
                                                    (item.access_level != 'company_wide' && item.access_level != 'owner') ?
                                                        <div>
                                                            {updatingRecordAccess[item.email] ? <LoadingOutlined /> : ''}
                                                            <Select
                                                                suffixIcon={<EditOutlined className={updatingRecordAccess[item.email] || (item.access_level != 'restricted' && loggedInUserAccess == 'view') || item.username == localStorage.getItem('username') ? "" : 'collaborators-access'} />}
                                                                className="collaborators-access"
                                                                dropdownMatchSelectWidth={false}
                                                                defaultValue={item.access_level == "restricted" ? "Invite" : item.access_level}
                                                                disabled={updatingRecordAccess[item.email] || item.username == localStorage.getItem('username') || (item.access_level != 'restricted' && loggedInUserAccess == 'view') ? true : false}
                                                                onChange={(e) => handleUsersAccessChange(item.email, e)}
                                                            >
                                                                {loggedInUserAccess == 'view' && item.access_level == 'restricted' ? '' : <Option className='manrope f-12 w-400' value='edit'><Tooltip placement='right' title={"Editor can edit item's information, request renders and create variations"}>Editor</Tooltip></Option> }
                                                                <Option className='manrope f-12 w-400' value='view'><Tooltip placement='right' title={"Viewer can view item's information and its renders"}>Viewer</Tooltip></Option>
                                                            </Select>
                                                        </div>
                                                    : (item.access_level == 'company_wide' || item.access_level == 'owner') &&
                                                        <Tooltip title={item.access_level == 'company_wide' ? "Company Wide Shared" : 'Owner'}><div style={{ margin: '3px 30px 3px 0px' }}> Editor </div></Tooltip>
                                                    }
                                                </div>
                                            );
                                        })}
                                    </div>
                                </Panel>
                            }
                            <Panel header={<div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <span>
                                Groups 
                                <Tooltip  title={<span className="manrope f-12 white">Use groups to share items with multiple users at once.</span>}> <InfoCircleOutlined /> </ Tooltip></span>
                                <a onClick={(e) => {window.location.href = '/account-details?tab=group'; e.stopPropagation()}}> Manage Groups</a>
                                </div>} key="2">
                                <div className="collaborators-list custom-scroll mt-10" >
                                    {customGroups.length > 0 ?
                                        customGroups.map((item, index) => {
                                            return (
                                                <div key={index} className="collaborators-list-item">
                                                    <div className="collaborator-email-username">
                                                        <span className="manrope f-14 w-500" style={{ display: 'block' }}><img src={'/img/group_icon.svg'} /> {item.group_name}</span>
                                                    </div>
                                                    <div>
                                                        {sceneSharingOption == 'replicate' ? 
                                                            // When replicating scene, the concept of access level does not apply 
                                                            // since a new copy of the scene will be created for the new user(s) so, show the share button which will trigger the scene-replicate-lambda
                                                            <Button ghost
                                                                className='action-icon-btn blue manrope f-14 blue hover-change'
                                                                onClick={() => sceneReplicate({'group_id': item.group_id})}>
                                                                Share
                                                            </Button>
                                                        :
                                                        <>
                                                        {updatingRecordAccess[item.group_id] ? <LoadingOutlined /> : ''}
                                                        <Select
                                                            suffixIcon={<EditOutlined className={updatingRecordAccess[item.email] || (item.access_level != 'restricted' && loggedInUserAccess == 'view') || item.username == localStorage.getItem('username') ? "" : 'collaborators-access'} />}
                                                            className="collaborators-access"
                                                            dropdownMatchSelectWidth={false}
                                                            defaultValue={item.access_level == "restricted" ? "Invite" : item.access_level}
                                                            disabled={updatingRecordAccess[item.group_id] || (item.access_level != 'restricted' && loggedInUserAccess == 'view') ? true : false}
                                                            onChange={(e) => handleGroupAccessChange(item.group_id, e)}
                                                        >
                                                            {loggedInUserAccess == 'view' && item.access_level == 'restricted' ? '' : <Option className='manrope f-12 w-400' value='edit'><Tooltip placement='right' title={"Editor can edit item's information, request renders and create variations"}>Editor</Tooltip></Option> }
                                                            <Option className='manrope f-12 w-400' value='view'><Tooltip placement='right' title={"Viewer can view item's information and its renders"}>Viewer</Tooltip></Option>
                                                        </Select>
                                                        </>
                                                        }
                                                    </div>
                                                </div>
                                            );
                                        })
                                    : 
                                    <Empty
                                        style={{ marginTop: 20, marginBottom: 20 }}
                                        description={"No groups created yet."}
                                        className="manrope f-14"
                                    />
                                    }
                                </div>
                            </Panel>
                        </Collapse>
                        <div className="mt-20">
                            <p className="manrope f-14 mt-10 mb-5">Invite</p>
                            <Form
                                ref={formRef}
                                onFinish={(values) => { sceneSharingOption == 'replicate' ? sceneReplicate({'email': values['email']}) : handleUsersAccessChange(values['email'], values['access_level']); setInviteLoader(true) }}
                                initialValues={{
                                    'access_level': loggedInUserAccess == 'edit' ? 'edit': 'view'
                                }}
                            >
                                <Row gutter={8}>
                                    <Col span={14}>
                                        <Form.Item
                                            name="email"
                                            rules={[{ type: 'email', message: 'Please enter a valid email.' },
                                            { required: true, message: 'Please enter an email.' },
                                            { pattern: "^[^\\s]+(\\s+[^\\s]+)*$", message: " Spaces are not allowed, whitespace found at the end." },
                                            ]}>
                                            <Input
                                                className='collab-access-form-fields'
                                                placeholder="Type email here" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>
                                        <Form.Item
                                            name="access_level"
                                        >
                                            <Select 
                                                className="collab-access-form-fields manrope f-12 mb-15" 
                                            >
                                                {loggedInUserAccess == 'edit' && <Option className='manrope f-12 w-400' value='edit'><Tooltip placement='right' title={"Editor can edit item's information, request renders and create variations"}>Editor</Tooltip></Option> }
                                                <Option className='manrope f-12 w-400' value='view'><Tooltip placement='right' title={"Viewer can view item's information and its renders"}>Viewer</Tooltip></Option>
                                            </Select>
                                        </Form.Item> 
                                    </Col>


                                    <Col span={5}>
                                        <Button htmlType="submit" disabled={accessUpdateLoader || inviteLoader} ref={formRef} className="modal-okay square font-12" >
                                            Share {inviteLoader ? <LoadingOutlined /> : ''}
                                        </Button>
                                    </Col>

                                </Row>
                            </Form>
                        </div>
                    </TabPane>
                    {publicLinks &&
                        <TabPane tab="Links" key="2" >
                            <div className='mb-10'>URL for public viewing</div>
                            <div>
                                {Object.entries(publicLinks).map(([k, v]) => {
                                    return (
                                        <div key={k} className="collaborators-list-item">
                                            <div>
                                                <span className="manrope f-14 w-500" style={{ display: 'block' }}>{k}</span>
                                            </div>

                                            <div>
                                                <Paragraph style={{ margin: '3px' }} copyable={{ icon: [<span key={k} className="manrope f-14 w-400"><LinkOutlined /> click to copy link </span>], text: v }}></Paragraph>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                        </TabPane>}
                </Tabs>
            </div>
        </Modal>
    </>)
}