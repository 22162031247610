import React, { useState, useEffect, useRef } from 'react';
import 'antd/dist/antd.css';
import '@ant-design/compatible/assets/index.css';
import _ from 'lodash'; // Import the entire lodash library
import { Grid, AutoSizer } from 'react-virtualized';
import { Col, Empty, Tag } from 'antd';
import { getRelevanceBasedOrderedSearchResult } from '../../Utilities';
import SceneCard from './SceneCard';
import ENVIRONMENT from '../../../../environments';
import LibraryGrid from '../LibraryGrid/LibraryGrid';

const RoomsListVirtualized = (props) => {
  const virtualizerContainer = useRef();
  const previousRoomsLength = useRef(null);
  const [currentSpaceData, setCurrentSpaceData] = useState([]);
  const [filteredValues, setFilteredValues] = useState([]);
  const [columnCount, setColumnCount] = useState(4);
  const [rowCount, setRowCount] = useState(0);
  const maxColWidth = 280;
  const {
    selectedRoom,
    setSelectedRoom,
    roomsData,
    type,
    searchValue,
    selectedCategories,
    setSelectedCategories,
    requestPlan,
    setData,
    multiple_select,
    selectedScenes,
    setSelectedScenes,
    creationType,
    isStore,
    requestStatusRoom,
    tabKey,
    updateTabCount
  } = props;
  const filtersHeight = `calc(100vh - ${
    selectedCategories.length > 0 ? '300px' : '220px'
  })`;

  const handleSpaceClick = (value) => {
    if(multiple_select){
        if(selectedScenes.find(ele => ele.id === value.id)){
            setSelectedScenes(selectedScenes.filter((ele) => ele.id !== value.id));
        }
        else
            setSelectedScenes([...selectedScenes, value]);
    }else{
        if (selectedRoom === value) {
            setSelectedRoom(null);
          } else setSelectedRoom(value);
    }
  };

  const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
    const data = filteredValues;
    const itemIndex = rowIndex * columnCount + columnIndex;
    const ele = data[itemIndex] && data[itemIndex];
    if (!ele) {
      return null;
    }
    const id = ele.room_id ?? ele.id;
    let selectedId = []
    if(multiple_select){
        selectedId = selectedScenes.map((ele) => ele.id)
    }
    else{
        selectedId.push(type === 'space' ? selectedRoom?.room_id : selectedRoom?.id)
    }
      
    const name = type === 'space' ? ele.room_name : ele.name;
    let thumbnail = ele.thumbnail;

    if (thumbnail && thumbnail.includes(ENVIRONMENT.DEFAULT_BASE_URL)) {
      thumbnail = thumbnail.replace(
          ENVIRONMENT.DEFAULT_BASE_URL,
          ENVIRONMENT.getBaseURL(ele.platform),
      );
    } else if (thumbnail && !thumbnail.includes('https')) {
      thumbnail =
        ENVIRONMENT.getBaseURL(ele.platform) +
        ENVIRONMENT.SCENE_THUMBNAIL_URI +
        ele.id +
        '/' +
        ele.thumbnail;
    }

    return (
      <div key={key} style={style}>
        <SceneCard
          last_modified_stamp={ele.last_modified_stamp}
          item_type={ele.item_type}
          id={ele.room_id ?? ele.id}
          name={type == 'scene' ? ele.name : ele.room_name}
          price={ele.price}
          customer_username={
            type == 'scene' ? ele.requested_by : ele.customer_username
          }
          company_display_name={ele.company_display_name}
          company_id={ele.company_id}
          getRequestPlan={requestPlan}
          itemType={type}
          thumbnail={thumbnail}
          category={type == 'scene' ? ele.room_name : ele.category}
          subscriptionPlan={''}
          platform={ele.platform}
          ele={ele}
          handleAddClick={handleSpaceClick}
          selectedId={selectedId}
          creationType={creationType}
          selectedScenes={selectedScenes}
          setSelectedScenes={setSelectedScenes}
          isStore={isStore}
          requestStatusRoom = {requestStatusRoom}
        />
      </div>
    );
  };

  const getSpaceData = () => {
    let data = [];
    if (searchValue != "" || selectedCategories?.length > 0) {
      data = props.checkFilteredResults(roomsData);
      if (data.length == 0 && selectedCategories?.length > 0 && searchValue == "" && props.tabChangeThroughClick == false) {
        props.switchTabIfEmpty();
      }
      if (data.length > 0 && props.tabChangeThroughClick == true) {
        props.setTabChangeThroughClick(false);
      }
      const newRoomListLength = data.length;
      if (newRoomListLength !== previousRoomsLength.current) {
        updateTabCount(newRoomListLength, tabKey);
      }
      
    } else if (searchValue == '' && selectedCategories?.length == 0) {
      data = [...roomsData];
      if (data.length !== currentSpaceData.length) {
        updateTabCount(-1, tabKey);
      }
    }

    setRowCount(Math.ceil(data.length / columnCount));
    previousRoomsLength.current = data.length;
    setCurrentSpaceData(data);
    return data;
  }

  useEffect(() => {
    let data = getSpaceData();
    setFilteredValues(data);
  }, [roomsData, searchValue, selectedCategories, tabKey]);


  useEffect(() => {
    setRowCount(Math.ceil(filteredValues.length / columnCount));
  }, [filteredValues]);

  return (
    <>
      
      <div
        ref={virtualizerContainer}
        style={{ width: '100%', height: filtersHeight, marginBottom: 144 }}>
          {currentSpaceData?.length > 0 ?
            <LibraryGrid
              setColumnCount={setColumnCount}
              setRowCount={setRowCount}
              maxColumnWidth={maxColWidth}
              data={currentSpaceData}
              cellRenderer={cellRenderer}
              columnCount={columnCount}
              rowCount={rowCount}
              getEstimatedRowSize={390}
            /> :
            <div className="no-models-div" style={{ marginTop: "25%" }}>
              <Empty description={"Nothing To Show"} className="manrope f-14 no-models-span" />
            </div>}
      </div>
    </>
  );
};

export default RoomsListVirtualized;
