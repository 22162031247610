import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Tabs, Button, Col, Tag, Row, Radio } from 'antd';
import _ from 'lodash'; // Import the entire lodash library
import 'antd/dist/antd.css';
import '@ant-design/compatible/assets/index.css';
import axios from 'axios';
import FileConstants from '../../../../FileConstants';
import ENVIRONMENT from '../../../../environments';
import RoomsListVirtualized from './RoomsListVirtualized';
import Filters from './Filters';
import DottedLoader from '../../DottedLoader';
import { CheckCircleFilled, LoadingOutlined } from '@ant-design/icons';
import * as Utilities from '../../Utilities';
const { TabPane } = Tabs;

const COMPANY_ID = localStorage.getItem('company_id');
const CUSTOMER_USERNAME = localStorage.getItem("username");
const MANAGED_CUSTOMER_USERNAME = localStorage.getItem('managed_customer_username');
const MANAGED_COMPANY_ID = localStorage.getItem('managed_company_id');
const MANAGED_CUSTOMER_EMAIL = localStorage.getItem('managed_customer_email');
const IS_MS_PROVIDER = localStorage.getItem("is_msprovider") === 'true' ? true : false;
const SHARED_ENTITIES = localStorage.getItem('shared_entities');
const SelectScene = (props) => {
  const [ownedSceneData, setOwnedSceneData] = useState([]);
  const [sharedSceneData, setSharedSceneData] = useState([]);
  const [mspUserData, setMspUserData] = useState([]);
  const [storeSceneData, setStoreSceneData] = useState([]);
  const [tabActiveKey, setTabActiveKey] = useState('owned');
  const [categories, setCategories] = useState([]);
  const [isMSProvider, setIsMSProvider] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [dataLoading, setDataLoading] = useState(false);
  const [mspFilteredData, setMspFilteredData] = useState([]);
  const [ownedFilteredData, setOwnedFilteredData] = useState([]);
  const [sharedFilteredData, setSharedFilteredData] = useState([]);
  const [storeFilteredData, setStoreFilteredData] = useState([]);
  const [productCollections, setProductCollections] = useState(null);
  const [initialLoadShared, setInitialLoadShared] = useState(true);
  const [initialLoadOwned, setInitialLoadOwned] = useState(true);
  const [sharedSceneLoader, setSharedSceneLoader] = useState(true);
  const [ownedSceneLoader, setOwnedSceneLoader] = useState(true);
  const [ownedTabType, setOwnedTabType] = useState('all');
  const [tabCount, setTabCount] = useState({owned: -1, other: -1, store: -1, msp: -1});
  const [tabChangeThroughClick, setTabChangeThroughClick] = useState(false);
  const {
    selectedRoom,
    setSelectedRoom,
    setStep,
    handleSubmit,
    setLoading,
    setIsStore,
    loading,
    multiple_select,
    selectedScenes,
    setSelectedScenes,
    creationType
  } = props;

  useEffect(() => {
    if (creationType != '') {
      if (props.empty_scene == true) {
        if (creationType == 'add-product-to-existing-scene') {
          setTabActiveKey("owned");
          setIsStore(false);
        }
        else {
          setTabActiveKey("store");
          setIsStore(true);
        }
      } else {
        setTabActiveKey("owned");
        setIsStore(false);
      }
    }
  }, [props.empty_scene, creationType]);

  const getScenePayload = (initial, type, sequence_id) => {
    const payload = {
      order_by: 'id desc',
      required_fields: [
        'id',
        'name',
        'scene_status',
        'thumbnail',
        'designed_for',
        'company_id',
        'scene_template_id',
        'render_generation',
        'collection_id',
        'customer_username',
        'img_render_count',
        'three_sixty_count',
        'threed_tour_count',
        'platform',
        'tags',
        'space_areas',
        "managed_scene",
        "last_modified_stamp"
      ],
    };

    
    if (sequence_id != -1) {
      payload['required_fields'].push(
        'folder_id',
        'folder_name',
        'parent_folder_id',
        'library_order',
        'entity_order',
      );
      payload['order_by'] = 'library_order asc,entity_order asc';
    } else {
      payload['order_by'] = 'id desc';
    }
    

    let filter_string = '';
    const scene_status = 'scene_status__in=[3]';
    let company_id = '';
    let shared_username = '';
    const is_hidden = 'is_hidden__not=true';
    const shared_hidden = 'shared_hidden__not=true';
    const customer_username = `customer_username__exact='${CUSTOMER_USERNAME}'`;
    const customer_username_notexact =
      'customer_username__notexact=\'' + CUSTOMER_USERNAME + '\'';
    const sequence_id_value = 'sequence_id__exact=' + sequence_id;
    const scene_template_null_true = '(scene_template_id__isnull=true)';
    const scene_template_null_false = 'scene_template_id__isnull=false';
    let is_hidden_managed_scene = "(is_hidden__is=true&&managed_scene__is=true)" // case when an msp removes scene after sharing with customer, customer should still be able to see it
    let scene_template_null_false_managed_scene = "((scene_template_id__isnull=true)||(scene_template_id__isnull=false&&designed_for__isnull=false))" // if a scene is a managed and template scene, show them
    const company_id_notexact = `company_id__notexact=${COMPANY_ID}||company_id__isnull=true`;
    const designed_for = '&&(designed_for__isnull=true)';
    if (type == 'shared') {
      shared_username = 'shared_username__exact=\'' + CUSTOMER_USERNAME + '\'';
      filter_string = `(${shared_username}&&${shared_hidden}&&${customer_username_notexact})||(${customer_username}&&${scene_template_null_false}&&${is_hidden})`;

      if (SHARED_ENTITIES != undefined && COMPANY_ID != undefined && SHARED_ENTITIES.split('_').includes('scene')) {
        company_id = `company_id__exact=${COMPANY_ID}`;
        filter_string = filter_string + `||(${company_id}&&${scene_template_null_false}&&${is_hidden})`
      }
      let not_managed_scene_check = "managed_scene__not=true"
      filter_string = "(" + filter_string + ")" + `&&(${not_managed_scene_check})`
    } else if (SHARED_ENTITIES != undefined && COMPANY_ID != undefined && SHARED_ENTITIES.split("_").includes("scene")) {
      company_id = `owned_by_companies__contains=ARRAY[${COMPANY_ID}]||company_id__exact=${COMPANY_ID}`;
      filter_string = `(${company_id}||${customer_username})&&(${scene_status})&&((${is_hidden})||${is_hidden_managed_scene})&&${scene_template_null_false_managed_scene}`;
    } else if (SHARED_ENTITIES != undefined && COMPANY_ID != undefined && !SHARED_ENTITIES.split("_").includes("scene")) {
      shared_username = "shared_username__exact='" + CUSTOMER_USERNAME + "'"
      filter_string = `(owned_by_companies__contains=ARRAY[${COMPANY_ID}]&&${shared_username})||(${customer_username}&&${scene_status}&&${is_hidden})&&${scene_template_null_true}`;
    } else {
      filter_string = `(${customer_username}&&${scene_status}&&${is_hidden})&&${scene_template_null_true}`;
    }

    if (sequence_id != -1) {
      filter_string =
        filter_string +
        '&&(' +
        sequence_id_value +
        '||sequence_id__isnull=true)';
    }

    if (productCollections && productCollections.length != 0) {
      filter_string = filter_string + '&&(collection_ids__notin=' + JSON.stringify(productCollections) + ')'
    }
    if (initial) {
      payload['pagination_filters'] = {
        limit: 100,
        offset: 0,
      };
    }

    if (IS_MS_PROVIDER && type == 'owned') {
      filter_string = filter_string + designed_for;
    }
    filter_string = filter_string + "&&(has_active_project__is=false)"
    payload['filter_string'] = filter_string;

    return payload;
  };

  const getMSPScenePayload = () => {
    const payload = {
      order_by: 'id desc',
      required_fields: [
        'id',
        'name',
        'scene_status',
        'thumbnail',
        'designed_for',
        'company_id',
        'scene_template_id',
        'render_generation',
        'collection_id',
        'customer_username',
        'img_render_count',
        'three_sixty_count',
        'threed_tour_count',
        'platform',
        'tags',
        'space_areas',
        'last_modified_stamp',
      ],
    };
    let filterString = '';

    const sceneStatus = 'scene_status__in=[3]';
    const isHidden = 'is_hidden__not=true';
    const customerUsername = `customer_username__exact='${CUSTOMER_USERNAME}'`;
    const designedFor = `(designed_for__exact='${MANAGED_CUSTOMER_USERNAME}')`;

    filterString = `(${customerUsername}&&${sceneStatus}&&${isHidden})&&${designedFor}`;
    payload['filter_string'] = filterString;

    return payload;
  };

  const getProductCollections = async (SelectedProductID) => {
    const payload = {
      "required_fields": ["id"],
      "order_by": "id desc"
    }
    let filterString = `((customer_username__exact='${CUSTOMER_USERNAME}'&&is_hidden__not=true)&&(template_collection_id__is=null)||(has_access_to__like='%\"${CUSTOMER_USERNAME}\"%'))&&(product_id__exact=${SelectedProductID})`
    payload['filter_string'] = filterString;

    await axios.post(ENVIRONMENT.LIST_COLLECTION, payload).then((res) => {
      let data = res.data
      let collection_list = data.map(ele => ele.id);
      setProductCollections(collection_list)
    });
  }

  useEffect(() => {
    if (productCollections) {
      fetchSceneData('owned', -1, true);
      fetchSceneData('shared', -1, true);
    }
  }, [productCollections])

  const getRoomsDataWithKeywords = (rooms) => {
    const result = rooms.map((obj) => {
      const keywords = [];

      if (obj.name) {
        for (const keyword of obj.name
          .replace(/[^a-zA-Z0-9 ]/g, ' ')
          .split(' ')) {
          if (!keywords.includes(keyword)) {
            if (keyword.length > 1) {
              keywords.push(keyword.toLowerCase());
            }
          }
        }
      }

      if (obj.room_name) {
        for (const keyword of obj.room_name
          .replace(/[^a-zA-Z0-9 ]/g, ' ')
          .split(' ')) {
          if (!keywords.includes(keyword)) {
            if (keyword.length > 1) {
              keywords.push(keyword.toLowerCase());
            }
          }
        }
      }

      return { ...obj, keywords: keywords };
    });
    return result;
  };

  const fetchMSPCustomerData = async () => {
    const payload = getMSPScenePayload();
    await axios.post(ENVIRONMENT.GET_SCENE_BATCH, payload).then((res) => {
      if (res.data) {
        const data = getRoomsDataWithKeywords(res.data);
        setMspUserData(data);
        setIsMSProvider(true);
      }
    });
  };

  const fetchSceneData = async (type, sequence_id, initial = false) => {
    if (!initial) {
      setDataLoading(true);
    } else {
      if (type == 'shared') {
        setInitialLoadShared(true);
      } else {
        setInitialLoadOwned(true);
      }
    }
    const payload = getScenePayload(initial, type, sequence_id);
    await axios.post(ENVIRONMENT.GET_SCENE_BATCH, payload).then((res) => {
      if (res.data) {
        const data = getRoomsDataWithKeywords(res.data);
        if (type == 'shared') {
          setSharedSceneData(data);
        } else {
          setOwnedSceneData(data);
        }
        if (initial) {
          if (type == 'shared') {
            setInitialLoadShared(false);
          } else {
            setInitialLoadOwned(false);
          }
        } else {
          if (type == 'shared') {
            setSharedSceneLoader(false);
          } else {
            setOwnedSceneLoader(false);
          }
        }
      }
    });
  };

  const fetchSpaceCategories = () => {
    const payload = {
      request_type: 'room_model',
    };
    axios.post(ENVIRONMENT.GET_CATEGORY_AND_PRICE, payload).then((res) => {
      let categories = [];
      if (res.data) {
        res.data.map((request) => {
          if (!categories.includes(request.category)) {
            categories.push(request.category);
          }
          categories = categories.filter((item) => item !== '');
          setCategories(categories);
        });
      }
    });
  };

  const handleTabChange = (key, event_type = 'invoked') => {
    setTabActiveKey(key);
    if (key == 'store') {
      setIsStore(true);
    } else setIsStore(false);

    if (event_type == 'click') {
      setTabChangeThroughClick(true);
    }
  };

  const fetchStoreScenesList = async (initial = false) => {
    const payload = {
      action: 'get_batch',
      status: '3',
    };
    await axios
      .post(ENVIRONMENT.TEMPLATE_ACTIONS, payload)
      .then((res) => {
        const data = getRoomsDataWithKeywords(res.data);
        setStoreSceneData(data);
      })
      .catch((error) => { });
  };

  useEffect(() => {
    const fetchData = async () => {
      let prod_id = new URLSearchParams(window.location.search).get("product_id")
      fetchSpaceCategories();
      if (prod_id) {
        await getProductCollections(prod_id)
      }
      else {
        await fetchSceneData('owned', -1, true);
        await fetchSceneData('shared', -1, true);
      }
      await fetchStoreScenesList();
      if (
        FileConstants.isMSProvider &&
        (localStorage.getItem('managed_customer_username') != null ||
          MANAGED_COMPANY_ID != null)
      ) {
        await fetchMSPCustomerData();
      }
      setDataLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    setMspFilteredData(mspUserData);
    setOwnedFilteredData(ownedSceneData);
    setSharedFilteredData(sharedSceneData);
    setStoreFilteredData(storeSceneData);
  }, [mspUserData, ownedSceneData, sharedSceneData, storeSceneData]);

  const continueSteps = () => {
    if (props.empty_scene == true) {
      setStep(3);
    } else {
      handleSubmit("");
    }

  }

  useEffect(() => {
    if (!initialLoadOwned && ownedSceneLoader) {
      fetchSceneData('owned', -1);
    }
  }, [initialLoadOwned]);

  useEffect(() => {
    if (!initialLoadShared && sharedSceneLoader) {
      fetchSceneData('shared', -1);
    }
  }, [initialLoadShared]);

  const goBack = () => {
    if (props.redirectionPath) {
      props.history.push({
        pathname: props.redirectionPath
      });
    } else {
      props.history.push({
        pathname: '/home'
      });
    }
  }

  const getSceneTabCount = (type) => {
    return tabCount[type] !== -1 ? tabCount[type] : getScenesLength(type);
  }

  const getScenesLength = (type, tab_options = 'not_all') => {
    if (type == "msp") {
      return mspUserData.length;
    } else if (type == "owned") {
      let scene_temp = ownedSceneData;
      if (ownedTabType == 'all' || tab_options == 'all') {
        return scene_temp.length;
      } else if (ownedTabType == 'self') {
        scene_temp = scene_temp.filter((scene) => (scene.managed_scene === false))
        return scene_temp.length;
      } else {
        scene_temp = scene_temp.filter((scene) => (scene.managed_scene === true))
        return scene_temp.length;
      }
    } else if (type == "store" && creationType != 'add-product-to-existing-scene') {
      return storeSceneData.length;
    } else if (type == "other") {
      return sharedSceneData.length;
    }
  };

  const getScenes = (type) => {
    if (type == "msp") {
      return mspUserData;
    } else if (type == "owned") {
      if (ownedTabType == 'all') {
        return ownedSceneData;
      } else if (ownedTabType == 'self') {
        const scenes = ownedSceneData.filter((scene) => (scene.managed_scene === false));
        return scenes;
      } else {
        const scenes = ownedSceneData.filter((scene) => (scene.managed_scene === true))
        return scenes;
      }
    } else if (type == "store" && creationType != 'add-product-to-existing-scene') {
      return storeSceneData;
    } else if (type == "other") {
      return sharedSceneData;
    }
  };


  const updateTabCount = (count, tabName) => {
    setTabCount(prevTabCount => ({
      ...prevTabCount,
      [tabName]: count
    }));
  };

  const onChangeOwnedTabType = (e) => {
    setOwnedTabType(e.target.value);
    setTabChangeThroughClick(true);
  }

  const closeTags = (entity) => {
    const arr = selectedCategories.filter((category) => {
      return entity != category;
    });
    setSelectedCategories(arr);
  };

  const getFilteredTags = () => {
    let filteredTags = <>{selectedCategories.length > 0 &&
      <Col span={24} style={{ marginLeft: 10 }}>
        <h4 className="manrope f-14 black-55 italic">Showing results for</h4>
        <div style={{ marginBottom: 20 }}>
          {selectedCategories?.map((category) => {
              return (
                <Tag
                  key={category}
                  onClose={() => closeTags(category, 'space_category')}
                  closable
                  visible={true}
                  className="manrope f-12 black-55 tag-filter"
                >
                  Category:{' '}
                  <span className="manrope f-12 grey-77">{category}</span>
                </Tag>
              );
            })}
        </div>
      </Col>}
    </>
    return filteredTags;
  }

  const tabs = [
    {
      type: 'msp',
      title: `Designed for ${localStorage.getItem('managed_customer_username')} (${getSceneTabCount('msp')}${searchValue != '' || selectedCategories.length > 0 ? ` of ${getScenesLength('msp')} scenes` : ``})`,
      data: getScenes('msp'),
      setData: setMspFilteredData
    },
    {
      type: 'owned',
      title: `Your Scenes ${ownedSceneLoader ? '' : `(${getSceneTabCount('owned')}${searchValue != '' || selectedCategories.length > 0 ? ` of ${getScenesLength('owned', 'all')} scenes` : ``})`}`,
      data: getScenes('owned'),
      setData: setOwnedFilteredData
    },
    {
      type: 'other',
      title: `Other Scenes ${sharedSceneLoader ? '' : `(${getSceneTabCount('other')}${searchValue != '' || selectedCategories.length > 0 || ownedTabType != 'all' ? ` of ${getScenesLength('other')} scenes` : ``})`}`,
      data: getScenes('other'),
      setData: setSharedFilteredData
    },
  ];
  if (creationType != 'add-product-to-existing-scene') {
    tabs.push(
      {
        type: 'store',
        title: `Store Templates (${getSceneTabCount('store')}${searchValue != '' || selectedCategories.length > 0 ? ` of ${getScenesLength('store')} scenes` : ``})`,
        data: getScenes('store'),
        setData: setStoreFilteredData
      },
    )
  }

  const checkFilteredResults = (scenesData) => {
    let data = [];
    if (searchValue != "") {
      data = Utilities.getRelevanceBasedOrderedSearchResult(searchValue, scenesData);
    } else if (selectedCategories?.length > 0) {
      data = Utilities.getRelevanceBasedOrderedSearchResult(selectedCategories.join(' '), scenesData);
    }
    return data;
  }

  const switchTabIfEmpty = () => {
    // if current selected tab is empty only then switch to other tab
    if (tabChangeThroughClick == false) {
      if (checkFilteredResults(getScenes(tabActiveKey))?.length == 0) {
        if ((IS_MS_PROVIDER && (MANAGED_CUSTOMER_USERNAME != null || MANAGED_COMPANY_ID != null)) && checkFilteredResults(getScenes("msp"))?.length > 0) {
          setTabActiveKey("msp");
        } else if (checkFilteredResults(getScenes("owned"))?.length > 0) {
          setTabActiveKey('owned');
        } else if (checkFilteredResults(getScenes("other"))?.length > 0) {
          setTabActiveKey('other');
        } else if (checkFilteredResults(getScenes("store"))?.length > 0) {
          setTabActiveKey('store');
        }
      }
    }
  }


  return (
    <div className="spaces-list">
      <Filters
        handleValueChange={setSelectedCategories}
        selectedValue={selectedCategories}
        filters={categories}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        title={props.empty_scene == true ? "Select a template" : "Select a scene"}
        searchPlaceholder="Search All Scenes"
      />
      {getFilteredTags()}
      <Tabs
        onChange={(key) => handleTabChange(key, 'click')}
        className={`product-tab library ${props.empty_scene == true ? `empty-scene-template` : ``}`}
        tabBarGutter={16}
        size="large"
        activeKey={tabActiveKey}
      >
        {(creationType == 'template-scene' && dataLoading) || (initialLoadOwned || initialLoadShared) ? (
          <DottedLoader />
        ) : (
          tabs?.map((tab, index) => {
            return (index === 0 && !isMSProvider) || (props.empty_scene == true && tab.type != "store") ? null : (
              <TabPane forceRender={true} tab={props.empty_scene == true ? '' : tab.title} key={tab.type} style={{ padding: 0 }}>
                {(tab.type == "owned" && (COMPANY_ID != undefined) && !IS_MS_PROVIDER) && 
                  <Row>
                    <div className="shared-models-tab manrope f-14 black-55">
                      <span className="shared-models-tab-span">
                        Show Items:
                      </span>
                      <Radio.Group onChange={onChangeOwnedTabType} value={ownedTabType}>
                        <Radio value='all'>
                          All
                        </Radio>
                        <Radio value='self'>
                          Created by You
                        </Radio>
                        <Radio value='managed'>
                          Created by Managed Service
                        </Radio>
                      </Radio.Group>
                    </div>
                  </Row>}
                <RoomsListVirtualized
                  tabChangeThroughClick={tabChangeThroughClick}
                  setTabChangeThroughClick={setTabChangeThroughClick}
                  checkFilteredResults={checkFilteredResults}
                  switchTabIfEmpty={switchTabIfEmpty}
                  type="scene"
                  roomsData={tab.data}
                  selectedRoom={selectedRoom}
                  setSelectedRoom={setSelectedRoom}
                  searchValue={searchValue}
                  selectedCategories={selectedCategories}
                  setSelectedCategories={setSelectedCategories}
                  setData={tab.setData}
                  multiple_select={multiple_select}
                  selectedScenes={selectedScenes}
                  setSelectedScenes={setSelectedScenes}
                  creationType={creationType}
                  updateTabCount={updateTabCount}
                  tabKey={tab.type}
                />
              </TabPane>
            );
          })
        )}
      </Tabs>

      <div className={`bottom-menu ${((!initialLoadOwned && ownedSceneLoader && tabActiveKey == "owned") || (sharedSceneLoader && tabActiveKey == "other")) ? 'justify-space-between-important' : ``}`} >
        {((!initialLoadOwned && ownedSceneLoader && tabActiveKey == "owned") || (sharedSceneLoader && tabActiveKey == "other")) &&
          <div className='manrope f-16 justify-in-start ml-100' style={{ color: '#276DD7', padding: 10 }}>
            <LoadingOutlined className='manrope f-24 mr-16' />
            Loading remaining {tabActiveKey} scenes
          </div>}
        <div className='mr-20 justify-in-end'>
          {(multiple_select && (selectedRoom || (selectedScenes && selectedScenes.length > 0))) && <div className='manrope black-d9 f-16 mr-16'><CheckCircleFilled /> {selectedRoom ? "1" : selectedScenes.length} selected</div>}

          <Button
            onClick={goBack}
            className='product-btn br-4 f-16 light-blue pd-16'>
            Cancel
          </Button>
          <Button
            onClick={continueSteps}
            disabled={selectedRoom == null && (selectedScenes && selectedScenes.length == 0)}
            className={(selectedRoom == null && (selectedScenes && selectedScenes.length == 0)) ? 'product-btn br-4 f-16 disabled-grey ml-16 pd-16' : 'product-btn dark-blue f-16 ml-16 br-4 pd-16'}>
            Continue {loading ? <LoadingOutlined /> : ''}
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => state;

export default withRouter(connect(mapStateToProps)(SelectScene));
