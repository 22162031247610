import React, {useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import DottedLoader from "../../DottedLoader";
import CustomerMainLayout from '../CustomerMainLayout'
import { MinusOutlined, ArrowRightOutlined,LoadingOutlined, DownloadOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Input, Col, Row, Card, Popconfirm, message, Button,Modal,Tooltip, Form } from 'antd';
import {getCollectionProducts, getCollectionRooms,updateCollection} from "../../../../redux/actions";
import {connect} from "react-redux";
import axios from 'axios';
import ENVIRONMENT from '../../../../environments'
import * as Utilities from '../../Utilities';
import FileConstants from '../../../../FileConstants'

import {withRouter, Link} from 'react-router-dom'

const COMPANY_ID = FileConstants.COMPANY_ID;
const CUSTOMER_USERNAME = FileConstants.CUSTOMER_USERNAME;
const IS_MS_PROVIDER = localStorage.getItem("is_msprovider") === 'true' ? true : false;

const CustomerCollectionProducts = (props) => {
    const { match: { params } } = props;
    const [modalVisible, setModalVisible] = useState(false);
    const [sceneName,setSceneName] = useState('');
    const [isLoading,setIsLoading] = useState(false);
    const [collectionName,setCollectionName] = useState(new URLSearchParams(window.location.search).get("name"));
    const [roomValidationModelVisible, setRoomValidationModelVisible] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [productValidationModelVisible, setProductValidationModelVisible] = useState(false);
    const [makeContentEditable, setContentMakeEditable] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [collectionDetail, setCollectionDetail] = useState(null);
    const [templateCollectionProducts, setTemplateCollectionProducts] = useState([]);
    const [collectionDifference, setCollectionDifference] = useState([]);
    const [sceneNames, setSceneNames] = useState([])
    const [collectionNames, setCollectionNames] = useState([]);
    const [form] = Form.useForm();
    const [assetsDownloadLink, setAssetsDownloadLink] = useState();

    const cancelModal = () => {
        setModalVisible(false);
    }
    const modalOk = () => {
        if (sceneName !== '') {
            setIsLoading(true);
            let values = {"scene_name": sceneName, "collection_id":params.id}
            let collectionDetails = getCollectionDetails(values.collection_id);
            if (collectionDetails.rooms_count == 0) {
                setIsLoading(false);
                setRoomValidationModelVisible(true);
            }
            else if(collectionDetails.rooms_count > 0 && collectionDetails.products_count == 0) {
                setIsLoading(false);
                setProductValidationModelVisible(true);
            }
            else {
                let payload = {
                    "scene_name": values.scene_name,
                    "collection_id": values.collection_id,
                    "room_id": "",
                    "username": localStorage.getItem('username')
                };
                let company_id = localStorage.getItem('company_id');
                if (company_id != undefined) {
                    payload.company_id = company_id;
                }
                getRoomAndCreateScene(payload, values.collection_id);
            }
            setModalVisible(false);
        }
    }
    useEffect(() => {
        props.getCollectionProducts({collection_id: params.id});
        props.getCollectionRooms({collection_id: params.id}); 
        populateCollectionDetails(); 
        getSceneNames();
        getCollectionNames();
    }, []);

    const generatePayload = () => {
        let payload = {
            'username': FileConstants.CUSTOMER_USERNAME
        }
        if (FileConstants.isMSProvider && (FileConstants.MANAGED_CUSTOMER_USERNAME != null)) {
          payload.designed_for = FileConstants.MANAGED_CUSTOMER_USERNAME;
        }
        if (FileConstants.SHARED_ENTITIES != undefined && FileConstants.COMPANY_ID != undefined) {
            if (FileConstants.SHARED_ENTITIES.includes('collection')) {
                payload.company_id = FileConstants.COMPANY_ID
            }
        }

        return payload
    }

    const getSceneNames = () => {
        let payload = generatePayload()

        axios.post(ENVIRONMENT.GET_SCENE_NAMES, payload)
        .then(res => {
            setSceneNames(res.data.body)
        })
    }

    const getCollectionNames = () => {
        let payload = generatePayload();
        axios.post(ENVIRONMENT.GET_COLLECTION_NAMES, payload)
        .then((response) => {
            let names = []
            response.data && response.data.map((coll => {
                names.push(coll.name);
            }))
            setCollectionNames(names);
        });
    }

    const triggerPostCollectionUpdate = () => {
        console.log("collection id", params.id)
        let collection_payload= {
            "collection_id": params.id  
        }
        axios.post(ENVIRONMENT.POST_COLLECTION_UPDATE, collection_payload)
            .then((res) => {
            })
    }
    const confirm = (product_id) =>{
        if (collectionDetail && collectionDetail.template_collection_id
            && (collectionDifference.length == 1 && collectionDifference.includes(product_id))) {
            message.error('You should have at least one of your own products in this collection.')
        } else {
            axios.post(ENVIRONMENT.DELETE_COLLECTION_PRODUCT, {
                collection_id: params.id, product_id: product_id
            })
                .then((response) => {
                    triggerPostCollectionUpdate();
                    message.success('Removing product from collection.');
                    window.location.reload(false);
                })
                .catch((error) => {
                    console.error('An error occurred:', error);
                });
        }
    }

    const cancel = (e) => {
    }
    const buildScene = () => {
        setModalVisible(true);
    }

    const getRoomAndCreateScene = (payload, id) => {
        axios.post(ENVIRONMENT.COLLECTION_GET_ROOMS, {
            collection_id: id.toString()
        })
            .then((response) => {
                payload.room_id = response.data[0].room_id;
                if (FileConstants.isMSProvider && (localStorage.getItem('managed_customer_username') != null || localStorage.getItem('managed_company_id') != null)) {
                    payload.designed_for = localStorage.getItem('managed_customer_username');
                }
                createScene(payload);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }
    const createScene = (payload) => {
        axios.post(ENVIRONMENT.UPDATE_SCENE, payload)
            .then((res) => {
                message.info('Scene created successfully');
                var scene_id = res.data["scene_id"];
                window.location.href = '/scene_builder/' + scene_id + '?origin=/home';
            })
            .catch((error) => {
                message.error('Error in creating scene');
                setIsLoading(false);
            });
    }

    const getCollectionDetails = (id) => {
        let collection_details = {
            products_count: 0,
            rooms_count: 0
        };
        if (props.collectionReducer.collection.collection_id == id) {
            collection_details.products_count = props.collectionReducer.products.length;
            collection_details.rooms_count = props.collectionReducer.rooms.length;
        }    
        return collection_details;
    }

    const populateCollectionDetails = () => {
        let payload = {
            collection_id: params.id.toString()
        }
        axios.post(ENVIRONMENT.GET_COLLECTION_DATA,payload)
        .then(res => {
            console.log(res)
            if (res.data) {
                setCollectionDetail(res.data);
                if (res.data.template_collection_id) {
                    let product_payload = {
                        collection_id: res.data.template_collection_id
                    }
                    axios.post(ENVIRONMENT.FETCH_COLLECTION_PRODUCTS, product_payload)
                    .then(res => {
                        
                        if (res.data && res.data.length > 0) {
                            let products = []
                            res.data.map((product) => {
                                products.push(product.id)
                            })
                            console.log('Template collection Products', products)
                            setTemplateCollectionProducts(products);
                        }
                    });
                }
            }
        })
    }

    useEffect(() => {
        if (props.collectionReducer.products && props.collectionReducer.products.length > 0 && collectionDetail && collectionDetail.template_collection_id) {
            let products = []
            props.collectionReducer.products.map((product) => {
                products.push(product.id)
            })
            console.log('Collection products', products)
         
            let difference = products.filter(x => !templateCollectionProducts.includes(x));
            console.log(difference, 'dif')
            setCollectionDifference(difference)
        }
    }, [templateCollectionProducts, props.collectionReducer.products])

    const onChange = (e) => {
        const { value } = e.target;
        setSceneName(value);
    }

    useEffect(() => {
        if (buttonLoader) {
            message.success('Collection name updated successfully!');
            setContentMakeEditable(false);
            window.location.href = '/collection/' + params.id + '/products/?name=' + collectionName
        }
    },[props.collectionReducer.isUpdated])

    const editCollectionName = () => {
       
        let collection_text_element = document.getElementById("collection_name");

        if (collection_text_element) {
            setErrorMessage('');
            
            
            if (collection_text_element.innerText) {
                if (collection_text_element.innerText != collectionName) {
                    if(collectionNames.includes(collection_text_element.innerText)){
                        setButtonLoader(false);
                        setErrorMessage("You have collection with same name. Please choose a different name!");
                    }
                    else{
                        setButtonLoader(true);
                        collection_text_element.contentEditable = false;
                        setCollectionName(collection_text_element.innerText);
                        props.updateCollection({
                            collection_id: params.id,
                            collection_name: collection_text_element.innerText,
                            edit_flag: true,
                            customer_username: localStorage.getItem("username")
                        });
                    }
                } else {
                    setButtonLoader(false);
                    setErrorMessage("You have not made any change to your collections's name.");
                }
            } else {
                setErrorMessage('You cannot leave this field empty.');
                setButtonLoader(false);
                if (collection_text_element) {
                    console.log('here')
                    collection_text_element.innerText = collectionName;
                }
            }
        }
    }

    const makeEditable = () => {
        let collection_text_element = document.getElementById("collection_name");

        if (collection_text_element) {
            collection_text_element.contentEditable = true;
        }

        setContentMakeEditable(true);
    }

    const collectionRooms = props.collectionReducer.rooms.map((ele, index) => {
        let RoomEntry =  <Col key={index} lg={6} sm={24} md={8} offset={0}>
            <Card bodyStyle={{ padding: "10px" }} className="card-shadow-product" bordered={false} style={{ marginRight: "16px", marginBottom: "16px", cursor: "pointer",border: "solid 1px #e3e3e3"}}>
                    <Link to={`/rooms/${ele.room_id}`} className='suggested-product' style={{zIndex:"1"}} >
                        <div className="box">
                            {ele.thumbnail ? (
                            <img
                                src={ENVIRONMENT.getBaseURL(ele.platform) + ENVIRONMENT.SPACE_THUMBNAIL_URI + ele.room_id + '/' + ele.thumbnail}
                                style={{ objectFit: "cover", width: "100%", height: 280 }}
                            />) 
                            : (
                            <React.Fragment>
                                <img
                                src={require("../../../../assets/images/space_incomplete.svg")}
                                style={{
                                    objectFit: "cover",
                                    width: "100%",
                                    height: 280,
                                    opacity: "0.5",
                                    filter: "blur(0px)",
                                }}
                                />
                                <div className="incomplete-request-text">
                                <span>Image not Found</span>
                                </div>
                            </React.Fragment>
                            )}
                        </div>
                        
                        <div className='justify-in-start' style= {{margin:"12px 12px 8px 12px"}}>
                            <Tooltip title={<span className='manrope f-12 white'>{ele.room_name}</span>}>
                            <span className="justify-in-start manrope f-14 w-500 black-00 clamp-text w-50">{ele.room_name}</span>
                            </Tooltip> 
                        </div>
                                    
                        <div className="justify-in-start" style= {{marginLeft:"12px",marginRight:"12px",marginBottom:"12px",textAlign:"left"}}> 
                            <span className="manrope f-12 grey-77 capitalize" style={{marginRight: 4}}>Category:</span>
                            <span className="manrope f-12 black-33 capitalize">{ele.category}</span>
                        </div>
                    </Link>
                </Card>
        </Col>
        return RoomEntry;
    });

    const onMouseOut = (evt) => {
        let element = document.getElementById('edit-icon');
        if (element) {
            element.setAttribute("src","/img/edit-w-hover.png");
        }
    }

    const onMouseOver = (evt) => {
        let element = document.getElementById('edit-icon')
        if (element) {
            element.setAttribute("src","/img/edit-icon.png");
        }
    }

    const checkFileExists = async (url, loader) => {
        console.log('File exists.')
        try {
            const response = await fetch(url, { method: "HEAD" });
            if (response.ok) {
                const linkElement = document.createElement("a");
                linkElement.href = url;
                linkElement.download = true;
                linkElement.click(); 
                setTimeout(loader);
            } else {
                setTimeout(()=> checkFileExists(url, loader), 5000); // try again in 5 seconds
            }
        } catch (error) {
            setTimeout(() => checkFileExists(url, loader), 5000); // try again in 5 seconds
        }
      }

    // Download User Assets of the Collection
    const downloadAssets = () => {
        let loader = message.loading('Preparing to download..',0);
        if(!assetsDownloadLink){

            let payload = {
                collection_id: params.id,
                username: CUSTOMER_USERNAME,
                company_id: COMPANY_ID
            }
    
            axios.post(ENVIRONMENT.DOWNLOAD_COLLECTION_USER_ASSETS, payload)
            .then(res => {
                setAssetsDownloadLink(res.data.archiveUrl);
                const download_url = res.data.archiveUrl;
                if (res.data.statusCode == 200){
                    checkFileExists(download_url , loader);
                } else {
                    setTimeout(loader);
                    let info = message.info('There are no products or spaces that belong to you.');
                    setTimeout(info , 3000);
                }
            })
        }
    }

    return (
        <CustomerMainLayout selected='13'>
            <Row style={{ padding:" 12px 0px 0 0px"}}>
                <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', zIndex: "1" ,marginTop:"10px",marginBottom:"10px"}}>
                    <div className="manrope f-16 grey-73" style={{marginBottom:0,alignSelf:"center"}}>
                        {FileConstants.isMSProvider ? <><Link className="store-bd " to={`/list-collections`} >Collection Library</Link> / <span className="manrope f-16 active-route-link-color">{new URLSearchParams(window.location.search).get("name")}</span></>
                        :  <><Link className="store-bd " to={`/scene_renders/${new URLSearchParams(window.location.search).get("scene_id")}`} >Scene Detail</Link> / <span className="manrope f-16 active-route-link-color">{new URLSearchParams(window.location.search).get("name")}</span></>}
                    </div>
                    <div  style={{display: "flex", justifyContent: "space-between"}}>
                    <Tooltip title="Download your spaces and products" placement="bottom">
                        <Button onClick={downloadAssets} className="icon-button-container mr-8">
                            <DownloadOutlined />
                        </Button>
                    </Tooltip>
                    {(collectionDetail && collectionDetail.render_generation && props.collectionReducer.products && props.collectionReducer.products.length != 0) &&
                    <Button 
                    className="modal-okay square font-16 pd-6-14 h-40"  
                    shape="round" style={{ marginRight: "10px"}} 
                    data-tut="reactour__stil_images_button" 
                    onClick={buildScene}>Create Scene{isLoading ? <LoadingOutlined/> : ""}</Button>}
                    </div>
                </Col>
            </Row>
            {
                FileConstants.isMSProvider ? 
                <Row style={{marginBottom: errorMessage == "" ? 20 : 8}}>
                <Col span={12} className="justify-in-start">
                    <div className={`manrope f-18 black-14 w-700 ${makeContentEditable ? `editable-text-settings` : ``}`} id="collection_name">
                    {collectionName}
                    </div>
                    {makeContentEditable ? 
                    <Button disabled={buttonLoader} className="save-button font-14" onClick={editCollectionName} style={{marginLeft: 20}}>
                        Save&nbsp;{buttonLoader ? <LoadingOutlined/> : ''}
                    </Button>
                    :  
                    <Tooltip title={<span className="manrope f-12 white">Edit Collection Name</span>}>
                        <img id="edit-icon" style={{width: 30, height: 30}} src="/img/edit-w-hover.png" onClick={makeEditable} className="edit-icon-data" onMouseOut={onMouseOut} onMouseOver={onMouseOver}/>
                    </Tooltip>}

                </Col>
            </Row> : '' }
            {errorMessage == "" ? '':
            <Row style={{marginBottom: 20}}>
                <Col span={12} className="justify-in-start">
                    <div className="justify-space-between err-bg manrope f-12 red-error" style={{marginRight: 8}}><img src="/img/error-small.png" style={{marginRight: 8}}/><span>{errorMessage}</span></div>
                </Col>
            </Row>
            }
           <div className="text-center">
               {props.collectionReducer.isLoading ? <DottedLoader />: (props.collectionReducer.products.length == 0 ?  'No products in this collections': '')}
           </div>
            <Row gutter={12}>
                {collectionRooms}
                {!props.collectionReducer.isLoading && props.collectionReducer.products.map((ele, index) => {
                    return (
                        <Col span={6} key={index} offset={0}>
                            <Card bodyStyle={{padding: "10px"}} className="card-shadow-product" bordered={false} style={{marginRight: "16px", marginBottom: "16px"}}>
                                {(collectionDetail && collectionDetail.render_generation) &&
                                <Popconfirm
                                    title="Are you sure?"
                                    onConfirm={() => confirm(ele.id)}
                                    onCancel={cancel}
                                    okText="Yes"
                                    cancelText="No">
                                   <MinusOutlined
                                       title="Remove From Collection"
                                       onClick={() => {}}
                                       className="group-settings plus-icon"
                                       />
                                </Popconfirm>}
                                <Link to={`/products/${ele.id}`} className="suggested-product" style={{zIndex:"1"}} >
                                    <div className="box">    
                                        <img style={{objectFit: "contain",height:"275px",zIndex:1}} className="product-image" src={ele.thumbnail.replace(ENVIRONMENT.getBaseURL(), ENVIRONMENT.getBaseURL(ele.platform)) || require("../../../../assets/images/chair.png")} />
                                    </div>
                                    <hr style={{margin: 0}}/>

                                    <div className='justify-in-start' style= {{margin:"12px 12px 8px 12px"}}>
                                        <Tooltip title={<span className='manrope f-12 white'>{ele.name}</span>}>
                                        <span className="justify-in-start manrope f-14 w-500 black-00 clamp-text w-50">{ele.name}</span>
                                        </Tooltip> 
                                       
                                    </div>
                                    
                                    <div className="justify-in-start" style= {{marginLeft:"12px",marginRight:"12px",marginBottom:"12px",textAlign:"left"}}> 
                                        <span className="manrope f-12 grey-77 capitalize" style={{marginRight: 4}}>Category:</span>
                                        <span className="manrope f-12 black-33 capitalize">{ele.category}</span>
                                    </div>
                        
                                  
                                </Link>
                            </Card>
                        </Col>
                    );
                })}
            </Row>
            <Modal
                    visible={modalVisible}
                    onCancel={cancelModal}
                    width={700}
                    className="modal-space"
                    bodyStyle={{padding: 0}}
                    footer={null}>
                    <Form
                        ref={form}
                        onFinish={modalOk}
                    >
                        <div style={{padding:"20px",display:"flex"}}>
                            <Col span={24}>
                                <h2 className="modal-heading manrope f-14 black-00 w-600">Add Scene Name</h2>
                                <Form.Item
                                    name="scene_name"
                                    rules={[{ required: true, message: 'Please add scene name.' },
                                    {
                                        validator: async(_, value) => {
                                        // if scene with same name exists
                                        if(sceneNames && sceneNames.includes(value)){
                                                throw new Error('Scene name must be unique. Please choose a different name!');
                                            }
                                        }
                                        }
                                    ]}
                                >
                                    <Input onChange={onChange} className='manrope f-12 black-55' placeholder='Enter Scene Name' style={{width: "100%",height:"40px"}}/>

                                </Form.Item>
                            </Col>
                        </div>
                        <div className="justify-in-end" style={{paddingBottom: 30, paddingRight: 20}}>
                            <Button className="modal-okay-gray square font-12" key="back" style={{marginRight: 20}} onClick={cancelModal}>
                            Cancel
                            </Button>
                            <Button htmlType='submit' className="modal-okay square font-12" key="ok">
                                Create Scene
                            </Button>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    visible={roomValidationModelVisible}
                    onCancel={() => setRoomValidationModelVisible(false)}
                    width={740}
                    className="model-space-alert"
                    bodyStyle={{padding: 0}}
                    footer={[
                        <div className='justify-in-end'>
                            <Button className="modal-okay square font-14" key="ok" onClick={() => setRoomValidationModelVisible(false)}>
                                Okay
                            </Button>
                        </div>
                    ]}>
                    <div style={{padding:"30px",display:"flex"}}>
                        <img src='/img/alert-triangle.svg' style={{width:"64px",height:"64px",marginRight:"20px"}} alt="Alert"/>
                        <div>
                            <h2 className="modal-heading manrope f-16 black-00 w-600">No Space has been selected</h2>
                            <p className="modal-text manrope f-14 black-55">You have not selected any Space. Please select at least one space</p>
                        </div>
                    </div>
                </Modal>
                <Modal
                    visible={productValidationModelVisible}
                    onCancel={() => setProductValidationModelVisible(false)}
                    width={740}
                    className="model-space-alert"
                    bodyStyle={{padding: 0}}
                    footer={[
                        <Button className="modal-okay square font-14" key="ok" onClick={() => setProductValidationModelVisible(false)}>
                            Okay
                        </Button>
                    ]}>
                    <div style={{padding:"20px",display:"flex"}}>
                        <img src='/img/alert-triangle.svg' style={{width:"64px",height:"64px",marginRight:"20px"}} alt="Alert"/>
                        <div>
                            <h2 className="modal-heading manrope f-16 black-00 w-600">No Product has been selected</h2>
                            <p className="modal-text manrope f-14 black-55">You have not selected any Product. Please select at least one product.</p>
                        </div>
                    </div>
                </Modal>
        </CustomerMainLayout>
    );
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    getCollectionProducts: (payload) => {
        dispatch(getCollectionProducts(payload));
    },
    getCollectionRooms: (payload) => {
        dispatch(getCollectionRooms(payload));
    },
    updateCollection: (payload) => {
        dispatch(updateCollection(payload));
    }
    
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
    )(CustomerCollectionProducts)
)
