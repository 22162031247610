import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Menu, Dropdown } from "antd";
import {Link} from "react-router-dom";
import {
    Logout,
} from 'react-cognito';
import LogoutButton from '../AuthComponents/LogoutButton';
import {connect} from "react-redux";


function clearLocalStorage(){
    localStorage.removeItem("all3d_jwt_token");
    localStorage.removeItem("super_admin_username")
    localStorage.removeItem("super_admin_expiry_token")
    localStorage.removeItem("modified_super_admin_token")
    localStorage.removeItem("customer_company")
    localStorage.removeItem("shared_entities")
    localStorage.removeItem("product_collab_qa")
    localStorage.removeItem("managed_customer_username");
    localStorage.removeItem("managed_company_id");
    localStorage.removeItem("managed_company_name")
    localStorage.removeItem("is_msprovider");
    localStorage.removeItem("managed_customer_email");
    localStorage.removeItem("username");
    localStorage.removeItem("company_id");
    localStorage.removeItem('sequence_ids')
    window.history.pushState("ALL3D Console", "ALL3D Console", "/");
}


const UserButton = (props) => {
    const [tourStep, setTourStep] = useState(new URLSearchParams(window.location.search).get("step_no"));
    const [dropdownParams, setDropdownParams] = useState(new URLSearchParams(window.location.search).get("account_dropdown"));
    const [visibleTour, setVisibleTour] = useState(false);
    useEffect(() => {
        if (tourStep=='3' && dropdownParams) {
            setVisibleTour(true);
        }
    })

    const onVisibleChange = () => {
        setVisibleTour(!visibleTour);
    }

    const menu = (user_type) => {
        return <Menu>
            {user_type == 2 ?
                <Menu.Item key="2" disabled={tourStep == '3' && dropdownParams}>
                    {<Link to='/account-details'>
                        <span data_tut="reactour__accountOption">Account Details</span>
                    </Link>}
                </Menu.Item>
                :
                ""}
            {user_type == 2 ?
            <Menu.Item key="4" onClick={() => window.location.href = "/home/?step_no=5"}>
                Help
            </Menu.Item>: ''
            }
            
            <Menu.Item key="3">
                <Logout onLogout={clearLocalStorage}>
                        <LogoutButton />
                </Logout>
            </Menu.Item>
        </Menu>
    };
    
        
    return (
        <div className="user-btn">
            <Dropdown onVisibleChange={onVisibleChange} visible={visibleTour} overlay={menu(props.user_type)}  trigger={["click"]}>
                <a className="ant-dropdown-link justify-in-center" href="" style={{display: 'flex'}}>
                    <Avatar className="pic" icon={<UserOutlined />} />
                    <span className="username">{props.cognito.user.username}</span>
                    <span className="float-right">
                            <DownOutlined className="user-arrow-down" />
                    </span>
                </a>
            </Dropdown>
        </div>

    );
    
}
const mapStateToProps = state => state;
export default connect(
    mapStateToProps
)(UserButton)
