import React, { useContext,useState, useEffect } from 'react';
import _ from "lodash";
import axios from 'axios';
import ENVIRONMENT from '../../../../environments';
import CustomerProductContext from '../../ContextFiles/CustomerProductContext';
import { Button, Menu, Dropdown, Tooltip } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import SiloPhotographyPresetsModal from '../../SiloPhotography/SiloPhotographyPresetsModal';


const AssetButtons = (props) => {
    const { product_id, openVariationModal, openVideoModal,
        openDimensionalModal, openThreeSixtyModal, statusOf360Spin, statusOfMp4Video, productData } = useContext(CustomerProductContext);

    const [openSiloPresetModal, setOpenSiloPresetModal] = useState(false);
    const [selectedPreset, setSelectedPreset] = useState(-1);
    const [allPresets, setAllPresets] = useState([]);
        
    
    useEffect(()=> {
        fetchProductSiloPresets() 
    },[])
    
    const menu_bottom = (
        <Menu style={{ width: "148px" }}>
            <Menu.Item className='manrope f-14' key={0} onClick={openVideoModal} disabled={statusOfMp4Video == "pending"}>
                <Tooltip title={statusOfMp4Video == "pending" ? "We are processing your MP4 Video request at the moment. You’ll be able to request a new MP4 Video once that request is complete. " : ""}>
                    <span className='cursor-pointer'>MP4 Video</span>
                </Tooltip>
            </Menu.Item>
            <Menu.Item key={1} onClick={openDimensionalModal} className='manrope f-14'>
                <span className='cursor-pointer'>Dimensional Image</span>
            </Menu.Item>
            <Menu.Item className='manrope f-14' key={2} onClick={openThreeSixtyModal} disabled={statusOf360Spin == "pending"}>
                <Tooltip title={statusOf360Spin == "pending" ? "We are processing your 360 Spin request at the moment. You’ll be able to request a new 360 Spin once that request is complete. " : ""}>
                    <span className='cursor-pointer'>Product 360 Spin</span>
                </Tooltip>
            </Menu.Item>
            <Menu.Item key={3} onClick={openVariationModal} className='manrope f-14'>
                <span className='cursor-pointer'>Product Variant</span>
            </Menu.Item>
        </Menu>
    );
    

    const gotoSiloPresetModal = () => {
        setOpenSiloPresetModal(true)
    }
    
    const goToSilo = () => {
        window.location.href = "/silo-tool/" + product_id + "?origin=/products/"+product_id;
    }

    const goToAddLifestyle = () => {
        window.location.href = "/add-lifestyle/" + product_id + "?origin=/products/"+product_id;
    }
    
    const fetchProductSiloPresets = () => {
        axios.post(ENVIRONMENT.CUSTOMER_SILO_CONFIG, {"username" : localStorage.getItem('username'), 'action':'get_presets'})
        .then(res => {
            if (res.data && res.data.length > 0 ){
                let presets = [];
                for (let i = 0; i< res.data.length; i++){
                    if (res.data[i].settings[productData.category] != undefined){
                        presets.push(res.data[i]);
                    }
                }
                setAllPresets(presets);
            }
        })
    }

    return (<>
        <Dropdown overlay={
            <Menu style={{ width: "155px", padding:"8px 0px", marginTop:"5px", borderRadius:"1px",dropShadow:"0px 3px 6px -4px #0000001F" }}>
                <Menu.Item className='manrope f-14' key={0} onClick={()=>goToSilo()} >
                    <span className='cursor-pointer'>From Scratch</span>
                </Menu.Item>
                <Menu.Item disabled={allPresets.length==0} key={1} onClick={()=>gotoSiloPresetModal()} className='manrope f-14'>
                    <span style={allPresets.length==0 ? {cursor:"not-allowed"} : {cursor:"pointer"}} className='cursor-pointer'>From a Saved Preset</span>
                </Menu.Item>
            </Menu>
        } trigger={['click']} >
            <Button className='product-btn dark-blue f-16 br-4' ghost>
                Generate Silos
            </Button>
        </Dropdown>
        <SiloPhotographyPresetsModal id={product_id} openSiloPresetModal={openSiloPresetModal} selectedPreset={selectedPreset} allPresets={allPresets} setSelectedPreset={setSelectedPreset} setOpenSiloPresetModal={setOpenSiloPresetModal} />
        <Button className='product-btn dark-blue f-16 ml-16 br-4' onClick={goToAddLifestyle}>
            Generate Lifestyle
        </Button>
        <Dropdown
            overlay={menu_bottom} trigger={['click']} className='ml-16'>
            <Button style={{width:"165px"}} className='product-btn light-blue f-16 br-4' ghost>
                More {props.up ? <UpOutlined /> : <DownOutlined />}
            </Button>
        </Dropdown>
    </>
    );
};

export default AssetButtons;