import React from "react";
import AWS from "aws-sdk";
import PropTypes from "prop-types";
import { registerUser } from "react-cognito";
import ENVIRONMENT from "../../../../environments";
import { LoadingOutlined, LeftOutlined, ConsoleSqlOutlined } from "@ant-design/icons";
import { Input, Button, Row, Col, Select, Form, message, Upload, Progress, Radio } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import { Country } from "country-state-city";
import Dashboard from "../../Dashboard";
import * as Constants from "../../CustomerComponents/Constants";
import * as Styles from "../../../../Styles";
import "./RegisterArtist.scss";
import { validateFileUploads } from "../../Utilities";

const progress_bar = Styles.progress_bar;

const { Option } = Select;
const { Dragger } = Upload;

const skillsList = [
  "3DS Max",
  "Vray",
  "PBR",
  "USDZ",
  "GLTF",
  "Obj",
  "Z-Brush",
  "Photoshop",
  "Substance Painter",
  "Marvelous designer",
];

const formDiv = {
  display: "flex",
  "justify-content": "space-around",
  "margin-top": "10px",
};

const additionalFormDiv = {
  display: "flex",
  "justify-content": "space-around",
  "margin-top": "10px",
  marginBottom: "10px",
};

const formStyle = {
  "background-color": "white",
  "border-radius": "5px",
};

const options = ["No Proficiency", "Average Proficiency", "Good Proficiency"];

const map_prof_options = {
  "No Proficiency" : 0,
  "Average Proficiency" : 5,
  "Good Proficiency" : 10
}

class RegisterArtist extends React.Component {
  formRef = React.createRef();
  errorRef = React.createRef();

  constructor(props) {
    super(props);
    let email_regex = /email=([^&]+)/.exec(window.location.href); //extracted email
    let email = "";
    if (email_regex && email_regex[1]) {
      email = email_regex[1];
    }
    this.state = {
      error: null,
      username: null,
      profile_name: null,
      password: null,
      password_confirm: null,
      skype: '',
      email: email || null,
      isLoading: false,
      user_group: "test_artist",
      stage: 1,
      step: 1,
      user_type: "test_artist",
      years_of_experience: null,
      country: null,
      skills: null,
      proposed_hourly_rate: null,
      proposed_working_hours_per_week: null,
      writing_english_prof: null,
      reading_english_prof: null,
      listening_english_prof: null,
      portfolio_url: "",
      portfolio: [],
      uploading: false,
      errorFlag: false,
      children: [],
      portfolioFilesList: [],
      errorMessage: null,
      hasError: false,
      pricingMethod: 1,
      billing_type:null,
      artist_skills: {},
      artist_invite: null,
      agency_billing_rate: null,
      agency_billing_type: null,
      show_billing_fields: true
    };
  }

  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  componentDidMount() {
    let search_url = window.location.href;
    let email_regex = /email=([^&]+)/.exec(search_url); //extracted email
    let email = "";
    if (email_regex && email_regex[1]) {
      email = email_regex[1];
    }
    console.log("Email ", email);
    let email_to_lowercase = email.toLowerCase();
    this.setState({
      email: email_to_lowercase,
    });

    this.setState({
      user_type: "test_artist",
    });

    axios.post(ENVIRONMENT.SKILL_GET_BATCH, {})
    .then(res => {
      this.setState({skills: res['data']})
    })

    axios.post(ENVIRONMENT.ARTIST_INVITE_GET, {
      'email': email_to_lowercase
    }).then(res => {
      if(res['data']){
        let addition_details = res.data['additional_details']
        console.log('addition ', addition_details)
        if(addition_details && res.data['agency_name'] && !res.data['agency_admin']){
          if(addition_details['billing_type'] && addition_details['hourly_rate']){
            this.setState({agency_billing_type: addition_details['billing_type'],agency_billing_rate: addition_details['hourly_rate'], show_billing_fields: false})
          }
        }
      }
    })
  }


  componentDidUpdate() {
    if(this.state.error && this.errorRef.current)
      this.errorRef.current.scrollIntoView({ behavior: "smooth" });
  }

  loginUser = () => {
    this.setState({
      stage: 3,
    });
  };

  onSelectSkill = (skill_name, skill_value) => {
    let skills = this.state.artist_skills;
    skills[skill_name] = skill_value
    this.setState({artist_skills: skills})
  }

  getSkillInitialValue = (skill_name) => {
    let skill_value = null
    for(let key in this.state.artist_skills){
      if(key == skill_name){
        skill_value = this.state.artist_skills[key]
      }
    }
    return skill_value
  }

  onSubmitContinue = (values) => {
    this.setState({ error: "" });
    if (this.state.username){
    this.setState({ isLoading: true });
    axios
      .post(ENVIRONMENT.GET_CUSTOMER_AND_COMPANY, { customer_username: this.state.username })
      .then((res) => {
        if(res.data.length > 0){
        if (res.data.customer_username == "") {
          // If no username is returned that means this username is unique
          this.setState({
            error: "",
          });
        } else {
          this.setState({ isLoading: false });
          this.setState({
            error: "A User with this username already exist",
          });
        }
        }
      });
    }
    if (
      this.state.email &&
      this.state.username &&
      this.state.password &&
      this.state.password_confirm
    ) {
      this.setState({ error: "" });
      let email_to_lowercase = this.state.email.toLowerCase();
      // if email is entered, validate if it is unique
      this.setState({ isLoading: true });
      axios
        .post(ENVIRONMENT.FETCH_USERNAME, { email: email_to_lowercase })
        .then((res) => {
          if (res.data.username == null) {
            // If no username is returned that means this email is unique
            if (
              this.state.password === this.state.password_confirm
            ) {
              if(this.state.error === ""){
              this.setState({ isLoading: false });
              this.setState({ step: 2 });
              }
            } else {
              this.setState({ isLoading: false });
              this.setState({
                error: "Passwords do not match",
              });
            }
          } else {
            this.setState({ isLoading: false });
            this.setState({
              error: "A User with this email address already exist",
            });
          }
        });
    }
  };

  onSubmit = (values) => {
    if(this.state.uploading){
      this.setState({error: "Uploads are in progress. Please wait for the uploads to complete."})
    }
    else {
      this.setState({ error: "" });
      this.setState({ isLoading: true });
      if (this.state.pricingMethod === 1) {
        this.setState({ billing_type: "hourly" });
      } else {
        this.setState({ billing_type: "per_model" });
      }

      let payload = {
        username: this.state.username,
        skype : this.state.skype,
        profile_name: this.state.profile_name,
        user_group: this.state.user_group,
        user_type: this.state.user_type,
        email: this.state.email.toLowerCase(),
        years_of_experience: this.state.years_of_experience,
        country: this.state.country,
        proposed_hourly_rate: this.state.proposed_hourly_rate,
        proposed_working_hours_per_week:
          this.state.proposed_working_hours_per_week,
        reading_english_proficiency:
          map_prof_options[this.state.reading_english_prof],
        writing_english_proficiency:
          map_prof_options[this.state.writing_english_prof],
        listening_english_proficiency:
          map_prof_options[this.state.listening_english_prof],
        portfolio_url: this.state.portfolio_url,
        portfolio_files: this.state.portfolioFilesList,
        action: "add_new_test_artist",
        billing_type: this.state.billing_type,
        skills: this.state.artist_skills
      };
      if(this.state.agency_billing_rate && this.state.agency_billing_type){
        payload.proposed_hourly_rate = this.state.agency_billing_rate;
        payload.billing_type = this.state.billing_type
      }

      if (this.state.password === this.state.password_confirm) {
        const { store } = this.context;
        const state = store.getState();
        const userPool = state.cognito.userPool;
        const config = state.cognito.config;
        this.setState({ isLoading: true });
        registerUser(userPool, config, this.state.username, this.state.password, {
          email: this.state.email.toLowerCase(),
        }).then(
          (action) => {
            store.dispatch(action);
            this.setState({ stage: 2 });
            this.setState({ isLoading: false });
            axios
              .post(ENVIRONMENT.TEST_ARTIST_CONTROLLER, payload)
              .then((res) => {
                console.log(res.data);
              });
          },
          (error) => {
            this.setState({ error, isLoading: false })
          }
        );
      } else {
        this.setState({ isLoading: false });
        this.setState({
          error: "Passwords do not match",
        });
      }
    }

  };

  changeUsername = (event) => {
    this.setState({ username: event.target.value });
  };

  changePassword = (event) => {
    this.setState({ password: event.target.value });
  };

  changePasswordConfirm = (event) => {
    this.setState({ password_confirm: event.target.value });
  };

  changeSkype = (event) => {
    this.setState({ skype: event.target.value });
  };

  changeYearsOfExperience = (event) => {
    this.setState({ years_of_experience: event.target.value });
  };
  onChangePricingMethod=(event)=>{
     this.setState({ pricingMethod: event.target.value });
  }
  changeHourlyRate = (event) => {
    this.setState({ proposed_hourly_rate: event.target.value });
  };

  changeWorkableHours = (event) => {
    this.setState({ proposed_working_hours_per_week: event.target.value });
  };

  handleSkillsChange = (value) => {
    if (value !== null || value !== "") {
      this.setState({ skills: value });
    }
  };

  changeURL = (event) => {
    this.setState({ portfolio_url: event.target.value });
  };
  changeEmail = (event) => {
    let email = event.target.value;
    this.setState({ email: email });
  };

  changeProfileName = (event) => {
    this.setState({profile_name: event.target.value})
  }

  onSelectCountry = (value) => {
    this.setState({
      country: value,
    });
  };

  onSelectListening = (value) => {
    this.setState({
      listening_english_prof: value,
    });
  };
  onSelectWritten = (value) => {
    this.setState({
      writing_english_prof: value,
    });
  };
  onSelectReading = (value) => {
    this.setState({
      reading_english_prof: value,
    });
  };

  handleChangePortfolioFiles = (info, check = null) => {
    let fileList = [...info.fileList];
    this.setState({ portfolioFilesList: fileList });
    console.log('File List = ', fileList)
    if(fileList.length > 0){
      this.setState({ uploading: true });
    }
    else{
      this.setState({uploading: false})
    }
    if (info.file.status === "done") {
      this.setState({ hasError: false });
      this.setState({ errorMessage: "" });
      this.setState({ uploading: false });
    } else if (info.file.status === "error") {
      this.setState({
        errorMessage: info.file.error.code.concat(
          "   " + info.file.error.message
        ),
      });
      if (info.file.error.code.includes("Credentials")) {
        this.setState({
          errorMessage: info.file.error.code.concat(
            ". Your session has expired. Please reload the page."
          ),
        });
      } else if (info.file.error.code.includes("Network")) {
        this.setState({
          errorMessage: info.file.error.code.concat(
            ". We are unable to upload due to an issue with your internet connection."
          ),
        });
      }
      this.setState({ hasError: true });
      this.setState({ uploading: false });
    }
  };

  setPhotoFileList = (fileList) => {
    this.setState({
      portfolioFilesList: fileList
    });
  }
  
  render = () => {
    return (
      <span>
        {this.state.stage === 1 ? (
          <Row>
            <Col span={12} style={{ overflow: "hidden", height: "100vh" }}>
              <img
                className="branding-image"
                style={{ height: this.state.step === 1 ? "" : "100%" }}
                alt="Brand Image"
                src={require("../../../../assets/images/login-branding.jpg")}
              />
            </Col>
            {this.state.step === 1 ? (
              <Col span={12} style={{ overflow: "hidden", height: "100vh" }}>
                <div
                  className="input-form custom-frm"
                  style={{ ...formDiv, overflowY: "scroll", height: "100vh" }}
                >
                  <div style={formStyle} className="register-artist-font">
                    <div style={formDiv}>
                      <div style={formStyle}>
                        <h3 className="text-center manrope w-700 text-login-logo mb-10">
                          {" "}
                          <span>ALL</span>
                          <span className="text-red">3D</span>{" "}
                        </h3>
                        <p className="manrope f-14 black-33 sub-heading">
                          By continuing, you accept ALL3D's
                          <br />
                          <a href="https://all3d.ai/terms" target="_blank">
                            Terms of Use
                          </a>{" "}
                          and{" "}
                          <a href="https://all3d.ai/privacy" target="_blank">
                            Privacy Policy
                          </a>
                          .
                        </p>
                        <h4 className=" manrope w-500 text-login-logo info-heading mb-10">
                          {" "}
                          Basic Information
                        </h4>
                        <Form
                          onFinishFailed={(e) => console.log(e)}
                          className="login-form form-width"
                          layout="vertical"
                          ref={this.formRef}
                          initialValues={{
                            email: this.state.email || "",
                            profile_name: this.state.profile_name || "",
                            password: this.state.password || "",
                            password_confirm: this.state.password_confirm || "",
                            skype: this.state.skype || "",
                            username: this.state.username || "",
                          }}
                        >
                          <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                              {
                                type: "email",
                                message: "Please enter a valid email.",
                              },
                              {
                                required: true,
                                message: "Please input your email!",
                              },
                              {
                                pattern: "^[^\\s]+(\\s+[^\\s]+)*$",
                                message:
                                  " Spaces are not allowed, whitespace found at the end.",
                              },
                            ]}
                          >
                            <Input
                              disabled={
                                this.state.artist_invite &&
                                this.state.artist_invite.email
                                  ? true
                                  : false
                              }
                              onChange={this.changeEmail}
                              placeholder="Enter a valid email address"
                            />
                          </Form.Item>

                          <Form.Item
                            name="profile_name"
                            label="Profile Name"
                            rules={[
                              {
                                required: true,
                                message: "Please input your Profile Name!",
                              },
                            ]}
                          >
                            <Input
                              onChange={this.changeProfileName}
                              placeholder="Please enter your name"
                            />
                          </Form.Item>

                          <Form.Item
                            name="username"
                            label="Username"
                            rules={[
                              {
                                required: true,
                                message: "Please input your username!",
                              },
                              {
                                pattern: "^[^\\s]+(\\s+[^\\s]+)*$",
                                message:
                                  " Spaces are not allowed, whitespace found at the end.",
                              },
                              {
                                pattern: "^[a-zA-Z0-9_]*$",
                                message:
                                  " Username can only contain alphanumeric or underscore",
                              },
                            ]}
                          >
                            <Input
                              onChange={this.changeUsername}
                              placeholder="Enter a valid username"
                            />
                          </Form.Item>

                          <Form.Item
                            name="password"
                            label="Password"
                            rules={[
                              {
                                required: true,
                                message: "Please input your password!",
                              },
                              {
                                pattern:
                                  "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
                                message:
                                  "Password should be at least 8 characters including at least one uppercase, one lower, one special character and a digit",
                              },
                            ]}
                          >
                            <Input
                              onChange={this.changePassword}
                              type="password"
                              placeholder="Enter a Strong Password"
                            />
                          </Form.Item>
                          <Form.Item
                            name="password_confirm"
                            label="Confirm Password"
                            rules={[
                              {
                                required: true,
                                message: "Confirm your password!",
                              },
                              {
                                pattern:
                                  "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
                                message:
                                  "Password should be at least 8 characters including at least one uppercase, one lower, one special character and a digit",
                              },
                            ]}
                          >
                            <Input
                              onChange={this.changePasswordConfirm}
                              type="password"
                              placeholder="Re-enter Password"
                            />
                          </Form.Item>

                          <Form.Item name="skype" label="Skype Username">
                            <Input
                              onChange={this.changeSkype}
                              placeholder="Enter your Skype Username"
                            />
                          </Form.Item>

                          <Form.Item>
                            <div className="flex-row">
                              <div className="w-80">Step 1/2</div>
                              <Progress
                                percent={50}
                                showInfo={false}
                                strokeColor="#276DD7"
                                trailColor="rgba(0, 0, 0, 0.12)"
                                style={{
                                  marginTop: 7,
                                }}
                              />
                              <div style={{ marginLeft: 244 }}>
                                {" "}
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  disabled={this.state.isLoading}
                                  className="login-button btn"
                                  style={{
                                    background: "#276DD7",
                                  }}
                                  onClick={this.onSubmitContinue}
                                >
                                  Continue{" "}
                                  {this.state.isLoading ? (
                                    <LoadingOutlined spin />
                                  ) : (
                                    ""
                                  )}
                                </Button>
                              </div>
                            </div>
                          </Form.Item>

                          <Form.Item className="text-center">
                            Already have an account?{" "}
                            <Link to="/">
                              <a href="" className="text-primary">
                                Sign In
                              </a>
                            </Link>{" "}
                            instead
                          </Form.Item>
                        </Form>
                      </div>
                    </div>
                    {this.state.error ? (
                      <div
                        ref={this.errorRef}
                        className="text-danger text-center mb-10"
                        style={{ paddingBottom: 20 }}
                      >
                        {this.state.error}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Col>
            ) : this.state.skills != {} ? (
              <Col span={12} style={{ overflow: "hidden", height: "100vh" }}>
                <div
                  className="input-form signup-form custom-frm"
                  style={{
                    ...additionalFormDiv,
                    "overflow-y": "scroll",
                    height: "100vh",
                  }}
                >
                  <div style={formStyle} className="register-artist-font">
                    <div style={additionalFormDiv}>
                      <div style={formStyle}>
                        <h4 className="manrope w-500 info-heading mb-20 mt-10">
                          {" "}
                          Additional Information
                        </h4>
                        <Form
                          onFinish={this.onSubmit}
                          onFinishFailed={(e) => console.log(e)}
                          className="login-form form-width"
                          ref={this.formRef}
                          style={{ marginRight: 20 }}
                          initialValues={{
                            years_of_experience: this.state.years_of_experience,
                            ...Object.assign(
                              {},
                              ...this.state.skills.map((s) => {
                                let skill = {};
                                if(this.state.artist_skills[s["name"]] != undefined)
                                  skill[s.name] = this.state.artist_skills[s["name"]]
                                return skill;
                              })
                            ),
                            country: this.state.country,
                            proposed_hourly_rate:
                              this.state.proposed_hourly_rate,
                            proposed_working_hours_per_week:
                              this.state.proposed_working_hours_per_week,
                            writing_english_prof:
                              this.state.writing_english_prof,
                            listening_english_prof:
                              this.state.listening_english_prof,
                            reading_english_prof:
                              this.state.reading_english_prof,
                            portfolio_url: this.state.portfolio_url,
                          }}
                          layout="vertical"
                        >
                          <Form.Item
                            name="years_of_experience"
                            label="Years of Experience"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please enter your years of experience!",
                              },
                            ]}
                          >
                            <Input
                              type="number"
                              onChange={this.changeYearsOfExperience}
                              placeholder="How many years have you worked in the field"
                            />
                          </Form.Item>

                          <div
                            style={{
                              color: "red",
                              display: "flex",
                              flexDirection: "row",
                              marginBottom: 0,
                            }}
                          >
                            * &nbsp;
                            <p
                              className="manrope f-16"
                              style={{ color: "#666666" }}
                            >
                              Rate your skills
                            </p>
                          </div>
                          <div
                            className="items-flex"
                            style={{
                              flexWrap: "wrap",
                              paddingLeft: 20,
                              paddingRight: 20,
                            }}
                          >
                            {this.state.skills.map((ele) => {
                              return (
                                <Form.Item
                                  label={ele.display_name}
                                  style={{ minWidth: "45%" }}
                                  name={ele.name}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Select your proficiency",
                                    },
                                  ]}
                                  className="w-32 sub-section"
                                >
                                  <Select
                                    placeholder="Select your skill level"
                                    onSelect={(value) =>
                                      this.onSelectSkill(ele.name, value)
                                    }
                                  >
                                    <Option value="Good" key="1">
                                      Good
                                    </Option>
                                    <Option value="Average" key="2">
                                      Average
                                    </Option>
                                    <Option value="Bad" key="3">
                                      Bad
                                    </Option>
                                  </Select>
                                </Form.Item>
                              );
                            })}
                          </div>
                          <Form.Item
                            name="country"
                            label="Country"
                            rules={[
                              {
                                required:
                                  this.state.country == null ? true : false,
                                message: "Please select your country!",
                              },
                            ]}
                          >
                            <Select
                              allowClear
                              showSearch
                              placeholder="Select your country from the list"
                              value={this.state.country}
                              onSelect={this.onSelectCountry}
                            >
                              {Country.getAllCountries().map((ele, index) => {
                                return (
                                  <Option key={index + 1} value={ele.name}>
                                    {" "}
                                    {ele.name}{" "}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Form.Item>
                          {this.state.show_billing_fields ? (
                            <React.Fragment>
                              <div className="items-flex">
                                <Form.Item
                                  name="pricing_method"
                                  label="Pricing Method (in USD)"
                                  className="w-32"
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Select pricing method!",
                                    },
                                  ]}
                                >
                                  <div className="items-flex">
                                    <Radio.Group
                                      onChange={this.onChangePricingMethod}
                                      value={this.state.pricingMethod}
                                      className="manrope f-14 items-flex mt-10"
                                    >
                                      <Radio value={1} defaultChecked>
                                        Hourly
                                      </Radio>
                                      <Radio value={2}>Per Model</Radio>
                                    </Radio.Group>
                                  </div>
                                </Form.Item>
                                <Form.Item
                                      name="proposed_hourly_rate"
                                      label="Rate"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please provide your rate!",
                                        },
                                      ]}
                                >
                                  <Input
                                    type="number"
                                    placeholder="Enter rate here"
                                    value={this.state.proposed_hourly_rate}
                                    onChange={this.changeHourlyRate}

                                  />
                                </Form.Item>
                                <Form.Item
                                  name="proposed_working_hours_per_week"
                                  label="Work Hours (per week)"
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please provide your working hours!",
                                    },
                                  ]}
                                  className="w-32"
                                >
                                  <Input
                                    onChange={this.changeWorkableHours}
                                    type="number"
                                    placeholder="Total hours"
                                  />
                                </Form.Item>
                              </div>
                            </React.Fragment>
                          ) : (
                            <Form.Item
                              name="proposed_working_hours_per_week"
                              label="Work Hours (per week)"
                              rules={[
                                {
                                  required: true,
                                  message: "Please provide your working hours!",
                                },
                              ]}
                              className="w-32"
                            >
                              <Input
                                onChange={this.changeWorkableHours}
                                type="number"
                                placeholder="Total hours"
                              />
                            </Form.Item>
                          )}
                          <div
                            style={{
                              color: "red",
                              display: "flex",
                              flexDirection: "row",
                              marginBottom: 0,
                            }}
                          >
                            * &nbsp;
                            <p
                              className="manrope f-16"
                              style={{ color: "#666666" }}
                            >
                              Proficiency in English
                            </p>
                          </div>
                          <div
                            className="items-flex"
                            style={{ paddingLeft: 20, paddingRight: 20 }}
                          >
                            <Form.Item
                              label="Writing"
                              name="writing_english_prof"
                              rules={[
                                {
                                  required: true,
                                  message: "",
                                },
                              ]}
                              className="w-32 sub-section"
                            >
                              <Select
                                onSelect={this.onSelectWritten}
                                value={this.state.writing_english_prof}
                              >
                                {options.map((x, index) => (
                                  <Option value={x} key={index}>
                                    {x}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              name="reading_english_prof"
                              rules={[
                                {
                                  required: true,
                                  message: "",
                                },
                              ]}
                              label="Reading"
                              className="w-32 sub-section"
                            >
                              <Select
                                onSelect={this.onSelectReading}
                                value={this.state.reading_english_prof}
                              >
                                {options.map((x, index) => (
                                  <Option value={x} key={index}>
                                    {x}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              name="listening_english_prof"
                              label="Listening"
                              rules={[
                                {
                                  required: true,
                                  message: "",
                                },
                              ]}
                              className="w-32 sub-section"
                            >
                              <Select
                                onSelect={this.onSelectListening}
                                value={this.state.listening_english_prof}
                              >
                                {options.map((x, index) => (
                                  <Option value={x} key={index}>
                                    {x}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>

                          <Form.Item
                            name="portfolio"
                            label="Portfolio (file or url)"
                          >
                            <Dragger
                              {...Constants.upload_props}
                              multiple={true}
                              fileList={this.state.portfolioFilesList}
                              listType="text"
                              onDrop={(e) => {
                                console.log(e);
                                console.log("file dropped");
                              }}
                              onRemove={(file) => {
                                const index =
                                  this.state.portfolioFilesList.indexOf(file);
                                const newFileList =
                                  this.state.portfolioFilesList.slice();
                                newFileList.splice(index, 1);
                                this.setState({
                                  portfolioFilesList: newFileList,
                                });
                                newFileList.forEach((file) => {
                                  if (file.status !== "error") {
                                    this.setState({ hasError: false });
                                  } else {
                                    this.setState({ hasError: true });
                                  }
                                });
                                return true;
                              }}
                              onChange={this.handleChangePortfolioFiles}
                              accept=".jpg,.jpeg,.png,.zip,.pdf"
                              className="upload-box-hover-color"
                              progress={progress_bar}
                            >
                              <p className="">
                                <img
                                  className=""
                                  src={require("../../../../assets/images/ion_cloud-upload-outline.png")}
                                  alt=""
                                ></img>
                              </p>
                              <p className="ant-upload-text ">
                                Click or drag file to this area to upload
                              </p>
                              <p className="ant-upload-hint">
                                SUPPORTED FORMATS: PDF, JPG, ZIP
                              </p>

                              {validateFileUploads(
                                this.state.portfolioFilesList
                              ) == "error" && (
                                <Button
                                  onClick={() =>
                                    Constants.triggerFailedFilesUpload('portfolio', this.state.portfolioFilesList, this.setPhotoFileList)
                                  }
                                  disabled={
                                    this.state.portfolioFilesList.length === 0
                                  }
                                  loading={this.state.uploading}
                                  className="retry-btn multiple manrope f-12"
                                  ghost
                                >
                                  {this.state.uploading ? (
                                    <span>Retrying...</span>
                                  ) : (
                                    "Upload Failed. Click to Retry."
                                  )}
                                </Button>
                              )}
                            </Dragger>
                          </Form.Item>

                          <Form.Item name="portfolio_url">
                            <Input
                              value={this.state.portfolio_url}
                              onChange={this.changeURL}
                              placeholder="Portfolio URL (ArtStation, Behance, etc.)"
                            />
                          </Form.Item>

                          <Form.Item>
                            <div className="flex-row">
                              <div className="w-80">Step 2/2</div>
                              <Progress
                                percent={100}
                                showInfo={false}
                                strokeColor="#276DD7"
                                style={{
                                  marginTop: 7,
                                }}
                              />
                              <div
                                className="flex-row"
                                style={{ marginLeft: 130, float: "right" }}
                              >
                                <Button
                                  className="back-button"
                                  onClick={() => {
                                    this.setState({
                                      step: 1,
                                    });
                                  }}
                                >
                                  <LeftOutlined />
                                  Back{" "}
                                </Button>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  className="login-button create-account-button"
                                  style={{ background: "#276DD7" }}
                                >
                                  Create Account{" "}
                                  {this.state.isLoading ? (
                                    <LoadingOutlined spin />
                                  ) : (
                                    ""
                                  )}
                                </Button>
                              </div>
                            </div>
                          </Form.Item>
                        </Form>
                      </div>
                    </div>
                    {this.state.error ? (
                      <div
                        ref={this.errorRef}
                        className="text-danger text-center mb-10"
                        style={{ paddingBottom: 20 }}
                      >
                        {this.state.error}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </Col>
            ) : (
              ""
            )}
          </Row>
        ) : (
          <span>
            {this.state.stage === 2 ? (
              <Dashboard loginUser={this.loginUser} />
            ) : (
              <Dashboard
                registerUsername={this.state.username}
                registerPassword={this.state.password}
              />
            )}
          </span>
        )}
      </span>
    );
  };
}

RegisterArtist.contextTypes = {
  store: PropTypes.object,
};

export default RegisterArtist;
